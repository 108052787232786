import type {FunctionComponent, MouseEvent, SVGProps} from "react";
import {useEffect, useRef, useState} from "react";
import styled, {css} from "styled-components";
import {ReactUtils} from "../../utils/ReactUtils";
import {baseDistance, ELLIPSIS, fontSize, radius, zIndex} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";
import CaretRightIcon from "../icons/caret-right.svg?react";
import {InfoBubbleStyled, InfoBubbleV5} from "../button/InfoBubbleV5";
import {Functions} from "../../../utils/function/Functions";
import {SearchFieldV5} from "../input/search/SearchFieldV5";
import {SearchFieldStyled} from "../input/search/SearchField.styled";
import {TimeUtils} from "../../../utils/TimeUtils";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {useAppStore} from "../../../StateManager";

export interface IDropdownOption {
	readonly label: string;
	readonly isActive?: boolean;
	readonly disabled?: boolean;
	readonly infoText?: string;
	readonly IconComponent?: FunctionComponent<SVGProps<SVGSVGElement> & {title?: string}>;
	readonly iconCustomStyle?: React.CSSProperties;
	readonly customElement?: JSX.Element;
	readonly onClick: (...args: any[]) => any;
}

export interface ISubOptionWrapper {
	readonly label: string;
	readonly disabled?: boolean;
	readonly infoText?: string;
	readonly IconComponent?: FunctionComponent<SVGProps<SVGSVGElement> & {title?: string}>;
	readonly iconCustomStyle?: React.CSSProperties;
	readonly options: (IDropdownOption | ISubOptionWrapper)[];
	readonly customElement?: JSX.Element;
}

interface IDropdownOptionsProps {
	readonly divRef?: React.RefObject<HTMLDivElement>;
	readonly style?: React.CSSProperties;
	readonly options: (IDropdownOption | ISubOptionWrapper)[];
	readonly className?: string;
	readonly optionsZIndex?: number;
	readonly showSearch?: boolean;
	readonly closeOnMouseLeave?: boolean;
	readonly horizontalAlignment?: HorizontalAlignment;
	readonly verticalAlignment?: VerticalAlignment;
	readonly offsetX?: number;
	readonly offsetY?: number;
	readonly changeAlignmentOnPositionCorrection?: boolean;
	readonly onlyIcons?: boolean;
	readonly onClose: () => void;
}

export const DropdownOptionsV5 = (props: IDropdownOptionsProps) => {
	const {
		style = {},
		divRef,
		options,
		className = "",
		optionsZIndex = zIndex.dropdowns,
		showSearch = true,
		closeOnMouseLeave = false,
		horizontalAlignment = HorizontalAlignment.outerRight,
		verticalAlignment = VerticalAlignment.center,
		offsetX = 0,
		offsetY = 8,
		changeAlignmentOnPositionCorrection = true,
		onlyIcons = false,
		onClose,
	} = props;
	const appState = useAppStore((state) => state.appState);
	const modalContainer = appState.app.modalContainer;

	const [openOption, setOpenOption] = useState<IDropdownOption | ISubOptionWrapper | null>(null);
	const [searchText, setSearchText] = useState<string>("");
	const [optionsTransform, setOptionsTransform] = useState<TransformObj | null>(null);

	const optionRef = useRef<HTMLDivElement | null>(null);
	const subMenuRef = useRef<HTMLDivElement | null>(null);
	const optionRefs = useRef<{[key: number]: HTMLDivElement | null}>({});
	const [hoveredOptionName, setHoveredOptionName] = useState<string | null>(null);

	const filteredOptions = searchText.length === 0 ? options : options.filter((o) => o.label.toLowerCase().includes(searchText.toLowerCase()));

	const finalStyle: React.CSSProperties = {
		...style,
		overflowY: options.some((o) => (o as ISubOptionWrapper).options?.length > 0) || options.some((o) => o.infoText) ? "visible" : "auto",
	};

	const onMouseEnter = (optionId: number, optionName: string) => {
		const element = optionRefs.current[optionId];
		if (element) {
			const isTruncated = element.scrollWidth > element.clientWidth;
			if (isTruncated) {
				setHoveredOptionName(optionName);
			} else {
				setHoveredOptionName(null); // Hide if not truncated
			}
		}
	};

	const onMouseLeave = () => {
		setHoveredOptionName(null);
	};

	useEffect(() => {
		const currentDivRef = divRef?.current;

		if (currentDivRef && closeOnMouseLeave) {
			currentDivRef.addEventListener("mouseleave", onClose);
		}
		return () => {
			currentDivRef?.removeEventListener("mouseleave", onClose);
		};
	}, [divRef, closeOnMouseLeave, onClose]);

	useEffect(() => {
		if (!openOption || !optionRef.current || !subMenuRef.current) {
			return;
		}

		const calculatedPosition = DomUtils.getFixedFloatingElementPosition(
			optionRef.current,
			subMenuRef.current,
			verticalAlignment,
			horizontalAlignment,
			offsetY,
			offsetX,
			changeAlignmentOnPositionCorrection,
		);

		setOptionsTransform(calculatedPosition);
	}, [openOption, horizontalAlignment, verticalAlignment, offsetX, offsetY, changeAlignmentOnPositionCorrection, divRef]);

	const inlineStyle: React.CSSProperties = {
		transform: optionsTransform?.translate,
	};

	return (
		<DropdownStyled
			id="DropdownOptionsV5"
			className={`DropdownOptions ${className}`}
			style={finalStyle}
			ref={divRef}
			onClick={Functions.stopPropagation}
			$zIndex={optionsZIndex}
			$onlyIcons={onlyIcons}
		>
			{showSearch && options.length > 4 && (
				<SearchFieldV5
					className="SearchField"
					value={searchText}
					onInput={(value, ev) => setSearchText(value)}
					onClick={(ev) => ev.stopPropagation()}
				/>
			)}
			<DropdownOptionsStyled $isSearchField={showSearch}>
				{filteredOptions.length === 0 && options.length > 0 && <div>No results found...</div>}
				{filteredOptions.map((option, index) => {
					const hasSubOptions = Array.isArray((option as ISubOptionWrapper).options);
					const isSubMenuOpen = hasSubOptions && option === openOption;

					return (
						<div
							key={JSON.stringify({...option, customElement: null})}
							className={ReactUtils.cls(`option`, {
								hasSubOptions,
								disabled: option.disabled,
								hasIcon: option.IconComponent,
								isActive: (option as IDropdownOption).isActive,
							})}
							onMouseOver={() => setOpenOption(option)}
							onMouseDown={async (ev: MouseEvent) => {
								ev.stopPropagation();
								if ((option as IDropdownOption).onClick && !option.disabled) {
									(option as IDropdownOption).onClick();
									await TimeUtils.waitForNextFrame(); // workaround: without this, "useClickOutside" can't find the "option" element within the modalContainer, so the "FloatingMenu" closes
									onClose();
								}
							}}
							ref={optionRef}
							title={hoveredOptionName}
						>
							<div className="optionLabel">
								{option.IconComponent && (
									<div className="icon">
										<option.IconComponent style={option.iconCustomStyle} />
									</div>
								)}
								{option.label !== "" && !onlyIcons && (
									<div
										className="title"
										ref={(el) => (optionRefs.current[index] = el)}
										onMouseEnter={() => onMouseEnter(index, option.label)}
										onMouseLeave={onMouseLeave}
									>
										{option.customElement ? option.customElement : option.label}
									</div>
								)}
							</div>
							{/* {
									(option as IDropdownOption).isActive &&
									<MarginLeftAuto>✓</MarginLeftAuto>
								} */}
							{hasSubOptions && <CaretRightIcon />}
							{option.infoText && (
								<InfoBubbleV5
									useDomPortal={false}
									content={option.infoText}
									className="InfoBubble"
								/>
							)}
							{isSubMenuOpen && (
								<DomPortal destination={modalContainer}>
									<DropdownOptionsV5
										options={(option as ISubOptionWrapper).options}
										onClose={onClose}
										divRef={subMenuRef}
										style={inlineStyle}
									/>
								</DomPortal>
							)}
						</div>
					);
				})}
			</DropdownOptionsStyled>
		</DropdownStyled>
	);
};

const DropdownStyled = styled.div<{$zIndex: number; $onlyIcons: boolean}>`
	display: flex;
	flex-direction: column;
	z-index: ${(props) => props.$zIndex};
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
	background-color: ${colorPalette.white};
	border-radius: ${radius.md};
	position: absolute;
	text-align: left;
	font-size: ${fontSize.lg};
	line-height: 24px;
	gap: ${baseDistance.sm};
	margin: 0;
	padding: ${baseDistance.sm};
	max-height: 288px;

	${SearchFieldStyled} {
		width: 100%;
	}

	&.sub {
		position: absolute;
		top: -8px;
		left: calc(100% + 8px);
		transform: initial;
		overflow-y: hidden;

		&::before {
			display: none;
		}

		.option {
			.optionLabel {
				width: 100%;
			}
		}
	}

	.option {
		color: ${colorPalette.gray.c950};
		position: relative;
		white-space: nowrap;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: ${(props) => !props.$onlyIcons && baseDistance.sm} ${baseDistance.xs};
		height: 32px;
		border-radius: ${radius.sm};
		width: 100%;

		.optionLabel {
			display: flex;
			gap: ${baseDistance.sm};
			align-items: center;
			max-width: 400px;
			width: 100%;
			${ELLIPSIS};

			.icon {
				display: flex;

				svg {
					${(props) =>
						!props.$onlyIcons &&
						css`
							width: ${baseDistance.md};
							height: ${baseDistance.md};
						`};
				}
			}

			.title {
				${ELLIPSIS};
				width: 100%;
			}
		}

		.rightIcon {
			display: flex;
		}

		> svg {
			&:first-of-type {
				min-width: 24px;
			}
		}

		> ${InfoBubbleStyled} {
			display: none;
		}

		&.isActive {
			color: ${colorPalette.primary.c500Primary};
			background: ${colorPalette.primary.c200Light};
		}

		&:not(.disabled, .isActive):hover {
			color: ${colorPalette.gray.c950};
			background: ${colorPalette.gray.c200Light};

			> .right {
				color: ${colorPalette.white};
				fill: ${colorPalette.white};
			}
		}

		&:hover {
			> ${InfoBubbleStyled} {
				display: flex;
			}
		}

		&.disabled {
			pointer-events: initial;
			color: ${colorPalette.gray.c200Light};
		}

		&.hasSubOptions {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: ${baseDistance.sm};

			.right {
				width: 10px;
				height: 10px;
			}
		}
	}
`;

export const DropdownOptionsStyled = styled.div<{$isSearchField: boolean}>`
	display: flex;
	flex-direction: column;
	max-height: ${(props) => (props.$isSearchField ? "280px" : "none")};
	overflow-y: ${(props) => (props.$isSearchField ? "auto" : "unset")};
`;
