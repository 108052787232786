import {Observer} from "mobx-react";
import type {Markup} from "../../../data/models/Markup";
import {MarkupType} from "../../../generated/api/base";
import type {MarkupPhoto360} from "../../modules/space/spaceeditor/logic3d/elements3d/markups/MarkupPhoto360";
import type {SpaceViewRenderer} from "../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {triggerPhoto360Previewer} from "../../modules/space/spaceeditor/logic3d/elements3d/markups/MarkupPhoto360Utils";
import {IconButton} from "./IconButton";

interface IPhoto360PreviewButtonProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
}

export const Photo360PreviewButton = (props: IPhoto360PreviewButtonProps) => {
	const {selectedItems} = props.spaceViewRenderer.spaceItemController;

	const onClick = () => {
		const markupPhoto360 = selectedItems[0] as MarkupPhoto360;
		const markupModelData = markupPhoto360.modelData as Markup;
		triggerPhoto360Previewer(markupModelData);
	};

	return (
		<Observer>
			{() => {
				const isButtonAvailable =
					selectedItems.length === 1 &&
					selectedItems[0].spaceItemType === "markup" &&
					selectedItems[0].type === MarkupType.Photo360 &&
					(selectedItems[0].modelData as Markup)?.isIncompletePhoto360 === false;

				return (
					isButtonAvailable && (
						<IconButton
							icon="markupPhoto360"
							title="Preview 360 Photo"
							onClick={onClick}
						/>
					)
				);
			}}
		</Observer>
	);
};
