import styled from "styled-components";
import {Functions} from "../../../../../utils/function/Functions";
import {ReactUtils} from "../../../../utils/ReactUtils";

interface IPortSelectAreaProps {
	readonly portId: string | null;
	readonly onClick: (portId: string) => void;
	readonly leaf: boolean;
}

export const PortSelectAreaV5 = (props: IPortSelectAreaProps) => {
	const onClick = () => {
		props.onClick(props.portId);
	};

	return (
		<PortSelectAreaWrapper
			className={ReactUtils.cls("PortSelectArea hbox alignCenter", {leaf: props.leaf})}
			style={{justifyContent: "flex-end"}}
			onClick={props.leaf ? onClick : Functions.emptyFunction}
		></PortSelectAreaWrapper>
	);
};

const PortSelectAreaWrapper = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	width: 100%;

	&:hover {
		&.leaf {
			cursor: pointer;
			background-color: rgba(52, 149, 240, 0.1);
		}
	}
`;
