import {makeObservable, observable} from "mobx";
import type {App} from "./App";
import {PDFRenderer} from "./ui/modules/space/spaceeditor/logic3d/managers/PDFRenderer";
import {SpaceViewRenderer} from "./ui/modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {GridItemSide} from "./ui/5.0/modules/spaceeditor/spaceselector/GridTypes";
import {PhotoSphereManager} from "./photosphere/PhotoSphereManager";
import {PhotoSphereSceneManager} from "./photosphere/PhotoSphereSceneManager";

export class GraphicalTools {
	private _spaceViewRenderer: SpaceViewRenderer;
	private _pdfRenderer: PDFRenderer;
	private _photoSphereSceneManager: PhotoSphereSceneManager;
	private _photoSphereManager: PhotoSphereManager;

	@observable
	public gridItemSide: GridItemSide | "" = "";

	constructor(app: App) {
		this._spaceViewRenderer = new SpaceViewRenderer(app);
		this._pdfRenderer = new PDFRenderer(document.createElement("canvas"));
		this._photoSphereSceneManager = new PhotoSphereSceneManager(document.createElement("canvas"), this._spaceViewRenderer);
		this._photoSphereManager = new PhotoSphereManager(this._photoSphereSceneManager);
		makeObservable(this);
	}

	public get spaceViewRenderer() {
		return this._spaceViewRenderer;
	}

	public get pdfRenderer() {
		return this._pdfRenderer;
	}

	public get photoSphereSceneManager() {
		return this._photoSphereSceneManager;
	}

	public get photoSphereManager() {
		return this._photoSphereManager;
	}
}
