import {Vector2} from "three";
import type {LineGeometry} from "three/examples/jsm/lines/LineGeometry.js";
import type {PointDouble} from "../../../../../../../generated/api/base";
import type {Markup} from "../../../../../../../data/models/Markup";
import {MarkupType} from "../../../../../../../generated/api/base";
import {MarkupAB2D} from "./abstract/MarkupAB2D";
import type {ICornerLetter} from "./abstract/MarkupAB";

export class MarkupTextBox extends MarkupAB2D {
	public static readonly outlineColor: string = "03A9F4";

	protected updateAB(A: PointDouble, B: PointDouble, isLocal: boolean = false, keepAspectRatio: boolean, fixedPoint: ICornerLetter = "a") {
		const worldVertices = this.processAB(A, B, isLocal, keepAspectRatio, fixedPoint);

		this._vertices.length = 0;
		this._2dVectors.length = 0;
		for (const worldVertex of worldVertices) {
			this._vertices.push(worldVertex.x);
			this._vertices.push(worldVertex.y);
			this._vertices.push(0);

			this._2dVectors.push(new Vector2(worldVertex.x, worldVertex.y));
		}
		this._vertices.push(worldVertices[0].x);
		this._vertices.push(worldVertices[0].y);
		this._vertices.push(0);

		(this._lineGeometry as LineGeometry).setPositions(this._vertices);

		this.updateMesh(this._2dVectors);

		if (isLocal) {
			this._selectionPart.update(this._vertices);
			// this._selectionPart === this._textArea in this case, so there's no need to update it again
			// this._textArea.update(this._vertices);
		}
		this.updateBoundingBox();
	}

	public override makeSizeFixed(): void {
		const modelData = this._modelData as Markup;

		if (modelData?.isSizeFixed !== true) {
			modelData.setIsSizeFixed(true);
		}
	}

	public hideOutline() {
		this._lineMaterial.visible = false;
		this._spaceViewRenderer.needsRender = true;
	}

	public get type() {
		return MarkupType.TextBox;
	}
}
