import * as React from "react";
import {inject, observer} from "mobx-react";
import {ReactSortable} from "react-sortablejs";
import {Button} from "../../../../widgets/button/Button";
import {IconButton} from "../../../../widgets/button/IconButton";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {AppState} from "../../../../../data/state/AppState";
import {featureTitles} from "../../../../../data/state/AppStateConstants";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {ClickToEditInput} from "../../../../widgets/input/clicktoedit/ClickToEditInput";
import {ToggleContainer} from "../../../../widgets/container/ToggleContainer";
import {findFieldDataTypeKey} from "../../../../../data/models/field/FieldDataType";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {SVGIcon} from "../../../../widgets/button/SVGIcon";
import {ToggleSwitchField} from "../../../../widgets/button/switch/ToggleSwitchField";
import type {IFieldAdapter} from "../../../../../data/models/field/Field";
import {isDefaultField} from "../../../../../data/models/field/FieldUtils";
import {SearchField} from "../../../../widgets/input/search/SearchField";
import {ArrayUtils} from "../../../../../utils/data/array/ArrayUtils";
import {ObjectUtils} from "../../../../../utils/data/ObjectUtils";
import {Functions} from "../../../../../utils/function/Functions";
import {TimeUtils} from "../../../../../utils/TimeUtils";
import {disableHighlightingOnBody, enableHighlightingOnBody} from "../../../../../utils/interaction/HighlightDisablingUtils";

export interface ISectionData {
	label: string;
	fields: ISectionField[];
}

export interface ISectionField {
	id: string; // refId
	noWrap: boolean;
}

interface ISortableFields {
	id: string;
	field: IFieldAdapter;
}

interface ISection extends ISectionData {
	id: string;
	dragCount: number;
	dragOver?: boolean;
	minimized?: boolean;
	focused?: boolean;
}

interface ILayoutSettingsProps {
	readonly feature: XyiconFeature;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface ILayoutSettingsState {
	findString: string;
	sections: ISection[];
	collapsedSectionsIdArray: string[];
	isSaving: boolean;
	openedFeatures: XyiconFeature[];
	tempOpenedFeature: XyiconFeature[];
	typeOfDraggedElement: draggedElementType;
}

export interface ILayoutDefinition {
	sections: ISectionData[];
}

enum draggedElementType {
	null = 0,
	availableField = 1,
	sectionField = 2,
	section = 3,
}

enum DragGroup {
	fields = "fields",
	sections = "sections",
}

@inject("transport")
@inject("appState")
@observer
export class LayoutSettings extends React.Component<ILayoutSettingsProps, ILayoutSettingsState> {
	private _isMounted: boolean = false;
	private _sortableStylingProps = {
		animation: 150,
		easing: "cubic-bezier(.17,.67,.83,.67)",
		ghostClass: "sortable-ghost-class__layouts",
		dragClass: "sortable-drag-class__layouts",
		chosenClass: "sortable-chosen__layouts",
		handle: ".drag-handle",
	};

	private _previousCursorStyle: string = "";

	constructor(props: ILayoutSettingsProps) {
		super(props);

		this.state = {
			findString: "",
			sections: [],
			collapsedSectionsIdArray: [],
			isSaving: false,
			openedFeatures: [this.props.feature],
			tempOpenedFeature: [],
			typeOfDraggedElement: draggedElementType.null,
		};
	}

	private getId(field: IFieldAdapter) {
		return field.refId;
	}

	private getLayoutDefinition(feature: XyiconFeature = this.props.feature): ILayoutDefinition {
		const {appState} = this.props;

		const layout = appState.layouts[feature];

		return (
			layout || {
				sections: [],
			}
		);
	}

	private getSavedSections(): ISectionData[] {
		return this.getLayoutDefinition().sections;
	}

	private getCurrentSections(): ISectionData[] {
		if (this._isMounted) {
			return this.state.sections.map((section) => ({
				label: section?.label,
				fields: section?.fields?.map((field) => ({
					id: field.id,
					noWrap: field?.noWrap || false,
				})),
			}));
		}
	}

	private isSaveEnabled() {
		const savedSections = JSON.stringify(this.getSavedSections());
		const currentSections = JSON.stringify(this.getCurrentSections());

		const areThereUnsavedChanges = savedSections !== currentSections;

		this.props.appState.app.navigation.unsavedChanges = areThereUnsavedChanges
			? {
					title: "You have changed the Layout",
					description: "Click <b>Save</b> to continue, or <b>Reset</b> to discard the changes",
					onSave: this.onSaveClick,
				}
			: null;

		// If there is anything to save -> allow save button
		return areThereUnsavedChanges && !this.state.isSaving;
	}

	private onSaveClick = async () => {
		if (this._isMounted) {
			this.setState({isSaving: true});
			const {feature, transport} = this.props;
			const serializedData = this.serialize();

			await transport.services.fieldLayout.updateLayout(serializedData, feature);
			this.setState({isSaving: false});
		}
	};

	private onResetClick = () => {
		this.refreshLayout();
	};

	private sectionToSectionData = (section: ISection, index: number) => ({
		label: section.label,
		fields: section.fields.filter((f) => {
			const field = this.props.appState.actions.getFieldByRefId(f?.id);

			return this.filterField(field);
		}),
		id: `${index + 1}`,
		dragCount: 0,
		dragOver: false,
	});

	private serialize() {
		const layoutDefinition: ILayoutDefinition = {
			sections: this.state.sections.map(this.sectionToSectionData),
		};

		return layoutDefinition;
	}

	private onAddNewSectionClicked = () => {
		const {sections} = this.state;

		const newSections = sections.slice(0);

		newSections.push({
			label: "Section",
			id: `${sections.length + 1}`,
			dragCount: 0,
			fields: [],
		});

		if (this._isMounted) {
			this.setState({sections: newSections});
		}
	};

	private onDeleteSectionClicked = (index: number) => {
		const {sections} = this.state;

		const newSections = sections.slice(0);

		newSections.splice(index, 1);

		if (this._isMounted) {
			this.setState({sections: newSections});
		}
	};

	private getFieldsByFeature(feature: XyiconFeature) {
		const {appState} = this.props;
		const result: IFieldAdapter[] = [];

		const defaultFields = appState.defaultFields[feature] || [];
		const customFields = appState.fields[feature] || [];

		result.push(...defaultFields);
		result.push(...customFields);
		return result.filter(this.filterField);
	}

	private filterField = (field: IFieldAdapter) => {
		const {feature, appState} = this.props;

		if (!field) {
			return false;
		}
		if (isDefaultField(field)) {
			// Handle exceptions:
			// (Note: these could be enforced on the SidePanel too.)

			// Exception 1
			// Only custom fields are inherited from Boundary to xyicon
			if (feature === XyiconFeature.Xyicon && field.feature === XyiconFeature.Boundary) {
				if (field.default) {
					return false;
				}
			}

			// Exception 2
			// Xyicon inherits Catalog.model and Catalog.type but we don't want those to be shown in the layout
			// because they are already shown in XyiconDefaultFields (or should be)
			if (field.fixed) {
				if (feature === field.feature) {
					// Don't show the feature's own fixed fields
					return false;
				}
			}

			if (field.refId.includes("versionName") || field.refId.includes("issuanceDate")) {
				return false;
			}
		}
		return appState.actions.isFieldShownForFeature(field, feature);
	};

	private getSectionFieldById(id: string) {
		for (const section of this.state.sections) {
			const sectionField = section?.fields?.find((sectionField) => sectionField?.id === id);

			if (sectionField) {
				return sectionField;
			}
		}
		return null;
	}

	private onFindInput = (value: string) => {
		const {openedFeatures, findString, tempOpenedFeature} = this.state;
		const groups: XyiconFeature[] = this.props.appState.actions.getInheritedFeatures(this.props.feature);

		let features: XyiconFeature[] = [];

		ArrayUtils.copy(features, openedFeatures);

		if (this._isMounted) {
			if (!findString && value) {
				this.setState({tempOpenedFeature: features});
			} else if (findString && !value) {
				this.setState({
					openedFeatures: tempOpenedFeature,
					tempOpenedFeature: [],
				});
			}

			if (value) {
				groups.forEach((feature) =>
					this.onToggle(!!this.getFieldsByFeature(feature).find((field) => StringUtils.containsIgnoreCase(field.name, value)), feature),
				);
			}

			this.setState({findString: value});
		}
	};

	private onRemoveFieldFromSection = (field: IFieldAdapter) => {
		const {sections} = this.state;
		const id = this.getId(field);

		const section = sections.find((section) => section.fields.find((f) => f.id === id));
		const fieldIndex = section.fields.findIndex((f) => f.id === id);

		const newFields = section.fields.slice(0);

		newFields.splice(fieldIndex, 1);
		section.fields = newFields;

		const newSections = sections.slice(0);

		if (this._isMounted) {
			this.setState({sections: newSections});
		}
	};

	private onToggleFieldWrap = (field: ISectionField) => {
		field.noWrap = !field.noWrap;
		this.forceUpdate();
	};

	private filterSearch = (field: IFieldAdapter) => {
		const {findString} = this.state;

		if (findString) {
			if (!StringUtils.containsIgnoreCase(field.name, findString)) {
				return false;
			}
		}

		return true;
	};

	private findSectionForField(fieldId: string) {
		return this.state.sections.filter((section) => section?.fields.find((f) => f?.id === fieldId))[0] || null;
	}

	private isFieldAddedToSection(fieldId: string) {
		return !!this.findSectionForField(fieldId);
	}

	private renderField = (field: IFieldAdapter, inSection: boolean) => {
		const id = this.getId(field);
		const sectionField = this.getSectionFieldById(id);

		return (
			<div className="field">
				<div className="drag-handle hbox alignCenter">
					<SVGIcon
						icon="drag"
						classNames="drag-icon"
					/>
				</div>
				<div className="label">
					<div className="fieldName">{field.name}</div>
					<div className="fieldType">{findFieldDataTypeKey(field.dataType)}</div>
				</div>
				<div className="flex_1" />
				{inSection && (
					<>
						{field.isNew && <div className="banner">New Field</div>}
						<div className="actions hbox alignCenter">
							<ToggleSwitchField
								label="Full width"
								value={sectionField?.noWrap || false}
								onChange={() => this.onToggleFieldWrap(sectionField)}
								labelFirst={true}
							/>
							<IconButton
								title="Remove"
								icon="close"
								className="close"
								onClick={() => this.onRemoveFieldFromSection(field)}
							/>
						</div>
					</>
				)}
			</div>
		);
	};

	public override UNSAFE_componentWillReceiveProps(nextProps: Readonly<ILayoutSettingsProps>, nextContext: any): void {
		if (this.props.feature !== nextProps.feature) {
			this.refreshLayout(nextProps.feature);
		}
	}

	public override componentDidMount() {
		this._isMounted = true;
		this.refreshLayout();
	}

	public override componentWillUnmount() {
		const fields = this.getFieldsByFeature(this.props.feature);

		fields.forEach((field) => (field.isNew = false));

		this.props.appState.app.navigation.unsavedChanges = null;
		this._isMounted = false;
	}

	public refreshLayout(feature?: XyiconFeature) {
		const layout = this.getLayoutDefinition(feature);

		if (this._isMounted) {
			this.setState({sections: ObjectUtils.deepClone(layout.sections.map(this.sectionToSectionData))});
		}
	}

	private shouldBeOpen = (feature: XyiconFeature) => {
		const {openedFeatures} = this.state;

		return feature === this.props.feature || openedFeatures.includes(feature);
	};

	private getUnassignedFields = (feature: XyiconFeature) => {
		return this.getFieldsByFeature(feature)
			.filter(this.filterSearch)
			.filter((field) => !this.isFieldAddedToSection(this.getId(field)) && !field.refId.includes("icon"))
			.sort((fieldA, fieldB) => StringUtils.sortIgnoreCase(fieldA.name, fieldB.name));
	};

	private renderUnassignedFields = (feature: XyiconFeature) => {
		const fields = this.getUnassignedFields(feature);
		const sortableFields: ISortableFields[] = fields.map((field) => ({id: field.refId, field}));

		return (
			<ReactSortable
				list={sortableFields}
				setList={Functions.emptyFunction}
				onStart={this.availableFieldDraggingStart}
				onEnd={this.draggingEnd}
				className="sortable vbox flexWrap"
				group={DragGroup.fields}
				forceFallback={true}
				filter=".ignore-element"
				{...this._sortableStylingProps}
			>
				{fields.length === 0 ? (
					<div className="empty hbox ignore-element">There are no available fields.</div>
				) : (
					fields.map((field, index) => (
						<div
							className="fieldCell"
							key={index}
						>
							{this.renderField(field, false)}
						</div>
					))
				)}
			</ReactSortable>
		);
	};

	private onSectionFieldCollapse = (sectionId: string) => {
		const sectionIndexArray = this.state.collapsedSectionsIdArray;

		if (sectionIndexArray.includes(sectionId)) {
			sectionIndexArray.splice(sectionIndexArray.indexOf(sectionId), 1);
		} else {
			sectionIndexArray.push(sectionId);
		}

		if (this._isMounted) {
			this.setState({collapsedSectionsIdArray: sectionIndexArray});
		}
	};

	private editSectionName = (section: ISection) => {
		section.focused = !section.focused;
		this.forceUpdate();
	};

	private onToggle = (value: boolean, feature: XyiconFeature) => {
		const openedFeatures = this.state.openedFeatures;

		if (value) {
			ArrayUtils.addMutable(openedFeatures, feature);
		} else {
			ArrayUtils.removeMutable(openedFeatures, feature);
		}

		if (this._isMounted) {
			this.setState({openedFeatures});
		}
	};

	private onSetListOfSectionFields = async (newState: ISectionField[], sectionIndex: number) => {
		const {sections, typeOfDraggedElement} = this.state;

		if (
			!ObjectUtils.compare(newState, sections[sectionIndex]) &&
			(typeOfDraggedElement === draggedElementType.sectionField || typeOfDraggedElement === draggedElementType.availableField)
		) {
			await TimeUtils.waitForNextFrame();

			const tempSections: ISection[] = sections;

			tempSections[sectionIndex].fields = newState;

			if (this._isMounted) {
				this.setState({sections: tempSections});
			}
		}
	};

	private onSetListOfSection = (newState: ISection[]) => {
		const {sections, typeOfDraggedElement} = this.state;

		if (!ObjectUtils.compare(newState, sections) && typeOfDraggedElement === draggedElementType.section) {
			const tempSections: ISection[] = newState;

			if (this._isMounted) {
				this.setState({sections: tempSections});
			}
		}
	};

	private onSectionNameChange = (value: string, section: ISection) => {
		section.label = value;
		this.forceUpdate();
	};

	private dragging = (type: draggedElementType) => {
		if (this._isMounted && type !== this.state.typeOfDraggedElement) {
			this.setState({typeOfDraggedElement: type});
		}
	};

	private sectionDraggingStart = () => {
		this._previousCursorStyle = document.body.style.cursor;
		document.body.style.cursor = "grabbing";
		this.dragging(draggedElementType.section);
		disableHighlightingOnBody();
	};

	private sectionFieldDraggingStart = () => {
		this._previousCursorStyle = document.body.style.cursor;
		document.body.style.cursor = "grabbing";
		this.dragging(draggedElementType.sectionField);
		disableHighlightingOnBody();
	};

	private availableFieldDraggingStart = () => {
		this._previousCursorStyle = document.body.style.cursor;
		document.body.style.cursor = "grabbing";
		this.dragging(draggedElementType.availableField);
		disableHighlightingOnBody();
	};

	private draggingEnd = () => {
		document.body.style.cursor = this._previousCursorStyle;
		this.dragging(draggedElementType.null);
		enableHighlightingOnBody();
	};

	public override render() {
		const {feature, appState} = this.props;
		const {sections, findString, collapsedSectionsIdArray, typeOfDraggedElement} = this.state;
		const groups = appState.actions.getInheritedFeatures(feature);
		const isSaveEnabled = this.isSaveEnabled();

		return (
			<div className="LayoutSettings">
				<div className="left">
					<div className="find">
						<h3 className="label">Available fields</h3>
						<SearchField
							value={findString}
							onInput={this.onFindInput}
						/>
					</div>
					<div className="vbox unAssignedFields">
						{typeOfDraggedElement === draggedElementType.sectionField ? (
							<ReactSortable
								className="availableDropArea hbox alignCenter justifyCenter"
								list={[]}
								setList={Functions.emptyFunction}
								group={DragGroup.fields}
								filter=".empty-text"
								{...this._sortableStylingProps}
							>
								<div className="empty-text">Drop Field here</div>
							</ReactSortable>
						) : (
							groups.map((feature: XyiconFeature, index: number) => (
								<ToggleContainer
									key={index}
									title={featureTitles[feature]}
									className="group"
									open={this.shouldBeOpen(feature)}
									onToggleOpen={(value) => this.onToggle(value, feature)}
								>
									{this.renderUnassignedFields(feature)}
								</ToggleContainer>
							))
						)}
					</div>
				</div>
				<div className="right">
					<div className="hbox">
						<div className="flex_1">
							<h3 className="title">Layout</h3>
						</div>
						<Button
							label="Add New Section"
							onClick={this.onAddNewSectionClicked}
							className="secondary addNewSection"
						/>
						<Button
							label="Reset"
							onClick={this.onResetClick}
							disabled={!isSaveEnabled}
							className="secondary"
						/>
						<Button
							label="Save"
							onClick={this.onSaveClick}
							disabled={!isSaveEnabled}
							className="primary"
						/>
					</div>
					<div className={ReactUtils.cls("sections", {sectionDraggingActive: typeOfDraggedElement === draggedElementType.section})}>
						{sections.length === 0 ? (
							<div
								className="empty hbox"
								onClick={this.onAddNewSectionClicked}
							>
								<div className="vbox alignCenter">
									<SVGIcon icon="add" />
									<div>Add new section to start</div>
								</div>
							</div>
						) : (
							<ReactSortable
								list={sections}
								setList={this.onSetListOfSection}
								onStart={this.sectionDraggingStart}
								onEnd={this.draggingEnd}
								className="sections"
								group={DragGroup.sections}
								forceFallback={true}
								{...this._sortableStylingProps}
							>
								{sections.map((section, sectionIndex) => (
									<div
										className="section-container__layouts"
										key={sectionIndex}
									>
										<div className={ReactUtils.cls("header", {close: collapsedSectionsIdArray.includes(section?.id)})}>
											<div className="drag-handle">
												<SVGIcon
													icon="drag"
													classNames="drag-icon"
												/>
											</div>
											<ClickToEditInput
												value={section?.label}
												focused={section?.focused || false}
												onChange={(value) => this.onSectionNameChange(value, section)}
											/>
											<div className="flex_1" />
											<IconButton
												title="Edit Section name"
												icon="edit"
												className="edit"
												onClick={() => this.editSectionName(section)}
											/>
											<IconButton
												title="Delete Section"
												icon="delete"
												className="delete"
												onClick={() => this.onDeleteSectionClicked(sectionIndex)}
											/>
											<IconButton
												title="Collapse Section"
												icon="angle_up"
												className="collapse"
												onClick={() => this.onSectionFieldCollapse(section?.id)}
											/>
										</div>
										{!collapsedSectionsIdArray.includes(section?.id) && typeOfDraggedElement !== draggedElementType.section && (
											<div
												className={ReactUtils.cls("fields", {
													empty: section?.fields.length === 0,
												})}
											>
												<ReactSortable
													list={section?.fields}
													setList={(newState) => this.onSetListOfSectionFields(newState, sectionIndex)}
													onStart={this.sectionFieldDraggingStart}
													onEnd={this.draggingEnd}
													className="sortable hbox"
													group={DragGroup.fields}
													forceFallback={true}
													{...this._sortableStylingProps}
												>
													{section?.fields.length === 0 ? (
														<div className="empty-fields noWrap">
															Drag and drop unassigned fields in this section.
															<br />
															These fields will show up in the details panel.
														</div>
													) : (
														section?.fields.map((sectionField, fieldsInSectionIndex) => {
															const field = appState.actions.getFieldByRefId(sectionField?.id);

															if (!this.filterField(field)) {
																return null;
															}
															return (
																<div
																	key={fieldsInSectionIndex}
																	className={ReactUtils.cls("fieldCell", {noWrap: sectionField?.noWrap})}
																>
																	{this.renderField(field, true)}
																</div>
															);
														})
													)}
												</ReactSortable>
											</div>
										)}
									</div>
								))}
							</ReactSortable>
						)}
					</div>
				</div>
				<div className="bottomShadow" />
			</div>
		);
	}
}
