import {type Object3D, type InstancedMesh, type InstancedBufferGeometry} from "three";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {ImageBasedXyiconMaterial} from "../../materials/ImageBasedXyiconMaterial";
import type {TextureManager} from "../TextureManager";
import {Xyicon3D} from "../../elements3d/Xyicon3D";
import {Constants} from "../../Constants";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import type {IXyiconMinimumData, Xyicon} from "../../../../../../../data/models/Xyicon";
import type {Catalog} from "../../../../../../../data/models/Catalog";
import {MathUtils} from "../../../../../../../utils/math/MathUtils";
import type {XyiconManager} from "./XyiconManager";
import {InstancedXyiconManager} from "./InstancedXyiconManager";

export class ImageBasedXyiconManager extends InstancedXyiconManager {
	private _textureManager: TextureManager;

	constructor(spaceViewRenderer: SpaceViewRenderer, xyiconManager: XyiconManager, container: Object3D) {
		super(spaceViewRenderer, xyiconManager, container);
		this._textureManager = spaceViewRenderer.textureManager;
	}

	private updateUVOffsetAttribute(instancedMesh: InstancedMesh, uvOffset: number[]) {
		try {
			const count = (instancedMesh.geometry as InstancedBufferGeometry).instanceCount;
			const geometry = instancedMesh.geometry as InstancedBufferGeometry;

			const {uvOffset: uvOffsetAttribute} = this._attributeData;
			const uvOffsetSize = uvOffsetAttribute.size;
			const uvOffsetTypedArray = new Float32Array(count * uvOffsetSize);

			uvOffsetTypedArray.set(uvOffset);
			geometry.setAttribute(
				"uvOffset" as keyof typeof this._attributeData,
				THREEUtils.createInstancedBufferAttribute(uvOffsetTypedArray, uvOffsetSize),
			);

			instancedMesh.count = uvOffset.length / uvOffsetSize;
		} catch (error: unknown) {
			console.warn(
				"Something went wrong with updating UV offsets to xyicons. The initialization has been interrupted. Probably a new space has been started to load, while another one was still loading.",
			);
			console.warn(error);
		}
	}

	protected async createGeometryAndMaterial(textureId: number) {
		const {space} = this._spaceViewRenderer;
		const {spaceUnitsPerMeter} = space;
		const {xyiconSize} = space.type.settings;
		const standardXyiconSize = MathUtils.convertDistanceToSpaceUnit(xyiconSize.value, xyiconSize.unit, spaceUnitsPerMeter);
		const instancedBufferGeometry = await THREEUtils.loadGLBAsInstancedBufferGeometry(Constants.URLForStandardXyiconGLB);
		instancedBufferGeometry.scale(standardXyiconSize, standardXyiconSize, standardXyiconSize);
		const cameraWorldPos = this._spaceViewRenderer.activeCamera.position;

		return {
			geometry: instancedBufferGeometry,
			material: new ImageBasedXyiconMaterial(
				{
					xyiconTextureAtlas: this._textureManager.xyiconAtlases[textureId],
					envMap: this._textureManager.miscTextures.envMap,
				},
				[cameraWorldPos.x, cameraWorldPos.y, cameraWorldPos.z],
				this._spaceViewRenderer.renderer.capabilities.isWebGL2,
			),
		};
	}

	public async init(imageBasedXyicons: Xyicon[]) {
		const textureAtlases =
			this._textureManager.xyiconAtlases.length > 0
				? this._textureManager.xyiconAtlases
				: await this._textureManager.initTextureAtlases(imageBasedXyicons);

		for (const textureAtlas of textureAtlases) {
			const maxCount = imageBasedXyicons.length * 2;

			await this.createInstancedMesh(maxCount, textureAtlas.id);
		}

		const xyiconDataArray: {
			instancedMeshId: number;
			instanceId: number;
			xyicon: Xyicon;
		}[] = [];
		const uvOffsets: number[][] = [];

		for (let i = 0; i < imageBasedXyicons.length; ++i) {
			const xyicon = imageBasedXyicons[i];
			const atlasIDAndXY = await this._textureManager.getTextureAtlasIdAndXYByCatalogId(xyicon.catalogId);
			const atlasID = atlasIDAndXY.atlasId;
			const instancedMeshId = this.getInstancedMeshIndexByName(atlasID);
			const instanceId = this.instancedMeshes[instancedMeshId].count++;

			if (!uvOffsets[atlasID]) {
				uvOffsets[atlasID] = [];
			}
			uvOffsets[atlasID][instanceId * 2] = atlasIDAndXY.x;
			uvOffsets[atlasID][instanceId * 2 + 1] = atlasIDAndXY.y;

			xyiconDataArray.push({
				instancedMeshId: instancedMeshId,
				instanceId: instanceId,
				xyicon: xyicon,
			});
		}

		try {
			for (let i = 0; i < this.instancedMeshes.length; ++i) {
				this.updateUVOffsetAttribute(this.instancedMeshes[i], uvOffsets[i]);
			}

			/** We need to call this separately, because the attributes are needed first */
			const xyicon3DObjects = xyiconDataArray.map(
				(xyiconData) => new Xyicon3D(this._spaceViewRenderer, xyiconData.instancedMeshId, xyiconData.instanceId, xyiconData.xyicon),
			);

			this._xyiconManager.add(xyicon3DObjects, false);
		} catch (error: unknown) {
			console.warn("Something went wrong with updating xyicon attributes. Probably the loading process of the space has been interrupted.");
		}
	}

	public async updateCatalogTexture(catalog: Catalog) {
		const {atlasId, x, y} = await this._textureManager.getTextureAtlasIdAndXYByCatalogId(catalog.id);
		const xyiconAtlas = this._textureManager.xyiconAtlases[atlasId];

		await xyiconAtlas.putCatalogById(catalog.id, x, y);
		const instancedMeshId = this.getInstancedMeshIndexByName(atlasId);

		if (instancedMeshId > -1) {
			(this.instancedMeshes[instancedMeshId]?.material as ImageBasedXyiconMaterial).setTextureAtlas(xyiconAtlas);
		}
		this._spaceViewRenderer.needsRender = true;
	}

	public async createNecessaryDataFromXyiconModel(model: IXyiconMinimumData) {
		const atlasIDAndXY = await this._textureManager.getTextureAtlasIdAndXYByCatalogId(model.catalogId);
		let instancedMeshId = this.getInstancedMeshIndexByName(atlasIDAndXY.atlasId);

		if (instancedMeshId === -1) {
			instancedMeshId = this.instancedMeshes.length;
		}

		let instancedMesh: InstancedMesh;

		if (instancedMeshId >= this.instancedMeshes.length) {
			const instancedMesh = await this.createInstancedMesh(1, atlasIDAndXY.atlasId);

			this.updateUVOffsetAttribute(instancedMesh, []);
		} else if (atlasIDAndXY.new) {
			(this.instancedMeshes[instancedMeshId].material as ImageBasedXyiconMaterial).setTextureAtlas(
				this._textureManager.xyiconAtlases[atlasIDAndXY.atlasId],
			);
		}

		instancedMesh = this.instancedMeshes[instancedMeshId];
		const previousCount = instancedMesh.count++;
		const previousMaxCount = (instancedMesh.geometry as InstancedBufferGeometry).instanceCount;

		if (previousCount >= previousMaxCount) {
			this.instancedMeshes[instancedMeshId] = THREEUtils.cloneInstancedMeshWithLargerMaxCount(instancedMesh);
			instancedMesh = this.instancedMeshes[instancedMeshId];
		}

		const instanceId = previousCount;
		const attributes = this.initAttributesOfNewInstance(instancedMesh, instanceId);

		THREEUtils.setAttributeXY(attributes.uvOffset, instanceId, atlasIDAndXY.x, atlasIDAndXY.y);

		return {
			color: 0xffffff,
			opacity: 1,
			instancedMeshId: instancedMeshId,
			instanceId: instanceId,
		};
	}
}
