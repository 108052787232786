import styled from "styled-components";
import type {IFontStyleSettings} from "../../../data/models/ViewUtils";
import ArrowBackIcon from "../icons/arrow-back.svg?react";
import BoldIcon from "../icons/bold.svg?react";
import ItalicIcon from "../icons/italic.svg?react";
import UnderlineIcon from "../icons/underline.svg?react";
import {IconButtonV5} from "../interaction/IconButtonV5";
import {baseDistance, radius} from "../styles/styles";

interface IFontStyleContainerProps {
	readonly fontStyleSettings: IFontStyleSettings;
	readonly onIsBoldChange: (newValue: boolean) => void;
	readonly onIsItalicChange: (newValue: boolean) => void;
	readonly onIsUnderlinedChange: (newValue: boolean) => void;
	readonly onClose?: () => void;
}

export const FontStyleContainerV5 = (props: IFontStyleContainerProps) => {
	const {fontStyleSettings, onClose} = props;

	return (
		<FontStyleContainer className="hbox alignCenter">
			{onClose && (
				<IconButtonV5
					title="Back"
					onClick={onClose}
					IconComponent={ArrowBackIcon}
				/>
			)}
			<IconButtonV5
				title="Bold"
				isActive={fontStyleSettings.isBold}
				onClick={() => props.onIsBoldChange(!fontStyleSettings.isBold)}
				IconComponent={BoldIcon}
			/>
			<IconButtonV5
				title="Italic"
				isActive={fontStyleSettings.isItalic}
				onClick={() => props.onIsItalicChange(!fontStyleSettings.isItalic)}
				IconComponent={ItalicIcon}
			/>
			<IconButtonV5
				title="Underline"
				isActive={fontStyleSettings.isUnderlined}
				onClick={() => props.onIsUnderlinedChange(!fontStyleSettings.isUnderlined)}
				IconComponent={UnderlineIcon}
			/>
		</FontStyleContainer>
	);
};

const FontStyleContainer = styled.div`
	gap: ${baseDistance.sm};

	.isActive {
		padding: 1px !important;
		border-radius: ${radius.sm};
	}
`;
