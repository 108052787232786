import React, {Children, cloneElement, useState} from "react";
import type {SVGProps, FunctionComponent} from "react";
import styled from "styled-components";
import {XyiconFeature} from "../../../../generated/api/base";
import type {IButtonProps} from "../../../widgets/button/Button";
import CloseIcon from "../../icons/xmark-large.svg?react";
import {SVGIcon} from "../../../widgets/button/SVGIcon";
import type {ITabChildProps} from "../../../widgets/tab/TabChild";
import {ToggleButtonV5} from "../ToggleButtonV5";
import {IconButtonV5} from "../../interaction/IconButtonV5";
import {SearchFieldV5} from "../../input/search/SearchFieldV5";
import {ButtonV5} from "../../button/ButtonV5";
import CirclePlus from "../../icons/circle-plus.svg?react";
import {useAppStore} from "../../../../StateManager";
import {colorPalette} from "../../styles/colorPalette";
import {baseDistance} from "../../styles/styles";
import {TabViewStyled} from "./TabView.styled";

type IChild = React.ReactElement<ITabChildProps>;

interface ITabViewProps {
	readonly className?: string;
	readonly selectedTabId?: string;
	readonly onChangeSelectedTabId?: (id: string) => void;
	readonly children?: IChild[] | IChild;
	readonly icon?: string;
	readonly onClose?: () => void;
	readonly feature?: XyiconFeature;
	readonly IconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
	readonly createButtonRef?: React.RefObject<HTMLDivElement>;
	readonly isSettings?: boolean;
}

/**
 * Can be used in 2 different way:
 * - if onChangeSelectedTabId is given its an externally managed component (uses prop.selectedTabId)
 * - if not given, it uses its own state (tabId is only used as the default state)
 */

export const TabViewV5 = (props: ITabViewProps) => {
	const {
		createButtonRef,
		feature,
		IconComponent,
		children,
		className,
		icon,
		isSettings = false,
		selectedTabId,
		onClose,
		onChangeSelectedTabId,
	} = props;
	const appState = useAppStore((state) => state.appState);
	const setModulesTypeAndFieldSearchString = useAppStore((state) => state.setModulesTypeAndFieldSearchString);
	const setModulesTypeAndFieldCreating = useAppStore((state) => state.setModulesTypeAndFieldCreating);
	const modulesTypeAndFieldSearchString = useAppStore((state) => state.modulesTypeAndFieldSearchString);
	const [tabId, setTabId] = useState(selectedTabId || (Children.toArray(children)?.[0] as IChild)?.props.id || "");
	const buttons: React.ReactElement<IButtonProps>[] = [];

	const onTabChange = (id: string) => {
		setModulesTypeAndFieldSearchString("");
		if (!location.hash.includes("permissionsets")) {
			setModulesTypeAndFieldCreating(false, id);
			onChangeSelectedTabId?.(id);
		}
		onChangeSelectedTabId?.(id);
		setTabId(id);
		if (feature === XyiconFeature.SpaceEditor) {
			appState.tableComponent.current?._table.current?.scroll({left: 0});
		}
	};

	const handleOnCreateButonClick = () => {
		setModulesTypeAndFieldCreating(true, selectedTabId);
	};

	const filteredChildren = Children.toArray(children).filter((child) => !!child);
	const tab = onChangeSelectedTabId ? selectedTabId : tabId;

	const views = filteredChildren.map((child: IChild, index: number) => {
		const id = child.props.id || index.toString();
		const active = id === tab || (!tab && index === 0);
		const button = (
			<ToggleButtonV5
				key={index}
				label={child.props.label}
				title={child.props.title}
				disabled={child.props.disabled}
				value={active}
				onChange={() => {
					onTabChange(id);
				}}
				icon={child.props.icon}
				IconComponent={IconComponent}
			/>
		);

		buttons.push(button);

		if (child.props.disabled || !active) {
			return null;
		} else {
			return cloneElement(child, {active: active} as ITabChildProps);
		}
	});

	const onSearch = (value: string) => {
		setModulesTypeAndFieldSearchString(value);
	};

	return (
		<TabViewStyled
			className={`TabView ${className} tab${filteredChildren.length}`}
			$isSettings={isSettings}
		>
			<TabViewButtonContainerStyled
				className="buttons"
				$selectedTabId={selectedTabId}
				$isSettings={isSettings}
			>
				{icon && (
					<div className="featureIcon">
						<SVGIcon icon={icon} />
					</div>
				)}
				<TabViewActionBarStyled $isSettings={isSettings}>
					{buttons}
					{selectedTabId !== "layouts" && isSettings && (
						<TabViewSearchAndCreateContainerStyled>
							<SearchFieldV5
								value={modulesTypeAndFieldSearchString}
								placeholder={"Find"}
								onInput={onSearch}
								minimized={true}
							/>
							<ButtonV5
								label="Create"
								title="Create"
								onClick={handleOnCreateButonClick}
								ref={createButtonRef}
							>
								<CirclePlus />
							</ButtonV5>
						</TabViewSearchAndCreateContainerStyled>
					)}
				</TabViewActionBarStyled>
				{onClose && (
					<IconButtonV5
						IconComponent={CloseIcon}
						onClick={onClose}
					/>
				)}
			</TabViewButtonContainerStyled>
			<div className="views">{views}</div>
		</TabViewStyled>
	);
};

const TabViewButtonContainerStyled = styled.div<{$selectedTabId: string; $isSettings: boolean}>`
	gap: ${baseDistance.lg};
	padding: 20px ${baseDistance.md};
	border-bottom: ${(props) => (props.$isSettings ? `1px solid ${colorPalette.gray.c300}` : "")};
	width: ${(props) => (props.$selectedTabId === "layouts" || !props.$isSettings ? "100%" : `calc(100% - 416px)`)};
`;

const TabViewActionBarStyled = styled.div<{$isSettings: boolean}>`
	display: flex;
	align-items: center;
	gap: ${baseDistance.md};
	flex-grow: 1;
	min-height: ${(props) => (props.$isSettings ? `32px` : `24px`)};
`;

const TabViewSearchAndCreateContainerStyled = styled.div`
	display: flex;
	align-items: center;
	gap: ${baseDistance.sm};
	margin-left: auto;

	.SearchField {
		border: none;
	}
`;
