/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum FileType {
	DOC = 1,
	DOCX = 2,
	PlainText = 3,
	PDF = 4,
	XLS = 5,
	PPT = 6,
	PPTX = 7,
	XLSX = 8,
	SVG = 9,
	JPG = 10,
	PNG = 11,
	TIFF = 12,
	GIF = 13,
	BMP = 14,
	CSV = 15,
	TAR = 16,
	SH = 17,
	MOV = 18,
	HTML = 19,
	GLB = 20,
	WEBP = 21,
	ZIP = 22,
	Unknown = -1,
}
