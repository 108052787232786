import {useEffect, useState} from "react";
import {runInAction} from "mobx";
import styled from "styled-components";
import {MathUtils} from "../../../../../utils/math/MathUtils";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {TimeUtils} from "../../../../../utils/TimeUtils";
import {colorPalette} from "../../../styles/colorPalette";
import type {ExtendedToolIconKey} from "./ToolButtonV5";
import {ToolButtonV5} from "./ToolButtonV5";

interface IToolTogglerProps {
	readonly icon: ExtendedToolIconKey;
	readonly title: string;
	readonly active: boolean;
	readonly setActive: (value: boolean) => void;
	readonly notificationNumber?: number;
}

export const ToolTogglerV5 = (props: IToolTogglerProps) => {
	const {active, notificationNumber, icon, title, setActive} = props;
	const [animate, setAnimate] = useState(false);

	const onClick = () => {
		toggle();
	};

	const toggle = () => {
		setActive(!active);
	};

	useEffect(() => {
		runInAction(async () => {
			setAnimate(true);
			await TimeUtils.wait(500);
			setAnimate(false);
		});
	}, [props.notificationNumber]);

	return (
		<ToolButtonV5
			icon={icon}
			title={title}
			isActive={active}
			onClick={onClick}
		>
			{MathUtils.isValidNumber(notificationNumber) && notificationNumber !== 0 && (
				<NotificationNumberStyled className={ReactUtils.cls("notificationNumber", {animate})}>{notificationNumber}</NotificationNumberStyled>
			)}
		</ToolButtonV5>
	);
};

const NotificationNumberStyled = styled.div`
	position: absolute;
	top: -5px;
	right: -5px;
	padding: 3px 6px;
	font-size: 10px;
	background-color: ${colorPalette.primary.c500Primary};
	color: ${colorPalette.white};
	border-radius: 10px;
	transition: transform 0.2s ease-in-out;

	&.animate {
		transform: scale(1.2);
	}
`;
