import styled from "styled-components";
import {ButtonStyled, ButtonV5} from "../button/ButtonV5";
import {ReactUtils} from "../../utils/ReactUtils";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {colorPalette} from "../styles/colorPalette";
import {fontSize, radius} from "../styles/styles";

interface IToggleSwitchFieldProps<T> {
	readonly options: T[];
	readonly onChange: (value: any, index: number) => void;
	readonly render: (item: T) => string;
	readonly selectedOption: string;
}

export const ToggleOptionV5 = <T,>(props: IToggleSwitchFieldProps<T>) => {
	const {options, onChange, render, selectedOption} = props;

	const handleButtonClick = (option: any, index: number) => {
		onChange(option, index);
	};

	return (
		<ToggleOptionStyled>
			{options.map((option, index) => {
				const optionName = render(option);

				return (
					<ButtonV5
						key={index}
						onClick={() => handleButtonClick(option, index)}
						className={ReactUtils.cls("toggle-button", {active: selectedOption === optionName})}
					>
						{StringUtils.titleCase(optionName)}
					</ButtonV5>
				);
			})}
		</ToggleOptionStyled>
	);
};

const ToggleOptionStyled = styled.div`
	display: flex;
	background-color: ${colorPalette.gray.c300};
	border-radius: ${radius.sm};

	${ButtonStyled} {
		width: 32px;
		height: 24px;
		font-size: ${fontSize.sm};
		background-color: ${colorPalette.gray.c300};
		color: ${colorPalette.gray.c950};

		&.active {
			background-color: ${colorPalette.primary.c200Light};
			color: ${colorPalette.primary.c500Primary};
		}
	}
`;
