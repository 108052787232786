import * as React from "react";
import {Provider, observer} from "mobx-react";
import {configure} from "mobx";
import {NewUserRegistration} from "./ui/modules/user/NewUserRegistration";
import {ForgottenPasswordReset} from "./ui/modules/user/ForgottenPasswordReset";
import {GraphicalTools} from "./GraphicalTools";
import type {IModel} from "./data/models/Model";
import {DetailsContainer} from "./ui/modules/DetailsContainer";
import {getNewDetailedItems, onDetailsContainerClose} from "./ui/search/DetailsContainerUtils";
import {Root} from "./ui/5.0/Root";
import {TransportLayer} from "./data/TransportLayer";
import {Navigation} from "./Navigation";
import {KeyboardListener} from "./utils/interaction/key/KeyboardListener";
import {XHRLoader} from "./utils/loader/XHRLoader";
import {LoginWindow} from "./ui/windows/login/LoginWindow";
import {TopBar} from "./ui/topbar/TopBar";
import type {FeatureMap} from "./data/state/AppStateConstants";
import {AppState} from "./data/state/AppState";
import {SideBar} from "./ui/sidebar/SideBar";
import {StringUtils} from "./utils/data/string/StringUtils";
import type {IConfig} from "./IConfig";
import type {AccessTokenDetailsDto} from "./generated/api/base";
import {XyiconFeature} from "./generated/api/base";
import {PopupManager} from "./ui/modules/abstract/popups/manager/PopupManager";
import type {ReportView} from "./ui/modules/report/ReportView";
import {AppNotifications} from "./ui/notification/AppNotifications";
import {LoginWindowV5} from "./ui/5.0/login/LoginWindowV5";
import {ForgottenPasswordResetV5} from "./ui/5.0/user/ForgottenPasswordResetV5";
import {NewUserRegistrationV5} from "./ui/5.0/user/NewUserRegistrationV5";
import {AppNotificationsV5} from "./ui/5.0/popup/AppNotificationsV5";
import {NavigationEnum} from "./Enums";
import {useAppStore} from "./StateManager";
import {LoaderV5} from "./ui/5.0/loader/LoaderV5";
import {HTMLUtils} from "./utils/HTML/HTMLUtils";
import {PhotoSphereViewer} from "./ui/5.0/photosphere/PhotoSphereViewer";
import {FullscreenLoader} from "./FullscreenLoader";
import {SSOLoginFail} from "./ui/windows/SSOLogin/SSOAuthFail";

// TODO: remove this, and actually run the MobX related assignments in "actions"
configure({
	enforceActions: "never",
});

// https://stackoverflow.com/questions/12709074/how-do-you-explicitly-set-a-new-property-on-window-in-typescript
declare global {
	interface Window {
		app: App;
	}
}

interface IAppState {
	hash: string;
	wait: boolean;
	navigationOpen: boolean;
	overlayedDetailedItems: IModel[];
}

interface ISelectView {
	selectItem?: (item: any) => void;
	focusItem?: (item: any) => void; // in ModuleViewV5 the selectItem() called focusItem()
}
@observer
export class App extends React.PureComponent<any, IAppState> {
	public config: IConfig;

	private readonly _appState = new AppState(this);
	private _transport: TransportLayer;
	private _navigation: Navigation;
	private _timeoutId: number;
	private _graphicalTools: GraphicalTools;
	private readonly _popups = React.createRef<PopupManager>();
	private readonly _modalContainer = React.createRef<HTMLDivElement>();
	private readonly _topBarRef = React.createRef<TopBar>();
	public readonly notificationContainer = React.createRef<AppNotifications>();
	public readonly moduleViews: FeatureMap<ISelectView> = {};
	public reportView: ReportView; // TODO: maybe V5 needs it also

	private _activeView = {
		component: undefined as React.ComponentClass | React.FunctionComponent,
		instance: undefined as React.JSX.Element,
		param1: undefined as string,
		param2: undefined as string,
	};

	private _sideBar = React.createRef<HTMLDivElement>();

	constructor(props: null) {
		super(props);

		this.state = {
			wait: true,
			navigationOpen: false,
			hash: "#auth/login",
			overlayedDetailedItems: [],
		};

		window.app = this;
	}

	// This method get invoked when SSO login is successful
	// It receives a base64 encoded string as a message
	// We need to decode this string and parse it to get the necessary data
	private parseUrlParams(queryString: string) {
		if (!queryString) {
			return {};
		}
		// Decoding the base64 encoded string
		queryString = atob(queryString);
		const params: AccessTokenDetailsDto = {};
		const regex = /([^&]+)=([^&]+)/g; // Regex to capture key-value pairs
		let match: RegExpExecArray;

		while ((match = regex.exec(queryString)) !== null) {
			const key = decodeURIComponent(match[1])
				.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())
				.replace(/([a-z])([Ii][Dd])$/, (_, letter1) => `${letter1}ID`)
				.replace(/Sso/, "SSO"); // Convert to camelCase only ID fields
			let value: any = decodeURIComponent(match[2]);
			// Convert specific values to the appropriate types
			if (/^\d+$/.test(value)) {
				value = parseInt(value, 10);
			} else if (value === "True" || value === "False") {
				value = value === "True";
			}

			(params as any)[key] = value;
		}
		return params;
	}

	private async init() {
		XHRLoader.defaultConfig.json = true;

		this.config = await XHRLoader.loadAsync({url: "src/config/config.json"});

		this._transport = new TransportLayer(this._appState, this.config);
		this._graphicalTools = new GraphicalTools(this);
		this._navigation = new Navigation(this._transport);
		this._transport.init();

		KeyboardListener.getInstance();
		document.body.addEventListener("paste", this.onPaste);
		window.addEventListener("hashchange", this.onHashChange);

		this._transport.signals.error.add(this.onError);
		this._transport.signals.switchOrganization.add(this.onAfterOrganizationSwitch);

		if (this.state.hash.includes("#auth/ssocallback")) {
			const queryString = this.state.hash.split("?")[1];

			let queryObject: {isSuccess: boolean; message: string} = {isSuccess: false, message: ""};

			queryString.replace(/([^=&]+)=([^&]*)/g, (match, key, value) => {
				const decodedKey = decodeURIComponent(key);
				const decodedValue = decodeURIComponent(value);

				if (decodedKey === "isSuccess") {
					queryObject[decodedKey] = decodedValue === "true"; // Convert string to boolean
				} else if (decodedKey === "message") {
					queryObject[decodedKey] = decodedValue;
				}
				return match;
			});

			const {isSuccess, message} = queryObject;

			if (!isSuccess) {
				// When the isSuccess is false it receives a normal message
				this._navigation.goToSSOLoginFailed(isSuccess, message);
			} else {
				// When the isSuccess is true it receives the message as a base64 encoded string
				const ssoLoginData = this.parseUrlParams(message);
				const results = await this._transport.services.auth.loginWithSSO(ssoLoginData);
				if (results.error) {
					this._navigation.goToLogin();
				}
				await this._navigation.redirectAfterLogin();
				this._transport.services.auth.loadSecondaryList();
			}
		} else {
			const autoLogin: AccessTokenDetailsDto | {error: string} = await this._transport.services.auth.tryAutoLogin();
			if ((autoLogin as {error: string}).error) {
				if (window.location.href.includes("auth/new_user") || window.location.href.includes("auth/forgotten_password")) {
					this.setState({
						hash: window.location.hash,
					});
				} else {
					this._navigation.goToLogin();
				}
			} else {
				const hash = await this._navigation.redirectAfterLogin();
				if (hash) {
					this.setState({
						hash: hash,
					});
				}
				// const pageUrl: NavigationEnum = hash?.split("/")[1] as NavigationEnum;
				this._transport.services.auth.loadSecondaryList();
			}
		}

		this.setState({wait: false});
		if (this.transport.services.localStorage.get("isActivePortfolioDeleted")) {
			this._appState.actions.goToPortfolios();
			this.transport.services.localStorage.remove("isActivePortfolioDeleted");
		}
	}

	private onError = async (xhr: XHRLoader) => {
		this._appState.lastError = "Session expired, please log in again.";
		await this._transport.services.auth.logout();
	};

	private onAfterOrganizationSwitch = () => {
		// Reload to make sure all data is reloaded again with the correct (new) organization
		this._navigation.reload();
	};

	private onCloseSideBar = (event: MouseEvent) => {
		const sideBar = this._sideBar.current;

		if (sideBar) {
			if (!sideBar.contains(event.target as HTMLElement)) {
				// Clicked outside of SideBar -> close it
				window.removeEventListener("click", this.onCloseSideBar);
				this.setState({navigationOpen: false});
			}
		}
	};

	private onHashChange = () => {
		if (this._transport.appState.user || location.hash.indexOf("#auth") > -1) {
			this.setState({hash: location.hash});
		} else {
			// No user -> go back to login
			this._transport.services.auth.cleanAuth();
			this._navigation.goToLogin();
		}
	};

	protected onPaste = (event: ClipboardEvent) => {
		if (event.clipboardData?.files.length > 0) {
			this.loadFiles(Array.from(event.clipboardData.files));
		}
	};

	public loadFiles(files: File[]) {
		// TODO ?
	}

	private async updateSpace(menu: string, param1: string) {
		if (menu === "space" && param1) {
			await this._transport.services.feature.refreshList(XyiconFeature.Space);
			const space = this._appState.actions.getSpaceById(param1);

			if (space) {
				if (this._appState.space?.id === space.id) {
					return;
				} else {
					this._appState.space = space;
				}
			} else {
				let navEnum = NavigationEnum.NAV_PORTFOLIOS;

				if (this._appState.currentUIVersion === "5.0") {
					navEnum = NavigationEnum.NAV_SPACES;

					if (this._appState.lists[XyiconFeature.Space].array.length === 0) {
						navEnum = NavigationEnum.NAV_BLANK;
					}
				}

				this._navigation.goApp(navEnum);
			}
		} else if (this._appState.space != null) {
			this._appState.space = null;
		}
	}

	private createView(hash: string) {
		// /app/:menu/:param1?

		const match = StringUtils.findMatchForHash<{menu: string; param1: string; param2: string}>(hash, "/app/:menu/:param1?/:param2?");

		if (match.matches) {
			const params = match.params;
			//const params = props.match.params;
			const selectedMenu = params.menu as NavigationEnum;

			requestAnimationFrame(() => {
				// Shouldn't update value of observable inside a "render" function
				this._appState.selectedMenu = selectedMenu;
			});
			const Component = this._navigation.getComponent(selectedMenu);

			// This is not super nice, but we're in the render function so we can't modify state directly.
			// We could put this code outside of the render function (
			clearTimeout(this._timeoutId);
			this._timeoutId = window.setTimeout(() => {
				this.updateSpace(params.menu, params.param1);
			}, 100);

			if (Component) {
				if (this._activeView.component !== Component || this._activeView.param1 !== params.param1 || this._activeView.param2 !== params.param2) {
					const instance = (
						<Component
							param1={params.param1}
							param2={params.param2}
							spaceViewRenderer={this.spaceViewRenderer}
						/>
					);

					this._activeView.component = Component;
					this._activeView.param1 = params.param1;
					this._activeView.instance = instance;

					return instance;
				} else {
					return this._activeView.instance;
				}
			}
		}

		return <div />;
	}

	private onToggleNavigation = () => {
		this.setState((prevState) => ({navigationOpen: !prevState.navigationOpen}));
	};

	public onDetailsClick = (item: IModel) => {
		this.setState((s) => ({
			overlayedDetailedItems: getNewDetailedItems(s.overlayedDetailedItems, item),
		}));
	};

	public onDetailsContainerClose = (closeAll: boolean = false) => {
		onDetailsContainerClose(this, closeAll);
	};

	private closeDetailsPanel = () => {
		this.onDetailsContainerClose(true);
	};

	// private onToggleTheme = () =>
	// {
	// 	const theme = this._appState.theme === "light" ? "dark" : "light";

	// 	this._appState.theme = theme;

	// 	document.body.classList.add("color-theme-in-transition");
	// 	document.body.setAttribute("data-theme", theme);
	// 	window.setTimeout(() =>
	// 	{
	// 		document.body.classList.remove("color-theme-in-transition");
	// 	}, 1000);

	// 	// this.setState(prevState => {
	// 	// 	const theme = prevState.theme === "light" ? "dark" : "light";
	// 	// 	document.body.classList.add("color-theme-in-transition");
	// 	// 	this.setState({ theme });
	// 	// 	document.body.setAttribute("data-theme", theme);
	// 	// 	window.setTimeout(() => {
	// 	// 		document.body.classList.remove("color-theme-in-transition");
	// 	// 	}, 1000);
	// 	// });
	// };

	public get graphicalTools() {
		return this._graphicalTools;
	}

	public get transport() {
		return this._transport;
	}

	public get navigation() {
		return this._navigation;
	}

	public get spaceViewRenderer() {
		return this._graphicalTools.spaceViewRenderer;
	}

	public get popups() {
		return this._popups.current;
	}

	public get modalContainer() {
		return this._modalContainer.current;
	}

	private renderView(): React.JSX.Element {
		if (this._appState.currentUIVersion === "5.0") {
			document.body.classList.remove("V4");
			document.body.classList.add("V5");
		} else {
			document.body.classList.remove("V5");
			document.body.classList.add("V4");
		}

		const hash = (this.state.hash || "").substring(1);
		if (hash.includes("auth/login")) {
			if (this._appState.currentUIVersion === "5.0") {
				return <LoginWindowV5 />;
			} else {
				return <LoginWindow />;
			}
		} else if (hash.includes("auth/sso-login-failed")) {
			return <SSOLoginFail />;
		} else if (hash.includes("auth/new_user")) {
			return (
				<>
					<div
						id={HTMLUtils.modalContainerId}
						ref={this._modalContainer}
					/>
					<div className="page">
						{this._appState.currentUIVersion === "5.0" ? (
							<>
								<AppNotificationsV5 ref={this.notificationContainer as unknown as React.RefObject<AppNotificationsV5>} />
								<NewUserRegistrationV5 />
							</>
						) : (
							<>
								<AppNotifications ref={this.notificationContainer} />
								<NewUserRegistration />
							</>
						)}
					</div>
				</>
			);
		} else if (hash.includes("auth/forgotten_password")) {
			return (
				<>
					<div
						id={HTMLUtils.modalContainerId}
						ref={this._modalContainer}
					/>
					<div className="page">
						{this._appState.currentUIVersion === "5.0" ? (
							<>
								<AppNotificationsV5 ref={this.notificationContainer as unknown as React.RefObject<AppNotificationsV5>} />
								<ForgottenPasswordResetV5 />
							</>
						) : (
							<>
								<AppNotifications ref={this.notificationContainer} />
								<ForgottenPasswordReset />
							</>
						)}
					</div>
				</>
			);
		} else {
			if (this._appState.currentUIVersion === "5.0") {
				return (
					<Root
						notificationContainer={this.notificationContainer}
						modalContainer={this._modalContainer}
						view={hash.includes("app/") && this.createView(hash)}
						overlayedDetailedItems={this.state.overlayedDetailedItems}
						closeDetailsContainer={this.onDetailsContainerClose}
						closeWideSearchPanel={this._topBarRef.current?.mainSearchRef.current?.closeSearchInput}
					/>
				);
			} else {
				const {activePhotoSphereMaybe} = this._graphicalTools.photoSphereManager;

				return (
					<>
						<AppNotifications ref={this.notificationContainer} />
						<div
							id={HTMLUtils.modalContainerId}
							ref={this._modalContainer}
						/>
						<TopBar
							ref={this._topBarRef}
							navigationOpen={this.state.navigationOpen}
							onToggleNavigation={this.onToggleNavigation}
						/>
						{activePhotoSphereMaybe && <PhotoSphereViewer activePhotoSphere={activePhotoSphereMaybe} />}
						<div className="main">
							<SideBar
								divRef={this._sideBar}
								open={this.state.navigationOpen}
								onToggleNavigation={this.onToggleNavigation}
							/>
							<article className="appContent">{hash.includes("app/") && this.createView(hash)}</article>
						</div>
						<DetailsContainer
							items={this.state.overlayedDetailedItems}
							onClose={this.onDetailsContainerClose}
							closeWideSearchPanel={this._topBarRef.current?.mainSearchRef.current?.closeSearchInput}
						/>
						<PopupManager
							ref={this._popups}
							keyboardListener={KeyboardListener.getInstance()}
						/>
					</>
				);
			}
		}
	}

	public override async componentDidMount() {
		document.querySelectorAll(".hardcodedBlack").forEach((item: SVGElement) => (item.style.fill = "black"));
		this.setState({hash: location.hash});
		await this.init();
		this._navigation.signals.navigate.add(this.closeDetailsPanel);
	}

	public override componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<IAppState>, snapshot?: any): void {
		if (this.state.navigationOpen && !prevState.navigationOpen) {
			setTimeout(() => {
				window.addEventListener("click", this.onCloseSideBar);
			});
		} else if (!this.state.navigationOpen && prevState.navigationOpen) {
			window.removeEventListener("click", this.onCloseSideBar);
		}
	}

	public override componentWillUnmount() {
		this._navigation?.signals.navigate.remove(this.closeDetailsPanel);
	}

	public override render() {
		const hash = (this.state.hash || "").substring(1);

		if (!hash || this.state.wait) {
			return (
				<div className="preLoadingPage">
					<LoaderV5 />
				</div>
			);
		}

		return (
			<Provider
				navigation={this._navigation}
				transport={this._transport}
				appState={this._appState}
				app={this}
			>
				<AppStoreInitializer appState={this._appState} />
				{this.renderView()}
				<FullscreenLoader text={this._appState?.fullscreenLoaderText} />
			</Provider>
		);
	}
}

interface IAppStoreInitializer {
	readonly appState: AppState;
}

const AppStoreInitializer = (props: IAppStoreInitializer) => {
	const appState = useAppStore((state) => state.appState);
	const setAppState = useAppStore((state) => state.setAppState);

	if (!appState) {
		setAppState(props.appState);
	}

	return <></>;
};
