import {useEffect, useRef, useState} from "react";
import styled, {css} from "styled-components";
import type {Boundary} from "../../../data/models/Boundary";
import type {BoundarySpaceMap} from "../../../data/models/BoundarySpaceMap";
import type {Catalog} from "../../../data/models/Catalog";
import type {Xyicon} from "../../../data/models/Xyicon";
import {XyiconFeature, Permission} from "../../../generated/api/base";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import {Functions} from "../../../utils/function/Functions";
import type {Pointer} from "../../../utils/interaction/Pointer";
import {PointerDetectorReact} from "../../interaction/PointerDetectorReact";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {CardLayoutToolTip} from "../../modules/space/spaceeditor/ui/toolbar/CardLayoutToolTip";
import {ReactUtils} from "../../utils/ReactUtils";
import {SVGIcon} from "../../widgets/button/SVGIcon";
import {useAppStore} from "../../../StateManager";
import {baseDistance, ELLIPSIS, FlexCenterStyle, fontSize} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";
import DogearFavIcon from "../../5.0/icons/dogear-fav.svg?react";
import {InfoBubbleV5} from "../button/InfoBubbleV5";
import GripDotsVerticalIcon from "../icons/grip-dots-vertical.svg?react";

interface ISpaceItemV5Props {
	readonly item: Catalog | Xyicon | Boundary | BoundarySpaceMap;
	readonly queryString: string;
	readonly hideDragHandle?: boolean; // If no need for drag handle anywhere, this can be removed
	readonly onPointerDown?: (pointer: Pointer) => void;
	readonly onPointerMove?: (pointer: Pointer) => void;
	readonly onPointerUp?: (pointer: Pointer) => void;
}

export const SpaceItemV5 = (props: ISpaceItemV5Props) => {
	const {
		item,
		queryString,
		hideDragHandle,
		onPointerDown = Functions.emptyFunction,
		onPointerMove = Functions.emptyFunction,
		onPointerUp = Functions.emptyFunction,
	} = props;
	const appState = useAppStore((state) => state.appState);
	const _parent = useRef<HTMLDivElement>();
	const _floating = useRef<HTMLDivElement>();
	const _timeOutId = useRef<number>(-1);

	const [isToolTipOpen, setIsToolTipOpen] = useState<boolean>(false);
	const [toolTipTransform, setToolTipTransform] = useState<TransformObj | null>(null);

	useEffect(() => {
		if (_parent.current && _floating.current) {
			setToolTipTransform(
				DomUtils.getFixedFloatingElementPosition(_parent.current, _floating.current, VerticalAlignment.topOuter, HorizontalAlignment.center),
			);
		}
	}, [isToolTipOpen]);

	const getEmbeddedCounter = () => {
		if (item.ownFeature === XyiconFeature.Xyicon) {
			const embeddedNumber = item.embeddedXyicons.length;

			if (embeddedNumber > 0) {
				return <div className="embeddedCounter">{embeddedNumber}</div>;
			}
		}

		return null;
	};

	const getFavoriteIcon = () => {
		if (item.ownFeature === XyiconFeature.XyiconCatalog && item.isFavorite) {
			return <DogearFavIcon style={{width: 24, position: "absolute", top: -4, left: -1}} />;
		}

		return null;
	};

	const openTooltip = () => {
		clearTimeout(_timeOutId.current);
		_timeOutId.current = window.setTimeout(() => setIsToolTipOpen(true), 1000);
	};

	const closeTooltip = () => {
		clearTimeout(_timeOutId.current);
		setIsToolTipOpen(false);
	};

	const renderCustomRow = (data: {key: string; value: string}) => {
		let html = "";

		if (data.value !== "") {
			html = data.key + (data.key !== "" ? ": " : "") + StringUtils.regexHighlight(data.value, queryString);
		}

		return (
			<div
				className="typeName"
				dangerouslySetInnerHTML={{__html: html}}
			/>
		);
	};

	const userXyiconPermission = () => {
		return appState.actions.getModuleTypePermission(item.typeId, XyiconFeature.Xyicon);
	};

	const hasSpaceItemV5Permission = () => {
		return item.ownFeature === XyiconFeature.Boundary || userXyiconPermission() > Permission.View;
	};

	const rows = appState.actions.getRowsForCardLayout(queryString, item);
	const inlineStyle: React.CSSProperties = {
		position: "absolute",
		left: "0",
		top: "0",
		transform: toolTipTransform?.translate,
		zIndex: 999,
		visibility: toolTipTransform?.translate ? "visible" : "hidden",
	};

	const isExternalXyicon = item.id && item.ownFeature === XyiconFeature.Xyicon && item.isExternal;

	return (
		<PointerDetectorReact
			onDown={hasSpaceItemV5Permission && onPointerDown}
			onMove={hasSpaceItemV5Permission && onPointerMove}
			onUp={hasSpaceItemV5Permission && onPointerUp}
		>
			<SpaceItemStyled
				data-id={item.id}
				ref={_parent}
				onMouseOver={openTooltip}
				onMouseLeave={closeTooltip}
			>
				{!hideDragHandle && !isExternalXyicon && <GripDotsVerticalIcon className="gripDots" />}
				<ThumbnailContainerStyled>
					<ThumbnailStyled
						style={{transform: (item as Xyicon).backgroundTransform ?? ""}}
						$backgroundImage={`url('${item.thumbnail}')`}
					/>
					{getEmbeddedCounter()}
					{getFavoriteIcon()}
				</ThumbnailContainerStyled>
				<div className="description vbox">
					<div className="typeName">{renderCustomRow(rows[0])}</div>
					{renderCustomRow(rows[1])}
					{renderCustomRow(rows[2])}
				</div>
				{isToolTipOpen && (
					<DomPortal destination={appState.app.modalContainer}>
						{hasSpaceItemV5Permission() ? (
							<CardLayoutToolTip
								item={item}
								divRef={_floating}
								style={inlineStyle}
								className={ReactUtils.cls({left: toolTipTransform?.horizontal === HorizontalAlignment.left})}
								queryString={queryString}
							/>
						) : (
							<InfoBubbleV5
								divRef={_floating}
								content={
									<>
										<SVGIcon icon="locked" />
										<div>You do not have permission to use this catalog item.</div>
									</>
								}
								style={inlineStyle}
								className="SpaceItemV5"
							/>
						)}
					</DomPortal>
				)}
			</SpaceItemStyled>
		</PointerDetectorReact>
	);
};

export const SpaceItemStyled = styled.div`
	${FlexCenterStyle};
	cursor: pointer;
	width: 100%;

	.description {
		line-height: 16px;
		max-width: calc(100% - 65px);

		> div,
		.typeName {
			width: 100%;
			color: ${colorPalette.gray.c500Primary};
			font-size: ${fontSize.xs};
			${ELLIPSIS};

			&:nth-child(1) {
				color: var(--icon);
				font-weight: initial;
			}

			&:first-child {
				font-size: ${fontSize.md};
				color: ${colorPalette.gray.c900};
			}
		}
	}

	.gripDots {
		width: 16px;
		min-width: 16px;
		opacity: 0;
		transition: ease-in-out opacity 0.2s;
	}

	&:hover {
		.gripDots {
			opacity: 1;
		}
	}
`;

const ThumbnailContainerStyled = styled.div`
	width: 40px;
	height: 40px;
	min-width: 40px;
	min-height: 40px;
	position: relative;
	margin-right: ${baseDistance.sm};

	.dogear {
		position: absolute;
		top: -2px;
		fill: #f2c010;
	}

	.embeddedCounter {
		position: absolute;
		top: -4px;
		right: -5px;
		background-color: #37474f;
		color: ${colorPalette.white};
		border: 1px solid ${colorPalette.white};
		padding: 3px;
		border-radius: 100%;
		min-width: 20px;
		height: 20px;
		font-size: x-small;
		text-align: center;
	}
`;

const ThumbnailStyled = styled.div<{$backgroundImage: string}>`
	width: 100%;
	height: 100%;
	${(props) => {
		return css`
			background-image: ${props.$backgroundImage};
			background-size: contain;
		`;
	}};
	background-repeat: no-repeat;
`;
