import {Observer} from "mobx-react";
import styled from "styled-components";
import type {RefObject} from "react";
import type {IModulePanelProps} from "../ModuleViewV5";
import type {View} from "../../../../data/models/View";
import {XyiconFeature} from "../../../../generated/api/base";
import type {IViewColumn} from "../../../../data/models/ViewUtils";
import {SaveToViewButtonV5} from "../view/SaveToViewButtonV5";
import {PopupV5} from "../../popup/PopupV5";
import {ButtonV5} from "../../button/ButtonV5";
import {ButtonContainerStyled} from "../../modules/report/create/wizard/ReportWizardV5.style";
import {HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {fontSize, fontWeight} from "../../styles/styles";
import {EditViewColumnsV5} from "./EditViewColumnsV5";

interface IManageColumnsPanelProps extends IModulePanelProps {
	readonly view: View;
	readonly feature: XyiconFeature;
	readonly onClose: () => void;
	readonly parentRef?: RefObject<HTMLDivElement>;
}

export const ManageColumnsPanelV5 = (props: IManageColumnsPanelProps) => {
	const {view, feature, onClose, parentRef} = props;
	const _columns = view.getColumnsToCompareWithWidth();

	const isResetButtonEnabled = () => {
		const savedColumns = view.getColumnsToCompareWithWidth();

		return JSON.stringify(_columns) !== JSON.stringify(savedColumns);
	};

	const onResetClick = () => {
		if (view.itemFeature === XyiconFeature.SpaceEditor) {
			view.setColumns(_columns[feature as XyiconFeature.Xyicon | XyiconFeature.Boundary] as IViewColumn[], feature);
		} else {
			view.setColumns(_columns as IViewColumn[], feature);
		}
	};

	return (
		<Observer>
			{() => {
				return (
					<PopupV5
						onClose={onClose}
						label="Manage Columns"
						className="ManageColumns"
						width="616px"
						height="524px"
						parentRef={parentRef.current}
						noButtons={true}
						verticalAlignment={VerticalAlignment.bottomOuter}
						horizontalAlignment={HorizontalAlignment.left}
						offsetX={0}
						offsetY={8}
						centerOnScreen={parentRef.current === null}
					>
						<ManageColumnsPanelStyled className="ManageColumnsPanel CreatePortfolioPanel SidePanel">
							<div className="sidePanelButtons">
								<EditViewColumnsV5
									title=""
									view={view}
									feature={feature}
								/>
								<ButtonContainerStyled>
									<ButtonV5
										className="secondary"
										type="secondary"
										label="Reset"
										title="Reset"
										onClick={onResetClick}
										disabled={!isResetButtonEnabled()}
									/>
									<SaveToViewButtonV5
										feature={view.itemFeature}
										viewChangeType="columns"
										manageColumns={true}
									/>
								</ButtonContainerStyled>
							</div>
						</ManageColumnsPanelStyled>
					</PopupV5>
				);
			}}
		</Observer>
	);
};

const ManageColumnsPanelStyled = styled.div`
	.manage-cols-box {
		grid-template-columns: 248px 28px 248px 28px;
		display: grid;
		grid-gap: 10px;
	}

	.ColumnEditor {
		max-height: calc(100% - 130px);

		.manage-cols-box {
			height: inherit;

			.col {
				max-height: 450px;

				.border {
					.views {
						height: calc(100% - 50px);

						.DetailsTab {
							padding: 0;
						}
					}

					.buttons {
						padding: 0;

						.button {
							width: 100%;
						}

						.tabLabel {
							font-size: ${fontSize.lg};
							font-weight: ${fontWeight.normal};
						}
					}
				}
			}
		}
	}
`;
