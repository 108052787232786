import {VectorUtils} from "../../utils/VectorUtils";
import type {PhotoSphere} from "../PhotoSphere";
import type {PhotoSphereManager} from "../PhotoSphereManager";
import {Signal} from "../../utils/signal/Signal";
import {MathUtils} from "../../utils/math/MathUtils";
import {Constants} from "../../ui/modules/space/spaceeditor/logic3d/Constants";
import {RayCaster} from "../utils/RayCaster";
import {HTMLUtils} from "../../utils/HTML/HTMLUtils";
import type {ITransition} from "../PhotoSphereTypes";
import {createPhotoSphereFromMarkup} from "../PhotoSphereUtils";
import type {Markup} from "../../data/models/Markup";
import {ColorUtils} from "../../utils/ColorUtils";
import {CSS3DSprite} from "./CSS3DSprite";

//
// Represents a transition from a given photosphere (A) to another (B).
// If you click this, there will be a transition effect from A to B
//
export class HotSpot {
	private readonly _photoSphereManager: PhotoSphereManager;
	private readonly _transition: ITransition;
	private readonly _photoSphere: PhotoSphere;
	private _element: HTMLDivElement;
	private _thumbnail: HTMLImageElement;
	private _labelContainer: HTMLDivElement;
	private _label: HTMLDivElement;
	private _object3D: CSS3DSprite;
	private _pointer: {
		startX: number;
		startY: number;
		clientX: number;
		clientY: number;
		hasHotSpotMoved: boolean;
		startCursorToObject: number[];
		triggerClickOnPointerUp: boolean;
		downTimeStamp: number;
	};

	private readonly _desktopScale = 0.0011;
	private readonly _mobileScale = this._desktopScale * 1.35;

	private _timeoutId: number = -1;
	private _animationFrameId: number = -1;

	public signals = {
		click: Signal.create<null>(),
	};

	constructor(photoSphereManager: PhotoSphereManager, transition: ITransition) {
		this._photoSphereManager = photoSphereManager;
		this._transition = transition;

		const markup = this.photoSphereSceneManager.spaceViewRenderer.markupManager.getItemById(this._transition.toPhotoSphereId);

		if (markup?.modelData) {
			this._photoSphere = createPhotoSphereFromMarkup(markup?.modelData as Markup);
		} else {
			console.warn("Markup for photo360 doesn't exist?");
		}
	}

	public createObject3D() {
		this._element = document.createElement("div");
		this._element.className = "photoSphereHotSpot";

		//this._thumbnail = await ImageUtils.getNewImageElement(this._photoSphere.thumbnail);
		this._thumbnail = document.createElement("img");
		//this._thumbnail.setAttribute("crossorigin", "anonymous");
		this._thumbnail.src = this._photoSphere.thumbnail;
		this._thumbnail.classList.add("thumbnail");
		this._element.appendChild(this._thumbnail);

		// Contains the label and the icon
		this._labelContainer = document.createElement("div");
		this._labelContainer.classList.add("vbox");
		this._labelContainer.classList.add("labelContainer");

		const icon = document.createElement("div");
		icon.classList.add("icon");
		const iconColor = this._photoSphere.itemModel.color;
		if (iconColor) {
			icon.style.backgroundColor = `#${iconColor}`;
		}
		this._labelContainer.appendChild(icon);

		if (this._photoSphere.name) {
			this._label = document.createElement("div");
			this._label.textContent = this._photoSphere.name;
			this._label.classList.add("label");
			const labelColor = this._photoSphere.itemModel.text.fontColor;
			if (labelColor) {
				this._label.style.color = ColorUtils.hex2rgb(labelColor.hex, 1 - labelColor.transparency) as string;
			}
			this._labelContainer.appendChild(this._label);
		}

		this._labelContainer.addEventListener("mousemove", this.showThumbnail);
		this._labelContainer.addEventListener("mouseleave", this.hideThumbnail);
		this._element.appendChild(this._labelContainer);
		this._labelContainer.addEventListener("mousedown", this.onMouseDown);
		this._labelContainer.addEventListener("touchstart", this.onTouchStart);
		window.addEventListener("mousemove", this.onMouseMove);
		window.addEventListener("touchmove", this.onTouchMove);
		window.addEventListener("mouseup", this.onPointerUp);
		window.addEventListener("touchend", this.onPointerUp);
		window.addEventListener("touchcancel", this.onPointerUp);

		const position = MathUtils.getSphereSurfacePointFromUV(this.longitude, Math.PI / 2 - this.latitude);

		this._object3D = new CSS3DSprite(this._element);

		// Workaround for safari bug -> If I set the scale instead of the position, the render is full of bugs (wrong positions, flickering, "z fighting", etc..)
		this._object3D.setOriginalPosition(position);

		return this._object3D;
	}

	private showThumbnail = () => {
		clearTimeout(this._timeoutId);
		cancelAnimationFrame(this._animationFrameId);

		this._thumbnail.style.display = "block";
		this._animationFrameId = window.requestAnimationFrame(() => {
			this._thumbnail.style.opacity = "1";
		});
	};

	private hideThumbnail = () => {
		clearTimeout(this._timeoutId);
		cancelAnimationFrame(this._animationFrameId);

		requestAnimationFrame(() => {
			this._thumbnail.style.opacity = "";
		});

		this._timeoutId = window.setTimeout(() => {
			this._thumbnail.style.display = "";
		}, 300);
	};

	private getUVFromClientXY(clientX: number, clientY: number) {
		const sceneManager = this.photoSphereSceneManager;
		const viewBox = sceneManager.viewBox;
		const cursor = MathUtils.getNormalizedCursorCoords(clientX, clientY, viewBox);
		const forward = sceneManager.forward;
		const hitPoint = RayCaster.intersectSphereByForwardAndCursor(forward, cursor, viewBox[0] / viewBox[1], sceneManager.userZoomFactor.value);

		return MathUtils.getUVFromSphereSufracePoint(hitPoint);
	}

	private onMouseDown = (event: MouseEvent) => {
		if (event.button === Constants.MOUSE_BUTTON.LEFT) {
			this.onPointerDown(event.clientX, event.clientY, event);
		}
	};

	private onTouchStart = (event: TouchEvent) => {
		event.preventDefault(); // helps preventing default pinch-to-zoom behaviour on iOS 12 safari

		if (event.touches.length === 1) {
			this.onPointerDown(event.touches[0].clientX, event.touches[0].clientY, event);
		} else {
			this.onPointerUp();
		}
	};

	private onPointerDown(clientX: number, clientY: number, event: MouseEvent | TouchEvent) {
		event.preventDefault();

		if (!this._pointer) {
			this.photoSphereSceneManager.signals.onBeforeRender.add(this.onBeforeRender);

			// if (this._photoSphereManager.app.props.mode === "edit")
			// {
			// 	event.stopImmediatePropagation();
			// }

			const uv = this.getUVFromClientXY(clientX, clientY);
			const worldPos = MathUtils.getSphereSurfacePointFromUV((uv[0] + 0.5) * 2 * Math.PI, (1 - uv[1]) * Math.PI);

			this._pointer = {
				startX: clientX,
				startY: clientY,
				clientX: clientX,
				clientY: clientY,
				hasHotSpotMoved: false,
				startCursorToObject: VectorUtils.subVectors(this._object3D.originalPosition, worldPos),
				triggerClickOnPointerUp: true,
				downTimeStamp: performance.now(),
			};
		}
	}

	private onMouseMove = (event: MouseEvent) => {
		this.onPointerMove(event.clientX, event.clientY, event);
	};

	private onTouchMove = (event: TouchEvent) => {
		event.preventDefault(); // helps preventing default pinch-to-zoom behaviour on iOS 12 safari
		if (event.touches.length === 1) {
			this.onPointerMove(event.touches[0].clientX, event.touches[0].clientY, event);
		} else {
			this.onPointerUp();
		}
	};

	private onPointerMove(clientX: number, clientY: number, event: MouseEvent | TouchEvent) {
		if (this._pointer) {
			this._pointer.clientX = clientX;
			this._pointer.clientY = clientY;
		}
	}

	private onBeforeRender = () => {
		if (this._pointer) {
			const {clientX, clientY} = this._pointer;
			const currentXToStartX = this._pointer.startX - clientX;
			const currentYToStartY = this._pointer.startY - clientY;

			if (Constants.clickThreshold.movement < Math.abs(currentXToStartX) || Constants.clickThreshold.movement < Math.abs(currentYToStartY)) {
				this._pointer.triggerClickOnPointerUp = false;
			}
		}
	};

	private onPointerUp = () => {
		if (this._pointer) {
			this.photoSphereSceneManager.signals.onBeforeRender.remove(this.onBeforeRender);
			this.photoSphereSceneManager.cameraControls.stopRotating();

			const timeStamp = performance.now();

			if (timeStamp - this._pointer.downTimeStamp > Constants.clickThreshold.time) {
				this._pointer.triggerClickOnPointerUp = false;
			}

			if (this._pointer.triggerClickOnPointerUp) {
				this.signals.click.dispatch(null);
			}
		}

		this._pointer = null;
	};

	public resize(factor: number = 1) {
		const photoSphereSceneManager = this.photoSphereSceneManager;
		const viewBox = photoSphereSceneManager.viewBox;
		const toMobile = viewBox[0] < 1000;
		const scale = factor * (toMobile ? this._mobileScale : this._desktopScale); // divide by this._userZoomFactor if you want them to be the same size on the screen
		const position = VectorUtils.multiplyByScalar(this._object3D.originalPosition, 1 / scale);
		this._object3D.position.set(position[0], position[1], position[2]);
	}

	public destroy() {
		if (this._element) {
			this.signals.click.removeAll();
			if (this._object3D?.parent) {
				this._object3D.parent.remove(this._object3D);
			}
			this._labelContainer.removeEventListener("mousemove", this.showThumbnail);
			this._labelContainer.removeEventListener("mouseleave", this.hideThumbnail);
			this._labelContainer.removeEventListener("mousedown", this.onMouseDown);
			this._labelContainer.removeEventListener("touchstart", this.onTouchStart);
			window.removeEventListener("mousemove", this.onMouseMove);
			window.removeEventListener("touchmove", this.onTouchMove);
			window.removeEventListener("mouseup", this.onPointerUp);
			window.removeEventListener("touchend", this.onPointerUp);
			window.removeEventListener("touchcancel", this.onPointerUp);
			HTMLUtils.detach(this._element);
		}
	}

	/** Currently we're on panorama A. By clicking this hotspot, it will get you to panorama B.
	 * This getter retrieves a transition from panorama B that will get you back to panorama A,
	 * if such a transition exists. Otherwise, it returns null.
	 */
	public get returnTransition() {
		const activePhotoSphereId = this._photoSphereManager.activePhotoSphereMaybe?.id ?? "";

		return this._photoSphere.transitions.find((transition: ITransition) => transition.toPhotoSphereId === activePhotoSphereId);
	}

	private get photoSphereSceneManager() {
		return this._photoSphereManager.photoSphereSceneManager;
	}

	public get element() {
		return this._element;
	}

	// linked panorama
	public get photoSphere() {
		return this._photoSphere;
	}

	public get longitude() {
		return this._transition.longitude;
	}

	public get latitude() {
		return this._transition.latitude;
	}
}
