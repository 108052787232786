import styled from "styled-components";
import {useEffect} from "react";
import {ReactUtils} from "../../../utils/ReactUtils";
import {Functions} from "../../../../utils/function/Functions";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";
import {colorPalette} from "../../styles/colorPalette";
import {baseDistance, Flex, FlexCenterStyle, radius} from "../../styles/styles";
import type {IBooleanFieldSettingsDefinition} from "../../../modules/settings/modules/field/datatypes/BooleanFieldSettings";

interface IToggleSwitchV5Props {
	readonly value: boolean;
	readonly onChange: (value: boolean) => void;
	readonly disabled?: boolean;
	readonly inline?: boolean;
	readonly dataTypeSettings?: IBooleanFieldSettingsDefinition;
	readonly focused?: boolean;
	readonly onClick?: (e: React.MouseEvent) => void;
	readonly noLabel?: boolean;
	readonly className?: string;
}

export const ToggleSwitchV5 = (props: IToggleSwitchV5Props) => {
	const {value, inline, disabled, noLabel, className, focused, onChange, onClick, dataTypeSettings} = props;

	const getButtonLabel = () => {
		let label = value ? "Yes" : "No";

		if (dataTypeSettings) {
			label = value ? dataTypeSettings.displayLabelForTrue : dataTypeSettings.displayLabelForFalse;
		}

		return label;
	};

	const handleToggleOnChange = (event: React.MouseEvent) => {
		if (onChange && event.detail === 1) {
			event.stopPropagation();

			onChange(!value);
			onClick?.(event);
		}
	};

	const onDoubleClick = (event: React.MouseEvent) => {
		handleToggleOnChange(event);
	};

	useEffect(() => {
		const onKeyUp = (event: KeyboardEvent) => {
			if (focused && event.key === KeyboardListener.KEY_ENTER) {
				onChange(!value);
			}
		};

		KeyboardListener.getInstance().signals.up.add(onKeyUp);

		return () => {
			KeyboardListener.getInstance().signals.up.remove(onKeyUp);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	{
		const booleanValue = !noLabel && getButtonLabel();
		return (
			<Flex
				$gap={baseDistance.sm}
				className={className}
			>
				<ToggleSwitchStyled
					className={ReactUtils.cls("ToggleSwitchV5", {disabled, inline, focused})}
					onClick={handleToggleOnChange}
				>
					<input
						type="checkbox"
						checked={value}
						onChange={Functions.emptyFunction /* empty arrow function prevents react to cry for onChange handler */}
					/>
					<div className="slider" />
					<div className="circle" />
				</ToggleSwitchStyled>
				{booleanValue && (
					<LabelStyled
						className={ReactUtils.cls("toggleSwitchLabel", {blank: booleanValue === "", inline})}
						onDoubleClick={onDoubleClick}
						onClick={handleToggleOnChange}
					>
						{booleanValue}
					</LabelStyled>
				)}
			</Flex>
		);
	}
};

const ToggleSwitchStyled = styled.div`
	cursor: pointer;
	position: relative;
	display: inline-block;
	min-width: 40px;
	height: 32px;

	input {
		display: none;
	}

	.slider {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: ${colorPalette.gray.c700Dark};
		-webkit-transition: transform 0.4s;
		transition: transform 0.4s;
		border-radius: 9px;
		height: 16px;
		width: 40px;
		transform: translate(0, 50%);
	}

	.circle {
		position: absolute;
		height: 20px;
		width: 20px;
		top: 6px;
		background-color: ${colorPalette.gray.c100};
		-webkit-transition: transform 0.4s;
		transition: transform 0.4s;
		border-radius: ${radius.xl};
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	}

	input:checked ~ .slider {
		background-color: ${colorPalette.primary.c300};
		opacity: 50%;
	}

	input:checked ~ .circle {
		background-color: ${colorPalette.primary.c500Primary};
		-webkit-transform: translateX(15px);
		-ms-transform: translateX(15px);
		transform: translateX(20px);
	}

	&.disabled {
		cursor: default;
		opacity: 0.5;
	}

	&.focused {
		&::before {
			content: "";
			position: absolute;
			top: 4px;
			left: -4px;
			width: calc(100% + 7px);
			height: 24px;
		}
	}
`;

export const LabelStyled = styled.div`
	/* color: ${colorPalette.gray.c700Dark}; */
	${FlexCenterStyle}/* 
	&.inline {
		color: ${colorPalette.gray.c950};
	} */
`;
