import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {CreatePortfolioPermissionSetRequest} from "../../../../../generated/api/base";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import {XyiconFeature} from "../../../../../generated/api/base";
import {ButtonStyled, ButtonV5} from "../../../button/ButtonV5";
import {ClickToEditInputV5} from "../../../input/clicktoedit/ClickToEditInputV5";
import {baseDistance} from "../../../styles/styles";
import {GeneralPermissionsV5} from "./GeneralPermissionsV5";

interface ICreatePermissionSetPanelProps {
	readonly onClose: (createdId?: string) => void;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
	readonly open?: boolean;
}

interface ICreatePermissionSetPanelState {
	name: string;
}

@inject("appState")
@inject("transport")
@observer
export class CreatePermissionSetPanelV5 extends React.PureComponent<ICreatePermissionSetPanelProps, ICreatePermissionSetPanelState> {
	constructor(props: ICreatePermissionSetPanelProps) {
		super(props);
		this.state = {
			name: "",
		};
	}

	private onCreateClicked = async () => {
		const {name} = this.state;

		const data: CreatePortfolioPermissionSetRequest = {
			name: name,
			portfolioFieldPermissions: [],
			portfolioPermission: GeneralPermissionsV5.generalPermissionOptions[0].id,
			featurePermissions: [],
		};
		const models = await this.props.transport.services.feature.create<PermissionSet>(data, XyiconFeature.PermissionSet);

		this.setState({name: ""});

		this.props.onClose(models?.[0]?.id);
	};

	private isNameValid = (name: string) => {
		return this.props.appState.actions.isPermissionSetNameValid(name, "");
	};

	private getErrorMessage = (name: string) => {
		return !name.trim() ? "Name cannot be empty!" : this.isNameValid(name) ? "" : "Name needs to be unique!";
	};

	private onTextInput = (value: string) => {
		this.setState({name: value});
	};

	public override render() {
		const {open} = this.props;
		const {name} = this.state;

		const isFormValid = name && this.isNameValid(name);

		return (
			<CreatePermissionSetPanelStyled>
				<ClickToEditInputV5
					value={name}
					onLiveChange={this.onTextInput}
					getErrorMessage={this.getErrorMessage}
					focused={open}
					placeholder="Name Value"
				/>
				<ButtonV5
					label="Confirm"
					title="Create"
					onClick={this.onCreateClicked}
					disabled={!isFormValid}
				/>
			</CreatePermissionSetPanelStyled>
		);
	}
}

const CreatePermissionSetPanelStyled = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: ${baseDistance.sm};
	padding-right: ${baseDistance.sm};
	gap: 24px;

	${ButtonStyled} {
		width: 70px;
		margin-left: 215px;
		margin-right: 0;
	}
`;
