import styled from "styled-components";
import {baseDistance, radius} from "../styles/styles";
import {FieldStyled} from "../details/Field.styled";

export const CreatePopupFieldStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.sm};

	${FieldStyled} {
		display: flex;
		align-items: center;
		border-radius: ${radius.sm};

		.label {
			max-width: 150px;
			min-width: 120px;
			margin-top: 0;
		}

		.element {
			width: 100%;
			margin: 0;
		}
	}
`;
