import styled from "styled-components";
import {colorPalette} from "../../../../../../styles/colorPalette";
import {baseDistance, fontSize, fontWeight} from "../../../../../../styles/styles";

export const ReportColumnsStyled = styled.div`
	min-width: 750px;
	height: 100%;

	.InfoButtonV5 {
		top: 0;
		color: ${colorPalette.blueGray.c700Dark};

		svg {
			width: 16px;
			height: 16px;
		}
	}

	.manage-cols-box {
		grid-template-columns: 341px 28px 341px 28px;
		display: grid;
		grid-gap: 10px;
	}

	.ColumnEditor {
		max-height: calc(100% - 130px);

		.manage-cols-box {
			height: inherit;

			.col {
				max-height: 450px;

				.border {
					.views {
						height: calc(100% - 50px);

						.DetailsTab {
							padding: 0;
						}
					}

					.buttons {
						padding: 0;

						.tabLabel {
							font-size: ${fontSize.lg};
							font-weight: ${fontWeight.normal};
						}
					}
				}
			}
		}
	}

	.toggleSwitchLabel {
		display: none;
	}

	.ToggleSwitchField {
		.label {
			padding: ${baseDistance.sm} 0 ${baseDistance.sm} ${baseDistance.md};
			color: black;
		}
	}
`;
