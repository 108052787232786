import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {IFieldPointer} from "../../../../data/models/field/Field";
import {FieldDataTypes} from "../../../../data/models/field/FieldDataTypes";
import type {IFilter} from "../../../../data/models/filter/Filter";
import type {FilterOperator} from "../../../../data/models/filter/operator/FilterOperator";
import type {AppState} from "../../../../data/state/AppState";
import {FieldDataType} from "../../../../generated/api/base";
import {ReactUtils} from "../../../utils/ReactUtils";
import LinkIcon from "../../icons/link.svg?react";
import {SelectInputV5} from "../../input/select/SelectInputV5";
import type {IFilterOperatorConfigV5} from "../operator/FilterOperatorsV5";
import {FilterOperatorsV5} from "../operator/FilterOperatorsV5";
import TrashIcon from "../../icons/trash.svg?react";
import {IconButtonStyled, IconButtonV5} from "../../interaction/IconButtonV5";
import {colorPalette} from "../../styles/colorPalette";
import {baseDistance} from "../../styles/styles";
import {AdvancedFilterFieldStyled} from "./AdvancedFilterField.styled";

interface IAdvancedFilterFieldProps {
	readonly fieldRefIds: IFieldPointer[];
	readonly filter: IFilter;
	readonly onChangeField: (field: IFieldPointer) => void;
	readonly onChangeOperator: (operator: FilterOperator) => void;
	readonly onChangeParams: (params: any[]) => void;
	readonly onRemove: () => void;
	readonly operatorFilter?: (operator: FilterOperator) => boolean;
	readonly appState?: AppState;
	readonly linkedFields?: boolean;
	readonly children: React.ReactNode;
}

interface IAdvancedFilterFieldState {
	focus: boolean;
}

@inject("appState")
@observer
export class AdvancedFilterFieldV5 extends React.PureComponent<IAdvancedFilterFieldProps, IAdvancedFilterFieldState> {
	constructor(props: IAdvancedFilterFieldProps) {
		super(props);
		this.state = {
			focus: false,
		};
	}

	private renderField = (fieldRefId: string) => {
		const {appState, linkedFields} = this.props;

		return (
			<div className="hbox alignCenter filterField">
				<span>{appState.actions.getFieldTitle(fieldRefId)}</span>
				{linkedFields && (
					<LinkIcon
						color={colorPalette.positive.c300}
						className="linkIcon"
					/>
				)}
			</div>
		);
	};

	private onFocus = (value: boolean) => {
		this.setState({focus: value});
	};

	public override render() {
		const {onRemove, appState, filter, fieldRefIds, onChangeField, onChangeOperator, onChangeParams, operatorFilter, children} = this.props;

		const {focus} = this.state;

		const selectedFieldRefId = fieldRefIds.includes(filter.field) ? filter.field : fieldRefIds[0];
		const selectedField = appState.actions.getFieldByRefId(selectedFieldRefId);

		//const fields        = this.getFields();
		//const selectedField = fields.find(field => field.refId === filter.field) || fields[0];

		let operators = FieldDataTypes.map[selectedField.dataType].operators(selectedField.dataTypeSettings);

		if (operatorFilter) {
			operators = operators.filter(operatorFilter);
		}

		const operator = FilterOperatorsV5.map[filter.operator] as IFilterOperatorConfigV5;
		const ParamControl = operator ? operator.control : null;

		return (
			<AdvancedFilterFieldStyled className={ReactUtils.cls("AdvancedFilterField hbox", {focus})}>
				<div className="criterias">
					<CriteriaInputsStyled>
						<div className="header">{children}</div>
						<SelectInputV5
							sort={true}
							options={fieldRefIds}
							selected={selectedFieldRefId}
							onChange={(fieldRefId) => onChangeField(fieldRefId)}
							render={this.renderField}
							onFocus={this.onFocus}
							stringifyOption={(fieldRefId) => appState.actions.getFieldTitle(fieldRefId)}
							className="SelectInput"
						/>
						<SelectInputV5
							options={operators}
							selected={filter.operator}
							onChange={(operator) => onChangeOperator(operator)}
							className="SelectInput"
							render={(operation) => {
								const op = FilterOperatorsV5.map[operation];
								let label = op.label;

								if (selectedField.dataType === FieldDataType.Boolean) {
									label = appState.actions.getBooleanFieldCustomLabelSettingsByFilterOperator(selectedField, op.id);
								}

								return label;
							}}
							onFocus={this.onFocus}
						/>
						{ParamControl && (
							<ParamControl
								param={filter.param}
								onChange={onChangeParams}
								onFocus={this.onFocus}
								field={selectedField}
							/>
						)}
						<IconButtonV5
							IconComponent={TrashIcon}
							onClick={onRemove}
							title="Delete"
						/>
					</CriteriaInputsStyled>
				</div>
			</AdvancedFilterFieldStyled>
		);
	}
}

export const CriteriaInputsStyled = styled.div`
	gap: ${baseDistance.sm};
	align-items: center;
	display: grid;
	grid-template-columns: 64px 156px 128px 200px 20px;

	${IconButtonStyled} {
		height: 16px;
		min-width: 16px;
	}
`;
