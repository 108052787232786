/**
 * CSS3DRenderer based on the three.js implementation
 */

import type {Object3D, Scene} from "three";
import {Matrix4, OrthographicCamera, PerspectiveCamera} from "three";
import {VectorUtils} from "../../utils/VectorUtils";
import {CSS3DObject} from "./CSS3DObject";
import {CSS3DSprite} from "./CSS3DSprite";

export class CSS3DRenderer {
	private _widthHalf: number = 1;
	private _heightHalf: number = 1;

	private readonly _matrix: Matrix4;
	private readonly _cache: {
		camera: {
			fov: number;
			style: string;
		};
		objects: WeakMap<
			CSS3DObject,
			{
				style: string;
				distanceToCameraSquared: number;
			}
		>;
	};
	private readonly _domElement: HTMLDivElement;
	private readonly _cameraElement: HTMLDivElement;

	private _calc1: [number, number, number] = [0, 0, 0];
	private _calc2: [number, number, number] = [0, 0, 0];

	private readonly _isIE = /Trident/i.test(navigator.userAgent);

	constructor() {
		this._matrix = new Matrix4();

		this._cache = {
			camera: {fov: 0, style: ""},
			objects: new WeakMap(),
		};

		this._domElement = document.createElement("div");
		this._domElement.style.overflow = "hidden";

		this._cameraElement = document.createElement("div");
		this._cameraElement.style.transformStyle = "preserve-3d";
		this._cameraElement.style.pointerEvents = "none";

		this._domElement.appendChild(this._cameraElement);
	}

	public get domElement() {
		return this._domElement;
	}

	public setSize(width: number, height: number) {
		this._widthHalf = width / 2;
		this._heightHalf = height / 2;

		this._domElement.style.width = `${width}px`;
		this._domElement.style.height = `${height}px`;

		this._cameraElement.style.width = `${width}px`;
		this._cameraElement.style.height = `${height}px`;
	}

	private epsilon(value: number) {
		return Math.abs(value) < 1e-10 ? 0 : value;
	}

	private getCameraCSSMatrix(matrix: Matrix4) {
		const elements = matrix.elements;

		return `matrix3d(
			${this.epsilon(elements[0])},
			${this.epsilon(-elements[1])},
			${this.epsilon(elements[2])},
			${this.epsilon(elements[3])},
			${this.epsilon(elements[4])},
			${this.epsilon(-elements[5])},
			${this.epsilon(elements[6])},
			${this.epsilon(elements[7])},
			${this.epsilon(elements[8])},
			${this.epsilon(-elements[9])},
			${this.epsilon(elements[10])},
			${this.epsilon(elements[11])},
			${this.epsilon(elements[12])},
			${this.epsilon(-elements[13])},
			${this.epsilon(elements[14])},
			${this.epsilon(elements[15])}
		)`;
	}

	private getObjectCSSMatrix(matrix: Matrix4, cameraCSSMatrix: string) {
		const elements = matrix.elements;
		const matrix3d = `matrix3d(
			${this.epsilon(elements[0])},
			${this.epsilon(elements[1])},
			${this.epsilon(elements[2])},
			${this.epsilon(elements[3])},
			${this.epsilon(-elements[4])},
			${this.epsilon(-elements[5])},
			${this.epsilon(-elements[6])},
			${this.epsilon(-elements[7])},
			${this.epsilon(elements[8])},
			${this.epsilon(elements[9])},
			${this.epsilon(elements[10])},
			${this.epsilon(elements[11])},
			${this.epsilon(elements[12])},
			${this.epsilon(elements[13])},
			${this.epsilon(elements[14])},
			${this.epsilon(elements[15])}
		)`;

		return this._isIE
			? `translate(-50%,-50%) translate(${this._widthHalf}px, ${this._heightHalf}px) ${cameraCSSMatrix} ${matrix3d}`
			: `translate(-50%,-50%) ${matrix3d}`;
	}

	private renderObject(object: Scene | CSS3DObject, camera: PerspectiveCamera | OrthographicCamera, cameraCSSMatrix: string) {
		if (!object.visible) {
			const elementMaybe = (object as CSS3DObject).element;
			if (elementMaybe) {
				elementMaybe.style.display = "none";
			}
		} else if (object instanceof CSS3DObject) {
			let style: string = "";

			if (object instanceof CSS3DSprite) {
				// http://swiftcoder.wordpress.com/2008/11/25/constructing-a-billboard-matrix/

				this._matrix.copy(camera.matrixWorldInverse);
				this._matrix.transpose();
				this._matrix.copyPosition(object.matrixWorld);
				this._matrix.scale(object.scale);

				this._matrix.elements[3] = 0;
				this._matrix.elements[7] = 0;
				this._matrix.elements[11] = 0;
				this._matrix.elements[15] = 1;

				style = this.getObjectCSSMatrix(this._matrix, cameraCSSMatrix);
			} else {
				style = this.getObjectCSSMatrix(object.matrixWorld, cameraCSSMatrix);
			}

			const element = object.element;
			if (element.style.display === "none") {
				element.style.display = "";
			}
			const cachedObject = this._cache.objects.get(object);

			if (cachedObject === undefined || cachedObject.style !== style) {
				element.style.transform = style;

				const objectData = {
					style: style,
					distanceToCameraSquared: 1,
				};

				if (this._isIE) {
					objectData.distanceToCameraSquared = this.getDistanceToSquared(camera, object);
				}

				this._cache.objects.set(object, objectData);
			}

			if (element.parentNode !== this._cameraElement) {
				this._cameraElement.appendChild(element);
			}
		}

		for (const child of object.children) {
			this.renderObject(child as CSS3DObject, camera, cameraCSSMatrix);
		}
	}

	private readonly getDistanceToSquared = (object1: Object3D, object2: Object3D) => {
		this._calc1 = VectorUtils.setFromMatrixPosition(object1.matrixWorld);
		this._calc2 = VectorUtils.setFromMatrixPosition(object2.matrixWorld);

		return VectorUtils.distanceBetweenSquaredVec3(this._calc1, this._calc2);
	};

	private filterAndFlatten(scene: Scene) {
		const result: CSS3DObject[] = [];

		scene.traverse((object: Object3D) => {
			if (object instanceof CSS3DObject) {
				result.push(object);
			}
		});

		return result;
	}

	private zOrder(scene: Scene) {
		const sorted = this.filterAndFlatten(scene).sort((a: CSS3DObject, b: CSS3DObject) => {
			const distanceA = this._cache.objects.get(a)?.distanceToCameraSquared ?? 0;
			const distanceB = this._cache.objects.get(b)?.distanceToCameraSquared ?? 0;

			return distanceA - distanceB;
		});

		const zMax = sorted.length;

		for (let i = 0; i < sorted.length; ++i) {
			sorted[i].element.style.zIndex = (zMax - i).toString();
		}
	}

	public render(scene: Scene, camera: PerspectiveCamera | OrthographicCamera) {
		const fov = camera.projectionMatrix.elements[5] * this._heightHalf;

		if (this._cache.camera.fov !== fov) {
			if (camera instanceof PerspectiveCamera) {
				this._domElement.style.perspective = `${fov}px`;
			} else {
				this._domElement.style.perspective = "";
			}
			this._cache.camera.fov = fov;
		}

		scene.updateMatrixWorld();

		if (camera.parent === null) {
			camera.updateMatrixWorld(false);
		}

		let tx = 0;
		let ty = 0;

		if (camera instanceof OrthographicCamera) {
			tx = -(camera.right + camera.left) / 2;
			ty = (camera.top + camera.bottom) / 2;
		}

		const cameraCSSMatrix =
			camera instanceof OrthographicCamera
				? `scale(${fov})translate(${this.epsilon(tx)}px,${this.epsilon(ty)}px)${this.getCameraCSSMatrix(camera.matrixWorldInverse)}`
				: `translateZ(${fov}px)${this.getCameraCSSMatrix(camera.matrixWorldInverse)}`;

		const style = `${cameraCSSMatrix}translate(${this._widthHalf}px, ${this._heightHalf}px)`;

		if (this._cache.camera.style !== style && !this._isIE) {
			this._cameraElement.style.transform = style;
			this._cache.camera.style = style;
		}

		this.renderObject(scene, camera, cameraCSSMatrix);

		if (this._isIE) {
			// IE10 and 11 does not support "preserve-3d".
			// Thus, z-order in 3D will not work.
			// We have to calc z-order manually and set CSS z-index for IE.
			// FYI: z-index can"t handle object intersection
			this.zOrder(scene);
		}
	}
}
