import styled from "styled-components";
import {baseDistance, fontSize, fontWeight, radius} from "../../../../../../styles/styles";
import {colorPalette} from "../../../../../../styles/colorPalette";
import {CriteriaInputsStyled} from "../../../../../../filter/advance/AdvancedFilterFieldV5";

export const ReportFilterStyled = styled.div`
	width: 750px;

	.ReportFilterContainer {
		min-height: 250px;
		overflow-y: auto;
		margin: 10px 0;
		height: 30vh;
	}

	.AdvancedFilterField {
		background: ${colorPalette.white};
		margin: 0px;
		padding: 0;

		.criterias {
			display: grid;
			margin: 10px;
			grid-gap: 10px;
			align-items: center;

			.header {
				display: none;
			}

			input {
				height: 32px;
				padding: 11px;
				border: 1px solid ${colorPalette.gray.c300};
				border-radius: ${radius.sm};
				width: 200px;
			}

			.SelectInput {
				span {
					width: 100%;
				}

				.filterField {
					svg {
						min-width: 12px;
						width: 12px;
						height: 12px;
					}
				}
			}

			${CriteriaInputsStyled} {
				grid-template-columns: 248px 160px 200px 20px;
			}
		}

		&:hover {
			background: ${colorPalette.gray.c100};

			.button {
				visibility: initial;
			}
		}
	}

	.TabView {
		.views {
			display: block;
			margin: 10px 0;

			.DetailsTab {
				padding: 0 ${baseDistance.lg};
			}
		}

		.buttons {
			padding: 0 ${baseDistance.lg};

			.button {
				background: none;
				margin-right: 50px;
				margin-bottom: 10px;
				color: ${colorPalette.gray.c700Dark};
				border-bottom: 3px solid transparent;

				&.selected {
					background: ${colorPalette.white};
					color: ${colorPalette.primary.c500Primary};
					border-bottom-color: ${colorPalette.primary.c500Primary};
				}

				.tabLabel {
					font-size: ${fontSize.xl};
					font-weight: ${fontWeight.bold};
				}
			}
		}
	}

	&.linkedFields {
		.TabView {
			& > .buttons {
				display: flex;
			}
		}
	}

	.buttons {
		justify-content: left;
	}
`;
