import React, {useRef} from "react";
import styled, {css} from "styled-components";
import {Observer} from "mobx-react";
import type {XyiconFeature} from "../../../../generated/api/base";
import {typesFeatures} from "../../../../data/state/AppStateConstants";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {useAppStore} from "../../../../StateManager";
import {TabChildV5} from "../../abstract/table/TabChildV5";
import {TabViewV5} from "../../widgets/tab/TabViewV5";
import {TypeSettingsV5} from "./type/TypeSettingsV5";
import {FieldSettingsV5} from "./field/FieldSettingsV5";
import {LayoutSettingsV5} from "./layout/LayoutSettingsV5";

interface IModuleSettingsProps {
	readonly feature: XyiconFeature;
	readonly param2: string; // "types" | "fields" | etc
}

export const ModuleSettingsV5 = (props: IModuleSettingsProps) => {
	const {feature, param2} = props;
	const appState = useAppStore((state) => state.appState);
	const modulesCreatingType = useAppStore((state) => state.modulesCreatingType);
	const modulesTypeAndFieldCreating = useAppStore((state) => state.modulesTypeAndFieldCreating);
	const {
		app: {navigation},
		layoutSettings,
	} = appState;
	const createButtonRef = useRef<HTMLDivElement>(null);

	const onChangeSettingsTab = (id: string) => {
		const parts = StringUtils.decomposeParts(location.hash).map((part) => part.value);

		parts[3] = id;
		const hash = parts.join("/");

		navigation.go(hash);
	};

	return (
		// key makes sure that when the user switches to another feature, new component instance will be recreated
		// with a fresh state, so eg. when the user is creating a type, and switches to another feature,
		// the CreateTypePanel will not be visible anymore.
		<Observer>
			{() => {
				const typesFeature = typesFeatures[feature as XyiconFeature.Xyicon] || feature;
				const activeTab = param2 || modulesCreatingType;
				return (
					<ModuleSettingsContatinerStyled
						key={feature}
						$isCreatingFields={modulesCreatingType === "fields" && modulesTypeAndFieldCreating}
					>
						<TabViewV5
							className="settingsTab"
							selectedTabId={activeTab}
							onChangeSelectedTabId={onChangeSettingsTab}
							createButtonRef={createButtonRef}
							isSettings={true}
						>
							<TabChildV5
								id="types"
								label="Types"
								title="Types"
							>
								<TypeSettingsV5
									feature={feature}
									typesFeature={typesFeature}
									createButtonParentRef={createButtonRef}
								/>
							</TabChildV5>
							<TabChildV5
								id="fields"
								label="Fields"
								title="Fields"
							>
								<FieldSettingsV5
									feature={feature}
									typesFeature={typesFeature}
								/>
							</TabChildV5>
							<TabChildV5
								id="layouts"
								label="Layouts"
								title="Layouts"
							>
								<LayoutSettingsV5
									ref={layoutSettings as React.Ref<LayoutSettingsV5>}
									feature={feature}
								/>
							</TabChildV5>
						</TabViewV5>
					</ModuleSettingsContatinerStyled>
				);
			}}
		</Observer>
	);
};

const ModuleSettingsContatinerStyled = styled.div<{$isCreatingFields: boolean}>`
	width: 100%;
	${(props) =>
		props.$isCreatingFields &&
		css`
			&::before {
				content: "";
				position: fixed;
				right: 0;
				bottom: 0;
				width: calc(100%);
				height: calc(100%);
				background-color: #00000045;
				z-index: 13;
			}
		`}
`;
