import * as Excel from "exceljs";
import {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import type {Portfolio} from "../../../../data/models/Portfolio";
import type {IFieldAdapter} from "../../../../data/models/field/Field";
import type {IImportStatus} from "../../../../data/signalr/SignalRListener";
import {featureTitles} from "../../../../data/state/AppStateConstants";
import {XyiconFeature, FieldDataType, Permission} from "../../../../generated/api/base";
import {notify} from "../../../../utils/Notify";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {FileUtils} from "../../../../utils/file/FileUtils";
import {XHRLoader} from "../../../../utils/loader/XHRLoader";
import {WarningWindow} from "../../../modules/abstract/popups/WarningWindow";
import type {INotificationElementParams} from "../../../notification/AppNotifications";
import type {INotificationParams} from "../../../notification/Notification";
import {NotificationType} from "../../../notification/Notification";
import {useAppStore} from "../../../../StateManager";
import {baseDistance, Flex, FlexCenter, FlexCenterStyle, fontSize, fontWeight, radius, VerticalFlex, VerticalFlexStyle} from "../../styles/styles";
import {ButtonV5} from "../../button/ButtonV5";
import {colorPalette} from "../../styles/colorPalette";
import {FileDropperReactV5} from "../../interaction/FileDropperReactV5";
import ExcelIcon from "../../icons/excel.svg?react";
import DeleteIcon from "../../icons/delete.svg?react";
import ArrowRightIcon from "../../icons/arrow-back.svg?react";
import {SelectInputV5} from "../../input/select/SelectInputV5";
import {PopupV5} from "../../popup/PopupV5";
import {FieldV5} from "../../details/FieldV5";
import {IconButtonV5} from "../../interaction/IconButtonV5";
import {CreatePopupFieldStyled} from "../../popup/CreatePopupField.styled";
import {ButtonContainerStyled} from "../../modules/report/create/wizard/ReportWizardV5.style";
import {StepIndicatorV5} from "./StepIndicatorV5";

interface IFeatureImportPanelProps {
	readonly onClose: (e?: React.MouseEvent) => void;
	readonly calledFromThisModule?: XyiconFeature;
}

type ScopeValue = "organization" | "currentPortfolio" | null;

interface IScopeOption {
	label: string;
	value: ScopeValue;
}

interface IDataToUpload {
	portfolioID: string;
	data: string[][];
	headerRow: string[];
	feature: XyiconFeature;
	isOrganizationWideImport: boolean;
}

type StepType = "Upload File" | "Match IDs" | "Map Columns";

const fileSizeLimitInMB = 5;
const rowCountLimit = 10_000;

export const FeatureImportPanelV5 = (props: IFeatureImportPanelProps) => {
	const appState = useAppStore((state) => state.appState);
	const currentPortfolio = appState.actions.getFeatureItemById<Portfolio>(appState.portfolioId, XyiconFeature.Portfolio);
	const modules = [XyiconFeature.Boundary, XyiconFeature.Xyicon, XyiconFeature.Space, XyiconFeature.XyiconCatalog];

	if (appState.user.isAdmin) {
		modules.push(XyiconFeature.Portfolio);
	}

	const [stepIndex, setStepIndex] = useState<number>(0);
	const [excelFile, setExcelFile] = useState<File | null>(null);
	const [excelColumns, setExcelColumns] = useState<string[]>([]);
	const [excelIdColumn, setExcelIdColumn] = useState<string | null>(null);
	const [excelData, setExcelData] = useState<string[][]>([]);
	const [fieldMapping, setFieldMapping] = useState<IFieldAdapter[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [feature, setFeature] = useState<XyiconFeature>(props.calledFromThisModule || null);
	const [selectedScopeValue, setSelectedScopeValue] = useState<ScopeValue>(null);

	let _steps: StepType[] = ["Upload File", "Match IDs", "Map Columns"];
	let _importRequestId: string | null = null;
	let _notification: INotificationElementParams | null = null;

	const scopeOptions = useMemo(() => {
		let scopeOptionsArr: IScopeOption[] = [];

		if ([XyiconFeature.Portfolio, XyiconFeature.XyiconCatalog].includes(feature)) {
			scopeOptionsArr.push({
				label: "Organization",
				value: "organization",
			});
		} else {
			scopeOptionsArr.push(
				{
					label: `Current portfolio (${currentPortfolio.name})`,
					value: "currentPortfolio",
				},
				{
					label: "Organization",
					value: "organization",
				},
			);
		}

		return scopeOptionsArr;
	}, [feature, currentPortfolio.name]);

	useEffect(() => {
		if (feature !== null) {
			setSelectedScopeValue(scopeOptions[0].value);
		}
	}, [feature, scopeOptions]);

	const getIgnoreField = (): IFieldAdapter => {
		return {
			refId: "",
			name: "(Ignore this column)",
			dataType: FieldDataType.SingleLineText,
			feature: feature,
			default: true, // aka hardcoded field
			displayOnLinks: false,
		};
	};

	const isThereAValidFieldMapping = () => {
		const ignoreField = getIgnoreField();

		return fieldMapping.slice(1).some((f) => f && f !== ignoreField);
	};

	const isNextButtonEnabled = (): boolean => {
		if (!selectedScopeValue && !feature) {
			return false;
		}

		switch (_steps[stepIndex]) {
			case "Upload File":
				return !!excelFile;
			case "Match IDs":
				return !!excelIdColumn;
			case "Map Columns":
				return isThereAValidFieldMapping() && !isLoading;
			default:
				return false;
		}
	};

	const getFilteredDataForUpload = () => {
		const headerRow = excelColumns.map((column: string, index: number) => fieldMapping[index]?.refId);
		const filteredHeaderRow: string[] = [];

		const indicesOfColumnsToIgnore: number[] = [];

		for (let i = 0; i < headerRow.length; ++i) {
			if (headerRow[i]) {
				if (headerRow[i].includes("/refId")) {
					filteredHeaderRow.push("id");
				} else {
					const fieldName = headerRow[i].split("/");

					filteredHeaderRow.push(fieldName[fieldName.length - 1]);
				}
			} else {
				indicesOfColumnsToIgnore.push(i);
			}
		}

		const filteredData: string[][] = [];

		for (const row of excelData) {
			const filteredRow: string[] = [];

			for (let i = 0; i < row.length; ++i) {
				if (!indicesOfColumnsToIgnore.includes(i)) {
					const field = appState.actions.getFieldByRefId(headerRow[i]);
					const fileNameSplit = excelFile.name.split(".");
					const extension = fileNameSplit[fileNameSplit.length - 1] as "xlsx" | "csv";
					let value = row[i];

					if (field?.dataType === FieldDataType.DateTime) {
						if (extension === "csv" && (row[i] === "undefined" || row[i] === "0")) {
							value = null;
						}

						if (extension === "xlsx" && row[i]) {
							const valueAsDate = new Date(row[i]);

							value = valueAsDate.toString() !== "Invalid Date" ? valueAsDate.toISOString() : "Invalid Date";
						}
					}
					filteredRow.push(value);
				}
			}
			filteredData.push(filteredRow);
		}

		return {
			filteredHeaderRow,
			filteredData,
		};
	};

	const onBackClick = () => {
		if (stepIndex > 0) {
			setStepIndex(stepIndex - 1);
		} else {
			props.onClose();
		}
	};

	const onNextClick = async () => {
		if (stepIndex < _steps.length - 1) {
			setStepIndex(stepIndex + 1);
		} else if (stepIndex === _steps.length - 1) {
			// Upload data

			const {filteredHeaderRow, filteredData} = getFilteredDataForUpload();

			const data: IDataToUpload = {
				portfolioID: appState.portfolioId,
				headerRow: filteredHeaderRow,
				data: filteredData,
				feature: feature,
				isOrganizationWideImport: selectedScopeValue === "organization",
			};

			setIsLoading(true);

			const {result, error} = await appState.app.transport.requestForOrganization<{requestID: string}>({
				url: "import",
				method: XHRLoader.METHOD_POST,
				params: data,
			});

			_importRequestId = result.requestID;

			dataImportStatusReceivedSignal.add(onDataImportStatusUpdate);

			setIsLoading(false);

			props.onClose();
		}
	};

	const dataImportStatusReceivedSignal = appState.app.transport.signalR.listener.signals.importStatusReceived;

	const onDataImportStatusUpdate = (data: IImportStatus) => {
		if (_importRequestId === data.requestID) {
			_notification?.onClose();

			if (data.isCompleted) {
				dataImportStatusReceivedSignal.remove(onDataImportStatusUpdate);

				const notificationParams: INotificationParams = {
					type: NotificationType.Success,
					title: "",
					lifeTime: 10000,
				};

				if (data.errorRows > 0) {
					if (data.errorRows === data.totalRows) {
						if (data.validRows > 0) {
							console.log("The numbers don't add up... This shouldn't have happened.");
						}

						notificationParams.type = NotificationType.Error;
						notificationParams.title = "Update unsuccessful!";
						notificationParams.description = "No records were updated due to errors.";
						notificationParams.lifeTime = Infinity;
					} else {
						notificationParams.type = NotificationType.Warning;
						notificationParams.title = `${data.validRows} of ${data.totalRows} records updated successfully!`;
						notificationParams.description = `${data.errorRows} records were not updated due to errors`;
						notificationParams.lifeTime = Infinity;
					}

					notificationParams.buttonLabel = "Download Error Report";
					notificationParams.onActionButtonClick = () => {
						const errorFilePath = appState.app.transport.getFullPathFromServer(`uploads/imports/errors/${data.requestID}.csv`);

						FileUtils.downloadFileFromUrl(errorFilePath, `${data.requestID}.csv`);
					};
				} else {
					if (data.validRows !== data.totalRows) {
						console.warn("ValidRows != TotalRows. This shouldn't have happened.");
					}
					notificationParams.type = NotificationType.Success;
					notificationParams.title = `All ${data.validRows} records updated successfully!`;
					notificationParams.lifeTime = 10000;
				}

				_notification = notify(appState.app.notificationContainer, notificationParams);
			} else {
				_notification = notify(appState.app.notificationContainer, {
					type: NotificationType.Message,
					title: `Update in progress! ${data.processedRows} of ${data.totalRows} completed`,
					description: `${data.errorRows} records failed to update due to errors`,
					lifeTime: 10000,
				});
			}
		}
	};

	const addCommaToNumbers = (num: number) => {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const getWorkSheetData = async (file: File): Promise<string[][]> => {
		try {
			const extension = FileUtils.getExtension(file.name);

			const workBook = new Excel.Workbook();

			if (extension === "csv") {
				const csv = await FileUtils.readAsText(file);
				const rows = StringUtils.CSVToArray(csv).map((r) => r.map((v) => `${v}`));
				return rows.filter((r) => r.join().length > 0);
			} else {
				const arrayBuffer = await FileUtils.readAsArrayBuffer(file);
				const xlsx = await workBook.xlsx.load(arrayBuffer);
				const workSheets = xlsx?.worksheets;

				if (xlsx?.worksheets?.length > 0) {
					const workSheet = workSheets[0];

					const rowCount = workSheet.rowCount;

					if (rowCount > rowCountLimit) {
						const rowCountLimitString = addCommaToNumbers(rowCountLimit);

						throw `Row Count Exceeds Limit. To update more than ${rowCountLimitString} rows, create multiple Excel files of less than ${rowCountLimitString} rows and import each file separately.`;
					}

					const rows: string[][] = [];

					const columnLetters = workSheet.columns.map((col) => col.letter);

					workSheet.eachRow((row: Excel.Row) => {
						const cells: string[] = [];
						let index = 0;

						columnLetters.forEach((letter) => {
							const cell = row.model.cells[index];
							const cellLetter = (cell?.address as unknown as string)?.replace(/\d/g, "");
							const propName = cell?.hyperlink ? "text" : "value";

							if (cellLetter === letter) {
								if (cell?.[propName] !== undefined) {
									cells.push(`${cell[propName]}`);
								} else {
									cells.push(undefined);
								}
								index++;
							} else {
								cells.push(undefined);
							}
						});
						rows.push(cells);
					});
					return rows;
				} else {
					throw "Sorry, your file cannot be opened: it seems this excel file doesn't have any worksheets in it";
				}
			}
		} catch (error: unknown) {
			console.log(error);
			await WarningWindow.open(`${error}`, "Cannot Upload File");
		}
	};

	const onFileInputChange = async (files: FileList) => {
		const ignoreField = getIgnoreField();
		const newFile = files[0];

		if (newFile && newFile !== excelFile) {
			if (newFile.size > 1024 * 1024 * fileSizeLimitInMB) {
				await WarningWindow.open(`Sorry, you can't use excel files that are larger than ${fileSizeLimitInMB} MB`, "Error");
			} else {
				const workSheetData = await getWorkSheetData(newFile);

				if (workSheetData) {
					const rowCount = workSheetData.length;

					if (rowCount > rowCountLimit) {
						const rowCountLimitString = addCommaToNumbers(rowCountLimit);

						await WarningWindow.open(
							`Row Count Exceeds Limit. To update more than ${rowCountLimitString} rows, create multiple Excel files of less than ${rowCountLimitString} rows and import each file separately.`,
							"Cannot Upload File",
						);
					} else if (rowCount === 0 || workSheetData[0]?.length === 0) {
						await WarningWindow.open("Sorry, this file doesn't seem to contain valid data", "Error");
					} else {
						const columns: string[] = workSheetData[0].filter((column) => !!column);

						const featureIdColumn = columns.find((column) => {
							const simplifiedColumn = column?.replace(/ /g, "").toLowerCase();

							return simplifiedColumn === `${featureTitles[feature]}id`.toLowerCase() || simplifiedColumn === "id";
						});

						const ownFields = _updatableFields();

						let fieldMapping: IFieldAdapter[] = [];

						for (const column of columns) {
							const columnAsLowerCase = column?.toLowerCase();
							const ownField = ownFields.find((f) => f.name.toLowerCase() === columnAsLowerCase);

							fieldMapping.push(ownField || ignoreField);
						}

						fieldMapping = addIdToFieldMapping(fieldMapping, columns, featureIdColumn);

						setExcelFile(newFile);
						setExcelColumns(columns);
						setExcelIdColumn(featureIdColumn);
						setExcelData(workSheetData.slice(1));
						setFieldMapping(fieldMapping);
					}
				}
			}
		}
	};

	const addIdToFieldMapping = (fieldMapping: IFieldAdapter[], columns: string[], featureIdColumn: string): IFieldAdapter[] => {
		const idFieldName = `${featureTitles[feature]} id`.toLowerCase(); // eg.: "xyicon id"
		const indexOfIdColumn = columns.findIndex((v) => v === featureIdColumn);

		if (indexOfIdColumn > -1) {
			const newFieldMapping = [...fieldMapping];

			const fields = _updatableFields();

			newFieldMapping[indexOfIdColumn] = fields.find((f) => f.name.toLowerCase() === idFieldName);

			return newFieldMapping;
		}

		return fieldMapping;
	};

	const onFileClear = () => {
		setExcelFile(null);
		setExcelIdColumn(null);
		setExcelColumns([]);
		setExcelData([]);
		setFieldMapping([]);
	};

	const onExcelIdColumnChange = (newExcelIdColumn: string) => {
		const newFieldMapping = addIdToFieldMapping(fieldMapping, excelColumns, newExcelIdColumn);

		setExcelIdColumn(newExcelIdColumn);
		setFieldMapping(newFieldMapping);
	};

	const hasUserPermission = (field: IFieldAdapter) => {
		const {user, actions} = appState;

		switch (feature) {
			case XyiconFeature.Portfolio:
				// only the users with admin rights can import portfolios
				return user?.isAdmin;
			case XyiconFeature.XyiconCatalog:
				return user?.getOrganizationPermission(XyiconFeature.XyiconCatalog) >= Permission.Update;
			case XyiconFeature.Space:
			case XyiconFeature.Xyicon:
			case XyiconFeature.Boundary:
				return actions.getFieldPermission(field) >= Permission.Update;
		}
	};

	const _updatableFields = () => {
		const defaultFieldsToIgnore = ["/type", "/model", "/lastModifiedBy", "/lastModifiedAt", "/icon"];

		return appState.actions
			.getFieldsByFeature(feature, false)
			.filter(
				(field: IFieldAdapter) =>
					field.refId.includes("/refId") ||
					(!field.hasFormula &&
						hasUserPermission(field) &&
						defaultFieldsToIgnore.every((fieldNameToIgnore) => !field.refId.includes(fieldNameToIgnore))),
			);
	};

	const _updatableFieldsWithoutId = _updatableFields().filter((f) => !f.refId.includes("/refId"));

	const getFieldRows = () => {
		const ignoreField = getIgnoreField();
		const ownFields = _updatableFieldsWithoutId;
		const options = [ignoreField, ...ownFields];

		return excelColumns.map((column: string, index: number) => {
			if (column === excelIdColumn) {
				return null;
			} else {
				const selected = fieldMapping[index] || ignoreField;

				return (
					<ImportMappingRowStyled key={index}>
						<div style={{padding: 8, color: colorPalette.gray.c700Dark}}>{column}</div>
						<ArrowRightIcon style={{fontSize: 14, transform: "rotate(180deg)"}} />
						<SelectInputV5
							options={options}
							selected={selected}
							render={(f) => f.name}
							onChange={(newSelected: IFieldAdapter) => {
								const newFieldMapping = [...fieldMapping];

								newFieldMapping[index] = newSelected;

								setFieldMapping(newFieldMapping);
							}}
						/>
					</ImportMappingRowStyled>
				);
			}
		});
	};

	const onScopeChange = (o: IScopeOption) => setSelectedScopeValue(o.value);

	const getForm = () => {
		switch (_steps[stepIndex]) {
			case "Upload File":
				return (
					<>
						<VerticalFlex>
							<CreatePopupFieldStyled>
								<FieldV5 label="Module">
									{!!props.calledFromThisModule ? (
										<InputTextStyled>{featureTitles[props.calledFromThisModule]}</InputTextStyled>
									) : (
										<SelectInputV5
											options={modules}
											render={(o) => featureTitles[o] as string}
											selected={modules.find((o) => o === feature)}
											onChange={(o) => setFeature(o)}
											disabled={!appState.user?.isAdmin}
											sort={false}
											isSameWidth={true}
											placeholder="(Select Module)"
										/>
									)}
								</FieldV5>
								<FieldV5 label="Scope">
									{scopeOptions.length === 1 ? (
										<InputTextStyled>{scopeOptions[0].label}</InputTextStyled>
									) : (
										<SelectInputV5
											options={scopeOptions}
											render={(o) => o.label}
											selected={scopeOptions.find((o) => o.value === selectedScopeValue)}
											onChange={onScopeChange}
											placeholder="(Select Scope)"
											disabled={!appState.user?.isAdmin}
											isSameWidth={true}
										/>
									)}
								</FieldV5>
							</CreatePopupFieldStyled>
						</VerticalFlex>
						{!excelFile ? (
							<FileDropperReactV5
								accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
								multiple={false}
								onFileInputChange={onFileInputChange}
								purpose="Drag and drop or click to add a new file (.csv or .xlsx). Max file size 5mb."
							/>
						) : (
							<ExcelFileStyled>
								<ExcelIcon />
								<VerticalFlex $flex="1">
									<h4>{excelFile.name}</h4>
									<small>{FileUtils.getSizeLabelOfFile(excelFile)}</small>
								</VerticalFlex>
								<IconButtonV5
									onClick={onFileClear}
									IconComponent={DeleteIcon}
								/>
							</ExcelFileStyled>
						)}
					</>
				);
			case "Match IDs":
				return (
					<VerticalFlex $gap={baseDistance.sm}>
						<FlexCenter $gap="156px">
							<div>Excel Column</div>
							<div>Field</div>
						</FlexCenter>
						<ImportMappingRowStyled>
							<SelectInputV5
								options={excelColumns}
								selected={excelIdColumn}
								onChange={onExcelIdColumnChange}
							/>
							<ArrowRightIcon style={{transform: "rotate(180deg)"}} />
							<div>{featureTitles[feature]} ID</div>
						</ImportMappingRowStyled>
					</VerticalFlex>
				);
			case "Map Columns":
				return (
					<VerticalFlex
						$gap={baseDistance.sm}
						className="colmnMapping"
					>
						<FlexCenter $gap="148px">
							<div>Excel Columns</div>
							<div>{featureTitles[feature]} Fields</div>
						</FlexCenter>
						{getFieldRows()}
					</VerticalFlex>
				);
		}
	};

	const nextLabel = stepIndex < _steps.length - 1 ? "Next" : isLoading ? "Updating..." : "Import";

	const onPopupCloseClick = (e: React.MouseEvent) => {
		props.onClose(e);
	};

	return (
		<PopupV5
			onClose={onPopupCloseClick}
			label={"Import"}
			centerOnScreen={true}
			noButtons={true}
			width="600px"
			height="600px"
			freezeRoot={true}
			closeOnClickOutside={false}
		>
			<FeatureImportPanelStyled>
				<StepIndicatorV5
					steps={_steps}
					currentStepIndex={stepIndex}
					customWidth={320}
				/>
				{getForm()}
			</FeatureImportPanelStyled>
			<Flex
				$flex="1"
				style={{alignSelf: "normal"}}
			/>
			<ButtonContainerStyled>
				{stepIndex > 0 && (
					<ButtonV5
						type="secondary"
						label={"Back"}
						title={"back"}
						onClick={onBackClick}
					/>
				)}
				<ButtonV5
					disabled={!isNextButtonEnabled()}
					label={nextLabel}
					title={nextLabel}
					type="secondary"
					onClick={onNextClick}
				/>
			</ButtonContainerStyled>
		</PopupV5>
	);
};

const InputTextStyled = styled.div`
	font-size: ${fontSize.md};
	margin-left: ${baseDistance.xs};
	padding: ${baseDistance.sm} 0;
`;

const ImportMappingRowStyled = styled.div`
	display: grid;
	grid-template-columns: 200px 24px 200px;
	gap: ${baseDistance.md};
	align-items: center;
`;

const FeatureImportPanelStyled = styled.div`
	${VerticalFlexStyle};
	align-items: center;
	gap: ${baseDistance.lg};

	.FileDropper {
		width: 400px;
		height: 200px;
		align-self: center;
	}

	label + .element {
		min-width: 200px;
		max-width: 200px;
	}

	.colmnMapping {
		max-height: 350px;
		overflow: scroll;
	}
`;

const ExcelFileStyled = styled.div`
	${FlexCenterStyle};
	width: 100%;
	justify-content: center;
	border: solid 1px ${colorPalette.gray.c200Light};
	padding: ${baseDistance.sm};
	box-sizing: border-box;
	border-radius: ${radius.sm};
	gap: ${baseDistance.sm};
	font-size: ${fontSize.md};
	font-weight: ${fontWeight.normal};

	h4 {
		font-weight: ${fontWeight.normal};
		margin: 0;
	}

	small {
		font-weight: ${fontWeight.normal};
		font-size: ${fontSize.md};
		color: ${colorPalette.gray.c400};
	}

	svg {
		width: 24px;
		height: 24px;
	}
`;
