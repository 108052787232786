import {InstancedBufferGeometry, BufferAttribute, Object3D, InstancedMesh, Matrix4} from "three";
import {MSDFTextShaderMaterial} from "../../materials/MSDFTextShaderMaterial";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import type {IInstancedRectangleObject} from "../InstancedRectangleManager";
import {InstancedRectangleManager} from "../InstancedRectangleManager";
import type {MarkupTextBox} from "../../elements3d/markups/MarkupTextBox";
import {calculateCaptionSizeFromTextObject, MarkupSidePadding, MeasureType, paddingLeftRightInPx} from "../../renderers/SpaceViewRendererUtils";
import {Constants} from "../../Constants";
import type {Markup} from "../../../../../../../data/models/Markup";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../../../utils/dom/DomUtils";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import {ColorUtils} from "../../../../../../../utils/ColorUtils";
import type {MarkupType, PointDouble} from "../../../../../../../generated/api/base";
import {getDefaultMarkupFontSize} from "../../elements3d/markups/MarkupText.utils";
import {MarkupsWithIndependentTextSize} from "../../elements3d/markups/MarkupStaticElements";
import type {SupportedFontName} from "../../../../../../../data/state/AppStateTypes";
import {
	doesEveryLineHasTheSameBackground,
	getMaxFontSizeFromObjectWithText,
	getNumberOfChars,
	preprocessObjectWithText,
	textPartsToTextContent,
} from "./TextUtils";
import type {IObjectWithText} from "./TextUtils";
import type {Caption} from "./Caption";
import {MSDFFont} from "./MSDFFont";
export abstract class TextGroupManager {
	// !!!Important!!!
	// If you change this array, you need to modify the MSDFTextShaderMaterial accordingly
	public static readonly supportedFontNames: SupportedFontName[] = ["Arial", "Caveat", "Georgia", "Lobster", "OpenSans", "Roboto", "SourceCodePro"];

	public static fonts: {
		[key in SupportedFontName]: MSDFFont;
	};

	protected _spaceViewRenderer: SpaceViewRenderer;
	private _geometry: InstancedBufferGeometry;
	private _material: MSDFTextShaderMaterial;
	protected _instancedMesh: InstancedMesh;
	private readonly _container: Object3D = new Object3D();
	private readonly _dummyBackground: Object3D = new Object3D();
	private readonly _dummyParent: Object3D = new Object3D();
	private readonly _dummyChild: Object3D = new Object3D();
	private _underlineObjects: IInstancedRectangleObject[] = [];
	private _backgroundObjects: IInstancedRectangleObject[] = [];

	private readonly _underlineManager: InstancedRectangleManager;
	private readonly _backgroundManager: InstancedRectangleManager;
	protected abstract _instancedMeshName: string;
	public updateOnNextFrame: boolean = false;

	private readonly _renderOnTop: boolean = false;

	constructor(spaceViewRenderer: SpaceViewRenderer, renderOnTop: boolean = false) {
		this._spaceViewRenderer = spaceViewRenderer;
		this._renderOnTop = renderOnTop;

		this._container = this._spaceViewRenderer.markupScene;
		this._underlineManager = new InstancedRectangleManager(this._container, this._renderOnTop);
		this._backgroundManager = new InstancedRectangleManager(this._container, this._renderOnTop);
		this._dummyParent.add(this._dummyChild);
	}

	public hide() {
		if (this._instancedMesh) {
			this._instancedMesh.visible = false;
		}
		this._underlineManager.hide();
		this._backgroundManager.hide();

		this._spaceViewRenderer.needsRender = true;
	}

	public show() {
		if (this._instancedMesh) {
			this._instancedMesh.visible = true;
		}
		this._underlineManager.show();
		this._backgroundManager.show();

		this._spaceViewRenderer.needsRender = true;
	}

	public async init() {
		if (!TextGroupManager.fonts) {
			TextGroupManager.fonts = {} as any;
			const promiseArray = [];

			for (const fontName of TextGroupManager.supportedFontNames) {
				TextGroupManager.fonts[fontName] = new MSDFFont(this._spaceViewRenderer, fontName);
				promiseArray.push(TextGroupManager.fonts[fontName].init());
			}

			await Promise.all(promiseArray);
		}

		this.createGeometry(this._objectsWithTexts);
	}

	public clear() {
		if (this._instancedMesh?.parent) {
			this._instancedMesh?.parent?.remove(this._instancedMesh);
			this._instancedMesh.dispose();
		}
	}

	protected abstract get _objectsWithTexts(): IObjectWithText[];

	private get isWebGL2Available() {
		return this._spaceViewRenderer.renderer.capabilities.isWebGL2;
	}

	private createInstancedBufferGeometry(charCount: number) {
		const geometry = new InstancedBufferGeometry();
		const positions = new Float32Array([0, 0, 0, 1, 0, 0, 1, 1, 0, 0, 1, 0]);

		geometry.setAttribute("position", new BufferAttribute(positions, 3));
		geometry.setIndex(new BufferAttribute(new Uint8Array([0, 1, 2, 0, 2, 3]), 1));

		geometry.setAttribute("uvOffset", THREEUtils.createInstancedBufferAttribute(new Float32Array(charCount * 2), 2));
		geometry.setAttribute("uvSize", THREEUtils.createInstancedBufferAttribute(new Float32Array(charCount * 2), 2));
		geometry.setAttribute("glyphSize", THREEUtils.createInstancedBufferAttribute(new Float32Array(charCount * 2), 2));
		geometry.setAttribute("color", THREEUtils.createInstancedBufferAttribute(new Float32Array(charCount * 3), 3));
		geometry.setAttribute("opacity", THREEUtils.createInstancedBufferAttribute(new Float32Array(charCount * 1), 1));
		geometry.setAttribute("isBold", THREEUtils.createInstancedBufferAttribute(new Float32Array(charCount * 1), 1));
		geometry.setAttribute("isItalic", THREEUtils.createInstancedBufferAttribute(new Float32Array(charCount * 1), 1));
		geometry.setAttribute("mapIndex", THREEUtils.createInstancedBufferAttribute(new Float32Array(charCount * 1), 1));

		return geometry;
	}

	private getLinePointerX(parentObjectSize: PointDouble, lineOfText: {width: number}, textHAlign: HorizontalAlignment) {
		const sidePadding: number = MarkupSidePadding * this._spaceViewRenderer.correctionMultiplier.current;

		if (textHAlign === HorizontalAlignment.left) {
			return -parentObjectSize.x / 2 + sidePadding;
		} else if (textHAlign === HorizontalAlignment.center) {
			return -lineOfText.width / 2;
		} // right
		else {
			return parentObjectSize.x / 2 - lineOfText.width - sidePadding;
		}
	}

	private getLinePointerY(parentObjectSize: PointDouble, textObject: {size: PointDouble}, textVAlign: VerticalAlignment) {
		switch (textVAlign) {
			case VerticalAlignment.topOuter:
				return parentObjectSize.y / 2 + textObject.size.y;
			case VerticalAlignment.top:
				return parentObjectSize.y / 2;
			case VerticalAlignment.center:
				return textObject.size.y / 2;
			case VerticalAlignment.bottom:
				return -parentObjectSize.y / 2 + textObject.size.y;
			case VerticalAlignment.bottomOuter:
				return -parentObjectSize.y / 2;
		}
	}

	public static preprocessObjectWithText(objectWithText: IObjectWithText, maxWidth: number, spaceViewRenderer: SpaceViewRenderer) {
		return preprocessObjectWithText(objectWithText, TextGroupManager.fonts, spaceViewRenderer, maxWidth);
	}

	public static isWidthLimited(objectWithText: IObjectWithText) {
		const modelDataMaybe = (objectWithText as MarkupTextBox).modelData as Markup;

		if (MarkupsWithIndependentTextSize.includes(modelDataMaybe?.type)) {
			return false;
		}
		const isTextBoxWithFixedSize = modelDataMaybe?.isSizeFixed;
		const isCaption = !!objectWithText.isCaption;
		return !isCaption && (objectWithText.measureType == null || objectWithText.measureType === MeasureType.NONE || isTextBoxWithFixedSize);
	}

	public static calculateMaxWidth(objectWithText: IObjectWithText, correctionMultiplier: number) {
		const isWidthLimited = this.isWidthLimited(objectWithText);

		return isWidthLimited
			? Math.max((objectWithText.scale?.x ?? Infinity) - paddingLeftRightInPx * correctionMultiplier, 1 * correctionMultiplier)
			: Infinity;
	}

	private createGeometry(objectsWithTexts: IObjectWithText[]) {
		const allChars = getNumberOfChars(objectsWithTexts);

		if (!this._material) {
			this._material = new MSDFTextShaderMaterial(TextGroupManager.fonts, this.isWebGL2Available);
		}
		if (!this._instancedMesh?.parent || (this._instancedMesh?.parent && allChars !== this._geometry.instanceCount)) {
			this._instancedMesh?.parent?.remove(this._instancedMesh);

			if (this._geometry) {
				this._geometry.dispose();
			}

			if (this._instancedMesh) {
				this._instancedMesh.dispose();
			}

			if (allChars > 0) {
				this._geometry = this.createInstancedBufferGeometry(allChars);
				this._instancedMesh = new InstancedMesh(this._geometry, this._material, allChars);
				this._instancedMesh.name = this._instancedMeshName;

				if (this._renderOnTop) {
					THREEUtils.add(this._container, this._instancedMesh);
				} else {
					THREEUtils.addFront(this._container, this._instancedMesh);
				}
			}
		}

		this.updateTextTransformations(objectsWithTexts);
	}

	public recreateGeometry(objectsWithTexts: IObjectWithText[] = this._objectsWithTexts) {
		this.createGeometry(objectsWithTexts);
		if (this._instancedMesh) {
			this._instancedMesh.computeBoundingBox();
		}
	}

	public updateTextTransformations(objectsWithTexts: IObjectWithText[] = this._objectsWithTexts) {
		this.updateOnNextFrame = false;
		if (this._instancedMesh) {
			this._underlineObjects.length = 0;
			this._backgroundObjects.length = 0;
			const zOffset = this._container.position.z;
			const geometry = this._geometry;
			const attributes = geometry.attributes as {[name: string]: BufferAttribute};

			let counter = 0;

			for (const objectWithText of objectsWithTexts) {
				const {position, textHAlign, textVAlign, isVisible, opacity} = objectWithText;
				const textOffset = objectWithText.textOffset ?? {x: 0, y: 0};
				const orientation = objectWithText.orientation ?? 0;
				const dimensionMultiplier = objectWithText.dimensionMultiplier ?? 1;

				const isTextBoxWithFixedSize = ((objectWithText as MarkupTextBox).modelData as Markup)?.isSizeFixed;

				const isCaption = !!objectWithText.isCaption;
				const isWidthLimited = TextGroupManager.isWidthLimited(objectWithText);

				const maxFontSize = getMaxFontSizeFromObjectWithText(objectWithText);
				const ratio = maxFontSize / Constants.SIZE.FONT.default; // should use MAX fontsize?

				objectWithText.textInstanceIds.length = 0;
				const textObject = TextGroupManager.preprocessObjectWithText(
					objectWithText,
					isWidthLimited
						? isTextBoxWithFixedSize
							? objectWithText.scale.x
							: TextGroupManager.calculateMaxWidth(objectWithText, this._spaceViewRenderer.correctionMultiplier.current * ratio)
						: Infinity,
					this._spaceViewRenderer,
				);

				objectWithText.sizeOfGeneratedText = {
					x: textObject.size.x * dimensionMultiplier,
					y: textObject.size.y * dimensionMultiplier,
				};

				if (isCaption) {
					const needsToCalculateInitialPosition = !objectWithText.scale;

					const captionSize = calculateCaptionSizeFromTextObject(textObject, maxFontSize, this._spaceViewRenderer.correctionMultiplier.current);

					objectWithText.scale = {
						x: captionSize.x,
						y: captionSize.y,
					};

					if (needsToCalculateInitialPosition) {
						(objectWithText as Caption).setToDefaultPosition();
					}
				}

				const linePosPointer = {
					x: null as number,
					y: this.getLinePointerY(objectWithText.scale, textObject, textVAlign),
				};

				if (isVisible && !objectWithText.markedForDeletion) {
					const unrotatedPosition: PointDouble = {
						x: position.x + textOffset.x,
						y: position.y + textOffset.y,
					};

					const rotatedPosition = THREEUtils.getRotatedVertex(unrotatedPosition, orientation, position);

					this._dummyParent.scale.set(dimensionMultiplier, dimensionMultiplier, dimensionMultiplier);
					this._dummyParent.position.set(rotatedPosition.x, rotatedPosition.y, zOffset);
					this._dummyParent.rotation.z = orientation + (objectWithText.textOrientation ?? 0);
				} else {
					this._dummyParent.scale.set(0, 0, 0);
				}
				this._dummyParent.updateMatrixWorld();

				const doesEveryLineHaveTheSameBG = doesEveryLineHasTheSameBackground(textObject.lines);

				if (objectWithText.isVisible && doesEveryLineHaveTheSameBG) {
					const backgroundColor = textObject.lines[0].backgroundColor;

					if (backgroundColor) {
						this._dummyBackground.position.copy(this._dummyParent.position);
						this._dummyBackground.rotation.copy(this._dummyParent.rotation);
						this._dummyBackground.scale.set(objectWithText.scale.x, objectWithText.scale.y, 1);
						this._dummyBackground.updateMatrixWorld();

						this._backgroundObjects.push({
							matrix: new Matrix4().copy(this._dummyBackground.matrixWorld),
							color: ColorUtils.hex2Array(backgroundColor.hex).slice(0, 3),
							opacity: (1 - backgroundColor.transparency) * objectWithText.opacity,
							associatedObject: objectWithText,
						});
					}
				}

				const urlsInText = this._spaceViewRenderer.actions.getStartIndexAndContentOfUrlsInText(textPartsToTextContent(objectWithText.text));

				for (const lineOfText of textObject.lines) {
					const {fontFamily, isBold, isItalic, isUnderlined, scaleCorrection, fontSize} = lineOfText;
					const {fontData, atlasScale} = TextGroupManager.fonts[fontFamily];
					const mapIndex = TextGroupManager.supportedFontNames.indexOf(fontFamily);
					const isBoldUniformValue = isBold ? 1.0 : 0.0;
					const isItalicUniformValue = isItalic ? 1.0 : 0.0;

					const fontColorObject = lineOfText.fontColor;
					const fontColor = ColorUtils.hex2Array(fontColorObject.hex).slice(0, 3);
					const fontOpacity = (1 - fontColorObject.transparency) * opacity;
					const lineHeight = TextGroupManager.getLineHeight(fontFamily, fontSize);

					linePosPointer.x = this.getLinePointerX(objectWithText.scale, lineOfText, textHAlign);

					if (isUnderlined) {
						this._dummyChild.position.set(linePosPointer.x + lineOfText.width / 2, linePosPointer.y - lineHeight * 0.85, 0);
						this._dummyChild.scale.set(lineOfText.width, scaleCorrection * 3, 1);
						this._dummyChild.updateMatrixWorld(true);
						this._underlineObjects.push({
							matrix: new Matrix4().copy(this._dummyChild.matrixWorld),
							color: fontColor,
							opacity: fontOpacity,
							associatedObject: objectWithText,
						});
					}
					for (const quad of lineOfText.quads) {
						objectWithText.textInstanceIds.push(counter);

						const finalFontColor = [...fontColor];

						if (urlsInText.length > 0) {
							const link = this._spaceViewRenderer.actions.getLinkFromMsdfTextInstanceId(objectWithText, counter);

							if (link) {
								finalFontColor[0] = 0;
								finalFontColor[1] = 0;
								finalFontColor[2] = 0.9333;
							}
						}

						const glyph = quad.glyph;

						this._dummyChild.position.set(
							linePosPointer.x + quad.position.x,
							linePosPointer.y + (fontData.common.lineHeight - glyph.height - glyph.yoffset - fontData.common.lineHeight) * scaleCorrection,
							0,
						);
						this._dummyChild.scale.set(glyph.width * scaleCorrection, glyph.height * scaleCorrection, 1);
						this._dummyChild.updateMatrixWorld(true);
						this._instancedMesh.setMatrixAt(counter, this._dummyChild.matrixWorld);

						THREEUtils.setAttributeXY(attributes.uvOffset, counter, glyph.x / atlasScale.x, (atlasScale.y - glyph.y - glyph.height) / atlasScale.y);
						THREEUtils.setAttributeXY(attributes.uvSize, counter, glyph.width / atlasScale.x, glyph.height / atlasScale.y);
						THREEUtils.setAttributeXY(attributes.glyphSize, counter, glyph.width * scaleCorrection, glyph.height * scaleCorrection);
						THREEUtils.setAttributeXYZ(attributes.color, counter, finalFontColor[0], finalFontColor[1], finalFontColor[2]);
						THREEUtils.setAttributeX(attributes.opacity, counter, fontOpacity);
						THREEUtils.setAttributeX(attributes.isBold, counter, isBoldUniformValue);
						THREEUtils.setAttributeX(attributes.isItalic, counter, isItalicUniformValue);
						THREEUtils.setAttributeX(attributes.mapIndex, counter, mapIndex);

						counter++;
					}
					const prevLinePosPointerY = linePosPointer.y;

					linePosPointer.y -= lineHeight;

					if (isVisible && !doesEveryLineHaveTheSameBG && lineOfText.backgroundColor) {
						this._dummyBackground.position.set(
							this._dummyParent.position.x,
							this._dummyParent.position.y + (prevLinePosPointerY + linePosPointer.y) / 2,
							this._dummyParent.position.z,
						);
						this._dummyBackground.rotation.copy(this._dummyParent.rotation);
						this._dummyBackground.scale.set(objectWithText.scale.x, Math.abs(prevLinePosPointerY - linePosPointer.y), 1);
						this._dummyBackground.updateMatrixWorld();

						const backgroundColor = lineOfText.backgroundColor;

						this._backgroundObjects.push({
							matrix: new Matrix4().copy(this._dummyBackground.matrixWorld),
							color: ColorUtils.hex2Array(backgroundColor.hex).slice(0, 3),
							opacity: (1 - backgroundColor.transparency) * objectWithText.opacity,
							associatedObject: objectWithText,
						});
					}
				}
			}

			this._underlineManager.update(this._underlineObjects);
			this._backgroundManager.update(this._backgroundObjects);

			if (this._instancedMesh) {
				this._instancedMesh.instanceMatrix.needsUpdate = true;
				if (this._renderOnTop && this._instancedMesh.parent) {
					THREEUtils.renderToTop(this._instancedMesh);
				}
			}

			this._spaceViewRenderer.needsRender = true;
		}
	}

	// markForDeletion: if true, they stay invisible forever, you need to call "recreate" to get them back
	// used when deleting something
	public hideTextGroup(forObjects: IObjectWithText[], markForDeletion: boolean = false) {
		this._dummyChild.scale.set(0, 0, 0);
		this._dummyChild.updateMatrix();

		for (const forObject of forObjects) {
			if (markForDeletion) {
				forObject.markedForDeletion = true;
			}

			for (const textInstanceId of forObject.textInstanceIds) {
				this._instancedMesh.setMatrixAt(textInstanceId, this._dummyChild.matrix);
			}
		}

		this._underlineObjects = this._underlineObjects.filter((value) => !forObjects.includes(value.associatedObject));
		this._backgroundObjects = this._backgroundObjects.filter((value) => !forObjects.includes(value.associatedObject));

		this._underlineManager.update(this._underlineObjects);
		this._backgroundManager.update(this._backgroundObjects);

		if (this._instancedMesh) {
			this._instancedMesh.instanceMatrix.needsUpdate = true;
		}
		this._spaceViewRenderer.needsRender = true;
	}

	public get instancedMesh() {
		return this._instancedMesh;
	}

	public static getLineHeight(fontName: SupportedFontName, fontSize: number) {
		return TextGroupManager.fonts[fontName].getLineHeight(fontSize);
	}

	public static getDefaultMarkupTextBoxSize(
		type: MarkupType,
		fontFamily: SupportedFontName,
		cameraZoomValue: number,
		correctionMultiplier: number,
	): PointDouble {
		const fontSize = getDefaultMarkupFontSize(type, cameraZoomValue);
		return {
			x: fontSize * 6.25 * correctionMultiplier,
			y: TextGroupManager.getLineHeight(fontFamily, fontSize),
		};
	}
}
