import * as React from "react";
import {inject, observer} from "mobx-react";
import type {Vector3} from "three";
import type {Xyicon3D} from "../../logic3d/elements3d/Xyicon3D";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {LinkBreakers} from "../actionbar/LinkBreakers";
import {Functions} from "../../../../../../utils/function/Functions";
import type {Pointer} from "../../../../../../utils/interaction/Pointer";
import type {Catalog} from "../../../../../../data/models/Catalog";
import {CatalogIconType, XyiconFeature, Permission} from "../../../../../../generated/api/base";
import type {IXyiconMinimumData, Xyicon} from "../../../../../../data/models/Xyicon";
import {HTMLUtils} from "../../../../../../utils/HTML/HTMLUtils";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import type {IModel} from "../../../../../../data/models/Model";
import {KeyboardListener} from "../../../../../../utils/interaction/key/KeyboardListener";
import type {GridView} from "../../../../abstract/grid/GridView";
import type {TableRow} from "../../../../../widgets/table/TableRow";
import {notify} from "../../../../../../utils/Notify";
import {NotificationType} from "../../../../../notification/Notification";
import type {Link} from "../../../../../../data/models/Link";
import type {ICreateUnplottedXyiconParam} from "../../../../abstract/ModuleView";
import type {CreateXyiconRequest, XyiconCreateDetail, XyiconLinkDetail} from "../../../../../../generated/api/base";
import {ImageUploadPreprocessor} from "../../../../../../utils/image/ImageUploadPreprocessor";
import type {AppState} from "../../../../../../data/state/AppState";
import {XyiconUtils} from "../../../../../../data/models/XyiconUtils";
import {MathUtils} from "../../../../../../utils/math/MathUtils";
import {Constants} from "../../logic3d/Constants";
import {DraggableXyiconCatalogItem} from "./DraggableXyiconCatalogItem";
import {isUserTryingToPutItOnTopOfHoveredItems} from "./EmbeddedUtils";

interface IDraggableXyiconCatalogContainerProps {
	readonly appState?: AppState;
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly gridView?: React.RefObject<GridView<IModel>>;
	readonly feature: XyiconFeature.XyiconCatalog | XyiconFeature.Xyicon;
	readonly onPointerMove: () => void;
	readonly onPointerUp: () => void;
	readonly onDuplicateCatalogClick: (catalog: Catalog) => void;
	readonly onCreateUnplottedXyicons: (params: ICreateUnplottedXyiconParam[]) => Promise<void> | void;
	readonly items: {object: Catalog | Xyicon; userData?: Link}[];
	readonly queryString: string;
}

interface IDraggableXyiconCatalogContainerState {
	selectedItems: (Xyicon | Catalog)[];
}

@inject("appState")
@observer
export class DraggableXyiconCatalogContainer extends React.Component<IDraggableXyiconCatalogContainerProps, IDraggableXyiconCatalogContainerState> {
	public static readonly defaultProps: Partial<IDraggableXyiconCatalogContainerProps> = {
		onPointerMove: Functions.emptyFunction,
		onPointerUp: Functions.emptyFunction,
	};

	private _previousRow: TableRow<IModel> | null = null;
	private _infoBubble: HTMLElement | null = null;

	private get _iconSize() {
		return XyiconUtils.iconSize;
	}

	private _draggedIcons: HTMLElement[] = [];
	private _areDraggedIconsEmbeddable: boolean = false;
	private _hasCursorMoved: boolean = false;
	private _lastPageX: number;
	private _lastPageY: number;
	private _hoveredXyicon: Xyicon3D | null = null;
	private _worldPointOnHoveredXyicon: Vector3 | null = null;
	private _previousCursorStyle: string = "";

	constructor(props: IDraggableXyiconCatalogContainerProps) {
		super(props);

		this.state = {
			selectedItems: [],
		};
	}

	private areDraggedXyiconsEmbeddable() {
		if (this.props.feature === XyiconFeature.Xyicon) {
			for (const item of this.state.selectedItems) {
				if ((item as Xyicon)?.embeddedXyicons.length > 0) {
					return false;
				}
			}
		}

		return true;
	}

	private updateLastPageXY(pointer: {pageX: number; pageY: number}) {
		const {spaceViewRenderer} = this.props;

		if (spaceViewRenderer.isMounted) {
			// SpaceEditor
			const {snapToGridManager} = spaceViewRenderer.spaceItemController;

			if (snapToGridManager.isActive) {
				const {domElement} = spaceViewRenderer;
				const size = HTMLUtils.getSize(domElement);
				const clientX = pointer.pageX - size.x;
				const clientY = pointer.pageY - size.y;

				const worldCoords = THREEUtils.domCoordinatesToWorldCoordinates(
					clientX,
					clientY,
					spaceViewRenderer.spaceOffset.z,
					domElement,
					spaceViewRenderer.activeCamera,
				);

				if (worldCoords) {
					const snappedWorldCoords = snapToGridManager.getUpdatedCoords(worldCoords.x, worldCoords.y);
					const snappedDomCoords = THREEUtils.worldCoordinatesToDomCoordinates(
						snappedWorldCoords.x,
						snappedWorldCoords.y,
						spaceViewRenderer.spaceOffset.z,
						domElement,
						spaceViewRenderer.activeCamera,
					);

					this._lastPageX = snappedDomCoords.x + size.x;
					this._lastPageY = snappedDomCoords.y + size.y;
				}
			} else {
				this._lastPageX = pointer.pageX;
				this._lastPageY = pointer.pageY;
			}
		} else {
			// Xyicon module table
			this._lastPageX = pointer.pageX;
			this._lastPageY = pointer.pageY;
		}
	}

	private addItemsToBody() {
		for (const item of this.state.selectedItems) {
			const draggedIcon = document.createElement("div");

			draggedIcon.setAttribute("data-id", item.id);
			draggedIcon.className = "glyph";
			draggedIcon.style.backgroundImage = `url('${item.thumbnail}')`;
			draggedIcon.style.transform = (item as Xyicon).backgroundTransform || "";
			draggedIcon.style.width = `${this._iconSize}px`;
			draggedIcon.style.height = `${this._iconSize}px`;

			if (item.iconCategory === CatalogIconType.ModelParameter) {
				const catalog = this.getCatalogFromElement(draggedIcon);
				const {space, correctionMultiplier} = this.props.spaceViewRenderer;

				if (space) {
					ImageUploadPreprocessor.getTopToBottomSnapshotOfCatalog(
						catalog,
						this.props.spaceViewRenderer.actions,
						space,
						correctionMultiplier.current,
					).then((ret) => {
						if (ret.image !== item.thumbnail) {
							const standardXyiconSize = this.props.spaceViewRenderer.xyiconManager.xyiconSize;
							const imageSize = (this._iconSize * Math.max(ret.xyiconSize.x, ret.xyiconSize.y)) / standardXyiconSize;

							draggedIcon.style.backgroundImage = `url('${ret.image}')`;
							draggedIcon.style.width = `${imageSize}px`;
							draggedIcon.style.height = `${imageSize}px`;
						}
					});
				}
			}

			draggedIcon.style.position = "absolute";
			draggedIcon.style.backgroundSize = "contain";
			draggedIcon.style.zIndex = "8999"; // popupwindow - 1
			// We need the "mousemove" event on the "tablerow", so we have to "pass this through"
			// https://stackoverflow.com/questions/1009753/pass-mouse-events-through-absolutely-positioned-element
			draggedIcon.style.pointerEvents = "none";
			draggedIcon.classList.add("hidden");

			this._draggedIcons.push(draggedIcon);
		}

		// Add it in reverse order, so the first one will be on "top" when rendering
		for (let i = this._draggedIcons.length - 1; i >= 0; --i) {
			document.body.appendChild(this._draggedIcons[i]);
		}

		this._areDraggedIconsEmbeddable = this.areDraggedXyiconsEmbeddable();
	}

	private getModelFromElement(element: Element): Catalog | Xyicon {
		const id = element.getAttribute("data-id");

		return (
			this.props.spaceViewRenderer.actions.getFeatureItemById<Catalog>(id, XyiconFeature.XyiconCatalog) ||
			this.props.spaceViewRenderer.actions.getFeatureItemById<Xyicon>(id, XyiconFeature.Xyicon)
		);
	}

	private getCatalogFromElement(element: Element): Catalog {
		const item = this.getModelFromElement(element);

		return item.ownFeature === XyiconFeature.XyiconCatalog ? item : item.catalog;
	}

	private onGlyphPointerDown = (pointer: Pointer) => {
		const item = this.getModelFromElement(pointer.currentTarget);

		if (item) {
			const newSelectedItems = [...this.state.selectedItems];

			const indexOfItem = newSelectedItems.indexOf(item);

			if (indexOfItem > -1) {
				if (KeyboardListener.isCtrlDown) {
					newSelectedItems.splice(indexOfItem, 1);

					this.setState({
						selectedItems: newSelectedItems,
					});
				}
			} else {
				if (!KeyboardListener.isCtrlDown) {
					newSelectedItems.length = 0;
				}
				newSelectedItems.push(item);

				this.setState({
					selectedItems: newSelectedItems,
				});
			}
		}

		this._hasCursorMoved = false;

		if (this.props.gridView?.current) {
			this.props.gridView.current.signals.onMouseMoveOnRow.add(this.onMouseMoveOnRow);
		}

		if (this.props.spaceViewRenderer.isMounted) {
			// Temporarily disable the active tool until pointer-up
			this.props.spaceViewRenderer.toolManager.activeTool.deactivate();
		}
	};

	private onGlyphPointerMove = (pointer: Pointer) => {
		const {spaceViewRenderer} = this.props;

		if (pointer.dx !== 0 || pointer.dy !== 0) {
			this._hasCursorMoved = true;

			if (this._draggedIcons.length === 0 && !KeyboardListener.isCtrlDown) {
				this.props.onPointerMove();

				this._previousCursorStyle = document.body.style.cursor;
				document.body.style.cursor = "grabbing";

				if (spaceViewRenderer.isMounted) {
					// SpaceEditor
					const {boundaryManager, xyiconManager} = spaceViewRenderer;

					if (!boundaryManager.isAddingItemsToServer && !xyiconManager.isAddingItemsToServer) {
						this.addItemsToBody();
					}
				} else {
					// Xyicon module table
					this.addItemsToBody();
				}
			} else if (this._draggedIcons.length > 0) {
				document.body.style.cursor = "grabbing";

				if (this._hoveredXyicon) {
					if (this._hoveredXyicon.modelData) {
						const permission = this.props.spaceViewRenderer.actions.getModuleTypePermission(
							this._hoveredXyicon.modelData.typeId,
							this._hoveredXyicon.modelData.ownFeature,
						);

						if (permission < Permission.Update) {
							document.body.style.cursor = "not-allowed";
						}
					}
				}
			}
		}

		if (!this.isCursorOverTable(pointer.pageX, pointer.pageY)) {
			this.resetPreviousRow();
			this._infoBubble?.remove();
			this._infoBubble = null;
		}

		if (this._draggedIcons.length > 0) {
			for (const element of this._draggedIcons) {
				element.classList.remove("hidden");
			}
			this.updateLastPageXY(pointer);
			this.updateDraggedIcons();

			if (this._infoBubble) {
				this._infoBubble.style.transform = `translate(${pointer.pageX}px, ${pointer.pageY - this._iconSize / 2 - 10}px) translate(-50%, -100%)`;
			}
		}
	};

	private onGlyphPointerUp = async (pointer: Pointer) => {
		document.body.style.cursor = this._previousCursorStyle;

		if (this._draggedIcons.length > 0 && this._hasCursorMoved) {
			this.props.onPointerUp();
			const spaceViewRenderer = this.props.spaceViewRenderer;
			const domElement = spaceViewRenderer.domElement;
			const itemsAndOffsets = this._draggedIcons.map((icon: HTMLElement, index: number) => {
				return {
					item: this.getModelFromElement(icon),
					offset: THREEUtils.getSpiralCoordinate(index),
				};
			});

			for (const draggedIcon of this._draggedIcons) {
				HTMLUtils.detach(draggedIcon);
			}
			this._draggedIcons.length = 0;
			this._areDraggedIconsEmbeddable = false;

			if (spaceViewRenderer.isMounted) {
				const parentXyicon3Ds: (Xyicon3D | undefined)[] = this._hoveredXyicon?.isSelected
					? ([...spaceViewRenderer.spaceItemController.xyiconManager.selectedItems] as Xyicon3D[])
					: [this._hoveredXyicon];
				const createNonEmbeddedXyiconList: Xyicon3D[] = [];
				const createXyiconList: Xyicon3D[] = []; // contains "createNonEmbeddedXyiconList"
				let type: "embedded" | "unplotted" | "brandNew" = null;

				if (this.isCursorOverSpace(this._lastPageX, this._lastPageY)) {
					// Add xyicon
					const size = HTMLUtils.getSize(domElement);
					const clientX = this._lastPageX - size.x;
					const clientY = this._lastPageY - size.y;

					const finalZ =
						this.isUserTryingToPutItOnTopOfHoveredItems && MathUtils.isValidNumber(this._worldPointOnHoveredXyicon?.z)
							? this._worldPointOnHoveredXyicon.z + Constants.EPSILON * spaceViewRenderer.correctionMultiplier.current
							: spaceViewRenderer.spaceOffset.z;
					const worldCoordinates = THREEUtils.domCoordinatesToWorldCoordinates(clientX, clientY, finalZ, domElement, spaceViewRenderer.activeCamera);

					if (worldCoordinates) {
						let currentParentXyiconModel: Xyicon = null; // used only if we unembed xyicons
						let selectedEmbeddedXyicons: number = 0;

						for (const parentXyicon3D of parentXyicon3Ds) {
							let parentXyiconModel: Xyicon | null = (parentXyicon3D?.modelData as Xyicon) || null;

							if (parentXyiconModel) {
								if (parentXyiconModel.isEmbedded) {
									selectedEmbeddedXyicons++;
									parentXyiconModel = null; // we don't allow embedding xyicons to be embedded
								}

								const permission = this.props.spaceViewRenderer.actions.getModuleTypePermission(
									parentXyiconModel.typeId,
									parentXyiconModel.ownFeature,
								);

								if (permission < Permission.Update) {
									continue; // we don't allow embedding to xyicons with show permission
								}
							}
							const xyicon3DArray: Xyicon3D[] = [];

							for (const itemAndOffset of itemsAndOffsets) {
								const catalog = itemAndOffset.item.ownFeature === XyiconFeature.XyiconCatalog ? itemAndOffset.item : itemAndOffset.item.catalog;
								const xyiconMinimumData: IXyiconMinimumData = {
									iconX: worldCoordinates.x + itemAndOffset.offset.x * spaceViewRenderer.xyiconManager.xyiconSize,
									iconY: worldCoordinates.y + itemAndOffset.offset.y * spaceViewRenderer.xyiconManager.xyiconSize,
									iconZ: worldCoordinates.z,
									orientation: 0,
									catalogId: catalog.id,
									catalog: catalog,
									embeddedXyicons: [],
									parentXyicon: parentXyiconModel,
									fieldData: {},
									portData: [],
								};

								const xyiconModel = itemAndOffset.item.ownFeature === XyiconFeature.Xyicon ? itemAndOffset.item : null;
								const isXyicon = this.props.feature === XyiconFeature.Xyicon;
								const isEmbeddedXyicon = isXyicon && !!xyiconModel?.isEmbedded;
								const isUnplottedXyicon = isXyicon && !xyiconModel?.spaceId;

								if (isEmbeddedXyicon || isUnplottedXyicon) {
									xyiconModel.setSpaceId(spaceViewRenderer.space.id);
									xyiconModel.setPosition(xyiconMinimumData.iconX, xyiconMinimumData.iconY, xyiconMinimumData.iconZ);
									xyiconModel.setOrientation(xyiconMinimumData.orientation);
								}

								const existingXyicon3D = xyiconModel && (spaceViewRenderer.xyiconManager.getItemById(xyiconModel.id) as Xyicon3D);

								if (existingXyicon3D) {
									existingXyicon3D.updateByModel(xyiconModel);
								}
								const xyicon3D =
									existingXyicon3D || (await spaceViewRenderer.xyiconManager.createSpaceItem3DFromModel(xyiconModel || xyiconMinimumData));

								if (isEmbeddedXyicon) {
									const isHidden = isEmbeddedXyicon;

									xyicon3D.setVisibility(!isHidden);
								}

								xyicon3DArray.push(xyicon3D);

								if (!type) {
									if (isEmbeddedXyicon) {
										currentParentXyiconModel = xyiconModel.parentXyicon;
										type = "embedded";
									} else if (isUnplottedXyicon) {
										type = "unplotted";
									} else {
										type = "brandNew";
									}
								}
							}

							if (type) {
								if (type === "embedded" || type === "unplotted") {
									await spaceViewRenderer.xyiconManager.updateItems(xyicon3DArray, true);
									if (parentXyiconModel) {
										const parentXyicon3D = spaceViewRenderer.xyiconManager.getItemById(parentXyiconModel.id) as Xyicon3D;

										if (parentXyicon3D) {
											await spaceViewRenderer.toolManager.linkManager.embedXyiconsIntoParentXyicon(xyicon3DArray, parentXyicon3D);
											spaceViewRenderer.spaceItemController.deselectAll();
											parentXyicon3D.select();
											spaceViewRenderer.spaceItemController.updateActionBar();
										}
									} else if (type === "embedded") {
										// Create regular links between the parentxyicon and the xyicons that just became unembedded
										const linkDetails: XyiconLinkDetail[] = [];

										for (const xyicon3D of xyicon3DArray) {
											linkDetails.push({
												fromXyiconID: currentParentXyiconModel.id,
												fromPortID: null,
												toXyiconID: xyicon3D.modelData.id,
												toPortID: null,
												isEmbedded: false,
											});
										}

										spaceViewRenderer.toolManager.linkManager.sendCreateRequest({
											fromPortfolioID: currentParentXyiconModel.portfolioId,
											toPortfolioID: currentParentXyiconModel.portfolioId,
											xyiconLinkDetails: linkDetails,
										});

										// Selecting these is not very easy (first attempt was pretty buggy, so I removed it),
										// because the response for the link change is handled by signalR
										// If the xyicon is still embedded we can't select it. So we need to wait for the signalR response first
										// We'll get back to this if the production team requests it
									}
								}

								if (type === "unplotted" || type === "brandNew") {
									createNonEmbeddedXyiconList.push(...xyicon3DArray);
									createXyiconList.push(...xyicon3DArray);
									if (type === "unplotted") {
										for (const xyicon3D of xyicon3DArray) {
											const embeddedXyicons = (xyicon3D.modelData as Xyicon).embeddedXyicons;

											for (const embeddedXyicon of embeddedXyicons) {
												createXyiconList.push(await spaceViewRenderer.xyiconManager.createSpaceItem3DFromModel(embeddedXyicon));
											}
										}
									}
								}
							}
						}

						if (createXyiconList.length > 0) {
							await this.props.spaceViewRenderer.xyiconManager.add(createXyiconList, type === "brandNew", true);

							for (const xyicon3D of createNonEmbeddedXyiconList) {
								xyicon3D.addCaption(spaceViewRenderer.xyiconManager.captionManager.getActiveCaptionFields());
							}
						}

						this.triggerErrorNotificationAboutEmbeddedXyicons(selectedEmbeddedXyicons, parentXyicon3Ds.length);

						for (const parentXyicon3D of parentXyicon3Ds) {
							parentXyicon3D?.updateCounter();
							parentXyicon3D?.mouseOut();
						}

						spaceViewRenderer.xyiconManager.captionManager.recreateGeometry();
						spaceViewRenderer.spaceItemController.captionCollisionSolver.updateCaptionsInARadius(
							worldCoordinates.x,
							worldCoordinates.y,
							spaceViewRenderer.xyiconManager.xyiconSize * 10,
						);
					}
				}

				this.props.spaceViewRenderer.spaceItemController.linkIconManager.update();
			} else if (this._previousRow) {
				// Xyicon module table

				//
				// Unplotted
				//
				const catalogIds = itemsAndOffsets.map((val) => val.item.id);
				const {isGreyedOut, highlight, isNotAllowed} = this._previousRow.state;
				// isNotAllowed state added to represent the xyiconPermission

				if (highlight === "Top" || highlight === "Bottom") {
					await this.props.onCreateUnplottedXyicons(catalogIds.map((c) => ({catalogId: c, parentXyiconId: null})));
				} else if (highlight === "Middle" && !isGreyedOut && !isNotAllowed) {
					const hoveredXyiconModel = this._previousRow.props.item as Xyicon;

					const parentXyicons = this._previousRow.props.selected ? (this.props.gridView.current.props.selected as Xyicon[]) : [hoveredXyiconModel];

					let selectedEmbeddedXyicons: number = 0;

					const createUnplottedParams: ICreateUnplottedXyiconParam[] = [];
					const createRegularParams: XyiconCreateDetail[] = [];

					const {appState} = spaceViewRenderer.transport;
					const {actions} = appState;

					for (const parentXyicon of parentXyicons) {
						if (parentXyicon.isEmbedded) {
							selectedEmbeddedXyicons++;
						} else {
							if (parentXyicon.isUnplotted) {
								createUnplottedParams.push(...catalogIds.map((c) => ({catalogId: c, parentXyiconId: parentXyicon.id})));
							} else {
								const xyiconCoords = {
									x: parentXyicon.iconX,
									y: parentXyicon.iconY,
									z: parentXyicon.iconZ,
								};

								createRegularParams.push(
									...catalogIds.map((cId) => ({
										spaceID: parentXyicon.spaceId,
										xyiconCatalogID: cId,
										iconX: xyiconCoords.x,
										iconY: xyiconCoords.y,
										iconZ: xyiconCoords.z,
										orientation: 0,
										parentXyiconID: parentXyicon.id,
										fieldValues: {},
										portData: [],
										linkedBoundarySpaceMapList: actions
											.getLinksXyiconBoundary(parentXyicon.id)
											.flatMap((l) =>
												[...l.object.boundarySpaceMaps]
													.filter((bsm) => THREEUtils.isPointInsidePolygon(xyiconCoords, bsm.geometryData))
													.map((bsm) => bsm.id),
											),
									})),
								);
							}
						}
					}

					const promises: Promise<Xyicon[] | void>[] = [];

					if (createUnplottedParams.length > 0) {
						promises.push(this.props.onCreateUnplottedXyicons(createUnplottedParams) as Promise<void>);
					}
					if (createRegularParams.length > 0) {
						const createData: CreateXyiconRequest = {
							portfolioID: appState.portfolioId,
							xyiconCreateDetailList: createRegularParams,
						};

						promises.push(spaceViewRenderer.transport.services.feature.create(createData, XyiconFeature.Xyicon) as Promise<Xyicon[]>);
					}

					await Promise.all(promises);

					this.triggerErrorNotificationAboutEmbeddedXyicons(selectedEmbeddedXyicons, parentXyicons.length);
				}
			}
		}

		this._hoveredXyicon?.mouseOut();
		this._hoveredXyicon = null;
		this._worldPointOnHoveredXyicon = null;
		this._hasCursorMoved = false;

		this._infoBubble?.remove();
		this._infoBubble = null;
		this.resetPreviousRow();
		if (this.props.gridView?.current) {
			this.props.gridView.current.signals.onMouseMoveOnRow.remove(this.onMouseMoveOnRow);
		}

		if (this.props.spaceViewRenderer.isMounted) {
			this.props.spaceViewRenderer.toolManager.activeTool.activate();
		}
	};

	private triggerErrorNotificationAboutEmbeddedXyicons(selectedEmbeddedXyicons: number, parentXyicons: number) {
		if (selectedEmbeddedXyicons > 0 && parentXyicons > 0) {
			notify(this.props.spaceViewRenderer.transport.appState.app.notificationContainer, {
				title: "Error",
				description: `${selectedEmbeddedXyicons} of ${parentXyicons} selected xyicons are embedded xyicons. You cannot embed xyicons into already embedded xyicons.`,
				type: NotificationType.Error,
			});
		}
	}

	private onGlyphMouseWheel = (event: WheelEvent) => {
		if (this._draggedIcons.length > 0) {
			if (this.isCursorOverSpace(event.pageX, event.pageY)) {
				this.updateLastPageXY(event);
				const spaceViewRenderer = this.props.spaceViewRenderer;

				spaceViewRenderer.toolManager.cameraControls.onMouseWheel(event);
			}
		}
	};

	private isCursorOverSpace(pageX: number, pageY: number): boolean {
		if (!this.props.spaceViewRenderer.isMounted) {
			return false;
		}

		return this.isCursorOverElement(pageX, pageY, this.props.spaceViewRenderer.domElement);
	}

	private isCursorOverTable(pageX: number, pageY: number) {
		if (this.props.spaceViewRenderer.isMounted) {
			return false;
		}

		const tableElement = this.props.appState.tableComponent.current?.container.current;

		return tableElement && this.isCursorOverElement(pageX, pageY, tableElement);
	}

	private isCursorOverElement(pageX: number, pageY: number, element: Element) {
		const hoverElements = document.elementsFromPoint(pageX, pageY);

		for (const hoverElement of hoverElements) {
			if (HTMLUtils.isDescendant(element, hoverElement)) {
				return true;
			}
		}

		return false;
	}

	private createInfoBubble() {
		const div = document.createElement("div");

		div.style.top = "0";
		div.style.left = "0";
		div.className = "InfoBubble flexCenter";

		return div;
	}

	private updateDraggedIcons = () => {
		const spaceViewRenderer = this.props.spaceViewRenderer;
		const selectedXyicon3Ds = spaceViewRenderer.xyiconManager.selectedItems as Xyicon3D[];
		const correctionMultiplier = spaceViewRenderer.isMounted ? spaceViewRenderer.correctionMultiplier.current : 1;
		const currentZoomValue = spaceViewRenderer.isMounted ? spaceViewRenderer.currentZoomValue : 1;
		const xyiconSize = XyiconUtils.getXyiconSize(spaceViewRenderer);

		const standardOffset = 5;

		for (let i = 0; i < this._draggedIcons.length; ++i) {
			const draggedIcon = this._draggedIcons[i];
			const item = this.getModelFromElement(draggedIcon);
			const catalog = item.ownFeature === XyiconFeature.XyiconCatalog ? item : item.catalog;
			const insertionInfo = catalog.insertionInfo;

			const iconSize = this._iconSize * xyiconSize;

			const spiralCoord = THREEUtils.getSpiralCoordinate(i);
			let offsetX = spaceViewRenderer.isMounted ? iconSize * spiralCoord.x : i * standardOffset;
			let offsetY = spaceViewRenderer.isMounted ? -iconSize * spiralCoord.y : i * standardOffset;

			offsetX += (insertionInfo.offsetX / correctionMultiplier) * currentZoomValue;
			offsetY += (insertionInfo.offsetY / correctionMultiplier) * currentZoomValue;

			draggedIcon.style.transform = `translate(${this._lastPageX + offsetX}px, ${this._lastPageY + offsetY}px) translate(-50%, -50%) ${XyiconUtils.getScaleForCSSXyicon(item as Xyicon, spaceViewRenderer)}`;

			if (spaceViewRenderer.isMounted) {
				// SpaceEditor

				// Check for xyicon (if it's over an existing xyicon, we embed the grabbed one into that on release)
				const domElement = spaceViewRenderer.domElement;
				const size = HTMLUtils.getSize(domElement);
				const clientX = this._lastPageX - size.x;
				const clientY = this._lastPageY - size.y;

				this._hoveredXyicon?.mouseOut();
				for (const selectedXyicon of selectedXyicon3Ds) {
					selectedXyicon.mouseOut();
				}
				this._hoveredXyicon = null;

				if (this._areDraggedIconsEmbeddable) {
					const intersection = spaceViewRenderer.spaceItemController.getMeshAtCoords(
						clientX,
						clientY,
						spaceViewRenderer.xyiconManager.getIntersectables(),
					);
					const {meshAtCoords} = intersection;

					this._worldPointOnHoveredXyicon = intersection.point;

					if (meshAtCoords) {
						if (this.isUserTryingToPutItOnTopOfHoveredItems) {
							// console.log(`x: ${this._worldPointOnHoveredXyicon.x}, y: ${this._worldPointOnHoveredXyicon.y}, z: ${this._worldPointOnHoveredXyicon.z}`);
						} else {
							this._hoveredXyicon = spaceViewRenderer.spaceItemController.getSpaceItemFromMeshAtCoords(meshAtCoords) as Xyicon3D;

							if (this._hoveredXyicon) {
								this._hoveredXyicon.mouseOver(true);
								if (this._hoveredXyicon.isSelected) {
									for (const selectedXyicon of selectedXyicon3Ds) {
										selectedXyicon.mouseOver(true);
									}
								}
							}
						}
					}
				}
			}
		}
	};

	private resetPreviousRow() {
		this._previousRow?.setState({
			highlight: "None",
			isGreyedOut: false,
			dragNdrop: false,
			isNotAllowed: false,
		});
	}

	private onMouseMoveOnRow = (pointer: Pointer, row: TableRow<IModel>) => {
		if (this._previousRow !== row) {
			this.resetPreviousRow();
		}

		// used this solution because of performance issues
		if (!this._infoBubble) {
			this._infoBubble = this.createInfoBubble();
			document.body.appendChild(this._infoBubble);
		}

		const areMultipleXyiconsBeingDragged = this._draggedIcons.length > 1;

		this._infoBubble.textContent = `Drop here to add as Unplotted ${areMultipleXyiconsBeingDragged ? "Xyicons" : "Xyicon"}`;

		const size = HTMLUtils.getSize(pointer.currentTarget);

		const hoveredItem = row.props.item;
		const hoveredItemPermission = this.props.spaceViewRenderer.actions.getModuleTypePermission(hoveredItem?.typeId, XyiconFeature.Xyicon);

		const threshold = 7;

		row.setState({
			isGreyedOut: false,
			dragNdrop: true,
			isNotAllowed: false,
		});

		if (pointer.pageY - size.top < threshold) {
			row.setState({
				highlight: "Top",
			});
		} else if (size.bottom - pointer.pageY < threshold) {
			row.setState({
				highlight: "Bottom",
			});
		} else {
			row.setState({
				highlight: "Middle",
			});

			if (hoveredItemPermission < Permission.Update) {
				this._infoBubble.classList.add("SpaceItem");
				this._infoBubble.innerHTML = `<svg class="icon">
						<use xlink:href="#icon-locked"></use>
					</svg>
					<div>
						You do not have permission to embed a catalog item into this xyicon.
					</div>`;
				row.setState({
					isNotAllowed: true,
				});
			} else {
				if (hoveredItem?.ownFeature === XyiconFeature.Xyicon && (hoveredItem as Xyicon).isEmbedded) {
					this._infoBubble.textContent = "Not allowed to embed Xyicons into embedded Xyicons";
					row.setState({
						isGreyedOut: true,
					});
				} else {
					this._infoBubble.textContent = `Drop here to embed ${areMultipleXyiconsBeingDragged ? "these" : "this"} ${areMultipleXyiconsBeingDragged ? "Xyicons" : "Xyicon"}`;
				}
			}
		}

		this._previousRow = row;
	};

	private get isUserTryingToPutItOnTopOfHoveredItems(): boolean {
		return isUserTryingToPutItOnTopOfHoveredItems();
	}

	public override async componentDidMount() {
		const feature = this.props.feature;

		if (this.props.spaceViewRenderer.actions.getList(feature).length < 1) {
			await this.props.spaceViewRenderer.transport.services.feature.refreshList(feature);
		}

		window.addEventListener("wheel", this.onGlyphMouseWheel, {passive: false});
		this.props.spaceViewRenderer.toolManager.cameraControls.signals.cameraZoomChange.add(this.updateDraggedIcons);
	}

	public override componentWillUnmount() {
		window.removeEventListener("wheel", this.onGlyphMouseWheel);
		this.props.spaceViewRenderer.toolManager.cameraControls.signals.cameraZoomChange.remove(this.updateDraggedIcons);

		if (this.props.gridView?.current) {
			this.props.gridView.current.signals.onMouseMoveOnRow.remove(this.onMouseMoveOnRow);
		}

		this._infoBubble?.remove();
		this._infoBubble = null;
	}

	public override render() {
		return this.props.items.map((obj: {object: Catalog | Xyicon; userData?: Link}, index: number) => {
			const item = obj.object;

			const isExternalXyicon = obj.userData?.id && item.ownFeature === XyiconFeature.Xyicon && item.isExternal;
			const isUnplottedXyicon = obj.userData?.id && item.ownFeature === XyiconFeature.Xyicon && item.isUnplotted;

			return (
				<DraggableXyiconCatalogItem
					key={item.refId}
					onDuplicateCatalogClick={this.props.onDuplicateCatalogClick}
					onCreateUnplottedXyiconClick={() => this.props.onCreateUnplottedXyicons([{catalogId: item.id, parentXyiconId: null}])}
					item={item}
					isSelected={this.state.selectedItems.includes(item)}
					onBreakLinkClick={
						isExternalXyicon || isUnplottedXyicon
							? () => LinkBreakers.breakLinks(this.props.spaceViewRenderer.transport, [obj.userData.id])
							: Functions.emptyFunction
					}
					onPointerDown={isExternalXyicon ? Functions.emptyFunction : this.onGlyphPointerDown}
					onPointerMove={isExternalXyicon ? Functions.emptyFunction : this.onGlyphPointerMove}
					onPointerUp={isExternalXyicon ? Functions.emptyFunction : this.onGlyphPointerUp}
					showInfoButton={item.ownFeature === XyiconFeature.Xyicon}
					showDeleteButton={item.ownFeature === XyiconFeature.Xyicon}
					queryString={this.props.queryString}
				/>
			);
		});
	}
}
