import styled from "styled-components";
import {colorPalette} from "../../../../../../styles/colorPalette";

export const ReportSortOrderStyled = styled.div`
	&.ReportSortOrder {
		min-width: 620px;
	}

	.ReportSortContainer {
		overflow-y: auto;
		margin: 10px 0;
		max-height: 35vh;
	}

	.search {
		@extend .hbox;
		margin-bottom: 30px;
	}

	.Button {
		background-color: ${colorPalette.white};
		color: ${colorPalette.blueGray.c500Primary};
		border: 1px solid ${colorPalette.blueGray.c500Primary};
		margin-left: auto;
		display: flex;
		width: 71px;
		margin-right: 5px;
	}

	.Button svg path {
		stroke: ${colorPalette.blueGray.c500Primary};
	}

	.sortField {
		@extend .hbox;
		column-gap: 10px;
		align-items: center;
		height: 58px;
		display: grid;
		grid-template-columns: 400px 160px 20px;

		.button {
			visibility: hidden;
		}

		&:hover {
			.button {
				visibility: initial;
			}
		}

		span {
			width: 100%;
		}
	}
`;
