import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IValueDidChange, Lambda} from "mobx";
import {observe} from "mobx";
import type {IBasicPropsFor3DTweaking, OverlayPanel} from "../../SpaceView";
import {ClipboardManager} from "../logic3d/managers/ClipboardManager";
import type {IinheritedMethods} from "../logic3d/renderers/SpaceViewRenderer";
import type {SpaceItem} from "../logic3d/elements3d/SpaceItem";
import type {ThemeType} from "../logic3d/renderers/SpaceViewRendererUtils";
import {SpaceEditorMode} from "../logic3d/renderers/SpaceViewRendererUtils";
import type {SelectionToolType, SpaceTool} from "../logic3d/features/tools/Tools";
import {SpaceToolBarType} from "../logic3d/features/tools/Tools";
import {Constants} from "../logic3d/Constants";
import type {Catalog} from "../../../../../data/models/Catalog";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {FocusLoss} from "../../../../../utils/ui/focus/FocusLoss";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";
import {PopupWindow} from "../../../abstract/popups/PopupWindow";
import type {SidePanel} from "../../../abstract/sidepanel/SidePanel";
import type {PortTemplateDto, SpaceFileInsertionInfo} from "../../../../../generated/api/base";
import type {IModel, ISpaceItemModel} from "../../../../../data/models/Model";
import {notify} from "../../../../../utils/Notify";
import type {App} from "../../../../../App";
import type {AppState} from "../../../../../data/state/AppState";
import type {Xyicon} from "../../../../../data/models/Xyicon";
import {MarkupType, Permission} from "../../../../../generated/api/base";
import {NotificationType} from "../../../../notification/Notification";
import {Functions} from "../../../../../utils/function/Functions";
import {WarningWindow} from "../../../abstract/popups/WarningWindow";
import {MeasureToolBar} from "./toolbar/MeasureToolBar";
import {MergeBoundariesWindow} from "./actionbar/MergeBoundariesWindow";
import {SpaceControlBar} from "./spacecontrolbar/SpaceControlBar";
import {LinkedXyiconsWindow} from "./actionbar/LinkedXyiconsWindow";
import type {InvisibleLinkedXyiconType} from "./actionbar/LinkedXyiconsWindow";
import {LinkBreakers} from "./actionbar/LinkBreakers";
import {SpaceContextMenu} from "./actionbar/SpaceContextMenu";
import {SpaceLoadingScreen} from "./SpaceLoadingScreen";
import {SpaceAlignViewBar} from "./viewbar/SpaceAlignViewBar";
import {PortSelector} from "./actionbar/PortSelector";
import {SpaceActionBar} from "./actionbar/SpaceActionBar";
import {SpaceToolBar} from "./toolbar/SpaceToolBar";
import {Dockable} from "./toolbar/Dockable";
import type {DockableTitle} from "./toolbar/Dockable";
import {SpaceViewBar} from "./viewbar/SpaceViewBar";
import {XyiconSizeChanger} from "./sceneoptions/XyiconSizeChanger";

interface ISpaceEditorProps extends IBasicPropsFor3DTweaking {
	readonly app?: App;
	readonly appState?: AppState;
	readonly mode: SpaceEditorMode;
	readonly sidePanelRef?: React.RefObject<SidePanel<IModel>>;
	readonly thumbnail?: string;
	readonly setDockableDocked?: (value: boolean, title: DockableTitle) => void;
	readonly setDockableOpen?: (value: boolean, title?: DockableTitle) => void;
	readonly isCatalogOpen?: boolean;
	readonly isCatalogDocked?: boolean;
	readonly isBoundaryTypesWindowOpen?: boolean;
	readonly isBoundaryTypesWindowDocked?: boolean;
	readonly isUnplottedXyiconsOpen?: boolean;
	readonly isUnplottedXyiconsDocked?: boolean;
	readonly setScale: (spaceUnitsPerMeter: number, applyToAllSpaces?: boolean) => void;
	readonly confirmAlignment: (insertionInfo: SpaceFileInsertionInfo) => void;
	readonly activeToolId: SpaceTool;
	readonly setActiveTool: (id: SpaceTool) => void;
	readonly selectionToolType: SelectionToolType;
	readonly selectItems?: (spaceItems: ISpaceItemModel[]) => void;
	readonly onToggleLayerPanel?: () => void;
	readonly onToggleConditionalFormattingPanel?: () => void;
	readonly onToggleCaptionPanel?: () => void;
	readonly openOverlayerPanel?: OverlayPanel;
	readonly isCaptionPanelOpen?: boolean;
	readonly onAddCatalogClick: () => void;
	readonly onDuplicateCatalogClick: (catalog: Catalog) => void;
	readonly spaceViewShareClick: (viewId: string) => void;
	readonly onOpenSpaceToPDFExportPanel: () => void;
}

interface ISpaceEditorState {
	actionBar: {
		isOpen: boolean;
		isLinkable: boolean /** If only xyicons and are selected */;
		isEditable: boolean /** If only one boundary, or one markup is selected */;
		isInEditMode: boolean;
		worldX: number;
		worldY: number;
	};
	portSelector: {
		isOpen: boolean;
		ports: PortTemplateDto[];
		worldX: number;
		worldY: number;
		item: Xyicon;
		type: "from" | "to";
	};
	contextMenu: {
		isOpen: boolean;
		worldX: number;
		worldY: number;
	};
	linkBreakers: {
		fromObjectIds: string[];
	};
	linkedXyicons: {
		fromObjectIds: string[];
		type: InvisibleLinkedXyiconType;
	};
	mergeBoundariesWindow: {
		isOpen: boolean;
	};
	activeToolBar: SpaceToolBarType;
}

@inject("appState")
@inject("app")
@observer
export class SpaceEditor extends React.Component<ISpaceEditorProps, ISpaceEditorState> {
	private _themeObserverDisposer: Lambda;
	private _canvasContainer = React.createRef<HTMLDivElement>();
	private _spaceToolBarRef = React.createRef<SpaceToolBar>();
	private _spaceActionBarRef = React.createRef<SpaceActionBar>();

	constructor(props: ISpaceEditorProps) {
		super(props);
		this.state = {
			actionBar: {
				isOpen: false,
				isLinkable: false,
				isEditable: false,
				isInEditMode: false,
				worldX: 0,
				worldY: 0,
			},
			portSelector: {
				isOpen: false,
				ports: [],
				worldX: 0,
				worldY: 0,
				item: null,
				type: "from",
			},
			contextMenu: {
				isOpen: false,
				worldX: 0,
				worldY: 0,
			},
			linkBreakers: {
				fromObjectIds: [],
			},
			linkedXyicons: {
				fromObjectIds: [],
				type: null,
			},
			mergeBoundariesWindow: {
				isOpen: false,
			},
			activeToolBar: SpaceToolBarType.PrimaryToolBar,
		};
	}

	private openPortSelector = (worldX: number, worldY: number, item: Xyicon, ports: PortTemplateDto[], type: "from" | "to") => {
		this.setState({
			portSelector: {
				isOpen: true,
				worldX: worldX,
				worldY: worldY,
				item: item,
				ports: ports,
				type: type,
			},
		});
	};

	private closePortSelector = () => {
		if (this.state.portSelector.isOpen) {
			this.setState({
				portSelector: {
					isOpen: false,
					worldX: 0,
					worldY: 0,
					item: null,
					type: "from",
					ports: [],
				},
			});
		}
	};

	private openContextMenu = (worldX: number, worldY: number) => {
		if (this.spaceViewRenderer.mode === SpaceEditorMode.NORMAL) {
			const {currentPointerWorld} = this.spaceViewRenderer.toolManager.activeTool;

			currentPointerWorld.x = worldX;
			currentPointerWorld.y = worldY;

			this.setState({
				contextMenu: {
					isOpen: true,
					worldX: worldX,
					worldY: worldY,
				},
			});
			this.closeActionBar();
		}
	};

	private isContextMenuOpen = () => {
		return this.state.contextMenu.isOpen;
	};

	private closeContextMenu = () => {
		if (this.state.contextMenu.isOpen) {
			this.setState({
				contextMenu: {
					isOpen: false,
					worldX: 0,
					worldY: 0,
				},
			});
		}
	};

	private onDeleteClick = () => {
		const {spaceItemController} = this.spaceViewRenderer;
		const selectedItems = spaceItemController.selectedItems;
		const {actions} = this.props.appState;

		const xyiconsWithoutPermissionCount = actions.getNumberOfSpaceItemsWithoutPermission(selectedItems, Permission.Delete);

		if (xyiconsWithoutPermissionCount === 0) {
			this.closeContextMenu();
			spaceItemController.onDeleteClick();
		} else {
			notify(this.props.app.notificationContainer, {
				type: NotificationType.Warning,
				title: "Action not allowed!",
				description: `${xyiconsWithoutPermissionCount} of ${selectedItems.length} objects you have selected do not have the required permissions to perform this action. To obtain permission, contact your organization's administrator.`,
				lifeTime: 10000,
			});
		}
	};

	private openLinkBreakers = (fromObjectIds: string[]) => {
		this.setState({
			linkBreakers: {
				fromObjectIds: fromObjectIds,
			},
		});
	};

	private updateLinkBreakers = () => {
		if (this.state.linkBreakers.fromObjectIds.length > 0) {
			const fromObjectIds = this.state.linkBreakers.fromObjectIds;

			this.setState({
				linkBreakers: {
					fromObjectIds: fromObjectIds,
				},
			});
		}
	};

	private closeLinkBreakers = () => {
		if (this.state.linkBreakers.fromObjectIds.length > 0) {
			this.setState({
				linkBreakers: {
					fromObjectIds: [],
				},
			});
		}
	};

	private openLinkedXyiconsWindow = (fromObjectIds: string[], type: InvisibleLinkedXyiconType) => {
		this.setState({
			linkedXyicons: {
				fromObjectIds: fromObjectIds,
				type: type,
			},
		});
	};

	private closeLinkedXyiconsWindow = () => {
		if (this.state.linkedXyicons.fromObjectIds.length > 0) {
			this.setState({
				linkedXyicons: {
					fromObjectIds: [],
					type: null,
				},
			});
		}
	};

	private openMergeBoundariesWindow = () => {
		if (!this.state.mergeBoundariesWindow.isOpen) {
			this.setState({
				mergeBoundariesWindow: {
					isOpen: true,
				},
			});
		}
	};

	private closeMergeBoundariesWindow = () => {
		if (this.state.mergeBoundariesWindow.isOpen) {
			this.setState({
				mergeBoundariesWindow: {
					isOpen: false,
				},
			});
		}
	};

	private openActionBar = (worldX: number, worldY: number) => {
		const {spaceItemController} = this.props.spaceViewRenderer;
		const selectedItems = spaceItemController.selectedItems;
		const isEditable =
			selectedItems.length === 1 &&
			spaceItemController.boundaryManager.selectedItems.length +
				spaceItemController.markupManager.selectedItems.filter((item) => item.type !== MarkupType.Photo360).length ===
				1;
		const isLinkable = selectedItems.every((spaceItem: SpaceItem) => spaceItem.spaceItemType === "xyicon");

		this.setState({
			actionBar: {
				isOpen: true,
				isLinkable: isLinkable,
				isEditable: isEditable,
				isInEditMode: isEditable && this.state.actionBar.isInEditMode,
				worldX: worldX,
				worldY: worldY,
			},
		});
		this.closeContextMenu();
	};

	private closeActionBar = () => {
		if (this.state.actionBar.isOpen) {
			this.setState({
				actionBar: {
					isOpen: false,
					isLinkable: false,
					isEditable: false,
					isInEditMode: false,
					worldX: 0,
					worldY: 0,
				},
			});
		}
	};

	private setActiveToolBar = (toolBar: SpaceToolBarType) => {
		this.setState({
			activeToolBar: toolBar,
		});
	};

	private onEditModeSwitched = (enabled: boolean) => {
		this.setState({
			actionBar: {
				isOpen: this.state.actionBar.isOpen,
				isLinkable: this.state.actionBar.isLinkable,
				isEditable: this.state.actionBar.isEditable,
				isInEditMode: enabled,
				worldX: this.state.actionBar.worldX,
				worldY: this.state.actionBar.worldY,
			},
		});

		if (enabled) {
			this.props.setActiveTool("selection");
		}
	};

	private onSwitchToTextEditMode = () => {
		this._spaceActionBarRef.current?.onAddTextClick();
	};

	private onExitTextEditMode = () => {
		this._spaceActionBarRef.current?.onTextEditorClose();
	};

	private onRedrawClick = () => {
		this.spaceViewRenderer.spaceItemController.onRedrawClick();
	};

	private onCancelEditClick = () => {
		this.spaceViewRenderer.spaceItemController.onCancelEditClick();

		requestAnimationFrame(() => {
			this.setState({
				actionBar: {
					isOpen: this.state.actionBar.isOpen,
					isLinkable: this.state.actionBar.isLinkable,
					isEditable: this.state.actionBar.isEditable,
					isInEditMode: false,
					worldX: this.state.actionBar.worldX,
					worldY: this.state.actionBar.worldY,
				},
			});
		});
	};

	private updateTheme = () => {
		this.spaceViewRenderer.updateTheme();
	};

	private getFocusedItems = (): ISpaceItemModel[] => [];

	private get inheritedMethods(): IinheritedMethods {
		return {
			openActionBar: this.openActionBar,
			closeActionBar: this.closeActionBar,
			openPortSelector: this.openPortSelector,
			closePortSelector: this.closePortSelector,
			openContextMenu: this.openContextMenu,
			isContextMenuOpen: this.isContextMenuOpen,
			closeContextMenu: this.closeContextMenu,
			selectItems: this.props.selectItems,
			focusItems: Functions.emptyFunction,
			getFocusedItems: this.getFocusedItems,
			setScale: this.props.setScale,
			confirmAlignment: this.props.confirmAlignment,
			setActiveTool: this.props.setActiveTool,
			onPasteClick: this.onPasteClick,
			openLinkBreakers: this.openLinkBreakers,
			updateLinkBreakers: this.updateLinkBreakers,
			closeLinkBreakers: this.closeLinkBreakers,
			openLinkedXyiconsWindow: this.openLinkedXyiconsWindow,
			closeLinkedXyiconsWindow: this.closeLinkedXyiconsWindow,
			onEditModeSwitched: this.onEditModeSwitched,
			onSwitchToTextEditMode: this.onSwitchToTextEditMode,
			onExitTextEditMode: this.onExitTextEditMode,
		};
	}

	private initSpaceViewRenderer() {
		if (this._canvasContainer.current) {
			this._canvasContainer.current.appendChild(this.spaceViewRenderer.canvas);
			this.spaceViewRenderer.mount();
		}
	}

	private getNonDockedTitle(): DockableTitle {
		if (this.props.isCatalogOpen && !this.props.isCatalogDocked) {
			return "Catalog";
		} else if (this.props.isBoundaryTypesWindowOpen && !this.props.isBoundaryTypesWindowDocked) {
			return "Boundary";
		} else if (this.props.isUnplottedXyiconsOpen && !this.props.isUnplottedXyiconsDocked) {
			return "Unplotted Xyicons";
		} else {
			return null;
		}
	}

	private pasteFromClipboard = (event: ClipboardEvent) => {
		if (KeyboardListener.isEventTargetAnInput(event)) {
			// we don't override the default behaviour, let the users paste text into input, and textarea elements
		} else {
			this.paste(event.clipboardData.getData("text/plain"));
		}
	};

	private onPasteClick = async () => {
		if (navigator.clipboard.readText) {
			this.paste(await navigator.clipboard.readText());
		} else {
			await WarningWindow.open(
				`It looks like you're missing permissions to read the clipboard. Please try to allow the browser to access the clipboard.<br />
				Alternatively, try to use the <i>Ctrl+C</i> (for copy), and <i>Ctrl+V</i> (for paste) methods.
				${
					Constants.isFirefox
						? `<br /><br />If you're using Firefox, open up a new tab, type <i>about:config</i> as the url you'd like to visit,
				then change the following 3 settings from <i>false</i> to <i>true</i> :<br /><br />
				<div class="hbox justifyCenter"><img src="src/assets/images/spaceviewer/mozilla_firefox_asyncclipboard.png" alt="Firefox clipboard settings" /><div>`
						: ""
				}`,
			);
		}
	};

	private async paste(pastedString: string = "") {
		if (this.props.mode === SpaceEditorMode.NORMAL) {
			if (!ClipboardManager.isPasting && !this.spaceViewRenderer.spaceItemController.isAddingItemsToServer) {
				try {
					const pastedText = StringUtils.formatPastedText(pastedString, ClipboardManager.storedHTML);
					const actionType = ClipboardManager.action;

					await ClipboardManager.paste(pastedText, this.spaceViewRenderer, actionType);
					if (actionType === "CUT") {
						ClipboardManager.clearClipboard();
					}
				} catch (error) {
					console.warn(`Paste error: ${error}`);
				}
			} else {
				console.warn("You tried to paste items, while some other items were already being created.");
			}
		}
	}

	private onApplyEditClick = () => {
		if (this.spaceViewRenderer.spaceItemController.currentlyEditedItem?.isValid) {
			this.props.spaceViewRenderer.spaceItemController.switchEditMode(false);
		}
	};

	private keyDown = (event: KeyboardEvent) => {
		if (!ClipboardManager.getSelectedText()) {
			const spaceViewRenderer = this.spaceViewRenderer;

			switch (event.key) {
				case KeyboardListener.KEY_ESCAPE:
					if (!KeyboardListener.isEventTargetAnInput(event) && PopupWindow.numberOfOpenPopups < 1 && !spaceViewRenderer.isMeasureToolBarOpen) {
						this.props.setActiveTool("selection");
					}
					break;
				case "c":
				case "C":
				case "d":
				case "D":
					if (KeyboardListener.isCtrlDown) {
						const isDuplicate = event.key.toLowerCase() === "d";

						if (isDuplicate) {
							event.preventDefault(); // ctrl+d => save to bookmark by default
						}
						spaceViewRenderer.copySelectedItemsToClipboard();
						if (isDuplicate) {
							this.onPasteClick();
						}
					}
					break;
				case "x":
				case "X":
					if (KeyboardListener.isCtrlDown) {
						spaceViewRenderer.cutSelectedItemsToClipboard();
					}
					break;
				case "s":
				case "S":
					if (KeyboardListener.isCtrlDown) {
						event.preventDefault(); // by default, the browser tries to save the html file
						spaceViewRenderer.copySelectedItemsToStamp();
					}
					break;
				case KeyboardListener.KEY_CTRL:
					this.forceUpdate();
					break;
			}
		}
	};

	private keyUp = (event: KeyboardEvent) => {
		const spaceViewRenderer = this.spaceViewRenderer;

		switch (event.key) {
			case KeyboardListener.KEY_DELETE:
				if (this.state.actionBar.isInEditMode) {
					spaceViewRenderer.boundaryManager.deleteSelectedVertex();
				} else {
					if (spaceViewRenderer.spaceItemController.selectedItems.length > 0) {
						this.onDeleteClick();
					}
				}
				break;
			case KeyboardListener.KEY_ESCAPE:
				if (this.state.actionBar.isInEditMode) {
					this.onCancelEditClick();
				}
				break;
			case KeyboardListener.KEY_ENTER:
				if (this.state.actionBar.isInEditMode) {
					this.onApplyEditClick();
				}
				break;
			case KeyboardListener.KEY_CTRL:
				this.forceUpdate();
				break;
		}
	};

	private onRealignSpaceClick = () => {
		this._spaceToolBarRef.current?.primaryToolBarRef.current?.openSpaceAlignToolBar();
	};

	public override componentDidMount() {
		KeyboardListener.getInstance().signals.down.add(this.keyDown);
		KeyboardListener.getInstance().signals.up.add(this.keyUp);

		this.props.appState.app.graphicalTools.pdfRenderer.setProcessOrder("LIFO");
		this.initSpaceViewRenderer();

		const appState = this.props.appState;

		this._themeObserverDisposer = observe(appState, "theme", (changeValue: IValueDidChange<ThemeType>) => {
			this.updateTheme();
		});

		document.addEventListener("paste", this.pasteFromClipboard);
		FocusLoss.listen(document.body, ClipboardManager.onBlur);
		this.spaceViewRenderer.transport.signals.switchOrganization.add(ClipboardManager.onBlur);
	}

	public override componentWillUnmount() {
		KeyboardListener.getInstance().signals.down.remove(this.keyDown);
		KeyboardListener.getInstance().signals.up.remove(this.keyUp);

		document.removeEventListener("paste", this.pasteFromClipboard);
		this.spaceViewRenderer.unmount();
		FocusLoss.stopListen(document.body, ClipboardManager.onBlur);
		this.spaceViewRenderer.transport.signals.switchOrganization.remove(ClipboardManager.onBlur);
		ClipboardManager.onBlur();

		this._themeObserverDisposer?.();
		this._themeObserverDisposer = null;
	}

	public get spaceViewRenderer() {
		return this.props.app.spaceViewRenderer;
	}

	public override render() {
		const spaceViewRenderer = this.spaceViewRenderer;
		const {actionBar, portSelector, contextMenu, linkBreakers, linkedXyicons, mergeBoundariesWindow} = this.state;

		this.spaceViewRenderer.setMode(this.props.mode);
		this.spaceViewRenderer.addInheritedMethods(this.inheritedMethods);

		const nonDockedTitle = this.getNonDockedTitle();
		const {photoSphereSceneManager} = this.props.appState.app.graphicalTools;
		const isInMixedOrSplitViewMode = ["mixed", "split", "transitioning"].includes(photoSphereSceneManager.viewMode);

		return (
			<div className="SpaceEditor">
				{!isInMixedOrSplitViewMode && nonDockedTitle && (
					<Dockable
						spaceViewRenderer={spaceViewRenderer}
						setDocked={this.props.setDockableDocked}
						setOpen={this.props.setDockableOpen}
						isDocked={false}
						title={nonDockedTitle}
						setActiveTool={this.props.setActiveTool}
						onAddCatalogClick={this.props.onAddCatalogClick}
						onDuplicateCatalogClick={this.props.onDuplicateCatalogClick}
						onCreateUnplottedXyicons={Functions.emptyFunction}
					/>
				)}
				<div
					className="canvasContainer"
					ref={this._canvasContainer}
				>
					{!isInMixedOrSplitViewMode && (
						<>
							<SpaceToolBar
								ref={this._spaceToolBarRef}
								spaceViewRenderer={spaceViewRenderer}
								mode={this.props.mode}
								activeToolBar={this.state.activeToolBar}
								setActiveToolBar={this.setActiveToolBar}
								setDockableOpen={this.props.setDockableOpen}
								isCatalogOpen={this.props.isCatalogOpen}
								isBoundaryTypesWindowOpen={this.props.isBoundaryTypesWindowOpen}
								isUnplottedXyiconsOpen={this.props.isUnplottedXyiconsOpen}
								activeToolId={this.props.activeToolId}
								setActiveTool={this.props.setActiveTool}
								selectionToolType={this.props.selectionToolType}
							/>
							{this.spaceViewRenderer.isMeasureToolBarOpen && (
								<MeasureToolBar
									spaceViewRenderer={this.spaceViewRenderer}
									activeToolId={this.props.activeToolId}
									setActiveTool={this.props.setActiveTool}
								/>
							)}
							{this.props.mode === SpaceEditorMode.NORMAL &&
								this.state.activeToolBar !== SpaceToolBarType.SpaceAlignToolBar &&
								this.props.sidePanelRef?.current && (
									<>
										<SpaceViewBar
											spaceViewRenderer={this.spaceViewRenderer}
											onToggleLayerPanel={this.props.onToggleLayerPanel}
											onToggleConditionalFormattingPanel={this.props.onToggleConditionalFormattingPanel}
											onToggleCaptionPanel={this.props.onToggleCaptionPanel}
											openOverlayerPanel={this.props.openOverlayerPanel}
											isCaptionPanelOpen={this.props.isCaptionPanelOpen}
											spaceViewShareClick={this.props.spaceViewShareClick}
											onOpenSpaceToPDFExportPanel={this.props.onOpenSpaceToPDFExportPanel}
										/>
										<div className="sceneOptions hidden">
											<XyiconSizeChanger spaceViewRenderer={this.spaceViewRenderer} />
										</div>
									</>
								)}
							{(this.props.mode === SpaceEditorMode.ALIGN || this.state.activeToolBar === SpaceToolBarType.SpaceAlignToolBar) && (
								<SpaceAlignViewBar spaceViewRenderer={this.spaceViewRenderer} />
							)}
							{this.spaceViewRenderer.toolManager && spaceViewRenderer.toolManager.cameraControls && this.props.thumbnail && (
								<SpaceControlBar
									spaceViewRenderer={spaceViewRenderer}
									onRealignSpaceClick={this.onRealignSpaceClick}
									navigationBoxImgSrc={this.props.thumbnail}
								/>
							)}
							{actionBar.isOpen && linkBreakers.fromObjectIds.length === 0 && !KeyboardListener.isCtrlDown && (
								<SpaceActionBar
									ref={this._spaceActionBarRef}
									spaceViewRenderer={this.spaceViewRenderer}
									isEditEnabled={actionBar.isEditable}
									isInEditMode={actionBar.isInEditMode}
									isLinkEnabled={actionBar.isLinkable}
									worldX={actionBar.worldX}
									worldY={actionBar.worldY}
									onRedrawClick={this.onRedrawClick}
									onCancelEditClick={this.onCancelEditClick}
									onApplyEditClick={this.onApplyEditClick}
									onDeleteClick={this.onDeleteClick}
									openMergeBoundariesWindow={this.openMergeBoundariesWindow}
								/>
							)}
							{mergeBoundariesWindow.isOpen && <MergeBoundariesWindow onClose={this.closeMergeBoundariesWindow} />}
							{contextMenu.isOpen && (
								<SpaceContextMenu
									spaceViewRenderer={spaceViewRenderer}
									worldX={contextMenu.worldX}
									worldY={contextMenu.worldY}
									onCopyClick={spaceViewRenderer.copySelectedItemsToClipboard}
									onCopyToStampClick={spaceViewRenderer.copySelectedItemsToStamp}
									onCutClick={spaceViewRenderer.cutSelectedItemsToClipboard}
									onDeleteClick={this.onDeleteClick}
									onPasteClick={this.onPasteClick}
									onRealignSpaceClick={this.onRealignSpaceClick}
									onClose={this.closeContextMenu}
								/>
							)}
							{portSelector.isOpen && (
								<PortSelector
									spaceViewRenderer={spaceViewRenderer}
									worldX={portSelector.worldX}
									worldY={portSelector.worldY}
									item={portSelector.item}
									ports={portSelector.ports}
									type={portSelector.type}
								/>
							)}
							{linkBreakers.fromObjectIds.length > 0 && (
								<LinkBreakers
									spaceViewRenderer={spaceViewRenderer}
									fromObjectIds={linkBreakers.fromObjectIds}
								/>
							)}
							{linkedXyicons.fromObjectIds.length === 1 && ( // TODO: what should happen if there are more than 1 xyicons with off-space links? As a temporary solution we don't show any popups now
								<LinkedXyiconsWindow
									spaceViewRenderer={spaceViewRenderer}
									fromObjectId={linkedXyicons.fromObjectIds[0]}
									type={linkedXyicons.type}
									onClose={this.closeLinkedXyiconsWindow}
								/>
							)}
						</>
					)}
				</div>
				<SpaceLoadingScreen spaceViewRenderer={spaceViewRenderer} />
			</div>
		);
	}
}
