import {inject, observer} from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import type {Boundary} from "../../../data/models/Boundary";
import {BoundaryUtils} from "../../../data/models/BoundaryUtils";
import type {Catalog} from "../../../data/models/Catalog";
import type {LibraryModel} from "../../../data/models/LibraryModel";
import type {IModel} from "../../../data/models/Model";
import type {Space} from "../../../data/models/Space";
import type {Xyicon} from "../../../data/models/Xyicon";
import type {AppState} from "../../../data/state/AppState";
import {MarkupType, Permission, XyiconFeature} from "../../../generated/api/base";
import {ColorUtils} from "../../../utils/ColorUtils";
import {Functions} from "../../../utils/function/Functions";
import {SpaceThumbnail} from "../../modules/abstract/sidepanel/tabs/details/default/spacethumbnail/SpaceThumbnail";
import {ReactUtils} from "../../utils/ReactUtils";
import {BoundarySpaceMapSelectorV5} from "../spaceeditor/BoundarySpaceMapSelectorV5";
import DogearFavIcon from "../icons/dogear-fav.svg?react";
import DogearCardsIcon from "../icons/dogear-cards.svg?react";
import type {BoundarySpaceMap} from "../../../data/models/BoundarySpaceMap";
import PenWithLine from "../icons/pen-with-line.svg?react";
import {baseDistance, FLEXCENTER, radius} from "../styles/styles";
import type {BoundarySpaceMap3D} from "../../modules/space/spaceeditor/logic3d/elements3d/BoundarySpaceMap3D";
import {colorPalette} from "../styles/colorPalette";
import type {Markup} from "../../../data/models/Markup";
import {triggerPhoto360Previewer} from "../../modules/space/spaceeditor/logic3d/elements3d/markups/MarkupPhoto360Utils";

interface IInitialsProps {
	readonly color?: string;
	readonly name: string;
	readonly item?: IModel;
	readonly permission?: Permission;
	readonly className?: string;
	readonly thumbnailSize?: number;
	readonly appState?: AppState;
	readonly onCatalogIconEditClick?: (catalog: Catalog) => void;
	readonly size?: number;
	readonly onSpaceIconClick?: (spaceId: string) => void;
}

interface IInitialsState {
	isBoundarySelectorOpen: boolean;
}

@inject("appState")
@observer
export class InitialsV5 extends React.Component<IInitialsProps, IInitialsState> {
	public static readonly defaultProps: Partial<IInitialsProps> = {
		thumbnailSize: 128,
	};

	constructor(props: IInitialsProps) {
		super(props);
		this.state = {
			isBoundarySelectorOpen: false,
		};
	}

	// If character in [a-z] or in [0-9]
	private okChar(c: string) {
		c = c || "";
		const char = c.toLowerCase().charCodeAt(0);

		return (96 < char && char < 123) || (47 < char && char < 58);
	}

	// Make Initials
	private makeInitialsFromString(s: string) {
		s = s || "";
		// First delete quotes, split on " " characters and then filter out special characters.
		let string = s.replace(/"|'/gi, "");
		const sArr = string.split(" ").filter((word) => this.okChar(word[0]));

		if (sArr.length === 0) {
			return "Xy";
		} else {
			if (sArr.length === 1) {
				if (sArr[0].length === 1) {
					return sArr[0][0].toLocaleUpperCase();
				} else {
					return sArr[0][0].toLocaleUpperCase() + sArr[0][1];
				}
			} else {
				return sArr[0][0].toLocaleUpperCase() + sArr[1][0].toLocaleUpperCase();
			}
		}
	}

	private getSpaceThumbnail(item: Space) {
		const fullURL = item.thumbnailFileURL;

		return (
			<SpaceThumbnail
				url={fullURL}
				spaceId={item.id}
				onClick={this.props.onSpaceIconClick}
			/>
		);
	}

	private onShowBoundarySpaceMaps = (event: React.MouseEvent) => {
		this.setState((prevState) => ({
			isBoundarySelectorOpen: !prevState.isBoundarySelectorOpen,
		}));
	};

	private onHideBoundarySpaceMaps = () => {
		this.setState({
			isBoundarySelectorOpen: false,
		});
	};

	private renderInitial() {
		const {name, item, size, thumbnailSize} = this.props;
		const thumbSize = item?.ownFeature === XyiconFeature.Markup ? 65 : (size ?? thumbnailSize);

		switch (item?.ownFeature) {
			case XyiconFeature.Space:
				return this.getSpaceThumbnail(item as Space);
			case XyiconFeature.Xyicon:
				const xyiconItem = item as Xyicon;

				return (
					<div
						style={{
							width: thumbSize,
							height: thumbSize,
							backgroundImage: `url('${xyiconItem.thumbnail}')`,
							transform: xyiconItem.backgroundTransform,
						}}
					/>
				);
			case XyiconFeature.XyiconCatalog:
				const catalogItem = item as Catalog;
				let thumbnail = catalogItem.thumbnail;

				if (catalogItem.source === "Revit") {
					const model = this.props.appState.actions
						.getList(XyiconFeature.LibraryModel)
						.find((a: LibraryModel) => a.id === catalogItem.iconData.modelParameters.libraryModelID);

					thumbnail = model.thumbnail;
				}

				return (
					<div
						className={ReactUtils.cls({
							"catalog-thumbnail": catalogItem,
							favoriteCatalog: catalogItem.isFavorite,
							button: !!this.props.onCatalogIconEditClick,
						})}
						onClick={() => this.props.onCatalogIconEditClick?.(catalogItem)}
					>
						<img
							src={thumbnail}
							width={thumbSize}
							height={thumbSize}
							title="Edit Icon"
							className={ReactUtils.cls({disabled: this.props.permission < Permission.Update})}
						/>
						{this.props.onCatalogIconEditClick && <PenWithLine className="edit" />}
						{catalogItem.isFavorite && <DogearFavIcon className="favorite" />}
					</div>
				);

			case XyiconFeature.Boundary:
				const boundaryItem = item as Boundary;
				const doesHaveMultipleSpaceMaps = (item as BoundarySpaceMap).parent ? false : BoundaryUtils.doesHaveMultipleSpaceMaps(item);
				const {selectedItems} = this.props.appState.app.spaceViewRenderer.spaceItemController;

				// If 2 (or more) boundaryspacemaps are selected with the same parent boundary, we consider them as one item
				const selectedItemIds: Set<string> = new Set<string>();

				for (const item of selectedItems) {
					selectedItemIds.add(((item.modelData as BoundarySpaceMap).isBoundarySpaceMap ? (item.modelData as BoundarySpaceMap).parent : item).id);
				}

				const areMultipleItemsSelected = selectedItemIds.size > 1;
				const isButtonEnabled = doesHaveMultipleSpaceMaps && !areMultipleItemsSelected;
				let boundaryThumbnail = boundaryItem.thumbnail;

				if (selectedItems.length === 1) {
					boundaryThumbnail = ((selectedItems[0] as BoundarySpaceMap3D)?.modelData as BoundarySpaceMap)?.thumbnail ?? boundaryThumbnail;
				}

				return (
					<div
						className={ReactUtils.cls("boundaryThumbnail", {btn: isButtonEnabled})}
						onClick={isButtonEnabled ? this.onShowBoundarySpaceMaps : Functions.emptyFunction}
					>
						<img
							src={boundaryThumbnail}
							width={thumbSize}
							height={thumbSize}
						/>
						{doesHaveMultipleSpaceMaps && <DogearCardsIcon style={{width: 24, position: "absolute", left: 0, top: 1}} />}
						{this.state.isBoundarySelectorOpen && (
							<BoundarySpaceMapSelectorV5
								item={boundaryItem}
								onClose={this.onHideBoundarySpaceMaps}
							/>
						)}
					</div>
				);
			case XyiconFeature.Markup: {
				const isMarkup360Photo = (item as Markup).type === MarkupType.Photo360;
				return (
					<div
						onClick={isMarkup360Photo ? () => triggerPhoto360Previewer(item as Markup) : Functions.emptyFunction}
						className={ReactUtils.cls("thumbnail", {btn: isMarkup360Photo})}
						style={{
							width: thumbSize,
							height: thumbSize,
							backgroundImage: `url('${item.thumbnail}')`,
						}}
					/>
				);
			}
			default:
				return name ? this.makeInitialsFromString(name) : "Xy";
		}
	}

	private forceUpdateArrow = () => {
		return this.forceUpdate();
	};

	public override async componentDidMount() {
		// Workaround fox scenario when another boundaryspacemap is selected from the same boundary,
		// and the thumbnail is not being updated on the details panel
		this.props.appState.app.spaceViewRenderer.spaceItemController.signals.actionBarUpdated.add(this.forceUpdateArrow);
		await this.props.appState.app.transport.services.feature.refreshList(XyiconFeature.LibraryModel);
	}

	public override componentWillUnmount(): void {
		this.props.appState.app.spaceViewRenderer.spaceItemController.signals.actionBarUpdated.remove(this.forceUpdateArrow);
	}

	public override render() {
		const {item, name, className} = this.props;
		let color = this.props.color || ColorUtils.hexColorByString(name || "Xyicon");

		const darkerColor = `#${ColorUtils.darkenColor(color, 30)}`;

		const style = {
			backgroundColor: `#${color}`,
			borderColor: darkerColor,
			color: darkerColor,
		};

		const isItemWithThumbnail = [
			XyiconFeature.Xyicon,
			XyiconFeature.XyiconCatalog,
			XyiconFeature.Space,
			XyiconFeature.Boundary,
			XyiconFeature.Markup,
		].includes(item?.ownFeature);

		return (
			<InitialsStyled
				className={ReactUtils.cls(`Initials ${className || ""}`, {thumbnail: isItemWithThumbnail})}
				style={!isItemWithThumbnail ? style : {}}
			>
				{this.renderInitial()}
			</InitialsStyled>
		);
	}
}

export const InitialsStyled = styled.div`
	width: 32px;
	height: 32px;
	${FLEXCENTER};
	border-radius: ${radius.sm};

	.catalog-thumbnail {
		position: relative;

		.favorite {
			position: absolute;
			top: -2px;
			right: 27px;
		}

		.edit {
			visibility: hidden;
			position: absolute;
			right: 0;
			bottom: 4px;
			width: 24px;
			height: 24px;
			background-color: #00000080;
			color: ${colorPalette.white};
			padding: ${baseDistance.xs};
			border-radius: ${radius.sm};
			cursor: pointer;
		}

		&:hover .edit {
			visibility: visible;
		}
	}

	&.image:not(.isSquare) {
		border-radius: 50%;
	}
`;
