import type {Markup} from "../data/models/Markup";
import {MarkupType} from "../generated/api/base";
import {PhotoSphere} from "./PhotoSphere";
import type {IPhotoSphere} from "./PhotoSphereTypes";

export const createPhotoSphereFromMarkup = (markup: Markup): PhotoSphere => {
	if (markup.type !== MarkupType.Photo360) {
		console.warn("Can't create photo360 from non photo360 markup!");

		return null;
	}

	const metaData = markup.photoSphereMetaData;
	const photoSphereData: IPhotoSphere = {
		item: markup,
		headingAngle: metaData.headingAngle,
		viewBox: metaData.viewBox,
		fileUrl: markup.photo360URL,
		thumbnailUrl: markup.thumbnail,
	};

	return new PhotoSphere(photoSphereData, markup.appState.app.graphicalTools.photoSphereManager);
};
