import {css, styled} from "styled-components";
import type {ForwardedRef, FunctionComponent, SVGProps} from "react";
import {forwardRef} from "react";
import {colorPalette} from "../styles/colorPalette";
import {baseDistance, FlexCenterStyle, fontSize, fontWeight, radius} from "../styles/styles";
import {ReactUtils} from "../../utils/ReactUtils";

export interface IButtonV5Props {
	readonly label?: string;
	readonly children?: React.ReactNode;
	readonly style?: React.CSSProperties;
	readonly disabled?: boolean;
	readonly className?: "secondary" | "tertiary" | (string & {});
	readonly title?: string;
	readonly type?: TButtonType;
	readonly IconComponent?: FunctionComponent<SVGProps<SVGSVGElement> & {title?: string}>;
	readonly isActive?: boolean;
	readonly onClick: (e: React.MouseEvent) => void;
}

type TButtonType = "secondary" | "tertiary" | null;

export const ButtonV5 = forwardRef((props: IButtonV5Props, ref: ForwardedRef<HTMLDivElement>) => {
	const {className, children, label = "", style, disabled, title, type, IconComponent, isActive} = props;

	const onClick = (e: React.MouseEvent) => {
		const {onClick} = props;

		if (!disabled && onClick) {
			onClick(e);
		}
	};

	return (
		<ButtonStyled
			className={ReactUtils.cls(`Button ${className || ""} ${type || ""}`, {disabled, isActive})}
			title={title || ""}
			data-cy={`Button.${label}`}
			style={style || null}
			onClick={onClick}
			ref={ref}
			$type={type}
			$disabled={disabled}
		>
			{children}
			{IconComponent && <IconComponent />}
			{!!label && <span className="label">{label}</span>}
		</ButtonStyled>
	);
});

export const ButtonStyled = styled.div<{$type?: TButtonType; $disabled?: boolean}>`
	${FlexCenterStyle};
	background-color: ${colorPalette.primary.c500Primary};
	border-radius: ${radius.sm};
	font-weight: ${fontWeight.normal};
	font-size: ${fontSize.md};
	color: ${colorPalette.white};
	gap: ${baseDistance.sm};

	&:hover {
		background-color: ${colorPalette.primary.c700Dark};
		color: ${colorPalette.white};
	}

	box-sizing: border-box;
	border: 1px solid transparent;
	height: 32px;
	justify-content: center;
	padding: 0 ${baseDistance.sm};
	cursor: pointer;

	svg {
		width: 16px;
		height: 16px;
		min-width: 16px;
		min-height: 16px;
	}

	${(props) => {
		if (props.$type === "secondary") {
			return css`
				background-color: transparent;
				color: ${colorPalette.primary.c500Primary};
				border-color: ${colorPalette.primary.c500Primary};

				&:hover {
					background-color: ${colorPalette.primary.c100};
					color: ${colorPalette.primary.c700Dark};
					border-color: ${colorPalette.primary.c700Dark};
				}
			`;
		} else if (props.$type === "tertiary") {
			return css`
				background-color: transparent;
				color: ${colorPalette.gray.c950};
				border: none;

				&:hover {
					background-color: ${colorPalette.gray.c200Light};
					color: ${colorPalette.gray.c950}; // used in filter button
				}
			`;
		}
	}};

	&.isActive {
		background-color: ${colorPalette.primary.c200Light};
		color: ${colorPalette.primary.c500Primary};
	}

	${({$disabled, $type}) => {
		if ($disabled) {
			if ($type === "tertiary" || $type === "secondary") {
				return css`
					background-color: transparent;
					border-color: ${colorPalette.gray.c300};
					color: ${colorPalette.gray.c300};
					pointer-events: none;
					cursor: default;
				`;
			}

			return css`
				background-color: ${colorPalette.gray.c300};
				border-color: ${colorPalette.gray.c300};
				color: ${colorPalette.white};
				pointer-events: none;
			`;
		}
	}};
`;
