import styled from "styled-components";
import {FLEXCENTER} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";

const AVATAR_SIZE = "32px";

export const AvatarStyled = styled.div`
	background: ${colorPalette.positive.c500Primary};
	border-radius: 50%;
	height: ${AVATAR_SIZE};
	text-align: center;
	width: ${AVATAR_SIZE};
	cursor: pointer;
	${FLEXCENTER};
`;
