import styled from "styled-components";
import {Fragment, useEffect, useRef, useState} from "react";
import {Observer} from "mobx-react";
import {SpaceActionBarStyled} from "../modules/spaceeditor/spaceactionbar/SpaceActionBar.styled";
import XmarkIcon from "../icons/xmark.svg?react";
import type {IModel} from "../../../data/models/Model";
import {IconButtonStyled, IconButtonV5} from "../interaction/IconButtonV5";
import {XyiconFeature} from "../../../generated/api/reports";
import {colorPalette} from "../styles/colorPalette";
import {baseDistance, radius} from "../styles/styles";
import {LineStyled} from "../topbar/ProjectName.styled";
import type {Catalog} from "../../../data/models/Catalog";
import {useAppStore} from "../../../StateManager";
import {HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import {DropdownButtonStyled, DropdownButtonV5} from "../interaction/DropdownButtonV5";
import type {IDropdownOption} from "../interaction/DropdownOptionsV5";
import {MassUpdatePopupV5} from "../details/datatypes/mass/MassUpdatePopupV5";
import {Functions} from "../../../utils/function/Functions";
import {ScreenType} from "../../modules/abstract/ScreenType";
import type {IActionBarItem} from "./ModuleViewV5";
import {MoreIconsPopupV5} from "./defaultfields/MoreIconsPopupV5";

interface IGridActionBarV5Props<T extends IModel> {
	readonly items: T[];
	readonly buttons: IActionBarItem<T>[];
	readonly feature: XyiconFeature;
	readonly defaultClick: (item: IActionBarItem<T>) => void;
	readonly onClose?: () => void;
	readonly onFocus?: (items: T[]) => void;
	readonly onSelect?: (items: T[]) => void;
	readonly selectedScreen?: ScreenType;
}

export const GridActionBarV5 = (props: IGridActionBarV5Props<IModel>) => {
	const {items, feature, buttons, selectedScreen = ScreenType.GRID, onClose, onFocus, onSelect, defaultClick} = props;

	const onClickCheckboxWithinMoreIconsPopup = (items: IModel[]) => {
		onFocus?.(items);
		onSelect?.(items);
	};

	const appState = useAppStore((state) => state.appState);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [selectedFieldRefId, setSelectedFieldRefId] = useState<string>("");
	const [parentWidth, setParentWidth] = useState<number>(0);

	const divRef = useRef<HTMLDivElement>();
	const _showAllDivRef = useRef<HTMLDivElement>();
	const resizeObserver = useRef<ResizeObserver>(
		new ResizeObserver((entries: ResizeObserverEntry[]) => {
			for (const entry of entries) {
				setParentWidth(entry.borderBoxSize[0].inlineSize);
			}
		}),
	);

	const onButtonClick = (button: IActionBarItem<IModel>) => {
		if (button.onClick) {
			button.onClick(items);
		} else {
			defaultClick(button);
		}
	};

	const getFieldsForMassUpdate = () => {
		const fieldRefIdsForType: string[] = [];

		for (const item of items) {
			if (item?.typeId) {
				const newRefIds = appState.actions.getFieldRefIdsForType(item.typeId, item.ownFeature);

				for (const refId of newRefIds) {
					if (!fieldRefIdsForType.includes(refId)) {
						const field = appState.actions.getFieldByRefId(refId);

						if (!field.hasFormula) {
							fieldRefIdsForType.push(refId);
						}
					}
				}
			}
		}

		return fieldRefIdsForType;
	};

	const onCountLabelClick = () => {
		if (feature !== XyiconFeature.SpaceEditor) {
			setIsOpen((o) => !o);
		}
	};

	const getCountLabel = () => {
		if (selectedScreen === ScreenType.CARD) {
			return "";
		} else {
			return (
				<CountLabelStyled
					onClick={onCountLabelClick}
					onMouseDown={Functions.stopPropagation} // to prevent from reopening again (useClickOutside closes it, then it reopens, if you click it while it's already open)
					ref={_showAllDivRef}
					$inListView={feature !== XyiconFeature.SpaceEditor}
				>
					{`${items.length} selected`}
				</CountLabelStyled>
			);
		}
	};

	useEffect(() => {
		const resizeObserverCurrent = resizeObserver.current;
		if (resizeObserverCurrent && divRef.current?.parentElement) {
			resizeObserverCurrent.observe(divRef.current.parentElement);
		}

		return () => {
			if (resizeObserverCurrent) {
				resizeObserverCurrent.disconnect();
			}
		};
	}, []);

	return (
		<Observer>
			{() => {
				const shouldShowIconsOnly = parentWidth < 700;
				return (
					<>
						<GridActionBarStyled
							className="GridActionBar"
							ref={divRef}
						>
							{getCountLabel()}
							<ButtonsStyled>
								{buttons.map((button, index) => {
									if (button.isVisible?.(items as Catalog[]) && button.IconComponent) {
										if (button.id === "fieldUpdate") {
											return (
												<DropdownButtonV5
													key={button.id}
													options={[
														...getFieldsForMassUpdate().map((fieldRefId) => {
															const field = appState.actions.getFieldByRefId(fieldRefId);

															return {
																label: field.name,
																onClick: () => setSelectedFieldRefId(fieldRefId),
															} as IDropdownOption;
														}),
													]}
													horizontalAlignment={HorizontalAlignment.left}
													verticalAlignment={VerticalAlignment.topOuter}
													changeAlignmentOnPositionCorrection={false}
													style={{
														maxHeight: "calc(100vh - 136px)",
														overflow: "auto",
													}}
													button={
														<IconButtonV5
															key={`${button.id}${index}`}
															title={button.title}
															onClick={() => onButtonClick(button)}
															label={shouldShowIconsOnly ? "" : button.label}
															IconComponent={button.IconComponent}
														/>
													}
												/>
											);
										}

										return (
											<Fragment key={`${button.id}${index}`}>
												{button.componentFactory?.(items) ?? (
													<IconButtonV5
														title={button.title}
														onClick={() => onButtonClick(button)}
														label={shouldShowIconsOnly ? "" : button.label}
														IconComponent={button.IconComponent}
														disabled={button.isEnabled ? !button.isEnabled?.(items) : false}
														isActive={button.isActive}
													/>
												)}
											</Fragment>
										);
									}

									return null;
								})}
							</ButtonsStyled>
							<ActionBarLineStyled />
							<CloseButtonStyled>
								<IconButtonV5
									onClick={onClose}
									IconComponent={XmarkIcon}
								/>
							</CloseButtonStyled>
						</GridActionBarStyled>
						{selectedFieldRefId && (
							<MassUpdatePopupV5
								fieldRefId={selectedFieldRefId}
								onClose={() => setSelectedFieldRefId("")}
								items={items}
							/>
						)}
						{isOpen && (
							<MoreIconsPopupV5
								items={items}
								parentRef={_showAllDivRef}
								onClose={() => setIsOpen(false)}
								verticalAlignment={VerticalAlignment.topOuter}
								offsetY={20}
								onClickCheckbox={onClickCheckboxWithinMoreIconsPopup}
							/>
						)}
					</>
				);
			}}
		</Observer>
	);
};

const GridActionBarStyled = styled(SpaceActionBarStyled)`
	bottom: 88px;
	transform: translateX(-50%);
	left: 50%;
	white-space: nowrap;
	gap: ${baseDistance.sm};
`;

const CountLabelStyled = styled.div<{$inListView: boolean}>`
	background-color: ${colorPalette.primary.c500Primary};
	border-radius: ${radius.sm};
	padding: ${baseDistance.sm};
	height: 32px;
	cursor: ${(props) => (props.$inListView ? "pointer" : "auto")};
`;

const ButtonsStyled = styled.div`
	display: flex;
	gap: ${baseDistance.sm};

	> ${IconButtonStyled} {
		padding: 0 ${baseDistance.xs};
	}

	> ${DropdownButtonStyled} {
		gap: ${baseDistance.sm};
		padding: 0 ${baseDistance.xs};

		&.isOpen {
			background-color: ${colorPalette.gray.c700Dark};
		}
	}

	.SetFavoriteButton {
		&:hover,
		&.selected {
			color: ${colorPalette.libraryColors.eggYolk};
		}
	}
`;

const ActionBarLineStyled = styled(LineStyled)`
	left: 0;
	background-color: ${colorPalette.white};
	width: 1px;
`;

const CloseButtonStyled = styled.div`
	svg {
		width: 16px;
		height: 16px;
	}

	${IconButtonStyled} {
		width: 32px;
		height: 32px;
	}
`;
