import {inject, observer} from "mobx-react";
import * as React from "react";
import {computed} from "mobx";
import {styled} from "styled-components";
import type {AppActions} from "../../../data/state/AppActions";
import type {XyiconFeature} from "../../../generated/api/base";
import type {AppState} from "../../../data/state/AppState";
import {ButtonV5} from "../button/ButtonV5";
import type {TransportLayer} from "../../../data/TransportLayer";
import {View} from "../../../data/models/View";
import type {IFilterState} from "../../../data/models/filter/Filter";
import {AlertV5} from "../popup/AlertV5";
import {colorPalette} from "../styles/colorPalette";
import {fontSize} from "../styles/styles";

interface IFilterChangeNotificationProps {
	readonly actions: AppActions;
	readonly feature: XyiconFeature;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface IFilterChangeNotificationState {
	disabled: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class FilterChangeNotificationV5 extends React.Component<IFilterChangeNotificationProps, IFilterChangeNotificationState> {
	constructor(props: IFilterChangeNotificationProps) {
		super(props);

		this.state = {
			disabled: false,
		};
	}

	@computed
	private get selectedView() {
		return this.props.appState.actions.getSelectedView(this.props.feature);
	}

	private saveView = async () => {
		this.setState({
			disabled: true,
		});

		const {appState, transport} = this.props;
		const selectedView = this.selectedView;
		const currentData = selectedView.getData();
		const savedData = selectedView.getSavedData();
		const savedView = new View(savedData, appState);

		// Save only the data that is associated with the viewDataType,
		// But after the update-on-the-backend, update the view with the rest of the (unsaved) modifications
		savedView.setFilters(this.newData as any);
		selectedView.setFilters(this.newData as any);

		const dataToSave = savedView.getData();

		await transport.services.view.update(dataToSave);

		// Update the view with the rest of the (unsaved) modifications as well, but don't save them
		const currentView = new View(currentData, appState);

		this.resetDataForSelectedView(currentView);

		this.setState({
			disabled: false,
		});
	};

	private resetDataForSelectedView = (viewToResetTo: View) => {
		const selectedView = this.selectedView;

		selectedView.setFilters(viewToResetTo.filters);
		selectedView.setSerializedColumns(viewToResetTo.getSerializedColumns());

		// Only SpaceEditor Views have this property
		if (selectedView.spaceEditorViewSettings) {
			selectedView.spaceEditorViewSettings.captions = viewToResetTo.spaceEditorViewSettings.captions;
			selectedView.spaceEditorViewSettings.formattingRules = viewToResetTo.spaceEditorViewSettings.formattingRules;
			selectedView.spaceEditorViewSettings.layers = viewToResetTo.spaceEditorViewSettings.layers;
		}
	};

	@computed
	private get newData() {
		return this.selectedView.getNewDataForType("filters");
	}

	private resetFilters = () => {
		this.selectedView.resetFiltersToSaved();
	};

	public override render() {
		const view = this.selectedView;

		const isAdvanced = view.filters.type === "advanced";
		const oldChanges = view.getSavedDataForType("filters", false);
		const newChanges = view.getNewDataForType("filters", false);

		let hasUnsavedChanges = false;

		if (isAdvanced) {
			hasUnsavedChanges = JSON.stringify(oldChanges?.filters?.advanced) !== JSON.stringify((newChanges as IFilterState)?.filters?.advanced);
		} else {
			hasUnsavedChanges = JSON.stringify(oldChanges?.filters?.simple) !== JSON.stringify((newChanges as IFilterState)?.filters?.simple);
		}

		return (
			<>
				{hasUnsavedChanges && (
					<AlertV5 zIndex={2}>
						<>
							{
								<TextStyled>
									<PrimaryText>A filter has been applied</PrimaryText>
									<SecondaryText>Save the changes or reset the view</SecondaryText>
								</TextStyled>
							}
							<ButtonV5
								disabled={this.state.disabled}
								label="Reset"
								type="secondary"
								onClick={this.resetFilters}
								style={{marginLeft: "auto"}}
							/>
							<ButtonV5
								disabled={this.state.disabled}
								label="Save"
								onClick={this.saveView}
							/>
						</>
					</AlertV5>
				)}
			</>
		);
	}
}

const TextStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	font-size: ${fontSize.md};
	line-height: 16px;
	width: 190px;
`;

const PrimaryText = styled.div`
	color: ${colorPalette.gray.c950};
`;

const SecondaryText = styled.div`
	color: ${colorPalette.gray.c700Dark};
	font-size: ${fontSize.xs};
`;
