import {inject, observer} from "mobx-react";
import * as React from "react";
import {computed} from "mobx";
import styled from "styled-components";
import {View} from "../../../../data/models/View";
import type {IViewSort, ViewChangeType} from "../../../../data/models/ViewUtils";
import {notify} from "../../../../utils/Notify";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import {NotificationType} from "../../../notification/Notification";
import type {XyiconFeature} from "../../../../generated/api/base";
import type {TransportLayer} from "../../../../data/TransportLayer";
import type {AppState} from "../../../../data/state/AppState";
import {PromptWindowV5} from "../../popup/PromptWindowV5";
import {DropdownButtonV5} from "../../interaction/DropdownButtonV5";
import ChevronDownIcon from "../../icons/chevron-down.svg?react";
import {HorizontalAlignment} from "../../../../utils/dom/DomUtils";
import {FlexCenterStyle, baseDistance} from "../../styles/styles";
import {GlobalSharingPopup} from "../../sharing/GlobalSharingPopup";

interface ISaveToViewButtonProps {
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
	readonly feature: XyiconFeature;
	readonly viewChangeType: ViewChangeType;
	readonly manageColumns?: boolean;
	readonly onSaveClicked?: () => void;
	readonly className?: string;
	readonly horizontalAlignment?: HorizontalAlignment;
}

interface ISaveToViewButtonState {
	isLoading: boolean;
	isGlobalActionPanelOpen: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class SaveToViewButtonV5 extends React.Component<ISaveToViewButtonProps, ISaveToViewButtonState> {
	private _isMounted: boolean = false;

	constructor(props: ISaveToViewButtonProps) {
		super(props);
		this.state = {
			isLoading: false,
			isGlobalActionPanelOpen: false,
		};
	}

	private onSaveAsNewViewClick = async () => {
		const view = this.selectedView;
		const name = await PromptWindowV5.open("Please enter the name for the new View", "New View", `${view.name} - Copy`, `${view.name} - Copy`);

		if (name) {
			await this.saveNewView(name);
			this.props.onSaveClicked?.();
		}
	};

	private onSaveToViewClick = async () => {
		const view = this.selectedView;

		if (view.isGlobal) {
			this.setState({
				isGlobalActionPanelOpen: true,
			});
		} else {
			await this.saveView();
			this.props.onSaveClicked?.();
		}
	};

	@computed
	private get selectedView() {
		return this.props.appState.actions.getSelectedView(this.props.feature);
	}

	@computed
	private get newData() {
		return this.selectedView.getNewDataForType(this.props.viewChangeType);
	}

	private saveNewDataToView(viewToSave: View) {
		const newData = this.newData as any;

		switch (this.props.viewChangeType) {
			case "captions":
				viewToSave.spaceEditorViewSettings.captions = newData;
				break;
			// Save columns and column sorts together
			case "columns":
			case "column sorts":
				viewToSave.setSerializedColumns(JSON.stringify(this.selectedView.getNewDataForType("columns")));
				viewToSave.setSorts(this.selectedView.getNewDataForType("column sorts") as IViewSort[]);
				break;
			case "conditional formatting":
				viewToSave.spaceEditorViewSettings.formattingRules = newData;
				break;
			case "filters":
				viewToSave.setFilters(newData);
				break;
			case "layers":
				viewToSave.spaceEditorViewSettings.layers = newData;
				break;
		}
	}

	// Overwrites selectedView's data with viewToResetTo's data
	private resetDataForSelectedView(viewToResetTo: View) {
		const selectedView = this.selectedView;

		selectedView.setFilters(viewToResetTo.filters);
		selectedView.setSerializedColumns(viewToResetTo.getSerializedColumns());

		// Only SpaceEditor Views have this property
		if (selectedView.spaceEditorViewSettings) {
			selectedView.spaceEditorViewSettings.captions = viewToResetTo.spaceEditorViewSettings.captions;
			selectedView.spaceEditorViewSettings.formattingRules = viewToResetTo.spaceEditorViewSettings.formattingRules;
			selectedView.spaceEditorViewSettings.layers = viewToResetTo.spaceEditorViewSettings.layers;
		}
	}

	private async saveNewView(newViewName: string) {
		this.setState({
			isLoading: true,
		});

		const {appState, transport, feature} = this.props;
		const selectedView = this.selectedView;

		const currentData = selectedView.getData(); //get all of selectedView's data
		const savedView = new View(currentData, appState);

		// Save only the data that is associated with the viewDataType,
		// But after creating the new view, reset the state of selected view before selecting the newly created view
		this.saveNewDataToView(savedView);

		const dataToSave = ObjectUtils.deepClone(savedView.getData());

		dataToSave.viewSharingSettings.length = 0;

		dataToSave.name = newViewName;
		dataToSave.ownerUserID = appState.user?.id || "";

		const {result: viewData, error} = await transport.services.view.create(dataToSave, feature);

		if (viewData?.viewID) {
			// Reset the data of the selected view
			const savedView = new View(currentData, appState);

			this.resetDataForSelectedView(savedView);

			// Select the newly created view
			appState.actions.selectViewById(viewData.viewID);
		} else if (error) {
			notify(this.props.appState.app.notificationContainer, {
				type: NotificationType.Error,
				title: "Error",
				lifeTime: Infinity,
				description: error.ErrorMessage || "Unknown error",
			});
		}

		if (this._isMounted) {
			this.setState({
				isLoading: false,
			});
		}
	}

	private saveView = async () => {
		this.setState({
			isLoading: true,
		});

		const {appState, transport} = this.props;
		const selectedView = this.selectedView;
		const currentData = selectedView.getData();
		const savedData = selectedView.getSavedData();
		const savedView = new View(savedData, appState);

		// Save only the data that is associated with the viewDataType,
		// But after the update-on-the-backend, update the view with the rest of the (unsaved) modifications
		this.saveNewDataToView(savedView);
		this.saveNewDataToView(selectedView);

		const dataToSave = savedView.getData();

		await transport.services.view.update(dataToSave);

		// Update the view with the rest of the (unsaved) modifications as well, but don't save them
		const currentView = new View(currentData, appState);

		this.resetDataForSelectedView(currentView);

		if (this._isMounted) {
			this.setState({
				isLoading: false,
			});
		}
	};

	public override componentDidMount() {
		this._isMounted = true;
	}

	public override componentWillUnmount() {
		this._isMounted = false;
	}

	public override render() {
		const view = this.selectedView;
		const {horizontalAlignment = HorizontalAlignment.left, className = ""} = this.props;

		// in case of manageColumns we don't want to detect column width change in that case
		if (view.hasUnsavedChanges(this.props.viewChangeType) || this.props.manageColumns) {
			return (
				<>
					{this.state.isGlobalActionPanelOpen && (
						<GlobalSharingPopup
							viewItem={view}
							onClose={() => this.setState({isGlobalActionPanelOpen: false})}
							viewActionType="modify"
							isReports={false}
							onSaveClicked={this.saveView}
						/>
					)}
					<DropdownButtonV5
						className={`${className} saveDropdown`}
						options={[
							{
								label: "Save to view",
								onClick: this.onSaveToViewClick,
								disabled: this.state.isLoading,
							},
							{
								label: "Save as new view",
								onClick: this.onSaveAsNewViewClick,
								disabled: this.state.isLoading,
							},
						]}
						horizontalAlignment={horizontalAlignment}
						button={
							<DropDownTitleStyle>
								Save <ChevronDownIcon />
							</DropDownTitleStyle>
						}
					/>
				</>
			);
		}

		return null;
	}
}

const DropDownTitleStyle = styled.div`
	${FlexCenterStyle};
	padding: 0px ${baseDistance.sm};
	max-width: 340px;

	svg {
		margin-left: ${baseDistance.xs};
		width: 14px;
		height: 14px;
		position: relative;
	}
`;
