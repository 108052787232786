import * as React from "react";
import {inject, observer} from "mobx-react";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import {SelectInput} from "../../../../../widgets/input/select/SelectInput";
import {XyiconFeature, Permission} from "../../../../../../generated/api/base";
import type {View} from "../../../../../../data/models/View";
import type {Catalog} from "../../../../../../data/models/Catalog";
import {filterModels} from "../../../../../../data/models/filter/Filter";
import type {Xyicon} from "../../../../../../data/models/Xyicon";
import {SearchField} from "../../../../../widgets/input/search/SearchField";
import {Debouncer} from "../../../../../../utils/function/Debouncer";
import {LoaderIcon} from "../../../../../widgets/button/LoaderIcon";
import type {IModel} from "../../../../../../data/models/Model";
import type {GridView} from "../../../../abstract/grid/GridView";
import type {ICreateUnplottedXyiconParam} from "../../../../abstract/ModuleView";
import type {AppState} from "../../../../../../data/state/AppState";
import {ToggleSwitchField} from "../../../../../widgets/button/switch/ToggleSwitchField";
import {DebugInformation} from "../../../../../../utils/DebugInformation";
import {TimeUtils} from "../../../../../../utils/TimeUtils";
import {IconButton} from "../../../../../widgets/button/IconButton";
import {DraggableXyiconCatalogContainer} from "./DraggableXyiconCatalogContainer";
import type {DockableTitle} from "./Dockable";

const logId: string = "Rendering XyiconCatalogContainer";

interface IXyiconCatalogContainerProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly gridView?: React.RefObject<GridView<IModel>>;
	readonly renderTitle: DockableTitle;
	readonly isDocked: boolean;
	readonly isOpen: boolean;
	readonly close: () => void;
	readonly onCloseClick: () => void;
	readonly onAddCatalogClick: () => void;
	readonly onDuplicateCatalogClick: (catalog: Catalog) => void;
	readonly onCreateUnplottedXyicons: (params: ICreateUnplottedXyiconParam[]) => Promise<void> | void;
	readonly appState?: AppState;
}

interface IXyiconCatalogContainerState {
	search: string;
	isLoading: boolean;
	onlyFavorites: boolean;
	loadMoreCount: number;
}

@inject("appState")
@observer
export class XyiconCatalogContainer extends React.Component<IXyiconCatalogContainerProps, IXyiconCatalogContainerState> {
	private _debouncer: Debouncer = new Debouncer(100);
	private _isMounted: boolean = false;
	private _glyphContainerRef = React.createRef<HTMLDivElement>();
	private _filteredUnslicedItems: Xyicon[] | Catalog[] = [];
	private readonly rowPerPage: number = 50;

	private _selectInput = React.createRef<SelectInput>();

	private get userCatalogPermission(): Permission {
		return this.props.appState.user?.getOrganizationPermission(XyiconFeature.XyiconCatalog) ?? Permission.None;
	}

	constructor(props: IXyiconCatalogContainerProps) {
		super(props);
		this.state = {
			loadMoreCount: 1,
			search: "",
			isLoading: true,
			onlyFavorites: false,
		};
	}

	private getFilteredItemsByView() {
		const feature = this.getFeature();
		const {appState} = this.props.spaceViewRenderer.transport;
		const items = feature === XyiconFeature.Xyicon ? appState.actions.getUnplottedXyicons() : appState.actions.getList(feature);

		return filterModels(items, appState.actions.getSelectedView(feature).filters, appState);
	}

	private filterItemBySearch = (catalogOrXyicon: Catalog | Xyicon) => {
		const feature = this.getFeature();
		const stringsToCheck = [catalogOrXyicon.model, catalogOrXyicon.type.name];

		const actions = this.props.spaceViewRenderer.actions;
		const fields = actions.getFieldsByFeature(feature).filter((f) => f.name !== "Icon");

		for (const field of fields) {
			const fieldValue = actions.renderValue(catalogOrXyicon, field.refId) as string;

			if (fieldValue.length > 0) {
				stringsToCheck.push(fieldValue);
			}
		}

		return stringsToCheck.some((str) => StringUtils.containsIgnoreCase(str, this.state.search));
	};

	private filterItemByFavoriteToggler = (catalogOrXyicon: Catalog | Xyicon) => {
		if (this.state.onlyFavorites) {
			if (catalogOrXyicon.ownFeature === XyiconFeature.XyiconCatalog) {
				return catalogOrXyicon.isFavorite;
			}
		}

		return true;
	};

	private onSearchInput = (value: string) => {
		this._debouncer.debounce(() => {
			this.setState({
				search: value,
				loadMoreCount: 1,
			});
		});
	};

	private onViewChange = (view: View) => {
		const {actions} = this.props.spaceViewRenderer.transport.appState;

		actions.selectView(view);
	};

	private getFeature() {
		if (this.props.renderTitle === "Catalog") {
			return XyiconFeature.XyiconCatalog;
		} else if (this.props.renderTitle === "Unplotted Xyicons") {
			return XyiconFeature.Xyicon;
		}
	}

	private columnContainerOnScroll(event: React.UIEvent<HTMLDivElement, UIEvent>) {
		const {scrollTop, offsetHeight, scrollHeight} = event.target as HTMLDivElement;
		const leewayBeforeBottom = 10;

		if (scrollTop + offsetHeight >= scrollHeight - leewayBeforeBottom) {
			this.setState((prevState) => ({
				loadMoreCount: prevState.loadMoreCount + 1,
			}));
		}
	}

	private get maxNumberOfItemsToShow(): number {
		return this.state.loadMoreCount * this.rowPerPage;
	}

	private getItems(feature: XyiconFeature.XyiconCatalog | XyiconFeature.Xyicon) {
		this._filteredUnslicedItems = this.getFilteredItemsByView().filter(this.filterItemByFavoriteToggler).filter(this.filterItemBySearch) as
			| Xyicon[]
			| Catalog[];

		const filteredItems = this._filteredUnslicedItems.slice(0, this.maxNumberOfItemsToShow).toSorted((a: Catalog | Xyicon, b: Catalog | Xyicon) => {
			const {actions} = this.props.appState;

			const aPermission = actions.getModuleTypePermission(a.typeId, XyiconFeature.Xyicon);
			const bPermission = actions.getModuleTypePermission(b.typeId, XyiconFeature.Xyicon);

			if (aPermission === bPermission) {
				if (a.type.name === b.type.name) {
					return StringUtils.sortIgnoreCase(a.model, b.model);
				} else {
					return StringUtils.sortIgnoreCase(a.type.name, b.type.name);
				}
			} else {
				return aPermission < bPermission ? 1 : -1;
			}
		});

		if (filteredItems.length > 0) {
			return (
				<DraggableXyiconCatalogContainer
					spaceViewRenderer={this.props.spaceViewRenderer}
					gridView={this.props.gridView}
					feature={feature}
					items={filteredItems.map((v) => ({object: v}))}
					onPointerMove={this.onGlyphPointerMove}
					onPointerUp={this.onGlyphPointerUp}
					onDuplicateCatalogClick={this.props.onDuplicateCatalogClick}
					onCreateUnplottedXyicons={this.props.onCreateUnplottedXyicons}
					queryString={this.state.search}
				/>
			);
		} else {
			return <div className="flexCenter">There are no {feature === XyiconFeature.Xyicon ? "unplotted xyicons" : "catalog items"} to display</div>;
		}
	}

	private onGlyphPointerMove = () => {
		if (!this.props.isDocked) {
			this.props.close();
		}
	};

	private onGlyphPointerUp = () => {
		if (!this.props.isDocked && !this.props.isOpen) {
			this.props.onCloseClick();
		}
	};

	private onOnlyFavoritesChange = async (value: boolean) => {
		this.props.appState.app.transport.services.localStorage.set(this.keyForLocalStorage, value);
		this.setState({
			onlyFavorites: value,
		});

		await TimeUtils.wait(300);
		this._selectInput.current?.setState({open: false});
	};

	private getOnlyFavorites() {
		if (this.getFeature() === XyiconFeature.XyiconCatalog) {
			return (
				<ToggleSwitchField
					classNames="onlyFavoritesToggler hbox alignCenter"
					label="Only Favorites"
					value={this.state.onlyFavorites}
					onChange={this.onOnlyFavoritesChange}
				/>
			);
		}

		return null;
	}

	private get keyForLocalStorage() {
		const {appState, spaceViewRenderer} = this.props;
		const module = spaceViewRenderer.isMounted ? "space-editor" : "xyicon-panel";

		return `srv4-org-${appState.organizationId}-${module}-catalog-panel-only-favorites`;
	}

	public override UNSAFE_componentWillMount() {
		DebugInformation.start(logId);
	}

	public override componentWillUnmount() {
		this._isMounted = false;
	}

	public override componentDidUpdate(prevProps: Readonly<IXyiconCatalogContainerProps>) {
		if (prevProps.renderTitle !== this.props.renderTitle) {
			this.setState({search: ""});
		}

		if (this._glyphContainerRef.current) {
			const div = this._glyphContainerRef.current;
			const hasVerticalScrollbar = div.scrollHeight > div.clientHeight;

			if (!hasVerticalScrollbar && this._filteredUnslicedItems.length > this.maxNumberOfItemsToShow) {
				this.setState((s) => ({
					loadMoreCount: s.loadMoreCount + 1,
				}));
			}
		}
	}

	public override componentDidMount() {
		const onlyFavorites = this.props.appState.app.transport.services.localStorage.get(this.keyForLocalStorage);

		this.setState({onlyFavorites});

		this._isMounted = true;
		setTimeout(() => {
			if (this._isMounted) {
				this.setState({
					isLoading: false,
				});
				requestAnimationFrame(() => {
					DebugInformation.end(logId);
				});
			}
		}, 300); // derived from css: 0.3s transition
	}

	public override render() {
		const {actions} = this.props.spaceViewRenderer.transport.appState;

		const feature = this.getFeature();

		return (
			<>
				<div className="secondaryHeader hbox">
					<SelectInput
						ref={this._selectInput}
						childBeforeOptions={this.getOnlyFavorites()}
						options={actions.getViews(feature)}
						selected={actions.getSelectedView(feature)}
						render={(view) => view.name}
						onChange={this.onViewChange}
					/>
					<SearchField
						onInput={this.onSearchInput}
						value={this.state.search}
					/>
					{feature === XyiconFeature.XyiconCatalog && this.userCatalogPermission > Permission.View && (
						<div className="tools hbox">
							<IconButton
								className="createCatalog"
								title="Create Catalog Item"
								icon="add"
								onClick={this.props.onAddCatalogClick}
							/>
							{/* <OptionsButton className="options flexCenter" options={this.getOptions()} /> */}
						</div>
					)}
				</div>
				{this.state.isLoading ? (
					<div className="dockableLoader flexCenter vbox">
						<LoaderIcon />
						<p>{`Loading ${feature === XyiconFeature.Xyicon ? "Unplotted Xyicons" : "Catalog"}...`}</p>
					</div>
				) : (
					<div
						className="glyphContainer"
						ref={this._glyphContainerRef}
						onScroll={(ev) => this.columnContainerOnScroll(ev)}
					>
						{this.getItems(feature)}
					</div>
				)}
			</>
		);
	}
}
