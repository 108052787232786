export class HTMLUtils {
	public static readonly modalContainerId = "modalContainer";
	public static readonly popupBackdropV5Class = "popupBackdropV5";
	public static readonly noFocusClassName = "noFocus";
	/**
	 * offsetXY is not widely supported by the browsers yet
	 */
	public static clientXYToOffsetXY(element: HTMLElement, x: number, y: number) {
		const boundingClientRect = element.getBoundingClientRect();

		return {
			x: x - boundingClientRect.left,
			y: y - boundingClientRect.top,
		};
	}

	public static getSize(element: Element) {
		return element.getBoundingClientRect();
	}

	/**
	 * Removes every child of the element
	 * @param element HTMLElement
	 */
	public static clearElement(element: Element, alsoRemoveFromDom: boolean = false) {
		while (element.lastChild) {
			element.removeChild(element.lastChild);
		}

		if (alsoRemoveFromDom) {
			HTMLUtils.detach(element);
		}
	}

	/**
	 * Removes element from DOM
	 * @param element HTMLelement
	 */
	public static detach(element: Element) {
		element?.parentElement?.removeChild(element);
	}

	/**
	 * Returns true if parent contains child
	 * @param parent
	 * @param child
	 * @param acceptIfSame
	 */
	public static isDescendant(parent: Element, child: Element, acceptIfSame: boolean = true) {
		let node = acceptIfSame ? child : child.parentElement;

		while (node) {
			if (node === parent) {
				return true;
			}
			node = node.parentElement;
		}

		return false;
	}

	public static doesAncestorHaveClass(element: Element, className: string, acceptIfSame: boolean = true) {
		let node = acceptIfSame ? element : element.parentElement;

		while (node?.classList) {
			if (node.classList.contains(className)) {
				return true;
			}
			node = node.parentElement;
		}

		return false;
	}

	public static elementFromPoint(x: number, y: number) {
		return document.elementFromPoint(x, y);
	}
}
