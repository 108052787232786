import * as React from "react";
import {inject, observer} from "mobx-react";
import {TextInput} from "../text/TextInput";
import {SVGIcon} from "../../button/SVGIcon";
import {IconButton} from "../../button/IconButton";
import {SearchField} from "../search/SearchField";
import {ReactUtils} from "../../../utils/ReactUtils";
import {ImageUtils} from "../../../../utils/image/ImageUtils";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {MathUtils} from "../../../../utils/math/MathUtils";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {HorizontalAlignment, VerticalAlignment, DomUtils} from "../../../../utils/dom/DomUtils";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {AppUtils} from "../../../../utils/AppUtils";
import type {App} from "../../../../App";

interface ISelectInputProps<T> {
	readonly className?: string;
	readonly childBeforeOptions?: React.ReactNode;
	readonly options: T[];
	readonly nullOption?: boolean;
	readonly selected?: T;
	readonly selectedIsRemoved?: boolean;
	readonly disabled?: boolean;
	readonly nullLabel?: string;
	readonly fullWidth?: boolean;
	readonly sort: boolean;
	readonly searchBar?: boolean;
	readonly horizontalAlignment?: HorizontalAlignment;
	readonly verticalAlignment?: VerticalAlignment;
	readonly openByDefault?: boolean;
	readonly noFixedPosition?: boolean;
	readonly onFocusLossForceBlur?: boolean;
	readonly optionsZIndex?: number;
	readonly placeholder?: string;
	readonly focused?: boolean;
	readonly dropdownIcon?: string;
	readonly inline?: boolean;
	readonly isDark?: boolean;

	readonly render?: (item: T) => React.ReactNode;
	readonly stringifyOption?: (item: T) => string;
	readonly onChange?: (option: T) => void;
	readonly onFocus?: (value: boolean) => void;
	readonly onClick?: () => void;

	readonly app?: App;
	readonly renderOptionValue?: (item: T) => string;
	readonly isSingleSelect?: boolean;
}

export interface ISelectInputState {
	open: boolean;
	search: string;
	transform: TransformObj;
}
@inject("app")
@observer
export class SelectInput<T = any> extends React.Component<ISelectInputProps<T>, ISelectInputState> {
	public static readonly defaultProps: ISelectInputProps<any> = {
		options: [],
		nullOption: false,
		disabled: false,
		render: (option) => option,
		nullLabel: "",
		sort: true,
		searchBar: true,
		openByDefault: false,
		isDark: false,
		horizontalAlignment: HorizontalAlignment.left,
		verticalAlignment: VerticalAlignment.bottomOuter,
	};

	private _element = React.createRef<HTMLDivElement>();
	private _list = React.createRef<HTMLDivElement>();
	private _isMounted = false;
	private _touchMove = false;

	constructor(props: ISelectInputProps<T>) {
		super(props);
		this.state = {
			open: props.openByDefault || props.focused,
			search: "",
			transform: null,
		};
	}

	private onOpen = async (event?: React.MouseEvent) => {
		try {
			document.addEventListener("click", this.onClick);

			this.props.onClick?.();
			event?.stopPropagation();
			this.props.onFocus?.(true);
			this.setState({open: true});
			AppUtils.disableScrolling(true);

			await TimeUtils.wait(100);

			if (this._isMounted) {
				// Auto-scroll to selected option when opening
				// requestAnimationFrame is needed because the div is not visible immediately after calling setState(open)
				requestAnimationFrame(() => {
					const selectedOption = this._element.current?.querySelector(".option.selected") as HTMLDivElement;

					DomUtils.scrollIntoViewIfNeeded(selectedOption);
				});
			}
		} catch (error) {
			console.warn(error);
		}
	};

	public override componentWillUnmount() {
		this._isMounted = false;
	}

	public override componentDidUpdate(prevProps: ISelectInputProps<T>, prevState: ISelectInputState) {
		if (
			!this.props.noFixedPosition &&
			((!prevState.open && this.state.open) || (prevState.search !== this.state.search && this.state.search !== "")) &&
			this._element.current &&
			this._list.current
		) {
			this.setState({
				transform: DomUtils.getFixedFloatingElementPosition(
					this._element.current,
					this._list.current,
					this.props.verticalAlignment,
					this.props.horizontalAlignment,
					0,
					0,
					true,
				),
			});
		}

		if (!prevProps.focused && this.props.focused) {
			this.onOpen();
		} else if (prevProps.focused && !this.props.focused) {
			this.onClose();
		}
	}

	private onClose = () => {
		if (this._isMounted) {
			document.removeEventListener("click", this.onClick);
			AppUtils.disableScrolling(false);

			this.setState({
				open: false,
				search: "",
			});

			this.props.onFocus?.(false);
		}
	};

	private onBlur = (event: any) => {
		if (this._isMounted) {
			let eventTargetInModalContainer = false;

			if (event.target instanceof Element) {
				eventTargetInModalContainer = this.props.app.modalContainer.contains(event.target);
			}

			if ((event && !eventTargetInModalContainer) || this.props.onFocusLossForceBlur || !this.props.focused) {
				this.onClose();

				AppUtils.disableScrolling(false);

				this.setState({
					open: false,
					search: "",
				});

				this.props.onFocus?.(false);
			}
		}

		return false;
	};

	private onOptionTouchMove = () => {
		this._touchMove = true;
	};

	private onOptionTouchEnd = (e: React.TouchEvent, option: T = null) => {
		if (!this._touchMove) {
			e.nativeEvent.stopImmediatePropagation();
			AppUtils.disableScrolling(false);
			this.setState({
				open: false,
				search: "",
			});
			this.props.onChange?.(option);
		}

		this._touchMove = false;
	};

	private onOptionClick = (e: React.MouseEvent, option: T = null) => {
		e.nativeEvent.stopImmediatePropagation();
		AppUtils.disableScrolling(false);
		this.setState({
			open: false,
			search: "",
		});
		this.props.onChange?.(option);
	};

	private deleteSearch = () => {
		this.setState({search: ""});
	};

	private onClick = (event: MouseEvent) => {
		if (!this._element.current?.contains(event.target as Element)) {
			this.onBlur(event);
		}
	};

	private renderLabel(option: T, placeholder: boolean) {
		if (placeholder && this.props.placeholder && this.props.selected === undefined) {
			return <div className="placeholder">{this.props.placeholder}</div>;
		}

		if (!option) {
			return this.props.nullOption ? this.props.nullLabel : "";
		} else {
			return this.props.render(option);
		}
	}

	public override componentDidMount() {
		this._isMounted = true;
	}

	public override render() {
		const {open, search, transform} = this.state;
		const {
			dropdownIcon,
			options,
			nullOption,
			disabled,
			selected,
			selectedIsRemoved,
			fullWidth,
			searchBar,
			noFixedPosition,
			stringifyOption,
			app,
			inline,
			childBeforeOptions,
			optionsZIndex,
			className,
			sort,
			isDark,
			renderOptionValue,
			isSingleSelect,
		} = this.props;

		const arr: number[] = [];

		// default selectinput font size and family
		ImageUtils.ctx.font = "14px Roboto";
		options.forEach((option) => {
			// need to add 30px to the measured width, because of the padding
			const label = this.renderLabel(option, false) || "";

			arr.push(Math.round(ImageUtils.ctx.measureText(label.toString()).width) + 30);
		});

		// need to calculate how wide should be the
		const listWidth = Math.max(...arr);
		const element = this._element.current;
		const distanceToRightViewPortEdge = element && window.innerWidth - element.getBoundingClientRect().right;
		const maxListWidth =
			element &&
			(listWidth > element.offsetWidth + distanceToRightViewPortEdge
				? listWidth - (listWidth - (element.offsetWidth + distanceToRightViewPortEdge)) - 20
				: listWidth);
		const pinCss: React.CSSProperties = {
			height: "10px",
			width: "10px",
			position: "absolute",
			left: transform?.x + element?.offsetWidth / 2 - 6 || "auto",
			top: transform?.y + 5 || "auto",
			zIndex: 9999,
			transform: "rotate(45deg)",
			border: "solid 5px transparent",
			borderLeft: "solid 5px white",
			borderTop: "solid 5px white",
		};

		let inlineStyle: React.CSSProperties = element && {
			width: MathUtils.clamp(maxListWidth, 150, 500),
			minWidth: element.offsetWidth,
			transform: transform?.translate,
			position: noFixedPosition ? "absolute" : "fixed",
			zIndex: optionsZIndex ?? 8500,
		};

		if (noFixedPosition && inlineStyle) {
			inlineStyle.transform = "";
		}

		const optionsClone = options.slice(); // [mobx] `observableArray.sort()` will not update the array in place. Use `observableArray.slice().sort()` to suppress this warning and perform the operation on a copy, or `observableArray.replace(observableArray.slice().sort())` to sort & update in place

		if (sort) {
			optionsClone.sort((optionA, optionB) => {
				let a = this.renderLabel(optionA, false).toString();
				let b = this.renderLabel(optionB, false).toString();

				if (stringifyOption) {
					a = stringifyOption(optionA);
					b = stringifyOption(optionB);
				}

				return StringUtils.sortIgnoreCase(a, b);
			});
		}

		const optionElements = optionsClone
			.filter((option) => {
				let o: any = option;

				if (stringifyOption) {
					o = stringifyOption(option);
				}

				if (search) {
					const value = typeof o === "string" ? o : this.renderLabel(o, false).toString();

					return StringUtils.containsIgnoreCase(value, search);
				} else {
					return true;
				}
			})
			.map((option, index) => {
				const isSelected = isSingleSelect ? renderOptionValue(option) : option;

				return (
					<div
						key={index}
						className={ReactUtils.cls("option", {
							selected: selected === isSelected,
							removed: selected === isSelected && selectedIsRemoved,
						})}
						onMouseDown={(event) => this.onOptionClick(event, option)}
						onTouchMove={this.onOptionTouchMove}
						onTouchEnd={(event) => this.onOptionTouchEnd(event, option)}
					>
						{this.renderLabel(option, false)}
					</div>
				);
			});

		return (
			<div
				ref={this._element}
				className={ReactUtils.cls(`SelectInput ${className || ""}`, {
					disabled,
					fullWidth,
					inline,
					isDark,
					cellContent: inline,
				})}
			>
				{open && options.length > 5 && searchBar && !inline ? (
					<div className="list-search FindInList">
						<SVGIcon
							classNames="search"
							icon="search"
						/>
						<TextInput
							value={search}
							onInput={(value) => this.setState({search: value})}
							placeholder="Find items ..."
							autoFocus={true}
						/>
						{search?.length > 0 && (
							<IconButton
								className="cancel"
								icon="cancel"
								onClick={this.deleteSearch}
							/>
						)}
					</div>
				) : (
					<div
						className="input"
						onClick={this.onOpen}
					>
						{dropdownIcon ? <SVGIcon icon={dropdownIcon} /> : this.renderLabel(selected, true)}
					</div>
				)}
				{open && (
					<>
						{inline && transform?.vertical === VerticalAlignment.bottom && (
							<DomPortal destination={app.modalContainer}>
								<div
									className="pin"
									style={pinCss}
								/>
							</DomPortal>
						)}
						<DomPortal
							destination={app.modalContainer}
							noPortal={noFixedPosition}
						>
							<div
								className={ReactUtils.cls("SelectInput__list ", {open, inline, isDark})}
								style={inlineStyle}
								ref={this._list}
							>
								{inline && options.length > 5 && (
									<SearchField
										value={search}
										onInput={(value) => this.setState({search: value})}
									/>
								)}
								{childBeforeOptions}
								{nullOption && optionElements.length > 0 && !(inline && search) && (
									<div
										className={ReactUtils.cls("option", {selected: !selected})}
										onMouseDown={this.onOptionClick}
										onTouchMove={this.onOptionTouchMove}
										onTouchEnd={this.onOptionTouchEnd}
									>
										{this.renderLabel(null, false)}
									</div>
								)}
								{optionElements.length === 0 ? (
									<div className="bottom-section noValues">
										No values found.
										<br />
										Contact your admin.
									</div>
								) : (
									optionElements
								)}
							</div>
						</DomPortal>
					</>
				)}
			</div>
		);
	}
}
