import type {Mesh, MeshPhongMaterial} from "three";
import {Box3} from "three";
import {FileUtils} from "../file/FileUtils";
import {MathUtils} from "../math/MathUtils";
import {Constants} from "../../ui/modules/space/spaceeditor/logic3d/Constants";
import {HTMLUtils} from "../HTML/HTMLUtils";
import type {IBox3} from "../THREEUtils";
import {THREEUtils} from "../THREEUtils";
import type {DefaultCameraPos} from "../../ui/modules/space/spaceeditor/logic3d/features/SceneManager";
import {SceneManager} from "../../ui/modules/space/spaceeditor/logic3d/features/SceneManager";
import type {Catalog} from "../../data/models/Catalog";
import type {AppActions} from "../../data/state/AppActions";
import type {Space} from "../../data/models/Space";
import type {Dimensions} from "../../generated/api/base";
import {CatalogIconType, ImageType, XyiconFeature} from "../../generated/api/base";
import type {LibraryModel} from "../../data/models/LibraryModel";
import type {Xyicon} from "../../data/models/Xyicon";
import type {FontObjectType} from "../../data/state/AppStateTypes";
import {ImageUtils} from "./ImageUtils";

interface ISnapShotObj {
	boundingBox: IBox3;
	xyiconSize: Dimensions;
	image: string;
}

export class ImageUploadPreprocessor {
	private static clearHelpersFromSVG(svg: SVGSVGElement) {
		const elementsToRemove = svg.querySelectorAll('[data-transformhelper="true"]');

		for (let i = 0; i < elementsToRemove.length; ++i) {
			const element = elementsToRemove[i];

			HTMLUtils.clearElement(element, true);
		}
	}

	private static getCSSReadySVGImageURL(svgElement: SVGSVGElement) {
		this.clearHelpersFromSVG(svgElement);

		return `${Constants.SVG_UTF8_PREFIX}${svgElement.outerHTML}`;
	}

	public static changeProblematicCharactersToURLEncode(url: string) {
		// https://stackoverflow.com/questions/30733607/svg-data-image-not-working-as-a-background-image-in-a-pseudo-element
		// https://gist.github.com/JacobDB/0ffffaf8e772c12acf7102edb8a302be
		return url.replace(/#/g, "%23");
	}

	private static paintSVGOnCanvas(resolution: number, svgElement: SVGSVGElement, fonts: FontObjectType) {
		return new Promise<string>(async (resolve, reject) => {
			const img = await ImageUtils.loadImage(this.getCSSReadySVGImageURL(svgElement));

			img.width = img.height = resolution;
			const thumbnailBase64 = ImageUtils.image2RasterBase64String(fonts, img, resolution, "png");

			resolve(thumbnailBase64);
		});
	}

	public static createCompressedImageFromSVG(
		fonts: FontObjectType,
		svgElement: SVGSVGElement,
		toSVG: boolean,
		toBase64: boolean = false,
		resolution: number = Constants.RESOLUTION.XYICON,
	) {
		if (toSVG) {
			this.clearHelpersFromSVG(svgElement);

			const url = toBase64
				? ImageUtils.svgText2Base64String(svgElement.outerHTML.replace(/[\n\r\s\t]+/g, " "))
				: this.getCSSReadySVGImageURL(svgElement);

			return this.changeProblematicCharactersToURLEncode(url);
		} else {
			return this.paintSVGOnCanvas(resolution, svgElement, fonts);
		}
	}

	public static getThumbnailFromMesh(mesh: Mesh, cameraPos: DefaultCameraPos, resolution: number = Constants.RESOLUTION.XYICON) {
		return new Promise<string>((resolve, reject) => {
			const cv = document.createElement("canvas");

			cv.width = cv.height = MathUtils.clamp(resolution, 1, 512);
			const sceneManager = new SceneManager(cv, mesh, false, cameraPos);

			const onAfterRender = () => {
				const base64 = cv.toDataURL();

				sceneManager.signals.onAfterRender.remove(onAfterRender);
				sceneManager.dispose();

				resolve(base64);
			};

			sceneManager.signals.onAfterRender.add(onAfterRender);
			sceneManager.init();
		});
	}

	public static async getTopToBottomSnapshotOfCatalog(
		catalog: Catalog,
		actions: AppActions,
		space: Space,
		correctionMultiplier: number,
	): Promise<ISnapShotObj> {
		const standardXyiconSize = Constants.SIZE.XYICON * correctionMultiplier;

		if (catalog.iconCategory === CatalogIconType.ModelParameter) {
			try {
				const modelParameters = catalog.iconData.modelParameters;
				const libraryModel = actions.getFeatureItemById<LibraryModel>(modelParameters.libraryModelID, XyiconFeature.LibraryModel);
				const mesh = await libraryModel.getPreviewMesh();
				const material = mesh.material as MeshPhongMaterial;

				material.color.set(`#${modelParameters.bodyColor.hex}`);

				if (modelParameters.bodyColor.transparency > 0) {
					material.opacity = 1 - modelParameters.bodyColor.transparency;
					material.transparent = true;
					material.needsUpdate = true;
				}

				const originalSizeInMeters = await libraryModel.getSizeInMeters();
				const newSizeInMeters = modelParameters.dimensions;
				const {spaceUnitsPerMeter} = space;

				THREEUtils.scaleObject3DForSpaceEditor(mesh, spaceUnitsPerMeter, originalSizeInMeters, newSizeInMeters);
				mesh.updateMatrixWorld(true);
				const bbox = new Box3().expandByObject(mesh);

				// It's rotated by default, so we need to swap y and z
				[bbox.min.y, bbox.min.z] = [bbox.min.z, bbox.min.y];
				[bbox.max.y, bbox.max.z] = [bbox.max.z, bbox.max.y];
				const xyiconSize: Dimensions = THREEUtils.getSizeOfBoundingBox3(bbox);
				const maxXyiconSize2D = Math.max(xyiconSize.x, xyiconSize.y);
				const image = await this.getThumbnailFromMesh(mesh, "top", (Constants.RESOLUTION.XYICON * maxXyiconSize2D) / standardXyiconSize);

				return {
					boundingBox: bbox,
					image: image,
					xyiconSize: xyiconSize,
				};
			} catch (error) {
				console.log("error ", error);
			}
		} else {
			return {
				boundingBox: {
					min: {
						x: -standardXyiconSize / 2,
						y: -standardXyiconSize / 2,
						z: -standardXyiconSize / 2,
					},
					max: {
						x: standardXyiconSize / 2,
						y: standardXyiconSize / 2,
						z: standardXyiconSize / 2,
					},
				},
				image: catalog.thumbnail,
				xyiconSize: {
					x: standardXyiconSize,
					y: standardXyiconSize,
					z: standardXyiconSize,
				},
			};
		}
	}

	public static async getTopToBottomSnapshotOfXyiconOrCatalog(
		xyiconOrCatalog: Xyicon | Catalog,
		actions: AppActions,
		space: Space,
		correctionMultiplier: number,
	): Promise<ISnapShotObj> {
		const isXyicon = xyiconOrCatalog.ownFeature === XyiconFeature.Xyicon;
		const xyicon = isXyicon ? xyiconOrCatalog : null;
		const catalog = isXyicon ? xyiconOrCatalog.catalog : xyiconOrCatalog;

		const catalogSnapshot = await this.getTopToBottomSnapshotOfCatalog(catalog, actions, space, correctionMultiplier);
		const finalSnapShot = {...catalogSnapshot};

		if (isXyicon && xyicon) {
			finalSnapShot.image = await ImageUtils.flipImage(catalogSnapshot.image, xyicon.isFlippedX, xyicon.isFlippedY);
			// Size and bbox don't change when the image is flipped
		}

		return finalSnapShot;
	}

	public static async getThumbnailFromGltf(
		gltfUrl: string,
		cameraPos: DefaultCameraPos,
		resolution: number = Constants.RESOLUTION.XYICON,
	): Promise<string> {
		const glTF = await THREEUtils.loadGLTF(gltfUrl);
		const mesh = THREEUtils.getMeshFromGltf(glTF);

		return this.getThumbnailFromMesh(mesh, cameraPos, resolution);
	}

	public static async getImageDataForUpload(fonts: FontObjectType, file: File, isThumbnailNeeded: boolean, thumbnailSize?: number) {
		const dataToUpload = {
			fullImageData: null as string,
			imageType: null as number,
			thumbnail: null as string,
			aspectRatio: 1,
		};

		let img: HTMLImageElement;

		if (file.type === "image/svg+xml") {
			dataToUpload.imageType = ImageType.SVG;
			const minimizedSVG = `${await FileUtils.readAsText(file)}`.replace(/[\n\r\s\t]+/g, " ");
			const vectorBase64 = ImageUtils.svgText2Base64String(minimizedSVG);
			const url = URL.createObjectURL(file);

			img = await ImageUtils.loadImage(url);
			URL.revokeObjectURL(url);
			dataToUpload.fullImageData = vectorBase64;

			if (isThumbnailNeeded) {
				const considerJpeg = !ImageUtils.hasTransparentPixels(img); // if true, we create the base64 for both png and jpeg, and choose the smaller one
				const base64PNG = await ImageUtils.image2RasterBase64String(fonts, img, thumbnailSize);
				let rasterBase64 = base64PNG;

				if (considerJpeg) {
					const base64JPG = await ImageUtils.image2RasterBase64String(fonts, img, thumbnailSize, "jpeg");

					if (rasterBase64.length > base64JPG.length) {
						rasterBase64 = base64JPG;
					}
				}
				dataToUpload.thumbnail = rasterBase64.length > vectorBase64.length ? vectorBase64 : rasterBase64;
			}
		} else {
			const url = URL.createObjectURL(file);

			img = await ImageUtils.loadImage(url);
			URL.revokeObjectURL(url);
			const considerJpeg = !ImageUtils.hasTransparentPixels(img); // if true, we create the base64 for both png and jpeg, and choose the smaller one
			let toJPG = false;
			const base64PNG = await ImageUtils.image2RasterBase64String(fonts, img, Infinity, "png");

			if (considerJpeg) {
				const base64JPG = await ImageUtils.image2RasterBase64String(fonts, img, Infinity, "jpeg");

				toJPG = base64JPG.length < base64PNG.length;
				dataToUpload.fullImageData = toJPG ? base64JPG : base64PNG;
			} else {
				dataToUpload.fullImageData = base64PNG;
			}
			dataToUpload.imageType = toJPG ? ImageType.JPG : ImageType.PNG;

			if (isThumbnailNeeded) {
				dataToUpload.thumbnail = await ImageUtils.image2RasterBase64String(fonts, img, thumbnailSize, "jpeg");
			}
		}

		dataToUpload.aspectRatio = img.width / img.height;

		return dataToUpload;
	}
}
