import * as React from "react";
import {inject, observer} from "mobx-react";
import {TextInputV5} from "../../details/datatypes/TextInputV5";
import {doesStructureContainElement, type IViewFolder} from "../../../../data/models/ViewUtils";
import type {IDropdownOption} from "../../interaction/DropdownOptionsV5";
import {ConfirmWindowV5} from "../../popup/ConfirmWindowV5";
import OpenFolderIcon from "../../icons/folder-open.svg?react";
import CloseFolderIcon from "../../icons/folder-close.svg?react";
import DotsHorizontalIcon from "../../icons/dots-horizontal.svg?react";
import PlusIcon from "../../icons/circle-plus.svg?react";
import {DropdownButtonV5} from "../../interaction/DropdownButtonV5";
import {IconButtonV5} from "../../interaction/IconButtonV5";
import type {AppState} from "../../../../data/state/AppState";
import type {View} from "../../../../data/models/View";
import {navBarZIndex} from "../../styles/styles";
import ShareIcon from "../../icons/share-dark.svg?react";
import TrashIcon from "../../icons/trash.svg?react";
import RenameIcon from "../../icons/pen-field.svg?react";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import type {WorkspaceViewType} from "./WorkspaceViewCommon";
import {ViewElementStyled} from "./WorkspaceViewCommon";

export const ViewFolderClassName = "ViewFolderV5";

interface IViewFolderProps {
	readonly appState?: AppState;
	readonly onShareClick: (viewItem: View | IViewFolder) => void;
	readonly data: IViewFolder;
	readonly saveViewFolderStructureToDatabase: () => void;
	readonly removeViewElementFromStructure: (viewElementId: string) => boolean;
	readonly onAddClick?: (viewFolderId: string) => void;
	readonly type: WorkspaceViewType;
	readonly searchString: string;
}

interface IViewFolderState {
	isInEditMode: boolean;
	isViewEllipsisOpen: boolean;
}

@inject("appState")
@observer
export class ViewFolderV5 extends React.Component<IViewFolderProps, IViewFolderState> {
	constructor(props: IViewFolderProps) {
		super(props);

		this.state = {
			isInEditMode: false,
			isViewEllipsisOpen: false,
		};
	}

	private onToggleOpen = (e: React.MouseEvent<HTMLDivElement>) => {
		// Without this, the navigation panel can open up when you click on a view/folder in a popup
		e.stopPropagation();

		if (!this.state.isInEditMode) {
			this.props.data.isOpen = !this.props.data.isOpen;

			return this.props.saveViewFolderStructureToDatabase();
		}
	};

	private onDeleteClick = async () => {
		const confirmed = await ConfirmWindowV5.open("Are you sure you want to delete the selected 1 item?");

		if (confirmed) {
			const isDeletionSuccessful = this.props.removeViewElementFromStructure(this.props.data.id);

			if (isDeletionSuccessful) {
				return this.props.saveViewFolderStructureToDatabase();
			}
		}
	};

	private onRenameClick = () => {
		// Workaround for focusloss/blur problem
		requestAnimationFrame(() => {
			if (!this.state.isInEditMode) {
				this.setState({
					isInEditMode: true,
				});
			}
		});
	};

	private onRenameApply = (value: string) => {
		if (value !== this.props.data.name && value != "") {
			this.props.data.name = value;
			this.props.saveViewFolderStructureToDatabase();
		}

		this.onRenameBlur();
	};

	private onRenameBlur = () => {
		if (this.state.isInEditMode) {
			this.setState({
				isInEditMode: false,
			});
		}
	};

	private onAddClick = (event: React.MouseEvent) => {
		event.stopPropagation();
		this.props.onAddClick?.(this.props.data.id);
	};

	private onShareClick = () => {
		this.props.onShareClick(this.props.data);
	};

	private getOptions = () => {
		const options: IDropdownOption[] = [];

		options.push({
			label: "Rename",
			onClick: this.onRenameClick,
			IconComponent: RenameIcon,
		});

		const isAdmin = this.props.appState.user?.isAdmin;

		if (isAdmin && this.props.type != "Favorites") {
			options.push({
				label: "Share",
				onClick: this.onShareClick,
				IconComponent: ShareIcon,
			});
		}

		if (this.props.data.children.length === 0) {
			options.push({
				label: "Remove",
				onClick: this.onDeleteClick,
				IconComponent: TrashIcon,
			});
		}

		return options;
	};

	public override render() {
		const {searchString, data, appState} = this.props;
		const {actions} = appState;
		const doesContainSearchString = StringUtils.containsIgnoreCase(data.name, searchString);
		const doesContainSearchedViewItem = doesStructureContainElement(data.children, searchString, actions);

		if (searchString && !doesContainSearchString && !doesContainSearchedViewItem) {
			return <></>;
		}

		const isOpen = data.isOpen;
		const isOpenFinal = isOpen || (searchString && doesContainSearchedViewItem);

		return (
			<ViewElementStyled
				className={`${ViewFolderClassName} header hbox alignCenter flex_1`}
				onClick={this.onToggleOpen}
				$isActive={false}
				$isHovered={this.state.isViewEllipsisOpen}
			>
				{isOpenFinal ? <OpenFolderIcon /> : <CloseFolderIcon />}
				{this.state.isInEditMode ? (
					<TextInputV5
						className="viewFolderName flex_1"
						value={this.props.data.name}
						onChange={this.onRenameApply}
						getErrorMessage={(value) => (value ? "" : "Folder name cannot be empty!")}
						onBlur={this.onRenameBlur}
						autoFocus={true}
					/>
				) : (
					<div className="viewFolderName flex_1">{this.props.data.name}</div>
				)}
				{this.props.onAddClick && (
					<IconButtonV5
						IconComponent={PlusIcon}
						onClick={this.onAddClick}
					/>
				)}
				<DropdownButtonV5
					options={this.getOptions()}
					button={
						<IconButtonV5
							IconComponent={DotsHorizontalIcon}
							onClick={() => this.setState((prevState) => ({isViewEllipsisOpen: !prevState.isViewEllipsisOpen}))}
						/>
					}
					optionsZIndex={navBarZIndex + 1}
					onDropdownClose={() => this.setState({isViewEllipsisOpen: false})}
				/>
			</ViewElementStyled>
		);
	}
}
