import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {AppState} from "../../../../../../data/state/AppState";
import type {Type} from "../../../../../../data/models/Type";
import {FieldDataType, XyiconFeature} from "../../../../../../generated/api/base";
import {DateFormatter} from "../../../../../../utils/format/DateFormatter";
import type {TransportLayer} from "../../../../../../data/TransportLayer";
import type {Color} from "../../../../../../generated/api/base";
import {Functions} from "../../../../../../utils/function/Functions";
import {HorizontalAlignment} from "../../../../../../utils/dom/DomUtils";
import {MathUtils} from "../../../../../../utils/math/MathUtils";
import {type IRealSize} from "../../../../../modules/settings/modules/type/form/RealSizeInput";
import type {ExtendedDistanceUnitName} from "../../../../../modules/space/spaceeditor/logic3d/Constants";
import {Constants} from "../../../../../modules/space/spaceeditor/logic3d/Constants";
import {FieldV5} from "../../../../details/FieldV5";
import {ClickToEditInputStyled, ClickToEditInputV5} from "../../../../input/clicktoedit/ClickToEditInputV5";
import {RealSizeInputV5} from "../../../../abstract/view/catalog/RealSizeInputV5";
import {UnitSelectorV5} from "../../../../details/UnitSelectorV5";
import InfoIcon from "../../../../icons/info.svg?react";
import {ColorSelectorV5} from "../../../../colors/ColorSelectorV5";
import {ClickToEditDistanceV5Styled} from "../../../../input/clicktoedit/ClickToEditDistanceV5";
import {FieldElementsContainerStyled} from "../../../../details/Field.styled";
import {colorPalette} from "../../../../styles/colorPalette";
import {baseDistance, fontSize} from "../../../../styles/styles";
interface ITypeFormHeaderProps {
	readonly type: Type;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
	readonly avatar?: boolean;
	readonly createPanelNameInput?: (name: string) => void;
}

@inject("appState")
@inject("transport")
@observer
export class TypeFormHeaderV5 extends React.Component<ITypeFormHeaderProps> {
	private _timeoutId: number = null;

	private isNameValid = (name: string) => {
		const {type, appState} = this.props;

		return appState.actions.isTypeNameValid(name, type);
	};

	private getErrorMessage = (name: string) => {
		return !name.trim() ? "Name cannot be empty!" : this.isNameValid(name) ? "" : "Name needs to be unique!";
	};

	private onChangeColor = (color: Color) => {
		this.props.type.settings.color = color;
		this.updateType();
	};

	private onChangeXyiconSize = (size: IRealSize) => {
		const xyiconSize = this.props.type.settings.xyiconSize;

		if (size.unit !== xyiconSize.unit) {
			size.value = (size.value * Constants.DISTANCE_UNITS[xyiconSize.unit].multiplicator) / Constants.DISTANCE_UNITS[size.unit].multiplicator;
		}

		if (size.unit === "foot&inch") {
			size.unit = "inch";
			size.value = MathUtils.convertFeetAndInchesToInches(1, 0);
		}

		this.props.type.settings.xyiconSize = {...size};
		this.updateType();
	};

	private onChangeUnitOfMeasurement = (unit: ExtendedDistanceUnitName) => {
		this.props.type.settings.unitOfMeasure = unit;
		this.updateType();
	};

	private onChangeBoundaryHeight = (size: IRealSize) => {
		const boundarySize = this.props.type.settings.boundaryHeight;

		if (size.unit !== boundarySize.unit) {
			size.value = (size.value * Constants.DISTANCE_UNITS[boundarySize.unit].multiplicator) / Constants.DISTANCE_UNITS[size.unit].multiplicator;
		}

		this.props.type.settings.boundaryHeight = {...size};
		this.updateType();
	};

	private updateType() {
		clearTimeout(this._timeoutId);
		this._timeoutId = window.setTimeout(this.saveChanges, 500);
	}

	private saveChanges = () => {
		const {type, transport} = this.props;

		if (type.id) {
			transport.services.typefield.updateType(type);
		}
	};

	private getColorInput() {
		const {type} = this.props;
		const settings = type.settings;
		const color = settings.color || {hex: "2296f3", transparency: 0};

		return (
			<FieldV5 label="Color">
				<ColorSelectorV5
					title="Color"
					color={color}
					onColorChange={this.onChangeColor}
					isTransparencyEnabled={false}
					horizontalAlignment={HorizontalAlignment.outerRight}
				/>
			</FieldV5>
		);
	}

	private onChange = (name: string) => {
		this.props.type.name = name;
		this.saveChanges();
	};

	private renderForm() {
		const {type} = this.props;

		if ([XyiconFeature.Portfolio, XyiconFeature.Xyicon].includes(type.feature)) {
			return this.getColorInput();
		} else if (type.feature === XyiconFeature.Space) {
			const xyiconSize = type.settings.xyiconSize;

			if (xyiconSize.unit === "foot&inch") {
				xyiconSize.unit = "inch";
				xyiconSize.value = MathUtils.convertFeetAndInchesToInches(1, 0);
			}

			if (!type.settings.unitOfMeasure) {
				type.settings.unitOfMeasure = "foot&inch";
			}

			return (
				<>
					<FieldV5
						label="Unit"
						tooltips={{postLabelIconTooltip: "Select the default unit of measurement for the space type."}}
						icons={{postLabelIcon: InfoIcon}}
					>
						<UnitSelectorV5
							unit={type.settings.unitOfMeasure}
							addFeetAndInches={true}
							onChange={this.onChangeUnitOfMeasurement}
						/>
					</FieldV5>
					<RealSizeInputV5
						size={xyiconSize}
						label="Xyicon Size"
						infoText="Set the size for standard xyicons placed in a space of this type."
						onChange={this.onChangeXyiconSize}
						icons={{postLabelIcon: InfoIcon}}
					/>
				</>
			);
		} else if (type.feature === XyiconFeature.Boundary) {
			const boundaryHeight = type.settings.boundaryHeight;

			return (
				<>
					{this.getColorInput()}
					<RealSizeInputV5
						size={boundaryHeight}
						label="Height"
						onChange={this.onChangeBoundaryHeight}
					/>
				</>
			);
		} else {
			return null;
		}
	}

	public override componentDidMount(): void {
		const {type} = this.props;

		if (type.feature === XyiconFeature.Space) {
			if (!type.settings.xyiconSize.unit) {
				type.settings.xyiconSize.unit = "inch";
			}
		}
		if (type.feature === XyiconFeature.Boundary) {
			if (!type.settings.boundaryHeight.unit) {
				type.settings.boundaryHeight.unit = "foot";
			}
		}
	}

	public override render() {
		const {type, appState, avatar, createPanelNameInput} = this.props;
		const editMode = !!type.id;

		return (
			<TypeFormHeaderV5Styled>
				<FieldV5
					label="Name"
					className={!editMode && "focused"}
				>
					<ClickToEditInputV5
						value={type.name}
						onChange={this.onChange}
						onBlur={Functions.emptyFunction}
						getErrorMessage={this.getErrorMessage}
						onLiveChange={createPanelNameInput}
						dataType={FieldDataType.SingleLineText}
						// noButtons={true}
						focused={!!createPanelNameInput}
					/>
				</FieldV5>
				{this.renderForm()}
				{editMode && (
					<>
						<FieldV5 label="Updated By">{appState.actions.renderName(type.lastModifiedBy)}</FieldV5>
						<FieldV5 label="Updated Date">{DateFormatter.format(type.lastModifiedAt)}</FieldV5>
					</>
				)}
			</TypeFormHeaderV5Styled>
		);
	}
}

const TypeFormHeaderV5Styled = styled.div`
	display: flex;
	gap: ${baseDistance.sm};
	flex-direction: column;
	max-height: 272px;
	max-width: 320px;

	${FieldElementsContainerStyled} {
		.fieldIconWrapper {
			height: 16px;
			width: 16px;
			color: ${colorPalette.primary.c700Dark};
		}

		.label {
			font-size: ${fontSize.md};
			line-height: 16px;
		}

		${ClickToEditInputStyled} {
			width: 100%;
		}
	}

	${ClickToEditDistanceV5Styled} {
		display: flex;
		flex-direction: column;
		width: 100%;

		${ClickToEditInputStyled} {
			width: 100%;

			.unfocused {
				width: 100% !important;
			}
		}

		.RealSizeInputUnitSelector {
			width: 100% !important;
		}
	}
`;
