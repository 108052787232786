import styled from "styled-components";
import {baseDistance, fontSize, fontWeight, radius, zIndex} from "../../../../../styles/styles";
import {colorPalette} from "../../../../../styles/colorPalette";

export const RunReportStyled = styled.div<{$isNavBarDocked: boolean}>`
	z-index: ${zIndex.createPanel};
	top: 65px;
	bottom: 0;
	position: absolute;
	background: ${colorPalette.white};
	right: 0;
	border-radius: ${radius.md};
	left: ${(props) => (props.$isNavBarDocked ? "200px" : "65px")};

	.RunReportTable {
		height: 100%;

		.head,
		.tr {
			padding-left: 40px;
		}
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: ${baseDistance.md};

		svg {
			cursor: pointer;
			width: ${baseDistance.md};
			height: ${baseDistance.md};
		}
	}

	.headerLabel {
		display: flex;
		align-items: center;
		gap: ${baseDistance.sm};
		height: 32px;

		.headerName {
			font-size: ${fontSize.xl};
			font-weight: ${fontWeight.bold};
			line-height: 24px;
		}

		.SearchField {
			border: none;
		}

		.savebutton {
			background-color: ${colorPalette.blueGray.c500Primary};
			color: ${colorPalette.white};
		}

		.editReportButton {
			padding: 0 ${baseDistance.sm};
		}
	}
`;

export const LineStyled = styled.div`
	height: 32px;
	width: 1px;
	background-color: ${colorPalette.gray.c950};
	position: relative;
	left: 0;
	margin: ${baseDistance.sm};
`;

export const DropDownTitleStyle = styled.div`
	padding: 0px ${baseDistance.sm};
	max-width: 340px;

	svg {
		width: 14px;
		height: 14px;
		position: relative;
		top: 2px;
	}

	.downloadIcon {
		margin-right: ${baseDistance.sm};
	}
`;
