import styled from "styled-components";
import {colorPalette} from "../../../styles/colorPalette";
import {baseDistance, FLEXCENTER, radius} from "../../../styles/styles";
import {IconButtonStyled} from "../../../interaction/IconButtonV5";
import {ColorSelectorStyled} from "../../../colors/ColorSelectorV5";
import {DropdownButtonStyled} from "../../../interaction/DropdownButtonV5";
import {SelectInputStyled} from "../../../input/select/SelectInputV5";
import {TextAlignOptionsStyled} from "../../../text/TextAlignOptions.styled";

export const SpaceActionBarStyled = styled.div`
	position: absolute;
	z-index: 1;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
	background-color: ${colorPalette.gray.c950};
	border-radius: ${radius.md};
	color: ${colorPalette.white};
	${FLEXCENTER};
	padding: ${baseDistance.sm};
	gap: ${baseDistance.xs};

	${IconButtonStyled}, ${ColorSelectorStyled}, ${DropdownButtonStyled} {
		&:hover {
			background-color: ${colorPalette.gray.c700Dark};
		}
		&.active,
		&.isOpen,
		&.isActive {
			background-color: ${colorPalette.primary.c500Primary};
		}
	}

	${IconButtonStyled}, ${ColorSelectorStyled}, ${DropdownButtonStyled}, ${SelectInputStyled} {
		&:hover,
		&.isOpen {
			color: inherit;
			background-color: ${colorPalette.gray.c700Dark};
		}
		&.isActive {
			color: inherit;
			background-color: ${colorPalette.primary.c500Primary};
		}
	}

	${IconButtonStyled} {
		&.isActive {
			background-color: ${colorPalette.primary.c500Primary};
		}
	}

	${TextAlignOptionsStyled} {
		${IconButtonStyled}, ${ColorSelectorStyled}, ${DropdownButtonStyled} {
			&:hover,
			&.isOpen {
				background-color: ${colorPalette.gray.c200Light};
			}
		}

		${IconButtonStyled} {
			&.isActive {
				background-color: ${colorPalette.primary.c200Light};
				color: ${colorPalette.primary.c500Primary};
			}
		}
	}
`;
