import styled from "styled-components";
import {baseDistance, ELLIPSIS, fontSize, fontWeight, radius} from "../../styles/styles";
import {colorPalette} from "../../styles/colorPalette";

export const ColumnContainerV5Styled = styled.div<{height?: string}>`
	min-height: 180px;
	overflow-y: auto;
	position: relative;
	height: ${(props) => props.height};
`;

export const ColumnEditorStyled = styled.div`
	height: 100%;
	max-height: calc(100vh - 220px);

	.IconButton {
		svg {
			height: 24px;
			width: 24px;
		}
	}

	.dropArea {
		height: calc(100% - 20px);
		border: 3px dashed ${colorPalette.libraryColors.explosive};
		margin: ${baseDistance.sm};

		> div {
			text-align: center;
			padding: 43% 0;
			color: ${colorPalette.gray.c200Light};
		}
	}

	.SearchField {
		background: ${colorPalette.white};
		width: auto;
		border: 1px solid ${colorPalette.gray.c300};
		margin: 10px 0 0 0;
		height: 32px;

		svg {
			top: 6px;
		}
	}

	.ToggleContainer:not(.FieldSection) {
		border: none;
		padding: 10px 0 0 0;
	}

	.ToggleContainer .children.open {
		padding: 10px 0;
	}

	.column {
		cursor: pointer;
		height: 38px;
		display: flex;
		align-items: center;
		font-style: normal;
		font-weight: ${fontWeight.normal};
		font-size: ${fontSize.lg};
		line-height: 16px;
		color: ${colorPalette.gray.c950};
		position: relative;
		padding-left: 5px;
		border-radius: ${radius.sm};

		&.semiHidden {
			opacity: 0.5;
		}

		.name {
			width: 250px;
			${ELLIPSIS}
		}

		svg {
			margin-right: ${baseDistance.xs};
		}

		&:not(.disabled) {
			cursor: grab;
			position: relative;

			&:hover {
				background: ${colorPalette.gray.c200Light};
			}
			&.selected {
				background: ${colorPalette.primary.c200Light};
			}
		}

		&.disabled {
			background: ${colorPalette.white};
			pointer-events: none;
			color: ${colorPalette.libraryColors.explosive};
			border: 1px solid #f0f0f0;
		}

		// count column is a special blue column in Report
		&.count {
			background: ${colorPalette.primary.c200Light};
		}

		.selectionCount {
			position: absolute;
			color: ${colorPalette.primary.c500Primary};
			background: ${colorPalette.white};
			border-radius: 50%;
			width: 25px;
			height: 25px;
			text-align: center;
			border: 1px solid ${colorPalette.primary.c500Primary};
			font-size: 15px;
			font-weight: ${fontWeight.bold};
			line-height: 23px;
			top: -7px;
			right: -5px;
		}
	}

	.manage-cols-box {
		.flexCenter {
			justify-content: center;
			align-items: center;
		}

		.col {
			label {
				color: ${colorPalette.primary.c500Primary};
				height: 24px;
				display: flex;
			}

			.button {
				width: 40px;
				background: #f5f5f5;
				color: ${colorPalette.gray.c700Dark};

				&.disabled {
					opacity: 0.5;
				}
			}

			.FindInList {
				.button {
					width: auto;
					height: auto;
				}
			}

			.border {
				border: 1px solid ${colorPalette.gray.c300};
				min-height: 272px;
				padding: 10px;
				border-radius: ${radius.md};

				.TabView {
					.buttons {
						display: flex;
						justify-content: space-between;

						.button {
							width: 50%;
							background: none;

							&.selected {
								background: ${colorPalette.white};
								color: ${colorPalette.primary.c500Primary};
							}
						}
					}
				}

				&.linkedFields {
					.TabView {
						& > .buttons {
							display: flex;
						}
					}
				}

				.ToggleContainer {
					margin-bottom: 0;

					&.prohibited {
						background: #ffb4b4;
					}

					.filter-title {
						margin: 0;
						background: none;
						padding: 0;
						color: ${colorPalette.gray.c950};
						font-size: ${fontSize.xl};
						font-weight: ${fontWeight.bold};
						text-transform: none;
						display: flex;
						align-items: center;
					}

					.children.open {
						padding: 0;
						margin-bottom: 0px;
					}
				}
			}
		}
	}

	.border {
		user-select: none;

		.TabView {
			height: 100%;

			.views {
				height: 100%;

				.DetailsTab {
					overflow: auto;
				}
			}
		}
	}
`;
