import {ReactSortable} from "react-sortablejs";
import type {Sortable} from "react-sortablejs";
import {useRef, useState} from "react";
import {SVGIcon} from "../../button/SVGIcon";
import {TextInput} from "../text/TextInput";
import {Button} from "../../button/Button";
import {ToggleSwitchField} from "../../button/switch/ToggleSwitchField";
import {ArrayUtils} from "../../../../utils/data/array/ArrayUtils";
import {IconButton} from "../../button/IconButton";
import {ReactUtils} from "../../../utils/ReactUtils";
import {InfoBubble} from "../../../modules/abstract/common/infobutton/InfoBubble";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import type {ListObject} from "../../../../data/models/field/datatypes/SingleSelect";

interface IListBuilderInputProps {
	readonly list: ListObject[];
	readonly onChange: (list: ListObject[]) => void;
}

interface IListBuilderInputSortableList {
	id: string;
	value: string;
	color: string;
}

const dragTolerance = 4;
const defaultItemString = "New Item";

const generateSortableListFromSourceList = (source: ListObject[]): IListBuilderInputSortableList[] => {
	return source.map((item, index) => ({id: `${item.value}-${index}`, value: item.value, color: item.color}));
};

const createNewItemName = (count: number) => `${defaultItemString} (${count})`;

function filterDragElement(this: Sortable, event: Event | TouchEvent, target: HTMLElement, sortable: Sortable): boolean {
	const element = event.target as HTMLElement;
	const elementName = element.nodeName.toLowerCase();

	return elementName === "input" || elementName === "textarea";
}

export const ListBuilderInput = (props: IListBuilderInputProps) => {
	const {list, onChange} = props;
	const [isUsingTextArea, setUsingTextArea] = useState<boolean>(false);
	const [textArea, setTextArea] = useState<string>(list.join("\n"));
	const [editedItem, setEditedItem] = useState<{index: number; value: string}>({index: -1, value: ""});
	const _listRef = useRef<HTMLDivElement>(null);

	const getTextAreaValues = (listData: ListObject[]) => {
		return listData.map((item) => item.value).join("\n");
	};

	const onAddItemClick = async () => {
		let count: number = 1;
		let newItemName = createNewItemName(count);

		while (getTextAreaValues(list).includes(newItemName)) {
			newItemName = createNewItemName(++count);
		}

		const cleanedList = list.map((item) => ({id: item.id, value: item.value.trim(), color: item.color}));
		onChange([...cleanedList, {id: `${newItemName}-${count}`, value: newItemName, color: ""}]);

		await TimeUtils.waitForNextFrame();
		await TimeUtils.waitForNextFrame();

		// Number.MAX_SAFE_INTEGER is not working reliably in Safari and Firefox
		_listRef.current.scrollTo({top: _listRef.current.scrollHeight, behavior: "smooth"});
	};

	const isCurrentListValid = (currentList: ListObject[] = props.list) => {
		let currList: string[] = currentList.map((item) => item.value);

		if (isUsingTextArea) {
			currList = textArea
				.split("\n") // Split the text into lines
				.filter((line) => !!line) // Filter out empty lines
				.map((line) => {
					const match = line.match(/^(.+?)\s*#(?:[0-9A-Fa-f]{6})?/); // Match the part before the `#` and the optional hex color code
					return match ? match[1].trim() : line.trim(); // Return the left part if a match is found, otherwise return the original line
				});
		} else {
			currList = ObjectUtils.deepClone(currList);
			if (editedItem.index !== -1) {
				const {value} = editedItem;

				currList[editedItem.index] = value.trim();
				if (!value) {
					return false;
				}
			}
		}

		const caseInsensitiveList = currList.map((el) => el.toLowerCase());

		const s = new Set<string>(caseInsensitiveList);
		return s.size === caseInsensitiveList.length;
	};

	const onEditItem = (value: string, index: number) => {
		setEditedItem({value: value.trim(), index});
	};

	const onBlurTextArea = () => {
		const colorsAndValues = textArea
			.split("\n")
			.filter((line) => !!line)
			.map((line) => {
				const match = line.split("#");
				return match.length > 1 ? {value: match[0]?.trim(), color: match[1] ? match[1].trim() : ""} : {value: match[0]?.trim(), color: ""};
			});

		const newListValues = colorsAndValues.map((item) => item.value);
		if (isCurrentListValid() && !ObjectUtils.compare(newListValues, list)) {
			onChange(colorsAndValues);
		} else {
			setTextArea(list.map((item) => `${item.value} ${item.color ? `\t#${item.color}` : ""}`).join("\n"));
		}
	};

	const onItemUpdate = (itemValue: string, color: string, newValue: string) => {
		if (isCurrentListValid()) {
			const newList = list.map((item) => (item.value === itemValue ? {color: color, value: newValue.trim()} : item));
			if (!ObjectUtils.compare(newList, list) && isCurrentListValid(newList)) {
				onChange(newList);
			}
		}
	};

	const onDeleteClick = (index: number) => {
		if (index < list.length) {
			onChange(ArrayUtils.removeAtIndex(list, index));
		}
	};

	const onSwitchChange = () => {
		if (!isUsingTextArea) {
			setTextArea(list.map((item) => `${item.value} ${item.color ? `\t#${item.color}` : ""}`).join("\n"));
		}
		setUsingTextArea(!isUsingTextArea);
		setEditedItem({index: -1, value: ""});
	};

	const onEditTextArea = (value: string) => {
		setTextArea(value.trim());
		const newList = value.split("\n").filter((line) => !!line);
		if (newList.length === 0) {
			setEditedItem({index: -1, value: ""});
		} else {
			setEditedItem({index: 0, value});
		}
	};

	const onSetList = (newList: IListBuilderInputSortableList[]) => {
		// React sortable will add extra fields to the array that we don't need.
		// We need to remove them before comparing the new list to the old list.

		const updatedList = newList.map((item) => {
			const {value, color} = item;
			return {value, color};
		});

		if (!ObjectUtils.compare(updatedList, list)) {
			onChange(updatedList);
		}
	};

	const listToRender = generateSortableListFromSourceList(list);
	const isValid = isCurrentListValid();
	return (
		<div className="ListBuilderInputWrapper">
			{!isValid && (
				<div className="infoIcon">
					<SVGIcon icon="info" />
					<InfoBubble
						content={editedItem.value === "" ? "Item name cannot be empty" : "Items must be unique"}
						isErrorMessage={true}
						className="left"
					/>
				</div>
			)}
			<div
				className={ReactUtils.cls("ListBuilderInput", {error: !isValid})}
				ref={_listRef}
			>
				{isUsingTextArea ? (
					<TextInput
						isTextArea={true}
						value={textArea}
						onInput={onEditTextArea}
						onBlur={onBlurTextArea}
					/>
				) : (
					<ReactSortable
						list={listToRender}
						setList={onSetList}
						animation={150}
						filter={filterDragElement}
						fallbackOnBody={true}
						forceFallback={true}
						swapThreshold={0.45}
						fallbackTolerance={dragTolerance}
						touchStartThreshold={dragTolerance}
						preventOnFilter={false}
						delay={500}
						delayOnTouchOnly={true}
					>
						{listToRender.map((item, index) => {
							const {color, value} = item;
							const onItemChange = (newValue: string) => onItemUpdate(value, color, newValue);

							return (
								<div
									className="item hbox grabbable"
									key={item.id}
								>
									<TextInput
										value={editedItem.index === index ? editedItem.value : item.value}
										onInput={(value) => onEditItem(value, index)}
										onChange={onItemChange}
										onBlur={() => {
											if (editedItem.index === index) {
												if (isValid) {
													onItemChange(editedItem.value);
												}
												setEditedItem({index: -1, value: ""});
											}
										}}
									/>
									<IconButton
										className="delete"
										icon="delete"
										title="Delete"
										onClick={() => onDeleteClick(index)}
									/>
								</div>
							);
						})}
					</ReactSortable>
				)}
			</div>
			<div className="hbox add-item alignCenter">
				{!isUsingTextArea && (
					<Button
						label="Add Item"
						className="small"
						onClick={onAddItemClick}
					/>
				)}
				<div className="flex_1" />
				<ToggleSwitchField
					value={isUsingTextArea}
					onChange={onSwitchChange}
					label="Add multiple items at once"
				/>
			</div>
		</div>
	);
};
