import {Object3D} from "three";

export class CSS3DObject extends Object3D {
	private _element: HTMLElement | SVGElement;

	constructor(element: HTMLElement | SVGElement) {
		super();

		this._element = element;
		this._element.style.position = "absolute";
		this._element.style.pointerEvents = "auto";
	}

	public get element() {
		return this._element;
	}
}
