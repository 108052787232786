import styled from "styled-components";
import {FLEXCENTER, zIndex} from "../styles/styles";

export const PopupBackdropV5 = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: ${zIndex.popup};
	background-color: rgba(0, 0, 0, 0.65);
	${FLEXCENTER};
`;
