import styled from "styled-components";
import {Responsive, WidthProvider} from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./HomeDashboard.css";
import {baseDistance, FLEXCENTER, fontWeight, radius} from "../../styles/styles";
import {useAppStore} from "../../../../StateManager";
import {AvatarStyled} from "../../user/Avatar.styled";
import {DateUtils} from "../../../../utils/DateUtils";
import {colorPalette} from "../../styles/colorPalette";
import type {Dashboard} from "../../../../data/models/Dashboard";
import type {WidgetDto} from "../../../../generated/api/base";
import {WidgetType, XyiconFeature} from "../../../../generated/api/base";
import type {AppState} from "../../../../data/state/AppState";
import {PortfolioList} from "./PortfolioList";
import {LinksWidget} from "./LinksWidget";

const ResponsiveGridLayout = WidthProvider(Responsive);

type ResizeHandle = "s" | "w" | "e" | "n" | "sw" | "nw" | "se" | "ne";
const availableHandles: ResizeHandle[] = ["se"];

interface IWidgetConfiguration {
	draggable?: boolean;
}

interface IDatasourceConfiguration {
	filters: {
		field: string;
		value: string;
		operator: string;
	}[];
	staticData: {
		caption: string;
		url: string;
		tooltip: string;
	}[];
}

const exampleDatasourceConfig: IDatasourceConfiguration = {
	filters: [{field: "Name", value: "bal", operator: "eq"}],
	staticData: [
		{caption: "FAQ", url: "http://xyicon.com/faq", tooltip: "This is FAQ"},
		{caption: "Helpful Articles", url: "http://xyicon.com/articles", tooltip: "This is Helpful Articles"},
		{caption: "Chat with Support", url: "http://xyicon.com/support", tooltip: "This is to Chat with Support"},
		{caption: "Keyboard Shortcuts", url: "http://xyicon.com/shortcuts", tooltip: "This is Keyboard Shortcuts"},
		{caption: "Report a Problem", url: "http://xyicon.com/problem", tooltip: "This is to Report a Problem"},
		{caption: "Request a Feature", url: "http://xyicon.com/articles", tooltip: "This is to Request a Feature"},
	],
};

const renderWidgetContent = (widget: WidgetDto, appState: AppState) => {
	const user = appState.user;
	const initials = user ? appState.actions.renderInitials(user.id) : "";

	switch (widget.widgetType) {
		case WidgetType.Header:
			return (
				<WelcomeBackStyled>
					<WelcomeTextWrapper>
						<AvatarStyled>{initials}</AvatarStyled>
						<WelcomeTextStyled>Welcome back, {appState.user?.firstName || ""}!</WelcomeTextStyled>
					</WelcomeTextWrapper>
					<DateWrapper>{DateUtils.todayUSFormat()}</DateWrapper>
				</WelcomeBackStyled>
			);
		case WidgetType.PortfolioList:
			return <PortfolioList />;
		case WidgetType.Link:
			if (widget.datasourceConfiguration) {
				const datasourceConfig: IDatasourceConfiguration = JSON.parse(widget.datasourceConfiguration);
				const staticData = datasourceConfig.staticData;

				return (
					<LinksWidget
						title={widget.title ?? ""}
						links={staticData}
					/>
				);
			} else {
				console.warn("Link Widget has an invalid `datasourceConfig`");

				return <></>;
			}

		case WidgetType.PieChart:
			return <></>;
		case null:
			return widget.title ?? "";
	}
};

const getDefaultWidgets = (): WidgetDto[] => {
	return [
		{
			width: 12,
			height: 1.5,
			column: 0,
			row: 0,
			title: "Welcome",
			widgetType: WidgetType.Header,
		},
		{
			width: 3,
			height: 3.5,
			column: 0,
			row: 2,
			title: "Portfolios",
			widgetType: WidgetType.PortfolioList,
		},
		{
			width: 3,
			height: 3.5,
			column: 3,
			row: 2,
			title: "3",
			widgetType: null,
		},
		{
			width: 3,
			height: 2,
			column: 6,
			row: 2,
			title: "4",
			widgetType: null,
		},
		{
			width: 3,
			height: 1.5,
			column: 6,
			row: 4,
			title: "5",
			widgetType: null,
		},
		{
			width: 3,
			height: 2,
			column: 9,
			row: 2,
			title: "6",
			widgetType: null,
		},
		{
			width: 1.5,
			height: 1.5,
			column: 9,
			row: 4,
			title: "Need Help?",
			widgetType: WidgetType.Link,
			datasourceConfiguration: JSON.stringify(exampleDatasourceConfig),
		},
		{
			width: 1.5,
			height: 1.5,
			column: 10.5,
			row: 4,
			title: "7",
			widgetType: WidgetType.PieChart,
		},
	];
};

const getWidgetsToRender = (dashboardMaybe: Dashboard | null): WidgetDto[] => {
	const widgets: WidgetDto[] = [];
	if (dashboardMaybe?.widgets?.length > 0) {
		widgets.push(...dashboardMaybe.widgets);
	} else {
		widgets.push(...getDefaultWidgets());
	}

	return widgets;
};

export const HomeDashboard = () => {
	const appState = useAppStore((state) => state.appState);
	const dashboards = appState.actions.getList<Dashboard>(XyiconFeature.Dashboard);
	const widgets = getWidgetsToRender(dashboards[0]);

	console.log(widgets);

	return (
		<HomeDashboardStyled>
			<ResponsiveGridLayout
				className="react-grid-layout"
				breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
				cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
				useCSSTransforms={true}
				isDraggable={true}
				compactType="vertical"
				resizeHandles={availableHandles}
			>
				{widgets.map((widget: WidgetDto, index: number) => {
					const widgetConfiguration: IWidgetConfiguration = JSON.parse(widget.configuration ?? `{"draggable": false}`);

					return (
						<TileStyled
							className="react-grid-item"
							key={widget.widgetID ?? index}
							data-grid={{w: widget.width, h: widget.height, x: widget.column, y: widget.row, static: !widgetConfiguration.draggable}}
						>
							{renderWidgetContent(widget, appState)}
						</TileStyled>
					);
				})}
			</ResponsiveGridLayout>
		</HomeDashboardStyled>
	);
};

const DateWrapper = styled.div`
	font-size: 24px;
	line-height: 32px;
	font-weight: ${fontWeight.bold};
`;

const WelcomeTextWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: ${baseDistance.md};

	${AvatarStyled} {
		width: 48px;
		height: 48px;
		font-size: 24px;
		line-height: 32px;
		font-weight: ${fontWeight.normal};
	}
`;

const WelcomeTextStyled = styled.div`
	font-size: 40px;
	line-height: 48px;
	font-weight: ${fontWeight.bold};
`;

const WelcomeBackStyled = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1;
	padding: ${baseDistance.md};
	background-color: ${colorPalette.primary.c500Primary};
	height: 100%;
	border-radius: ${radius.xl};
	color: ${colorPalette.white};
`;

const HomeDashboardStyled = styled.div`
	width: 100%;
	height: 100%;
`;

const TileStyled = styled.div`
	cursor: pointer;
	background-color: ${colorPalette.white};
	${FLEXCENTER};
	box-shadow: 0px 4px 20px 0px #00000033;
	border-radius: ${radius.xl};
	user-select: none;

	&.resizing {
		opacity: 0.9;
	}
`;
