import type {TransportLayer} from "../TransportLayer";

export class PermissionService {
	private readonly _transportLayer: TransportLayer;
	private _list: IPermission[];

	constructor(transportLayer: TransportLayer) {
		this._transportLayer = transportLayer;
		this._list = []; //localStorage
	}

	public async getPermissions() {
		const {result: permissions} = await this._transportLayer.request<IPermissionsResponse>({
			url: "token/permissions",
		});

		if (permissions) {
			this._list = permissions.Permissions;
		} else {
			console.error("Couldn't get permissions!");
		}
	}

	public hasPermission(module: string, permission: string) {
		let hasPermission = false;
		let hasModule = false;

		const list = this._list;

		if (permission) {
			const permArray = permission.split(",");

			for (let b = 0; b < permArray.length; b++) {
				for (let i = 0; i < list.length; i++) {
					if (module.toLowerCase() === list[i].Module.toLowerCase()) {
						hasModule = true;
						if (permArray[b].toLowerCase() === list[i].Permission.toLowerCase()) {
							//console.log("the user has the permission:" + list[i].Permission + " - On module:" + attrs.authorize);
							hasPermission = true;
						}
					}
				}
			}
		} else {
			for (let c = 0; c < list.length; c++) {
				if (module.toLowerCase() === list[c].Module.toLowerCase()) {
					hasModule = true;
					hasPermission = true;
				}
			}
		}

		return hasPermission && hasModule;
	}
}

interface IPermissionsResponse {
	Permissions: IPermission[];
}

interface IPermission {
	Module: string;
	Permission: string;
}
