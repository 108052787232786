export class FileUtils {
	public static file2Base64(file: File) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.readAsDataURL(file);
			reader.onload = () => {
				const data = reader.result;

				resolve(data);
			};
			reader.onerror = (error) => {
				console.warn(error);
				reject(error);
			};
		});
	}

	public static dataURL2File(dataurl: string, filename: string) {
		const arr = dataurl.split(",");
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[arr.length - 1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, {type: mime});
	}

	public static arrayBuffer2Base64(arrayBuffer: ArrayBuffer): string {
		let binary = "";
		const bytes = new Uint8Array(arrayBuffer);
		const len = bytes.byteLength;

		for (let i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}

		return btoa(binary);
	}

	public static base64ToArrayBuffer(base64: string): ArrayBufferLike {
		// Remove the data URL scheme part (e.g., "data:image/webp;base64,")
		const base64SplitArray = base64.split(",");
		const base64Final = base64SplitArray.length > 1 ? base64SplitArray[1] : base64;

		const binaryString = atob(base64Final);
		const bytes = new Uint8Array(binaryString.length);

		for (let i = 0; i < binaryString.length; ++i) {
			bytes[i] = binaryString.charCodeAt(i);
		}

		return bytes.buffer;
	}

	/**
	 *
	 * @param arrayBuffer
	 * @param type "image/webp", "image/jpeg", "text/plain"
	 * @returns Blob
	 */
	public static arrayBufferToBlob(arrayBuffer: ArrayBufferLike, type: string): Blob {
		return new Blob([arrayBuffer], {type});
	}

	/**
	 *
	 * @param base64String
	 * @param type "image/webp", "image/jpeg", "text/plain"
	 * @returns Blob
	 */
	public static base64ToBlob(base64String: string, type: string): Blob {
		return this.arrayBufferToBlob(this.base64ToArrayBuffer(base64String), type);
	}

	public static async createFileFromURL(path: string, type: string, name: string): Promise<File> {
		const response = await fetch(path);
		const data = await response.blob();

		const metaData = {
			type,
		};

		return new File([data], name, metaData);
	}

	public static async fetchFileAsArrayBuffer(url: string): Promise<ArrayBuffer> {
		const response = await fetch(url);

		return response.arrayBuffer();
	}

	public static canvasToBlob(canvas: HTMLCanvasElement) {
		return new Promise<Blob>((resolve, reject) => {
			canvas.toBlob((blob: Blob) => {
				resolve(blob);
			});
		});
	}

	public static async downloadIntoDataURL(url: string) {
		const response = await fetch(url);
		const blob = await response.blob();

		return URL.createObjectURL(blob);
	}

	public static async createURLFromFile(file: File) {
		try {
			const arrayBuffer = await this.readAsArrayBuffer(file);

			return this.createURLFromData(arrayBuffer);
		} catch (error) {
			console.warn("error ", error);
		}
	}

	public static createURLFromData(content: string | Uint8Array | Buffer | ArrayBuffer, type: string = "text/plain") {
		// This is slow for big text -> we use Blob instead.
		//var url = "data:text/plain;charset=utf-8," + encodeURIComponent(content);

		const blob = new Blob([content], {type: type});
		return URL.createObjectURL(blob);
	}

	public static async downloadFileGivenByData(content: string | Uint8Array | Buffer, defaultFileName: string, type: string = "text/plain") {
		try {
			const url = FileUtils.createURLFromData(content, type);

			await FileUtils.downloadFileFromUrl(url, defaultFileName);
			URL.revokeObjectURL(url);
		} catch (error) {
			console.warn("error ", error);
		}
	}

	public static async downloadFileFromUrl(url: string, defaultFileName: string) {
		const a = document.createElement("a");

		// Need to convert images/txt into dataURL to force download images as opposed to just open them.
		a.href = await this.downloadIntoDataURL(url);

		// This doesn't seem to be working, filename is always render.png
		a.setAttribute("download", defaultFileName);
		a.download = defaultFileName;

		a.style.display = "none";
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	/**
	 * This should be called from a user generated event handler to work (eg. "click").
	 */
	public static openFileDialogue(
		multiple = false,
		acceptedFormats: string[] = null,
		onComplete: (fileList: FileList) => void,
		onCancel?: () => void,
	) {
		const input = document.createElement("input");

		input.type = "file";

		// Add to dom but don't show it (it's not needed to add to dom but
		// it maybe helps the change event to fire more reliably (sometimes it fails)
		input.style.display = "none";
		document.body.appendChild(input);

		if (multiple) {
			input.multiple = true;
		}

		if (acceptedFormats) {
			input.accept = acceptedFormats.join(",");
		}

		input.onchange = (event: Event) => {
			input.parentNode.removeChild(input);
			input.onchange = null;

			onComplete(input.files);
		};

		input.addEventListener("cancel", () => {
			onCancel?.();
		});

		input.click();
	}

	public static readAsArrayBuffer(file: File) {
		return new Promise<ArrayBuffer>((resolve, reject) => {
			const fileReader = new FileReader();

			fileReader.onload = () => {
				resolve(fileReader.result as ArrayBuffer);
			};
			fileReader.onerror = () => {
				reject("Error");
			};
			fileReader.readAsArrayBuffer(file);
		});
	}

	public static readAsText(file: File) {
		return new Promise<string>((resolve, reject) => {
			const fileReader = new FileReader();

			fileReader.onload = () => {
				resolve(fileReader.result as string);
			};
			fileReader.onerror = () => {
				reject("Error");
			};
			fileReader.readAsText(file);
		});
	}

	public static readAsImage(file: File) {
		return new Promise<HTMLImageElement>((resolve, reject) => {
			const img = new Image();

			img.onload = () => {
				resolve(img);
			};

			img.src = URL.createObjectURL(file);
		});
	}

	public static getValueFromTextByKey(text: string, key: string) {
		const indexOfKey = text.indexOf(key);

		if (indexOfKey > -1) {
			const indexOfNextQuoteSymbol = text.indexOf(`"`, indexOfKey + key.length + 3);

			return text.substring(key.length + indexOfKey + 2, indexOfNextQuoteSymbol);
		} else {
			return null;
		}
	}

	public static isVideo(file: File) {
		return this.matches(file, /video.*/);
	}

	public static isImageFile(file: File) {
		return this.matches(file, /image.*/);
	}

	public static matches(file: File, type: string | RegExp) {
		const fileType = file.type || this.getExtension(file.name);

		return !fileType || fileType.match(type);
	}

	public static iterate(files: FileList, iterator: (file: File, index: number) => void) {
		for (let i = 0; i < files.length; ++i) {
			iterator(files[i], i);
		}
	}

	public static decomposeFileName(fileName: string) {
		let name = fileName;
		let extension = "";

		try {
			fileName = fileName || "";
			const parts = fileName.split(".");

			const lastPart = parts.pop().toLowerCase();

			// Remove query string and hashtag parts if present in url, eg.: ?t=3#hash
			extension = lastPart.split(/[#?]/)[0];

			name = parts.join(".");
		} catch (e) {
			console.warn(e);
		}

		return {name, extension};
	}

	public static getExtension(fileName: string): string {
		return this.decomposeFileName(fileName).extension;
	}

	public static getSizeLabelOfFile(file: File): string {
		return this.getSizeLabel(file.size);
	}

	public static getSizeLabel(numberOfBytes: number): string {
		return `${(numberOfBytes / 1024).toFixed(1)} kB (${(numberOfBytes / 1024 / 1024).toFixed(1)} MB)`;
	}

	public static async getFileSize(url: string): Promise<string> {
		const res = await fetch(url, {method: "HEAD"});
		const bytes = parseInt(res.headers.get("content-length"));

		return this.getSizeLabel(bytes);
	}
}
