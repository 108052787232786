import * as React from "react";
import {inject, observer} from "mobx-react";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import type {IFieldAdapter, IFieldPointer} from "../../../../data/models/field/Field";
import type {IModel} from "../../../../data/models/Model";
import {FieldDataType, XyiconFeature} from "../../../../generated/api/base";
import {
	getTooltipByIcon,
	type FieldIconName,
	getIconComponentFromFieldIconName,
} from "../../../modules/abstract/sidepanel/tabs/details/field/FieldInputUtils";
import type {AppState} from "../../../../data/state/AppState";
import type {TransportLayer} from "../../../../data/TransportLayer";
import {TimeUtils} from "../../../../utils/TimeUtils";
import type {IFieldPropagation, IXyiconLinkObject} from "../../../../data/state/AppActions";
import type {Link} from "../../../../data/models/Link";
import type {Boundary} from "../../../../data/models/Boundary";
import {IconButtonV5} from "../../interaction/IconButtonV5";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";
import {CardLayoutToolTip} from "../../../modules/space/spaceeditor/ui/toolbar/CardLayoutToolTip";
import {XHRLoader} from "../../../../utils/loader/XHRLoader";
import type {Xyicon} from "../../../../data/models/Xyicon";
import InfoIcon from "../../icons/circle-information.svg?react";
import UnlinkIcon from "../../icons/unlink.svg?react";
import PinIcon from "../../icons/location-pin.svg?react";
import {FieldV5} from "../FieldV5";
import {FieldInputV5} from "./FieldInputV5";
import {PropagatedFieldInDetailsTab} from "./SingleFieldInput.styled";

interface ISingleFieldInputState {
	isToolTipOpen: boolean;
	toolTipTransform: TransformObj;
	itemIndex: number;
}

interface ISingleFieldInputProps<T extends IModel> {
	readonly item: T;
	readonly field: IFieldAdapter;
	readonly fieldRefIdsForType: IFieldPointer[];
	readonly noWrap: boolean;
	readonly feature: XyiconFeature;
	readonly disabled?: boolean;
	readonly icon?: FieldIconName;
	readonly className?: string;
	readonly isXyiconXyiconLink?: boolean;
	readonly isInheritedFromBoundary?: boolean;
	readonly onHoverPropagatedValue: (model: IModel) => void;
	readonly onSelectPropagatedSource: (model: IModel) => void;
	readonly closeWideSearchPanel?: () => void;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class SingleFieldInputV5<T extends IModel> extends React.Component<ISingleFieldInputProps<T>, ISingleFieldInputState> {
	private _fieldInputRef = React.createRef<FieldInputV5>();

	private _cardLayoutToolTipRef = React.createRef<HTMLDivElement>();
	private parentRefArray: HTMLDivElement[] = [];

	constructor(props: ISingleFieldInputProps<T>) {
		super(props);
		this.state = {
			isToolTipOpen: false,
			toolTipTransform: null,
			itemIndex: -1,
		};
	}

	private onInputChange = async (value: string | number) => {
		const {item, field} = this.props;
		const {actions} = this.props.appState;

		await TimeUtils.waitUpdate(
			actions.updateFields([item], {
				[field.refId]: value,
			}),
			this.props.appState.app.notificationContainer,
		);
	};

	private getLink(propagation: IFieldPropagation) {
		let links: ({link: Link; object: Boundary} | IXyiconLinkObject)[] = [];

		if (propagation.model.ownFeature === XyiconFeature.Xyicon && this.props.item.ownFeature === XyiconFeature.Xyicon) {
			links = this.props.appState.actions.getLinksXyiconXyicon(this.props.item.id);
		} else if (propagation.model.ownFeature === XyiconFeature.Boundary && this.props.item.ownFeature === XyiconFeature.Boundary) {
			links = this.props.appState.actions.getLinksBoundaryBoundary(this.props.item.id);
		} else {
			links = this.props.appState.actions.getLinksXyiconBoundary(this.props.item.id);
		}

		return links.find((l) => l.object.refId === propagation.model.refId);
	}

	private onDetailsClick = (propagation: IFieldPropagation) => {
		this.props.appState.app.onDetailsClick?.(propagation.model);
	};

	private onPinClick = (propagation: IFieldPropagation) => {
		const link = this.getLink(propagation);

		this.props.appState.actions.navigateToSpaceItem(link.object, true);
		this.props.closeWideSearchPanel?.();
	};

	private onBreakLinkClick = async (propagation: IFieldPropagation) => {
		const {transport} = this.props;
		const link = this.getLink(propagation);

		await transport.requestForOrganization({
			url: "xyicons/deletelink",
			method: XHRLoader.METHOD_DELETE,
			params: {
				portfolioID: transport.appState.portfolioId,
				linkIDList: [link.link.id],
			},
		});
	};

	private onMouseLeave = () => {
		this.setState({itemIndex: -1, isToolTipOpen: false});
	};

	public override UNSAFE_componentWillReceiveProps(nextProps: ISingleFieldInputProps<T>) {
		if (nextProps.item !== this.props.item) {
			// TODO V5
			// const clickToEditRef = this._fieldInputRef.current?.clickToEditRef.current;
			// const editingValue = clickToEditRef?.editingValue;
			// if (clickToEditRef?.state.editing)
			// {
			// 	this.onInputChange?.(editingValue);
			// 	clickToEditRef?.cancelLocalEdit();
			// }
		}
	}

	public override componentDidUpdate(prevProps: ISingleFieldInputProps<T>, prevState: ISingleFieldInputState) {
		if (!prevState.isToolTipOpen && this.state.isToolTipOpen && this.parentRefArray[this.state.itemIndex] && this._cardLayoutToolTipRef.current) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this.parentRefArray[this.state.itemIndex],
					this._cardLayoutToolTipRef.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
				),
			});
		}
	}

	public override render() {
		const {
			item,
			field,
			fieldRefIdsForType,
			noWrap,
			feature,
			appState,
			disabled,
			icon,
			className,
			onHoverPropagatedValue,
			onSelectPropagatedSource,
			isInheritedFromBoundary,
			isXyiconXyiconLink,
		} = this.props;
		const {isToolTipOpen, toolTipTransform, itemIndex} = this.state;
		const actions = appState.actions;

		const value = actions.getOwnFieldValue(item, field.refId);

		const isUpdating =
			appState.itemFieldUpdateManager.isItemFieldBeingUpdated(item.id, field.refId, feature) && field.dataType !== FieldDataType.MultipleChoiceList;

		// Default fields and inherited fields are not editable
		const shouldBeDisabled = disabled || field.feature !== feature || field.default || field.hasFormula;
		const propagations = actions.getFieldPropagations(item, field);

		// Show input if feature is the same and type mapping matches or default
		// Note is there a way showInput will become false here?
		// Note for the above question: if the Input field is an inherited DOL field

		const showOwnInput =
			field.feature === feature && !actions.isFieldHiddenByMasking(item, field) && (fieldRefIdsForType.includes(field.refId) || field.default);

		const inlineStyle: React.CSSProperties = this._cardLayoutToolTipRef && {
			position: "absolute",
			top: 0,
			left: 0,
			transform: toolTipTransform?.translate,
		};

		return (
			<FieldV5
				key={`${field.refId}${item.id}`}
				label={field.name}
				disabled={shouldBeDisabled || isUpdating}
				noWrap={noWrap}
				className={`${className} ${icon}` || ""}
				icons={{preLabelIcon: getIconComponentFromFieldIconName(icon)}}
				tooltips={{
					labelTooltip: field.helperText,
					preLabelIconTooltip: getTooltipByIcon(icon),
				}}
			>
				<>
					{/* //Please do not delete it, it is for debugging. It shows the field's refid. */}
					{/* <div style={{position: "absolute", top: "-3px", left: "-30px", fontSize: "10px", fontWeight: 700}}>{this.props.field.refId}, {shouldBeDisabled ? "⛔" : "✔"}</div> */}
					{showOwnInput && (
						<FieldInputV5
							refId={shouldBeDisabled ? undefined : field.refId}
							ref={this._fieldInputRef}
							dataType={field.dataType}
							dataTypeSettings={field.dataTypeSettings}
							value={value}
							onChange={this.onInputChange}
							disabled={shouldBeDisabled}
							hasValidation={field.hasValidation}
							updating={isUpdating}
							noButtons={true}
							dropdownIcon={false}
						/>
					)}
					{propagations?.map((propagation, index) => (
						<PropagatedFieldInDetailsTab
							key={`${field.fieldID || field.refId} ${index}`}
							ref={(parentRef) => (this.parentRefArray[index] = parentRef)}
							onMouseOver={() => this.setState({itemIndex: index, isToolTipOpen: true})}
							onMouseLeave={this.onMouseLeave}
							$onlyFieldValue={!showOwnInput && index === 0}
						>
							<FieldInputV5
								refId={field.fieldID || field.refId}
								dataType={field.dataType}
								dataTypeSettings={field.dataTypeSettings}
								value={propagation.value}
								disabled={true}
								onClick={() => {
									onSelectPropagatedSource(propagation.model);
								}}
								onHover={(over = true) => {
									const modelMaybe: T = over ? (propagation.model as T) : null;

									onHoverPropagatedValue(modelMaybe);
								}}
								dropdownIcon={false}
							/>
							{icon && itemIndex === index && (
								<div className="buttonContainer hbox alignCenter">
									{isXyiconXyiconLink && (
										<IconButtonV5
											IconComponent={UnlinkIcon}
											title="Break Link"
											onClick={() => this.onBreakLinkClick(propagation)}
										/>
									)}
									{(isXyiconXyiconLink || isInheritedFromBoundary) && (
										<IconButtonV5
											IconComponent={PinIcon}
											title="Open in Space Editor"
											onClick={() => this.onPinClick(propagation)}
											disabled={(propagation.model as Xyicon).isUnplotted}
										/>
									)}
									<IconButtonV5
										IconComponent={InfoIcon}
										title="Details"
										onClick={() => this.onDetailsClick(propagation)}
									/>
								</div>
							)}
							{isToolTipOpen && itemIndex === index && (
								<DomPortal destination={this.props.appState.app.modalContainer}>
									<CardLayoutToolTip
										item={propagation.model as Xyicon}
										divRef={this._cardLayoutToolTipRef}
										style={inlineStyle}
									/>
								</DomPortal>
							)}
						</PropagatedFieldInDetailsTab>
					))}
				</>
			</FieldV5>
		);
	}
}
