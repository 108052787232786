import type {Intersection, Mesh, Object3D, Object3DEventMap, Vector3} from "three";
import {Vector2, LineSegments, OrthographicCamera, InstancedMesh, Raycaster} from "three";
import type {BoundarySpaceMap3D} from "../../elements3d/BoundarySpaceMap3D";
import {MarkupTextRotationName, type Markup3D} from "../../elements3d/markups/abstract/Markup3D";
import type {Xyicon3D} from "../../elements3d/Xyicon3D";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import type {ItemSelectionBox} from "../../elements3d/ItemSelectionBox";
import {ItemSelectionBoxName} from "../../elements3d/ItemSelectionBox";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import type {ILineSegment} from "../../../../../../../utils/THREEUtils";
import {MathUtils} from "../../../../../../../utils/math/MathUtils";
import type {PointDouble} from "../../../../../../../generated/api/base";
import type {BoundaryManager} from "./BoundaryManager";
import type {MarkupManager} from "./MarkupManager";
import type {XyiconManager} from "./XyiconManager";
import {keyForObjectsWithRotationHandlersInUserData} from "./icons/RotationIconTypes";

interface IMeshAtCoordsReturnType {
	itemManager: XyiconManager | BoundaryManager | MarkupManager | null;
	meshAtCoords: Mesh | null;
	point: Vector3 | null;
	normal: Vector3 | null;
}

export class RaycasterManager {
	private _spaceViewRenderer: SpaceViewRenderer;
	private _tempV2 = new Vector2();
	private _intersectables: Object3D[] = [];
	private _raycaster: Raycaster = new Raycaster();
	private _intersects: Intersection<Object3D>[] = [];
	private _raycastIgnoreList: Set<string> = new Set<string>();
	private readonly _specialIntersectableNames: Set<string> = new Set<string>(["embeddedIcon", "rotationIcon", "linkIcon", "grabbableCorner"]);

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		this._spaceViewRenderer = spaceViewRenderer;
	}

	public updateLineThreshold() {
		const cameraZoomValue = this._spaceViewRenderer.toolManager.cameraControls.cameraZoomValue;
		const min = 1.5; // px;
		const max = 15; // px
		const thresholdInPx = MathUtils.clamp(MathUtils.getInterpolant(0, min, 1, max, cameraZoomValue), min, max);

		this._raycaster.params.Line.threshold = (thresholdInPx * this._spaceViewRenderer.correctionMultiplier.current) / cameraZoomValue;
	}

	private getFirstValidInstanceFromIntersects(intersects: Intersection<Object3D>[], positionInNDC: PointDouble) {
		if (this._spaceViewRenderer.activeCamera instanceof OrthographicCamera) {
			const lineSegments: Intersection<Object3D>[] = [];

			for (let i = 0; i < intersects.length; ++i) {
				if (this.isIntersectionABoundarySpaceMapLine(intersects[i])) {
					lineSegments.push(intersects[i]);
				}
			}

			const cursorWorldPos = THREEUtils.NDCtoWorldCoordinates(positionInNDC.x, positionInNDC.y, 0, this._spaceViewRenderer.activeCamera);

			lineSegments.sort((a, b) => {
				const geometryDataA: PointDouble[] = a.object.parent.userData.spaceItem.data.geometryData;
				const geometryDataB: PointDouble[] = b.object.parent.userData.spaceItem.data.geometryData;

				const lineIndexA = this.getBoundaryLineIndex(a);
				const lineIndexB = this.getBoundaryLineIndex(b);

				const lineA: ILineSegment = {
					A: geometryDataA[lineIndexA],
					B: geometryDataA[(lineIndexA + 1) % geometryDataA.length],
				};
				const lineB: ILineSegment = {
					A: geometryDataB[lineIndexB],
					B: geometryDataB[(lineIndexB + 1) % geometryDataB.length],
				};

				const distanceSqA = THREEUtils.pointToSegmentDistSq(cursorWorldPos, lineA);
				const distanceSqB = THREEUtils.pointToSegmentDistSq(cursorWorldPos, lineB);

				return distanceSqA - distanceSqB;
			});

			for (let i = 0; i < intersects.length; ++i) {
				if (this.isIntersectionABoundarySpaceMapLine(intersects[i])) {
					intersects[i] = lineSegments.shift();
				}
			}
		}

		for (const intersect of intersects) {
			// Users shouldn't be able to select invisible and/or invalid spaceitems
			const spaceItem = this.getSpaceItemFromMeshAtCoords(intersect.object as Mesh) as Xyicon3D | BoundarySpaceMap3D | Markup3D;

			// Users shouldn't be able to select temp markups either
			if ((spaceItem as Markup3D)?.isTemp) {
				return null;
			}

			if (spaceItem) {
				if (spaceItem.isVisible && spaceItem.modelData && !this._raycastIgnoreList.has(spaceItem.modelData.id)) {
					return intersect;
				}
			}
		}

		return null;
	}

	private filterIntersects = (intersects: Intersection<Object3D<Object3DEventMap>>[], raycastIgnoreList: Set<string>) => {
		return intersects.filter((intersect) => {
			if (intersect.object instanceof InstancedMesh) {
				const spaceItem = this.getSpaceItemByInstancedMeshAndInstanceId(intersect.object as InstancedMesh, intersect.instanceId) as Xyicon3D;

				if (spaceItem?.modelData?.id) {
					return !raycastIgnoreList.has(spaceItem.modelData.id);
				}
			}

			return true;
		});
	};

	public getMeshAtCoords(domX: number, domY: number, intersectables?: Object3D[], excludedSpaceItemIDs: string[] = []): IMeshAtCoordsReturnType {
		this._raycastIgnoreList = new Set(excludedSpaceItemIDs);
		if (intersectables) {
			this._intersectables = intersectables;
		} else {
			const visibleMsdfTextObjects = [
				this._spaceItemController.markupTextManager.instancedMesh,
				this._xyiconManager.captionManager.instancedMesh,
				this._boundaryManager.captionManager.instancedMesh,
			].filter((m) => m?.parent && m?.visible);

			this._intersectables = [
				...visibleMsdfTextObjects,
				...this._boundaryManager.getIntersectables(),
				...this._markupManager.getIntersectables(),
				...this._xyiconManager.getIntersectables(),
			];

			if (this._spaceItemController.linkIconManager.icons) {
				this._intersectables.push(this._spaceItemController.linkIconManager.icons);
			}
			if (this._rotationIconManager.icons) {
				this._intersectables.push(this._rotationIconManager.icons);
			}
		}

		let itemManager: XyiconManager | BoundaryManager | MarkupManager;

		const positionInNDC = THREEUtils.domCoordinatesToNDC(domX, domY, this._spaceViewRenderer.domElement);

		/** x and y should be in NDC, between -1 and 1 */
		this._tempV2.set(positionInNDC.x, positionInNDC.y);
		this._raycaster.setFromCamera(this._tempV2, this._spaceViewRenderer.activeCamera);
		this._intersects.length = 0;
		this._raycaster.intersectObjects(this._intersectables, false, this._intersects);
		this._intersects = this.filterIntersects(this._intersects, this._raycastIgnoreList);

		if (this._intersects.length > 0) {
			const intersectedInstancedMeshes: Set<InstancedMesh> = new Set<InstancedMesh>();

			for (const intersect of this._intersects) {
				if (intersect.object instanceof InstancedMesh) {
					if (!intersectedInstancedMeshes.has(intersect.object)) {
						intersectedInstancedMeshes.add(intersect.object);
						intersect.object.userData.instanceIds = [intersect.instanceId];
					} else {
						intersect.object.userData.instanceIds.push(intersect.instanceId);
					}
				}
			}
			/** Jason's preference: make xyicons high in this list */
			const firstValidIntersect =
				this.getGrabbableItemFromIntersects(this._intersects) ||
				this.getXyiconFromIntersects(this._intersects) ||
				this.getFirstValidInstanceFromIntersects(this._intersects, positionInNDC);

			if (this.isIntersectionABoundarySpaceMapLine(firstValidIntersect)) {
				// Based on this we can decide which line it is in a given boundaryspacemap
				firstValidIntersect.object.name = `${this.getBoundaryLineIndex(firstValidIntersect)}`;
			}

			itemManager = firstValidIntersect?.object.parent.userData.spaceItem?.itemManager;

			return {
				meshAtCoords: firstValidIntersect ? (firstValidIntersect.object as Mesh) : null,
				itemManager: firstValidIntersect ? (itemManager ?? this._xyiconManager) : null,
				point: firstValidIntersect ? firstValidIntersect.point : null,
				normal: firstValidIntersect ? firstValidIntersect.normal : null,
			};
		} else {
			return {
				meshAtCoords: null,
				itemManager: null,
				point: null,
				normal: null,
			};
		}
	}

	private isIntersectionABoundarySpaceMapLine(intersect?: Intersection<Object3D>): boolean {
		return intersect?.object instanceof LineSegments && intersect?.object.parent?.userData?.spaceItem?.spaceItemType === "boundary";
	}

	private getBoundaryLineIndex(intersect: Intersection<Object3D>) {
		// Based on this we can decide which line it is in a given boundaryspacemap
		return Math.floor(intersect.index / 6);
	}

	private getGrabbableItemFromIntersects(intersects: Intersection<Object3D>[]): Intersection<Object3D> {
		for (const intersect of intersects) {
			if (this._specialIntersectableNames.has(intersect.object.name)) {
				return intersect;
			}
		}

		return null;
	}

	private isXyiconIntersectValid(instancedMesh: InstancedMesh) {
		let spaceItem: Xyicon3D = null;

		for (const instanceId of instancedMesh.userData.instanceIds) {
			spaceItem = this.getSpaceItemByInstancedMeshAndInstanceId(instancedMesh, instanceId) as Xyicon3D;

			if (spaceItem?.isVisible) {
				const itemId = spaceItem.modelData?.id;

				if (itemId && !this._raycastIgnoreList.has(itemId)) {
					return true;
				}
			}
		}

		return false;
	}

	private getXyiconFromIntersects(intersects: Intersection<Object3D>[]): Intersection<Object3D> {
		for (const intersect of intersects) {
			if (intersect.object instanceof InstancedMesh) {
				if (this._xyiconManager.instancedMeshes.includes(intersect.object)) {
					if (this.isXyiconIntersectValid(intersect.object)) {
						return intersect;
					}
				}
			}
		}

		return null;
	}

	public getSpaceItemFromMeshAtCoords(meshAtCoords: Mesh | InstancedMesh): Xyicon3D | BoundarySpaceMap3D | Markup3D | ItemSelectionBox {
		if (meshAtCoords instanceof InstancedMesh) {
			let spaceItem: Xyicon3D | BoundarySpaceMap3D | Markup3D | ItemSelectionBox | null = null;

			for (const instanceId of meshAtCoords.userData.instanceIds) {
				spaceItem = this.getSpaceItemByInstancedMeshAndInstanceId(meshAtCoords, instanceId) as
					| Xyicon3D
					| BoundarySpaceMap3D
					| Markup3D
					| ItemSelectionBox;
				const itemId = (spaceItem as Xyicon3D)?.modelData?.id;

				if (
					[ItemSelectionBoxName, MarkupTextRotationName].includes(spaceItem?.typeName) ||
					(itemId && (spaceItem as Xyicon3D)?.isVisible && !this._raycastIgnoreList.has(itemId))
				) {
					return spaceItem;
				}
			}

			return null;
		} else {
			return meshAtCoords?.parent?.userData?.spaceItem as Xyicon3D | BoundarySpaceMap3D | Markup3D;
		}
	}

	private getSpaceItemByInstancedMeshAndInstanceId(instancedMesh: InstancedMesh, instanceId: number) {
		if (instancedMesh.name === "rotationIcon") {
			return instancedMesh.userData[keyForObjectsWithRotationHandlersInUserData][instanceId];
		} else if (instancedMesh.name === "linkIcon") {
			return instancedMesh.userData.selectedItems[instanceId];
		} else if (instancedMesh.name === "markupTexts") {
			return this._markupManager.items.array.find((m: Markup3D) => m.textInstanceIds.includes(instanceId));
		} else if (instancedMesh.name.includes("captionTexts")) {
			const type = instancedMesh.name.substring("captionTexts - ".length) as "xyicon" | "boundary";
			const itemManager = type === "xyicon" ? this._xyiconManager : this._boundaryManager;

			return itemManager.items.array.find((item: BoundarySpaceMap3D | Xyicon3D) => item.caption?.textInstanceIds.includes(instanceId));
		} else {
			const {manager, index} = this.getManagerAndIndexByInstancedMesh(instancedMesh);

			return manager.getSpaceItemByInstancedMeshIDAndInstanceID(index, instanceId);
		}
	}

	private getManagerAndIndexByInstancedMesh(instancedMesh: InstancedMesh) {
		if (instancedMesh === this._markupManager.photo360InstancedMesh) {
			return {
				manager: this._markupManager,
				index: 0,
			};
		}
		const instancedMeshes = this._xyiconManager.instancedMeshes;
		const index = instancedMeshes.indexOf(instancedMesh);

		if (index > -1) {
			return {
				manager: this._xyiconManager,
				index: index,
			};
		}
	}

	private get _spaceItemController() {
		return this._spaceViewRenderer.spaceItemController;
	}

	private get _xyiconManager() {
		return this._spaceItemController.xyiconManager;
	}

	private get _boundaryManager() {
		return this._spaceItemController.boundaryManager;
	}

	private get _markupManager() {
		return this._spaceItemController.markupManager;
	}

	private get _rotationIconManager() {
		return this._spaceItemController.rotationIconManager;
	}
}
