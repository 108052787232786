import * as React from "react";
import {observer} from "mobx-react";
import {flushSync} from "react-dom";
import type {Xyicon3D} from "../../logic3d/elements3d/Xyicon3D";
import type {SelectionTool} from "../../logic3d/features/tools/SelectionTool";
import type {Markup3D} from "../../logic3d/elements3d/markups/abstract/Markup3D";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import {IconButton} from "../../../../../widgets/button/IconButton";
import type {Xyicon} from "../../../../../../data/models/Xyicon";
import {DropdownButton} from "../../../../../widgets/button/DropdownButton";
import {PointerDetector} from "../../../../../../utils/interaction/PointerDetector";
import {XHRLoader} from "../../../../../../utils/loader/XHRLoader";
import {KeyboardListener} from "../../../../../../utils/interaction/key/KeyboardListener";
import {Permission, CatalogIconType} from "../../../../../../generated/api/base";
import {ColorSelector} from "../../../../abstract/common/colorselector/ColorSelector";
import type {Color} from "../../../../../../generated/api/base";
import {Debouncer} from "../../../../../../utils/function/Debouncer";
import type {Markup} from "../../../../../../data/models/Markup";
import {notify} from "../../../../../../utils/Notify";
import {NotificationType} from "../../../../../notification/Notification";
import {ImageUploadPreprocessor} from "../../../../../../utils/image/ImageUploadPreprocessor";
import {HTMLUtils} from "../../../../../../utils/HTML/HTMLUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../../utils/dom/DomUtils";
import {XyiconUtils} from "../../../../../../data/models/XyiconUtils";
import {MarkupsWithCustomizableColor} from "../../logic3d/elements3d/markups/MarkupStaticElements";
import {Photo360PreviewButton} from "../../../../../widgets/button/Photo360PreviewButton";
import {FindXyiconsWindow} from "./FindXyiconsWindow";
import {ArrowHeadSizeChanger} from "./ArrowHeadSizeChanger";
import {LineThicknessChanger} from "./LineThicknessChanger";
import {MarkupTypeChanger} from "./MarkupTypeChanger";
import {FillButton} from "./FillButton";
import {MarkupTextModifier} from "./MarkupTextModifier";

interface ISpaceActionBarProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly isEditEnabled: boolean;
	readonly isInEditMode: boolean;
	readonly isLinkEnabled: boolean;
	readonly worldX: number;
	readonly worldY: number;
	readonly onDeleteClick: () => void;
	readonly onRedrawClick: () => void;
	readonly onCancelEditClick: () => void;
	readonly onApplyEditClick: () => void;
	readonly openMergeBoundariesWindow: () => void;
}

interface ISpaceActionBarState {
	isTextEditorOpen: boolean;
	isInFindXyiconsMode: boolean;
}

@observer
export class SpaceActionBar extends React.Component<ISpaceActionBarProps, ISpaceActionBarState> {
	private _markupColorChangeDebouncer: Debouncer = new Debouncer();
	private _addTextRef = React.createRef<IconButton>();
	private _ref = React.createRef<HTMLDivElement>();
	private _itemsWithoutPermissionCount = this.spaceItemsWithoutPermissionCount;
	private get _iconSize() {
		return XyiconUtils.iconSize;
	}

	constructor(props: ISpaceActionBarProps) {
		super(props);

		this.state = {
			isTextEditorOpen: false,
			isInFindXyiconsMode: false,
		};
	}

	private notification() {
		const {selectedItems} = this.props.spaceViewRenderer.spaceItemController;

		notify(this.props.spaceViewRenderer.transport.appState.app.notificationContainer, {
			type: NotificationType.Warning,
			title: "Action not allowed!",
			description: `${this._itemsWithoutPermissionCount} of ${selectedItems.length} objects you have selected do not have the required permissions to perform this action. To obtain permission, contact your organization's administrator.`,
			lifeTime: 10000,
		});
	}

	private closeActionBar() {
		this.props.spaceViewRenderer.spaceItemController.closeActionBar();
	}

	private closePortSelector() {
		this.props.spaceViewRenderer.spaceItemController.closePortSelector();
	}

	private onLinkClick = () => {
		const {selectedItems} = this.props.spaceViewRenderer.spaceItemController;
		const xyicon = (selectedItems[0] as Xyicon3D).modelData as Xyicon;
		const ports = xyicon.ports;

		if (this._itemsWithoutPermissionCount === 0) {
			if (selectedItems.length === 1 && ports.length > 0) {
				this.props.spaceViewRenderer.spaceItemController.openPortSelector(this.props.worldX, this.props.worldY, xyicon, ports, "from");
			} else {
				this.props.spaceViewRenderer.toolManager.linkManager.enableLinkMode(null);
			}

			this.props.spaceViewRenderer.inheritedMethods.setActiveTool("selection");
		} else {
			this.notification();
		}
	};

	private onCancelLinkClick = () => {
		this.props.spaceViewRenderer.toolManager.linkManager.disableLinkMode();
		this.setState({
			isInFindXyiconsMode: false,
		});
	};

	private onFindXyiconClick = () => {
		this.setState({
			isInFindXyiconsMode: true,
		});
	};

	private onFlipXClick = () => {
		this.props.spaceViewRenderer.spaceItemController.xyiconManager.flipXSelected();
	};

	private onFlipYClick = () => {
		this.props.spaceViewRenderer.spaceItemController.xyiconManager.flipYSelected();
	};

	private onUnplotClick = async () => {
		const {spaceViewRenderer} = this.props;
		const {xyiconManager, space} = spaceViewRenderer;
		const {selectedItems} = xyiconManager;
		const selectedXyicons = selectedItems as Xyicon3D[];

		if (this._itemsWithoutPermissionCount === 0) {
			const xyiconIDList: string[] = selectedXyicons.map((xyicon) => xyicon.modelData.id);
			const correctionMultiplier = spaceViewRenderer.isMounted ? spaceViewRenderer.correctionMultiplier.current : 1;
			const unplotIconInToolarPos = (document.querySelector(".btn.Unplotted.Xyicons") as HTMLElement).getBoundingClientRect();

			unplotIconInToolarPos.x += unplotIconInToolarPos.width / 2;
			unplotIconInToolarPos.y += unplotIconInToolarPos.height / 2;
			const {domElement} = spaceViewRenderer;
			const size = HTMLUtils.getSize(domElement);
			const tempDivs: HTMLDivElement[] = [];
			const duration = 200;

			for (const x of selectedXyicons) {
				const xyicon = x.modelData as Xyicon;
				const tempDiv = document.createElement("div");

				const coords = THREEUtils.worldCoordinatesToDomCoordinates(
					x.position.x,
					x.position.y,
					x.position.z,
					spaceViewRenderer.domElement,
					spaceViewRenderer.activeCamera,
				);

				coords.x += size.x;
				coords.y += size.y;

				tempDiv.className = `glyph-tobeunplotted ${x.modelData.id}`;
				tempDiv.style.backgroundImage = `url('${xyicon.thumbnail}')`;
				tempDiv.style.width = `${this._iconSize}px`;
				tempDiv.style.height = `${this._iconSize}px`;
				tempDiv.style.position = "absolute";
				tempDiv.style.backgroundSize = "contain";
				tempDiv.style.zIndex = "9999";
				tempDiv.style.left = `${coords.x}px`;
				tempDiv.style.top = `${coords.y}px`;
				tempDiv.style.pointerEvents = "none";

				tempDiv.style.transform = `translate(-50%, -50%) ${XyiconUtils.getScaleForCSSXyicon(xyicon, spaceViewRenderer)}`;

				if (xyicon.iconCategory === CatalogIconType.ModelParameter) {
					const snapShot = await ImageUploadPreprocessor.getTopToBottomSnapshotOfCatalog(
						xyicon.catalog,
						spaceViewRenderer.actions,
						space,
						correctionMultiplier,
					);

					if (snapShot.image !== xyicon.thumbnail) {
						const standardXyiconSize = this.props.spaceViewRenderer.xyiconManager.xyiconSize;
						const imageSize = (this._iconSize * Math.max(snapShot.xyiconSize.x, snapShot.xyiconSize.y)) / standardXyiconSize;

						tempDiv.style.backgroundImage = `url('${snapShot.image}')`;
						tempDiv.style.width = `${imageSize}px`;
						tempDiv.style.height = `${imageSize}px`;
					}
				}

				tempDivs.push(tempDiv);
				document.body.appendChild(tempDiv);
			}

			xyiconManager.deleteSelectedItems(false);

			const promises: Promise<Animation[]>[] = [];

			for (const tempDiv of tempDivs) {
				promises.push(DomUtils.flyTo(unplotIconInToolarPos.x, unplotIconInToolarPos.y, tempDiv, duration, false));
			}

			await Promise.all(promises);

			for (const tempDiv of tempDivs) {
				document.body.removeChild(tempDiv);
			}

			for (const selectedXyicon of selectedXyicons) {
				(selectedXyicon.modelData as Xyicon).unplot();
			}

			await spaceViewRenderer.transport.requestForOrganization({
				url: "xyicons/unplot",
				method: XHRLoader.METHOD_POST,
				params: {
					xyiconIDList: xyiconIDList,
					portfolioID: spaceViewRenderer.transport.appState.portfolioId,
				},
			});
		} else {
			this.notification();
		}
	};

	public onAddTextClick = () => {
		if (!this.state.isTextEditorOpen) {
			this.setState({
				isTextEditorOpen: true,
			});
		}

		this.props.spaceViewRenderer.inheritedMethods.setActiveTool("selection");
	};

	public onTextEditorClose = () => {
		if (this.state.isTextEditorOpen) {
			this.setState({
				isTextEditorOpen: false,
			});
		}
	};

	private onEditClick = () => {
		this.props.spaceViewRenderer.spaceItemController.switchEditMode(!this.props.isInEditMode);
	};

	private onRotateClick = () => {
		const {spaceItemController} = this.props.spaceViewRenderer;
		const {rotationIconManager} = spaceItemController;

		if (this._itemsWithoutPermissionCount === 0) {
			rotationIconManager.update();
			this.closeActionBar();
			this.closePortSelector();
			this.props.spaceViewRenderer.inheritedMethods.setActiveTool("selection");
		} else {
			this.notification();
		}
	};

	private onEventsClick = () => {
		this.closeActionBar();
		this.closePortSelector();
		this.props.spaceViewRenderer.inheritedMethods.setActiveTool("selection");
	};

	private onCameraMove = () => {
		const activeTool = this.props.spaceViewRenderer.toolManager.activeTool;

		if (activeTool.toolId !== "selection" || (activeTool.toolId === "selection" && !(activeTool as SelectionTool).isSomethingBeingTranslated)) {
			flushSync(this.forceUpdateArrow);
		}
	};

	private forceUpdateArrow = () => {
		this.forceUpdate();
	};

	private onKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ENTER:
				if (this._addTextRef.current) {
					this.onAddTextClick();
				}
				break;
		}
	};

	private get _isSingleMarkupSelected() {
		const {spaceItemController} = this.props.spaceViewRenderer;

		return spaceItemController.markupManager.selectedItems.length === 1 && spaceItemController.selectedItems.length === 1;
	}

	private get spaceItemsWithoutPermissionCount() {
		const {spaceItemController, actions} = this.props.spaceViewRenderer;

		return actions.getNumberOfSpaceItemsWithoutPermission(spaceItemController.selectedItems, Permission.Update);
	}

	private getMoreOptions() {
		const {spaceViewRenderer} = this.props;
		const {spaceItemController} = spaceViewRenderer;
		const areOnlyXyiconsSelected = spaceItemController.selectedItems.length === spaceViewRenderer.xyiconManager.selectedItems.length;

		const moreOptions = [
			{
				label: "Copy to Stamp",
				onClick: spaceViewRenderer.copySelectedItemsToStamp,
				icon: "copy-to-stamp",
			},
			{
				label: "Cut",
				onClick: spaceViewRenderer.cutSelectedItemsToClipboard,
				icon: "cut",
			},
		];

		if (areOnlyXyiconsSelected) {
			moreOptions.push(
				{
					label: "Flip Horizontal",
					onClick: this.onFlipXClick,
					icon: "flipX",
				},
				{
					label: "Flip Vertical",
					onClick: this.onFlipYClick,
					icon: "flipY",
				},
				{
					label: "Unplot",
					onClick: this.onUnplotClick,
					icon: "unplot",
				},
			);
		}

		const {selectedItems} = spaceItemController;

		if (selectedItems.length > 0 && selectedItems.length === spaceItemController.boundaryManager.selectedItems.length) {
			moreOptions.push({
				label: "Merge",
				onClick: this.props.openMergeBoundariesWindow,
				icon: "merge",
			});
		}

		return moreOptions;
	}

	private get hasPermissionToDelete() {
		const {spaceViewRenderer} = this.props;

		return spaceViewRenderer.actions.someSpaceItemsHaveGivenPermission(spaceViewRenderer.spaceItemController.selectedItems, Permission.Delete);
	}

	private onMarkupColorChange = (newColor: Color) => {
		const selectedMarkups = (this.props.spaceViewRenderer.markupManager.selectedItems as Markup3D[]).filter((m) =>
			MarkupsWithCustomizableColor.includes(m.type),
		);

		for (const markup of selectedMarkups) {
			const modelData = markup.modelData as Markup;

			modelData.setColor(newColor.hex);
			markup.updateByModel(modelData);
		}

		this._markupColorChangeDebouncer.debounce(() => {
			// Since we've already changed the modelData, and updated the model based on that,
			// it's necessary to pass "true" for the second (force) parameter here
			this.props.spaceViewRenderer.markupManager.updateItems(selectedMarkups, true);
		});
	};

	public override componentDidMount() {
		const {spaceViewRenderer} = this.props;

		spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.add(this.onCameraMove);
		spaceViewRenderer.signals.onCanvasResized.add(this.forceUpdateArrow);
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);

		// ref.current is null at the first render, so we have to recalculate the position immediately
		this.forceUpdate();
	}

	public override componentDidUpdate(prevProps: Readonly<ISpaceActionBarProps>, prevState: Readonly<ISpaceActionBarState>, snapshot?: any): void {
		if (this.state.isInFindXyiconsMode !== prevState.isInFindXyiconsMode) {
			this.forceUpdate();
		}
	}

	public override componentWillUnmount() {
		const {spaceViewRenderer} = this.props;

		spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.remove(this.onCameraMove);
		spaceViewRenderer.signals.onCanvasResized.remove(this.forceUpdateArrow);
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
	}

	public override render() {
		const {worldX, spaceViewRenderer} = this.props;
		const worldZ = spaceViewRenderer.spaceOffset.z;
		const {spaceItemController} = spaceViewRenderer;
		const areOnlyMarkupsSelected = spaceItemController.selectedItems.length === spaceViewRenderer.markupManager.selectedItems.length;
		const firstColoredMarkup = spaceViewRenderer.markupManager.selectedItems.find((m: Markup3D) =>
			MarkupsWithCustomizableColor.includes(m.type),
		) as Markup3D;

		const singleMarkup = spaceItemController.markupManager.selectedItems[0] as Markup3D;
		const doesMarkupSupportText = singleMarkup?.doesSupportText;
		const doesSupportText = this._isSingleMarkupSelected && doesMarkupSupportText;

		const isTextEditorOpen = doesSupportText && this.state.isTextEditorOpen;

		let {worldY} = this.props;

		if (
			spaceItemController.isInEditMode &&
			spaceItemController.selectedItems.every((m) => m.spaceItemType === "markup" && (m as Markup3D).isTextOffsetHandlerVisible)
		) {
			worldY += 100 * spaceViewRenderer.correctionMultiplier.current;
		}

		const style = THREEUtils.getStyleForFloatingUIElement(worldX, worldY, worldZ, spaceViewRenderer, true, "top", this._ref.current);

		return isTextEditorOpen ? (
			<MarkupTextModifier
				barRef={this._ref}
				style={style}
				spaceViewRenderer={spaceViewRenderer}
				markup3D={singleMarkup}
				onClose={this.onTextEditorClose}
			/>
		) : this.state.isInFindXyiconsMode ? (
			<FindXyiconsWindow
				divRef={this._ref}
				onClose={this.onCancelLinkClick}
				style={style}
				appState={this.props.spaceViewRenderer.transport.appState}
			/>
		) : (
			<div
				ref={this._ref}
				className="SpaceActionBar"
				style={style}
				onContextMenu={PointerDetector.onContextMenu}
			>
				{this.props.isInEditMode ? (
					<>
						{this.props.spaceViewRenderer.boundaryManager.isInEditMode && (
							<IconButton
								icon="reDraw"
								title="Redraw"
								onClick={this.props.onRedrawClick}
							/>
						)}
						<IconButton
							icon="apply"
							title="Done"
							onClick={this.props.onApplyEditClick}
						/>
						<IconButton
							icon="cancel"
							title="Cancel"
							onClick={this.props.onCancelEditClick}
						/>
					</>
				) : this.props.spaceViewRenderer.toolManager.linkManager.isInLinkMode ? (
					<>
						<IconButton
							icon="search"
							title="Find..."
							onClick={this.onFindXyiconClick}
						/>
						<IconButton
							icon="cancel"
							title="Cancel"
							onClick={this.onCancelLinkClick}
						/>
					</>
				) : (
					<>
						<MarkupTypeChanger spaceViewRenderer={spaceViewRenderer} />
						<IconButton
							icon="rotate"
							title="Rotate"
							onClick={this.onRotateClick}
						/>
						<Photo360PreviewButton spaceViewRenderer={spaceViewRenderer} />
						<FillButton spaceViewRenderer={spaceViewRenderer} />
						<LineThicknessChanger
							spaceViewRenderer={spaceViewRenderer}
							items={spaceViewRenderer.spaceItemController.selectedItems}
						/>
						<ArrowHeadSizeChanger spaceViewRenderer={spaceViewRenderer} />
						{this.props.isEditEnabled && (
							<IconButton
								icon="edit"
								title="Edit"
								onClick={this.onEditClick}
							/>
						)}
						{areOnlyMarkupsSelected && firstColoredMarkup && (
							<ColorSelector
								title="Markup color"
								color={{hex: firstColoredMarkup.color, transparency: 0}}
								onColorChange={this.onMarkupColorChange}
								isTransparencyEnabled={false}
								eyeDropperProps={spaceViewRenderer.eyeDropperProps}
								outerDivRef={this._ref}
								changeAlignmentOnPositionCorrection={true}
								horizontalAlignment={HorizontalAlignment.outerRight}
								verticalAlignment={VerticalAlignment.top}
								icon="paint-bucket"
								classNames="button"
							/>
						)}
						{this.props.isLinkEnabled && (
							<IconButton
								icon="attach"
								title="Link"
								onClick={this.onLinkClick}
								strokeOnly={true}
							/>
						)}
						<IconButton
							icon="duplicate"
							title="Copy"
							onClick={spaceViewRenderer.copySelectedItemsToClipboard}
						/>
						{doesSupportText && (
							<IconButton
								icon="text"
								title="Add Text"
								onClick={this.onAddTextClick}
								ref={this._addTextRef}
							/>
						)}
						{/* <IconButton icon="events" title="Events"  onClick={this.onEventsClick} /> */}
						{this.hasPermissionToDelete && (
							<IconButton
								icon="delete"
								title="Delete"
								onClick={this.props.onDeleteClick}
							/>
						)}
						<DropdownButton
							className="SpaceActionBarDropdown"
							button={
								<IconButton
									icon="more_v"
									title="More Tools"
								/>
							}
							options={this.getMoreOptions()}
						/>
					</>
				)}
			</div>
		);
	}
}
