import {makeObservable, observable, reaction} from "mobx";
import {Group as TweenGroup} from "@tweenjs/tween.js";
import {Signal} from "../utils/signal/Signal";
import type {SpaceViewRenderer} from "../ui/modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {Constants} from "../ui/modules/space/spaceeditor/logic3d/Constants";
import {Convergence, Easing} from "../utils/animation/Convergence";
import {BoundedConvergence} from "../utils/animation/BoundedConvergence";
import type {CallbackType} from "./css3d/CSS3DManager";
import {CSS3DManager} from "./css3d/CSS3DManager";
import {WebGLRenderer} from "./WebGLRenderer";
import {PhotoSphereCameraControls} from "./PhotoSphereCameraControls";
import type {ITextureData} from "./PhotoSphereManager";
import type {HotSpot} from "./css3d/HotSpot";
import type {PhotoSphereViewMode} from "./PhotoSphereTypes";

export class PhotoSphereSceneManager {
	private readonly _canvas: HTMLCanvasElement;
	private _cameraControls: PhotoSphereCameraControls;
	private readonly _webGLRenderer: WebGLRenderer;
	private readonly _css3dManagers: [CSS3DManager, CSS3DManager] = [new CSS3DManager(this), new CSS3DManager(this)];
	private _activeCss3DManagerIndex: number = 1;
	private _forward: [number, number, number] = [1, 0, 0];
	private readonly _userZoomFactor: BoundedConvergence;

	private _viewBox: number[] = [];

	private _isFirstTextureLoaded: boolean = false;
	private readonly _spaceViewRenderer: SpaceViewRenderer;
	private _requestAnimationFrameId: number = null;
	public prevTimeStamp: number = 0;
	private _timeStamp: number = 0;
	private _deltaFrame: number = 1000;
	public rotationOffset: number = 0;

	@observable
	public viewMode: PhotoSphereViewMode = "normal";

	@observable
	public showHotSpots: boolean = true;

	@observable
	public hotSpotNumberLimit: number = 10;

	public readonly activeConvergences: Convergence[] = [];
	public readonly tweenGroup = new TweenGroup();

	public signals = {
		resize: Signal.create<{width: number; height: number}>(),
		onBeforeRender: Signal.create<null>(),
		onAfterRender: Signal.create<null>(),
	};
	public cameraControlsNeedsUpdate = true;
	public needsRender = true;

	constructor(canvas: HTMLCanvasElement, spaceViewRenderer: SpaceViewRenderer) {
		this._canvas = canvas;
		this._spaceViewRenderer = spaceViewRenderer;
		this._webGLRenderer = new WebGLRenderer(this._canvas, this);
		this._userZoomFactor = new BoundedConvergence({
			start: Constants.DEFAULT_ZOOM_FACTOR,
			end: Constants.DEFAULT_ZOOM_FACTOR,
			min: Constants.MIN_ZOOM_FACTOR,
			max: 4,
			easing: Easing.EASE_OUT,
			timeStampManager: this,
		});
		makeObservable(this);

		reaction(
			() => this.showHotSpots,
			(newValue: boolean) => {
				for (const css3dManager of this._css3dManagers) {
					newValue ? css3dManager.show() : css3dManager.hide();
				}
				this.needsRender = true;
			},
		);

		reaction(
			() => this.hotSpotNumberLimit,
			(newValue: number) => {
				this.activeCSS3DManager.updateHotSpotNumberLimit();
				this.needsRender = true;
			},
		);
	}

	/** Return if it's successfully initialized */
	public init() {
		try {
			// Init renderers
			const successful = this._webGLRenderer.init();
			if (!successful) {
				return false;
			}

			// Init controls
			this._cameraControls = new PhotoSphereCameraControls(this._canvas.parentElement, this);
			this._cameraControls.activate();

			window.addEventListener("resize", this.onWindowResize);
			this.onWindowResize();
		} catch (error) {
			return false;
		}

		return true;
	}

	public onWindowResize = () => {
		const canvasParent = this._canvas.parentElement;
		let width = canvasParent.offsetWidth;
		let height = canvasParent.offsetHeight;

		if (this.viewMode === "split") {
			width /= 2;
		}

		this._canvas.width = width;
		this._canvas.height = height;
		this._webGLRenderer.setSize(width, height);
		this._css3dManagers[0].setSize(width, height);
		this._css3dManagers[1].setSize(width, height);
		this._cameraControls.setSize();
		this._viewBox[0] = width;
		this._viewBox[1] = height;
		this.signals.resize.dispatch({width: width, height: height});
	};

	public loadDefaultTexture() {
		this._webGLRenderer.loadDefaultTexture();
	}

	public loadPhotoSphere(texData: ITextureData, playMovementEffect: boolean, hotSpots: HotSpot[], headingAngle: number, callback: CallbackType) {
		this._cameraControls.limitRotation(texData.viewBox);

		// 1.7 / 4000 -> the max userzoomfactor for an image width 4000px width is 1.7.
		const userZoomMax = Math.max(Constants.MIN_ZOOM_FACTOR, (texData.textureData.width * (1.7 / 4000)) / (texData.viewBox[2] - texData.viewBox[0]));

		if (userZoomMax < this._userZoomFactor.value) {
			playMovementEffect = false;
		}

		this._webGLRenderer.loadTexture(texData, playMovementEffect);

		// See the similar stucture in WebGLRenderer for explanation
		requestAnimationFrame(() => {
			this._userZoomFactor.setEnd(Math.min(this._userZoomFactor.end, userZoomMax));
			setTimeout(() => {
				this._userZoomFactor.setMax(userZoomMax);
			}, this._userZoomFactor.animationDuration);
		});

		const previousIndex = this._activeCss3DManagerIndex;
		this._activeCss3DManagerIndex = this._activeCss3DManagerIndex === 1 ? 0 : 1;
		this._css3dManagers[previousIndex].fade();
		this.activeCSS3DManager.updateHotSpots(hotSpots, callback, playMovementEffect);
		this.needsRender = true;
		this._isFirstTextureLoaded = true;
	}

	private update = () => {
		this.tweenGroup.update();

		if (this._isFirstTextureLoaded) {
			this._timeStamp = performance.now();
			this._deltaFrame = this._timeStamp - this.prevTimeStamp;
			this.prevTimeStamp = this._timeStamp;
			this.cameraControlsNeedsUpdate = Convergence.updateActiveOnes(this._timeStamp, this) || this.cameraControlsNeedsUpdate;
			if (this.cameraControlsNeedsUpdate) {
				this._forward = this._cameraControls.update();
				this.cameraControlsNeedsUpdate = false;
				this.needsRender = true;
			}

			this.signals.onBeforeRender.dispatch(null);

			if (this.needsRender) {
				this._webGLRenderer.render(this._forward, this._userZoomFactor.value);
				this._css3dManagers[0].render(this._forward, this._userZoomFactor.value);
				this._css3dManagers[1].render(this._forward, this._userZoomFactor.value);
				this.needsRender = false;
			}

			this.signals.onAfterRender.dispatch(null);
		}
		this._requestAnimationFrameId = requestAnimationFrame(this.update);
	};

	public animate = () => {
		this.prevTimeStamp = performance.now();
		this.update();
	};

	/** Returns the timestamp of the newest render run  */
	public get timeStamp() {
		return this._timeStamp;
	}

	/** Returns the time between the last 2 frames, so we can get an idea of the user's FPS */
	public get deltaFrame() {
		return this._deltaFrame;
	}

	public mount(parentElement: HTMLElement) {
		parentElement.appendChild(this._canvas);
		parentElement.appendChild(this._css3dManagers[0].domElement);
		parentElement.appendChild(this._css3dManagers[1].domElement);
	}

	public unmount() {
		cancelAnimationFrame(this._requestAnimationFrameId);
		this._cameraControls?.deactivate();
		this.prevTimeStamp = 0;
		this._timeStamp = 0;
		this._deltaFrame = 1000;

		this._canvas.remove();
		this._css3dManagers?.[0]?.domElement?.remove();
		this._css3dManagers?.[1]?.domElement?.remove();

		window.removeEventListener("resize", this.onWindowResize);
	}

	public get userZoomFactor() {
		return this._userZoomFactor;
	}

	public get maxTextureSize() {
		return this._webGLRenderer.maxTextureSize;
	}

	public get css3dManagers() {
		return this._css3dManagers;
	}

	public get activeCSS3DManager() {
		return this._css3dManagers[this._activeCss3DManagerIndex];
	}

	public get cameraControls() {
		return this._cameraControls;
	}

	public get viewBox() {
		return this._viewBox;
	}

	public get forward() {
		return this._forward;
	}

	public get webGLRenderer() {
		return this._webGLRenderer;
	}

	public get canvas() {
		return this._canvas;
	}

	public get spaceViewRenderer() {
		return this._spaceViewRenderer;
	}
}
