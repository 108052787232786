import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {MeasureType} from "../../renderers/SpaceViewRendererUtils";
import {SelectionTool} from "../../features/tools/SelectionTool";
import {PanTool} from "../../features/tools/PanTool";
import {BoundaryTool} from "../../features/tools/BoundaryTool";
import {ABTool} from "../features/../tools/markuptools/ABTool";
import {PencilTool} from "../features/../tools/markuptools/PencilTool";
import {SetScaleTool} from "../features/../tools/markuptools/SetScaleTool";
import {MoveBackgroundTool} from "../features/../tools/MoveBackgroundTool";
import {SetPinTool} from "../features/../tools/SetPinTool";
import type {Tool} from "./Tool";
import {MarkupStampTool} from "./markuptools/MarkupStampTool";

export enum SpaceToolBarType {
	PrimaryToolBar,
	MarkupToolBar,
	SpaceAlignToolBar,
}

export type SelectionToolType = "selection" | "pan";
export const arrayOfSelectionToolTypes: SelectionToolType[] = ["selection", "pan"]; // easier to check instances with this

export type ToolType = "selection" | "setPin" | "pan" | "moveBackground" | "markup" | "boundary";

export interface IToolConfig {
	icon: string;
	title: string;
	tool: Tool;
}

export const initTools = (spaceViewRenderer: SpaceViewRenderer): {[key in SpaceTool]: IToolConfig} => {
	return {
		selection: {
			title: "Selection Tool",
			icon: "cursor",
			tool: new SelectionTool(spaceViewRenderer),
		},
		pan: {
			title: "Pan Tool",
			icon: "pan",
			tool: new PanTool(spaceViewRenderer),
		},
		boundary: {
			title: "Boundary Tool",
			icon: "boundary",
			tool: new BoundaryTool(spaceViewRenderer),
		},
		markupCloud: {
			title: "Cloud",
			icon: "markupCloud",
			tool: new ABTool(spaceViewRenderer, "cloud"),
		},
		markupCallout: {
			title: "Callout",
			icon: "markupCallout",
			tool: new ABTool(spaceViewRenderer, "callout"),
		},
		markupArrow: {
			title: "Arrow",
			icon: "markupArrow",
			tool: new ABTool(spaceViewRenderer, "arrow"),
		},
		markupBidirectionalArrow: {
			title: "Bidirectional Arrow",
			icon: "markupBidirectionalArrow",
			tool: new ABTool(spaceViewRenderer, "bidirectional-arrow"),
		},
		markupLine: {
			title: "Line",
			icon: "markupLine",
			tool: new ABTool(spaceViewRenderer, "line"),
		},
		markupDashedLine: {
			title: "Dashed Line",
			icon: "markupLineDashed",
			tool: new ABTool(spaceViewRenderer, "dashed-line"),
		},
		markupPencil: {
			title: "Pencil Tool",
			icon: "markupPencil",
			tool: new PencilTool(spaceViewRenderer),
		},
		markupHighlight: {
			title: "Highlight Tool",
			icon: "markupHighlight",
			tool: new PencilTool(spaceViewRenderer, true),
		},
		markupText: {
			title: "Text Box",
			icon: "text",
			tool: new ABTool(spaceViewRenderer, "text"),
		},
		markupRectangle: {
			title: "Rectangle",
			icon: "markupRectangle",
			tool: new ABTool(spaceViewRenderer, "rectangle"),
		},
		markupEllipse: {
			title: "Ellipse",
			icon: "markupEllipse",
			tool: new ABTool(spaceViewRenderer, "ellipse"),
		},
		markupTriangle: {
			title: "Triangle",
			icon: "markupTriangle",
			tool: new ABTool(spaceViewRenderer, "triangle"),
		},
		markupCross: {
			title: "Cross",
			icon: "markupCross",
			tool: new ABTool(spaceViewRenderer, "cross"),
		},
		markupPhoto360: {
			title: "Markup 360 Photo",
			icon: "markupPhoto360",
			tool: new MarkupStampTool(spaceViewRenderer, "photo360"),
		},
		measureLinearDistance: {
			title: "Measure Tool - Linear Distance",
			icon: "linearDistance",
			tool: new ABTool(spaceViewRenderer, "line", MeasureType.DISTANCE),
		},
		measureRectArea: {
			title: "Measure Tool - Rectangle Area",
			icon: "rectArea",
			tool: new ABTool(spaceViewRenderer, "rectangle", MeasureType.AREA),
		},
		measureNonLinearDistance: {
			title: "Measure Tool - Nonlinear Distance",
			icon: "nonLinearDistance",
			tool: new PencilTool(spaceViewRenderer, false, MeasureType.DISTANCE),
		},
		measureIrregularArea: {
			title: "Measure Tool - Irregular Area",
			icon: "irregularArea",
			tool: new PencilTool(spaceViewRenderer, false, MeasureType.AREA),
		},
		// markupStamp: {
		// 	title: "Stamp Tool",
		// 	icon: "markupStamp",
		// 	tool: null
		// },
		// markupPhoto: {
		// 	title: "Add Photo",
		// 	icon: "markupPhoto",
		// 	tool: null
		// },
		setScale: {
			title: "Set Scale",
			icon: "setScale",
			tool: new SetScaleTool(spaceViewRenderer),
		},
		moveBackground: {
			title: "Move Background",
			icon: "realignSpace",
			tool: new MoveBackgroundTool(spaceViewRenderer),
		},
		setPin: {
			title: "Set Pin",
			icon: "setPin",
			tool: new SetPinTool(spaceViewRenderer),
		},
		tempMeasureLinearDistance: {
			title: "Measure Tool - Linear Distance",
			icon: "linearDistance",
			tool: new ABTool(spaceViewRenderer, "bidirectional-arrow", MeasureType.DISTANCE, true),
		},
		tempMeasureRectArea: {
			title: "Measure Tool - Rectangle Area",
			icon: "rectArea",
			tool: new ABTool(spaceViewRenderer, "rectangle", MeasureType.AREA, true),
		},
		tempMeasureNonLinearDistance: {
			title: "Measure Tool - Nonlinear Distance",
			icon: "nonLinearDistance",
			tool: new PencilTool(spaceViewRenderer, false, MeasureType.DISTANCE, true),
		},
		tempMeasureIrregularArea: {
			title: "Measure Tool - Irregular Area",
			icon: "irregularArea",
			tool: new PencilTool(spaceViewRenderer, false, MeasureType.AREA, true),
		},
	};
};

export type SpaceTool =
	| "selection"
	| "pan"
	| "boundary"
	| "markupCloud"
	| "markupCallout"
	| "markupArrow"
	| "markupBidirectionalArrow"
	| "markupLine"
	| "markupDashedLine"
	| "markupPencil"
	| "markupHighlight"
	| "markupText"
	| "markupRectangle"
	| "markupEllipse"
	| "markupTriangle"
	| "markupCross"
	| "markupPhoto360"
	| "measureLinearDistance"
	| "measureRectArea"
	| "measureNonLinearDistance"
	| "measureIrregularArea"
	// | "markupStamp"
	// | "markupPhoto"
	| "setScale"
	| "moveBackground"
	| "setPin"
	| "tempMeasureLinearDistance"
	| "tempMeasureRectArea"
	| "tempMeasureNonLinearDistance"
	| "tempMeasureIrregularArea";
