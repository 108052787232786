import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import type {LibraryModel} from "../../../../../data/models/LibraryModel";
import {LibraryModelType} from "../../../../../data/models/LibraryModel";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {FileDropperWrapper} from "../../../../interaction/draganddrop/FileDropperWrapper";
import type {LibraryImage} from "../../../../../data/models/LibraryImage";
import CloudUpArrowIcon from "../../../icons/cloud-arrow-up.svg?react";
import DeleteIcon from "../../../icons/delete.svg?react";
import {NoResultSearchViewV5} from "../../table/NoResultSearchViewV5";
import {FileDropperReactV5, FileDropperStyled} from "../../../interaction/FileDropperReactV5";
import {colorPalette} from "../../../styles/colorPalette";
import {SVGIcon} from "../../../../widgets/button/SVGIcon";
import {SearchFieldV5} from "../../../input/search/SearchFieldV5";
import {ButtonV5} from "../../../button/ButtonV5";
import {SearchFieldStyled} from "../../../input/search/SearchField.styled";
import {IconButtonStyled} from "../../../interaction/IconButtonV5";
import {baseDistance, fontSize, radius} from "../../../styles/styles";
import {openKeywordsPopupForFile} from "../../../../modules/catalog/create/CatalogUtils";
import type {AppState} from "../../../../../data/state/AppState";
import {GoogleImagePanelV5} from "./GoogleImagePanelV5";

type LibraryItemType = "image" | "glb";

type LibraryItem = LibraryImage | LibraryModel;

interface ILibraryItemsProps {
	readonly libraryItems: LibraryItem[];
	readonly selectedLibraryItems: LibraryItem[];
	readonly type: LibraryItemType;
	readonly onFileInputChange: (file: File, keywords: string[]) => Promise<void>;
	readonly onItemsSelected: (items: LibraryItem[]) => void;
	readonly onSetItemActive: (item: LibraryItem) => Promise<void> | void;
	readonly onDeleteSelectedClick: () => Promise<void>;
	readonly appState?: AppState;
}

interface ILibraryItemsState {
	searchString: string;
	isGoogleImagePanelOpen: boolean;
}

@inject("appState")
@observer
export class LibraryItemsV5 extends React.Component<ILibraryItemsProps, ILibraryItemsState> {
	constructor(props: ILibraryItemsProps) {
		super(props);
		this.state = {
			searchString: "",
			isGoogleImagePanelOpen: false,
		};
	}

	private onSearchInput = (value: string) => this.setState({searchString: value});

	private onItemClick = (libraryItem: LibraryItem) => {
		if (KeyboardListener.isCtrlDown) {
			const newSelectedLibraryItems = [...this.props.selectedLibraryItems];
			const indexOfClicked = newSelectedLibraryItems.indexOf(libraryItem);

			if (indexOfClicked > -1) {
				newSelectedLibraryItems.splice(indexOfClicked, 1);
			} else {
				newSelectedLibraryItems.push(libraryItem);
			}
			this.props.onItemsSelected(newSelectedLibraryItems);
		} else {
			this.props.onItemsSelected([libraryItem]);
			this.props.onSetItemActive(libraryItem);
		}
	};

	private get label() {
		return this.props.type === "image" ? "image" : "model";
	}

	private get accept() {
		return this.props.type === "image" ? "image/*" : ".glb";
	}

	private onFileInputChange = (files: FileList) => {
		if (files.length > 0) {
			const file = files[0];

			return openKeywordsPopupForFile(file, this.label, this.props.onFileInputChange, this.props.appState.currentUIVersion);
		}
	};

	private onGoogleImageClick = () => {
		this.setState((prevState) => ({
			isGoogleImagePanelOpen: !prevState.isGoogleImagePanelOpen,
		}));
	};

	private onGoogleImagePanelClose = () => {
		this.setState({
			isGoogleImagePanelOpen: false,
		});
	};

	private onAddItemClick = () => {
		const input = document.createElement("input");

		input.type = "file";
		input.multiple = false;
		input.accept = this.accept;
		input.onchange = (event: InputEvent) => {
			const files = (event.currentTarget as HTMLInputElement).files;

			this.onFileInputChange(files);
		};

		input.click();
	};

	private getFilteredElements() {
		return StringUtils.filterByKeywords(this.props.libraryItems, this.state.searchString);
	}

	public override render() {
		const label = this.label;
		const items = this.getFilteredElements() as (LibraryItem | LibraryModel)[];
		const isTheStandard = (items[0] as LibraryModel)?.geometryType === LibraryModelType.STANDARD;
		const customItems = isTheStandard ? items.slice(1) : items;

		return (
			<LibraryItemsStyled $type={this.props.type}>
				<SearchAndButtonsStyled>
					<SearchFieldV5
						onInput={this.onSearchInput}
						value={this.state.searchString}
					/>
					{this.props.type === "glb" && (
						<>
							<ButtonV5
								onClick={this.onAddItemClick}
								title={`Add new ${label}`}
								label="Upload"
								type="tertiary"
							>
								<CloudUpArrowIcon />
							</ButtonV5>
							{this.props.selectedLibraryItems.length > 0 && (
								<ButtonV5
									onClick={this.props.onDeleteSelectedClick}
									title={`Delete selected ${label}s`}
									label="Delete"
									type="tertiary"
								>
									<DeleteIcon />
								</ButtonV5>
							)}
						</>
					)}
				</SearchAndButtonsStyled>
				{this.props.type === "image" && (
					<ButtonContainerStyled>
						<LeftButtonsStyled>
							<ButtonV5
								onClick={this.onAddItemClick}
								title={`Add new ${label}`}
								label="Upload"
								type="tertiary"
							>
								<CloudUpArrowIcon />
							</ButtonV5>
							{this.props.selectedLibraryItems.length > 0 && (
								<ButtonV5
									onClick={this.props.onDeleteSelectedClick}
									title={`Delete selected ${label}s`}
									label="Delete"
									type="tertiary"
								>
									<DeleteIcon />
								</ButtonV5>
							)}
						</LeftButtonsStyled>
						<div
							className={ReactUtils.cls("googleBtn btn hbox alignCenter", {selected: this.state.isGoogleImagePanelOpen === true})}
							onClick={this.onGoogleImageClick}
						>
							<SVGIcon icon="google" />
							Google Search
						</div>
					</ButtonContainerStyled>
				)}

				{this.state.isGoogleImagePanelOpen ? (
					<GoogleImagePanelV5
						defaultSearchValue={this.state.searchString || ""}
						onCloseClick={this.onGoogleImagePanelClose}
						addImage={this.props.onFileInputChange}
					/>
				) : (
					<>
						{this.props.libraryItems.length > 0 ? (
							items.length > 0 ? (
								<ItemContainerStyled $type={this.props.type}>
									<FileDropperWrapper
										accept={this.accept}
										onFileInputChange={this.onFileInputChange}
										multiple={false}
									>
										<>
											{customItems.map((libraryItem: LibraryImage | LibraryModel, index: number) => {
												const isSelected = this.props.selectedLibraryItems.includes(libraryItem);
												const style: React.CSSProperties = {
													backgroundImage: `url(${libraryItem.thumbnail})`,
												};

												return (
													<ThumbnailStyled
														key={index}
														style={style}
														className={ReactUtils.cls("thumbnail", {selected: isSelected})}
														onClick={() => this.onItemClick(libraryItem)}
													/>
												);
											})}
										</>
									</FileDropperWrapper>
								</ItemContainerStyled>
							) : (
								<div className="noResult">
									<NoResultSearchViewV5 searchString={this.state.searchString}></NoResultSearchViewV5>
								</div>
							)
						) : (
							<FileDropperReactV5
								onFileInputChange={this.onFileInputChange}
								purpose="Drag and drop or click here to add new images"
								accept={this.accept}
								multiple={false}
							/>
						)}
					</>
				)}
			</LibraryItemsStyled>
		);
	}
}

const LibraryItemsStyled = styled.div<{$type: "image" | "glb"}>`
	padding: ${baseDistance.md};
	padding-top: ${(props) => (props.$type === "image" ? "0" : baseDistance.md)};

	${FileDropperStyled} {
		height: 384px;
	}
`;

const SearchAndButtonsStyled = styled.div`
	width: 100%;
	display: flex;
	gap: ${baseDistance.md};
	margin-bottom: ${baseDistance.md};

	${SearchFieldStyled} {
		width: 100%;
	}
`;

const ButtonContainerStyled = styled.div`
	display: flex;
	justify-content: space-between;
	margin: ${baseDistance.md} 0;

	.googleBtn {
		padding: ${baseDistance.sm};
		font-size: ${fontSize.md};
		color: ${colorPalette.primary.c500Primary};
		cursor: pointer;

		svg {
			width: 12px;
			height: 12px;
			margin-right: ${baseDistance.sm};
		}

		&.selected {
			background-color: ${colorPalette.primary.c200Light};
			border-radius: ${radius.sm};
		}

		&.selected:hover {
			background-color: ${colorPalette.primary.c200Light};
			border-radius: ${radius.sm};
		}
	}

	.googleBtn:hover {
		background-color: ${colorPalette.gray.c200Light};
		border-radius: ${radius.sm};
	}
`;

const LeftButtonsStyled = styled.div`
	display: flex;
`;

const ItemContainerStyled = styled.div<{$type: LibraryItemType}>`
	overflow-x: hidden;
	overflow-y: scroll;
	display: grid;
	grid-template-columns: repeat(4, 109px);
	grid-gap: ${baseDistance.sm};
	max-height: ${(props) => (props.$type === "glb" ? "495px" : "382px")};
`;

const ThumbnailStyled = styled.div`
	width: 109px;
	height: 109px;
	background-color: ${colorPalette.gray.c100};
	position: relative;
	border: 4px solid ${colorPalette.white};

	${IconButtonStyled} {
		visibility: hidden;
		position: absolute;
		right: 0;
		top: 0;
	}

	&:hover {
		${IconButtonStyled} {
			visibility: visible;
		}
	}

	&.selected {
		width: 109px;
		height: 109px;
		border: 4px solid ${colorPalette.primary.c500Primary};
	}
`;
