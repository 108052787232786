import {useState} from "react";
import styled from "styled-components";
import type {ITextInputProps} from "../../../widgets/input/text/TextInput";
import {TextInputV5} from "../../details/datatypes/TextInputV5";
import {PillsStyle} from "../../sharing/SharingPanel.styled";
import {Permission} from "../../../../generated/api/base";
import {colorPalette} from "../../styles/colorPalette";
import {baseDistance, fontSize, fontWeight, radius} from "../../styles/styles";
import {SelectInputV5} from "../select/SelectInputV5";
import {HorizontalAlignment} from "../../../../utils/dom/DomUtils";
import CloseIcon from "../../icons/xmark.svg?react";
import type {SelectedItem} from "../../sharing/SharingUtils";

interface ISearchFieldV5Props extends Partial<ITextInputProps> {
	readonly value: string;
	readonly placeholder: string;
	readonly selectedItems: SelectedItem[];
	readonly isDropdown?: boolean;
	readonly isSharing?: boolean;
	readonly getPermissionType?: (permission: Permission) => void;
	readonly removeSelectedItem?: (items: SelectedItem[]) => void;
}

export const SelectSearchFieldV5 = (props: ISearchFieldV5Props) => {
	const [selectedPermissionType, setSelectedPermissionType] = useState<Permission>(Permission.View);
	const {placeholder = "Find", selectedItems, isDropdown, removeSelectedItem, isSharing} = props;

	const permissionOptions = [
		{value: Permission.View, label: "Can View"},
		{value: Permission.Update, label: "Can Edit"},
	];

	const selectedPermissionLabel = permissionOptions.find((option) => option.value === selectedPermissionType)?.label || "";

	const handleRemoveSelectedItem = (id: string) => {
		removeSelectedItem(selectedItems.filter((item) => item.id !== id));
	};

	const onSetPermission = (selectedOption: {value: Permission; label: string}) => {
		setSelectedPermissionType(selectedOption.value);
		props.getPermissionType(selectedOption.value);
	};

	const selectedOption = permissionOptions.find((option) => option.value === selectedPermissionType);

	return (
		<SelectSearchFieldStyled
			className="SearchField"
			$multiselectDropdown={true}
			$isSharing={isSharing}
		>
			<div className="inputField">
				{selectedItems.map((item) => (
					<PillsStyle
						className="selectedItem"
						key={item.id}
					>
						<span>{item.itemName}</span>
						<CloseIcon onClick={() => handleRemoveSelectedItem(item.id)} />
					</PillsStyle>
				))}
				<div className="list-search">
					<TextInputV5
						{...props}
						placeholder={placeholder || "Find"}
					/>
				</div>
			</div>
			{!isDropdown ? (
				""
			) : (
				<div className="permissionSelect">
					<SelectInputV5
						options={permissionOptions}
						render={(option) => option.label}
						selected={selectedOption}
						onChange={onSetPermission}
						placeholder={selectedPermissionLabel}
						className="permissionSelectInput"
						dropdownFixedWidth="128px"
						optionFontSize="14px"
						horizontalAlignment={HorizontalAlignment.right}
					/>
				</div>
			)}
		</SelectSearchFieldStyled>
	);
};

const SelectSearchFieldStyled = styled.div<{$multiselectDropdown?: boolean; $isSharing: boolean}>`
	width: ${(props) => (props.$isSharing ? "200px" : "448px")};
	border-radius: ${radius.sm};
	background-color: ${colorPalette.white};
	border: 1px solid ${colorPalette.gray.c300};
	align-items: center;
	position: relative;
	font-size: ${fontSize.md};
	grid-template-columns: ${(props) => props.$isSharing && "360px 85px"};
	display: grid;
	grid-gap: 10px;

	.inputField {
		display: inline-flex;
		flex-wrap: wrap;
		align-items: center;

		.list-search {
			min-width: 10px;
			display: flex;
			flex: 1;
		}
	}

	input {
		border: none;
		background-color: transparent;
		font-weight: ${fontWeight.normal};
		font-size: ${fontSize.md};
		position: relative;
		padding: 0 0 0 ${baseDistance.sm};
		width: ${(props) => (props.$isSharing ? "350px" : "100%")};
		min-width: 10px;
		height: 30px;

		&:focus-visible {
			outline: none;
		}

		&::placeholder {
			color: ${colorPalette.gray.c700Dark};
			opacity: 1;
		}
	}

	.permissionSelect {
		height: 30px;
	}

	svg {
		width: 32px;
		height: 16px;
		min-width: 16px;
		min-height: 16px;
		left: 8px;

		&.cancel {
			left: auto;
			right: 8px;
			cursor: pointer;
		}

		path {
			width: 12px;
			height: 12px;
		}
	}

	.saveDropdown {
		width: 90px;

		svg {
			margin: 0;
			width: 16px;
		}
	}

	.permissionSelectInput {
		border: none;
		font-size: ${fontSize.sm};
		width: 83px;
		height: 24px;
		gap: 0;
		position: absolute;
		right: 4px;
		margin: ${baseDistance.xs} 0;

		svg {
			width: 5px;
		}
	}
`;

export const SelectedOptionStyled = styled.div`
	top: 0px;
	left: 15px;
`;
