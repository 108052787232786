import {LineGeometry} from "three/examples/jsm/lines/LineGeometry.js";
import {LineMaterial} from "three/examples/jsm/lines/LineMaterial.js";
import {Line2 as Line} from "three/examples/jsm/lines/Line2.js";
import type {BufferGeometry, Object3D} from "three";
import {Vector2, Shape, ShapeGeometry, Mesh, Color as ThreeColor} from "three";
import {LineSegmentsGeometry} from "three/examples/jsm/lines/LineSegmentsGeometry.js";
import {LineSegments2 as LineSegments} from "three/examples/jsm/lines/LineSegments2.js";
import {EditableSpaceItem} from "../../EditableSpaceItem";
import type {SpaceViewRenderer} from "../../../renderers/SpaceViewRenderer";
import {BasicMaterial} from "../../../materials/BasicMaterial";
import {HighlightMaterial} from "../../../materials/HighlightMaterial";
import type {SpaceItemType} from "../../../managers/spaceitems/ItemManager";
import type {ColorRuleCategory} from "../../../../ui/viewbar/ColorRules";
import {MeasureType, getCorrectionMultiplierForSpaceItem} from "../../../renderers/SpaceViewRendererUtils";
import {Constants, type ExtendedDistanceUnitName} from "../../../Constants";
import {getTextWorldPosition} from "../../../managers/MSDF/TextUtils";
import type {IObjectWithText} from "../../../managers/MSDF/TextUtils";
import type {IObjectWithRotationHandler} from "../../../managers/spaceitems/icons/RotationIconTypes";
import {LeaderLine} from "../../LeaderLine";
import {KeyboardListener} from "../../../../../../../../utils/interaction/key/KeyboardListener";
import {MathUtils} from "../../../../../../../../utils/math/MathUtils";
import {NotificationType} from "../../../../../../../notification/Notification";
import {Markup} from "../../../../../../../../data/models/Markup";
import type {IMarkupMinimumData, IMarkupSettingsData} from "../../../../../../../../data/models/Markup";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../../../../utils/dom/DomUtils";
import {notify} from "../../../../../../../../utils/Notify";
import {THREEUtils} from "../../../../../../../../utils/THREEUtils";
import {MarkupType, XyiconFeature} from "../../../../../../../../generated/api/base";
import {ColorUtils} from "../../../../../../../../utils/ColorUtils";
import type {Color, PointDouble} from "../../../../../../../../generated/api/base";
import {ArrayUtils} from "../../../../../../../../utils/data/array/ArrayUtils";
import {VectorUtils} from "../../../../../../../../utils/VectorUtils";
import {
	MarkupsWithChangeableLineThickness,
	MarkupsWithCustomText,
	MarkupsWithCustomizableFillOpacity,
	MarkupsWithTextOffset,
} from "../MarkupStaticElements";
import type {SupportedFontName} from "../../../../../../../../data/state/AppStateTypes";
import {Rectangle} from "./Rectangle";
import {getDefaultTargetForMarkupCallout, isMarkupFilteredOutByColor, MarkupUtils} from "./MarkupUtils";
import type {IMarkupConfig} from "./MarkupUtils";
import type {ICornerLetter} from "./MarkupAB";
import {InvisiblePart} from "./InvisiblePart";

export const MarkupTextRotationName = "MarkupTextRotation";

/**
 * Represents a markup in the 3D world
 */
export abstract class Markup3D extends EditableSpaceItem implements IObjectWithText {
	private _tempId: string = MathUtils.getNewRandomGUID(); // used when the object is being created, and the server is not aware of it yet
	private _rectangle: Rectangle;
	private _leaderLine: LeaderLine;

	public tempSettings: IMarkupSettingsData = {}; // used when the object is being created, and the server is not aware of it yet
	protected _color: number;
	protected _lineGeometry: LineGeometry | LineSegmentsGeometry | BufferGeometry;
	protected _line: Line | LineSegments;
	protected _textBackground: Rectangle;

	protected _shape: Shape;
	protected _shapeGeometry: ShapeGeometry;
	protected _mesh: Mesh;

	protected _selectionPart: InvisiblePart;
	protected _textArea: InvisiblePart;

	protected _modelData: Markup;
	protected _spaceItemType: SpaceItemType = "markup";
	protected _config: IMarkupConfig;

	public sizeOfGeneratedText: PointDouble = null; // is calculated in TextGroupManager
	public isTextInHtmlEditMode: boolean = false;
	public readonly textInstanceIds: number[] = [];

	constructor(spaceViewRenderer: SpaceViewRenderer, config: IMarkupConfig) {
		super(spaceViewRenderer);

		this._config = config;
		this._config.measureType = this._config.measureType ?? MeasureType.NONE;
		this._color = parseInt(`0x${config.strokeColor}`);
		this._lineMaterial = new LineMaterial({
			color: this._color,
			linewidth: config.lineWidth ?? MarkupUtils.defaultLineThickness,
			resolution: new Vector2(this._spaceViewRenderer.canvas.width, this._spaceViewRenderer.canvas.height),
		});
		const isDashed = !!config.dashSize;

		if (isDashed) {
			this._lineMaterial.dashed = isDashed;
			this._lineMaterial.dashSize = config.dashSize;
			this._lineMaterial.gapSize = config.gapSize;
			// For explanation, see this: https://threejs.org/examples/webgl_lines_fat.html
			this._lineMaterial.defines.USE_DASH = "";
		}

		this._lineGeometry = config.useSegments ? new LineSegmentsGeometry() : new LineGeometry();
		this._line = config.useSegments
			? new LineSegments(this._lineGeometry as LineSegmentsGeometry, this._lineMaterial)
			: new Line(this._lineGeometry as LineGeometry, this._lineMaterial);

		this.init3DObjects(config);
	}

	protected get _markupManager() {
		return this._spaceViewRenderer.markupManager;
	}

	protected get _correctionMultiplier() {
		return getCorrectionMultiplierForSpaceItem(this._spaceViewRenderer, this._modelData);
	}

	protected get unitName(): ExtendedDistanceUnitName {
		if (this._config.isTemp) {
			return this._spaceViewRenderer.measureToolUnit;
		} else {
			const spaceMaybe = this._modelData?.space ? this._modelData.space : this._spaceViewRenderer.space;

			return spaceMaybe?.type?.settings?.unitOfMeasure ?? "foot&inch";
		}
	}

	public override onLayerSettingsModified() {
		const selectedView = this._spaceViewRenderer.actions.getSelectedView(XyiconFeature.SpaceEditor);
		const {layers} = selectedView.spaceEditorViewSettings;
		const hiddenMarkupColors = new Set<string>(layers?.hiddenMarkupColors || []);

		// Eg.: setscaleline doesn't have layersettings, as its type is null
		if (this.type != null) {
			const layerSettings = layers?.markup.included[this.type];

			if (layerSettings || hiddenMarkupColors.size > 0) {
				this.setVisibility(!layerSettings.isHidden && !isMarkupFilteredOutByColor(hiddenMarkupColors, this));
				this.setPositionLocked(layerSettings.isPositionLocked);

				this._spaceViewRenderer.needsRender = true;
			}
		}
	}

	// If the layer of this type is hidden, calling this function will show the layer with a notification
	public showLayerWithNotification() {
		const layersMaybe = this._spaceViewRenderer.actions.getSelectedView(XyiconFeature.SpaceEditor).spaceEditorViewSettings?.layers;
		const markupLayerSettings = layersMaybe?.markup.included[this.type];
		const isFilteredOutByColor = layersMaybe?.hiddenMarkupColors.includes(this.color);

		if (markupLayerSettings?.isHidden || isFilteredOutByColor) {
			markupLayerSettings.isHidden = false;
			if (layersMaybe?.hiddenMarkupColors) {
				ArrayUtils.removeMutable(layersMaybe.hiddenMarkupColors, this.color);
			}

			this._markupManager.onLayerSettingsModified();
			notify(this._spaceViewRenderer.transport.appState.app.notificationContainer, {
				title: "Some of your hidden markups were made visible",
				description: "We changed the markup layer to visible so that you can see the markup on the space.",
				lifeTime: 15000,
				type: NotificationType.Message,
			});
		}
	}

	private init3DObjects(config: IMarkupConfig) {
		if (this.type === MarkupType.Photo360) {
			return;
		}

		if (!config.isHighLight) {
			THREEUtils.add(this._group, this._line);
		}

		const fillOpacity = config.fillOpacity ?? MarkupUtils.getDefaultFillOpacityForType(this.type);

		if (config.fill) {
			this._shape = new Shape();
			this._shapeGeometry = new ShapeGeometry(this._shape);
			this._meshMaterial = new BasicMaterial(this._color, fillOpacity, true);
			this._mesh = new Mesh(this._shapeGeometry, this._meshMaterial);
			THREEUtils.add(this._group, this._mesh);
		}

		if (MarkupsWithCustomText.includes(this.type)) {
			this._textArea = new InvisiblePart(this._spaceViewRenderer, this._intersectables, this, false);
		}
		const isTextBox = this.type === MarkupType.TextBox;

		this._selectionPart = isTextBox ? this._textArea : new InvisiblePart(this._spaceViewRenderer, this._intersectables, this, true);

		THREEUtils.add(this._group, this._intersectables);
		THREEUtils.add(this._markupManager.container, this._group);

		this._group.visible = false;
	}

	protected updateMesh(vectors: Vector2[]) {
		const area = THREEUtils.calculateArea(vectors);

		if (area === 0) {
			// we need to have a little bit of offset to prevent the following three.js warning:
			// "faceless geometries are not supported"
			const offset = this._correctionMultiplier * 1;

			vectors[0].x += offset;
			vectors[0].y += offset;
		}
		this._shape.curves.length = 0;
		this._shape.setFromPoints(vectors);
		this._shapeGeometry.dispose();
		this._shapeGeometry = new ShapeGeometry(this._shape);
		this._mesh.geometry = this._shapeGeometry;
	}

	protected override destroyCallback(notifyServer: boolean = false, removeFromCollections: boolean = true) {
		super.destroyCallback(notifyServer, removeFromCollections);
		this._selectionPart?.dispose();
		if (this._textArea !== this._selectionPart) {
			this._textArea?.dispose();
		}
		this._textBackground?.dispose();
		this._lineGeometry?.dispose();
		this._lineMaterial?.dispose();
		this._mesh?.geometry?.dispose();
		(this._mesh?.material as BasicMaterial)?.dispose();
	}

	public onCanvasResize() {
		(this._lineMaterial as LineMaterial).resolution.set(this._spaceViewRenderer.canvas.width, this._spaceViewRenderer.canvas.height);
	}

	public abstract override updateGeometry(pathCoords: PointDouble[], isLocal?: boolean, isAltDown?: boolean, fixedPoint?: ICornerLetter): void;

	protected override onGeometryUpdated = () => {
		this.updateTextBackground();
		this._spaceViewRenderer.needsRender = true;
	};

	protected setColor(color: number, intensity: number, save: boolean = false) {
		(this._lineMaterial as LineMaterial).color.set(color);
		(this._lineMaterial as LineMaterial).color.multiplyScalar(intensity);

		if (this._meshMaterial instanceof HighlightMaterial) {
			const colorObject = new ThreeColor(color);

			colorObject.multiplyScalar(intensity);
			this._meshMaterial.setColor(colorObject.getHex());
		} else if (this._meshMaterial instanceof BasicMaterial) {
			this._meshMaterial.setColor(color);
		}

		if (save) {
			this._color = color;
		}

		this._textBackground?.updateColor(color, color);

		this._spaceViewRenderer.needsRender = true;
	}

	protected override setGrayScaled(value: boolean) {
		// We can't exclude markups, so we can't grayscale them either
	}

	private updateLeaderLine() {
		this._leaderLine?.destroy();
		const {textOffset} = this;
		const markupCenterToTextCenterInPx = VectorUtils.lengthOf([textOffset.x, textOffset.y]) / this._correctionMultiplier;

		if (markupCenterToTextCenterInPx > 250) {
			this._leaderLine = new LeaderLine(
				this._spaceViewRenderer,
				this._group,
				textOffset.x,
				textOffset.y,
				this._group,
				Constants.COLORS.SELECTION_BOX,
				true,
			);
		}
	}

	public override select(updateSelectionBox: boolean = true): boolean {
		this.updateLeaderLine();

		return super.select(updateSelectionBox);
	}

	public override deselect(): boolean {
		this._leaderLine?.destroy();
		this._leaderLine = null;

		return super.deselect();
	}

	public onGrabbableCornerPointerMove(deltaX: number, deltaY: number) {
		if (this._isInEditMode) {
			const newX = this._savedVertex.x + deltaX;
			const newY = this._savedVertex.y + deltaY;

			const index = this._indicesOfSelectedVertices[0];

			const isTextBeingGrabbed = index === this.getGrabbableVertices().length - 1;

			if (this.isTextOffsetHandlerVisible && isTextBeingGrabbed) {
				const {position} = this;
				const textSize = this.sizeOfGeneratedText;
				const fullOrientation = this.orientation + this.textOrientation;

				const newTopLeftOfText = {
					x: newX,
					y: newY,
				};
				const unrotatedCornerToCenter = {
					x: textSize.x / 2,
					y: -textSize.y / 2,
				};
				const rotatedCornerToCenter = THREEUtils.getRotatedVertex(unrotatedCornerToCenter, fullOrientation, {x: 0, y: 0});
				const newCenterOfText = THREEUtils.addVec2ToVec2(newTopLeftOfText, rotatedCornerToCenter);
				const newRotatedOffset = THREEUtils.subVec2fromVec2(newCenterOfText, position);
				const unrotatedOffset = THREEUtils.getRotatedVertex(newRotatedOffset, -this.orientation, {x: 0, y: 0});

				this._modelData.setTextOffset({x: unrotatedOffset.x, y: unrotatedOffset.y});
				this.setPosOfGrabbableCorner(index, newX, newY);
				this._rectangle?.updatePosition(this.getTextWorldPosition());
			} else {
				this._worldGeometryData[index].x = newX;
				this._worldGeometryData[index].y = newY;

				if (this.type === MarkupType.TextBox || this.type === MarkupType.Callout) {
					if (this.sizeOfGeneratedText) {
						if (this.type === MarkupType.TextBox) {
							// Shrink the textbox to the size of the generated text
							const unrotatedGeometryData = this.unrotatedGeometryData;
							const aToGrabbed = unrotatedGeometryData[index].y - unrotatedGeometryData[(index + 1) % unrotatedGeometryData.length].y;
							const currentVerticalHeightOfMarkup = Math.abs(aToGrabbed);
							const currentVerticalHeightOfText = this.sizeOfGeneratedText.y;
							const difference = currentVerticalHeightOfText - currentVerticalHeightOfMarkup;

							const unrotatedDirection = {x: 0, y: Math.sign(aToGrabbed)};
							const direction = THREEUtils.getRotatedVertex(unrotatedDirection, this._lastSavedOrientation, {x: 0, y: 0});
							const offset = THREEUtils.multiplyByScalar(direction, difference);

							this._worldGeometryData[index] = THREEUtils.addVec2ToVec2(this._worldGeometryData[index], offset);
						}

						this.makeSizeFixed(); // overridden in "MarkupTextBox", and MarkupCallout
					}
				}

				this.updateGeometry(this._worldGeometryData, false, KeyboardListener.isAltDown, index === 0 ? "b" : "a");

				if (this._worldGeometryData.length !== this._grabbableCorners.length) {
					// MarkupAB2D
					this.updateGrabbableCorners(undefined, false);
				} else {
					this.setPositionOfVertexAtIndex(index, this._worldGeometryData[index].x, this._worldGeometryData[index].y);
				}
			}

			this._spaceViewRenderer.spaceItemController.rotationIconManager.updateTransformations();
		}
	}

	public override onGrabbableCornerPointerUp() {
		this.resetFillColorOfGrabbableCorners();
	}

	public get isTextOffsetHandlerVisible(): boolean {
		return this._isInEditMode && MarkupsWithTextOffset.includes(this.type) && this.sizeOfGeneratedText?.x > 0 && this.sizeOfGeneratedText?.y > 0;
	}

	public override switchEditMode(enabled: boolean, updateBackendOnFinish: boolean): boolean {
		const hasChanged = super.switchEditMode(enabled, updateBackendOnFinish);

		if (hasChanged) {
			if (this._isInEditMode && this.isTextOffsetHandlerVisible) {
				this._leaderLine?.destroy();

				let textWorldPos = this.getTextWorldPosition();
				let orientation = this.orientation + this.textOrientation;
				let textSize = this.sizeOfGeneratedText;

				const object: IObjectWithRotationHandler = {
					position: textWorldPos,
					orientation,
					typeName: MarkupTextRotationName,
					getRotationIconObject: (target: Object3D) => {
						textWorldPos = this.getTextWorldPosition();
						orientation = this.orientation + this.textOrientation;
						object.position = textWorldPos;
						object.orientation = this.orientation + this.textOrientation;
						const correctionMultiplier = this._correctionMultiplier;
						const cameraZoomLevel = this._spaceViewRenderer.toolManager.cameraControls.cameraZoomValue;
						const worldSizeOfRotationHandler = {
							x: (Constants.SIZE.ROTATION_HANDLER_PX * correctionMultiplier) / cameraZoomLevel,
							y: (Constants.SIZE.ROTATION_HANDLER_PX * correctionMultiplier) / cameraZoomLevel,
						};

						const unrotatedPos: PointDouble = {
							x: textWorldPos.x,
							y: textWorldPos.y + (textSize.y + worldSizeOfRotationHandler.y) / 2,
						};

						const rotatedPos = THREEUtils.getRotatedVertex(unrotatedPos, orientation, textWorldPos);

						target.scale.set(worldSizeOfRotationHandler.x, worldSizeOfRotationHandler.y, 1);
						target.position.set(rotatedPos.x, rotatedPos.y, 0);
						target.rotation.set(0, 0, orientation);

						target.updateMatrix();

						return target;
					},
				};
				const material = this._spaceViewRenderer.textureManager.miscTextures.rotation.material;

				this._spaceViewRenderer.spaceItemController.rotationIconManager.update({material, objects: [object]});
			} else {
				this.updateLeaderLine();
				this._rectangle?.dispose();
				this._rectangle = null;
				this._spaceViewRenderer.spaceItemController.rotationIconManager.clear();
			}
		}

		return hasChanged;
	}

	private getTextGrabHandlerPosition(): PointDouble {
		const centerOfText = this.getTextWorldPosition();

		if (centerOfText) {
			const textSize = this.sizeOfGeneratedText;
			const fullOrientation = this.orientation + this.textOrientation;

			const unrotatedCenterToCorner: PointDouble = {
				x: -textSize.x / 2,
				y: textSize.y / 2,
			};

			const rotatedCenterToCorner = THREEUtils.getRotatedVertex(unrotatedCenterToCorner, fullOrientation, {x: 0, y: 0});

			return THREEUtils.addVec2ToVec2(centerOfText, rotatedCenterToCorner);
		}

		return null;
	}

	private getGrabbableVertices(): PointDouble[] {
		const grabbableVertices = [...this._worldGeometryData];

		if (this.isTextOffsetHandlerVisible) {
			grabbableVertices.push(this.getTextGrabHandlerPosition());
		}

		return grabbableVertices;
	}

	protected override updateGrabbableCorners(vertices: PointDouble[] = this.getGrabbableVertices(), recreateThem: boolean = true) {
		if (this.isTextOffsetHandlerVisible) {
			if (!this._rectangle) {
				this._rectangle = new Rectangle(
					this.group,
					this.getTextWorldPosition(),
					this.sizeOfGeneratedText,
					this.orientation + this.textOrientation,
					Constants.COLORS.SELECTION_BOX,
				);
			} else {
				this._rectangle.update(this.getTextWorldPosition(), this.sizeOfGeneratedText, this.orientation + this.textOrientation);
			}
		} else {
			this._rectangle?.dispose();
			this._rectangle = null;
		}

		return super.updateGrabbableCorners(vertices, recreateThem);
	}

	public onTextRotated() {
		this.updateGrabbableCorners(undefined, false);
	}

	public override updateByModel(model: IMarkupMinimumData, keepEditMode: boolean = true) {
		if (this._meshMaterial && MarkupsWithCustomizableFillOpacity.includes(this.type)) {
			this._meshMaterial.setOpacity(1 - model.fillTransparency);
		}

		if (this._lineMaterial && MarkupsWithChangeableLineThickness.includes(this.type)) {
			this._lineMaterial.linewidth = model.lineThickness;
		}

		this._color = parseInt(`0x${model.color}`);

		super.updateByModel(model, keepEditMode);
	}

	public makeSizeFixed(): void {
		return;
	}

	protected setFormattingColor(colorRuleCategory: ColorRuleCategory, colorHex: string) {
		// We don't support this
	}

	protected recreateTextGroups(additionalObjectsWithTexts: IObjectWithText[] = [this]) {
		if (this._spaceViewRenderer.spaceItemController.markupTextManager) {
			const items = [...(this.itemManager.items.array as Markup3D[] as IObjectWithText[])];

			for (const additionalObjectWithText of additionalObjectsWithTexts) {
				if (items.every((item) => item !== additionalObjectWithText && item.id !== additionalObjectWithText.id)) {
					items.push(additionalObjectWithText);
				}
			}
			this._spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry(items);
		}
	}

	protected override getDefaultDataToCalculateBBoxFrom(): PointDouble[] {
		// this.isTextOffsetHandlerVisible returns with true even if it's not actually visible yet, but it will be in the next frame.
		// so we need to check whether the grabbable corners have already been created as well
		if (this.isTextOffsetHandlerVisible && this._grabbableCorners.length > 0) {
			return this._grabbableCorners.slice(0, -1).map((c) => c.mesh.position);
		}

		return super.getDefaultDataToCalculateBBoxFrom();
	}

	public get4CornersAsWorldVertices() {
		const unrotatedGeometryData = this.unrotatedGeometryData;

		const {min, max} = THREEUtils.calculateBox(unrotatedGeometryData);
		const extendedGeometryData = THREEUtils.getGeometryDataFromBox(min, max);
		return THREEUtils.getRotatedVertices(extendedGeometryData, this._lastSavedOrientation);
	}

	public get textOffset() {
		return this._modelData?.textOffset ?? {x: 0, y: 0};
	}

	public get textOrientation() {
		return this._modelData?.textOrientation ?? 0;
	}

	protected get _lineThickness() {
		return this._lineMaterial?.linewidth ?? this._modelData?.lineThickness ?? MarkupUtils.defaultLineThickness;
	}

	protected get _arrowHeadSize() {
		let ret = MarkupUtils.defaultArrowHeadSize;

		if (this._modelData?.settings) {
			const settingsData = this._modelData.settings;

			ret = settingsData?.arrowHeadSize;
		} else if (MathUtils.isValidNumber(this._modelData?.arrowHeadSize)) {
			ret = this._modelData.arrowHeadSize;
		}

		return ret ?? MarkupUtils.defaultArrowHeadSize;
	}

	protected get _isDashed() {
		return this._config.dashSize != null;
	}

	public getTextWorldPosition(): PointDouble | null {
		return getTextWorldPosition(this);
	}

	public abstract override get type(): MarkupType;

	public get textBackground() {
		return this._textBackground;
	}

	public get dimensionMultiplier() {
		if (this.isTemp) {
			const cameraZoomLevel = this._spaceViewRenderer.toolManager.cameraControls.cameraZoomValue;

			return 1 / cameraZoomLevel;
		}

		return 1;
	}

	private updateTextBackground() {
		const textSize = this.sizeOfGeneratedText;

		if (textSize && this.textContent && this.isTemp) {
			const backgroundPos = this._isInEditMode || !this._modelData ? this.position : {x: 0, y: 0};
			const paddingX = 30;
			const paddingY = 10;
			const correctionMultiplier = this._correctionMultiplier;

			const backgroundSize = {
				x: textSize.x + paddingX * correctionMultiplier,
				y: textSize.y + paddingY * correctionMultiplier,
			};

			if (!this._textBackground) {
				this._textBackground = new Rectangle(this._group, backgroundPos, backgroundSize, this.orientation, this._color, this._color);
			} else {
				this._textBackground.update(backgroundPos, backgroundSize, this.orientation);
			}
		}
	}

	protected override onCameraZoomChangeCallback = () => {
		if (this.isTemp && this.textContent) {
			this._spaceViewRenderer.spaceItemController.markupTextManager.updateOnNextFrame = true;
			this.updateTextBackground();
		}
	};

	public override updateCenter(calledByStopRotationAroundItsOwnPivot: boolean = false) {
		return super.updateCenter(calledByStopRotationAroundItsOwnPivot);
	}

	public override addModelData(modelData: IMarkupMinimumData): void {
		super.addModelData(modelData);
		this.updateTextBackground();
	}

	public get isTemp() {
		return this._config?.isTemp ?? this._modelData?.isTemp;
	}

	public get doesSupportText() {
		return MarkupsWithCustomText.includes(this.type);
	}

	public get mesh() {
		return this._mesh;
	}

	public get isValid() {
		for (let i = 0; i < this._worldGeometryData?.length - 1; ++i) {
			const vertex = this._worldGeometryData[i];
			const nextVertex = this._worldGeometryData[i + 1];

			if (vertex.x !== nextVertex.x || vertex.y !== nextVertex.y) {
				return true;
			}
		}
		return false;
	}

	public get position() {
		if (this._isInEditMode || !this._modelData) {
			if (!this.boundingBox) {
				this.updateBoundingBox();
			}
			return {
				...THREEUtils.getCenterOfBoundingBox(this.boundingBox),
				z: this._group.position.z,
			};
		} else {
			return {
				x: this._group.position.x,
				y: this._group.position.y,
				z: this._group.position.z,
			};
		}
	}

	public override get id() {
		return this._modelData?.id || this._tempId;
	}

	public get orientation() {
		return this._isInEditMode ? this._lastSavedOrientation : this._group.rotation.z;
	}

	public get text() {
		return [
			{
				content: this.textContent,
			},
		];
	}

	public get textContent() {
		return this._modelData?.text.content ?? "";
	}

	public get textHAlign() {
		return this._modelData?.text?.horizontalAlignment ?? Markup.defaultText.horizontalAlignment;
	}

	public get textVAlign() {
		return this._modelData?.text?.verticalAlignment ?? Markup.defaultText.verticalAlignment;
	}

	public get fontFamily(): SupportedFontName {
		return (this._modelData?.text?.fontFamily || Markup.defaultText.fontFamily) as SupportedFontName;
	}

	public get fontSize() {
		return this._modelData?.text?.fontSize ?? Markup.defaultText.fontSize;
	}

	public get fontColor(): Color {
		return this.isTemp ? {hex: "FFFFFF", transparency: 0} : ((this._modelData?.text?.fontColor || Markup.defaultText.fontColor) as Color);
	}

	public get isBold() {
		return this.isTemp ? true : (this._modelData?.text?.isBold ?? Markup.defaultText.isBold);
	}

	public get isItalic() {
		return this._modelData?.text?.isItalic ?? Markup.defaultText.isItalic;
	}

	public get isUnderlined() {
		return this._modelData?.text?.isUnderlined ?? Markup.defaultText.isUnderlined;
	}

	public get color() {
		return ColorUtils.getHexStringFromNumber(this._color, false);
	}

	public get target() {
		return this._modelData?.target || getDefaultTargetForMarkupCallout(this._worldGeometryData, this.fontFamily, this._spaceViewRenderer);
	}

	public get config() {
		return this._config;
	}

	public override get data(): IMarkupMinimumData {
		const markupText = this._modelData?.text || Markup.defaultText;

		if (this.type === MarkupType.TextBox) {
			if (!this._modelData?.text) {
				markupText.fontColor = {
					hex: this.itemManager.markupColor,
					transparency: 0,
				};
				markupText.horizontalAlignment = HorizontalAlignment.left;
				markupText.verticalAlignment = VerticalAlignment.top;
			}
		}

		return {
			id: this._modelData?.id,
			type: this.type,
			geometryData: this._worldGeometryData,
			orientation: this.orientation,
			color: this.color,
			lineThickness: this._lineThickness,
			text: markupText,
			fillTransparency:
				this._modelData?.fillTransparency ?? 1 - (this._meshMaterial?.opacityValue ?? MarkupUtils.getDefaultFillOpacityForType(this.type)),
			settings: this._modelData?.settings ?? this.tempSettings ?? {},
		};
	}

	public get itemManager() {
		return this._markupManager;
	}
}
