import type {InstancedBufferAttribute} from "three";
import {Color} from "three";
import type {PointDouble} from "../../../../../../../generated/api/base";
import {MarkupType} from "../../../../../../../generated/api/base";
import {MathUtils} from "../../../../../../../utils/math/MathUtils";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import {Constants} from "../../Constants";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import type {SpaceItemOpacityValue} from "../SpaceItem";
import {SpaceItem} from "../SpaceItem";
import {Markup3D} from "./abstract/Markup3D";
import type {IMarkupConfig} from "./abstract/MarkupUtils";

export class MarkupPhoto360 extends Markup3D {
	private _instanceId: number;
	private _radius: number = 0;

	constructor(spaceViewRenderer: SpaceViewRenderer, config: IMarkupConfig, instanceId: number) {
		super(spaceViewRenderer, config);
		this._instanceId = instanceId;
		this._spaceViewRenderer.markupManager.markupPhoto360InstancedMeshManager.addMarkup360Items([this]);
	}

	public override updateGeometry(geometryData: PointDouble[]) {
		if (geometryData.length !== 1) {
			throw "Error! This markup should have exactly 1 coordinate!";
		}
		this._radius = (Constants.SIZE.XYICON / 2) * this._spaceViewRenderer.correctionMultiplier.current;
		this._worldGeometryData = geometryData;

		this.updateBoundingBox();
		this._group.position.setX(this._worldGeometryData[0].x);
		this._group.position.setY(this._worldGeometryData[0].y);
		this.updateInstancedMeshRelatedData();
	}

	private updateInstancedMeshRelatedData() {
		THREEUtils.updateMatrices(this._group);
		this._instancedMesh.setMatrixAt(this._instanceId, this._group.matrixWorld);

		this.onGeometryUpdated();
	}

	public override setOpacity(opacityMultiplicator: SpaceItemOpacityValue): void {
		super.setOpacity(opacityMultiplicator);
		THREEUtils.setAttributeX(this._instancedMesh.geometry.attributes.opacity, this._instanceId, this.opacity);

		this._spaceViewRenderer.needsRender = true;
	}

	public override mouseOver() {
		if (!this._isSelected && !this.isDestroyed) {
			this.setColor(this._color, SpaceItem.COLOR_INTENSITY.HOVERED);
		}
	}

	public override mouseOut() {
		if (!this._isSelected && !this.isDestroyed) {
			this.setColor(this._color, SpaceItem.COLOR_INTENSITY.DESELECTED);
		}
	}

	public override setColor(color: number, intensity: number) {
		const colorObject = new Color(color);
		colorObject.multiplyScalar(intensity);
		THREEUtils.setAttributeXYZ(this._instancedMesh.geometry.attributes.color, this._instanceId, colorObject.r, colorObject.g, colorObject.b);

		this._spaceViewRenderer.needsRender = true;
	}

	public override setGrayScaled(value: boolean) {
		const isGrayScaledAttrib = this._instancedMesh.geometry.attributes.isGrayScaled as InstancedBufferAttribute;

		THREEUtils.setAttributeX(isGrayScaledAttrib, this._instanceId, value ? 1.0 : 0.0);

		this._spaceViewRenderer.needsRender = true;
	}

	public override updateCenter(calledByStopRotationAroundItsOwnPivot?: boolean) {
		return THREEUtils.cloneGeometryData(this._worldGeometryData);
	}

	public override setVisibility(visible: boolean) {
		super.setVisibility(visible);
		const scaleValue = visible ? 1 : 0;
		this._group.scale.setX(scaleValue);
		this._group.scale.setY(scaleValue);
		THREEUtils.updateMatrices(this._group);
		this.updateInstancedMeshRelatedData();
	}

	private get _instancedMesh() {
		return this._spaceViewRenderer.markupManager.markupPhoto360InstancedMeshManager.photo360InstancedMesh;
	}

	protected override onGeometryUpdated = () => {
		this._instancedMesh.computeBoundingSphere();
		this._instancedMesh.instanceMatrix.needsUpdate = true;
		this._spaceViewRenderer.needsRender = true;
	};

	protected override updateBoundingBox(
		dataToCalculateFrom: PointDouble[] = [
			{
				x: this._worldGeometryData[0].x - this._radius,
				y: this._worldGeometryData[0].y - this._radius,
			},
			{
				x: this._worldGeometryData[0].x + this._radius,
				y: this._worldGeometryData[0].y + this._radius,
			},
		],
	) {
		return super.updateBoundingBox(dataToCalculateFrom);
	}

	public override translate(x: number, y: number, z: number, force: boolean = false): boolean {
		const hasChanged = super.translate(x, y, z, force);

		if (hasChanged) {
			this.updateInstancedMeshRelatedData();
		}

		return hasChanged;
	}

	public override rotateWithHandlerByDelta(deltaAngle: number, pivot?: PointDouble): void {
		super.rotateWithHandlerByDelta(deltaAngle, pivot);
		if (this.hasPermissionToMoveOrRotate) {
			this.updateInstancedMeshRelatedData();
		}
	}

	public override setRotation(angle: number) {
		super.setRotation(angle);
		this.updateInstancedMeshRelatedData();
	}

	private disposeGroup() {
		THREEUtils.clearContainer(this._group);
		this._group.parent?.remove(this._group);
	}

	public override destroy(notifyServer?: boolean, removeFromCollections: boolean = true) {
		this._group.scale.set(0, 0, 0); // secures the link symbol to be hidden until it's deleted (it gets deleted when the server's response arrives)
		super.destroy(notifyServer, removeFromCollections);
	}

	/**
	 * Used when another xyicon is deleted. We must shift everything related to the instancedMesh
	 * Removing and shifting the values is handled in THREEUtils.removeItemFromInstancedMesh
	 */
	private decreaseInstanceId() {
		--this._instanceId;
		this.setColor(this._color, SpaceItem.COLOR_INTENSITY.DESELECTED);
	}

	/** The only scenario when we wouldn't want removeFromCollections to be true is when we delete everything
	 * Because we take care of removing everything after this from the collections with one call,
	 * since it's much faster then removing them one by one.
	 */
	protected override destroyCallback(notifyServer: boolean = false, removeFromCollections: boolean = true) {
		super.destroyCallback(notifyServer);
		// DON'T DISPOSE THE MAP WITHOUT THINKING TWICE: OTHER XYICONS MIGHT USE IT
		if (removeFromCollections) {
			this.disposeGroup();
			THREEUtils.removeItemFromInstancedMesh(this._instancedMesh, this._instanceId);
			for (const item of [...this._markupManager.items.array, ...this._spaceViewRenderer.ghostModeManager.ghostMarkups].filter(
				(item) => item.type === MarkupType.Photo360,
			) as MarkupPhoto360[]) {
				if (item._instanceId > this._instanceId) {
					item.decreaseInstanceId();
				}
			}
			this._markupManager.deleteItem(this, notifyServer);
			this._spaceViewRenderer.needsRender = true;
		}
	}

	public override get scale() {
		return {
			x: 2 * this._radius,
			y: 2 * this._radius,
		};
	}

	public get instanceId() {
		return this._instanceId;
	}

	public override get isValid(): boolean {
		return (
			this._worldGeometryData.length === 1 &&
			MathUtils.isValidNumber(this._worldGeometryData[0].x) &&
			MathUtils.isValidNumber(this._worldGeometryData[0].y)
		);
	}

	public override get type(): MarkupType {
		return MarkupType.Photo360;
	}
}
