import React, {useCallback, useEffect, useReducer, useState} from "react";
import styled from "styled-components";
import {FileUtils} from "../../../../utils/file/FileUtils";
import {XyiconFeature} from "../../../../generated/api/base";
import {Space} from "../../../../data/models/Space";
import type {SpaceFileInsertionInfo} from "../../../../generated/api/base";
import type {SpaceVersion} from "../../../../data/models/SpaceVersion";
import {Functions} from "../../../../utils/function/Functions";
import type {Type} from "../../../../data/models/Type";
import type {SpaceTool} from "../../../modules/space/spaceeditor/logic3d/features/tools/Tools";
import {SpaceEditorMode, isSpaceNamePresentInAnotherSpaceVersion} from "../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRendererUtils";
import type {IBasicPropsFor3DTweaking} from "../../../modules/space/SpaceView";
import {useAppStore} from "../../../../StateManager";
import {SpaceEditorV5} from "../spaceeditor/SpaceEditorV5";
import {ConfirmWindowV5} from "../../popup/ConfirmWindowV5";
import type {ISpaceItemModel} from "../../../../data/models/Model";
import {radius} from "../../styles/styles";
import type {IOnePagePDFDoc} from "./CreateSpacePanelV5";
import {PDFListV5} from "./PDFListV5";

const getEmptyArray = (): ISpaceItemModel[] => [];

interface ISpaceConfirmSettingsV5Props extends IBasicPropsFor3DTweaking {
	readonly selectedVersion: SpaceVersion;
	readonly spaceType: Type;
	readonly pdfFiles: {
		originalFiles: File[];
		onePagePDFDocs: IOnePagePDFDoc[][]; // OriginalPDFFiles split by pages. pdfFiles.onePagePDFDocs[3] is an array of PDF documents that is generated by the pages of pdpfFiles.originalFiles[3]
	};
	readonly applySpaceUnitsPerMeterToAllSpaces: (spaceUnitsPerMeter: number) => void;
	readonly forceUpdate: () => void; // needed to update the "next" button (enabled/disabled)
}

export const SpaceConfirmSettingsV5 = (props: ISpaceConfirmSettingsV5Props) => {
	const {selectedVersion, spaceType, pdfFiles, applySpaceUnitsPerMeterToAllSpaces, forceUpdate} = props;
	const appState = useAppStore((state) => state.appState);
	const [, forceUpdateLocal] = useReducer((x) => x + 1, 0);
	const [activePDFFile, setActivePDFFile] = useState<IOnePagePDFDoc>(pdfFiles.onePagePDFDocs[0][0]);
	const [activeToolId, setActiveToolId] = useState<SpaceTool>("pan");

	console.log("Below for rendering:");
	console.log(activePDFFile.spaceName);

	const getMode = useCallback(
		(activePDF: IOnePagePDFDoc = activePDFFile) => {
			const spaceName = activePDF.spaceName;

			return isSpaceNamePresentInAnotherSpaceVersion(appState.actions, spaceName) ? SpaceEditorMode.ALIGN : SpaceEditorMode.SET_SCALE;
		},
		[activePDFFile, appState.actions],
	);

	const mode = getMode();

	const loadActivePDF = useCallback(
		async (newActivePDF: IOnePagePDFDoc = activePDFFile) => {
			let space: Space;

			const getActivePDFURL = async (activePDF: IOnePagePDFDoc = activePDFFile) => {
				return FileUtils.createURLFromData(await activePDF.doc.save());
			};

			if (getMode(newActivePDF) === SpaceEditorMode.ALIGN) {
				const spaceName = newActivePDF.spaceName;
				const spaces = appState.actions.getList(XyiconFeature.Space) as Space[];

				space = spaces.find((space: Space) => space.name === spaceName);
			}

			const spaceFileData = {
				spaceFileID: null as string,
				sourceFile: await getActivePDFURL(newActivePDF),
				thumbnailFile: newActivePDF.thumbnail,
				settings: newActivePDF.insertionInfo ? {insertionInfo: newActivePDF.insertionInfo} : (null as {insertionInfo: SpaceFileInsertionInfo}),
				spaceVersionID: selectedVersion.id,
			};

			if (!space) {
				space = new Space(
					{
						spaceID: null,
						spaceRefID: null,
						spaceTypeID: spaceType.id,
						portfolioID: appState.portfolioId,
						name: newActivePDF.spaceName,
						unitsPerMeter: null,
						fieldData: null,
						settings: null,
						spaceFiles: [spaceFileData],
					},
					appState,
				);
			} else {
				space.addTemporarySpaceFile(spaceFileData);
			}

			if (newActivePDF.insertionInfo) {
				space.newestSpaceFile.setInsertionInfo(newActivePDF.insertionInfo);
			}

			return appState.app.spaceViewRenderer.populateData(space);
		},
		[appState, activePDFFile, getMode, selectedVersion.id, spaceType.id],
	);

	const onSelect = async (originalPDFIndex: number, originalPageNumber: number) => {
		const setNewState = async () => {
			setActiveTool("pan");
			const newActivePDF = pdfFiles.onePagePDFDocs[originalPDFIndex][originalPageNumber];

			setActivePDFFile(newActivePDF);
			await loadActivePDF(newActivePDF);
		};

		if (activePDFFile.spaceId && !activePDFFile.isAlignmentConfirmed) {
			const confirmed = await ConfirmWindowV5.open("Are you sure you want to switch without confirming your alignment first?", "Please confirm", {
				ok: "Yes",
				cancel: "No",
			});

			if (confirmed) {
				await setNewState();
			}
		} else {
			await setNewState();
		}
	};

	const setScale = (spaceUnitsPerMeter: number, applyToAllSpaces: boolean = false) => {
		console.log("Below 2 for setScale func:");
		console.log(activePDFFile.spaceName);
		console.log(pdfFiles.onePagePDFDocs);
		if (applyToAllSpaces) {
			applySpaceUnitsPerMeterToAllSpaces(spaceUnitsPerMeter);
		} else {
			activePDFFile.spaceUnitsPerMeter = spaceUnitsPerMeter;
			forceUpdate();
			forceUpdateLocal();
		}
	};

	const confirmAlignment = (insertionInfo: SpaceFileInsertionInfo) => {
		const activePDF = activePDFFile;

		activePDF.insertionInfo = insertionInfo;
		activePDF.isAlignmentConfirmed = true;

		const space = appState.actions.getFeatureItemById(activePDF.spaceId, XyiconFeature.Space) as Space;

		space.newestSpaceFile.setInsertionInfo(insertionInfo);

		forceUpdate();
		forceUpdateLocal();
	};

	const setActiveTool = (id: SpaceTool) => {
		setActiveToolId(id);
	};

	useEffect(() => {
		const fetchData = async () => {
			await loadActivePDF();
		};

		fetchData();
	}, [loadActivePDF]);

	return (
		<SpaceScaleSettingsStyled className="SpaceScaleSettings hbox">
			<PDFListV5
				selectedVersion={selectedVersion}
				pdfFiles={pdfFiles}
				mode="SetScaleOrAlign"
				activePDFFile={activePDFFile}
				onSelect={onSelect}
				forceUpdate={forceUpdate}
			/>
			<SpaceEditorV5
				spaceViewRenderer={appState.app.spaceViewRenderer}
				mode={mode}
				setScale={setScale}
				confirmAlignment={confirmAlignment}
				setActiveTool={setActiveTool}
				activeToolId={activeToolId}
				selectionToolType="selection"
				focusItems={Functions.emptyFunction}
				onAddCatalogClick={Functions.emptyFunction}
				onDuplicateCatalogClick={Functions.emptyFunction}
				toggleDetailsPanel={Functions.emptyFunction}
				onOpenPanelClick={Functions.emptyFunction}
				getFocusedItems={getEmptyArray}
			/>
		</SpaceScaleSettingsStyled>
	);
};

const SpaceScaleSettingsStyled = styled.div`
	position: relative;
	height: calc(100vh - 300px);
	margin-left: initial;
	margin-right: initial;
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	.PDFList {
		width: 100%;
		max-width: 200px;
		overflow: auto;
	}

	.pdfContainer {
		cursor: pointer;
		margin: 5px;
		border-radius: ${radius.sm};
	}
`;
