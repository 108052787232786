import type {SpaceViewRenderer} from "../../../renderers/SpaceViewRenderer";
import type {PointDouble} from "../../../../../../../../generated/api/base";
import {THREEUtils} from "../../../../../../../../utils/THREEUtils";
import {Markup3D} from "./Markup3D";
import type {IMarkupConfig} from "./MarkupUtils";

/**
 * Examples for 1D: line, dashed line, arrow
 * Examples for 2D: cloud, rectangle, circle (ellipse)
 */
export type MarkupABDimensions = 1 | 2;

export type ICornerLetter = "a" | "b";

/**
 * Represents a Markup which can be defined by 2 pair of world coordinates: A, and B (pathCoords[0], and pathCoords[1])
 */
export abstract class MarkupAB extends Markup3D {
	protected abstract _dimensions: MarkupABDimensions;

	constructor(spaceViewRenderer: SpaceViewRenderer, config: IMarkupConfig) {
		super(spaceViewRenderer, config);
	}

	/**
	 * @param geometryData: geometryData in world coordinates
	 * @param keepAspectRatio:
	 * 2D: If the proportion of the object's side should be 1:1 (usually achieved by holding the alt key),
	 * 1D: snap to 45 degrees
	 */

	public override updateGeometry(
		geometryData: PointDouble[],
		isLocal: boolean = false,
		keepAspectRatio: boolean = false,
		fixedPoint: ICornerLetter = "a",
	) {
		if (geometryData.length !== 2) {
			throw "Error! This markup should have exactly 2 coordinates!";
		}

		this.updateAB(geometryData[0], geometryData[1], isLocal, keepAspectRatio, fixedPoint);
		this.onGeometryUpdated();
	}

	/**
	 * A, and B must be word coordinates!
	 * Returns if the geometry is valid (and visible).
	 * Eg.: an ellipse with rx===0 is not visible, so we better remove it - or don't even add it to the scene in the first place
	 */
	protected abstract updateAB(A: PointDouble, B: PointDouble, isLocal: boolean, keepAspectRatio: boolean, fixedPoint?: ICornerLetter): void;

	protected abstract processAB(
		A: PointDouble,
		B: PointDouble,
		isLocal: boolean,
		keepAspectRatio: boolean,
		fixedPoint?: ICornerLetter,
	): PointDouble[] | {min: PointDouble; max: PointDouble};

	public get dimensions() {
		return this._dimensions;
	}

	/**
	 * Returns the modified (A,B) coordinates (when the user holds the shift key for example)
	 * @param A
	 * @param B
	 * @param dimensions
	 */
	private getConstrainedAB(A: PointDouble, B: PointDouble, fixedPoint: ICornerLetter) {
		const center = {
			x: (A.x + B.x) / 2,
			y: (A.y + B.y) / 2,
		};
		const unrotatedAB = THREEUtils.getRotatedVertices([A, B], -this._lastSavedOrientation, center);

		let a = unrotatedAB[0];
		let b = unrotatedAB[1];

		const bbox = THREEUtils.calculateBox(unrotatedAB);
		const side = THREEUtils.getSizeOfBoundingBox2(bbox);

		if (this._dimensions === 1) {
			const constrainedCoords = THREEUtils.snapTo45Deg(side, a, b, fixedPoint);

			a = constrainedCoords.a as {x: number; y: number};
			b = constrainedCoords.b as {x: number; y: number};
		} else {
			const constrainedCoords = THREEUtils.constrainScale(side, a, b, fixedPoint);

			a = constrainedCoords.a;
			b = constrainedCoords.b;
		}

		const rerotatedAB = THREEUtils.getRotatedVertices([a, b], this._lastSavedOrientation, center);

		return {
			a: rerotatedAB[0],
			b: rerotatedAB[1],
		};
	}

	/**
	 * @param A world coordinate
	 * @param B world coordinate
	 */
	protected process1DAB(A: PointDouble, B: PointDouble, isLocal: boolean, keepAspectRatio: boolean, fixedPoint: ICornerLetter = "a") {
		this._group.visible = true;
		this._spaceViewRenderer.needsRender = true;

		let cloned = THREEUtils.cloneGeometryData([A, B]);

		if (keepAspectRatio) {
			const constrainedAB = this.getConstrainedAB(A, B, fixedPoint);

			cloned[0] = constrainedAB.a;
			cloned[1] = constrainedAB.b;
		}

		if (isLocal) {
			cloned = THREEUtils.getRotatedVertices(cloned, -this._lastSavedOrientation);
			this._group.rotation.z = this._lastSavedOrientation;
			THREEUtils.updateMatrices(this._group);
		} else {
			this._worldGeometryData = cloned;
		}

		return cloned;
	}

	protected process2DAB(A: PointDouble, B: PointDouble, isLocal: boolean, keepAspectRatio: boolean, fixedPoint: ICornerLetter = "a") {
		const processed1DAB = this.process1DAB(A, B, isLocal, keepAspectRatio, fixedPoint);
		const orientation = isLocal ? 0 : this._lastSavedOrientation;
		const unrotatedGeometryData = THREEUtils.getRotatedVertices(processed1DAB, -orientation);

		const {min, max} = THREEUtils.calculateBox(unrotatedGeometryData);
		const extendedGeometryData = THREEUtils.getGeometryDataFromBox(min, max);
		return THREEUtils.getRotatedVertices(extendedGeometryData, orientation);
	}
}
