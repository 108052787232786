import type {Group, Object3D} from "three";
import {Line, BufferGeometry, LineDashedMaterial, BufferAttribute, DynamicDrawUsage, Vector3} from "three";
import {BasicMaterial} from "../materials/BasicMaterial";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import {getCaptionLeaderLineVisibilityThreshold} from "../renderers/SpaceViewRendererUtils";
import {VectorUtils} from "../../../../../../utils/VectorUtils";
import {THREEUtils} from "../../../../../../utils/THREEUtils";

export class LeaderLine {
	private _spaceViewRenderer: SpaceViewRenderer;
	private _line: Line;
	private _geometry: BufferGeometry;
	private _material: LineDashedMaterial | BasicMaterial;
	private _parent: Group;
	private _container: Object3D;
	public static readonly dashSize: number = 8; // px
	public static readonly gapSize: number = 8; // px

	constructor(
		spaceViewRenderer: SpaceViewRenderer,
		parent: Group,
		x: number,
		y: number,
		container: Object3D = parent,
		color: number = 0x000000,
		isDashed: boolean = true,
	) {
		this._spaceViewRenderer = spaceViewRenderer;

		this._parent = parent;
		this._container = container;

		const vertices =
			parent === container ? new Float32Array([0, 0, 0, x, y, 0]) : new Float32Array([parent.position.x, parent.position.y, 0, x, y, 0]);

		const correctionMultiplier = this._correctionMultiplier;

		this._geometry = new BufferGeometry();
		this._geometry.setAttribute("position", new BufferAttribute(vertices, 3));
		(this._geometry.attributes.position as BufferAttribute).setUsage(DynamicDrawUsage);

		this._material = isDashed
			? new LineDashedMaterial({
					color: color,
					dashSize: LeaderLine.dashSize * correctionMultiplier,
					gapSize: LeaderLine.gapSize * correctionMultiplier,
				})
			: new BasicMaterial(color, 1.0, false, true);

		this._line = new Line(this._geometry, this._material);
		this._line.name = `LeaderLine of ${this._parent.name}`;
		if (isDashed) {
			this._line.computeLineDistances();
		}

		this.updateVisibility();

		if (this._line.visible) {
			THREEUtils.add(this._container, this._line);
		}
	}

	private get _correctionMultiplier() {
		return this._spaceViewRenderer.correctionMultiplier.current;
	}

	private get _threshold() {
		return getCaptionLeaderLineVisibilityThreshold(this._correctionMultiplier);
	}

	private get positionAttribute(): BufferAttribute {
		return this._geometry.attributes.position as BufferAttribute;
	}

	public updateVisibility() {
		const array = this.positionAttribute.array;
		const distance = VectorUtils.lengthOf([array[3] - array[0], array[4] - array[1], array[5] - array[2]]);

		this._line.visible = distance > this._threshold;
	}

	public hide() {
		this._line.visible = false;
	}

	public update(x: number, y: number) {
		if (this._container !== this._parent) {
			this.positionAttribute.setXYZ(0, this._parent.position.x, this._parent.position.y, 0);
		}
		this.positionAttribute.setXYZ(1, x, y, 0);

		this.updateVisibility();

		if (this._line.visible) {
			this.positionAttribute.needsUpdate = true;
			if (this._material instanceof LineDashedMaterial) {
				this._line.computeLineDistances();
			}

			if (!this._line.parent) {
				THREEUtils.add(this._container, this._line);
			}
		}
	}

	public destroy() {
		THREEUtils.disposeAndClearContainer(this._line);
		this._line.geometry.dispose();
		this._line.parent?.remove(this._line);
	}

	public renderToBottom() {
		THREEUtils.renderToBottom(this._line);
	}

	public get posA(): [number, number] {
		const worldPos = new Vector3();

		this._line.getWorldPosition(worldPos);

		return [worldPos.x + this.positionAttribute.getX(0), worldPos.y + this.positionAttribute.getY(0)];
	}

	public get posB(): [number, number] {
		const worldPos = new Vector3();

		this._line.getWorldPosition(worldPos);

		return [worldPos.x + this.positionAttribute.getX(1), worldPos.y + this.positionAttribute.getY(1)];
	}

	public get line() {
		return this._line;
	}

	public get isVisible() {
		return this._line.visible;
	}
}
