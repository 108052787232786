import {PerspectiveCamera, Scene, Vector3} from "three";
import {BoundedConvergence} from "../../utils/animation/BoundedConvergence";
import {Easing} from "../../utils/animation/Convergence";
import {Constants} from "../../ui/modules/space/spaceeditor/logic3d/Constants";
import type {PhotoSphereSceneManager} from "../PhotoSphereSceneManager";
import type {PhotoSphere} from "../PhotoSphere";
import {VectorUtils} from "../../utils/VectorUtils";
import type {HotSpot} from "./HotSpot";
import {CSS3DRenderer} from "./CSS3DRenderer";

export type CallbackType = (photoSphere: PhotoSphere, headingAngle: number | null, playMovementEffect: boolean) => void;

export class CSS3DManager {
	private readonly _renderer: CSS3DRenderer = new CSS3DRenderer();
	private readonly _scene: Scene = new Scene();
	private _userZoomFactor: number = 1;
	private readonly _scaleFactor: BoundedConvergence;
	private readonly _camera: PerspectiveCamera = new PerspectiveCamera(Constants.FOV, 1, 0.5, 2);
	private readonly _photoSphereSceneManager: PhotoSphereSceneManager;
	private _forward: number[] = [];
	private _isForwardLocked: boolean = false;
	private _hotSpots: HotSpot[] = [];
	private _limitedHotSpots: HotSpot[] = [];
	private _clickCallback: CallbackType;

	constructor(photoSphereSceneManager: PhotoSphereSceneManager) {
		this._photoSphereSceneManager = photoSphereSceneManager;
		this._scaleFactor = new BoundedConvergence({
			start: 1,
			end: 1,
			min: Constants.MIN_ZOOM_FACTOR / Constants.DEFAULT_ZOOM_FACTOR,
			max: Constants.DEFAULT_ZOOM_FACTOR,
			easing: Easing.EASE_IN_OUT,
			timeStampManager: this._photoSphereSceneManager,
		});
		this._renderer.domElement.style.position = "absolute";
		this._renderer.domElement.style.top = "0";
		this._renderer.domElement.style.left = "0";
	}

	private clearScene() {
		for (const hotSpot of this._hotSpots) {
			hotSpot.destroy();
		}
		this._hotSpots.length = 0;
		this._limitedHotSpots.length = 0;
		this._scene.children.length = 0;
	}

	public fade() {
		this._renderer.domElement.style.zIndex = "0";
		this._isForwardLocked = true;

		const oldScaleValue = this._scaleFactor.end;
		this._scaleFactor.reset(Constants.MIN_ZOOM_FACTOR, Constants.DEFAULT_ZOOM_FACTOR);

		this.addClassNameToHotSpots("zeroOpacity");

		setTimeout(() => {
			this.clearScene();
			this._scaleFactor.reset(oldScaleValue, oldScaleValue);
		}, Constants.DURATIONS.DEFAULT_ANIMATION);
	}

	public hide() {
		this.addClassNameToHotSpots("zeroOpacity");

		setTimeout(() => {
			this.addClassNameToHotSpots("hidden");
			for (let i = 0; i < this._limitedHotSpots.length; ++i) {
				this._scene.children[i].visible = false;
			}
		}, Constants.DURATIONS.DEFAULT_ANIMATION);
	}

	public show() {
		this.removeClassNameFromHotSpots("hidden");
		for (let i = 0; i < this._limitedHotSpots.length; ++i) {
			this._scene.children[i].visible = true;
		}

		setTimeout(() => {
			this.removeClassNameFromHotSpots("zeroOpacity");
		}, Constants.DURATIONS.DEFAULT_ANIMATION);
	}

	private addClickHandlerToHotSpot(hotSpot: HotSpot) {
		hotSpot.signals.click.add(() => {
			const returnTransitionMaybe = hotSpot.returnTransition;
			this._clickCallback(hotSpot.photoSphere, returnTransitionMaybe ? returnTransitionMaybe.longitude - Math.PI / 2 : null, true);
		});
	}

	public updateHotSpots(hotSpots: HotSpot[], callback: CallbackType, playMovementEffect: boolean = false) {
		this._clickCallback = callback;
		this._isForwardLocked = false;
		this._renderer.domElement.style.zIndex = "1";
		this._hotSpots = hotSpots.toSorted(this.sortByDistance);
		this.updateHotSpotNumberLimit();

		if (playMovementEffect) {
			this.playMovementEffect();
		}

		this.addClassNameToHotSpots("zeroOpacity");
		requestAnimationFrame(() => {
			requestAnimationFrame(() => {
				this.removeClassNameFromHotSpots("zeroOpacity");
			});
		});
	}

	public updateHotSpotNumberLimit = () => {
		for (const hotSpot of this._limitedHotSpots) {
			hotSpot.destroy();
		}
		this._scene.children.length = 0;

		const hotSpotNumberLimit = Math.min(this._photoSphereSceneManager.hotSpotNumberLimit, this._hotSpots.length);

		this._limitedHotSpots = this._hotSpots.slice(0, hotSpotNumberLimit);

		for (const hotSpot of this._limitedHotSpots) {
			this.addClickHandlerToHotSpot(hotSpot);
			const obj3D = hotSpot.createObject3D();
			this._scene.add(obj3D);
		}

		this.resizeHotSpots();
	};

	private resizeHotSpots(factor: number = 1) {
		for (const hotSpot of this._limitedHotSpots) {
			hotSpot.resize(factor);
		}
	}

	public setSize(width: number, height: number) {
		this._renderer.setSize(width, height);
		this._camera.aspect = width / height;
		this._camera.updateProjectionMatrix();

		this.resizeHotSpots();
	}

	private addClassNameToHotSpots(className: string) {
		for (const hotSpot of this._htmlElements) {
			hotSpot.classList.add(className);
		}
	}

	private removeClassNameFromHotSpots(className: string) {
		for (const hotSpot of this._htmlElements) {
			hotSpot.classList.remove(className);
		}
	}

	public playMovementEffect() {
		this._scaleFactor.reset(this._scaleFactor.min, Constants.MIN_ZOOM_FACTOR);
	}

	public render(forward: number[], userZoomFactor: number) {
		if (this._photoSphereSceneManager.showHotSpots) {
			const hasZoomFactorChanged = this._userZoomFactor !== userZoomFactor;
			if (!this._isForwardLocked || this._forward == null) {
				this._forward = forward;
				this._userZoomFactor = userZoomFactor;
			}
			if (hasZoomFactorChanged) {
				this._camera.zoom = this._userZoomFactor;
				this._camera.updateProjectionMatrix();
			}
			if (this._scaleFactor.hasChangedSinceLastTick || hasZoomFactorChanged) {
				this.resizeHotSpots(this._scaleFactor.value);
			}
			this._camera.lookAt(new Vector3(this._forward[0], this._forward[1], this._forward[2]));
			this._camera.updateMatrix();
			this._camera.updateMatrixWorld();
			this._renderer.render(this._scene, this._camera);
		}
	}

	private sortByDistance = (hotSpotA: HotSpot, hotSpotB: HotSpot) => {
		const activePhotoSphereMaybe = this._photoSphereManager.activePhotoSphereMaybe;

		if (activePhotoSphereMaybe) {
			const hotSpotAWorldPosition = hotSpotA.photoSphere.itemModel.position;
			const hotSpotBWorldPosition = hotSpotB.photoSphere.itemModel.position;
			const activePhotoSpherePosition = activePhotoSphereMaybe.itemModel.position;

			const activePhotoSphereToHotSpotAPositionDistanceSq = VectorUtils.distanceBetweenSquaredVec2(
				[activePhotoSpherePosition.x, activePhotoSpherePosition.y],
				[hotSpotAWorldPosition.x, hotSpotAWorldPosition.y],
			);

			const activePhotoSphereToHotSpotBPositionDistanceSq = VectorUtils.distanceBetweenSquaredVec2(
				[activePhotoSpherePosition.x, activePhotoSpherePosition.y],
				[hotSpotBWorldPosition.x, hotSpotBWorldPosition.y],
			);

			return activePhotoSphereToHotSpotAPositionDistanceSq - activePhotoSphereToHotSpotBPositionDistanceSq;
		}

		return 0;
	};

	private get _photoSphereManager() {
		return this._photoSphereSceneManager.spaceViewRenderer.transport.appState.app.graphicalTools.photoSphereManager;
	}

	private get _htmlElements() {
		return this._limitedHotSpots.map((hotSpot: HotSpot) => hotSpot.element);
	}

	public get hotSpots() {
		return this._hotSpots;
	}

	public get domElement() {
		return this._renderer.domElement;
	}
}
