import {useState} from "react";
import {Observer} from "mobx-react";
import styled from "styled-components";
import {useAppStore} from "../../../../StateManager";
import {SearchFieldV5} from "../../input/search/SearchFieldV5";
import type {Portfolio} from "../../../../data/models/Portfolio";
import {XyiconFeature} from "../../../../generated/api/base";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {colorPalette} from "../../styles/colorPalette";
import {baseDistance, fontSize, fontWeight, radius} from "../../styles/styles";
import {SearchFieldStyled} from "../../input/search/SearchField.styled";

export const PortfolioList = () => {
	const appState = useAppStore((state) => state.appState);
	const [searchString, setSearchString] = useState<string>("");

	return (
		<Observer>
			{() => {
				const portfolios = appState.actions.getList<Portfolio>(XyiconFeature.Portfolio);
				const filteredPortfolios = searchString ? portfolios.filter((p) => StringUtils.containsIgnoreCase(p.name, searchString)) : portfolios;

				return (
					<PortfolioListStyled>
						<TitleStyled>Portfolios</TitleStyled>
						<SearchFieldV5
							value={searchString}
							onInput={setSearchString}
							placeholder="Find Portfolio"
						/>
						<LabelStyled>Portfolio Name</LabelStyled>
						<PortfoliosStyled>
							{filteredPortfolios.map((p) => {
								return <PorfolioRowStyled key={p.id}>{p.name}</PorfolioRowStyled>;
							})}
						</PortfoliosStyled>
					</PortfolioListStyled>
				);
			}}
		</Observer>
	);
};

const TitleStyled = styled.div`
	font-size: 24px;
	line-height: 32px;
	font-weight: ${fontWeight.bold};
	color: ${colorPalette.primary.c500Primary};
`;

const PortfoliosStyled = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
`;

const LabelStyled = styled.div`
	font-size: ${fontSize.sm};
	line-height: 16px;
	color: ${colorPalette.gray.c700Dark};
	margin-left: ${baseDistance.sm};
`;

const PorfolioRowStyled = styled.div`
	display: flex;
	align-items: center;
	padding: 0 ${baseDistance.sm};
	min-height: 40px;
	width: 100%;
	border-radius: ${radius.sm};
	font-size: ${fontSize.lg};
	line-height: 24px;
	font-weight: ${fontWeight.normal};
	color: ${colorPalette.gray.c950};

	&:hover {
		background-color: ${colorPalette.primary.c200Light};
	}
`;

const PortfolioListStyled = styled.div`
	height: 100%;
	width: 100%;
	padding: ${baseDistance.md};
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.md};

	${SearchFieldStyled} {
		width: 100%;
	}
`;
