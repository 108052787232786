import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {ISectionData} from "../layout/LayoutSettingsV5";
import {NotificationType} from "../../../../notification/Notification";
import type {Field, IFieldData} from "../../../../../data/models/field/Field";
import {Field as FieldModel} from "../../../../../data/models/field/Field";
import type {XyiconFeature} from "../../../../../generated/api/base";
import type {IError, TransportLayer} from "../../../../../data/TransportLayer";
import {FieldDataType} from "../../../../../generated/api/base";
import type {ISingleSelectFieldSettingsDefinition} from "../../../../../data/models/field/datatypes/SingleSelect";
import type {AppState} from "../../../../../data/state/AppState";
import {notify} from "../../../../../utils/Notify";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import {ButtonV5} from "../../../button/ButtonV5";
import CloseIcon from "../../../icons/xmark.svg?react";
import {baseDistance} from "../../../styles/styles";
import {FieldFormV5} from "./FieldFormV5";

interface ICreateFieldPanelState {
	createClicked: boolean;
}

interface ICreateFieldPanelProps {
	readonly feature: XyiconFeature;
	readonly typesFeature: XyiconFeature;
	readonly onCancel: () => void;
	readonly onCreated: (id: string) => void;
	readonly transport?: TransportLayer;
	readonly appState?: AppState;
}

@inject("transport")
@inject("appState")
@observer
export class CreateFieldPanelV5 extends React.PureComponent<ICreateFieldPanelProps, ICreateFieldPanelState> {
	private readonly _field: FieldModel;

	constructor(props: ICreateFieldPanelProps) {
		super(props);
		this.state = {
			createClicked: false,
		};
		this._field = new FieldModel({
			feature: props.feature,
			dataType: FieldDataType.SingleLineText,
		} as Partial<IFieldData> as any);
	}

	private resetCreateFields = () => {
		if (this._field.dataType === FieldDataType.SingleChoiceList || this._field.dataType === FieldDataType.MultipleChoiceList) {
			(this._field.dataTypeSettings as ISingleSelectFieldSettingsDefinition).choiceList = [];
		}

		this._field.name = "";
		this._field.dataType = FieldDataType.SingleLineText;

		this.setState({
			createClicked: false,
		});
	};

	private onCreateClicked = async () => {
		this.setState({createClicked: true});

		const {error, field} = (await this.props.transport.services.typefield.createField(this._field)) as {error: IError; field: Field};

		await this.props.transport.services.fieldLayout.refreshLayouts();
		field.isNew = true;

		const sections = this.props.appState.layouts[this.props.feature]?.sections;

		if (sections && sections.length > 0) {
			sections[0].fields.unshift({id: field.refId, noWrap: false});
			await this.props.transport.services.fieldLayout.updateLayout({sections: sections}, this.props.feature);
		} else {
			const newSection = {label: "Section", fields: [{id: field.refId, noWrap: false}]} as ISectionData;

			await this.props.transport.services.fieldLayout.updateLayout({sections: [newSection]}, this.props.feature);
		}

		if (field) {
			this.resetCreateFields();
			this.props.onCreated(field.id);

			notify(this.props.appState.app.notificationContainer, {
				title: `Field ${field.name} added to Layout`,
				type: NotificationType.Message,
				lifeTime: 10000,
				description: "Go to Layouts to rearrange the fields.",
			});
		}
	};

	private onNameInput = (value: string) => {
		this._field.name = value;
	};

	private onCancel = () => {
		this.resetCreateFields();
		this.props.onCancel();
	};

	public override render() {
		const {createClicked} = this.state;
		const {typesFeature, appState} = this.props;
		const field = this._field;

		const isValid = appState.actions.isFieldNameValid(field.name, field) && field.dataType;

		return (
			<CreateFieldPanelContainerStyled>
				<CreateFieldPanelTitleContainerStyled>
					<h4>Create New Field</h4>
					<CreateFiledPanelCreateButtonContainerStyled>
						<ButtonV5
							label="Create"
							onClick={this.onCreateClicked}
							disabled={!isValid || createClicked || (field.hasFormula && !field.formula)}
							className="primary"
						/>
						<IconButtonV5
							className="close"
							IconComponent={CloseIcon}
							onClick={this.onCancel}
						/>
					</CreateFiledPanelCreateButtonContainerStyled>
				</CreateFieldPanelTitleContainerStyled>
				<FieldFormV5
					createPanelNameInput={this.onNameInput}
					field={field}
					typesFeature={typesFeature}
				/>
			</CreateFieldPanelContainerStyled>
		);
	}
}

const CreateFieldPanelContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.md};
	height: 100%;
	padding: ${baseDistance.md};
`;

const CreateFieldPanelTitleContainerStyled = styled.div`
	display: flex;
	align-items: center;
`;

const CreateFiledPanelCreateButtonContainerStyled = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: ${baseDistance.sm};
	margin-left: auto;
`;
