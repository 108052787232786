import * as React from "react";
import {observer} from "mobx-react";
import {DateFormatter} from "../../../../../../../../utils/format/DateFormatter";
import {OptionsButton} from "../../../../../../../widgets/button/options/OptionsButton";
import type {IContextOption} from "../../../../../../../widgets/context/ContextOptions";
import {getFileTypeLabel} from "../../../../../../../../data/models/FileType";
import type {DocumentSectionItem} from "../DocumentSection";
import {ReactUtils} from "../../../../../../../utils/ReactUtils";

interface IDocumentListItemProps {
	readonly documentItem: DocumentSectionItem;
	readonly getOptions: (document: DocumentSectionItem) => IContextOption[];
}

@observer
export class DocumentListItem extends React.Component<IDocumentListItemProps> {
	public override render() {
		const {documentItem, getOptions} = this.props;
		const document = documentItem.document;

		return (
			<div
				key={document.id}
				className={ReactUtils.cls("DocumentListItem", {inherited: documentItem.inherited})}
			>
				<div
					className="thumb"
					style={{backgroundImage: `url(${document.thumbnailFileURL})`}}
				/>
				<div className="text">
					<div className="description">{document.fileName}</div>
					<div className="type">{getFileTypeLabel(document.fileType)}</div>
					<div className="date">{DateFormatter.format(document.lastModifiedAt)}</div>
				</div>
				<OptionsButton
					className="options flexCenter"
					options={getOptions(documentItem)}
				/>
			</div>
		);
	}
}
