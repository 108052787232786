import {useRef} from "react";
import type {CSSProperties} from "styled-components";
import styled from "styled-components";
import {useClickOutside} from "../utils";
import type {Boundary} from "../../../data/models/Boundary";
import {XyiconFeature} from "../../../generated/api/base";
import type {BoundarySpaceMap} from "../../../data/models/BoundarySpaceMap";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import PinIcon from "../icons/location-pin.svg?react";
import {useAppStore} from "../../../StateManager";
import {colorPalette} from "../styles/colorPalette";
import {baseDistance, fontSize, radius} from "../styles/styles";

interface IBoundarySpaceMapDataSelectorProps {
	readonly item: Boundary;
	readonly onClose: () => void;
	readonly closePortfolioWideSearch?: () => void;
	readonly style?: CSSProperties;
}

export const BoundarySpaceMapSelectorV5 = (props: IBoundarySpaceMapDataSelectorProps) => {
	const appState = useAppStore((state) => state.appState);
	const {item, onClose, closePortfolioWideSearch, style = {}} = props;

	const _ref = useRef<HTMLDivElement>();

	const getSpaceNameById = (spaceId: string) => {
		return appState.actions.getFeatureItemById(spaceId, XyiconFeature.Space).name || "";
	};

	const onCloseLocal = (event: MouseEvent) => {
		event.stopPropagation();
		onClose();
	};

	useClickOutside([_ref], onCloseLocal);

	const getRows = () => {
		return [...item.boundarySpaceMaps]
			.toSorted((a: BoundarySpaceMap, b: BoundarySpaceMap) => StringUtils.sortIgnoreCase(a.spaceName, b.spaceName))
			.map((boundarySpaceMap: BoundarySpaceMap, index: number) => (
				<BoundarySpaceMapRowStyled
					key={boundarySpaceMap.id}
					onClick={(event: React.MouseEvent<HTMLDivElement>) => {
						event.stopPropagation(); // workaround for this: in portfoliowidesearch, when you click this, its parent is also getting clicked, and the details panel opens
						appState.actions.navigateToSpaceItem(boundarySpaceMap, true);
						closePortfolioWideSearch?.();
					}}
				>
					<BoundaryThumbnailAndInfoStyled>
						<div
							className="thumbnail"
							style={{backgroundImage: `url(${boundarySpaceMap.thumbnail})`}}
						/>
						<InfoStyled>
							<div>{getSpaceNameById(boundarySpaceMap.spaceId)}</div>
							<div className="subtle">{boundarySpaceMap.typeName}</div>
						</InfoStyled>
					</BoundaryThumbnailAndInfoStyled>
					<PinIcon />
				</BoundarySpaceMapRowStyled>
			));
	};

	return (
		<BoundarySpaceMapSelectorStyled
			style={style}
			ref={_ref}
		>
			<div className="boundaryContainer">{getRows()}</div>
		</BoundarySpaceMapSelectorStyled>
	);
};

const InfoStyled = styled.div`
	display: flex;
	flex-direction: column;

	.subtle {
		color: ${colorPalette.gray.c300};
	}
`;

const BoundaryThumbnailAndInfoStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const BoundarySpaceMapRowStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	font-size: ${fontSize.md};
	line-height: 16px;
	padding: ${baseDistance.xs};
	border: 1px solid ${colorPalette.white};

	&:hover {
		border-color: ${colorPalette.primary.c500Primary};
	}

	.pin {
		width: 25px;
		height: 25px;
		margin-left: auto;
		margin-right: 15px;
	}
`;

const BoundarySpaceMapSelectorStyled = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 100%;
	left: 0;
	width: 350px;
	z-index: 1;
	background-color: ${colorPalette.white};
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
	padding: ${baseDistance.xs};
	border-radius: ${radius.md};

	.boundaryContainer {
		max-height: 400px;
		overflow-y: auto;
	}

	.thumbnail {
		min-width: 38px;
		min-height: 38px;
		margin-right: 10px;
	}
`;
