import * as React from "react";
import {inject} from "mobx-react";
import styled from "styled-components";
import type {IFieldAdapter} from "../../../../../data/models/field/Field";
import type {AppState} from "../../../../../data/state/AppState";
import {ArrayUtils} from "../../../../../utils/data/array/ArrayUtils";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";
import {IconButton} from "../../../../widgets/button/IconButton";
import {TextInputV5} from "../../../details/datatypes/TextInputV5";
import {SelectInputStyled, SelectInputV5} from "../../../input/select/SelectInputV5";
import {colorPalette} from "../../../styles/colorPalette";
import {baseDistance, fontSize, radius} from "../../../styles/styles";
import {HorizontalAlignment} from "../../../../../utils/dom/DomUtils";

interface IAnyParamProps {
	readonly param: any[];
	readonly onChange: (value: any[]) => void;
	readonly appState?: AppState;
	readonly field?: IFieldAdapter;
}

interface IAnyParamState {
	value: string;
	tags: string[];
}

@inject("appState")
export class AnyParamV5 extends React.Component<IAnyParamProps, IAnyParamState> {
	private _element = React.createRef<HTMLDivElement>();
	private _list = React.createRef<HTMLDivElement>();

	constructor(props: IAnyParamProps) {
		super(props);
		this.state = {
			value: "",
			tags: [],
		};
	}

	public static getDerivedStateFromProps(props: IAnyParamProps, state: IAnyParamState) {
		if (props.param) {
			return {
				tags: props.param,
			} as IAnyParamState;
		}
		return null;
	}

	private getSuggestionList(value: string) {
		const {tags} = this.state;
		const {appState, field} = this.props;
		const feature = field.feature;
		const models = appState.actions.getList(feature);

		const suggestions = models
			.map((model) => appState.actions.renderValue(model, field.refId))
			.filter((val) => val && !tags.includes(val) && val.toLowerCase?.().startsWith(value.toLowerCase()))
			.sort((a, b) => StringUtils.sortIgnoreCase(a, b));

		return ArrayUtils.removeDuplicates(suggestions);
	}

	private onInputValue = (value: string) => {
		this.setState({value});
	};

	private removeTag = (index: number) => {
		const tags = ArrayUtils.removeAtIndex(this.state.tags, index);

		this.setState({tags});
		this.props.onChange(tags);
	};

	private addTag = (value: string) => {
		const tags = [...this.state.tags];

		if (!tags.includes(value) && value) {
			tags.push(value);
			this.props.onChange(tags);
		}
		this.setState({tags, value: ""});
	};

	private onKeyDown = (event: React.KeyboardEvent, value: string) => {
		switch (event.key) {
			case KeyboardListener.KEY_ENTER:
				this.addTag(value);
				break;
		}
	};

	public override render() {
		const {value, tags} = this.state;
		const suggestions = this.getSuggestionList(value);

		return (
			<AnyParamStyled ref={this._element}>
				<div className="items">
					{tags.slice().map((option, index) => {
						return (
							<div
								className="item hbox"
								key={index}
							>
								<div className="label">{option}</div>
								<IconButton
									icon="close"
									className="delete"
									onClick={() => this.removeTag(index)}
								/>
							</div>
						);
					})}
					<div className="list-search">
						<TextInputV5
							value={this.state.value || ""}
							onInput={this.onInputValue}
							inputType="text"
							autoFocus={true}
							onKeyDown={this.onKeyDown}
						/>
					</div>
				</div>
				<SelectInputV5
					options={suggestions}
					onChange={this.addTag}
					searchBarMode="always on"
					horizontalAlignment={HorizontalAlignment.right}
					dropdownFixedWidth="200px"
					render={(item) => item}
				/>
			</AnyParamStyled>
		);
	}
}

const AnyParamStyled = styled.div`
	display: grid;
	grid-template-columns: 165px 36px;
	width: 200px;
	align-items: center;
	background-color: ${colorPalette.white};
	border-radius: ${radius.sm};
	border: 1px solid ${colorPalette.gray.c300};

	.items {
		display: inline-flex;
		flex-wrap: wrap;
		font-size: ${fontSize.md};
	}

	${SelectInputStyled} {
		border: none;
		padding-left: 0;
		width: 32px;

		&:hover {
			background-color: inherit;
		}
	}

	.item {
		border-radius: ${radius.sm};
		margin: ${baseDistance.xs};
		padding: ${baseDistance.xs};
		align-items: center;
		gap: ${baseDistance.sm};
		width: fit-content;
		background-color: ${colorPalette.gray.c100};

		.label {
			max-width: 134px;
			overflow: hidden;
			word-wrap: break-word;
			line-height: 16px;
		}

		svg {
			height: 12px;
			width: 12px;
			padding: 2px;
		}
	}

	input {
		border: none;
		outline: none;
	}

	.list-search {
		min-width: 10px;
		display: flex;
		flex: 1;
	}
`;
