import {XyiconFeature} from "../../generated/api/base";
import {GHOST_ID_POSTFIX} from "../../ui/modules/space/spaceeditor/logic3d/managers/GhostModeConstants";
import type {Boundary} from "./Boundary";
import type {IModel} from "./Model";

export class BoundaryUtils {
	public static doesHaveMultipleSpaceMaps(model: IModel): boolean {
		if (model.ownFeature === XyiconFeature.Boundary) {
			return [...(model as Boundary).boundarySpaceMaps].filter((item) => !item.id?.includes(GHOST_ID_POSTFIX)).length > 1;
		}

		return false;
	}
}
