import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {AppState} from "../../../../../data/state/AppState";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import {XyiconFeature} from "../../../../../generated/api/base";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {TextInputV5} from "../../../details/datatypes/TextInputV5";
import {DropdownButtonV5} from "../../../interaction/DropdownButtonV5";
import DotsHorizontalIcon from "../../../icons/dots-horizontal.svg?react";
import {type IDropdownOption} from "../../../interaction/DropdownOptionsV5";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import {Functions} from "../../../../../utils/function/Functions";
import {baseDistance, ELLIPSIS, fontSize, fontWeight, InfoIconStyled, radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import PenFieldIcon from "../../../icons/pen-field.svg?react";
import DeleteIcon from "../../../icons/delete.svg?react";
import {HorizontalAlignment} from "../../../../../utils/dom/DomUtils";

interface IPermissionSetItemPropsV5 {
	readonly id: string;
	readonly selected: boolean;
	readonly appState?: AppState;
	readonly onSelect: (id: string) => void;
	readonly onDelete: (id: string) => void;
	readonly onSave: (id: string, name: string) => void;
}

interface IPermissionSetItemStateV5 {
	editMode: boolean;
	errorMessage: string;
}

@inject("appState")
@observer
export class PermissionSetItemV5 extends React.Component<IPermissionSetItemPropsV5, IPermissionSetItemStateV5> {
	private _permissionSetTempName = "";
	private _prevName = "";
	private _container = React.createRef<HTMLDivElement>();

	constructor(props: IPermissionSetItemPropsV5) {
		super(props);
		this.state = {
			editMode: false,
			errorMessage: "",
		};
	}

	private reset() {
		this.setState({editMode: false});
	}

	private onBlur = () => {
		const name = this.props.appState.actions.getFeatureItemById<PermissionSet>(this.props.id, XyiconFeature.PermissionSet).name;

		if (!this.state.errorMessage) {
			if (this._permissionSetTempName !== name) {
				this.props.onSave(this.props.id, this._permissionSetTempName);
			}
		} else {
			this.props.onSave(this.props.id, this._prevName);
			this.setState({errorMessage: ""});
			this._permissionSetTempName = this._prevName;
		}

		this.reset();
	};

	private onRenameClick = () => {
		const {id, appState} = this.props;

		requestAnimationFrame(() => {
			if (!this.state.editMode) {
				this.setState({
					editMode: true,
				});
			}

			this._permissionSetTempName = appState.actions.getFeatureItemById<PermissionSet>(id, XyiconFeature.PermissionSet).name;
			this._prevName = this._permissionSetTempName;
		});
	};

	private onRename = (value: string) => {
		const {appState, id} = this.props;
		const isValid = appState.actions.isPermissionSetNameValid(value, id);
		let errorMessage = "";

		if (!isValid) {
			errorMessage = "Name needs to be unique!";
		}

		if (!value.trim()) {
			errorMessage = "Name cannot be empty!";
		}

		this.setState({errorMessage});
		this._permissionSetTempName = value;
	};

	private getOptions = () => {
		const {onDelete, id} = this.props;

		const options: IDropdownOption[] = [
			{
				label: "Rename",
				IconComponent: PenFieldIcon,
				iconCustomStyle: {width: "16px", height: "16px"},
				onClick: () => this.onRenameClick(),
			},
			{
				label: "Delete",
				IconComponent: DeleteIcon,
				iconCustomStyle: {width: "16px", height: "16px"},
				onClick: () => onDelete(id),
			},
		];

		return options;
	};

	private onSelect = () => {
		this.props.onSelect(this.props.id);
	};

	public override render() {
		const {appState, id, selected} = this.props;
		const {editMode, errorMessage} = this.state;
		const permissionSet = appState.actions.getFeatureItemById<PermissionSet>(id, XyiconFeature.PermissionSet);

		return (
			<PermissionSetItemStyled>
				<div
					ref={this._container}
					className={ReactUtils.cls("PermissionSetItem hbox listItem", {selected, editMode})}
					onClick={this.onSelect}
				>
					<div className="ItemNameInput vbox flex_1">
						{editMode ? (
							<TextInputV5
								value={this._permissionSetTempName}
								onInput={this.onRename}
								autoFocus={true}
								onBlur={this.onBlur}
								getErrorMessage={() => errorMessage}
								className={ReactUtils.cls("Input", {isError: errorMessage})}
							/>
						) : (
							<div className="name">{permissionSet?.name}</div>
						)}
						<span className="description">{appState.actions.renderName(permissionSet?.lastModifiedBy)}</span>
					</div>
					<DropdownButtonV5
						options={this.getOptions()}
						button={
							<IconButtonV5
								IconComponent={DotsHorizontalIcon}
								onClick={Functions.emptyFunction}
							/>
						}
						className="dropdownButton"
						horizontalAlignment={HorizontalAlignment.right}
					/>
				</div>
			</PermissionSetItemStyled>
		);
	}
}

const PermissionSetItemStyled = styled.div`
	.PermissionSetItem {
		cursor: pointer;
		height: 48px;
		padding: ${baseDistance.xs} ${baseDistance.sm} ${baseDistance.xs} ${baseDistance.sm};

		.name {
			${ELLIPSIS}
			max-width: 312px;
			font-size: ${fontSize.lg};
			font-weight: ${fontWeight.normal};
			line-height: 24px;
		}

		.description {
			${ELLIPSIS}
			max-width: 312px;
			font-size: ${fontSize.md};
			margin-top: auto;
			margin-bottom: auto;
			line-height: 16px;
			color: ${colorPalette.gray.c700Dark};
		}

		.dropdownButton {
			width: 24px;
			height: 24px;
			margin-top: auto;
			margin-bottom: auto;
		}

		&:hover {
			background: ${colorPalette.gray.c200Light};
			border-radius: ${radius.sm};
		}

		&.selected,
		&.editMode {
			background: ${colorPalette.primary.c500Primary};
			border-radius: ${radius.sm};

			.name {
				color: ${colorPalette.white};
				margin-top: auto;
				margin-bottom: auto;
			}

			.description {
				color: ${colorPalette.gray.c200Light};
				margin-top: auto;
				margin-bottom: auto;
			}

			.dropdownButton {
				background: ${colorPalette.primary.c500Primary};
			}

			.dropdownButton svg path {
				stroke: ${colorPalette.white};
			}

			.ItemNameInput input {
				background: ${colorPalette.white};
				height: 24px;
				width: 312px;

				&.isError {
					border: 1px solid ${colorPalette.negative.c500Primary};
				}
			}
		}

		.dropdownButton:hover {
			background: ${colorPalette.gray.c200Light};
			border: 1px solid black;

			svg path {
				stroke: black;
			}
		}

		.ItemNameInput {
			${InfoIconStyled} {
				right: 16px;
				top: 0px;
			}
		}
	}
`;
