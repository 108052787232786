import {useEffect, useState} from "react";
import styled from "styled-components";
import {useAppStore} from "../../../StateManager";
import type {Report} from "../../../data/models/Report";
import {ReportScope, type ReportArchiveDownloadDto, type ReportArchiveDto} from "../../../generated/api/reports";
import {SearchFieldV5} from "../input/search/SearchFieldV5";
import {ToggleContainerV5} from "../widgets/ToggleContainerV5/ToggleContainerV5";
import {ButtonV5} from "../button/ButtonV5";
import {colorPalette} from "../styles/colorPalette";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {FileUtils} from "../../../utils/file/FileUtils";
import XlsxIcon from "../icons/xlsx.svg?react";
import PDFIcon from "../icons/pdf.svg?react";
import CSVIcon from "../icons/csv.svg?react";
import DownloadIcon from "../icons/download.svg?react";
import {XyiconFeature} from "../../../generated/api/base";
import type {Portfolio} from "../../../data/models/Portfolio";
import {baseDistance, FlexCenterStyle, fontSize, radius} from "../styles/styles";

interface IReportHistorySectionProps {
	readonly report: Report;
}

export const ReportHistorySectionV5 = (props: IReportHistorySectionProps) => {
	const {report} = props;
	const appState = useAppStore((state) => state.appState);
	const [search, setSearch] = useState<string>("");
	const [archiveReports, setArchiveReports] = useState<ReportArchiveDto[]>([]);
	const fileFormat = StringUtils.lowerCase(props.report.deliveryFormat || "csv");
	const portfolioIDList = appState.lists[XyiconFeature.Portfolio].array.map((p: Portfolio) => p.id).join();
	const currentPortfolioId = appState.portfolioId;
	const featureService = appState.app.transport.services.feature;

	const downloadReportsArchives = async (archiveReport: ReportArchiveDto) => {
		const result = await featureService.downloadArchiveReports(archiveReport);

		if (result) {
			const filePath = appState.app.transport.getReportFilePath(archiveReport.reportArchiveID, fileFormat);
			result.forEach((item: ReportArchiveDownloadDto) => {
				FileUtils.downloadFileFromUrl(filePath, `${report.name}_${item.reportName}`);
			});
		}
	};

	const onSearchStringChange = (value: string) => {
		setSearch(value);
	};

	const reportGeneratedSignal = appState.app.transport.signalR.listener.signals.reportGenerated;

	useEffect(() => {
		const fetchReports = async () => {
			try {
				const fetchedReports: ReportArchiveDto[] = await featureService.getArchiveReports(report.id);
				let filteredFetchedReports: ReportArchiveDto[] = [];

				for (const r of fetchedReports) {
					const portfolioIDsArray = r.portfolioIDs.replace(/[\[\]"]/g, "").split(",");

					if (
						(r.reportScope == ReportScope.CurrentPortfolio && portfolioIDsArray[0] == currentPortfolioId) ||
						(r.reportScope == ReportScope.PortfolioIDList && portfolioIDsArray.every((id) => portfolioIDList.includes(id))) ||
						r.reportScope == ReportScope.Organization
					) {
						filteredFetchedReports.push(r);
					}
				}

				setArchiveReports(filteredFetchedReports);
			} catch (e) {
				console.warn(e);
			}
		};

		fetchReports();

		const handleReportGenerated = () => {
			fetchReports();
		};

		reportGeneratedSignal.add(handleReportGenerated);

		return () => {
			reportGeneratedSignal.remove(handleReportGenerated);
		};
	}, [report.id, currentPortfolioId, featureService, portfolioIDList, reportGeneratedSignal]);

	const filteredReports = search
		? archiveReports.filter((archiveReport) => {
				const nameMatches = StringUtils.containsIgnoreCase(archiveReport.name, search);
				if (nameMatches) {
					return true;
				}

				const typeMatches = StringUtils.containsIgnoreCase(fileFormat, search);
				if (typeMatches) {
					return true;
				}

				const renderedName = appState.actions.renderName(archiveReport.invokedBy);
				return StringUtils.containsIgnoreCase(renderedName, search);
			})
		: archiveReports;

	return (
		<ToggleContainerV5
			title="Recently Generated Reports"
			open={true}
			saveStateToLocalStorage={true}
		>
			<SearchFieldV5
				value=""
				onInput={onSearchStringChange}
			/>
			{filteredReports.length > 0 ? (
				<>
					<ResultCountStyled> {filteredReports.length} results</ResultCountStyled>
					{filteredReports.map((archiveReport: ReportArchiveDto) => (
						<RowStyled key={archiveReport.name}>
							{fileFormat == "csv" ? <CSVIcon /> : fileFormat == "pdf" ? <PDFIcon /> : <XlsxIcon />}
							<ContainerStyled>
								<div className="date">{archiveReport.name}</div>
								<div>{appState.actions.renderName(archiveReport.invokedBy)}</div>
								<div>{archiveReport.fileSizeText}</div>
							</ContainerStyled>
							<ButtonV5
								label="Download"
								title="Download"
								className="download"
								onClick={() => downloadReportsArchives(archiveReport)}
							>
								<DownloadIcon />
							</ButtonV5>
						</RowStyled>
					))}
				</>
			) : (
				<ResultCountStyled className="empty">There are no reports to display</ResultCountStyled>
			)}
		</ToggleContainerV5>
	);
};

const ResultCountStyled = styled.div`
	font-size: ${fontSize.md};
	color: ${colorPalette.gray.c700Dark};
	margin: ${baseDistance.sm} 0;

	&.empty {
		justify-content: center;
		padding: 50px 88px;
	}
`;

const RowStyled = styled.div`
	${FlexCenterStyle}
	gap: ${baseDistance.xs};
	padding: 0 ${baseDistance.sm};
	margin-bottom: ${baseDistance.xs};
	cursor: pointer;

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
		border-radius: ${radius.sm};

		.download {
			display: flex;
		}
	}

	.download {
		height: 24px;
		width: 86px;
		font-size: ${fontSize.sm};
		display: none;
		gap: ${baseDistance.xs};
		padding: 0 ${baseDistance.xs};
	}
`;

const ContainerStyled = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding-left: ${baseDistance.sm};
	color: ${colorPalette.gray.c700Dark};
	font-size: ${fontSize.sm};
	line-height: 16px;
	overflow: hidden;
	white-space: nowrap;

	.date {
		color: ${colorPalette.gray.c950};
		font-size: ${fontSize.md};
	}
`;
