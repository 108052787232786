import {forwardRef, useImperativeHandle, useState} from "react";
import styled from "styled-components";
import type {IMassInputV5Props} from "../../../../modules/abstract/sidepanel/tabs/details/field/mass/IMassInput";
import type {IModel} from "../../../../../data/models/Model";
import {TimeUtils} from "../../../../../utils/TimeUtils";
import {FieldDataType} from "../../../../../generated/api/base";
import {FieldDataTypes} from "../../../../../data/models/field/FieldDataTypes";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {SelectInputV5} from "../../../input/select/SelectInputV5";
import {useAppStore} from "../../../../../StateManager";
import {ConfirmWindowV5} from "../../../popup/ConfirmWindowV5";
import {ClickToEditInputV5} from "../../../input/clicktoedit/ClickToEditInputV5";
import {SingleSelectInputV5} from "../SingleSelectInputV5";
import {baseDistance, VerticalFlexStyle} from "../../../styles/styles";

export type IOptionId = "all" | "blank" | string;

interface IOption {
	id: IOptionId;
	label: string;
	value?: any;
}

export const MassFieldInputV5 = forwardRef((props: IMassInputV5Props, ref) => {
	const appState = useAppStore((store) => store.appState);

	const [selectedOptionId, setSelectedOptionId] = useState<IOptionId>("all");
	const [selectedOptionValue, setSelectedOptionValue] = useState<any>(undefined);
	const [inputValue, setInputValue] = useState<string | boolean | number>(getInitialInputValueForFieldType());

	const onApply = async (items: IModel[]) => {
		const {field, selectItemsCount} = props;

		// KeyboardListener.getInstance().signals.up.remove(pressButton);

		const itemsToUpdate = items.filter((item) => {
			const hasValue = appState.actions.hasOwnFieldValue(item, field.refId);

			if (!hasValue) {
				return false;
			}

			const value_ = appState.actions.getOwnFieldValue(item, field.refId);
			const value = field.dataType === FieldDataType.Boolean ? value_ : appState.actions.formatValue(value_, field.refId);
			const changeChecker = FieldDataTypes.map[field.dataType].changeChecker;

			if (selectedOptionId === "all") {
				if (changeChecker) {
					return changeChecker(value_, inputValue, selectedOptionValue, field.refId, appState);
				}
				return value !== inputValue;
			} else if (selectedOptionId === "blank") {
				return StringUtils.isBlank(value);
			} else {
				if (changeChecker) {
					return changeChecker(value_, inputValue, selectedOptionValue, field.refId, appState);
				}
				const currentValue = selectedOptionValue;

				return currentValue === value && value !== inputValue;
			}
		});

		const confirmed = await ConfirmWindowV5.open(
			`${itemsToUpdate.length} of ${selectItemsCount} items will be updated. Continue?`,
			"Confirm Update",
			{
				ok: "Yes",
				cancel: "Cancel",
			},
		);

		if (confirmed) {
			if (itemsToUpdate.length > 0) {
				await TimeUtils.waitUpdate(
					appState.actions.updateMassFields(itemsToUpdate, {
						[field.refId]: inputValue,
					}),
					appState.app.notificationContainer,
				);
			}
			props.onClose();
		} else {
			// KeyboardListener.getInstance().signals.up.add(pressButton);
		}
	};

	const onOptionChange = (option: IOption) => {
		setSelectedOptionId(option.id);
		setSelectedOptionValue(option.value);
	};

	const onInputChange = (value: string | boolean) => {
		setInputValue(value === null ? getInitialInputValueForFieldType() : value);
	};

	const getEditInput = () => {
		const {field} = props;
		const isAnyBeingUpdated = getIsAnyBeingUpdated();

		return field.dataType === FieldDataType.SingleChoiceList ? (
			<SingleSelectInputV5
				value={inputValue}
				dataTypeSettings={field.dataTypeSettings}
				onChange={onInputChange}
				updating={isAnyBeingUpdated}
				onFocusLossForceBlur={true}
			/>
		) : (
			<ClickToEditInputV5
				dataType={field.dataType}
				onLiveChange={onInputChange}
				value={inputValue}
				dataTypeSettings={field.dataTypeSettings}
				onChange={onInputChange}
				updating={isAnyBeingUpdated}
				onFocusLossForceBlur={true}
			/>
		);
	};

	function getIsAnyBeingUpdated(): boolean {
		const {items, field} = props;
		const {itemFieldUpdateManager} = appState;

		return items.some((item: IModel) => itemFieldUpdateManager.isItemFieldBeingUpdated(item.id, field.refId, item.ownFeature));
	}

	function getInitialInputValueForFieldType() {
		const {field} = props;

		if ([FieldDataType.Numeric, FieldDataType.DateTime].includes(field.dataType)) {
			return undefined;
		} else if (field.dataType === FieldDataType.Boolean) {
			return false;
		} else {
			return "";
		}
	}

	useImperativeHandle(ref, () => ({
		onApply,
	}));

	{
		const {items, field} = props;
		const actions = appState.actions;

		const options: IOption[] = [
			{
				id: "all",
				label: "All",
			},
		];

		let allValuesMatch = true;
		let blankAdded = false;

		let first = true;
		let firstValue: string;

		for (const item of items) {
			const hasValue = actions.hasOwnFieldValue(item, field.refId);

			if (!hasValue) {
				continue;
			}

			// Note: if this is changed to getValue, then firstValue and option.label needs to be formatted to string
			// to avoid crashes from React.
			const value = actions.renderValue(item, field.refId);
			const valueHiddenByFieldMasking = appState.actions.isFieldHiddenByMasking(item, field);

			if (first) {
				firstValue = value;
				first = false;
			}
			if (value !== firstValue && !valueHiddenByFieldMasking) {
				allValuesMatch = false;
			}

			if (StringUtils.isBlank(value) && !valueHiddenByFieldMasking) {
				if (!blankAdded) {
					options.push({
						id: "blank",
						label: "All Blanks",
					});
					blankAdded = true;
				}
			} else {
				const id = `field-${value}`;

				if (!options.some((option) => option.id === id)) {
					options.push({
						id: id,
						label: value,
						value: actions.getFieldValue(item, field.refId),
					});
				}
			}
		}

		// const disabled = field.hasValidation;

		return (
			<div className="MassFieldInput">
				<div className="container">
					<OptionSelectorInputStyled>
						<SelectInputV5
							options={options}
							render={(option) => option.label}
							selected={options.find((option) => option.id === selectedOptionId)}
							onChange={onOptionChange}
						/>
					</OptionSelectorInputStyled>
					{getEditInput()}
				</div>
			</div>
		);
	}
});

export const OptionSelectorInputStyled = styled.div`
	${VerticalFlexStyle}
	margin-bottom: ${baseDistance.sm};
	align-items: flex-start;

	> div {
		border-color: transparent;

		> div.input {
			flex: none;
			width: auto;
		}
	}
`;
