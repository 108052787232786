import styled from "styled-components";
import type {Catalog} from "../../../data/models/Catalog";
import {XyiconFeature, Permission} from "../../../generated/api/base";
import {Functions} from "../../../utils/function/Functions";
import DotsHorizontalIcon from "../icons/dots-horizontal.svg?react";
import TrashIcon from "../icons/trash.svg?react";
import InfoIcon from "../icons/info.svg?react";
import UnlinkIcon from "../icons/unlink.svg?react";
import PlusIcon from "../icons/plus.svg?react";
import {DropdownButtonStyled, DropdownButtonV5} from "../interaction/DropdownButtonV5";
import type {IDropdownOption} from "../interaction/DropdownOptionsV5";
import {useAppStore} from "../../../StateManager";
import {colorPalette} from "../styles/colorPalette";
import {IconButtonStyled, IconButtonV5} from "../interaction/IconButtonV5";
import {baseDistance, radius} from "../styles/styles";
import {getCatalogItemOptionsV5} from "./CatalogItemOptionsV5";

interface ISpaceItemActionButtons {
	readonly feature: XyiconFeature;
	readonly isFavorite?: boolean;
	readonly itemTypeId?: string;
	readonly isCrossPortfolioXyicon: boolean;
	readonly showDeleteButton: boolean;
	readonly showInfoButton: boolean;
	readonly catalog?: Catalog;
	readonly options?: IDropdownOption[];
	readonly onFavoriteClick?: () => void;
	readonly onUnFavoriteClick?: () => void;
	readonly onCreateUnplottedXyiconClick?: () => void;
	readonly onBreakLinkClick?: () => void;
	readonly onDetailsClick?: () => void;
	readonly deleteItem?: () => void;
	readonly onDuplicateCatalogClick?: (item: Catalog) => void;
	readonly onDeleteCatalogClick?: (item: Catalog) => void;
}

export const SpaceItemActionButtonsV5 = (props: ISpaceItemActionButtons) => {
	const appState = useAppStore((state) => state.appState);

	const {
		feature,
		isFavorite,
		itemTypeId,
		catalog,
		isCrossPortfolioXyicon,
		showInfoButton,
		showDeleteButton,
		onBreakLinkClick,
		onDetailsClick,
		onFavoriteClick,
		onUnFavoriteClick,
		onCreateUnplottedXyiconClick,
		onDuplicateCatalogClick,
		onDeleteCatalogClick,
		deleteItem,
		options,
	} = props;

	const isSpaceEditorMounted = location.hash.includes("space/");
	const userCatalogPermission = appState.user.getOrganizationPermission(XyiconFeature.XyiconCatalog);
	const userXyiconPermission = appState.actions.getModuleTypePermission(itemTypeId, XyiconFeature.Xyicon);
	const hasSpaceItemPermission = feature === XyiconFeature.Boundary || userXyiconPermission > Permission.View;

	return (
		<SpaceItemActionButtonsStyled className="SpaceItemActionButtons buttonContainer hbox alignCenter">
			{!isSpaceEditorMounted && feature === XyiconFeature.XyiconCatalog && hasSpaceItemPermission && (
				<IconButtonV5
					IconComponent={PlusIcon}
					title="Create unplotted xyicon"
					onClick={onCreateUnplottedXyiconClick}
				/>
			)}
			{options?.length > 0 ? (
				<DropdownButtonV5
					button={<DotsHorizontalIcon />}
					options={options}
				/>
			) : (
				!isCrossPortfolioXyicon &&
				feature === XyiconFeature.XyiconCatalog &&
				userCatalogPermission > Permission.View &&
				hasSpaceItemPermission && (
					<DropdownButtonV5
						button={<DotsHorizontalIcon />}
						options={getCatalogItemOptionsV5({
							isFavorite,
							app: appState.app,
							item: catalog,
							onDeleteCatalogClick,
							onDuplicateCatalogClick,
							userCatalogPermission,
							onFavoriteClick,
							onUnFavoriteClick,
						})}
					/>
				)
			)}
			{onBreakLinkClick && onBreakLinkClick !== Functions.emptyFunction && hasSpaceItemPermission && (
				<IconButtonV5
					IconComponent={UnlinkIcon}
					title="Break Link"
					onClick={onBreakLinkClick}
				/>
			)}
			{showInfoButton && hasSpaceItemPermission && (
				<IconButtonV5
					IconComponent={InfoIcon}
					title="Details"
					onClick={onDetailsClick}
				/>
			)}
			{showDeleteButton && deleteItem && userXyiconPermission === Permission.Delete && hasSpaceItemPermission && (
				<IconButtonV5
					IconComponent={TrashIcon}
					title="Delete"
					onClick={deleteItem}
				/>
			)}
		</SpaceItemActionButtonsStyled>
	);
};

const SpaceItemActionButtonsStyled = styled.div`
	padding-right: ${baseDistance.sm};
	display: flex;
	gap: ${baseDistance.xs};

	${IconButtonStyled}, ${DropdownButtonStyled} {
		min-width: 24px;
		min-height: 24px;
		padding: 2px;
		height: 24px;
		width: 24px;
		border: 1px solid transparent;
		border-radius: ${radius.sm};

		&:hover {
			border-color: ${colorPalette.gray.c950};
		}
	}
`;
