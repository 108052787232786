import {useState} from "react";
import SearchIcon from "../../icons/search.svg?react";
import type {ITextInputProps} from "../../../widgets/input/text/TextInput";
import {useClickOutside} from "../../utils";
import {TextInputV5} from "../../details/datatypes/TextInputV5";
import {SearchFieldStyled} from "./SearchField.styled";

interface ISearchFieldV5Props extends Partial<ITextInputProps> {
	readonly value: string;
	readonly placeholder?: string;
	readonly showCancelIcon?: boolean;
	readonly minimized?: boolean;
	readonly showClearIcon?: boolean;
	readonly fullWidth?: boolean;
	readonly autoFocus?: boolean;
	readonly resetSearch?: () => void;
	readonly triggerSearch?: () => void;
}

export const SearchFieldV5 = (props: ISearchFieldV5Props) => {
	const {placeholder = "Find", value, minimized, autoFocus = false, fullWidth = false} = props;
	const [extended, setExtended] = useState<boolean>(false);

	const onOpen = () => setExtended(true);
	const onClose = () => {
		if (!value) {
			setExtended(false);
		}
	};

	const onSearchFieldClick = (e: React.MouseEvent<HTMLDivElement>) => {
		// e.stopPropagation();
		onOpen();
	};

	const handleSearchIconClick = () => {
		props.triggerSearch?.();
	};

	useClickOutside([], onClose);

	return (
		<SearchFieldStyled
			className="SearchField"
			$fullWidth={fullWidth}
			$minimized={!!minimized}
			onClick={onSearchFieldClick}
			$extended={extended}
		>
			<TextInputV5
				{...props}
				placeholder={placeholder ?? "Find"}
				inputType="search"
			/>
			<SearchIcon onClick={handleSearchIconClick} />
		</SearchFieldStyled>
	);
};
