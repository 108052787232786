import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {Navigation} from "../../../Navigation";
import type {AppState} from "../../../data/state/AppState";
import {XyiconFeature} from "../../../generated/api/base";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {useAppStore} from "../../../StateManager";
import ArrowDownToBracketIcon from "../icons/arrow-down-to-bracket.svg?react";
import CircleQuestionIcon from "../icons/circle-question.svg?react";
import BugIcon from "../icons/bug.svg?react";
import LightbulbIcon from "../icons/lightbulb-alt.svg?react";
import NotebookIcon from "../icons/notebook.svg?react";
import KeyboardIcon from "../icons/keyboard.svg?react";
import MessageSquareExclamationIcon from "../icons/message-square-exclamation.svg?react";
import {FeatureImportPanelV5} from "../abstract/view/FeatureImportPanelV5";
import {NavigationMenuItem} from "../navigation/NavigationMenuItem";
import {TopBarBackButtonV5} from "../topbar/TopBarBackButtonV5";
import {TimeUtils} from "../../../utils/TimeUtils";
import {ShortcutWindowV5} from "../popup/ShortcutWindowV5";
import {TopBarDropdownV5} from "../dropdown/TopBarDropdownV5";
import {HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {NavigationEnum} from "../../../Enums";
import {colorPalette} from "../styles/colorPalette";
import {baseDistance, fontSize, fontWeight, radius} from "../styles/styles";
import {OrganizationSettingsV5} from "./organization/OrganizationSettingsV5";
import {MyProfileV5} from "./myProfile/MyProfileV5";
import {PermissionSetsV5} from "./permissions/permissionsets/PermissionSetsV5";
import {SettingOption, SettingsLabelContentStyled} from "./SettingOptionV5";
import {ModuleSettingsV5} from "./modules/ModuleSettingsV5";
import {UsersViewV5} from "./permissions/users/UsersViewV5";
import {UserGroupsViewV5} from "./permissions/usergroups/UserGroupsViewV5";
import {PortfolioGroupsViewV5} from "./permissions/portfoliogroups/PortfolioGroupsViewV5";

interface ISettingsViewProps {
	readonly param1?: string;
	readonly param2?: string;
	readonly transport?: TransportLayer;
	readonly navigation?: Navigation;
	readonly appState?: AppState;
}
interface ISettingsViewState {
	selectedParent: string;
	tree: ISetting[];
}
interface ISetting {
	id: string;
	label: string;
	component?: React.ComponentType<any>;
	props?: any;
	children?: ISetting[];
}

enum SettingsItems {
	PERSONAL = "personal",
	ORGANIZATION = "organization",
	PERMISSIONS = "permissions",
	USERS = "users",
	USER_GROUPS = "usergroups",
	PORTFOLIO_GROUPS = "portfoliogroups",
	PERMISSION_SETS = "permissionsets",
	MODULES = "modules",
	PORTFOLIO = "portfolio",
	SPACE = "space",
	CATALOG = "catalog",
	XYICON = "xyicon",
	BOUNDARY = "boundary",
}

export const SettingsViewV5 = (props: ISettingsViewProps) => {
	const {param1, param2} = props;
	const {appState, navBarState, setNavBarState} = useAppStore();
	const setModulesTypeAndFieldSearchString = useAppStore((state) => state.setModulesTypeAndFieldSearchString);
	const {
		app: {navigation, modalContainer},
		user,
	} = appState;
	const isUserAdmin = user?.isAdmin;

	const helpRef = useRef<HTMLDivElement>(null);
	const [importOpen, setImportOpen] = useState<boolean>(false);
	const [isShortcutOpen, setIsShortcutOpen] = useState<boolean>(false);
	const [isHelpHovered, setIsHelpHovered] = useState<boolean>(false);
	const [iSettingsState, setISettingsState] = useState<ISettingsViewState>({
		selectedParent: "",
		tree: [
			{
				id: "personal",
				label: "My Profile",
				component: MyProfileV5,
			},
			{
				id: "organization",
				label: "Organization",
				component: OrganizationSettingsV5,
			},
			{
				id: "permissions",
				label: "Permissions",
				children: [
					{
						id: "users",
						label: "Users",
						component: UsersViewV5,
					},
					{
						id: "usergroups",
						label: "User Groups",
						component: UserGroupsViewV5,
					},
					{
						id: "portfoliogroups",
						label: "Portfolio Groups",
						component: PortfolioGroupsViewV5,
					},
					{
						id: "permissionsets",
						label: "Permission Sets",
						component: PermissionSetsV5,
					},
				],
			},
			{
				id: "modules",
				label: "Modules",
				children: [
					{
						id: "portfolio",
						label: "Portfolio",
						component: ModuleSettingsV5,
						props: {
							feature: XyiconFeature.Portfolio,
							icon: "portfolio",
						},
					},
					{
						id: "space",
						label: "Space",
						component: ModuleSettingsV5,
						props: {
							feature: XyiconFeature.Space,
							icon: "spaces",
						},
					},
					{
						id: "catalog",
						label: "Catalog",
						component: ModuleSettingsV5,
						props: {
							feature: XyiconFeature.XyiconCatalog,
							icon: "catalog",
						},
					},
					{
						id: "xyicon",
						label: "Xyicon",
						component: ModuleSettingsV5,
						props: {
							feature: XyiconFeature.Xyicon,
							icon: "xyicons",
						},
					},
					{
						id: "boundary",
						label: "Boundary",
						component: ModuleSettingsV5,
						props: {
							feature: XyiconFeature.Boundary,
							icon: "boundaries",
						},
					},
				],
			},
		],
	});
	const selected = param1 || iSettingsState.tree[0].id;

	useEffect(() => {
		if (navBarState !== "hidden") {
			setNavBarState("hidden");
		}
	}, [navBarState, setNavBarState]);

	const onSelectOption = (id: string, parentId = "") => {
		setModulesTypeAndFieldSearchString("");
		if (parentId === "modules") {
			const parts = StringUtils.decomposeParts(location.hash);
			let settingsPart = "types";

			if (parts[3]?.value && parts[2]?.value !== "permissionsets") {
				settingsPart = parts[3]?.value;
			}
			navigation.goApp(NavigationEnum.NAV_SETTINGS, id, settingsPart);
		} else if (parentId === "permissions" && id === "permissionsets") {
			const parts = StringUtils.decomposeParts(location.hash);
			let settingsPart = "portfolios";

			if (parts[3]?.value && parts[2]?.value === "permissionsets") {
				settingsPart = parts[3]?.value;
			}
			navigation.goApp(NavigationEnum.NAV_SETTINGS, id, settingsPart);
		} else {
			navigation.goApp(NavigationEnum.NAV_SETTINGS, id);
		}
	};

	const searchComponent = (id: string, params: any, settings?: ISetting[]): React.ReactElement<any, any> => {
		settings = settings || iSettingsState.tree;

		for (const setting of settings) {
			if (setting.id === id) {
				const Component = setting.component;
				const props = setting.props || {};

				if (Component) {
					return (
						<Component
							{...props}
							{...params}
						/>
					);
				}
			}

			if (setting.children) {
				const result = searchComponent(id, params, setting.children);

				if (result) {
					return result;
				}
			}
		}
	};

	const parentSettingOptionClick = (settingOption: ISetting) => {
		const {selectedParent} = iSettingsState;

		// In web view, don't navigate to parent if childrens are available
		if (settingOption.children?.length) {
			return;
		}

		if (!settingOption.children?.length) {
			onSelectOption(settingOption.id);
		}

		if (!selectedParent || selectedParent !== settingOption.id) {
			setISettingsState((prevState) => ({...prevState, selectedParent: settingOption.id}));
		} else {
			setISettingsState((prevState) => ({...prevState, selectedParent: ""}));
		}
	};

	// Only supports one level of nesting now
	const component = searchComponent(selected, {
		param2: param2,
	});

	// This logic is implemented to support the new design. Elements of the Modules are taken to the top level
	const settingsNavItems = iSettingsState.tree.flatMap((settingOption, index) => {
		let moduleItems: ISetting[] = [];

		if (settingOption.id === SettingsItems.MODULES && settingOption.children) {
			moduleItems.push(...settingOption.children);
		} else {
			moduleItems.push(settingOption);
		}
		return moduleItems;
	});

	const onToggleLiveChatBox = () => {
		const liveChat: HTMLDivElement = document.querySelector("#hubspot-messages-iframe-container.widget-align-left");

		liveChat?.classList.toggle("open");
		setIsHelpHovered(false);
	};

	const onShortCutClicked = async () => {
		if (!isShortcutOpen) {
			setIsShortcutOpen(true);
		} else {
			setIsShortcutOpen(false);
			await TimeUtils.waitForNextFrame();
			setIsShortcutOpen(true);
		}
		setIsHelpHovered(false);
	};

	const onCloseShortcut = () => {
		setIsShortcutOpen(false);
	};

	const onDocsClicked = () => {
		setIsHelpHovered(false);
		appState.app.navigation.openInNewTab("https://support.xyicon.com");
	};

	const onFeatureClicked = () => {
		setIsHelpHovered(false);
		appState.app.navigation.openInNewTab("https://support.xyicon.com/docs/submit-a-feature-request");
	};

	const onBugClicked = () => {
		setIsHelpHovered(false);
		appState.app.navigation.openInNewTab("https://support.xyicon.com/docs/report-an-issue");
	};

	const helpElements = [
		{
			icon: <MessageSquareExclamationIcon />,
			onMouseDown: onToggleLiveChatBox,
			label: "Live Chat",
		},
		{
			icon: <KeyboardIcon />,
			onMouseDown: onShortCutClicked,
			label: "Keyboard Shortcuts",
		},
		{
			icon: <NotebookIcon />,
			onMouseDown: onDocsClicked,
			label: "Help Documents",
		},
		{
			icon: <LightbulbIcon />,
			onMouseDown: onFeatureClicked,
			label: "Suggest a Feature",
		},
		{
			icon: <BugIcon />,
			onMouseDown: onBugClicked,
			label: "Report a Problem",
		},
	];

	return (
		<>
			<SettingsContainerStyled>
				<SettingsTabStyled>
					<SettingsBackButtonContainerStyled>
						<TopBarBackButtonV5 />
						<SettingsTitleStyled>SETTINGS</SettingsTitleStyled>
					</SettingsBackButtonContainerStyled>
					<HorizontalSeparatorStyled />
					<NavItemsContainerStyled>
						{settingsNavItems.map((settingOption, index) => {
							if ((!isUserAdmin && ["My Profile", "Organization"].includes(settingOption.label)) || isUserAdmin) {
								return (
									<SettingsContentStyled key={settingOption.id}>
										<SettingOption
											key={settingOption.id}
											label={settingOption.label}
											selected={selected === settingOption.id}
											onClick={() => parentSettingOptionClick(settingOption)}
											group={settingOption.children?.length > 0}
										>
											{settingOption.children && (
												<ChildrenContentStyled>
													{settingOption.children.map((child, index) => (
														<SettingOption
															isHelpHovered={isHelpHovered}
															key={child.id}
															label={child.label}
															selected={selected === child.id}
															onClick={() => onSelectOption(child.id, settingOption.id)}
														/>
													))}
												</ChildrenContentStyled>
											)}
										</SettingOption>
										{settingOption.children?.length > 0 && iSettingsState.selectedParent === settingOption.id && (
											<div>
												{settingOption.children.map((child, index) => (
													<SettingOption
														key={child.id}
														label={child.label}
														selected={selected === child.id}
														onClick={() => {
															onSelectOption(child.id, settingOption.id);
															setISettingsState((prevState) => ({...prevState, selectedParent: ""}));
														}}
													/>
												))}
											</div>
										)}
									</SettingsContentStyled>
								);
							}
						})}
					</NavItemsContainerStyled>
					<WidgetMenuContainerStyled>
						<NavigationMenuItem
							icon={ArrowDownToBracketIcon}
							label="Import"
							onClick={() => setImportOpen(true)}
							small={true}
						/>
						<LeftNavHelpContainerStyled
							ref={helpRef}
							onMouseDown={() => setIsHelpHovered(true)}
							onMouseLeave={() => setIsHelpHovered(false)}
						>
							<NavigationMenuItem
								icon={CircleQuestionIcon}
								label="Help"
								onClick={() => setIsHelpHovered(true)}
								small={true}
							/>
						</LeftNavHelpContainerStyled>
						{importOpen && <FeatureImportPanelV5 onClose={() => setImportOpen(false)} />}
					</WidgetMenuContainerStyled>
				</SettingsTabStyled>
				<ComponentContainerStyled>{component}</ComponentContainerStyled>
			</SettingsContainerStyled>
			{isHelpHovered && (
				<LeftNavHelpPopupStyled
					onMouseLeave={() => setIsHelpHovered(false)}
					onMouseOver={() => setIsHelpHovered(true)}
				>
					<TopBarDropdownV5
						elements={helpElements}
						parent={helpRef}
						horizontalAlignment={HorizontalAlignment.outerRight}
						verticalAlignment={VerticalAlignment.bottom}
					/>
				</LeftNavHelpPopupStyled>
			)}
			{isShortcutOpen && (
				<DomPortal destination={document.body}>
					<ShortcutWindowV5 onClose={onCloseShortcut} />
				</DomPortal>
			)}
		</>
	);
};

const SettingsContainerStyled = styled.div`
	display: flex;
	flex: 1 1 0px;
	/* min-width: 220px; */
	border-right: 1px solid ${colorPalette.gray.c300};
	position: relative;
	height: 100vh;
`;

const SettingsTabStyled = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 200px;
	padding: ${baseDistance.md};
	border-right: 1px solid ${colorPalette.gray.c300};
	height: 100%;
	gap: ${baseDistance.md};
	position: absolute;
	margin-bottom: 0;
	top: -65px;
	background-color: ${colorPalette.white};
	z-index: 12;

	@media screen and (max-width: 1366px) and (max-height: 768px) {
		overflow-y: auto;
	}
`;

const SettingsContentStyled = styled.div`
	display: flex;
`;

const ComponentContainerStyled = styled.div`
	margin-left: 200px;
	width: 100%;
	display: flex;
`;

const ChildrenContentStyled = styled.div`
	display: flex;
	flex-direction: column;
	text-indent: 16px;
	padding: 0px, 0px, 0px, ${baseDistance.sm};

	${SettingsLabelContentStyled} {
		font-weight: ${fontWeight.normal};
	}
`;

const WidgetMenuContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.sm};
	margin-top: auto;
`;

const SettingsBackButtonContainerStyled = styled.div`
	display: flex;
	align-items: center;
	gap: ${baseDistance.sm};
`;

const SettingsTitleStyled = styled.div`
	font-size: ${fontSize.md};
	font-weight: ${fontWeight.bold};
	letter-spacing: 0.01em;
`;
const HorizontalSeparatorStyled = styled.div`
	width: 168px;
	height: 2px;
	border-radius: ${radius.sm};
	background-color: ${colorPalette.gray.c100};
`;

const LeftNavHelpContainerStyled = styled.div``;

const LeftNavHelpPopupStyled = styled.div``;

const NavItemsContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.sm};
`;
