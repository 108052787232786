import * as React from "react";
import {inject, observer} from "mobx-react";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {Navigation} from "../../../Navigation";
import type {AppState} from "../../../data/state/AppState";
import {SVGIcon} from "../../widgets/button/SVGIcon";
import {FormContainerStyled, LoginWindowStyled} from "../user/UserStaticV5.Style";
import {LoaderIconV5} from "../loader/LoaderIconV5";
import CircleQuestionIcon from "../icons/circle-question.svg?react";
import {IconButton} from "../../widgets/button/IconButton";
import {handleLogin} from "../../../utils/LoginUtils";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {ReactUtils} from "../../utils/ReactUtils";

interface ILoginWindowProps {
	readonly navigation?: Navigation;
	readonly transport?: TransportLayer;
	readonly appState?: AppState;
	readonly isOrganizationSwitchView?: boolean;
	readonly handleClose?: () => void;
	readonly handleOrganizationSwitch?: () => Promise<void>;
}

interface ILoginWindowState {
	message: string;
	isLoading: boolean;
	loginWithSSO: boolean;
	isValidEmail?: boolean;
	inputString: string;
}

@inject("navigation")
@inject("transport")
@inject("appState")
@observer
export class LoginWindowV5 extends React.Component<ILoginWindowProps, ILoginWindowState> {
	constructor(props: ILoginWindowProps) {
		super(props);

		this.state = {
			message: "",
			isLoading: false,
			loginWithSSO: false,
			isValidEmail: false,
			inputString: "",
		};
	}

	public override componentDidMount() {
		window.addEventListener("hashchange", this.onHashChange);
	}

	public override componentWillUnmount() {
		window.removeEventListener("hashchange", this.onHashChange);
	}

	private onHashChange = () => {
		this.props.appState.isLoggingIn = false;
	};

	private onForgottenPasswordClick = () => {
		this.props.navigation.go("auth/forgotten_password");
	};

	private handleLoginWithSSO = () => {
		this.setState((prev) => ({
			loginWithSSO: !prev.loginWithSSO,
			message: "",
			inputString: "",
			isValidEmail: false,
		}));
	};

	private validateInputs = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {
			target: {value},
		} = event;
		const isValid = StringUtils.emailValidator(value);
		if (isValid) {
			this.setState({isValidEmail: true, inputString: value});
		} else {
			this.setState({isValidEmail: false, inputString: value});
		}
	};

	public override render() {
		const message = this.state.message || this.props.appState.lastError || "";
		const {isLoggingIn} = this.props.appState;
		const {user} = this.props.appState;
		const {loginWithSSO, isLoading, isValidEmail, inputString} = this.state;
		const {isOrganizationSwitchView = false} = this.props;
		const isDisabled = isLoggingIn || isLoading || (!isValidEmail && loginWithSSO);

		return (
			<LoginWindowStyled className="LoginWindow">
				<FormContainerStyled
					className="formContainer hbox alignCenter login"
					$loginWithSSO={loginWithSSO}
					$isOrganizationSwitchView={isOrganizationSwitchView}
				>
					{isOrganizationSwitchView && (
						<div className="closeButtonContainer">
							<IconButton
								className="close"
								icon="close"
								onClick={this.props.handleClose}
							/>
						</div>
					)}
					<SVGIcon
						classNames="xyicon-logo"
						icon="xyiconlogo"
					/>
					<form
						className="vbox"
						role="form"
						name="formLogin"
						noValidate
						autoCapitalize="off"
						onSubmit={this.onSubmit}
					>
						<div className="inputGroup login">
							<div className="labelName">{loginWithSSO ? "Email" : "Username"}</div>
							<input
								id="userName"
								name="userName"
								type="text"
								className="Input"
								placeholder={loginWithSSO ? "Email" : "Username"}
								onChange={(event) => this.validateInputs(event)}
								value={isOrganizationSwitchView ? user?.email : inputString}
								disabled={isOrganizationSwitchView}
							/>
						</div>
						{!loginWithSSO && (
							<div className="inputGroup login">
								<div className="labelName">Password</div>
								<input
									id="pwInput"
									name="pwInput"
									type="password"
									className="Input"
									placeholder="************"
									autoComplete="off"
								/>
								{!isOrganizationSwitchView && (
									<div
										className="forgotPassword labelName"
										onClick={this.onForgottenPasswordClick}
									>
										Forgot Password?
									</div>
								)}
							</div>
						)}
						<button
							name="submitButton"
							className={ReactUtils.cls("Button primary", {disabled: isDisabled})}
							type="submit"
							disabled={isDisabled}
						>
							{isLoading ? (
								<div className="loading">
									<LoaderIconV5 />
								</div>
							) : loginWithSSO ? (
								"Login with SSO"
							) : (
								"Log in"
							)}
						</button>
						{!isOrganizationSwitchView && (
							<div className="sso-button-container">
								<button
									type="button"
									className="sso-login-text"
									disabled={isLoading}
									onClick={this.handleLoginWithSSO}
								>
									{loginWithSSO ? "Go back to login page" : "or login with SSO"}
								</button>
							</div>
						)}
						<div className="labelName help">
							<a
								href="https://support.xyicon.com/docs"
								target="_blank"
							>
								<CircleQuestionIcon />
								<p>Help</p>
							</a>
						</div>
						{!loginWithSSO && <div className="errorMessage">{message}</div>}
					</form>
				</FormContainerStyled>
			</LoginWindowStyled>
		);
	}

	private handleLoginStateChange = (state: ILoginWindowState) => {
		this.setState(state);
	};

	private onSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
		event.preventDefault();
		const {transport, appState, isOrganizationSwitchView, navigation, handleClose, handleOrganizationSwitch} = this.props;
		const {loginWithSSO} = this.state;
		this.props.appState.lastError = "";
		this.props.appState.isLoggingIn = true;

		await handleLogin({
			event,
			transport,
			appState,
			navigation,
			isOrganizationSwitchView,
			loginWithSSO,
			handleClose,
			handleOrganizationSwitch,
			handleLoginStateChange: this.handleLoginStateChange,
		});

		this.props.appState.isLoggingIn = false;
	};
}
