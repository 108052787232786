import type {IInputProps} from "../../FieldInput";
import {SelectInput} from "../../../select/SelectInput";
import type {ISingleSelectFieldSettingsDefinition} from "../../../../../../data/models/field/datatypes/SingleSelect";
import {HorizontalAlignment} from "../../../../../../utils/dom/DomUtils";

export const SingleSelectInput = (props: IInputProps<ISingleSelectFieldSettingsDefinition>) => {
	const {value, onChange, disabled, onFocusLossForceBlur, inline, focused} = props;
	const list = [...(props.dataTypeSettings.choiceList || [])];

	const selectedOption = list.find((item) => item.value === value);
	const selectedIsRemoved = value && !selectedOption;

	if (selectedIsRemoved) {
		list.push({value: value, color: ""});
	}

	let focus = focused;

	const onFocus = (value: boolean) => {
		if (!value) {
			focus = false;
		}
	};

	const onClick = () => {
		props.onClick?.();
	};

	const renderLabel = (option: {color: string; value: string}) => {
		return <>{option === value ? value : option.value}</>;
	};

	const fetchValueFromOption = (option: {color: string; value: string} | null) => {
		if (option === null) {
			onChange?.(null);
		} else {
			onChange?.(option.value);
		}
	};

	const renderOptionValue = (option: {color: string; value: string}) => {
		return option.value;
	};

	return (
		<SelectInput
			options={list}
			selected={value}
			selectedIsRemoved={selectedIsRemoved}
			disabled={disabled}
			nullOption={true}
			fullWidth={true}
			sort={false}
			onFocusLossForceBlur={onFocusLossForceBlur}
			focused={focus}
			onClick={onClick}
			onFocus={onFocus}
			inline={inline}
			horizontalAlignment={HorizontalAlignment.center}
			render={renderLabel}
			onChange={fetchValueFromOption}
			renderOptionValue={renderOptionValue}
			isSingleSelect={true}
		/>
	);
};
