import styled, {css} from "styled-components";
import {useEffect, useState} from "react";
import {useAppStore} from "../../../StateManager";
import {getFileTypeLabel} from "../../../data/models/FileType";
import type {IDropdownOption} from "../interaction/DropdownOptionsV5";
import {IconButtonV5} from "../interaction/IconButtonV5";
import {DropdownButtonV5} from "../interaction/DropdownButtonV5";
import DotsIcon from "../icons/dots-horizontal.svg?react";
import {Functions} from "../../../utils/function/Functions";
import {colorPalette} from "../styles/colorPalette";
import {baseDistance, ELLIPSIS, FlexCenter, FlexCenterStyle, fontSize, radius, VerticalFlex, VerticalFlexStyle, zIndex} from "../styles/styles";
import {FileUtils} from "../../../utils/file/FileUtils";
import type {DocumentSectionItem} from "./DocumentSectionV5";

export type TDocumentItemType = "card" | "tile";

interface IDocumentItemProps {
	readonly documentItem: DocumentSectionItem;
	readonly getOptions: (document: DocumentSectionItem) => IDropdownOption[];
	readonly type: TDocumentItemType;
}

export const DocumentItemV5 = (props: IDocumentItemProps) => {
	const appState = useAppStore((state) => state.appState);
	const {transport} = appState.app;
	const {documentItem, getOptions, type} = props;
	const doc = documentItem.document;
	const fileTypeLabel = getFileTypeLabel(doc.fileType);
	const thumbnailPath = doc.thumbnailFileURL;
	const [docSize, setDocSize] = useState<string>("");

	useEffect(() => {
		FileUtils.getFileSize(doc.fileUrl).then((res) => setDocSize(res));
	}, [props.documentItem.document, doc, transport.services.document]);

	return (
		<DocumentItemStyled
			key={doc.id}
			onClick={getOptions(documentItem)[0]?.onClick}
			$type={props.type}
		>
			<FlexCenter $flex="1">
				<VerticalFlex
					$flex="1"
					style={{minWidth: 0}}
				>
					<span className="name">{doc.fileName}</span>
					<span>{fileTypeLabel}</span>
					<span>{docSize}</span>
				</VerticalFlex>
				<DropdownButtonV5
					className="dropdown"
					button={
						<IconButtonV5
							IconComponent={DotsIcon}
							onClick={Functions.emptyFunction}
						/>
					}
					options={getOptions(documentItem)}
					optionsZIndex={zIndex.contextOptions}
				/>
			</FlexCenter>
			<DocumentThumbnailStyled
				key={doc.id}
				onClick={getOptions(documentItem)[0]?.onClick}
				$src={thumbnailPath}
				$type={type}
			/>
		</DocumentItemStyled>
	);
};

const DocumentThumbnailStyled = styled.div<{$src: string; $type: TDocumentItemType}>`
	background-image: url(${(props) => props.$src});
	background-size: cover;
	background-position: center;
	height: 76px;
	border-radius: ${radius.sm};

	${(props) =>
		props.$type === "card" &&
		css`
			height: 40px;
			width: 40px;
		`};
`;

const DocumentItemStyled = styled.div<{$type: TDocumentItemType}>`
	${(porps) => (porps.$type === "tile" ? VerticalFlexStyle : FlexCenterStyle)};

	${(props) => {
		if (props.$type === "tile") {
			return css`
				${VerticalFlexStyle}
			`;
		} else {
			return css`
				${FlexCenterStyle};
				flex-direction: row-reverse;
				gap: ${baseDistance.sm};
			`;
		}
	}}

	flex: 1;
	position: relative;
	cursor: pointer;
	border-radius: ${radius.sm};
	font-size: ${fontSize.md};
	padding: ${baseDistance.sm};

	&:hover {
		.dropdown {
			display: block;
		}
	}

	.dropdown {
		display: none;
	}

	span {
		color: ${colorPalette.gray.c700Dark};
		${ELLIPSIS};

		&.name {
			color: ${colorPalette.gray.c950};
		}
	}

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}

	&:active {
		background-color: ${colorPalette.blueGray.c200Light};

		span.name {
			color: ${colorPalette.primary.c500Primary};
		}
	}
`;
