import type {SpaceViewRenderer} from "../../../renderers/SpaceViewRenderer";
import type {MeasureType} from "../../../renderers/SpaceViewRendererUtils";
import type {MarkupCallout} from "../MarkupCallout";
import {TextGroupManager} from "../../../managers/MSDF/TextGroupManager";
import type {SpaceTool} from "../../../features/tools/Tools";
import type {Markup} from "../../../../../../../../data/models/Markup";
import type {MarkupDto, MarkupUpdateDetail, PointDouble, UpdateMarkupRequest} from "../../../../../../../../generated/api/base";
import {MarkupType} from "../../../../../../../../generated/api/base";
import {MathUtils} from "../../../../../../../../utils/math/MathUtils";
import {THREEUtils} from "../../../../../../../../utils/THREEUtils";
import {TimeUtils} from "../../../../../../../../utils/TimeUtils";
import {MarkupsWithCustomizableFillOpacity} from "../MarkupStaticElements";
import type {IPreprocessableObjectWithText} from "../../../managers/MSDF/TextUtils";
import {preprocessObjectWithText} from "../../../managers/MSDF/TextUtils";
import {Constants} from "../../../Constants";
import {ObjectUtils} from "../../../../../../../../utils/data/ObjectUtils";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../../../../utils/dom/DomUtils";
import type {SupportedFontName} from "../../../../../../../../data/state/AppStateTypes";
import {XHRLoader} from "../../../../../../../../utils/loader/XHRLoader";
import type {IModel} from "../../../../../../../../data/models/Model";
import type {Markup3D} from "./Markup3D";

export interface IMarkupConfig {
	strokeColor?: string;
	strokeOpacity?: number;
	fill?: boolean;
	fillOpacity?: FillOpacity;
	dashSize?: number;
	gapSize?: number;
	measureType?: MeasureType;
	lineWidth?: number;

	// We should ignore the ones below this comment when saving to db, as they're for internal use only
	useSegments?: boolean;
	isHighLight?: boolean;

	// For temporary measure markups, which are not saved into the db
	isTemp?: boolean;
}

export type FillOpacity = 0 | 0.1 | 1;

export class MarkupUtils {
	public static getDefaultFillOpacityForType(type: MarkupType): FillOpacity {
		switch (type) {
			case MarkupType.Cloud:
			case MarkupType.TextBox:
			case MarkupType.Callout:
				return 0;
			case MarkupType.IrregularArea:
			case MarkupType.RectangleArea:
				return 0.1;
			default:
				return 1;
		}
	}
	public static readonly defaultLineThickness: number = 2; // px
	public static readonly defaultArrowHeadSize: number = 50; // px
}

export const isMarkupFilteredOutByColor = (hiddenMarkupColors: Set<string>, markup: Markup3D | Markup) => {
	return hiddenMarkupColors.has(getMarkupColorForColorFiltering(markup));
};

export const getMarkupColorForColorFiltering = (markup: Markup3D | Markup): string =>
	markup.type === MarkupType.TextBox ? markup.fontColor.hex : markup.color;

export const TempMarkupLineWidth = 3;

export const getDefaultTargetForMarkupCallout = (
	geometryData: PointDouble[],
	fontFamily: SupportedFontName,
	spaceViewRenderer: SpaceViewRenderer,
): PointDouble => {
	const cameraZoomValueMaybe = spaceViewRenderer.toolManager.cameraControls.cameraZoomValue;
	const cameraZoomValue = MathUtils.isValidNumber(cameraZoomValueMaybe) ? cameraZoomValueMaybe : 1;
	const correctionMultiplierMaybe = spaceViewRenderer.correctionMultiplier.current;
	const correctionMultiplier = MathUtils.isValidNumber(correctionMultiplierMaybe) ? correctionMultiplierMaybe : 0.005;
	const defaultSize = TextGroupManager.getDefaultMarkupTextBoxSize(MarkupType.Callout, fontFamily, cameraZoomValue, correctionMultiplier);

	const targetOffset: PointDouble = {
		x: defaultSize.x * 0.5,
		y: defaultSize.y,
	};

	const leftCenter = {
		x: Math.min(geometryData[0].x, geometryData[1].x),
		y: (geometryData[0].y + geometryData[1].y) / 2,
	};

	return {
		x: leftCenter.x - targetOffset.x,
		y: leftCenter.y - targetOffset.y,
	};
};

export const changeMarkupType = async (markups: Markup[], newType: MarkupType, spaceViewRenderer: SpaceViewRenderer) => {
	const markupManager = spaceViewRenderer.markupManager;
	const markupsToUpdate: Markup[] = [];
	const emptyTextBoxMarkups = markups.filter((m) => newType === MarkupType.TextBox && m.textContent.length === 0);

	spaceViewRenderer.inheritedMethods?.onExitTextEditMode?.();

	await TimeUtils.waitForNextFrame();
	await TimeUtils.waitForNextFrame();

	for (const markup of markups) {
		const hasChanged = markup.type !== newType;

		if (hasChanged) {
			if (newType === MarkupType.Callout) {
				markup.setTarget(getDefaultTargetForMarkupCallout(markup.geometryData, "Roboto", spaceViewRenderer));
			}

			const prevType = markup.type;

			markup.setType(newType);
			markup.setFillTransparency(
				MarkupsWithCustomizableFillOpacity.includes(prevType) ? markup.fillTransparency : 1 - MarkupUtils.getDefaultFillOpacityForType(newType),
			);
			markupsToUpdate.push(markup);

			const markup3DMaybe = spaceViewRenderer.markupManager.getItemById(markup.id);
			if (markup3DMaybe) {
				markup3DMaybe.destroy(false);
			}
		}
	}

	const markupsInCurrentSpace = markupsToUpdate.filter((markup) => markup.spaceId === spaceViewRenderer.space?.id);
	if (markupsInCurrentSpace.length > 0) {
		const markup3DArray = markupManager.addItemsByModel(markupsInCurrentSpace);

		for (const markup3D of markup3DArray) {
			markup3D.select();
		}

		spaceViewRenderer.spaceItemController.updateDetailsPanel();

		// Markup.data already changed, need to use "forceUpdate!!!"
		await updateMarkups(markupsToUpdate, spaceViewRenderer, true);

		if (emptyTextBoxMarkups.length === 1 && markups.length === 1) {
			spaceViewRenderer.inheritedMethods?.onSwitchToTextEditMode?.();
		}
	} else if (markupsToUpdate.length > 0) {
		// Markup.data already changed, need to use "forceUpdate!!!"
		await updateMarkups(markupsToUpdate, spaceViewRenderer, true);
	}
};

// useDefaultFillOpacity - useful when you change the type
export const updateMarkups = async (items: Markup[], spaceViewRenderer: SpaceViewRenderer, force: boolean = false) => {
	let textNeedsUpdate: boolean = false;

	const markups = [...items]; // the original array can be modified (eg.: length = 0), and it can cause bugs if we don't clone the array like this
	const markupUpdateDetail: MarkupUpdateDetail[] = [];

	for (const item of markups) {
		const isMarkupOnMountedSpace = spaceViewRenderer.space?.id === item.spaceId;
		const markup3D = isMarkupOnMountedSpace
			? (spaceViewRenderer.markupManager.getItemById(item.id) as Markup3D)
			: spaceViewRenderer.markupManager.createSpaceItem3DFromModel(item);

		const modelData = item;
		const previousData = {
			svgData: {
				geometryData: modelData.geometryData,
				color: modelData.color,
				fillTransparency: modelData.fillTransparency,
				lineThickness: modelData.lineThickness,
				text: modelData.text,
			},
			orientation: modelData.orientation,
			type: modelData.type,
			settings: modelData.settings,
		};

		const currentData = markup3D.data;

		const dataToCompare = {
			svgData: {
				geometryData: currentData.geometryData,
				color: currentData.color,
				fillTransparency: currentData.fillTransparency,
				lineThickness: currentData.lineThickness,
				text: currentData.text,
			},
			orientation: currentData.orientation,
			type: currentData.type,
			settings: currentData.settings,
		};

		const hasChanged = force || !modelData.isSettingsSaved || JSON.stringify(previousData) !== JSON.stringify(dataToCompare);

		if (hasChanged) {
			const dataToSend: MarkupUpdateDetail = {
				...dataToCompare,
				markupID: modelData.id,
			};

			if (currentData.text.content.length > 0) {
				textNeedsUpdate = true;
			}

			if (modelData.isTemp) {
				modelData.applyData(dataToSend);
			} else {
				markupUpdateDetail.push(dataToSend);
			}
		}

		if (!isMarkupOnMountedSpace) {
			markup3D.destroy(false);
		}
	}

	if (markupUpdateDetail.length > 0) {
		try {
			const appState = spaceViewRenderer.transport.appState;
			const spaceID = items[0].spaceId; // TODO: currently it's not possible to update markups from multiple spaces at once, but maybe in the future it will be...?
			const portfolioID = appState.portfolioId;

			const params: UpdateMarkupRequest = {
				markupUpdateDetail: markupUpdateDetail,
				spaceID: spaceID,
				portfolioID: portfolioID,
			};

			const {result} = await spaceViewRenderer.transport.requestForOrganization<MarkupDto[]>({
				url: "markups/update",
				method: XHRLoader.METHOD_POST,
				params: params,
			});

			const updatedMarkupArray: IModel[] = [];

			for (const newModelData of result) {
				const markup = markups.find((item) => item.id === newModelData.markupID);
				const markup3DMaybe = spaceViewRenderer.markupManager.getItemById(markup.id);

				if (markup3DMaybe) {
					markup3DMaybe.applyModelData(ObjectUtils.apply(JSON.parse(JSON.stringify((markup3DMaybe.modelData as Markup).data)), newModelData));
				} else {
					markup.applyData(ObjectUtils.apply(JSON.parse(JSON.stringify(markup.data)), newModelData));
				}
				updatedMarkupArray.push(markup);
			}

			if (spaceViewRenderer.isMounted) {
				const filteredUpdatedMarkupArray = updatedMarkupArray.filter((m) => m.spaceId === spaceViewRenderer.space?.id);
				if (filteredUpdatedMarkupArray.length > 0) {
					spaceViewRenderer.markupManager.signals.itemsUpdate.dispatch(filteredUpdatedMarkupArray);
				}
			}
		} catch (error) {
			console.warn("Items couldn't be updated on the backend!");
			console.warn(error);
		}
	}

	if (textNeedsUpdate) {
		// Workaround for the bug, when text is "below" the new markup
		requestAnimationFrame(() => {
			spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry();
		});
	}
};

export const markupTypeToMarkupToolName = (markupType: MarkupType): SpaceTool => {
	switch (markupType) {
		case MarkupType.Arrow:
			return "markupArrow";
		case MarkupType.BidirectionalArrow:
			return "markupBidirectionalArrow";
		case MarkupType.Callout:
			return "markupCallout";
		case MarkupType.Cloud:
			return "markupCloud";
		case MarkupType.Cross:
			return "markupCross";
		case MarkupType.DashedLine:
			return "markupDashedLine";
		case MarkupType.Ellipse:
			return "markupEllipse";
		case MarkupType.HighlightDrawing:
			return "markupHighlight";
		case MarkupType.IrregularArea:
			return "measureIrregularArea";
		case MarkupType.Line:
			return "markupLine";
		case MarkupType.LinearDistance:
			return "measureLinearDistance";
		case MarkupType.NonlinearDistance:
			return "measureNonLinearDistance";
		case MarkupType.PencilDrawing:
			return "markupPencil";
		case MarkupType.Rectangle:
			return "markupRectangle";
		case MarkupType.RectangleArea:
			return "measureRectArea";
		case MarkupType.TextBox:
			return "markupText";
		case MarkupType.Triangle:
			return "markupTriangle";
		case MarkupType.Photo360:
			return "markupPhoto360";
		default:
			return "markupCloud";
	}
};

export const getArrowPointsForMarkupCallout = (markup: MarkupCallout, useSavedTarget: boolean = false) => {
	const {position} = markup;

	const target = useSavedTarget ? (markup?.modelData as Markup)?.savedSettingsData?.target || markup.target : markup.target;

	const corners = markup.get4CornersAsWorldVertices();
	const potentialStartingPoints: PointDouble[] = [];

	for (let i = 0; i < corners.length; ++i) {
		const nextIndex = (i + 1) % corners.length;
		const middleOfLineSegment: PointDouble = {
			x: (corners[i].x + corners[nextIndex].x) / 2,
			y: (corners[i].y + corners[nextIndex].y) / 2,
		};

		potentialStartingPoints.push(middleOfLineSegment);
	}

	const potentialElbowPoints: PointDouble[] = potentialStartingPoints.map((v) => ({
		x: v.x + (v.x - position.x) * 0.5,
		y: v.y + (v.y - position.y) * 0.5,
	}));

	let minIndex: number = 0;
	let minDistance: number = Infinity;

	for (let i = 0; i < potentialStartingPoints.length; ++i) {
		const dist = THREEUtils.calculateDistance([potentialStartingPoints[i], target]);

		if (dist < minDistance) {
			minDistance = dist;
			minIndex = i;
		}
	}

	const startPos = potentialStartingPoints[minIndex];
	const elbowPos = potentialElbowPoints[minIndex];

	return {
		startPos,
		elbowPos,
		target,
	};
};

export function calculateArrowHead(a: PointDouble, b: PointDouble, arrowHeadSize: number, correctionMultiplier: number) {
	const localAtoLocalB = {
		x: b.x - a.x,
		y: b.y - a.y,
	};

	const normal = {
		x: -localAtoLocalB.y,
		y: localAtoLocalB.x,
	};

	const multiplicator = arrowHeadSize * correctionMultiplier;

	const bc = THREEUtils.multiplyByScalar(
		THREEUtils.normalize({
			x: normal.x - localAtoLocalB.x,
			y: normal.y - localAtoLocalB.y,
		}),
		multiplicator,
	);

	const bd = THREEUtils.multiplyByScalar(
		THREEUtils.normalize({
			x: -normal.x - localAtoLocalB.x,
			y: -normal.y - localAtoLocalB.y,
		}),
		multiplicator,
	);

	return {
		bc: bc,
		bd: bd,
	};
}

export function getUnrotatedCornersFromAB(a: PointDouble, b: PointDouble): PointDouble[] {
	// Without this, markup cloud curves are messed up
	return [
		{
			//bottom left, ccw
			x: Math.min(a.x, b.x),
			y: Math.min(a.y, b.y),
		},
		{
			x: Math.max(a.x, b.x),
			y: Math.min(a.y, b.y),
		},
		{
			x: Math.max(a.x, b.x),
			y: Math.max(a.y, b.y),
		},
		{
			x: Math.min(a.x, b.x),
			y: Math.max(a.y, b.y),
		},
	];
}

export const onMarkupTextInputChange = (markup3D: Markup3D, innerText: string, callback?: () => void) => {
	if (markup3D.type === MarkupType.TextBox || markup3D.type === MarkupType.Callout) {
		const {fontFamily, fontSize, fontColor, isBold, isItalic, isUnderlined, spaceViewRenderer} = markup3D;
		const modelData = markup3D.modelData as Markup;

		const tObject: IPreprocessableObjectWithText = {
			text: [{content: innerText}],
			fontSize,
			fontFamily,
			fontColor,
			isBold,
			isItalic,
			isUnderlined,
		};
		const widthNeedsUpdate = !modelData.isSizeFixed;
		const preprocessedTObject = preprocessObjectWithText(
			tObject,
			TextGroupManager.fonts,
			spaceViewRenderer,
			modelData.isSizeFixed ? markup3D.scale.x : undefined,
		);
		const textObjectSize = preprocessedTObject.size;

		const correctionMultiplier = spaceViewRenderer.correctionMultiplier.current;
		const markupScale = markup3D.scale;
		const margin = 20 * (fontSize / Constants.SIZE.FONT.default) * correctionMultiplier;

		for (const line of tObject.text) {
			line.content = line.content.replace(/\n\n+$/g, "\n");
		}

		const newHeight = modelData.isSizeFixed
			? preprocessedTObject.size.y
			: ((tObject.text[0]?.content.match(/\n/g) || "").length + 1) * TextGroupManager.getLineHeight(fontFamily, fontSize);
		const heightNeedsUpdate = true;

		if (widthNeedsUpdate || heightNeedsUpdate) {
			const geometryData = ObjectUtils.deepClone(markup3D.data.geometryData);

			if (widthNeedsUpdate) {
				const hSortedVertices = [...geometryData].sort((a: PointDouble, b: PointDouble) => a.x - b.x);
				const differenceX = textObjectSize.x + margin - markupScale.x;

				switch (markup3D.textHAlign) {
					case HorizontalAlignment.left:
						hSortedVertices[hSortedVertices.length - 1].x += differenceX;
						break;
					case HorizontalAlignment.center:
						hSortedVertices[0].x -= differenceX / 2;
						hSortedVertices[hSortedVertices.length - 1].x += differenceX / 2;
						break;
					case HorizontalAlignment.right:
						hSortedVertices[0].x -= differenceX;
						break;
				}
			}
			if (heightNeedsUpdate) {
				const vSortedVertices = [...geometryData].sort((a: PointDouble, b: PointDouble) => a.y - b.y);
				const differenceY = newHeight - markupScale.y;

				switch (markup3D.textVAlign) {
					case VerticalAlignment.bottom:
						vSortedVertices[vSortedVertices.length - 1].y += differenceY;
						break;
					case VerticalAlignment.center:
						vSortedVertices[vSortedVertices.length - 1].y += differenceY / 2;
						vSortedVertices[0].y -= differenceY / 2;
						break;
					case VerticalAlignment.top:
						vSortedVertices[0].y -= differenceY;
						break;
				}
			}

			markup3D.updateGeometry(geometryData, false, false);
			markup3D.updateCenter();
			markup3D.itemManager.updateSelectionBox();
			spaceViewRenderer.spaceItemController.updateActionBar();
			callback?.();
		}
	}
};
