import {useRef, useState} from "react";
import styled from "styled-components";
import {HorizontalAlignment} from "../../../utils/dom/DomUtils";
import {useClickOutside} from "../utils";
import AlignCenter from "../icons/align-center-justify.svg?react";
import AlignLeft from "../icons/align-left-justify.svg?react";
import AlignRight from "../icons/align-right-justify.svg?react";
import {colorPalette} from "../styles/colorPalette";
import {baseDistance, FLEXCENTER, radius} from "../styles/styles";
import {ReactUtils} from "../../utils/ReactUtils";

interface ITextAlignButtonProps {
	readonly horizontalAlignment: HorizontalAlignment;
	readonly onTextAlignmentChange: (horizontalAlignment: HorizontalAlignment) => void;
}

const alignments = [HorizontalAlignment.left, HorizontalAlignment.center, HorizontalAlignment.right];

const icons: {[key: number]: React.ReactNode} = {
	[HorizontalAlignment.left]: <AlignLeft />,
	[HorizontalAlignment.center]: <AlignCenter />,
	[HorizontalAlignment.right]: <AlignRight />,
};

export const TextAlignButtonV5 = (props: ITextAlignButtonProps) => {
	const {horizontalAlignment, onTextAlignmentChange} = props;
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const _alignButton = useRef<HTMLSpanElement>();

	const onTextAlignClick = () => {
		setIsOpen((open) => !open);
	};

	useClickOutside([_alignButton], () => setIsOpen(false));

	return (
		<TextAlignButtonStyled
			ref={_alignButton}
			onClick={onTextAlignClick}
			className={ReactUtils.cls("button flexCenter textAlignButton", {isActive: isOpen})}
			title="Align text..."
		>
			{icons[horizontalAlignment]}
			{isOpen && (
				<AlignOptionsStyled>
					{alignments.map((alignment, index) => (
						<AlignOption
							key={index}
							onClick={() => onTextAlignmentChange(alignment)}
							className={ReactUtils.cls({selected: alignment === horizontalAlignment})}
						>
							{icons[alignment]}
						</AlignOption>
					))}
				</AlignOptionsStyled>
			)}
		</TextAlignButtonStyled>
	);
};

export const TextAlignButtonStyled = styled.span`
	position: relative;

	svg {
		width: 24px;
	}
`;

const AlignOptionsStyled = styled.div`
	background-color: ${colorPalette.white};
	color: ${colorPalette.gray.c950};
	position: absolute;
	top: 40px;
	box-shadow: 0px 4px 8px 0px #00000080;
	border-radius: ${radius.sm};
	z-index: 1;
	padding: ${baseDistance.sm};
`;

const AlignOption = styled.div`
	width: 32px;
	height: 32px;
	${FLEXCENTER};
	border-radius: ${radius.sm};

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}

	&.selected {
		background-color: ${colorPalette.primary.c200Light};
		color: ${colorPalette.primary.c500Primary};
	}
`;
