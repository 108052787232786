import styled, {css} from "styled-components";
import type {MouseEvent} from "react";
import LabelsIcon from "../../../icons/label-icon.svg?react";
import LayersIcon from "../../../icons/layer-icon.svg?react";
import FilterIcon from "../../../icons/filter.svg?react";
import FormattingIcon from "../../../icons/formatting-icon.svg?react";
import {baseDistance, FlexCenterStyle, radius, zIndex} from "../../../styles/styles";
import {IconButtonStyled, IconButtonV5} from "../../../interaction/IconButtonV5";
import {colorPalette} from "../../../styles/colorPalette";
import type {TSpaceEditorPanelTypes} from "../SpaceEditorPanelV5";
import {Functions} from "../../../../../utils/function/Functions";
import FilterMenuIcon from "../../../icons/filter-menu.svg?react";
import {DropdownButtonV5} from "../../../interaction/DropdownButtonV5";
import {VerticalAlignment} from "../../../../../utils/dom/DomUtils";

const panelTypes: TSpaceEditorPanelTypes[] = ["filter", "layers", "labels", "formatting"];

const getIconComponentByPanelType = (panelType: TSpaceEditorPanelTypes) => {
	switch (panelType) {
		case "filter":
			return FilterIcon;
		case "layers":
			return LayersIcon;
		case "labels":
			return LabelsIcon;
		case "formatting":
			return FormattingIcon;
	}
};

interface ISpaceViewBarProps {
	readonly activePanel: TSpaceEditorPanelTypes;
	readonly divRef: React.RefObject<HTMLDivElement>;
	readonly dropdownStyle: boolean;
	readonly onOpenPanelClick: (type: TSpaceEditorPanelTypes | null) => void;
}

export const SpaceViewBarV5 = (props: ISpaceViewBarProps) => {
	const {activePanel, dropdownStyle, onOpenPanelClick} = props;

	return (
		<SpaceViewBarStyled
			ref={props.divRef}
			$vertical={props.dropdownStyle}
		>
			{dropdownStyle ? (
				<DropdownButtonV5
					verticalAlignment={VerticalAlignment.bottomOuter}
					optionsZIndex={zIndex.contextOptions}
					offsetY={16}
					onlyIcons={true}
					closeOnMouseLeave={true}
					button={
						<IconButtonV5
							IconComponent={FilterMenuIcon}
							onClick={Functions.emptyFunction}
						/>
					}
					options={panelTypes.map((panelType) => ({
						key: panelType,
						isActive: activePanel === panelType,
						label: "",
						IconComponent: getIconComponentByPanelType(panelType),
						onClick: (event: React.MouseEvent) => {
							onOpenPanelClick(activePanel === panelType ? null : panelType);
						},
					}))}
				></DropdownButtonV5>
			) : (
				panelTypes.map((panelType) => (
					<IconButtonV5
						key={panelType}
						isActive={activePanel === panelType}
						IconComponent={getIconComponentByPanelType(panelType)}
						onClick={Functions.emptyFunction}
						onMouseDown={(event: MouseEvent) => {
							event.stopPropagation();
							onOpenPanelClick(activePanel === panelType ? null : panelType);
						}}
					/>
				))
			)}
		</SpaceViewBarStyled>
	);
};

const SpaceViewBarStyled = styled.div<{$vertical: boolean}>`
	${FlexCenterStyle};
	gap: ${baseDistance.sm};
	position: absolute;
	z-index: 2;
	top: 16px;
	right: 16px;
	padding: ${baseDistance.sm};
	background-color: ${colorPalette.white};
	border-radius: ${radius.md};
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);

	${(props) =>
		props.$vertical &&
		css`
			flex-direction: column;
		`};

	${IconButtonStyled} {
		&.isActive {
			background-color: ${colorPalette.primary.c200Light};
			color: ${colorPalette.blueGray.c500Primary};
		}
	}
`;
