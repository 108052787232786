import styled from "styled-components";
import type {ReactNode} from "react";
import {Component} from "react";
import {colorPalette} from "../styles/colorPalette";
import CircleExclamationIcon from "../icons/circle-exclamation.svg?react";
import CloseIcon from "../icons/xmark.svg?react";
import {baseDistance, FLEXCENTER, fontSize, radius} from "../styles/styles";
import {IconButtonV5} from "../interaction/IconButtonV5";

interface IAlertProps {
	readonly children: ReactNode;
	readonly zIndex?: number;
}

interface IAlertState {
	isOpen: boolean;
}

export class AlertV5 extends Component<IAlertProps, IAlertState> {
	private static readonly _instances: AlertV5[] = [];
	private static readonly _maxNumberOfOpenInstances: number = 1;

	constructor(props: IAlertProps) {
		super(props);
		this.state = {
			isOpen: true,
		};
	}

	private open = () => {
		this.setState({
			isOpen: true,
		});
	};

	private close = () => {
		this.setState({
			isOpen: false,
		});
	};

	public override componentDidMount(): void {
		AlertV5._instances.push(this);

		for (let i = 0; i < AlertV5._instances.length - AlertV5._maxNumberOfOpenInstances; ++i) {
			AlertV5._instances[i].setState({isOpen: false});
		}
	}

	public override componentDidUpdate(prevProps: Readonly<IAlertProps>, prevState: Readonly<IAlertState>, snapshot?: any): void {
		if (!prevState.isOpen && this.state.isOpen) {
			for (const instance of AlertV5._instances) {
				if (instance !== this) {
					instance.setState({
						isOpen: false,
					});
				}
			}
		}
	}

	public override componentWillUnmount(): void {
		AlertV5._instances.shift();
	}

	public override render() {
		const {children, zIndex} = this.props;
		const {isOpen} = this.state;

		return isOpen ? (
			<AlertStyled $zIndex={zIndex}>
				<IconStyled onClick={this.close}>
					<CircleExclamationIcon />
				</IconStyled>
				{children}
				<IconButtonV5
					IconComponent={CloseIcon}
					onClick={this.close}
				/>
			</AlertStyled>
		) : (
			<IconStyled onClick={this.open}>
				<CircleExclamationIcon />
			</IconStyled>
		);
	}
}

const IconStyled = styled.div`
	border-radius: ${radius.md};
	background-color: ${colorPalette.warning.c500Primary};
	width: 32px;
	height: 32px;
	cursor: pointer;
	${FLEXCENTER};
	color: ${colorPalette.white};

	&:hover {
		background-color: ${colorPalette.warning.c700Dark};
	}
`;

const AlertStyled = styled.div<{$zIndex: number}>`
	display: flex;
	gap: ${baseDistance.sm};
	padding: ${baseDistance.xs};
	z-index: ${(props) => props.$zIndex ?? ""};
	background: ${colorPalette.warning.c50};
	width: auto;
	height: 48px;
	overflow: hidden;
	border-radius: ${radius.md};
	box-shadow: 0px 4px 50px 0px #00000033;
	border: 1px solid;
	border-color: ${colorPalette.warning.c500Primary};
	align-items: center;

	.IconButton {
		color: ${colorPalette.gray.c950};

		svg {
			height: 16px;
			width: 16px;
		}
	}

	.MultiActionButton {
		margin-left: auto;
	}

	.saveDropdown {
		width: auto !important;
		margin-left: auto !important;
		background-color: ${colorPalette.primary.c500Primary};
		color: ${colorPalette.white};
		font-size: ${fontSize.md};

		&:hover {
			background-color: ${colorPalette.primary.c700Dark};
		}
	}
`;
