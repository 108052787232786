import * as React from "react";
import {observer, inject} from "mobx-react";
import {Draggable, Droppable} from "@hello-pangea/dnd";
import styled from "styled-components";
import type {Xyicon} from "../../../../../data/models/Xyicon";
import type {Catalog} from "../../../../../data/models/Catalog";
import type {PortDataDto, PortTemplateDto} from "../../../../../generated/api/base";
import {LinkType, XyiconFeature} from "../../../../../generated/api/base";
import {PortLayoutType} from "../../../../modules/abstract/sidepanel/tabs/details/PortLayoutType";
import type {AppState} from "../../../../../data/state/AppState";
import {Functions} from "../../../../../utils/function/Functions";
import {ReactUtils} from "../../../../utils/ReactUtils";
import type {Port} from "../../../../modules/catalog/port/Port";
import {DRAGGABLE_ID_SEPARATOR, FULL_LIST} from "../../../../modules/catalog/port/Port";
import {FocusLoss} from "../../../../../utils/ui/focus/FocusLoss";
import type {Link} from "../../../../../data/models/Link";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import CloseIcon from "../../../icons/xmark.svg?react";
import TrashIcon from "../../../icons/trash.svg?react";
import AddIcon from "../../../icons/circle-plus.svg?react";
import {colorPalette} from "../../../styles/colorPalette";
import {baseDistance, FlexCenter, fontSize, fontWeight, radius} from "../../../styles/styles";
import {ClickToEditInputStyled, ClickToEditInputV5} from "../../../input/clicktoedit/ClickToEditInputV5";
import {CheckboxInputV5} from "../../../details/datatypes/CheckboxInputV5";
import {DomUtils, HorizontalAlignment, VerticalAlignment, type TransformObj} from "../../../../../utils/dom/DomUtils";
import {SpaceItemContainerStyled} from "../../../spaceeditor/SpaceItemContainer.Style";
import {PortComponentLinkV5} from "./PortComponentLinkV5";
import type {IPortMap} from "./PortsV5";
import {CrossPortfolioXyiconV5} from "./CrossPortfolioXyiconV5";

interface IPortComponentProps {
	readonly id: string;
	readonly selectedId: string;
	readonly item?: Catalog | Xyicon;
	readonly label: string;
	readonly isReadOnly: boolean;
	readonly children: PortTemplateDto[];
	readonly isStructurallyEditable: boolean; // structurally editable (while creating a catalogitem). If false, we can still edit the label names in the details panel for example
	readonly portMap: IPortMap;
	readonly labelOverrides: PortDataDto[];
	readonly appState?: AppState;
	readonly lastChildHasChild: boolean; // needed for styling
	readonly feature: XyiconFeature; // Xyicon or Catalog
	readonly layout: PortLayoutType;
	readonly grabbedItemDraggableId: string;
	readonly onClick: (id: string) => void;
	readonly onDeleteClick: (id: string) => void;
	readonly onAddChildClick: (id: string) => void;
	readonly onLabelChange: (newLabel: string, portID: string) => void;
	readonly onReadonlyChange: (value: boolean) => void;
	readonly onEnterKeyPress?: () => void;
}

interface IPortComponentState {
	fullList: boolean;
	width: number;
	transform: TransformObj;
}

@inject("appState")
@observer
export class PortComponentV5 extends React.Component<IPortComponentProps, IPortComponentState> {
	private readonly _container = React.createRef<HTMLDivElement>();
	private readonly _thumbnailContainer = React.createRef<HTMLDivElement>();
	private _isMounted = false;

	constructor(props: IPortComponentProps) {
		super(props);
		this.state = {
			fullList: false,
			width: 0,
			transform: null,
		};
	}

	public static readonly defaultProps: Partial<IPortComponentProps> = {
		isStructurallyEditable: true,
		selectedId: null,
		item: null,
		portMap: {},
		labelOverrides: [],
		layout: PortLayoutType.Icon,
		onClick: Functions.emptyFunction,
		onDeleteClick: Functions.emptyFunction,
		onAddChildClick: Functions.emptyFunction,
		onReadonlyChange: Functions.emptyFunction,
	};

	private _resizeObserver: ResizeObserver;

	public override componentDidUpdate(prevProps: IPortComponentProps, prevState: IPortComponentState) {
		if (prevProps.layout !== this.props.layout) {
			this.closeFullListContainer();
		}

		if (!!prevProps.grabbedItemDraggableId && !this.props.grabbedItemDraggableId) {
			this.closeFullListContainer();
		}

		if (!prevState.fullList && this.state.fullList && this._thumbnailContainer.current && this._container.current) {
			this.setState({
				transform: DomUtils.getFixedFloatingElementPosition(
					this._thumbnailContainer.current,
					this._container.current,
					VerticalAlignment.bottomOuter,
					HorizontalAlignment.left,
					10,
				),
			});
		}
	}

	private onAddChildClick = () => {
		this.props.onAddChildClick(this.props.id);
	};

	private onDeleteClick = () => {
		this.props.onDeleteClick(this.props.id);
	};

	private getChildren() {
		const {
			children,
			selectedId,
			isStructurallyEditable,
			portMap,
			feature,
			onClick,
			onDeleteClick,
			onAddChildClick,
			onLabelChange,
			onReadonlyChange,
			labelOverrides,
			layout,
			grabbedItemDraggableId,
			item,
		} = this.props;

		if (children.length > 0) {
			return (
				<div className={ReactUtils.cls("childrenContainer", {lastChildHasChild: this.props.lastChildHasChild})}>
					{children.map((port: Port, index: number) => {
						const id = port.id;
						const lastChildHasChild = index === children.length - 1 ? port.children.length > 0 : false;

						return (
							<PortComponentV5
								key={id}
								id={id}
								item={item}
								selectedId={selectedId}
								label={port.label}
								isReadOnly={port.isReadOnly}
								isStructurallyEditable={isStructurallyEditable}
								portMap={portMap}
								feature={feature}
								children={port.children ? [...port.children] : []}
								onClick={onClick}
								onDeleteClick={onDeleteClick}
								onAddChildClick={onAddChildClick}
								onLabelChange={onLabelChange}
								onReadonlyChange={onReadonlyChange}
								labelOverrides={labelOverrides}
								lastChildHasChild={lastChildHasChild}
								layout={layout}
								grabbedItemDraggableId={grabbedItemDraggableId}
							/>
						);
					})}
				</div>
			);
		} else {
			return null;
		}
	}

	private onClickShowMore = () => {
		if (this._isMounted) {
			this.setState({fullList: true});
		}

		if (this.props.layout === PortLayoutType.Card) {
			requestAnimationFrame(() => {
				FocusLoss.stopListen(this._container?.current, this.closeFullListContainer);
				FocusLoss.listen(this._container?.current, this.closeFullListContainer);
			});
		}
	};

	private closeFullListContainer = (event?: React.MouseEvent | Event) => {
		if (this.state.fullList && this._isMounted && !this.props.appState.app.modalContainer?.contains(event?.target as Element)) {
			this.setState({fullList: false});
		}
	};

	private renderPortObjects(portObjects: {xyiconId: string; link: Link}[], maxItemNumber: number, variant: "base" | "more") {
		const {appState} = this.props;
		const {fullList} = this.state;

		const crossPortfolioLinks = this.props.item ? this.props.appState.actions.getCrossPortfolioLinksXyiconXyicon(this.props.item.id) : [];

		return portObjects.map((portObject: {xyiconId: string; link: Link}, index: number) => {
			const basicCondition = variant === "base" ? index < maxItemNumber : index >= maxItemNumber;

			if (basicCondition) {
				const xyicon = appState.actions.getFeatureItemById<Xyicon>(portObject.xyiconId, XyiconFeature.Xyicon);

				if (xyicon) {
					let dragId = `${portObject.link?.id}${DRAGGABLE_ID_SEPARATOR}${portObject.xyiconId}${DRAGGABLE_ID_SEPARATOR}${this.props.id}`;

					if (variant === "more") {
						dragId += FULL_LIST;
					}

					return (
						<Draggable
							key={dragId}
							draggableId={dragId}
							index={index}
							isDragDisabled={!portObject.link?.id}
						>
							{(provided, snapshot) => (
								<div
									{...provided.draggableProps}
									{...provided.dragHandleProps}
									ref={provided.innerRef}
									className={ReactUtils.cls("link", {dragging: snapshot.isDragging})}
								>
									<PortComponentLinkV5
										xyicon={xyicon}
										layout={this.props.layout}
										isDraggingActive={dragId === this.props.grabbedItemDraggableId}
										link={portObject.link}
									/>
								</div>
							)}
						</Draggable>
					);
				} else {
					const isCrossPortfolioLink = portObject.link.fromType === LinkType.Xyicon && portObject.link.toType === LinkType.Xyicon;

					if (isCrossPortfolioLink) {
						const linkData = crossPortfolioLinks.find((o) => o.link.id === portObject.link.id);

						if (linkData) {
							return (
								<div
									className="link"
									key={linkData.link.id}
								>
									<CrossPortfolioXyiconV5
										transport={this.props.appState.app.transport}
										linkData={linkData}
										showIconOnly={this.props.layout === PortLayoutType.Icon}
										showBreakLinkButton={true}
										showDeleteButton={true}
										isOption={true}
									/>
								</div>
							);
						}
					}
					return false;
				}
			}

			if (variant === "base") {
				if (!fullList && index === maxItemNumber) {
					return (
						<div
							key={portObject.link.id}
							className="more"
							onClick={this.onClickShowMore}
						>
							{`${portObjects.length - maxItemNumber} more...`}
						</div>
					);
				}
			}
		});
	}

	private getLinkedXyicons() {
		const {portMap, id, label, layout} = this.props;
		const portObjects = portMap[id] || [];
		const {fullList, width, transform} = this.state;

		let inlineStyle: React.CSSProperties = this._thumbnailContainer && {
			left: transform?.x || 0,
			top: transform?.y || 0,
		};

		let maxItemNumber = Math.floor(width / 50);

		if (layout === PortLayoutType.Card) {
			maxItemNumber = Math.floor(width / 150);
		}

		return (
			<>
				{fullList && (
					<div
						className="fullListContainer vbox"
						ref={this._container}
						style={inlineStyle}
					>
						<div className="header hbox alignCenter">
							<h4>{`Has ${portObjects.length - maxItemNumber} more linked xyicons`}</h4>
							<IconButtonV5
								IconComponent={CloseIcon}
								onClick={this.closeFullListContainer}
							/>
						</div>
						<Droppable
							droppableId={`${id}${FULL_LIST}`}
							direction="horizontal"
							isDropDisabled={this.props.children.length > 0}
						>
							{(provided, snapshot) => (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									className={ReactUtils.cls("cardsContainer", {
										iconLayout: layout === PortLayoutType.Icon,
										cardLayout: layout === PortLayoutType.Card,
									})}
								>
									{this.renderPortObjects(portObjects, maxItemNumber, "more")}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</div>
				)}
				<Droppable
					droppableId={`${id}`}
					direction="horizontal"
					isDropDisabled={this.props.children.length > 0}
				>
					{(provided, snapshot) => (
						<>
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
								className={ReactUtils.cls("droparea flex_1", {
									active: snapshot.isDraggingOver,
									cardLayout: layout === PortLayoutType.Card,
								})}
							>
								<div className="hbox alignCenter thumbnailWrapper flex_1">
									<div
										className="hbox alignCenter thumbnailContainer flex_1"
										ref={this._thumbnailContainer}
										style={{width: 140}}
									>
										{this.renderPortObjects(portObjects, maxItemNumber, "base")}
										{provided.placeholder}
									</div>
								</div>
							</div>
						</>
					)}
				</Droppable>
			</>
		);
	}

	private onClickToEditClick = () => {
		this.props.onClick(this.props.id);
	};

	private onLabelChange = (value: string) => {
		this.props.onLabelChange(value, this.props.id);
	};

	public override componentDidMount() {
		this._isMounted = true;

		if (this._thumbnailContainer.current) {
			this._resizeObserver = new ResizeObserver((entries) => {
				this.setState({width: entries[0].contentRect.width});
			});

			this._resizeObserver.observe(this._thumbnailContainer.current);
		}
	}

	public override componentWillUnmount() {
		this._isMounted = false;

		if (this._thumbnailContainer.current) {
			this._resizeObserver.unobserve(this._thumbnailContainer.current);
		}
	}

	public override render() {
		const selected = this.props.selectedId === this.props.id;
		const label = this.props.labelOverrides.find((portLabelData: PortDataDto) => portLabelData.id === this.props.id)?.label ?? this.props.label ?? "";

		return (
			<>
				<PortComponentStyled
					className={ReactUtils.cls("PortComponent hbox", {
						selected: selected,
						leaf: this.props.children.length === 0,
						locked: this.props.isReadOnly,
					})}
				>
					<div className="linkedXyicons">
						<FlexCenter>
							<ClickToEditInputV5
								disabled={this.props.isReadOnly || !this.props.onLabelChange}
								value={label}
								onChange={this.onLabelChange}
								onClick={this.onClickToEditClick}
								className="TextInput"
							/>
						</FlexCenter>
						{!this.props.isStructurallyEditable && this.getLinkedXyicons()}
					</div>
					{selected && (
						<FlexCenter
							$gap={baseDistance.xs}
							className="interactionsDiv"
						>
							<CheckboxInputV5
								value={this.props.isReadOnly}
								label="Read only"
								labelFirst={true}
								onChange={this.props.onReadonlyChange}
							/>
							<IconButtonV5
								IconComponent={TrashIcon}
								title="Delete"
								onClick={this.onDeleteClick}
							/>
							<IconButtonV5
								IconComponent={AddIcon}
								title="Add Child"
								onClick={this.onAddChildClick}
							/>
						</FlexCenter>
					)}
				</PortComponentStyled>
				{this.getChildren()}
			</>
		);
	}
}

const PortComponentStyled = styled.div`
	position: relative;
	display: flex;
	gap: ${baseDistance.xs};
	margin-top: ${baseDistance.md};

	.link {
		&.dragging {
			filter: drop-shadow(9px 17px 8px rgba(0, 0, 0, 0.25));
		}
	}

	.interactionsDiv {
		margin: 0;
		padding: 0;
		white-space: nowrap;

		.ToggleSwitchField {
			margin: 0;
		}
	}

	.linkedXyicons {
		display: flex;
		flex: 1 1 0px;
	}

	.thumbnailWrapper {
		height: 100%;

		.thumbnailContainer {
			height: 100%;
			display: flex;
			gap: ${baseDistance.sm};

			${SpaceItemContainerStyled} {
				width: 140px;
			}

			&:not(:has(${SpaceItemContainerStyled})) {
				margin-left: 8px;
			}

			.more {
				cursor: pointer;
			}

			.thumbnail {
				margin: 0 3px;
			}

			.SpaceItemContainer {
				.SpaceItem:not(.showIconOnly) {
					.thumbnailContainer {
						position: relative;
						margin-left: 28px;

						.thumbnail {
							position: absolute;
						}
					}
				}
			}

			.link {
				.CrossPortfolioXyicon {
					.SpaceItemContainer {
						background: ${colorPalette.gray.c200Light};

						.SpaceItem:not(.showIconOnly) {
							.thumbnailContainer {
								margin-left: 0;
								padding: 0;
							}

							.guid {
								font-size: ${fontSize.md};
							}
						}
					}
				}
			}
		}
	}

	${ClickToEditInputStyled} {
		width: 138px;
		height: 38px;
		font-size: ${fontSize.md};

		.unfocused.disabled {
			border: 1px solid ${colorPalette.gray.c300};
			pointer-events: all;
			cursor: default;
		}
	}

	&.locked {
		${ClickToEditInputStyled} {
			.unfocused {
				padding-right: 20px;
			}
			&::after {
				position: absolute;
				left: 123px;
				top: 0;
				display: inline-block;
				content: "";
				background-image: url(./src/assets/images/common/lock.svg);
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center center;
				filter: grayscale(1);
				width: 10px;
				height: 100%;
			}
		}
	}

	.viewButton.add {
		margin-left: 10px;
	}

	.textContainer {
		margin-right: ${baseDistance.sm};

		.ClickToEditInput {
			& > div {
				border-radius: ${radius.sm};
				border: solid 1px ${colorPalette.gray.c200Light};
				padding: 10px;
				cursor: text;

				input {
					border: none;
					width: fit-content;
					width: 100%;
					margin: 0;
					padding: 0;

					&:focus {
						border: none;
						outline: none;
					}
				}
			}
		}
	}

	.fullListContainer {
		width: 528px;
		border-radius: ${radius.md};
		max-height: 320px;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 8000;
		background: ${colorPalette.white};
		visibility: visible;
		box-shadow:
			0px 24px 32px 0px #32324714,
			0px 16px 16px 0px #32324714;

		.SpaceItemContainer {
			background-color: ${colorPalette.gray.c200Light};
		}

		.SpaceItem {
			.guid {
				font-size: ${fontSize.md};
			}
		}

		.header {
			padding: ${baseDistance.sm};
			justify-content: space-between;

			h4 {
				font-size: ${fontSize.lg};
				font-weight: ${fontWeight.bold};
				color: #000000;
				line-height: 24px;
				height: 32px;
			}
		}

		.cardsContainer {
			padding: ${baseDistance.xs};
			overflow: auto;
			margin: ${baseDistance.sm};
			display: grid;
			grid-template-columns: repeat(2, 49%);
			gap: ${baseDistance.sm};
			border: 1px solid ${colorPalette.gray.c300};
			border-radius: ${radius.md};

			&.iconLayout {
				display: flex;
				flex-wrap: wrap;
			}
		}
	}
`;
