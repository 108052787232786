import type {DistanceUnitName, IDistanceUnit} from "../../space/spaceeditor/logic3d/Constants";
import {Constants} from "../../space/spaceeditor/logic3d/Constants";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import {MathUtils} from "../../../../utils/math/MathUtils";
import {Formatter} from "../../../../utils/format/Formatter";
import {PopupWindow} from "./PopupWindow";
import type {IPopupWindowConfig} from "./PopupWindow";

interface IScaleValue {
	value: number;
	unit: IDistanceUnit;
	applyToAllSpaces: boolean;
}

export class SetScaleWindow extends PopupWindow<IScaleValue | null> {
	protected static override readonly _defaultConfig: IPopupWindowConfig = {
		ok: "Ok",
		cancel: "Cancel",
		backdrop: false,
	};
	private _selectedUnit: IDistanceUnit;
	private _units: IDistanceUnit[] = [];
	private _checkbox: HTMLInputElement = document.createElement("input");
	private _currentValue: number;

	protected _okValue: IScaleValue;
	protected _cancelValue: null = null;

	constructor(
		applyToAllSpacesEnabled: boolean,
		message: string,
		title: string,
		placeholder: string,
		selectedUnit: DistanceUnitName,
		config: IPopupWindowConfig,
	) {
		super({
			title: title,
			message: message,
			config: ObjectUtils.mergeConfig(SetScaleWindow._defaultConfig, {additionalElements: document.createElement("div"), ...config}),
		});

		this._selectedUnit = Constants.DISTANCE_UNITS[selectedUnit];
		this._config.additionalElements.className = "vbox";
		this._config.additionalElements.style.marginRight = "20px";

		const measurementsContainer = document.createElement("div");

		measurementsContainer.className = "hbox";
		const input = document.createElement("input");

		input.type = "number";
		input.defaultValue = placeholder;
		input.onkeyup = this.onInputFieldChange;
		input.onchange = this.onInputFieldChange;
		measurementsContainer.appendChild(input);

		requestAnimationFrame(() => {
			if (input?.parentElement) {
				input.focus();
			}
		});

		const select = document.createElement("select");

		select.onchange = this.onUnitChange;

		for (const key in Constants.DISTANCE_UNITS) {
			const unit = Constants.DISTANCE_UNITS[key as keyof typeof Constants.DISTANCE_UNITS];

			if (unit.name !== "foot&inch") {
				this._units.push(unit);

				const option = document.createElement("option");

				option.textContent = Formatter.capitalize(unit.plural);
				option.value = unit.abbreviation;
				option.selected = this._selectedUnit.name === unit.name;

				select.appendChild(option);
			}
		}

		measurementsContainer.appendChild(select);

		this._config.additionalElements.appendChild(measurementsContainer);

		if (applyToAllSpacesEnabled) {
			const toggleSwitchField = document.createElement("div");

			toggleSwitchField.className = "ToggleSwitchField";
			toggleSwitchField.style.marginLeft = "10px";

			const label = document.createElement("label");

			label.className = "label";
			label.textContent = "Apply this scale to all pages";

			toggleSwitchField.appendChild(label);

			const toggleSwitch = document.createElement("div");

			toggleSwitch.className = "ToggleSwitch";

			this._checkbox.type = "checkbox";
			this._checkbox.checked = false;

			toggleSwitchField.onclick = () => {
				this._checkbox.checked = !this._checkbox.checked;
				this.updateOkValue();
			};
			toggleSwitch.appendChild(this._checkbox);

			const slider = document.createElement("div");

			slider.className = "slider";
			toggleSwitch.appendChild(slider);
			const circle = document.createElement("div");

			circle.className = "circle";
			toggleSwitch.appendChild(circle);

			toggleSwitchField.appendChild(toggleSwitch);

			this._config.additionalElements.appendChild(toggleSwitchField);
		}

		this.updateOkValue();
	}

	private onUnitChange = (event: Event) => {
		this._selectedUnit = this._units[(event.currentTarget as HTMLSelectElement).selectedIndex];
		this.updateOkValue();
	};

	private onInputFieldChange = (event: Event) => {
		this._currentValue = parseFloat(((event.currentTarget ?? event.target) as HTMLInputElement).value);
		this.updateOkValue();
	};

	private updateOkValue() {
		this._okValue = {
			value: this._currentValue,
			unit: this._selectedUnit,
			applyToAllSpaces: this._checkbox.checked,
		};

		if (MathUtils.isValidNumber(this._okValue.value) && this._okValue.value > 0) {
			super.enableOkButton();
		} else {
			super.disableOkButton();
		}
	}

	public static open(
		applyToAllSpacesEnabled: boolean,
		message: string,
		title: string = "Submit",
		placeholder: string = "",
		selectedUnit: DistanceUnitName,
		config: IPopupWindowConfig = {},
	) {
		return new SetScaleWindow(applyToAllSpacesEnabled, message, title, placeholder, selectedUnit, config).open() as Promise<IScaleValue>;
	}
}
