import type {Matrix4} from "three";

export class VectorUtils {
	public static lengthOfSquared(vector: number[]) {
		let sumSq = 0;

		for (const element of vector) {
			sumSq += element * element;
		}

		return sumSq;
	}
	public static lengthOf(vector: number[]) {
		return Math.sqrt(VectorUtils.lengthOfSquared(vector));
	}

	public static dotProduct(a: number[], b: number[]) {
		let sum = 0;
		for (let i = 0; i < a.length; ++i) {
			sum += a[i] * b[i];
		}

		return sum;
	}

	public static subVectors(a: number[], b: number[]) {
		const vector: number[] = [];

		for (let i = 0; i < a.length; ++i) {
			vector.push(a[i] - b[i]);
		}

		return vector;
	}

	public static addVectors(a: number[], b: number[]) {
		const vector: number[] = [];

		for (let i = 0; i < a.length; ++i) {
			vector.push(a[i] + b[i]);
		}

		return vector;
	}

	public static multiplyByScalar(vector: number[], scalar: number) {
		const out: number[] = [];

		for (let i = 0; i < vector.length; ++i) {
			out[i] = vector[i] * scalar;
		}

		return out;
	}

	public static crossVectors(a: number[], b: number[]) {
		return [a[1] * b[2] - a[2] * b[1], a[2] * b[0] - a[0] * b[2], a[0] * b[1] - a[1] * b[0]];
	}

	public static readonly normalize = (vector: number[]) => {
		const length = VectorUtils.lengthOf(vector);

		for (let i = 0; i < vector.length; ++i) {
			vector[i] /= length;
		}

		return vector;
	};

	public static getWorldPositionFromUV(u: number, v: number) {
		const vector = [Math.cos(u) * Math.sin(v), Math.cos(v), Math.sin(u) * Math.sin(v)];

		return VectorUtils.normalize(vector);
	}

	public static distanceBetweenSquaredVec2(a: [number, number], b: [number, number]): number {
		const dx = a[0] - b[0];
		const dy = a[1] - b[1];

		return dx * dx + dy * dy;
	}

	public static distanceBetweenSquaredVec3(a: [number, number, number], b: [number, number, number]): number {
		const dx = a[0] - b[0];
		const dy = a[1] - b[1];
		const dz = a[2] - b[2];

		return dx * dx + dy * dy + dz * dz;
	}

	public static setFromMatrixPosition(m: Matrix4): [number, number, number] {
		const e = m.elements;

		return [e[12], e[13], e[14]];
	}
}
