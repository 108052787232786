import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {AppState} from "../../../data/state/AppState";
import type {TransportLayer} from "../../../data/TransportLayer";
import {XyiconFeature} from "../../../generated/api/base";
import type {IModel} from "../../../data/models/Model";
import type {Markup} from "../../../data/models/Markup";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import type {DocumentModel} from "../../../data/models/DocumentModel";
import {DomUtils} from "../../../utils/dom/DomUtils";
import {IconButtonV5} from "../interaction/IconButtonV5";
import CloseIcon from "../icons/xmark-large.svg?react";
import BackArrowIcon from "../icons/arrow-left.svg?react";
import BigSearchIcon from "../icons/search-alt-2.svg?react";
import type {Navigation} from "../../../Navigation";
import {FlexCenter, FlexCenterStyle, VerticalFlex, VerticalFlexStyle, baseDistance, fontSize, fontWeight, radius, zIndex} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";
import type {NavBarState} from "../../../StateManager";
import {LoaderV5} from "../loader/LoaderV5";
import {SearchFeatureBlockV5} from "./SearchFeatureBlockV5";

interface IPortfolioWideSearchProps {
	readonly query: string;
	readonly loading: boolean;
	readonly navBarState: NavBarState;
	readonly appState?: AppState;
	readonly navigation?: Navigation;
	readonly transport?: TransportLayer;
	readonly closeSearchInput: () => void;
}

interface IPortfolioWideSearchState {
	expandedMode: boolean;
	expandedSearchFeatureBlock: XyiconFeature;
	visibleSearchBlocks: XyiconFeature[];
	itemsLoaded: boolean;
}

@inject("appState")
@inject("transport")
@inject("navigation")
@observer
export class PortfolioWideSearchV5 extends React.Component<IPortfolioWideSearchProps, IPortfolioWideSearchState> {
	private _timeoutId: number = null;
	private static filteredFeatures: XyiconFeature[] = [
		XyiconFeature.Xyicon,
		XyiconFeature.Boundary,
		XyiconFeature.Markup,
		XyiconFeature.Space,
		XyiconFeature.XyiconCatalog,
		XyiconFeature.OrganizationDocument,
		XyiconFeature.PortfolioDocument,
	];
	private static readonly itemsNumberWhenCollapsed = 12;
	private static readonly ItemsNumberWhenExpanded = 99;

	private _element = React.createRef<HTMLDivElement>();
	private _viewport: VisualViewport = window.visualViewport;

	private _lastResult = {
		query: "",
		result: [] as IModel[],
	};

	constructor(props: IPortfolioWideSearchProps) {
		super(props);
		this.state = {
			//detailedItems: [],
			expandedMode: false,
			expandedSearchFeatureBlock: null,
			visibleSearchBlocks: [],
			itemsLoaded: false,
		};
	}

	private loadFeatureItems = () => {
		const featureService = this.props.transport.services.feature;

		Promise.all(PortfolioWideSearchV5.filteredFeatures.map((feature) => featureService.refreshList(feature))).then(() =>
			this.setState({itemsLoaded: true}),
		);
	};

	private getFilteredList(): IModel[] {
		const {query, appState} = this.props;

		if (query.length !== 0) {
			if (this._lastResult.query === query) {
				// Don't search again if query didn't change
				return this._lastResult.result;
			}

			const result: IModel[] = [];

			const featuresToSearch = [XyiconFeature.Xyicon, XyiconFeature.XyiconCatalog, XyiconFeature.Boundary, XyiconFeature.Space];

			for (const feature of featuresToSearch) {
				const array = appState.actions.getList(feature);
				const matching = appState.actions.searchModelsCached(array, query, feature, PortfolioWideSearchV5.ItemsNumberWhenExpanded);

				result.push(...matching);
			}

			appState.actions.getList<Markup>(XyiconFeature.Markup).forEach((markup) => {
				if (
					StringUtils.containsIgnoreCase(markup.textContent, query) ||
					StringUtils.containsIgnoreCase(markup.typeName, query) ||
					StringUtils.containsIgnoreCase(markup.space.name, query)
				) {
					result.push(markup);
				}
			});

			const portfolioDocuments = appState.actions.getList<DocumentModel>(XyiconFeature.PortfolioDocument).filter((d) => d.parentElementMaybe);
			const orgDocuments = appState.actions
				.getList<DocumentModel>(XyiconFeature.OrganizationDocument)
				.filter(
					(d) =>
						(d.documentMap[0].feature === XyiconFeature.XyiconCatalog ||
							(d.documentMap[0].feature === XyiconFeature.Portfolio && d.data.portfolioID === appState.portfolioId)) &&
						d.parentElementMaybe,
				);

			const allDocuments = [...portfolioDocuments, ...orgDocuments];

			allDocuments.forEach((doc: DocumentModel) => {
				if (StringUtils.containsIgnoreCase(doc.fileName, query)) {
					result.push(doc);
				}
			});

			this._lastResult.query = query;
			this._lastResult.result = result;

			return result;
		} else {
			return [];
		}
	}

	private leaveExpandedMode = () => {
		this.setState({
			expandedMode: false,
			expandedSearchFeatureBlock: null,
		});
	};

	private startExpandedMode(item: XyiconFeature) {
		this.setState({
			expandedMode: true,
			expandedSearchFeatureBlock: item,
		});
	}

	private getFeatureMenuLabel(feature: XyiconFeature) {
		// TODO Documents are missing in Navigation
		if ([XyiconFeature.OrganizationDocument, XyiconFeature.PortfolioDocument, XyiconFeature.Document].includes(feature)) {
			return "Documents";
		} else if (feature === XyiconFeature.Markup) {
			return "Markups";
		}

		return this.props.navigation.menus.find((menu) => menu.feature === feature).label;
	}

	private scrollToFeature(feature: XyiconFeature) {
		const featureBlocks = document.querySelectorAll(".SearchFeatureBlock h3");
		const divToScroll = Array.from(featureBlocks).find((node: HTMLElement) => node.dataset.featureid === feature.toString());

		if (divToScroll) {
			DomUtils.scrollIntoViewIfNeeded(divToScroll as HTMLElement);
		}
	}

	private isElementInViewport(el: HTMLDivElement) {
		const element = el.getBoundingClientRect();

		// We need to check only the vieport first 1/3 (or at least 300px)
		let viewportBottom = this._viewport.height * (1 / 3);

		viewportBottom = viewportBottom < 300 ? 300 : viewportBottom;

		return element.bottom < this._viewport.height && element.top > this._viewport.offsetTop;
	}

	private updateVisibleSearchBlocks = () => {
		const featureSearchBlocks = this._element.current?.childNodes as NodeList;
		const visibleBlocks: XyiconFeature[] = [];

		featureSearchBlocks?.forEach((block: HTMLDivElement) => {
			if (this.isElementInViewport(block)) {
				if (block.dataset.featureid) {
					visibleBlocks.push(parseInt(block.dataset.featureid));
				}
			}
		});

		const blocks1 = this.state.visibleSearchBlocks.toString();
		const blocks2 = visibleBlocks.toString();

		// Avoid unnecessary re-render (which can be very expensive here)
		if (blocks1 !== blocks2) {
			this.setState({visibleSearchBlocks: visibleBlocks});
		}
	};

	private scrollHandle = (e: React.UIEvent) => {
		this.updateVisibleSearchBlocks();
	};

	private filterFilteredListByFeature(filteredList: IModel[], feature: XyiconFeature) {
		return filteredList.filter((item: IModel) => {
			let qf = feature;
			if (qf === XyiconFeature.Document) {
				return [XyiconFeature.Document, XyiconFeature.OrganizationDocument, XyiconFeature.PortfolioDocument].includes(item.ownFeature);
			}

			return item.ownFeature === qf;
		});
	}

	public override componentDidMount() {
		// Reset lastResult when component is opened to refresh cache when data changes
		// (If data changes when component is currently opened, that's still an issue which
		// we could consider solving by listening on changes on lists.)

		this._lastResult = {
			query: "",
			result: [] as any[],
		};
		this.loadFeatureItems();
	}

	public override componentDidUpdate(prevProps: IPortfolioWideSearchProps, prevState: IPortfolioWideSearchState) {
		if (this.props.query !== prevProps.query) {
			this.props.appState.app.onDetailsContainerClose();

			clearTimeout(this._timeoutId);
			// Wait for loading search groups
			this._timeoutId = window.setTimeout(this.updateVisibleSearchBlocks, 1000);
		}
	}

	public override render() {
		const {expandedMode, expandedSearchFeatureBlock, itemsLoaded} = this.state;
		const {loading, query, navBarState, appState} = this.props;
		const loadingFlag = loading || !itemsLoaded;

		// Important not to call the heavy getFilteredList yet
		const filteredList = loadingFlag ? [] : this.getFilteredList();

		const isFilteredListEmpty = filteredList.length === 0;
		const isQueryEmpty = query.length === 0;
		const isEmptyScreen = !isQueryEmpty && isFilteredListEmpty;
		const isHome = ["portfolios", "catalog", "reports", "home"].includes(appState.selectedMenu);

		const sectionFeatures: XyiconFeature[] = [
			...PortfolioWideSearchV5.filteredFeatures.filter(
				(f) => ![XyiconFeature.Document, XyiconFeature.OrganizationDocument, XyiconFeature.PortfolioDocument].includes(f),
			),
			XyiconFeature.Document,
		];

		return (
			<PortfolioWideSearchStyled
				onScroll={this.scrollHandle}
				$shrinkSize={navBarState !== "docked" ? 64 : isHome ? 200 : 288}
			>
				<HeaderStyled>
					<FlexCenter $gap={baseDistance.md}>
						{expandedMode && (
							<IconButtonV5
								IconComponent={BackArrowIcon}
								className="back"
								onClick={this.leaveExpandedMode}
							/>
						)}
						<h4>Search results</h4>
					</FlexCenter>
					<IconButtonV5
						IconComponent={CloseIcon}
						onClick={this.props.closeSearchInput}
						style={{alignSelf: "flex-end"}}
					/>
				</HeaderStyled>
				<FlexCenter
					style={{
						alignItems: loadingFlag ? "" : "flex-start",
						alignSelf: loadingFlag ? "center" : "",
						height: "inherit",
					}}
					$gap={baseDistance.lg}
				>
					{!isFilteredListEmpty ? (
						<>
							<ModuleLinksStyled>
								<ul>
									{sectionFeatures.map((feature: XyiconFeature) => {
										const arrayWithItems = this.filterFilteredListByFeature(filteredList, feature);
										if (arrayWithItems.length > 0) {
											return (
												<li
													key={feature}
													style={{cursor: "pointer"}}
													onClick={() => this.scrollToFeature(feature)}
												>
													<h4>{this.getFeatureMenuLabel(feature)}</h4>
													<span>{arrayWithItems.length}</span>
												</li>
											);
										}
									})}
								</ul>
							</ModuleLinksStyled>
							<ModuleGroupsStyled ref={this._element}>
								{sectionFeatures.map((feature: XyiconFeature, index: number) => {
									const arrayWithItems = this.filterFilteredListByFeature(filteredList, feature);

									if ((expandedMode && expandedSearchFeatureBlock === feature) || (!expandedMode && arrayWithItems.length !== 0)) {
										return (
											<SearchFeatureBlockV5
												key={feature}
												feature={feature}
												queryString={query}
												featureItems={arrayWithItems.slice(
													0,
													!expandedMode ? PortfolioWideSearchV5.itemsNumberWhenCollapsed : PortfolioWideSearchV5.ItemsNumberWhenExpanded,
												)}
												startExpandedMode={(feature: XyiconFeature) => this.startExpandedMode(feature)}
												filteredLength={arrayWithItems.length}
												itemsNumberWhenCollapsed={PortfolioWideSearchV5.itemsNumberWhenCollapsed}
												itemsNumberWhenExpanded={PortfolioWideSearchV5.ItemsNumberWhenExpanded}
												expanded={expandedMode && feature === expandedSearchFeatureBlock}
												menuLabel={this.getFeatureMenuLabel(feature)}
												closeSearchInput={this.props.closeSearchInput}
												closePortfolioWideSearch={this.props.closeSearchInput}
											/>
										);
									}
								})}
							</ModuleGroupsStyled>
						</>
					) : loadingFlag ? (
						<LoaderV5 label="Searching..." />
					) : (
						<VerticalFlex
							style={{alignItems: "center", alignSelf: "center", margin: "0 auto"}}
							$gap={baseDistance.md}
						>
							<BigSearchIcon style={{width: 42, height: 42}} />
							<p style={{fontSize: 32}}>{isEmptyScreen ? "No matching results found" : "Start typing to search in Portfolio"}</p>
						</VerticalFlex>
					)}
				</FlexCenter>
			</PortfolioWideSearchStyled>
		);
	}
}

const HeaderStyled = styled.div`
	${FlexCenterStyle};
	justify-content: space-between;

	h4 {
		text-transform: uppercase;
	}
`;

const ModuleGroupsStyled = styled.div`
	${VerticalFlexStyle};
	gap: ${baseDistance.md};
	flex: 1;
	overflow: auto;
	height: calc(100% - 90px);
`;

const ModuleLinksStyled = styled.div`
	width: 168px;
	font-size: ${fontSize.md};
	text-transform: uppercase;
	font-weight: ${fontWeight.bold};
	cursor: pointer;

	ul {
		li {
			${FlexCenterStyle};
			justify-content: space-between;
			height: 32px;
			position: relative;

			&::before {
				display: block;
				content: "";
				position: absolute;
				left: -8px;
				width: 3px;
				height: 32px;
				background-color: ${colorPalette.primary.c500Primary};
				border-radius: ${radius.sm};
				opacity: 0;
				transition: ease-in-out 0.2s opacity;
			}

			&:hover::before {
				opacity: 1;
			}

			span {
				width: 36px;
				text-align: center;
			}
		}
	}
`;

const PortfolioWideSearchStyled = styled.div<{$shrinkSize: number}>`
	${VerticalFlexStyle};
	gap: ${baseDistance.md};
	background: ${colorPalette.white};
	position: absolute;
	right: 0;
	bottom: 0;
	width: ${(props) => `calc(100vw - ${props.$shrinkSize}px)`};
	height: calc(100vh - 67px);
	z-index: ${zIndex.modal};
	padding: ${baseDistance.md};
	color: ${colorPalette.gray.c950};

	&:hover::-webkit-scrollbar-thumb {
		border-width: 0;
		transition: ease-in-out all 0.2s;
	}
`;
