import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {AppState} from "../../../../../data/state/AppState";
import {User} from "../../../../../data/models/User";
import type {ISwitchListChange} from "../../../details/SwitchListV5";
import {ArrayUtils} from "../../../../../utils/data/array/ArrayUtils";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {UserGroup} from "../../../../../data/models/UserGroup";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import {ButtonV5} from "../../../button/ButtonV5";
import {FieldV5} from "../../../details/FieldV5";
import {TextInputStyled, TextInputV5} from "../../../details/datatypes/TextInputV5";
import CirclePlusIcon from "../../../icons/circle-plus.svg?react";
import XMarkIcon from "../../../icons/xmark.svg?react";
import {FieldStyled} from "../../../details/Field.styled";
import {baseDistance, fontSize} from "../../../styles/styles";
import {UserFormV5} from "./UserFormV5";

interface ICreateUserPanelProps {
	readonly onCancel: () => void;
	readonly onCreated: (id: string) => void;
	readonly creating?: boolean;
	readonly transport?: TransportLayer;
	readonly appState?: AppState;
}

interface ICreateUserPanelState {
	loading: boolean;
	userGroups: string[];
}

@inject("transport")
@inject("appState")
@inject("navigation")
@observer
export class CreateUserPanelV5 extends React.PureComponent<ICreateUserPanelProps, ICreateUserPanelState> {
	private _user = new User(
		{
			userID: "",
			email: "",
		},
		this.props.appState,
	);

	constructor(props: ICreateUserPanelProps) {
		super(props);
		this.state = {
			loading: false,
			userGroups: [],
		};
	}

	private resetCreatePanel() {
		this.setState({
			userGroups: [],
		});

		this._user = new User(
			{
				userID: "",
				email: "",
			} as any,
			this.props.appState,
		);

		this.forceUpdate();
	}

	private onChangeUserGroup = (changes: ISwitchListChange[]) => {
		const newList = [...this.state.userGroups];

		for (const change of changes) {
			if (change.value) {
				ArrayUtils.addMutable(newList, change.id);
			} else {
				ArrayUtils.removeMutable(newList, change.id);
			}
		}

		this.setState({userGroups: newList});
	};

	private onCreateClicked = async () => {
		this.setState({loading: true});

		const user = this._user;
		const {transport} = this.props;
		const {userGroups} = this.state;

		user.updatePermissions();
		const data = user.getCreateData();

		data.userGroupIDList = userGroups;

		const models = await transport.services.feature.create<User>(data, XyiconFeature.User);
		const newUser = models?.[0];

		if (newUser) {
			this.setState({loading: false});
			this.resetCreatePanel();

			[...newUser.userGroupIds, ...userGroups].forEach((userGroupId) => {
				const userGroup = this.props.appState.actions.getFeatureItemById<UserGroup>(userGroupId, XyiconFeature.UserGroup);

				if (userGroup) {
					userGroup.addUser(newUser.id, this.props.appState);
				}
			});

			this.props.onCreated(newUser.id);
		}

		this.setState({userGroups: []});
	};

	private isEmailValid(): boolean {
		return StringUtils.emailValidator(this._user.email);
	}

	private isUniqEmail(): boolean {
		return !this.props.appState.actions.getList(XyiconFeature.User).find((user: User) => StringUtils.equalsIgnoreCase(user.email, this._user.email));
	}

	private shouldInviteButtonAvailable(): boolean {
		return this.isUniqEmail() && this.isEmailValid() && !this.state.loading;
	}

	private onCancelClicked() {
		this.resetCreatePanel();
		this.props.onCancel();
	}

	public override render() {
		const user = this._user;

		return (
			<CreateUserPanelStyled>
				<div className="CreateUserPanel SidePanel DetailsTab">
					<div className="topbar">
						<div className="heading hbox createBox">
							<CirclePlusIcon />
							<h4>ADD USER</h4>
						</div>

						<div className="sidePanelButtons hbox">
							<ButtonV5
								label={this.state.loading ? "Inviting" : "Invite"}
								onClick={this.onCreateClicked}
								disabled={this.state.loading || !this.shouldInviteButtonAvailable()}
								className="primary"
							/>
							<IconButtonV5
								IconComponent={XMarkIcon}
								onClick={() => this.onCancelClicked()}
							/>
						</div>
					</div>

					<div className="scrollContainer">
						{!this.isUniqEmail() && (
							<div className="errorMessage">
								<p>A user with this email already exists.</p>
							</div>
						)}
						<FieldV5
							label="Email"
							className="emailField"
						>
							<TextInputV5
								placeholder="Email"
								value={user.email}
								onInput={(value) => (user.email = value)}
								autoFocus={this.props.creating}
							/>
						</FieldV5>

						<UserFormV5
							users={[this._user]}
							onChangeUserGroup={this.onChangeUserGroup}
							assignedUserGroups={this.state.userGroups}
							hideSystemUserGroups={true}
						/>
					</div>
				</div>
			</CreateUserPanelStyled>
		);
	}
}

const CreateUserPanelStyled = styled.div`
	.topbar {
		display: flex;
		justify-content: space-between;
		padding: ${baseDistance.md};

		.heading {
			margin-bottom: ${baseDistance.md};

			.IconButton {
				width: 24px;
				height: 24px;
			}

			h4 {
				font-size: ${fontSize.lg};
				margin-top: auto;
				margin-bottom: auto;
			}
		}

		.sidePanelButtons {
			gap: ${baseDistance.sm};
		}
	}

	.emailField {
		padding-left: ${baseDistance.md};
		padding-right: ${baseDistance.md};
	}

	.errorMessage {
		margin-left: ${baseDistance.md};
		margin-bottom: ${baseDistance.xs};
	}

	${TextInputStyled} {
		width: 100%;
	}

	${FieldStyled} {
		margin-bottom: ${baseDistance.sm};

		.element {
			height: 30px;
		}
	}
`;
