import React from "react";

interface IGlobalSharingHeaderComponentProps {
	readonly viewActionType?: ViewActionType;
	readonly isReports?: boolean;
}

type ViewActionType = "makePrivate" | "makeGlobal" | "modify" | "delete" | "rename" | "create";

export const GlobalSharingHeaderComponent = (props: IGlobalSharingHeaderComponentProps) => {
	const {viewActionType, isReports} = props;
	let header;
	const itemType = isReports ? "Report" : "View";

	switch (viewActionType) {
		case "delete":
			header = `Delete Global ${itemType}`;
			break;
		case "modify":
		case "rename":
			header = `Modify Global ${itemType}`;
			break;
		case "makePrivate":
			header = `Convert to Private ${itemType}`;
			break;
		case "makeGlobal":
			header = `Convert to Global ${itemType}`;
			break;
		case "create":
			header = `Create Global ${itemType}`;
			break;
		default:
			header = "";
			break;
	}

	return <span>{header}</span>;
};
