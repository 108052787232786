import {computed, observable, makeObservable} from "mobx";
import type {DocumentMapDto, FullDocumentDto} from "../../generated/api/base";
import {XyiconFeature} from "../../generated/api/base";
import type {AppState} from "../state/AppState";
import {featureTitles} from "../state/AppStateConstants";
import type {IModel} from "./Model";

type DocumentType = XyiconFeature.Document | XyiconFeature.PortfolioDocument | XyiconFeature.OrganizationDocument;

export class DocumentModel implements IModel {
	public readonly ownFeature: DocumentType = XyiconFeature.Document;

	@observable
	private _data: FullDocumentDto = {};

	private _appState: AppState;

	constructor(data: FullDocumentDto, feature: DocumentType, appState: AppState) {
		makeObservable(this);
		this._appState = appState;
		this.ownFeature = feature;
		this.applyData(data);

		this.parentElementMaybe; // to log out warning, if doesn't exist
	}

	public applyData(data: FullDocumentDto) {
		for (const key in data) {
			this._data[key as keyof FullDocumentDto] = data[key as keyof FullDocumentDto];
		}
	}

	public isAttachedToObject(objectId: string) {
		return this.documentMap.find((o) => o.objectID === objectId);
	}

	@computed
	public get id() {
		return this._data.documentID;
	}

	@computed
	public get fileName() {
		return this._data.fileName;
	}

	@computed
	public get fileExtension() {
		return this._data.fileExtension;
	}

	@computed
	public get fileType() {
		return this._data.fileType;
	}

	@computed
	public get lastModifiedBy() {
		return this._data.lastModifiedBy;
	}

	@computed
	public get lastModifiedAt() {
		return this._data.lastModifiedAt;
	}

	@computed
	public get documentMap(): DocumentMapDto[] {
		return this._data.documentMap ?? [];
	}

	@computed
	public get fileUrl() {
		return this._appState.app.transport.services.document.getFilePath(this);
	}

	@computed
	public get thumbnailFileURL() {
		return this._appState.app.transport.services.document.getThumbnailPath(this);
	}

	@computed
	public get parentElementMaybe(): IModel | null {
		const {documentMap} = this;
		let parentElement: IModel | null = null;
		if (documentMap.length > 0) {
			const {objectID, feature} = documentMap[0];

			parentElement = this._appState.actions.getFeatureItemById(objectID, feature);

			if (!parentElement) {
				console.warn(
					`The document's (id: ${this.id}) parent element (id: ${objectID}) is a ${featureTitles[feature]} item, but it's not found. Is it deleted?`,
				);
			}
		} else {
			console.warn(`The document's (id: ${this.id}) documentMap doesn't exist, or is an empty array.`);
		}

		return parentElement;
	}

	@computed
	public get data() {
		return this._data;
	}
}
