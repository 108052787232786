import styled from "styled-components";
import type {View} from "../../../data/models/View";
import {PopupV5} from "../popup/PopupV5";
import GlobeIcon from "../icons/globe.svg?react";
import {ButtonStyled, ButtonV5} from "../button/ButtonV5";
import {useAppStore} from "../../../StateManager";
import {ButtonContainerStyled} from "../modules/report/create/wizard/ReportWizardV5.style";
import {colorPalette} from "../styles/colorPalette";
import {Functions} from "../../../utils/function/Functions";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {PopupBackdropV5} from "../popup/PopupBackdropV5";
import {GlobalSharingHeaderComponent} from "./GlobalSharingHeaderComponent";
import {GlobalSharingBodyComponent} from "./GlobalSharingBodyComponent";

export type ViewActionType = "makePrivate" | "makeGlobal" | "modify" | "delete" | "rename" | "create";

interface IGlobalSharingPopupProps {
	readonly viewItem?: View;
	readonly onClose: () => void;
	readonly viewActionType?: ViewActionType;
	readonly isReports?: boolean;
	readonly onSaveClicked?: () => void;
	readonly newName?: string;
}

export const GlobalSharingPopup = (props: IGlobalSharingPopupProps) => {
	const {viewItem, onClose, viewActionType, isReports, onSaveClicked, newName} = props;
	const appState = useAppStore((state) => state.appState);

	const onConfirm = async (viewActionType: ViewActionType) => {
		switch (viewActionType) {
			case "delete": {
				await appState.app.transport.services.view.delete(viewItem.id);
				const {organization} = appState;

				if (organization) {
					await organization.setGlobalViews(organization.globalViews);
				}

				break;
			}
			case "modify": {
				onSaveClicked();
				break;
			}
			case "rename": {
				const isNameValid = appState.actions.isNameValidForView(newName, viewItem.itemFeature, viewItem.id);

				if (isNameValid && newName !== viewItem.name) {
					viewItem.name = newName;
					await appState.app.transport.services.view.update(viewItem.getData());
				}

				break;
			}
			case "makePrivate": {
				appState.app.transport.services.view.markViewAsPrivate(viewItem);
				break;
			}
			case "makeGlobal": {
				appState.app.transport.services.view.markViewAsGlobal(viewItem);
				break;
			}
			case "create": {
				onSaveClicked();
				break;
			}
		}
		onClose();
	};

	return (
		<DomPortal destination={appState.app.modalContainer}>
			<PopupBackdropV5 onClick={Functions.stopPropagation}>
				<PopupV5
					onClose={props.onClose}
					label=""
					className="GlobalViewSharing"
					isSmallPopup={true}
					centerOnScreen={true}
					noButtons={true}
					width="488px"
					height="272px"
					freezeRoot={true}
				>
					<div>
						<HeaderStyled>
							<GlobeIcon />
							<GlobalSharingHeaderComponent
								viewActionType={viewActionType}
								isReports={isReports}
							/>
						</HeaderStyled>
						<ContainerStyled>
							<GlobalSharingBodyComponent
								viewActionType={viewActionType}
								isReports={false}
							/>
							<ButtonContainerStyled>
								<ButtonV5
									label={viewActionType === "delete" ? "Delete" : "Confirm"}
									className={viewActionType === "delete" ? "delete" : "confirm"}
									onClick={() => onConfirm(viewActionType)}
								/>
							</ButtonContainerStyled>
						</ContainerStyled>
					</div>
				</PopupV5>
			</PopupBackdropV5>
		</DomPortal>
	);
};

const HeaderStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 24px;
	font-weight: 700;
	text-align: center;
	gap: 8px;
	line-height: 32px;

	svg {
		width: 32px;
		height: 32px;
		padding: 4px;
	}
`;

const ContainerStyled = styled.div`
	font-size: 16px;
	line-height: 24px;
	padding: 24px 8px;

	${ButtonStyled} {
		&.delete {
			background-color: ${colorPalette.negative.c500Primary};
		}
	}
`;
