import {styled, css} from "styled-components";

export interface IFlexStyle {
	$flex?: string;
	$wrap?: boolean;
	$gap?: string;
}

export const Grabbable = css`
	cursor: grab;
	position: relative;
	&::before {
		content: "";
		background: url(../assets/images/common/drag-icon.svg) no-repeat center;
		position: absolute;
		left: 10px;
		top: -4px;
		bottom: -4px;
		width: 10px;
		pointer-events: none;
	}
	&:hover::before {
		background: url(../assets/images/common/drag-icon-blue.svg) no-repeat center;
	}
`;

export const FontFamily = css`
	@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap");
	font-family: Inter, sans-serif;
`;

export const FlexStyle = css<IFlexStyle>`
	display: flex;
	flex: ${(props) => (props.$flex ? props.$flex : "")};
	flex-wrap: ${(props) => (props.$wrap ? "wrap" : "nowrap")};
	gap: ${(props) => (props.$gap ? props.$gap : "")};
`;

export const FlexCenterStyle = css`
	${FlexStyle}
	align-items: center;
`;

export const FlexCenter = styled.div<IFlexStyle>`
	${FlexCenterStyle};
`;

export const Flex = styled.div<IFlexStyle>`
	${FlexStyle}
`;

export const VerticalFlexStyle = css`
	${FlexStyle}
	flex-direction: column;
`;

export const VerticalFlex = styled.div<IFlexStyle>`
	${VerticalFlexStyle}
`;

export const TOPBAR_HEIGHT = "64px";

export const radius = {
	xs: "2px",
	sm: "4px",
	md: "8px",
	xl: "16px",
	xxl: "32px",
};

export const fontSize = {
	xxl: "20px",
	xl: "18px",
	lg: "16px",
	md: "14px",
	sm: "12px",
	xs: "10px",
};

const distanceUnit = 8;

export const baseDistance = {
	xs: `${distanceUnit / 2}px`,
	sm: `${distanceUnit}px`,
	oh: `${distanceUnit + distanceUnit / 2}px`,
	md: `${distanceUnit * 2}px`,
	lg: `${distanceUnit * 4}px`,
};

export const fontWeight = {
	bold: 700,
	semiBold: 500,
	normal: 400,
	thin: 300,
};

export const zIndex = {
	hidden: -1,
	component: 1000,
	focus: 1001,
	focusSelect: 1001,
	canvas: 2000,
	canvasIndicator: 2001,
	canvasToolbar: 2002,
	sidePanel: 2999,
	secondaryHeader: 3000,
	runReport: 3002,
	secondaryNavigation: 4000,
	createPanel: 4002, // needs to be > secondary-header
	dropdowns: 5000,
	spaceNavigator: 5001,
	primaryNavigation: 6000,
	fullScreenFeature: 7000,
	modal: 8000,
	shadowDiv: 8480,
	overlayDetailsPanel: 8490,
	contextOptions: 8500,
	header: 99999,
	popup: 999999,
	notification: 9999999,
};

export const navBarZIndex = zIndex.secondaryNavigation;

export const ELLIPSIS = `
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

export const BACKGROUND = `
	box-sizing: border-box;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
`;

export const FLEXCENTER = `
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const InfoIconStyled = styled.div`
	position: absolute;
	right: 10px;
	top: 4px;
	color: #f5423e;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
