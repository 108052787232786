import {computed, observable, makeObservable} from "mobx";
import {
	ViewPreferenceCategory,
	type GlobalBase,
	type OrganizationDto,
	type UpdateOrganizationSettingRequest,
	XyiconFeature,
} from "../../generated/api/base";
import type {AppState} from "../state/AppState";
import {XHRLoader} from "../../utils/loader/XHRLoader";
import {StringUtils} from "../../utils/data/string/StringUtils";
import {ObjectUtils} from "../../utils/data/ObjectUtils";
import {
	cleanViewFolderStructureFromAdditionalProps,
	doesItemExistInViewFolderStructure,
	filterViewFolderStructure,
	removeElementFromViewFolderStructureById,
} from "./ViewUtils";
import type {ViewFolderStructure} from "./ViewUtils";
import type {View} from "./View";

export class Organization {
	private _appState: AppState;
	@observable
	private _data: OrganizationDto = {};

	constructor(data: OrganizationDto, appState: AppState) {
		makeObservable(this);
		this._appState = appState;
		this.applyData(data);
	}

	public applyData(data: OrganizationDto) {
		this._data = {
			...this._data,
			...data,
		};

		if (!this._data.settings) {
			this._data.settings = {};
		}
		if (!this._data.settings.logoFileName) {
			this._data.settings.logoFileName = "";
		}
		if (!this._data.settings.googleMapSettings) {
			this._data.settings.googleMapSettings = {};
		}
		if (!this._data.settings.googleMapSettings.featureLocationSetting) {
			this._data.settings.googleMapSettings.featureLocationSetting = [];
		}
		if (!this._data.settings.defaultViews) {
			this._data.settings.defaultViews = {};
		}
		if (!this._data.settings.globalViews) {
			this._data.settings.globalViews = [];
		}
	}

	@computed
	public get isSSOEnabledForOrganization() {
		return this._data.isSSOEnabledForOrganization;
	}

	@computed
	public get isSSOEnabledForUser() {
		return this._data.isSSOEnabledForUser;
	}

	@computed
	public get id() {
		return this._data.organizationID;
	}

	@computed
	public get name() {
		return this._data.name;
	}

	@computed
	public get ownerUserID() {
		return this._data.ownerUserID;
	}

	@computed
	public get settings() {
		return this._data.settings;
	}

	@computed
	public get lastModifiedAt() {
		return this._data.lastModifiedAt;
	}

	private filterWorkspaceGlobalViews = (view: View) => {
		return view.isGlobal && [XyiconFeature.Boundary, XyiconFeature.Xyicon, XyiconFeature.SpaceEditor].includes(view.itemFeature);
	};

	@computed
	public get globalViews() {
		const newGlobalViews: ViewFolderStructure = this._appState.actions
			.getAllViews()
			.filter(this.filterWorkspaceGlobalViews)
			.toSorted((a: View, b: View) => StringUtils.sortIgnoreCase(a.name, b.name))
			.map((v) => ({id: v.id, category: ViewPreferenceCategory.View}));

		if (!this.settings.globalViews?.length) {
			if (!ObjectUtils.compare(this.settings.globalViews, newGlobalViews)) {
				for (const globalView of newGlobalViews) {
					(globalView as GlobalBase).shared = {
						userGroupIDs: [],
						userIDs: [],
					};
				}
				this.settings.globalViews = newGlobalViews;
			}
		}

		for (const view of newGlobalViews) {
			if (!doesItemExistInViewFolderStructure(this.settings.globalViews as ViewFolderStructure, view.id)) {
				this.settings.globalViews.push(view);
			}
		}

		return this.settings.globalViews as ViewFolderStructure;
	}

	public setGlobalViews(globalViews: ViewFolderStructure) {
		const allViews = this._appState.actions.getAllViews();
		const allGlobalViews = allViews.filter(this.filterWorkspaceGlobalViews);
		const availableViews = allGlobalViews.toSorted((a, b) => StringUtils.sortIgnoreCase(a.name, b.name));
		const availableViewIds = availableViews.map((v) => v.id);
		const sanitizedGlobalViews = cleanViewFolderStructureFromAdditionalProps(globalViews);

		this._data.settings.globalViews = filterViewFolderStructure(sanitizedGlobalViews, availableViewIds);

		for (const viewElement of this._data.settings.globalViews) {
			if (!viewElement.shared) {
				viewElement.shared = {
					userGroupIDs: [],
					userIDs: [],
				};
			}
		}

		const params: UpdateOrganizationSettingRequest = {
			organizationSettings: this._data.settings,
		};

		return this._appState.app.transport.requestForOrganization({
			url: "organizations/updatesetting",
			method: XHRLoader.METHOD_POST,
			params,
		});
	}

	public saveGlobalViewsToDb() {
		return this.setGlobalViews(this.globalViews);
	}

	public removeElementFromGlobalViews(viewIdToDelete: string) {
		const isDeletionSuccessful = removeElementFromViewFolderStructureById(this.globalViews, viewIdToDelete);

		if (isDeletionSuccessful) {
			return true;
		}

		return false;
	}
}
