import React, {useCallback, useEffect, useRef, useState} from "react";
import {Observer} from "mobx-react";
import styled from "styled-components";
import {useAppStore} from "../../../../../StateManager";
import type {IHeader} from "../../../../widgets/table/SimpleTable";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {IModel} from "../../../../../data/models/Model";
import type {Type} from "../../../../../data/models/Type";
import type {Field} from "../../../../../data/models/field/Field";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";
import {SimpleTableV5} from "../../../abstract/table/SimpleTableV5";
import {SortDirection} from "../../../../../data/models/ViewUtils";
import {EmptyListViewV5} from "../../../details/EmptyListViewV5";
import {Panel} from "../../../abstract/Panel";
import type {TypeFieldKind} from "../../../../../data/services/TypeAndFieldServiceUtils";
import {onDeleteClickForTypeAndField} from "../../../modules/ActionBarUtils";
import {colorPalette} from "../../../styles/colorPalette";
import {baseDistance} from "../../../styles/styles";

interface ISettingTableTabProps<T> {
	readonly headers: IHeader[];
	readonly feature: XyiconFeature;
	readonly typesFeature: XyiconFeature;
	readonly kind: TypeFieldKind;
	readonly getFields: (item: T, index?: number) => (string | number)[];
	readonly right: (selected: T[]) => React.ReactNode;
	readonly create: (onCancel: () => void, onCreated: (id: string) => void) => React.ReactNode;
	readonly emptyListText?: string;
}

export const SettingTableTabV5 = <T extends IModel = Type | Field>(props: ISettingTableTabProps<T>) => {
	const {headers, typesFeature, feature, kind, emptyListText, getFields, create, right} = props;
	const appState = useAppStore((state) => state.appState);
	const modulesTypeAndFieldSearchString = useAppStore((state) => state.modulesTypeAndFieldSearchString);
	const modulesTypeAndFieldCreating = useAppStore((state) => state.modulesTypeAndFieldCreating);
	const modulesCreatingType = useAppStore((state) => state.modulesCreatingType);
	const setModulesTypeAndFieldCreating = useAppStore((state) => state.setModulesTypeAndFieldCreating);
	const {
		app: {transport},
	} = appState;

	const featureRef = useRef<XyiconFeature>(feature);

	// This is for actually checking whether the component is currently mounted
	const [selectedIds, setSelectedIds] = useState<string[]>([]);
	const [focusedIds, setFocusedIds] = useState<string[]>([]);
	const featureService = transport.services.feature;

	const derivedTypesFeature = kind === "types" ? typesFeature : feature;
	const getListFeatures = useCallback(() => {
		const features = [typesFeature];

		if (typesFeature === XyiconFeature.Xyicon) {
			features.push(XyiconFeature.XyiconCatalog); // Catalog items also use type feature
		}

		return features;
	}, [typesFeature]);

	const onCancelCreate = () => {
		setModulesTypeAndFieldCreating(false, modulesCreatingType);
	};

	const onCreated = (createdId: string) => {
		setModulesTypeAndFieldCreating(false, "");
		setSelectedIds([createdId]);
	};

	useEffect(() => {
		const initList = () => {
			const features = getListFeatures();
			const promises: Promise<IModel[]>[] = [];

			if (appState.portfolioId) {
				for (const feature of features) {
					promises.push(featureService.refreshList(feature));
				}
			}

			return Promise.all(promises);
		};
		initList();
	}, [appState, typesFeature, featureService, getListFeatures]);

	useEffect(() => {
		const onKeyUp = (event: KeyboardEvent) => {
			switch (event.key) {
				case KeyboardListener.KEY_ESCAPE:
					setModulesTypeAndFieldCreating(false, "");
					break;
				case KeyboardListener.KEY_DELETE:
					if (selectedIds.length > 0) {
						onDeleteClickForTypeAndField(selectedIds, kind, derivedTypesFeature, appState, () => setSelectedIds([]));
					}
					break;
			}
		};

		KeyboardListener.getInstance().signals.up.add(onKeyUp);

		return () => {
			KeyboardListener.getInstance().signals.up.remove(onKeyUp);
		};
	}, [selectedIds, derivedTypesFeature, appState, kind, setModulesTypeAndFieldCreating]);

	useEffect(() => {
		if (featureRef.current !== feature) {
			setSelectedIds([]);
		}
	}, [selectedIds, feature]);

	const data = kind === "fields" ? (appState.fields[feature] as unknown as T[]) : (appState.types[derivedTypesFeature] as unknown as T[]);

	const selected = selectedIds.map((id) => data.find((item) => item.id === id)).filter((item) => !!item);
	const focused = focusedIds.map((id) => data.find((item) => item.id === id)).filter((item) => !!item);

	return (
		<Observer>
			{() => {
				return (
					<>
						<Panel
							isOpen={modulesCreatingType === "fields" && modulesTypeAndFieldCreating}
							style={{top: 0, zIndex: 14}}
						>
							{modulesTypeAndFieldCreating && create(onCancelCreate, onCreated)}
						</Panel>
						<SettingTableTabContainerStyled>
							<TableAndDetailsContainerStyled>
								<SettingTableStyled>
									{data.length > 0 ? (
										<SimpleTableV5
											data={data}
											feature={feature}
											kind={kind}
											headers={headers}
											getFields={getFields}
											focused={focused}
											selected={selected}
											onSelect={(selected) => {
												setSelectedIds(selected.map((item) => item.id));
												setFocusedIds(selected.map((item) => item.id));
											}}
											onFocus={(focused) => {
												setSelectedIds([]);
												setFocusedIds(focused.map((item) => item.id));
											}}
											defaultSort={{
												column: "name",
												direction: SortDirection.ASC,
											}}
											tableSearchQuery={modulesTypeAndFieldSearchString}
										/>
									) : (
										<EmptyListViewV5 text={emptyListText} />
									)}
								</SettingTableStyled>
								<SettingTableTapSidePanelStyled>{right(focused)}</SettingTableTapSidePanelStyled>
							</TableAndDetailsContainerStyled>
						</SettingTableTabContainerStyled>
					</>
				);
			}}
		</Observer>
	);
};

const SettingTableTabContainerStyled = styled.div`
	height: 100%;
`;

const SettingTableTapSidePanelStyled = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	border-left: 1px solid ${colorPalette.gray.c300};
	height: 100%;
	padding: ${baseDistance.md};
	box-sizing: border-box;
	width: 416px;
	overflow: auto;
	background-color: ${colorPalette.white};
	max-height: calc(100% - 64px);
`;

const TableAndDetailsContainerStyled = styled.div`
	display: flex;
`;

const SettingTableStyled = styled.div`
	display: flex;
	width: 100%;
	max-width: calc(100% - 416px);
	height: 100%;
`;
