import type {Markup} from "../../../../../../../data/models/Markup";
import {MarkupType} from "../../../../../../../generated/api/base";
import {createPhotoSphereFromMarkup} from "../../../../../../../photosphere/PhotoSphereUtils";

export interface IPhotoSphereMetaData {
	viewBox: number[];
	headingAngle: number;
	originalWidth: number;
	originalHeight: number;
}

export const DefaultHeightOfMarkupPhotosInMeters = 1.6; // m

export const triggerPhoto360Previewer = (markupModelData: Markup) => {
	if (markupModelData.type === MarkupType.Photo360) {
		const photoSphere = createPhotoSphereFromMarkup(markupModelData);
		markupModelData.appState.app.graphicalTools.photoSphereManager.activePhotoSphereMaybe = photoSphere;
	} else {
		console.warn(`360 Photo previewer is attempted to open on a ${markupModelData.typeName}. Call Peter!`);
	}
};
