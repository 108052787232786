import {computed, observable, makeObservable, action, autorun} from "mobx";
import type {AppState} from "../state/AppState";
import {getDefaultInsertionInfo} from "../../ui/modules/catalog/create/CatalogTypes";
import {XyiconFeature} from "../../generated/api/base";
import type {
	PortTemplateDto,
	CatalogIconType,
	XyiconCatalogDto,
	XyiconCatalogIconDto,
	Color,
	XyiconCatalogUpdateTypeDto,
} from "../../generated/api/base";
import {ImageUploadPreprocessor} from "../../utils/image/ImageUploadPreprocessor";
import {ArrayUtils} from "../../utils/data/array/ArrayUtils";
import {XHRLoader} from "../../utils/loader/XHRLoader";
import {ImageUtils} from "../../utils/image/ImageUtils";
import type {IModel} from "./Model";

export interface ICatalogSettings {
	xyiconFieldSettings: {
		visibleFields: string[];
	};
	catalogFieldSettings: {
		visibleFields: string[];
	};
	glyphChildrenColors: Color[];
	source: string;
}

export class Catalog implements IModel, XyiconCatalogDto {
	public static search(catalog: Catalog, search = "") {
		if (!search) {
			return true;
		}

		search = search.toLowerCase();
		const model = catalog.model.toLowerCase();

		if (model.includes(search)) {
			return true;
		}

		return false;
	}

	private _appState: AppState;
	public readonly ownFeature = XyiconFeature.XyiconCatalog;

	@observable
	private _data: XyiconCatalogDto;

	constructor(data: XyiconCatalogDto, appState: AppState) {
		makeObservable(this);
		this._data = data;

		/**
		 * Single quotes can cause problems in an icon
		 * when we set it as a background image with css.
		 * we can fix them with this workaround
		 */
		this.updateIconData(this._data.iconData, this._data.iconType, this._data.icon);
		this._data.fieldData = this._data.fieldData || {};
		this._data.portTemplate = this._data.portTemplate || [];
		this._appState = appState;

		this.applySettingsData();

		autorun(() => {
			this.updateSVGIconIfNeeded();
		});
	}

	public applySettingsData() {
		if (this._data.settings && this._data.settings !== "null") {
			if (typeof this._data.settings === "string") {
				this._data.settings = JSON.parse(this._data.settings);
			}

			if (!this._data.settings.catalogFieldSettings) {
				this._data.settings.catalogFieldSettings = {visibleFields: []};
			}
			if (!this._data.settings.xyiconFieldSettings) {
				this._data.settings.xyiconFieldSettings = {visibleFields: []};
			}

			if (!this._data.settings.glyphChildrenColors) {
				this._data.settings.glyphChildrenColors = [];
			}
		} else {
			const settings: ICatalogSettings = {
				xyiconFieldSettings: {
					visibleFields: [],
				},
				catalogFieldSettings: {
					visibleFields: [],
				},
				glyphChildrenColors: [],
				source: "",
			};

			this._data.settings = settings;
		}
	}

	public applyUpdate(data: XyiconCatalogUpdateTypeDto) {
		if (data.xyiconCatalogID.includes(this.id)) {
			this._data.lastModifiedAt = data.lastModifiedAt || this._data.lastModifiedAt;
			this._data.lastModifiedBy = data.lastModifiedBy || this._data.lastModifiedBy;
		}
	}

	public applyFieldValues(fieldValues: Record<string, any> | null) {
		if (fieldValues?.model !== undefined) {
			this.setModel(fieldValues.model);
		}
	}

	public updateIconData(iconData: XyiconCatalogIconDto, iconCategory: CatalogIconType, icon: string) {
		this._data.iconData = iconData;
		this._data.iconType = iconCategory;
		/**
		 * Single quotes can cause problems in an icon
		 * when we set it as a background image with css.
		 * we can fix them with this workaround: replace them with "apostrophe"
		 */
		this._data.icon = icon.replace(/'/g, "\u2018").replace(/\n\s*/g, ""); // replace single quotes, and remove white-spaces;
	}

	public setFavorite(value: boolean) {
		const user = this._appState.user;
		const {favoriteCatalogs} = user;

		if (value) {
			ArrayUtils.addMutable(favoriteCatalogs, this.id);
		} else {
			ArrayUtils.removeMutable(favoriteCatalogs, this.id);
		}

		return this._appState.app.transport.requestForOrganization({
			url: "users/markentitiesasfavorites",
			method: XHRLoader.METHOD_POST,
			params: {
				entityIDList: [this.id],
				feature: this.ownFeature,
				isFavorite: value,
			},
		});
	}

	@computed
	public get isFavorite(): boolean {
		const arr = this._appState.user?.favoriteCatalogs || [];

		return arr.includes(this.id);
	}

	@computed
	public get id() {
		return this._data.xyiconCatalogID;
	}

	@computed
	public get refId() {
		return this._data.xyiconCatalogRefID;
	}

	public setFieldData(fieldData: {[refId: string]: any}) {
		this._data.fieldData = {...fieldData};
	}

	@computed
	public get fieldData() {
		return this._data.fieldData;
	}

	public set typeId(value: string) {
		this._data.xyiconTypeID = value;
	}

	@computed
	public get typeId() {
		return this._data.xyiconTypeID;
	}

	@computed
	public get type() {
		return this._appState.actions.getTypeById(this.typeId);
	}

	@computed
	public get typeName() {
		return this.type?.name || "";
	}

	@computed
	public get iconCategory() {
		return this._data.iconType;
	}

	@computed
	public get iconData() {
		return this._data.iconData;
	}

	public setPortTemplate(portTemplate: PortTemplateDto[]) {
		this._data.portTemplate = portTemplate;
	}

	@computed
	public get portTemplate(): PortTemplateDto[] {
		return this._data.portTemplate;
	}

	public setModel(value: string) {
		this._data.model = value;
	}

	public setGlyphChildrenColors(colors: Color[]) {
		this._data.settings.glyphChildrenColors = colors;
	}

	@computed
	public get glyphChildrenColors() {
		return this.settings.glyphChildrenColors;
	}

	@computed
	public get insertionInfo() {
		return this._data.iconData.modelParameters?.insertionInfo ?? getDefaultInsertionInfo();
	}

	@computed
	public get model() {
		return this._data.model;
	}

	@computed
	public get icon() {
		return this._data.icon;
	}

	@computed
	public get xyiconTypeId() {
		return this._data.xyiconTypeID;
	}

	@action
	private updateSVGIconIfNeeded() {
		const dataIcon = ImageUtils.embedFontIntoSvgIfNecessary(this._data.icon, this._appState.fonts);
		if (this._data.icon !== dataIcon) {
			this._data.icon = dataIcon;
		}
	}

	@computed
	public get thumbnail() {
		return ImageUploadPreprocessor.changeProblematicCharactersToURLEncode(this._data.icon);
	}

	public set lastModifiedBy(value: string) {
		this._data.lastModifiedBy = value;
	}

	@computed
	public get lastModifiedBy() {
		return this._data.lastModifiedBy;
	}

	public set lastModifiedAt(value: string) {
		this._data.lastModifiedAt = value;
	}

	@computed
	public get lastModifiedAt() {
		return this._data.lastModifiedAt;
	}

	@computed
	public get data() {
		return this._data;
	}

	@computed
	public get settings(): ICatalogSettings {
		return this._data.settings;
	}

	@computed
	public get xyiconVisibleFields(): string[] {
		return this.settings.xyiconFieldSettings?.visibleFields || [];
	}

	@computed
	public get catalogVisibleFields(): string[] {
		return this.settings.catalogFieldSettings?.visibleFields || [];
	}

	@computed
	public get source() {
		return this.settings.source;
	}
}
