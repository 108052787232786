import {styled} from "styled-components";
import {FLEXCENTER, baseDistance, fontSize, fontWeight, radius, zIndex} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";
import {AvatarStyled} from "../user/Avatar.styled";

export const DropdownContentStyled = styled.div`
	top: 0;
	left: 0;
	visibility: hidden;
	display: block;
	position: absolute;
	color: ${colorPalette.gray.c950};
	background-color: ${colorPalette.white};
	min-width: 200px;
	width: 260px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: ${zIndex.popup};
	padding: 5px;
	border-radius: ${radius.md};
	cursor: pointer;
`;

export const ProfileItemStyled = styled.div`
	height: 40px;
	position: relative;
	display: flex;
	align-items: center;
	padding-left: ${baseDistance.sm};
	gap: ${baseDistance.sm};

	& > span {
		flex: 1;
	}

	.arrow {
		&.isOpen {
			transform: rotate(0deg);
		}
	}

	.CheckboxInput {
		width: ${baseDistance.sm};
		margin-top: ${baseDistance.xs};
		position: absolute;
		right: 18px;
	}

	${AvatarStyled} {
		width: 24px;
		height: 24px;
		font-size: ${fontSize.sm};
	}

	.profileButton {
		margin: 0px 15px 5px 15px;
		left: 0;
		${FLEXCENTER}
		justify-content: left;
		font-size: ${fontSize.lg};
		font-weight: ${fontWeight.normal};
		min-height: 40px;

		.icon {
			margin-right: 15px;
			fill: black;
			width: 18px;
			height: 18px;
			stroke-width: 1px;
		}

		&:hover {
			color: ${colorPalette.primary.c500Primary};
		}
	}

	&.lined {
		.profileButton {
			margin-bottom: 0;
		}

		border-top: 2px solid ${colorPalette.gray.c300};
	}

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
		border-radius: ${radius.sm};
	}

	.ToggleSwitchField {
		align-items: center;

		.toggleSwitch {
			position: absolute;
			right: 8px;
			margin-left: auto;
			padding-right: ${baseDistance.sm};
			height: 24px;
		}

		.label {
			cursor: pointer;
		}
	}
`;
