import type {FunctionComponent, SVGProps} from "react";
import styled, {css} from "styled-components";
import * as React from "react";
import type {Color} from "../../../../../generated/api/base";
import type {SpaceTool} from "../../../../modules/space/spaceeditor/logic3d/features/tools/Tools";
import HandIcon from "../../../icons/hand.svg?react";
import BoundaryIcon from "../../../icons/boundary.svg?react";
import CursorIcon from "../../../icons/cursor.svg?react";
import CatalogIcon from "../../../icons/book-open.svg?react";
import ArrowBackIcon from "../../../icons/arrow-back.svg?react";
import MarkupCloudIcon from "../../../icons/markup-cloud.svg?react";
import MarkupCalloutIcon from "../../../icons/markup-callout.svg?react";
import MarkupArrowIcon from "../../../icons/markup-arrow.svg?react";
import MarkupBidirectionalArrowIcon from "../../../icons/markup-bidirectional-arrow.svg?react";
import MarkupDashedLineIcon from "../../../icons/markup-dashed-line.svg?react";
import MarkupCircleIcon from "../../../icons/markup-circle.svg?react";
import MarkupCrossIcon from "../../../icons/markup-x.svg?react";
import MarkupHighlightIcon from "../../../icons/markup-highlight.svg?react";
import MarkupLineIcon from "../../../icons/markup-line.svg?react";
import MarkupMeasureRectangleIcon from "../../../icons/markup-measure-rectangle.svg?react";
import MarkupMeasureIrregularAreaIcon from "../../../icons/markup-measure-irregular-area.svg?react";
import MarkupMeasureLineIcon from "../../../icons/markup-measure-line.svg?react";
import MarkupMeasureNonlinearDistanceIcon from "../../../icons/markup-measure-nonlinear-distance.svg?react";
import MarkupPencilIcon from "../../../icons/markup-pencil.svg?react";
import MarkupRectangleIcon from "../../../icons/markup-rectangle.svg?react";
import MarkupTextIcon from "../../../icons/markup-text.svg?react";
import MarkupTriangleIcon from "../../../icons/markup-triangle.svg?react";
import MarkupPhoto360Icon from "../../../icons/markup-photo-360.svg?react";
import PenIcon from "../../../icons/pen.svg?react";
import PinIcon from "../../../icons/pin.svg?react";
import MoveIcon from "../../../icons/arrows-cross.svg?react";
import SetScaleIcon from "../../../icons/scale.svg?react";
import UnplottedXyiconIcon from "../../../icons/unplotted-xyicon.svg?react";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {baseDistance, FlexCenterStyle, fontSize, radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";

export type ExtendedToolIconKey = "back" | "pen" | "catalog" | "boundary" | "unplotted" | "text" | "markupLineDashed" | SpaceTool;
export type TTitleAlignment = "top" | "right";
export type TTitle = {
	$title: string;
	$titleAlignment: TTitleAlignment;
	$isSingleSelectColor?: boolean;
};

export interface IToolButtonProps {
	readonly icon: ExtendedToolIconKey;
	readonly title?: string;
	readonly titleAlignment?: TTitleAlignment;
	readonly isActive?: boolean;
	readonly onClick?: () => void;
	readonly color?: Color;
	readonly children?: React.ReactNode;
}

const toolIcons: {[toolId in ExtendedToolIconKey]: FunctionComponent<SVGProps<SVGSVGElement> & {title?: string}>} = {
	back: ArrowBackIcon,
	boundary: BoundaryIcon,
	catalog: CatalogIcon,
	markupArrow: MarkupArrowIcon,
	markupBidirectionalArrow: MarkupBidirectionalArrowIcon,
	markupCallout: MarkupCalloutIcon,
	markupCloud: MarkupCloudIcon,
	markupCross: MarkupCrossIcon,
	markupDashedLine: MarkupDashedLineIcon,
	markupEllipse: MarkupCircleIcon,
	markupHighlight: MarkupHighlightIcon,
	markupLine: MarkupLineIcon,
	markupLineDashed: MarkupDashedLineIcon,
	markupPencil: MarkupPencilIcon,
	markupRectangle: MarkupRectangleIcon,
	markupPhoto360: MarkupPhoto360Icon,
	markupText: MarkupTextIcon,
	markupTriangle: MarkupTriangleIcon,
	measureIrregularArea: MarkupMeasureIrregularAreaIcon,
	measureLinearDistance: MarkupMeasureLineIcon,
	measureNonLinearDistance: MarkupMeasureNonlinearDistanceIcon,
	measureRectArea: MarkupMeasureRectangleIcon,
	moveBackground: MoveIcon,
	pan: HandIcon,
	pen: PenIcon,
	selection: CursorIcon,
	setPin: PinIcon,
	setScale: SetScaleIcon,
	tempMeasureIrregularArea: MarkupMeasureIrregularAreaIcon,
	tempMeasureLinearDistance: MarkupMeasureLineIcon,
	tempMeasureNonLinearDistance: MarkupMeasureNonlinearDistanceIcon,
	tempMeasureRectArea: MarkupMeasureRectangleIcon,
	text: MarkupTextIcon,
	unplotted: UnplottedXyiconIcon,
};

export const ToolButtonV5 = (props: IToolButtonProps) => {
	const Icon = toolIcons[props.icon];

	if (!Icon) {
		console.log(props.icon);
	}

	const iconProps: SVGProps<SVGSVGElement> & {title?: string} = {
		color: props.color?.hex ? `#${props.color.hex}` : "white",
		width: 24,
		height: 24,
	};

	if (props.icon === "markupPhoto360") {
		iconProps.fill = props.isActive ? "white" : iconProps.color;
	}

	return (
		<ToolButtonStyled
			className={ReactUtils.cls("ToolButtonStyled", {isActive: props.isActive})}
			onClick={props.onClick}
			data-title={props.title}
			$title={props.title}
			$titleAlignment={props.titleAlignment}
		>
			<Icon {...iconProps} />
			{props.children}
		</ToolButtonStyled>
	);
};

export const TitleStyled = css<TTitle>`
	${(props) =>
		!!props.$title &&
		css`
			&:hover::after {
				content: "${props.$title}";
				background-color: ${colorPalette.gray.c950};
				border-radius: ${radius.sm};
				position: absolute;
				color: ${colorPalette.white};
				display: block;
				font-size: ${fontSize.xs};
				padding: ${baseDistance.xs};
				width: max-content;
				z-index: 1000;
				left: ${props.$titleAlignment === "top" ? "50%" : "41px"};
				transform: ${props.$titleAlignment === "top" ? (props.$isSingleSelectColor ? "translate(-30%)" : "translate(-50%)") : "none"};
				top: ${props.$titleAlignment === "top" ? "-24px" : "inherit"};
			}
		`};
`;

export const ToolButtonStyled = styled.div<TTitle>`
	${FlexCenterStyle};
	justify-content: center;
	position: relative;
	width: 32px;
	height: 32px;
	border-radius: ${radius.sm};
	cursor: pointer;
	${TitleStyled};
`;
