import {useCallback, useEffect, useRef, useState} from "react";
import {SearchFieldV5} from "../input/search/SearchFieldV5";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {appHolder} from "../../../utils/AppUtils";
import {useAppStore} from "../../../StateManager";
import {KeyboardListener} from "../../../utils/interaction/key/KeyboardListener";
import {PortfolioWideSearchV5} from "./PortfolioWideSearchV5";

export const MainSearchV5 = () => {
	const [queryString, setQueryString] = useState<string>("");
	const navBarState = useAppStore((state) => state.navBarState);
	const isSearchWindowOpen = useAppStore((state) => state.isPortfolioWideSearchOpen);
	const searchInputRef = useRef<HTMLDivElement>(null);
	const setSearchWindowOpenState = useAppStore((state) => state.setIsPortfolioWideSearchOpen);

	const onChangeSearch = (value: string) => {
		setQueryString(value);
	};

	const onInputPress = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === KeyboardListener.KEY_ENTER) {
				setSearchWindowOpenState(true);
			}
		},
		[setSearchWindowOpenState],
	);

	useEffect(() => {
		if (searchInputRef.current) {
			const input = searchInputRef.current;

			input.addEventListener("keypress", onInputPress);

			return () => {
				input.removeEventListener("keypress", onInputPress);
			};
		}
	}, [onInputPress]);

	const triggerSearch = () => {
		if (queryString) {
			setSearchWindowOpenState(true);
		}
	};

	const closeSearchInput = () => {
		setQueryString("");
		setSearchWindowOpenState(false);
	};

	return (
		<div>
			<SearchFieldV5
				value={queryString}
				onInput={onChangeSearch}
				divRef={searchInputRef}
				resetSearch={closeSearchInput}
				triggerSearch={triggerSearch}
				placeholder="Search"
			/>
			{isSearchWindowOpen && (
				<DomPortal destination={appHolder()}>
					<PortfolioWideSearchV5
						query={queryString}
						loading={false}
						closeSearchInput={closeSearchInput}
						navBarState={navBarState}
					/>
				</DomPortal>
			)}
		</div>
	);
};
