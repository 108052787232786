import {Observer} from "mobx-react";
import {useCallback, useRef, useState} from "react";
import {User} from "../../../data/models/User";
import {ReactUtils} from "../../utils/ReactUtils";
import type {Permission} from "../../../generated/api/base";
import {XyiconFeature} from "../../../generated/api/base";
import {UserGroup} from "../../../data/models/UserGroup";
import {AddUserOrUserGroupStyled, DropdownSelectorStyled, SectionTitle, SharingPanelStyled} from "../sharing/SharingPanel.styled";
import {useAppStore} from "../../../StateManager";
import {useClickOutside} from "../utils";
import {SelectSearchFieldV5} from "../input/search/SelectSearchFieldV5";
import {GroupShareSelectorV5} from "../sharing/GroupShareSelectorV5";
import type {SelectedItem} from "../sharing/SharingUtils";

interface IAddUserOrUserGroupProps {
	readonly ownedByUserId: string;
	readonly exceptions: string[];
	readonly selectedItemList: SelectedItem[];
	readonly setSelectedItems: (items: SelectedItem[]) => void;
	readonly onDeleteItem: (item: User | UserGroup) => void;
	readonly getPermissionType: (permission: Permission.View | Permission.Update) => void;
	readonly isGlobal?: boolean;
}

export const AddUserOrUserGroupV5 = (props: IAddUserOrUserGroupProps) => {
	const appState = useAppStore((state) => state.appState);
	const {exceptions, ownedByUserId, selectedItemList, setSelectedItems, getPermissionType, onDeleteItem, isGlobal} = props;
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [searchString, setSearchString] = useState<string>("");
	const ref = useRef<HTMLDivElement>();

	const onToggleIsOpen = () => {
		setIsOpen((o) => !o);
	};

	const onClose = useCallback(() => {
		setIsOpen(false);
		setSearchString("");
	}, []);

	useClickOutside([ref], onClose);

	const onItemSelect = (item: User | UserGroup) => {
		const {ownFeature: feature, id} = item;
		const itemName = feature === XyiconFeature.User ? (item as User).firstName : (item as UserGroup).name;
		const isItemSelected = selectedItemList.some((item) => item.id === id);

		if (!isItemSelected) {
			setSelectedItems([...selectedItemList, {id, itemName, feature}]);
		}

		setSearchString("");
	};

	const onItemRemove = (item: User | UserGroup) => {
		const updatedItems = selectedItemList.filter((selectedItem) => selectedItem.id !== item.id);
		setSelectedItems(updatedItems);
	};

	const getPermission = (value: Permission.View | Permission.Update) => {
		getPermissionType(value);
	};

	return (
		<Observer>
			{() => {
				const exceptionsWithCurrentUser = [...exceptions, !isGlobal && appState.user?.id];

				let users = appState.actions.getList<User>(XyiconFeature.User);
				let userGroups = appState.actions.getList<UserGroup>(XyiconFeature.UserGroup);

				let sharedUsers: User[] = [];
				let sharedUserGroups: UserGroup[] = [];

				if (searchString) {
					users = users.filter((user) => User.search(user, searchString));
					userGroups = userGroups.filter((userGroup) => UserGroup.search(userGroup, searchString));
				}

				if (exceptionsWithCurrentUser?.length) {
					sharedUsers = users.filter((user) => exceptionsWithCurrentUser.includes(user.id));
				}

				if (exceptions?.length) {
					sharedUserGroups = userGroups.filter((userGroup) => exceptions.includes(userGroup.id));
				}

				return (
					<AddUserOrUserGroupStyled
						ref={ref}
						className={ReactUtils.cls("AddUserOrUserGroup", {isOpen})}
					>
						<SelectSearchFieldV5
							className="hbox flex_1"
							value={searchString}
							onInput={setSearchString}
							onClick={() => onToggleIsOpen()}
							selectedItems={selectedItemList}
							placeholder={!isOpen && selectedItemList.length === 0 ? "Enter user/group, comma separated" : " "}
							getPermissionType={getPermission}
							isDropdown={true}
							isSharing={true}
							removeSelectedItem={setSelectedItems}
						/>
						{(isOpen || searchString) && (
							<DropdownSelectorStyled
								className="AddUserOrUserGroup__selector vbox"
								ref={ref}
								$isDropdown={true}
							>
								<SharingPanelStyled $isDropdown={true}>
									{userGroups.length !== 0 && (
										<div className="section borderBottom">
											<SectionTitle>User Groups</SectionTitle>
											<GroupShareSelectorV5
												items={userGroups}
												addedItems={sharedUserGroups}
												selectedItemList={selectedItemList}
												isSharing={true}
												isGlobal={isGlobal}
												onDeleteItem={onDeleteItem}
												onItemSelect={onItemSelect}
												onItemRemove={onItemRemove}
												feature={XyiconFeature.UserGroup}
											/>
										</div>
									)}
									{users.length !== 0 && (
										<div className="section">
											<SectionTitle>Users</SectionTitle>
											<GroupShareSelectorV5
												items={users}
												addedItems={sharedUsers}
												selectedItemList={selectedItemList}
												isSharing={true}
												isGlobal={isGlobal}
												onDeleteItem={onDeleteItem}
												onItemSelect={onItemSelect}
												onItemRemove={onItemRemove}
												ownedByUserId={ownedByUserId}
												feature={XyiconFeature.User}
											/>
										</div>
									)}
									{userGroups.length === 0 && users.length === 0 && (
										<div className="empty">We couldn't find a match. To invite this user to Xyicon, please contact your Administrator.</div>
									)}
								</SharingPanelStyled>
							</DropdownSelectorStyled>
						)}
					</AddUserOrUserGroupStyled>
				);
			}}
		</Observer>
	);
};
