import styled from "styled-components";
import {baseDistance, radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import {ColorSelectorStyled} from "../../../colors/ColorSelectorV5";
import {ToolButtonStyled} from "./ToolButtonV5";

export const SpaceToolBarStyled = styled.div<{$activeBackgroundColor: string}>`
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.sm};
	z-index: 2;
	padding: ${baseDistance.sm};
	top: 50%;
	left: 16px;
	border-radius: ${radius.md};
	background: ${colorPalette.gray.c950};
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
	transition: all 500ms ease-out;
	transform: translateY(-50%);

	&.collapsed {
		transform: translateX(-80px) translateY(-50%);
	}

	${ToolButtonStyled}, ${ColorSelectorStyled} {
		border-radius: ${radius.md};

		svg {
			transition: transform 0.1s ease-out;
		}

		&:hover {
			svg {
				transform: scale(1.1);
			}
		}

		&.isActive {
			background-color: ${(props) => props.$activeBackgroundColor};

			svg {
				color: ${colorPalette.white};
			}
		}
	}
`;
