import styled from "styled-components";
import {baseDistance, fontSize, fontWeight, radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import CloseIcon from "../../../icons/xmark.svg?react";
import {GridCard} from "./GridCard";

interface IGridSelectorProps {
	readonly onClose: () => void;
}

export const GridSelector = (props: IGridSelectorProps) => {
	const {onClose} = props;

	return (
		<GridSelectorStyled>
			<GridSelectorHeaderStyled>
				<span>Select List Mode</span>
				<IconButtonV5
					IconComponent={CloseIcon}
					onClick={onClose}
				/>
			</GridSelectorHeaderStyled>
			<ListWrapper>
				<ListTitleStyled>Xyicon List</ListTitleStyled>
				<CardWrapper>
					<GridCard
						sideOfList="left"
						typeOfList="xyicon"
						onClose={onClose}
					/>
					<GridCard
						sideOfList="right"
						typeOfList="xyicon"
						onClose={onClose}
					/>
				</CardWrapper>
			</ListWrapper>
			<ListWrapper>
				<ListTitleStyled>Boundary List</ListTitleStyled>
				<CardWrapper>
					<GridCard
						sideOfList="left"
						typeOfList="boundary"
						onClose={onClose}
					/>
					<GridCard
						sideOfList="right"
						typeOfList="boundary"
						onClose={onClose}
					/>
				</CardWrapper>
			</ListWrapper>
		</GridSelectorStyled>
	);
};

const CardWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 64px;
`;

const ListTitleStyled = styled.div`
	font-size: ${fontSize.lg};
	line-height: 24px;
	font-weight: ${fontWeight.bold};
`;

const ListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: ${radius.xl};
	background-color: ${colorPalette.white};
	padding: ${baseDistance.md};
	gap: ${baseDistance.md};
	border: 1px solid transparent;

	&:hover {
		background-color: ${colorPalette.gray.c100};
		border: 1px solid ${colorPalette.gray.c300};
	}
`;

const GridSelectorHeaderStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: ${fontWeight.bold};
	font-size: ${fontSize.xl};
	line-height: 24px;
`;

const GridSelectorStyled = styled.div`
	margin-top: ${baseDistance.sm};
	border-radius: ${radius.xl};
	background-color: ${colorPalette.white};
	box-shadow: 0px 4px 8px 0px #00000080;
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.sm};
	padding: ${baseDistance.md};
	z-index: 1;
	width: 649px;
	max-height: calc(100vh - 150px);
	overflow-y: auto;
`;
