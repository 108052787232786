import * as React from "react";
import type {ViewSharingSettingsData} from "../../../generated/api/base";
import {Permission, XyiconFeature} from "../../../generated/api/base";
import type {User} from "../../../data/models/User";
import type {UserGroup} from "../../../data/models/UserGroup";
import {InitialsV5} from "../widgets/InitialsV5";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {ShareOptionV5} from "../details/ShareOptionV5";
import {ReactUtils} from "../../utils/ReactUtils";
import {ButtonV5} from "../button/ButtonV5";
import type {ReportSharingSettingsData} from "../../../generated/api/reports";
import type {Portfolio} from "../../../data/models/Portfolio";
import {NameContainerStyled, RowStyled} from "./SharingPanel.styled";
import type {IGlobalViewFolderSharingSettingsData} from "./SharingPopupV5";
import type {SelectedItem} from "./SharingUtils";

interface IEditViewSharingProps {
	readonly items: (User | UserGroup | Portfolio)[];
	readonly addedItems: (User | UserGroup | Portfolio)[];
	readonly feature: XyiconFeature.User | XyiconFeature.UserGroup | XyiconFeature.Portfolio;
	readonly selectedItemList?: SelectedItem[];
	readonly sharingList?: (ViewSharingSettingsData | ReportSharingSettingsData | IGlobalViewFolderSharingSettingsData)[];
	readonly isSharing?: boolean;
	readonly showShareOption?: boolean;
	readonly ownedByUserId?: string;
	readonly isGlobal?: boolean;
	readonly itemLabel?: "View" | "Report" | "View Folder" | "Global View" | "Global Report";
	readonly onDeleteItem: (item: any) => void;
	readonly onItemSelect?: (item: any) => void;
	readonly onItemRemove?: (items: any) => void;
	readonly setUserPermission?: (permission: number, sharingItem: ViewSharingSettingsData | ReportSharingSettingsData) => void;
	readonly setUserGroupPermission?: (item: any, sharingList: any) => void;
}

export const GroupShareSelectorV5 = (props: IEditViewSharingProps) => {
	const {
		items,
		addedItems,
		selectedItemList,
		sharingList,
		isSharing,
		showShareOption,
		ownedByUserId,
		onDeleteItem,
		onItemSelect,
		onItemRemove,
		setUserPermission,
		setUserGroupPermission,
		isGlobal,
		itemLabel,
		feature,
	} = props;

	const toggleSelect = (item: User | UserGroup | Portfolio) => {
		if (!addedItems.some((addedItem) => addedItem.id === item.id)) {
			if (!selectedItemList.some((selectedItem) => selectedItem.id === item.id)) {
				onItemSelect(item);
			} else {
				onItemRemove(item);
			}
		}
	};

	return (
		<>
			{items
				.toSorted((a, b) => {
					if (feature === XyiconFeature.User) {
						return StringUtils.sortIgnoreCase((a as User).fullName, (b as User).fullName);
					} else if (feature === XyiconFeature.UserGroup) {
						return StringUtils.sortIgnoreCase((a as UserGroup).name, (b as UserGroup).name);
					} else if (feature === XyiconFeature.Portfolio) {
						return StringUtils.sortIgnoreCase((a as Portfolio).name, (b as Portfolio).name);
					}
				})
				.map((item) => {
					let sharingItem: ViewSharingSettingsData | ReportSharingSettingsData | undefined;
					let hasUpdatePermission = false;
					const isSelected = selectedItemList?.some((selectedItem) => selectedItem.id === item.id);
					const name =
						feature === XyiconFeature.User
							? (item as User).fullName
							: feature === XyiconFeature.UserGroup
								? (item as UserGroup).name
								: (item as Portfolio).name;

					if (sharingList) {
						sharingItem = sharingList?.find((sharing) =>
							feature === XyiconFeature.User ? sharing.userID === item.id : sharing.userGroupID === item.id,
						);
						hasUpdatePermission =
							(sharingItem as ViewSharingSettingsData)?.canEditSharedView || (sharingItem as ReportSharingSettingsData)?.canEditSharedReport || false;
					}

					return (
						<RowStyled
							key={item.id}
							className={ReactUtils.cls({
								unregistered: feature === XyiconFeature.User && (item as User).status === "invited",
								selected: isSelected,
							})}
							onClick={() => toggleSelect(item)}
						>
							<div className="avatar">
								{feature === XyiconFeature.User ? (
									(item as User).profileFileName ? (
										<img
											src={(item as User).profileFileName}
											alt={`${(item as User).fullName} profile image`}
										/>
									) : (
										<InitialsV5
											name={(item as User).fullName || (item as User).email}
											className="image"
										/>
									)
								) : feature === XyiconFeature.UserGroup ? (
									<InitialsV5
										name={(item as UserGroup).name}
										className={ReactUtils.cls("image", {isSquare: true})}
									/>
								) : (
									<InitialsV5
										name={(item as Portfolio).name}
										className={ReactUtils.cls("image", {isUser: false})}
									/>
								)}
							</div>
							<NameContainerStyled>
								<div
									className="name"
									title={name}
								>
									{name}
									{feature === XyiconFeature.User ? (
										<div className="email">{(item as User).email}</div>
									) : (
										feature === XyiconFeature.UserGroup && <div className="counter">{(item as UserGroup).renderMemberCount()}</div>
									)}
								</div>
							</NameContainerStyled>
							{item.id !== ownedByUserId &&
								addedItems.some((addedItem) => addedItem.id === item.id) &&
								(!isGlobal || (isGlobal && (item as UserGroup).name !== "Administrators")) &&
								itemLabel !== "View Folder" && (
									<ButtonV5
										label={isSharing ? "Unshare" : "Remove"}
										title={isSharing ? "Unshare" : "Remove"}
										className="unshare"
										onClick={(e) => onDeleteItem(item)}
									/>
								)}
							{sharingList &&
								showShareOption &&
								(isGlobal && (item as UserGroup).name === "Administrators" ? (
									<span className="sharedStyles disabled">Can Edit</span>
								) : (
									<ShareOptionV5
										value={hasUpdatePermission ? Permission.Update : Permission.View}
										onChange={
											feature === XyiconFeature.User
												? (permission) => setUserPermission(permission, sharingItem)
												: (permission) => setUserGroupPermission(permission, sharingItem)
										}
										hasOwner={false}
									/>
								))}
						</RowStyled>
					);
				})}
		</>
	);
};
