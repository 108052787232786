import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {Space} from "../../../data/models/Space";
import type {AppState} from "../../../data/state/AppState";
import type {IModel} from "../../../data/models/Model";
import type {XyiconFeature} from "../../../generated/api/base";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {EmptyListViewV5} from "../details/EmptyListViewV5";
import type {IActionBarItem} from "../abstract/ModuleViewV5";
import {GridActionBarV5} from "../abstract/GridActionBarV5";
import {NoResultSearchViewV5} from "../abstract/table/NoResultSearchViewV5";
import {NoResultFilterViewV5} from "../abstract/table/NoResultFilterViewV5";
import {baseDistance} from "../styles/styles";
import {SpaceCard} from "./spaceeditor/spaceselector/SpaceCard";

interface ICardViewProps<T extends IModel> {
	readonly appState?: AppState;
	readonly feature: XyiconFeature;
	readonly items: T[];
	readonly filterSuppression?: boolean;
	readonly selected: T[];
	readonly focused: T[];
	readonly onSelect: (item: T[]) => void;
	readonly onFocus: (item: T[]) => void;
	readonly onDoubleClick: (item: T) => void;
	readonly onAddClick: () => void;
	readonly searchString: string;
	readonly actionBarButtons?: IActionBarItem<T>[];
	readonly actionBarDefaultClick: (item: IActionBarItem<T>) => void;
	readonly onCloseGridActionBar?: () => void;
}

@inject("appState")
@observer
export class CardViewV5<T extends IModel> extends React.Component<ICardViewProps<T>> {
	public override render() {
		const {
			appState,
			items: items_,
			searchString,
			filterSuppression,
			feature,
			selected,
			focused,
			onSelect,
			onFocus,
			onDoubleClick,
			onAddClick,
			actionBarButtons,
			actionBarDefaultClick,
			onCloseGridActionBar,
		} = this.props;
		const searchedItems = appState.actions.searchModels(items_, searchString, feature);

		const items = searchedItems.toSorted((a: IModel, b: IModel) => StringUtils.sortIgnoreCase(a.name, b.name));

		const hasSearchSuppression = items_.length !== items.length;

		return (
			<>
				{items.length > 0 ? (
					<CardViewStyled>
						{items.map((item: T) => (
							<SpaceCard
								key={item.id}
								isSelected={selected.includes(item) || focused.includes(item)}
								space={item as IModel as Space}
								onClick={(s) => {
									if (!selected.includes(s as IModel as T)) {
										onSelect([s as IModel as T]);
										onFocus([s as IModel as T]);
									}
								}}
								onDoubleClick={onDoubleClick as (item: IModel) => void}
								topRight="VersionSelector"
							/>
						))}
					</CardViewStyled>
				) : filterSuppression ? (
					<NoResultFilterViewV5 />
				) : hasSearchSuppression ? (
					<NoResultSearchViewV5 searchString={searchString} />
				) : (
					<EmptyListViewV5
						onAddClick={onAddClick}
						feature={feature}
					/>
				)}

				{selected.length > 0 && actionBarButtons?.length > 0 && (
					<GridActionBarV5
						items={selected}
						feature={this.props.feature}
						buttons={actionBarButtons}
						defaultClick={actionBarDefaultClick}
						onClose={onCloseGridActionBar}
					/>
				)}
			</>
		);
	}
}

const CardViewStyled = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
	grid-auto-rows: 208px;
	gap: ${baseDistance.lg};
	overflow-y: auto;
	height: 100%;
	padding: ${baseDistance.md};
`;
