import {NoResultViewStyled} from "./NoResultStyled";

interface INoResultSearchViewProps {
	readonly searchString: string;
}

export const NoResultSearchViewV5 = (props: INoResultSearchViewProps) => {
	const {searchString} = props;

	return <NoResultViewStyled className="message">{`No results found for the term "${searchString}"`}</NoResultViewStyled>;
};
