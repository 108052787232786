import styled, {css} from "styled-components";
import type {ISingleSelectFieldSettingsDefinition} from "../../../../data/models/field/datatypes/SingleSelect";
import {HorizontalAlignment} from "../../../../utils/dom/DomUtils";
import {SelectInputV5} from "../../input/select/SelectInputV5";
import type {IInputPropsV5} from "../../../widgets/input/clicktoedit/InputUtils";
import {ELLIPSIS, baseDistance, FLEXCENTER, FlexCenterStyle, fontSize, radius} from "../../styles/styles";
import Flag from "../../icons/flag.svg?react";
import Circle from "../../icons/circle.svg?react";
import {ColorUtils} from "../../../../utils/ColorUtils";
import {colorPalette} from "../../styles/colorPalette";

export const renderIconComponent = (type: string) => {
	switch (type) {
		case "flag":
			return <Flag />;
		case "pill":
			return <Circle />;
		case "colorBar":
		case "colorBarFill":
			return <div className="colorline"></div>;
		default:
			return null;
	}
};

export const SingleSelectInputV5 = (props: IInputPropsV5<ISingleSelectFieldSettingsDefinition>) => {
	const {value, onChange, disabled, inline, focused} = props;
	const list = [...props.dataTypeSettings.choiceList];
	const type = props.dataTypeSettings?.type;

	const selectedOption = list.find((item) => item.value === value);
	const selectedIsRemoved = value && !selectedOption;

	if (selectedIsRemoved) {
		list.push({value: value, color: ""});
	}

	let focus = focused;

	const onFocus = (value: boolean) => {
		if (!value) {
			focus = false;
		}
	};

	const onClick = () => {
		props.onClick?.();
	};

	const renderPill = (option: {color: string; value: string}) => {
		return (
			<PillStyled
				$color={option.color ? `#${option.color}` : ""}
				$type={type}
			>
				{renderIconComponent(type)}
				{option.value}
			</PillStyled>
		);
	};

	const renderSelectedOption = (selected: string | null) => {
		const optionsClone = list.slice();

		return (
			<>
				{optionsClone.map((option) => {
					if (typeof option === "object" && option !== null) {
						const result = renderColorValue(option, selected);

						if (result && result.value === selected) {
							return (
								<PillStyled
									key={`${option.value}_${option.color}_${option.id}`}
									$color={result.color ? `#${result.color}` : ""}
									$type={type}
									$inline={inline}
									$selected={true}
								>
									{renderIconComponent(type)}
									{selected}
								</PillStyled>
							);
						}
					}
					return null;
				})}
				{!selected && (
					<PillStyled
						className="nullLabel"
						$type={type}
						$inline={inline}
					>
						{"-"}
					</PillStyled>
				)}
			</>
		);
	};

	const renderColorValue = (option: {color: string; value: string}, selected: string) => {
		if (option.value === selected) {
			return {color: option.color, value: option.value};
		}
	};

	const fetchValueFromOption = (option: {color: string; value: string} | null) => {
		if (option === null) {
			onChange?.(null);
		} else {
			onChange?.(option.value);
		}
	};

	const renderOptionValue = (option: {color: string; value: string}) => {
		return option.value;
	};

	return (
		<SelectInputV5
			options={list}
			selected={value}
			type={type}
			disabled={disabled}
			nullOption={true}
			fullWidth={true}
			sort={false}
			focused={focus}
			onClick={onClick}
			onFocus={onFocus}
			horizontalAlignment={inline ? HorizontalAlignment.center : undefined}
			isSameWidth={false}
			inline={inline}
			dropdownIcon={false}
			isSingleSelect={true}
			render={renderPill}
			onChange={fetchValueFromOption}
			dropdownMaxHeight={"230px"}
			renderOptionValue={renderOptionValue}
			renderSelectedWhenClosed={renderSelectedOption}
			nullLabel="-"
			closeIcon={true}
			stringifyOption={renderOptionValue}
			selectedIsRemoved={selectedIsRemoved}
			dropdownFixedWidth="272px"
		/>
	);
};

export const PillStyled = styled.div<{$color?: string; $type?: string; $selected?: boolean; $inline?: boolean}>`
	${FlexCenterStyle};
	cursor: pointer;
	font-size: ${fontSize.md};
	gap: ${baseDistance.xs};
	display: block;
	${ELLIPSIS}

	${(props) => {
		const hslColor = ColorUtils.hex2hsl(props.$color);

		const nullLabelDefaultStyle = css`
			&.nullLabel {
				display: flex;
				padding: ${baseDistance.xs};
				background-color: transparent;
				color: black;
				height: 24px;
				width: 100px;
				text-align: left;
			}
		`;

		switch (props.$type) {
			case "textOnly":
				return css`
					color: ${props.$color === colorPalette.white ? "black" : props.$color};
					margin-left: ${baseDistance.xs};
					margin-right: ${baseDistance.xs};
					line-height: 16px;
					${nullLabelDefaultStyle}
				`;
			case "pillColored":
				const pillColoredCommonStyles = css`
					background-color: ${props.$color ? props.$color : colorPalette.white};
					border-radius: ${radius.xl};
					padding: ${baseDistance.xs};
					height: 24px;
					min-width: 32px;
					max-width: ${props.$selected && !props.$inline ? "calc(100% - 25px)" : "98%"};
					margin-left: ${!props.$selected ? baseDistance.xs : ""};
					width: fit-content;
					height: ${props.$selected ? "24px" : ""};
					text-align: center;
					line-height: 16px;
					${nullLabelDefaultStyle}
				`;

				if (hslColor.l >= 0.83 || props.$color === null || props.$color === "") {
					return css`
						color: black;
						${pillColoredCommonStyles}
					`;
				} else {
					return css`
						color: ${colorPalette.white};
						${pillColoredCommonStyles}
					`;
				}
			case "pill":
				return css`
					border-radius: ${radius.xl};
					padding: ${baseDistance.xs};
					height: 24px;
					margin-left: ${!props.$selected ? baseDistance.xs : ""};
					min-width: 32px;
					max-width: ${props.$selected && !props.$inline ? "calc(100% - 25px)" : "98%"};
					width: fit-content;
					margin-right: ${baseDistance.xs};
					background-color: ${colorPalette.gray.c100};
					color: ${props.$selected ? "black" : ""};
					${nullLabelDefaultStyle}

					svg {
						fill: ${props.$color ? props.$color : colorPalette.white};
						width: 8px;
						height: 8px;
						margin-right: ${baseDistance.xs};

						path {
							stroke: ${props.$color ? props.$color : colorPalette.white};
						}
					}
				`;
			case "flag":
				return css`
					margin-left: ${baseDistance.xs};
					margin-right: ${baseDistance.xs};
					gap: ${baseDistance.xs};
					max-width: ${props.$selected && !props.$inline ? "calc(100% - 25px)" : "98%"};
					height: 16px;
					line-height: 16px;
					vertical-align: middle;
					${nullLabelDefaultStyle}

					svg {
						fill: ${props.$color ? props.$color : colorPalette.white};
						width: 16px;
						height: 16px;
						margin-right: ${baseDistance.xs};
						vertical-align: middle;

						path {
							stroke: ${props.$color ? props.$color : colorPalette.white};
						}
					}
				`;
			case "colorBar":
				return css`
					width: 100%;
					max-width: ${props.$selected && !props.$inline ? "calc(100% - 25px)" : "98%"};
					height: ${props.$selected ? "24px" : "40px"};
					border-radius: ${radius.xs};
					color: black;
					position: relative;
					padding-left: ${baseDistance.sm};
					line-height: ${props.$selected ? "24px" : "40px"};
					${nullLabelDefaultStyle}

					.colorline {
						position: absolute;
						background-color: ${props.$color ? props.$color : colorPalette.white};
						border-radius: ${radius.sm} 0px 0px ${radius.sm};
						height: ${props.$selected ? "24px" : "40px"};
						width: 4px;
						left: 0;
						top: 0;
					}
				`;
			case "colorBarFill":
				const colorAfterOpacity: string = ColorUtils.hex2rgb(props.$color, 0.3) as string;
				const rgbaToHex = ColorUtils.rgba2hex(colorAfterOpacity);
				const minimizedHslColor = ColorUtils.hex2hsl(rgbaToHex);

				const colorBarFilledCommonStyles = css`
					width: ${props.$selected && !props.$inline ? "fit-content" : "100%"};
					padding-right: ${props.$selected && !props.$inline ? baseDistance.sm : ""};
					height: ${props.$selected ? "24px" : "40px"};
					border-radius: ${radius.xs};
					background-color: ${colorAfterOpacity};
					position: relative;
					padding-left: ${baseDistance.sm};
					line-height: ${props.$selected ? "24px" : "40px"};
					max-width: ${props.$selected && !props.$inline ? "calc(100% - 25px)" : "98%"};
					${nullLabelDefaultStyle}

					.colorline {
						position: absolute;
						background-color: ${props.$color ? props.$color : colorPalette.white};
						border-radius: ${radius.sm} 0px 0px ${radius.sm};
						height: ${props.$selected ? "24px" : "40px"};
						width: 4px;
						left: 0;
						top: 0;
					}
				`;

				if (minimizedHslColor.l >= 0.83 || props.$color === null || props.$color === "") {
					return css`
						color: black;
						${colorBarFilledCommonStyles}
					`;
				} else {
					return css`
						color: ${colorPalette.white};
						${colorBarFilledCommonStyles}
					`;
				}
			default:
				const defaultcommonStyles = css`
					padding: ${props.$selected && !props.$inline ? "4px 8px" : baseDistance.sm};
					margin-left: ${baseDistance.xs};
					margin-right: ${baseDistance.xs};
					width: ${props.$selected && !props.$inline ? "fit-content" : "100%"};
					max-width: ${props.$selected && !props.$inline ? "calc(100% - 25px)" : "98%"};
					height: ${props.$selected && !props.$inline ? "24px" : "32px"};
					border-radius: ${radius.sm};
					background-color: ${props.$color ? props.$color : colorPalette.white};
					align-items: left;

					${props.$inline
						? css`
								&.nullLabel {
									background-color: ${colorPalette.white};
									color: black;
									border-radius: ${radius.sm};
									border: 1px solid #c8c8c8;
									height: 32px;
									${FLEXCENTER};
									width: 100%;
								}
							`
						: nullLabelDefaultStyle}
				`;

				if (hslColor.l >= 0.83 || props.$color === null || props.$color === "") {
					return css`
						color: black;
						${defaultcommonStyles}
					`;
				} else {
					return css`
						color: ${colorPalette.white};
						${defaultcommonStyles}
					`;
				}
		}
	}}
`;
