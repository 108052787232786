import styled from "styled-components";
import type {CSSProperties, ReactNode} from "react";
import {useEffect, useRef, useState} from "react";
import {colorPalette} from "../styles/colorPalette";
import {PointerDetectorReact} from "../../interaction/PointerDetectorReact";
import type {Pointer} from "../../../utils/interaction/Pointer";
import {MathUtils} from "../../../utils/math/MathUtils";

interface IPanelProps {
	readonly children: ReactNode;
	readonly isOpen?: boolean;
	readonly style?: CSSProperties;
}

export const Panel = (props: IPanelProps) => {
	const {children, isOpen} = props;
	const key = "v5-spaceeditor-resizeable-panel-width";
	const minWidth = 432;
	const maxWidth = window.innerWidth / 2 < 800 ? window.innerWidth / 2 : 800;
	const savedWidth = parseInt(localStorage.getItem(key)) || minWidth;
	const divRef = useRef<HTMLDivElement>();
	const timeoutId = useRef<number>();
	const [width, setWidth] = useState<number>(savedWidth);

	const onColResizeStart = () => {
		localStorage.setItem(key, width.toString());
	};

	const onColResizeMove = (pointer: Pointer) => {
		setWidth(MathUtils.clamp(width - pointer.localX, minWidth, maxWidth));
	};

	const onColResizeEnd = () => {
		localStorage.setItem(key, width.toString());
	};

	const style: CSSProperties = {
		transform: `translateX(${props.isOpen ? 0 : "100%"})`,
		width: `${width}px`,
		...props.style,
	};

	useEffect(() => {
		if (isOpen) {
			clearTimeout(timeoutId.current);
			timeoutId.current = window.setTimeout(() => {
				if (isOpen && divRef.current) {
					// workaround for drag n drop positioning issue. The dnd library doesn't work well if the parentelement has a css transform
					divRef.current.style.transform = "";
				}
			}, 200);
		}
	}, [isOpen]);

	return (
		<PanelStyled
			ref={divRef}
			style={style}
		>
			<PointerDetectorReact
				onDown={onColResizeStart}
				onMove={onColResizeMove}
				onUp={onColResizeEnd}
			>
				<ResizerStyled />
			</PointerDetectorReact>
			<OverflowAble>{children}</OverflowAble>
		</PanelStyled>
	);
};

const OverflowAble = styled.div`
	height: 100%;
	overflow-y: auto;
`;

const ResizerStyled = styled.div`
	width: 10px;
	position: absolute;
	height: 100%;
	left: 0;
	cursor: col-resize;
	border-left-color: ${colorPalette.gray.c300};
	border-left-width: 1px;
	border-left-style: solid;
`;

export const PanelStyled = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	z-index: 10;
	background-color: ${colorPalette.white};
	transition: ease-in-out transform 0.2s;
	height: calc(100vh - 66px);
	box-shadow: -4px 0px 12px 0px rgba(0, 0, 0, 0.2);
`;
