import {Observer} from "mobx-react";
import SideBarIcon from "../icons/sidebar.svg?react";
import ChevronDownIcon from "../icons/chevron-down.svg?react";
import {useAppStore} from "../../../StateManager";
import {HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import type {Portfolio} from "../../../data/models/Portfolio";
import {XyiconFeature} from "../../../generated/api/base";
import {DropdownButtonV5} from "../interaction/DropdownButtonV5";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {zIndex} from "../styles/styles";
import {IconButtonV5} from "../interaction/IconButtonV5";
import {LineStyled, ProjectNameIconsStyled, ProjectNameStyled, ProjectNameDropDownStyled} from "./ProjectName.styled";

export const ProjectNameV5 = () => {
	const appState = useAppStore((state) => state.appState);
	const navBarState = useAppStore((state) => state.navBarState);
	const {localStorage: ls} = appState.app.transport.services;
	const setNavBarState = useAppStore((state) => state.setNavBarState);
	const dockNavBar = () => {
		setNavBarState("docked");

		ls.set(ls.getV5NavigationPanelDockedStateKey(appState.user.id), "docked");
	};

	const portfolios = appState.actions
		.getList<Portfolio>(XyiconFeature.Portfolio)
		.toSorted((a: Portfolio, b: Portfolio) => StringUtils.sortIgnoreCase(a.name, b.name));

	const onSwitchPortfolio = async (portfolio: Portfolio) => {
		await appState.app.transport.services.auth.switchPortfolio(portfolio.id);
	};

	return (
		<Observer>
			{() => {
				const selectedPortfolio = portfolios.find((p) => p.id === appState.portfolioId);
				const isOrgWide = ["portfolios", "catalog", "reports", "settings", "home"].includes(appState.selectedMenu);
				const isInSettingsView = ["settings"].includes(appState.selectedMenu);

				return (
					<ProjectNameStyled
						className="dockIcon"
						$isOrgWide={isOrgWide}
						$isInSettingsView={isInSettingsView}
					>
						{navBarState != "hidden" && (
							<IconButtonV5
								IconComponent={SideBarIcon}
								onClick={dockNavBar}
							/>
						)}
						{isOrgWide ? (
							<div className="projectTopBar">
								<span className="projectNameTopBar">{isOrgWide ? appState.organization.name : selectedPortfolio.name}</span>
							</div>
						) : (
							<>
								<DropdownButtonV5
									button={
										<ProjectNameDropDownStyled className="PortfolioDropdownV5">
											<div className="projectTopBar">
												<span
													className="projectNameTopBar"
													title={selectedPortfolio?.name}
												>
													{selectedPortfolio?.name}
												</span>
												<ProjectNameIconsStyled className="arrowIcon">
													<ChevronDownIcon />
												</ProjectNameIconsStyled>
											</div>
										</ProjectNameDropDownStyled>
									}
									options={portfolios.map((p) => ({
										label: p.name,
										onClick: () => onSwitchPortfolio(p),
										isActive: appState.portfolioId === p.id,
									}))}
									verticalAlignment={VerticalAlignment.topOuter}
									horizontalAlignment={HorizontalAlignment.left}
									width="272px"
									showSearch={true}
									optionsZIndex={zIndex.contextOptions}
								/>
								<LineStyled />
							</>
						)}
					</ProjectNameStyled>
				);
			}}
		</Observer>
	);
};
