import {css, styled} from "styled-components";
import {baseDistance, FontFamily, fontSize, fontWeight, radius} from "./styles/styles";
import {colorPalette} from "./styles/colorPalette";

const defaultStyles = css`
	${FontFamily}
	display: flex;
	background-color: ${colorPalette.white};
	height: 100vh;
	color: ${colorPalette.gray.c950};

	font-family: Inter, sans-serif;

	input,
	select,
	textarea {
		font-size: inherit;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6,
	li {
		color: inherit;
		font-weight: ${fontWeight.bold};
		margin: 0;
		padding: 0;
	}

	a,
	li,
	ul,
	ui-button,
	span.k-select {
		cursor: pointer;
	}

	button {
		background-color: transparent;
		padding: 0;
	}

	input {
		max-width: 100%;
		line-height: 16px;
		font-size: ${fontSize.md};
	}

	.button,
	button,
	.Button {
		cursor: pointer;
	}

	ul {
		list-style: none;

		li {
			font-weight: ${fontWeight.normal};
			font-size: ${fontSize.lg};
		}
	}

	svg.icon {
		width: 24px;
		height: 24px;
	}
`;

export const RootStyled = styled.div<{$isFrozen: boolean}>`
	display: flex;
	background-color: ${colorPalette.white};
	height: 100vh;

	${(props) =>
		props.$isFrozen &&
		css`
			&::before {
				content: "";
				position: fixed;
				left: 0;
				top: 0;
				width: 100vw;
				height: 100vh;
				background-color: #00000045;
				z-index: 4001;
			}
		`};

	${defaultStyles};
`;

export const ModalStyled = styled.div`
	position: absolute;
	${defaultStyles};
`;

export const pseudoLabelTooltipStyle = css<{$label: string}>`
	${(props) => {
		if (props.$label) {
			// Display label as a tooltip on hover
			return css`
				&:hover:after {
					content: "${props.$label}";
					position: absolute;
					background-color: ${colorPalette.blueGray.c200Light};
					left: 41px;
					border-radius: ${radius.sm};
					height: 32px;
					padding: 0 ${baseDistance.sm};
					width: max-content;
					font-size: ${fontSize.md};
					display: flex;
					align-items: center;
				}
			`;
		}
	}};
`;
