import * as React from "react";
import styled from "styled-components";
import {DateFormatter} from "../../../../utils/format/DateFormatter";
import {ReactUtils} from "../../../utils/ReactUtils";
import {DateUtils} from "../../../../utils/DateUtils";
import {SelectInputStyled, SelectInputV5} from "../select/SelectInputV5";
import {baseDistance, FLEXCENTER, fontWeight, radius} from "../../styles/styles";
import {colorPalette} from "../../styles/colorPalette";
import ChevronLeft from "../../icons/chevron-left.svg?react";
import ChevronRight from "../../icons/chevron-right.svg?react";
import {IconButtonV5} from "../../interaction/IconButtonV5";
import {MathUtils} from "../../../../utils/math/MathUtils";

interface IDatePickerV5Props {
	readonly value: string; // date
	readonly onChange: (value: string) => void;
}

export const DatePickerV5: React.FC<IDatePickerV5Props> = ({value, onChange}: IDatePickerV5Props) => {
	const _dateFormatter = new DateFormatter();

	const getSelectedDate = () => {
		let result = new Date(value);

		if (isNaN(result.getTime())) {
			result = new Date();
		}
		return result;
	};

	const date = getSelectedDate();
	const currentYear = date.getFullYear();
	const currentMonth = date.getMonth();

	const onPreviousMonthClick = () => {
		let newMonth = currentMonth;
		let newYear = currentYear;

		if (currentMonth > 0) {
			newMonth = newMonth - 1;
		} else {
			newYear = newYear - 1;
			newMonth = 11;
		}

		let date = DateUtils.parse(value);

		date.setDate(MathUtils.clamp(date.getDate(), 1, DateUtils.lastDay(newYear, newMonth)));
		date.setMonth(newMonth);
		date.setFullYear(newYear);

		onChange(DateUtils.stringify(date));
	};

	const onNextMonthClick = () => {
		let newMonth = currentMonth;
		let newYear = currentYear;

		if (currentMonth < 11) {
			newMonth = newMonth + 1;
		} else {
			newYear = newYear + 1;
			newMonth = 0;
		}

		let date = DateUtils.parse(value);

		date.setDate(MathUtils.clamp(date.getDate(), 1, DateUtils.lastDay(newYear, newMonth)));
		date.setMonth(newMonth);
		date.setFullYear(newYear);

		onChange(DateUtils.stringify(date));
	};

	const changeMonth = (month: string) => {
		const newMonth = _dateFormatter.monthNames.findIndex((m) => m === month);

		let date = DateUtils.parse(value);

		date.setDate(MathUtils.clamp(date.getDate(), 1, DateUtils.lastDay(currentYear, newMonth)));
		date.setMonth(newMonth);

		onChange(DateUtils.stringify(date));
	};

	const onClickDay = (day: number) => {
		let date = DateUtils.parse(value);

		date.setMonth(currentMonth);
		date.setFullYear(currentYear);
		date.setDate(day + 1);

		onChange(DateUtils.stringify(date));
	};

	const getYears = (yearNow: number) => {
		let yearsArray: number[] = [];

		for (let i = 0; i < 50; i++) {
			yearsArray.push(yearNow - 50 + i);
		}

		for (let i = 0; i < 50; i++) {
			yearsArray.push(yearNow + i);
		}

		return yearsArray;
	};

	const changeYear = (newYear: number) => {
		let date = DateUtils.parse(value);

		date.setDate(MathUtils.clamp(date.getDate(), 1, DateUtils.lastDay(newYear, currentMonth)));
		date.setFullYear(newYear);

		onChange(DateUtils.stringify(date));
	};

	{
		const daysInMonth = DateFormatter.daysInMonth(currentYear, currentMonth);
		const navDate = new Date(currentYear, currentMonth, 1);
		const firstDay = navDate.getDay();
		const selected = DateUtils.parse(value);
		const years = getYears(currentYear) as number[];

		return (
			<DatePickerStyled>
				<DatePickerHeaderStyled>
					<IconButtonV5
						IconComponent={ChevronLeft}
						onClick={onPreviousMonthClick}
						className="arrowIcon"
					/>
					<MonthAndYearStyled>
						<SelectInputV5
							options={_dateFormatter.monthNames}
							selected={_dateFormatter.monthNames[currentMonth]}
							onChange={(option) => changeMonth(option)}
							searchBarMode="always off"
							sort={false}
							fullWidth={true}
						/>
						<SelectInputV5
							options={years}
							selected={currentYear}
							onChange={(option) => changeYear(option)}
							searchBarMode="always off"
							fullWidth={true}
						/>
					</MonthAndYearStyled>
					<IconButtonV5
						IconComponent={ChevronRight}
						onClick={onNextMonthClick}
						className="arrowIcon"
					/>
				</DatePickerHeaderStyled>
				<WeekDaysStyled>
					{_dateFormatter.dayNames.map((day, index) => (
						<WeekDayStyled key={index}>
							{day.charAt(0).toUpperCase()}
							{day.charAt(1)}
						</WeekDayStyled>
					))}
				</WeekDaysStyled>
				<DaysStyled $isSpecialFebruary={daysInMonth === 28 && firstDay === 0}>
					{
						// Empty days before the first day
						ReactUtils.loop(firstDay, (index: number) => (
							<span key={index} />
						))
					}
					{ReactUtils.loop(daysInMonth, (date) => {
						const currentDate = new Date(currentYear, currentMonth, date + 1);
						const today = new Date();

						return (
							<span
								key={date}
								onClick={(e) => onClickDay(date)}
								className={ReactUtils.cls("day", {
									selected: DateUtils.sameDay(currentDate, selected),
									today: DateUtils.sameDay(currentDate, today),
								})}
							>
								{date + 1}
							</span>
						);
					})}
				</DaysStyled>
			</DatePickerStyled>
		);
	}
};

const DatePickerStyled = styled.div`
	border: 1px solid ${colorPalette.gray.c300};
	border-radius: ${radius.md};
	padding: ${baseDistance.sm};
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.md};
	height: 338px;
`;

const DatePickerHeaderStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	span {
		align-items: center;
		border-radius: ${radius.sm};
		cursor: pointer;

		&:hover {
			background-color: ${colorPalette.gray.c200Light};
		}

		&:active {
			background-color: ${colorPalette.primary.c200Light};
		}

		svg {
			height: 8px;
			width: 8px;
			cursor: pointer;
			stroke-width: 0;
		}
	}

	${SelectInputStyled} {
		&.open {
			background-color: ${colorPalette.primary.c200Light};
			color: ${colorPalette.primary.c500Primary};

			.input {
				font-weight: ${fontWeight.bold};
			}
		}
	}

	.arrowIcon {
		height: 24px;
		width: 24px;
	}
`;

const MonthAndYearStyled = styled.div`
	display: flex;
	gap: ${baseDistance.sm};

	${SelectInputStyled} {
		color: ${colorPalette.gray.c950};
		.input {
			font-weight: ${fontWeight.bold};
		}
	}
`;

const WeekDaysStyled = styled.div`
	font-weight: ${fontWeight.thin};
	gap: ${baseDistance.lg};
	${FLEXCENTER};
`;

const WeekDayStyled = styled.div`
	width: 24px;
	height: 24px;
`;

const DaysStyled = styled.div<{$isSpecialFebruary: boolean}>`
	display: grid;
	grid-template-columns: repeat(7, 24px);
	grid-template-rows: ${(props) => (props.$isSpecialFebruary ? "repeat(4, 24px)" : "repeat(5, 24px)")};
	column-gap: ${baseDistance.lg};
	row-gap: ${baseDistance.md};
	justify-content: center;

	span {
		align-self: center;
		justify-self: center;
		border-radius: 50%;
		color: ${colorPalette.gray.c700Dark};
		width: 32px;
		height: 32px;
		${FLEXCENTER};

		&.day {
			cursor: pointer;

			&:hover {
				background-color: ${colorPalette.gray.c100};
			}

			&.today {
				border: 1px solid ${colorPalette.primary.c500Primary};
			}

			&.selected {
				background-color: ${colorPalette.primary.c500Primary};
				color: ${colorPalette.white};
			}
		}
	}
`;
