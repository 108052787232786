import * as React from "react";
import {inject, observer} from "mobx-react";
import {getTooltipByIcon} from "../FieldInputUtils";
import type {App} from "../../../../../../../../App";
import type {AppState} from "../../../../../../../../data/state/AppState";
import {Field} from "../../../../../../../widgets/form/field/Field";
import {SelectInput} from "../../../../../../../widgets/input/select/SelectInput";
import {ReactUtils} from "../../../../../../../utils/ReactUtils";
import {IconButton} from "../../../../../../../widgets/button/IconButton";
import {SingleLineInput} from "../../../../../../../widgets/input/clicktoedit/datatypes/singleline/SingleLineInput";
import {FieldInputs, FieldMassEditInputs} from "../../../../../../../widgets/input/clicktoedit/InputUtils";
import {ConfirmWindow} from "../../../../../popups/ConfirmWindow";
import {StringUtils} from "../../../../../../../../utils/data/string/StringUtils";
import {TimeUtils} from "../../../../../../../../utils/TimeUtils";
import type {TransformObj} from "../../../../../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../../../../utils/dom/DomUtils";
import {FocusLoss} from "../../../../../../../../utils/ui/focus/FocusLoss";
import {DomPortal} from "../../../../../portal/DomPortal";
import {AppUtils} from "../../../../../../../../utils/AppUtils";
import {FieldDataType, Permission} from "../../../../../../../../generated/api/base";
import {FieldDataTypes} from "../../../../../../../../data/models/field/FieldDataTypes";
import type {IModel} from "../../../../../../../../data/models/Model";
import {KeyboardListener} from "../../../../../../../../utils/interaction/key/KeyboardListener";
import {massUpdatePressButton, getItemsToCheck, getMassUpdateOptions} from "./IMassInput";
import type {IMassInputProps, IOption} from "./IMassInput";

interface IMassFieldInputProps extends IMassInputProps {
	readonly noFixedPosition?: boolean;
	readonly app?: App;
	readonly appState?: AppState;
}

interface IMassFieldInputState {
	selectedOptionId: string;
	selectedOptionValue: any;
	inputValue: string | boolean | number;
	transform: TransformObj;
	open: boolean;
}

@inject("app")
@inject("appState")
@observer
export class MassFieldInput extends React.Component<IMassFieldInputProps, IMassFieldInputState> {
	private _element = React.createRef<HTMLDivElement>();
	private _floating = React.createRef<HTMLDivElement>();

	constructor(props: IMassFieldInputProps) {
		super(props);
		this.state = {
			selectedOptionId: "all",
			selectedOptionValue: undefined,
			inputValue: this.initialInputValueForFieldType,
			transform: null,
			open: this.props.open,
		};
	}

	static getDerivedStateFromProps(props: IMassFieldInputProps, state: IMassFieldInputState) {
		if (props.open !== state.open) {
			return {open: props.open};
		}

		return null;
	}

	private onOpenClick = async () => {
		const {onOpen, field} = this.props;

		onOpen(field.refId);
		await TimeUtils.wait(100);
		this.addListeners();
	};

	private onApply = async (items: IModel[]) => {
		const {field, appState, numberOfSelectedItems, onOpen, app} = this.props;
		const {selectedOptionId, selectedOptionValue, inputValue} = this.state;

		KeyboardListener.getInstance().signals.up.remove(this.pressButton);

		const itemsToUpdate = items.filter((item) => {
			const hasValue = appState.actions.hasOwnFieldValue(item, field.refId);

			if (!hasValue) {
				return false;
			}

			const hasPermission = appState.actions.getFieldPermission(field, [item]) > Permission.View;

			if (!hasPermission) {
				return false;
			}

			const value_ = appState.actions.getOwnFieldValue(item, field.refId);
			const value = field.dataType === FieldDataType.Boolean ? value_ : appState.actions.formatValue(value_, field.refId);
			const changeChecker = FieldDataTypes.map[field.dataType].changeChecker;

			if (selectedOptionId === "all") {
				if (changeChecker) {
					return changeChecker(value_, inputValue, selectedOptionValue, field.refId, appState);
				}
				return value !== inputValue;
			} else if (selectedOptionId === "blank") {
				return StringUtils.isBlank(value);
			} else {
				if (changeChecker) {
					return changeChecker(value_, inputValue, selectedOptionValue, field.refId, appState);
				}
				const currentValue = selectedOptionValue;

				return currentValue === value && value !== inputValue;
			}
		});

		const confirmed = await ConfirmWindow.open(
			`${itemsToUpdate.length} of ${numberOfSelectedItems ?? items.length} items will be updated. Continue?`,
			"Confirm Update",
		);

		if (confirmed) {
			onOpen("");
			this.removeListeners();
			if (itemsToUpdate.length > 0) {
				await TimeUtils.waitUpdate(
					appState.actions.updateMassFields(itemsToUpdate, {
						[field.refId]: inputValue,
					}),
					app.notificationContainer,
				);
			}
			this.setState({inputValue: this.initialInputValueForFieldType, selectedOptionId: "all", selectedOptionValue: undefined});
		} else {
			KeyboardListener.getInstance().signals.up.add(this.pressButton);
		}
	};

	private onBlur = (event?: MouseEvent) => {
		if (!(event?.target instanceof Element) || !this.props.app.modalContainer.contains(event?.target)) {
			this.onCancel();
		}
	};

	private onCancel = () => {
		this.removeListeners();
		this.props.onOpen("");
	};

	private onOptionChange = (option: IOption) => {
		this.setState({
			selectedOptionId: option.id,
			selectedOptionValue: option.value,
		});
	};

	private onInputChange = (value: string | boolean) => {
		this.setState({inputValue: value === null ? this.initialInputValueForFieldType : value});
	};

	private removeListeners = () => {
		AppUtils.disableScrolling(false);
		FocusLoss.stopListen(this._floating.current, this.onBlur);
		KeyboardListener.getInstance().signals.up.remove(this.pressButton);
	};

	private addListeners = () => {
		this.removeListeners();
		AppUtils.disableScrolling(true);
		FocusLoss.listen(this._floating.current, this.onBlur);
		KeyboardListener.getInstance().signals.up.add(this.pressButton);
	};

	private pressButton = (event: KeyboardEvent) => {
		massUpdatePressButton(event, true, this.onApply, this.onCancel, this.props);
	};

	private getEditInput() {
		const {field} = this.props;
		const {inputValue} = this.state;
		const isAnyBeingUpdated = this.isAnyBeingUpdated;

		let InputComponent = FieldMassEditInputs[field.dataType] || FieldInputs[field.dataType];

		InputComponent = InputComponent || SingleLineInput;

		return (
			<InputComponent
				field={field}
				onInput={this.onInputChange}
				value={inputValue}
				dataTypeSettings={field.dataTypeSettings}
				onChange={this.onInputChange}
				updating={isAnyBeingUpdated}
				onFocusLossForceBlur={true}
			/>
		);
	}

	private get initialInputValueForFieldType() {
		const {field} = this.props;

		if ([FieldDataType.Numeric, FieldDataType.DateTime].includes(field.dataType)) {
			return undefined;
		} else if (field.dataType === FieldDataType.Boolean) {
			return false;
		} else {
			return "";
		}
	}

	private get isAnyBeingUpdated(): boolean {
		const {appState, items, field} = this.props;
		const {itemFieldUpdateManager} = appState;

		return items.some((item: IModel) => itemFieldUpdateManager.isItemFieldBeingUpdated(item.id, field.refId, item.ownFeature));
	}

	public override componentDidUpdate(prevProps: IMassFieldInputProps, prevState: IMassFieldInputState) {
		if (!this.props.noFixedPosition && !prevState.open && this.state.open && this._element.current && this._floating.current) {
			this.setState({
				transform: DomUtils.getFixedFloatingElementPosition(
					this._element.current,
					this._floating.current,
					VerticalAlignment.bottom,
					HorizontalAlignment.left,
					10,
				),
			});
		}
	}

	public override componentWillUnmount() {
		this.removeListeners();
	}

	public override render() {
		const {open, items, field, appState, noFixedPosition, icon} = this.props;
		const {selectedOptionId, transform} = this.state;
		const element = this._element;
		const itemsToCheck = getItemsToCheck(items, field, appState);
		const {options, allValuesMatch, firstValue} = getMassUpdateOptions(itemsToCheck, field, appState, true);

		let inlineStyle: React.CSSProperties = element && {
			transform: transform?.translate,
			position: noFixedPosition ? "absolute" : "fixed",
		};

		if (noFixedPosition && inlineStyle) {
			inlineStyle.transform = "";
		}

		const disabled = this.props.disabled || field.hasValidation;

		return (
			<div className={ReactUtils.cls("MassFieldInput", {open})}>
				<Field
					key={field.refId}
					label={field.name}
					disabled={disabled}
					noWrap={true} //field.noWrap}
					icons={{preLabelIcon: icon || ""}}
					tooltips={{
						labelTooltip: field.helperText,
						preLabelIconTooltip: getTooltipByIcon(icon),
					}}
				>
					<div
						className={ReactUtils.cls("unfocused", {disabled})}
						onClick={this.onOpenClick}
						ref={this._element}
					>
						{allValuesMatch ? firstValue : <i>Multiple values</i>}
						{this.isAnyBeingUpdated && <span className={ReactUtils.cls("spinner", {hasValidation: this.props.field.hasValidation})} />}
					</div>
					{open && (
						<DomPortal
							destination={this.props.app.modalContainer}
							noPortal={noFixedPosition}
						>
							<div
								className={ReactUtils.cls("MassFieldInput__editArea", {[VerticalAlignment[transform?.vertical]]: transform})}
								style={inlineStyle}
								ref={this._floating}
							>
								<div className="header hbox alignCenter">
									<h4 className="flex_1">
										Editing multiple Values for <span>{`${this.props.field.name}`}</span>
									</h4>
									<IconButton
										icon="close"
										onClick={this.onCancel}
									/>
								</div>
								<div className="container">
									<Field label="Value(s) to Update">
										<SelectInput
											options={options}
											render={(option) => option.label}
											selected={options.find((option) => option.id === selectedOptionId)}
											onChange={this.onOptionChange}
											onFocusLossForceBlur={true}
										/>
									</Field>
									<Field label="New value">{this.getEditInput()}</Field>
									<div className="editButtons">
										<IconButton
											className="apply"
											icon="apply"
											title="Apply"
											onClick={() => this.onApply(itemsToCheck)}
										/>
										<IconButton
											className="cancel"
											icon="cancel"
											title="Cancel"
											onClick={this.onCancel}
										/>
									</div>
								</div>
							</div>
						</DomPortal>
					)}
				</Field>
			</div>
		);
	}
}
