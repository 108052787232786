import * as React from "react";
import {useState} from "react";
import type {IModel} from "../../../data/models/Model";
import type {PortTemplateDto} from "../../../generated/api/base";
import {Permission, XyiconFeature} from "../../../generated/api/base";
import {PortLayoutType} from "../../modules/abstract/sidepanel/tabs/details/PortLayoutType";
import {LocalStorageService} from "../../../data/services/LocalStorageService";
import {PORTS_LAYOUT} from "../../modules/catalog/port/Port";
import type {Xyicon} from "../../../data/models/Xyicon";
import {XHRLoader} from "../../../utils/loader/XHRLoader";
import type {Catalog} from "../../../data/models/Catalog";
import {PortsV5} from "../modules/spaceeditor/links/PortsV5";
import {ToggleContainerV5} from "../widgets/ToggleContainerV5/ToggleContainerV5";
import AddIcon from "../icons/circle-plus.svg?react";
import {useAppStore} from "../../../StateManager";
import type {IDropdownOption} from "../interaction/DropdownOptionsV5";
import SquareIcon from "../icons/square.svg?react";
import CardIcon from "../icons/card-layout.svg?react";
import {ButtonV5} from "../button/ButtonV5";
import {PortTemplateEditorV5} from "./PortTemplateEditorV5";

interface IOption {
	id: PortLayoutType;
	label: string;
	icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {title?: string}>;
}

interface IPortsSectionProps {
	readonly item: IModel;
	readonly feature: XyiconFeature;
	readonly setPortTemplateEditorOpen: (value: boolean) => void;
	readonly isPortTemplateEditorOpen: boolean;
	readonly saveStateToLocalStorage?: boolean;
}

export const PortsSectionV5 = (props: IPortsSectionProps) => {
	const appState = useAppStore((state) => state.appState);

	const _options: IDropdownOption[] = [
		{
			label: "Icon Layout",
			onClick: () => setLayout(PortLayoutType.Icon),
			IconComponent: SquareIcon,
		},
		{
			label: "Card Layout",
			onClick: () => setLayout(PortLayoutType.Card),
			IconComponent: CardIcon,
		},
	];

	const _localStorageManager = new LocalStorageService(appState.app.transport);
	const [layout, setLayout] = useState<PortLayoutType>(_localStorageManager.get(`${PORTS_LAYOUT}-${appState.user?.id}`) || PortLayoutType.Card);

	const onLabelChange = async (newValue: string, portId: string) => {
		if (props.feature === XyiconFeature.Xyicon) {
			const xyicon = props.item as Xyicon;

			xyicon.addPortData({
				id: portId,
				label: newValue,
			});

			await appState.app.transport.requestForOrganization({
				url: "xyicons/updateportdata",
				method: XHRLoader.METHOD_POST,
				params: {
					xyiconID: xyicon.id,
					portfolioID: appState.portfolioId,
					portData: [
						{id: portId, label: newValue}, // send only the one that has changed
					],
				},
			});
		}
	};

	const onEditPortTemplateClick = () => {
		props.setPortTemplateEditorOpen(true);
	};

	const closePortTemplateEditor = () => {
		props.setPortTemplateEditorOpen(false);
	};

	const onSavePortTemplate = async (portTemplate: PortTemplateDto[]) => {
		closePortTemplateEditor();

		await appState.app.transport.updatePortTemplate(props.item as Catalog, portTemplate);
	};

	const getCatalogPermission = () => {
		return appState.user?.getOrganizationPermission(XyiconFeature.XyiconCatalog);
	};

	function getPortElements(catalog: Catalog) {
		const portTemplate = catalog.portTemplate;

		if (props.isPortTemplateEditorOpen) {
			return (
				<PortTemplateEditorV5
					portTemplate={portTemplate}
					onBackClick={closePortTemplateEditor}
					onSaveClick={onSavePortTemplate}
				/>
			);
		} else {
			const isCatalog = props.feature === XyiconFeature.XyiconCatalog;

			return isCatalog && portTemplate.length === 0 ? (
				<ButtonV5
					IconComponent={AddIcon}
					label="Create"
					title="Add Port Template"
					onClick={onEditPortTemplateClick}
					style={{alignSelf: "baseline"}}
					disabled={getCatalogPermission() < Permission.Update}
				/>
			) : (
				<PortsV5
					item={props.item as Xyicon | Catalog}
					onLabelChange={props.feature === XyiconFeature.Xyicon ? onLabelChange : null}
					onEditPortTemplateClick={isCatalog ? onEditPortTemplateClick : null}
					layout={layout}
				/>
			);
		}
	}

	function renderPorts() {
		const {feature, item} = props;
		const catalog = feature === XyiconFeature.XyiconCatalog ? (item as Catalog) : (item as Xyicon).catalog;

		return getPortElements(catalog);
	}

	const onPortLayoutChange = (option: any) => {
		_localStorageManager.set(`${PORTS_LAYOUT}-${appState.user?.id}`, option.id);

		setLayout(option.id);
	};

	const {feature, item} = props;
	const shouldRenderPorts = feature === XyiconFeature.XyiconCatalog ? true : (item as Xyicon).catalog?.portTemplate.length > 0;

	return (
		[XyiconFeature.Xyicon, XyiconFeature.XyiconCatalog].includes(feature) &&
		shouldRenderPorts && (
			<ToggleContainerV5
				title={props.feature === XyiconFeature.Xyicon ? "Ports" : "Port Template"}
				saveStateToLocalStorage={props.saveStateToLocalStorage}
				dropdownOptions={props.feature === XyiconFeature.Xyicon ? _options : []}
			>
				{renderPorts()}
			</ToggleContainerV5>
		)
	);
};
