import * as React from "react";
import {inject, observer} from "mobx-react";
import type {App} from "../../../../../../../../../App";
import type {AppState} from "../../../../../../../../../data/state/AppState";
import {Field} from "../../../../../../../../widgets/form/field/Field";
import {SelectInput} from "../../../../../../../../widgets/input/select/SelectInput";
import {ReactUtils} from "../../../../../../../../utils/ReactUtils";
import {IconButton} from "../../../../../../../../widgets/button/IconButton";
import {SingleLineInput} from "../../../../../../../../widgets/input/clicktoedit/datatypes/singleline/SingleLineInput";
import {FieldInputs} from "../../../../../../../../widgets/input/clicktoedit/InputUtils";
import {ConfirmWindow} from "../../../../../../popups/ConfirmWindow";
import type {IMassInputProps, IOption} from "../IMassInput";
import {massUpdatePressButton, getItemsToCheck, getMassUpdateOptions} from "../IMassInput";
import {DomPortal} from "../../../../../../portal/DomPortal";
import {TimeUtils} from "../../../../../../../../../utils/TimeUtils";
import {FocusLoss} from "../../../../../../../../../utils/ui/focus/FocusLoss";
import {AppUtils} from "../../../../../../../../../utils/AppUtils";
import type {TransformObj} from "../../../../../../../../../utils/dom/DomUtils";
import {DomUtils, VerticalAlignment, HorizontalAlignment} from "../../../../../../../../../utils/dom/DomUtils";
import {ObjectUtils} from "../../../../../../../../../utils/data/ObjectUtils";
import type {IModel} from "../../../../../../../../../data/models/Model";
import {FieldInput} from "../../../../../../../../widgets/input/clicktoedit/FieldInput";
import {KeyboardListener} from "../../../../../../../../../utils/interaction/key/KeyboardListener";
import {Permission} from "../../../../../../../../../generated/api/base";

interface IMassFieldMultiInputProps extends IMassInputProps {
	readonly noFixedPosition?: boolean;
	readonly app?: App;
	readonly appState?: AppState;
}

interface IMassFieldMultiInputState {
	selectedOption: string;
	inputValue: string[];
	transform: TransformObj;
	open: boolean;
}

@inject("app")
@inject("transport")
@inject("appState")
@observer
export class MassFieldMultiInput extends React.Component<IMassFieldMultiInputProps, IMassFieldMultiInputState> {
	private readonly _options: IOption[];
	private _element = React.createRef<HTMLDivElement>();
	private _floating = React.createRef<HTMLDivElement>();

	constructor(props: IMassFieldMultiInputProps) {
		super(props);
		this.state = {
			selectedOption: "add",
			inputValue: [],
			transform: null,
			open: this.props.open,
		};

		this._options = [
			{
				id: "add",
				label: "Add to List",
			},
			{
				id: "remove",
				label: "Remove from List",
			},
			{
				id: "overwrite",
				label: "Overwrite List",
			},
		];
	}

	static getDerivedStateFromProps(props: IMassFieldMultiInputProps, state: IMassFieldMultiInputState) {
		if (props.open !== state.open) {
			return {open: props.open};
		}

		return null;
	}

	private onOpenClick = async () => {
		const {onOpen, field} = this.props;

		onOpen(field.refId);
		await TimeUtils.wait(100);
		this.addListeners();
	};

	private onApply = async (items: IModel[]) => {
		const {field, appState, onOpen} = this.props;
		const {selectedOption, inputValue} = this.state;

		KeyboardListener.getInstance().signals.up.remove(this.pressButton);

		const itemsToUpdate = items.filter((item) => {
			const hasPermission = appState.actions.getFieldPermission(field, [item]) > Permission.View;

			if (!hasPermission) {
				return false;
			}

			const values = appState.actions.getOwnFieldValue(item, field.refId) ?? ([] as unknown as string[]);

			if (selectedOption === "add") {
				return inputValue.some((value) => !values.includes(value));
			} else if (selectedOption === "remove") {
				return inputValue.some((value) => values.includes(value));
			} else if (selectedOption === "overwrite") {
				return !ObjectUtils.compare(inputValue, values);
			}
		});
		const confirmed = await ConfirmWindow.open(`${itemsToUpdate.length} of ${items.length} items will be updated. Continue?`, "Confirm Update");

		if (confirmed) {
			onOpen("");
			this.removeListeners();

			if (selectedOption === "add") {
				for (const item of itemsToUpdate) {
					const value = (appState.actions.getOwnFieldValue(item, field.refId) || []).slice() as string[];

					for (const v of inputValue) {
						if (!value.includes(v)) {
							value.push(v);
						}
					}

					appState.actions.updateFields([item], {
						[field.refId]: value,
					});
				}
			} else if (selectedOption === "remove") {
				for (const item of itemsToUpdate) {
					let value = (appState.actions.getOwnFieldValue(item, field.refId) || []).slice() as string[];

					for (const v of inputValue) {
						if (value.includes(v)) {
							value = value.filter((vv) => vv !== v);
						}
					}

					appState.actions.updateFields([item], {
						[field.refId]: value,
					});
				}
			} else if (selectedOption === "overwrite") {
				appState.actions.updateMassFields(itemsToUpdate, {
					[field.refId]: inputValue,
				});
			}

			this.setState({selectedOption: "add", inputValue: [], open: false});
		} else {
			KeyboardListener.getInstance().signals.up.add(this.pressButton);
		}
	};

	private onBlur = (event?: MouseEvent) => {
		if (!(event.target instanceof Element) || !this.props.app.modalContainer.contains(event.target)) {
			this.onCancel();
		} else {
			FocusLoss.listen(this._floating.current, this.onBlur);
		}
	};

	private onCancel = () => {
		this.removeListeners();
		this.props.onOpen("");
	};

	private onOptionChange = (option: IOption) => {
		this.setState({
			selectedOption: option.id,
		});
	};

	private onInputChange = (value: any) => {
		this.setState({
			inputValue: value,
		});
	};

	private removeListeners = () => {
		AppUtils.disableScrolling(false);
		FocusLoss.stopListen(this._floating.current, this.onBlur);
		KeyboardListener.getInstance().signals.up.remove(this.pressButton);
	};

	private addListeners = () => {
		FocusLoss.listen(this._floating.current, this.onBlur);
		KeyboardListener.getInstance().signals.up.add(this.pressButton);
		AppUtils.disableScrolling(true);
	};

	private pressButton = (event: KeyboardEvent) => {
		massUpdatePressButton(event, true, this.onApply, this.onCancel, this.props);
	};

	private getEditInput() {
		const {field} = this.props;
		const {inputValue} = this.state;

		let InputComponent = FieldInputs[field.dataType];

		InputComponent = InputComponent || SingleLineInput;

		return (
			<InputComponent
				value={inputValue}
				dataTypeSettings={field.dataTypeSettings}
				onChange={this.onInputChange}
				onInput={this.onInputChange}
				onFocusLossForceBlur={true}
			/>
		);
	}

	public override componentDidUpdate(prevProps: IMassFieldMultiInputProps, prevState: IMassFieldMultiInputState) {
		if (!this.props.noFixedPosition && !prevState.open && this.state.open && this._element.current && this._floating.current) {
			this.setState({
				transform: DomUtils.getFixedFloatingElementPosition(
					this._element.current,
					this._floating.current,
					VerticalAlignment.bottom,
					HorizontalAlignment.left,
					10,
				),
			});
		}
	}

	public override componentWillUnmount() {
		this.removeListeners();
	}

	public override render() {
		const {open, items, field, appState, noFixedPosition, app, disabled} = this.props;
		const {selectedOption, transform} = this.state;
		const {actions} = appState;
		const element = this._element;
		const itemsToCheck = getItemsToCheck(items, field, appState);
		const {allValuesMatch} = getMassUpdateOptions(itemsToCheck, field, appState);

		let inlineStyle: React.CSSProperties = element && {
			transform: transform?.translate,
			position: noFixedPosition ? "absolute" : "fixed",
		};

		if (noFixedPosition && inlineStyle) {
			inlineStyle.transform = "";
		}

		return (
			<div className={ReactUtils.cls("MassFieldInput", {open})}>
				<Field
					key={field.refId}
					label={field.name}
					noWrap={true}
					disabled={disabled}
				>
					<div
						className={ReactUtils.cls("unfocused", {multiInput: allValuesMatch})}
						onClick={this.onOpenClick}
						ref={this._element}
					>
						{allValuesMatch ? (
							<FieldInput
								dataType={field.dataType}
								dataTypeSettings={field.dataTypeSettings}
								value={actions.getOwnFieldValue(items[0], field.refId)}
								disabled={true}
							/>
						) : (
							<i>Multiple values</i>
						)}
					</div>
					{open && (
						<DomPortal
							destination={app.modalContainer}
							noPortal={noFixedPosition}
						>
							<div
								className={ReactUtils.cls("MassFieldInput__editArea", {[VerticalAlignment[transform?.vertical]]: transform})}
								style={inlineStyle}
								ref={this._floating}
							>
								<div className="header hbox alignCenter">
									<h4 className="flex_1">
										Editing multiple Values for <span>{`${this.props.field.name}`}</span>
									</h4>
									<IconButton
										icon="close"
										onClick={this.onCancel}
									/>
								</div>
								<div className="container">
									<Field label="Action">
										<SelectInput
											options={this._options}
											render={(option) => option.label}
											selected={this._options.find((option) => option.id === selectedOption)}
											onChange={this.onOptionChange}
											onFocusLossForceBlur={true}
										/>
									</Field>
									<Field label="Value(s)">{this.getEditInput()}</Field>
									<div className="editButtons">
										<IconButton
											className="apply"
											icon="apply"
											title="Apply"
											onClick={() => this.onApply(itemsToCheck)}
										/>
										<IconButton
											className="cancel"
											icon="cancel"
											title="Cancel"
											onClick={this.onCancel}
										/>
									</div>
								</div>
							</div>
						</DomPortal>
					)}
				</Field>
			</div>
		);
	}
}
