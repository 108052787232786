import {BoundedConvergence} from "../utils/animation/BoundedConvergence";
import {Easing} from "../utils/animation/Convergence";
import type {PhotoSphereSceneManager} from "./PhotoSphereSceneManager";
import {PhotoSphereShader} from "./PhotoSphereShader";
import type {ITextureData} from "./PhotoSphereManager";

export class WebGLRenderer {
	private _canvas: HTMLCanvasElement;
	private _gl: WebGLRenderingContext | WebGL2RenderingContext;
	private _webGLVersion: 1 | 2 = 2;
	private _isTextureLodAvailable: boolean = false;
	private _shader0: PhotoSphereShader;
	private _shader1: PhotoSphereShader;

	private _photoSphereSceneManager: PhotoSphereSceneManager;
	private _isInitialTexture: boolean = true;
	private _maxTextureSize: number = 1;

	private _mixFactor: BoundedConvergence;

	constructor(canvas: HTMLCanvasElement, photoSphereSceneManager: PhotoSphereSceneManager) {
		this._canvas = canvas;
		this._photoSphereSceneManager = photoSphereSceneManager;
		this._mixFactor = new BoundedConvergence({
			start: 0,
			end: 0,
			min: 0,
			max: 1,
			easing: Easing.EASE_IN_OUT,
			timeStampManager: this._photoSphereSceneManager,
		});
	}

	public init() {
		const contextAttributes = {
			alpha: false,
			antialias: false, // Not needed for this app! In fact, it causes major performance issues on lower-end devices, but visually it stays the same!
		};

		this._gl = (this._canvas.getContext("webgl2", contextAttributes) ||
			this._canvas.getContext("experimental-webgl2", contextAttributes)) as WebGL2RenderingContext;

		if (!this._gl) {
			this._webGLVersion = 1;
			this._gl = this._canvas.getContext("webgl", contextAttributes) as WebGLRenderingContext;

			if (this._gl) {
				this._isTextureLodAvailable = !!this._gl.getExtension("EXT_shader_texture_lod");
			}
		}

		if (this._gl) {
			this._maxTextureSize = this._gl?.getParameter(this._gl.MAX_TEXTURE_SIZE) ?? 1;

			this._gl.clearColor(0.125, 0.25, 0.5, 1.0);
			this._gl.viewport(0, 0, this._gl.drawingBufferWidth, this._gl.drawingBufferHeight);

			// Not needed for this app
			//this._gl.enable(this._gl.CULL_FACE);
			//this._gl.cullFace(this._gl.BACK);

			this._gl.enable(this._gl.BLEND);
			this._gl.blendFunc(this._gl.SRC_ALPHA, this._gl.DST_ALPHA);
			this._gl.clear(this._gl.COLOR_BUFFER_BIT | this._gl.DEPTH_BUFFER_BIT);

			this._shader0 = new PhotoSphereShader(this._gl, this._webGLVersion, this._isTextureLodAvailable, this._photoSphereSceneManager);
			this._shader1 = new PhotoSphereShader(this._gl, this._webGLVersion, this._isTextureLodAvailable, this._photoSphereSceneManager);

			this._canvas.addEventListener("webglcontextlost", this.onContextLost);
			this.initGeometry();
			this._gl.useProgram(this._shader0.program);

			return true;
		} else {
			return false;
		}
	}

	public get maxTextureSize() {
		return this._maxTextureSize;
	}

	public get isWebGL2Supported() {
		return this._webGLVersion >= 2;
	}

	public get isTextureLodAvailable() {
		return this._isTextureLodAvailable;
	}

	private onContextLost = (event: Event) => {
		event.preventDefault();
		//this._sceneManager.photoSphereViewer.onError("contextLost");
	};

	public setSize(width: number, height: number) {
		if (this._gl) {
			const pixelRatio = window.devicePixelRatio;

			const w = window.innerWidth * pixelRatio;
			const h = window.innerHeight * pixelRatio;

			this._canvas.width = w;
			this._canvas.height = h;

			this._gl.viewport(0, 0, this._gl.drawingBufferWidth, this._gl.drawingBufferHeight);

			this._shader0.setSize(width, height);
			this._shader1.setSize(width, height);

			this._photoSphereSceneManager.needsRender = true;
		}
	}

	private initAttribPointer(positionAttribLocation: GLint) {
		this._gl.vertexAttribPointer(
			positionAttribLocation, // Attribute location
			3, // Number of elements per attribute
			this._gl.FLOAT, // Type of elements
			false, // is it normalized
			3 * Float32Array.BYTES_PER_ELEMENT, // Size of an individual vertex
			0, // Offset from the beginning of a single vertex to this attribute
		);
	}

	private initVertices(program: WebGLProgram) {
		const positionAttribLocation = this._gl.getAttribLocation(program, "vertPosition");
		this.initAttribPointer(positionAttribLocation);
		this._gl.enableVertexAttribArray(positionAttribLocation);
	}

	private initGeometry() {
		const squareVertices = [-1, -1, 0, 1, -1, 0, -1, 1, 0, 1, 1, 0];

		const canvasVertexBufferObject = this._gl.createBuffer();
		this._gl.bindBuffer(this._gl.ARRAY_BUFFER, canvasVertexBufferObject);
		this._gl.bufferData(this._gl.ARRAY_BUFFER, new Float32Array(squareVertices), this._gl.STATIC_DRAW);

		this.initVertices(this._shader0.program);
		this.initVertices(this._shader1.program);
	}

	public loadDefaultTexture() {
		this.loadTexture(PhotoSphereShader.defaultTextureData);
	}

	public loadTexture(texData: ITextureData, playMovementEffect: boolean = false) {
		let newTextureIndex = 0;
		if (this._isInitialTexture) {
			this._isInitialTexture = false;
		} else {
			const currentlyUsedTextureIndex = this._mixFactor.end;
			newTextureIndex = currentlyUsedTextureIndex === 1 ? 0 : 1;
		}

		const prevShader = newTextureIndex === 0 ? this._shader1 : this._shader0;
		const nextShader = newTextureIndex === 0 ? this._shader0 : this._shader1;

		prevShader.lockForward();
		nextShader.unlockForward();

		nextShader.loadTexture(texData);

		if (!playMovementEffect) {
			prevShader.resetZoom();
			nextShader.resetZoom();
		}

		// Start the animation only when the new texture has already been uploaded to the gpu (because it can freeze for a moment)
		// This is needed so we can have an animation without a freeze
		requestAnimationFrame(() => {
			// New texture has been uploaded, and we already rendered once with it (with 0 alpha), so we can start the animation
			this._mixFactor.setEnd(newTextureIndex);
			if (playMovementEffect) {
				prevShader.fadeOut();
				nextShader.fadeIn();
			}
		});
	}

	public render(forward: number[], userZoomFactor: number) {
		// Not needed for this app
		// this._gl.clear(this._gl.COLOR_BUFFER_BIT | this._gl.DEPTH_BUFFER_BIT);

		this._shader0.render(forward, 1 - this._mixFactor.value, userZoomFactor);
		this._shader1.render(forward, this._mixFactor.value, userZoomFactor);
	}
}
