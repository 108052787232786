import * as React from "react";
import {observer, inject} from "mobx-react";
import styled from "styled-components";
import type {IModel} from "../../../data/models/Model";
import {XyiconFeature} from "../../../generated/api/base";
import type {AppState} from "../../../data/state/AppState";
import type {Boundary} from "../../../data/models/Boundary";
import type {IXyiconLinkObject} from "../../../data/state/AppActions";
import type {ICrossPortfolioLinkData} from "../../modules/space/spaceeditor/ui/actionbar/CrossPortfolioXyicon";
import type {Xyicon} from "../../../data/models/Xyicon";
import {SpaceItemContainerV5} from "../spaceeditor/SpaceItemContainerV5";
import {ToggleContainerV5} from "../widgets/ToggleContainerV5/ToggleContainerV5";
import type {Link} from "../../../data/models/Link";
import {LinkBreakersV5} from "../modules/spaceeditor/links/LinkBreakersV5";
import {Functions} from "../../../utils/function/Functions";
import {CrossPortfolioXyiconV5} from "../modules/spaceeditor/links/CrossPortfolioXyiconV5";
import {SpaceItemContainerStyled} from "../spaceeditor/SpaceItemContainer.Style";
import {colorPalette} from "../styles/colorPalette";
import {SpaceItemStyled} from "../spaceeditor/SpaceItemV5";
import {baseDistance} from "../styles/styles";

interface IDetailsSectionProps {
	readonly item: IModel;
	readonly feature: XyiconFeature;
	readonly appState?: AppState;
	readonly saveStateToLocalStorage?: boolean;
}

@inject("appState")
@observer
export class LinksSectionV5 extends React.Component<IDetailsSectionProps> {
	private xyiconLinks: Link[] = [];

	private renderLinks(feature: XyiconFeature) {
		const {appState, item} = this.props;
		const {actions} = appState;

		const itemId = item.id;

		const boundaries: Boundary[] = [...actions.getLinksXyiconBoundary(itemId), ...actions.getLinksBoundaryBoundary(itemId, true)].map(
			(value) => value.object,
		);
		const xyiconsForBoundary: IXyiconLinkObject[] = actions.getLinksXyiconsForBoundary(itemId);
		const xyiconsForXyicon: IXyiconLinkObject[] = actions.getLinksXyiconXyicon(itemId);
		const crossPortfolioXyiconLinks = actions.getCrossPortfolioLinksXyiconXyicon(itemId);
		const xyicons: (Xyicon | ICrossPortfolioLinkData)[] = [...xyiconsForBoundary.map((obj) => obj.object)];
		const embeddedXyicons: Xyicon[] = [];
		const xyiconsLinkedViaPort: (Xyicon | ICrossPortfolioLinkData)[] = []; // xyicons that are connected to the selected xyicon via their ports, BUT not connected to the selected xyicon's port, but to the selected xyicon itself

		const filteredXyiconLinks = xyiconsForXyicon
			.filter(({link}) => {
				const isSelectedTypeFrom = link.fromObjectId === itemId;
				return (isSelectedTypeFrom && !link.isEmbedded) || !isSelectedTypeFrom;
			})
			.map(({link}) => link);

		const additionalXyiconLinks = crossPortfolioXyiconLinks.map(({link}) => link);

		this.xyiconLinks = [...filteredXyiconLinks, ...additionalXyiconLinks];

		for (const obj of xyiconsForXyicon) {
			const {link, object} = obj;
			const isSelectedTypeFrom = link.fromObjectId === itemId;

			if (isSelectedTypeFrom) {
				if (link.isEmbedded) {
					// boundary/xyicon is connected to a xyicon
					embeddedXyicons.push(object);
				} else if (link.fromPortId) {
					// xyicon's port is connected to another xyicon, or another xyicon's port (we handle this case in the "ports" section)
				} else if (link.toPortId) {
					// xyicon is connected to a xyicon's port
					xyiconsLinkedViaPort.push(object);
				} // boundary/xyicon is connected to a xyicon
				else {
					xyicons.push(object);
				}
			} else {
				if (link.toPortId) {
				} else if (link.fromPortId) {
					// xyicon is connected to another xyicon's port
					xyiconsLinkedViaPort.push(object);
				} // xyicon is connected to a xyicon
				else {
					xyicons.push(object);
				}
			}
		}

		for (const link of crossPortfolioXyiconLinks) {
			if (!link.onePortId) {
				if (link.otherPortId) {
					xyiconsLinkedViaPort.push(link);
				} // if (!link.otherPortId)
				else {
					xyicons.push(link);
				}
			}
		}

		return this.renderLinkContainers(feature, boundaries, xyicons, embeddedXyicons, xyiconsLinkedViaPort);
	}

	private getLinkElements(items: (Boundary | Xyicon | ICrossPortfolioLinkData)[], spaceItemType?: "linked-xyicon" | null) {
		return (
			<LinkElementsStyled>
				{items.map((item: Xyicon | Boundary | ICrossPortfolioLinkData) => {
					const spaceItem = item as Xyicon | Boundary;
					const crossPortfolioLinkData = item as ICrossPortfolioLinkData;
					const index = items.findIndex((item) => item === spaceItem);

					if (!(item as Xyicon).ownFeature) {
						return (
							<CrossPortfolioXyiconV5
								key={crossPortfolioLinkData.link.id}
								transport={this.props.appState.app.transport}
								linkData={crossPortfolioLinkData}
								showBreakLinkButton={true}
								showDeleteButton={false}
								isOption={false}
							/>
						);
					} else {
						return (
							<SpaceItemContainerV5
								queryString=""
								key={spaceItem.id}
								item={spaceItem}
								showInfoButton={true}
								showDeleteButton={false}
								hideDragHandle={true}
								onBreakLinkClick={
									spaceItemType === "linked-xyicon"
										? () => LinkBreakersV5.breakLinks(this.props.appState.app.transport, [this.xyiconLinks[index].id], false)
										: Functions.emptyFunction
								}
								onPointerDown={Functions.emptyFunction}
								onPointerMove={Functions.emptyFunction}
								onPointerUp={Functions.emptyFunction}
							/>
						);
					}
				})}
			</LinkElementsStyled>
		);
	}

	private renderLinkContainers(
		feature: XyiconFeature,
		boundaries: Boundary[],
		xyicons: (Xyicon | ICrossPortfolioLinkData)[],
		embeddedXyicons: Xyicon[],
		xyiconsLinkedViaPort: (Xyicon | ICrossPortfolioLinkData)[],
	) {
		const arrayOfContainers = [
			<ToggleContainerV5
				key={0}
				title="Boundaries"
				saveStateToLocalStorage={this.props.saveStateToLocalStorage}
				className="FieldSection"
			>
				{this.getLinkElements(boundaries)}
			</ToggleContainerV5>,
			<ToggleContainerV5
				key={1}
				title="Xyicons"
				saveStateToLocalStorage={this.props.saveStateToLocalStorage}
				className="FieldSection"
			>
				{this.getLinkElements(xyicons, "linked-xyicon")}
			</ToggleContainerV5>,
		];

		if (feature === XyiconFeature.Xyicon) {
			arrayOfContainers.push(
				<ToggleContainerV5
					key={2}
					title="Embedded Xyicons"
					saveStateToLocalStorage={this.props.saveStateToLocalStorage}
					className="FieldSection"
				>
					{this.getLinkElements(embeddedXyicons)}
				</ToggleContainerV5>,
				<ToggleContainerV5
					key={3}
					title="Ports"
					saveStateToLocalStorage={this.props.saveStateToLocalStorage}
					className="FieldSection"
				>
					{this.getLinkElements(xyiconsLinkedViaPort, "linked-xyicon")}
				</ToggleContainerV5>,
			);
		}
		return arrayOfContainers;
	}

	public override render() {
		const {feature} = this.props;

		return (
			(feature === XyiconFeature.Xyicon || feature === XyiconFeature.Boundary) && (
				<ToggleContainerV5
					title="Links"
					saveStateToLocalStorage={this.props.saveStateToLocalStorage}
				>
					{this.renderLinks(this.props.feature)}
				</ToggleContainerV5>
			)
		);
	}
}

const LinkElementsStyled = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, 176px);
	grid-gap: ${baseDistance.md};

	${SpaceItemContainerStyled} {
		${SpaceItemStyled} {
			cursor: default;
		}

		border: 1px solid transparent;

		&:hover {
			background-color: transparent;
			border: 1px solid ${colorPalette.gray.c500Primary};

			.description {
				max-width: calc(100% - 104px);
			}
		}
	}
`;
