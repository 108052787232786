import * as React from "react";
import {inject, observer} from "mobx-react";
import {IconButton} from "../../widgets/button/IconButton";
import {TextInput} from "../../widgets/input/text/TextInput";
import {SVGIcon} from "../../widgets/button/SVGIcon";
import type {AppState} from "../../../data/state/AppState";
import {ReactUtils} from "../../utils/ReactUtils";
import type {Organization} from "../../../data/models/Organization";
import type {TransportLayer} from "../../../data/TransportLayer";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {LoginWindow} from "../../windows/login/LoginWindow";
import {PopupBackdropV5} from "../../5.0/popup/PopupBackdropV5";
import {Functions} from "../../../utils/function/Functions";
import {XHRLoader} from "../../../utils/loader/XHRLoader";
import {BrowserWindow} from "../../../utils/device/BrowserWindow";

interface ISwitchOrganizationProps {
	readonly open?: boolean;
	readonly toggleOrganizationSwitch?: () => void;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface ISwitchOrganizationState {
	query: string;
	isLoggingUsingCredentials: boolean;
	switchingOrgId: string;
}

@inject("appState")
@inject("transport")
@observer
export class OrganizationSwitch extends React.Component<ISwitchOrganizationProps, ISwitchOrganizationState> {
	constructor(props: ISwitchOrganizationProps) {
		super(props);

		this.state = {
			query: "",
			isLoggingUsingCredentials: false,
			switchingOrgId: "",
		};
	}

	private onClickOrganization = async (org: Organization) => {
		const isUserLoggedInUsingXyiconCredentials = this.props.appState.user?.isUserLoggedInUsingXyiconCredentials;
		const {
			auth: {loginConst},
			localStorage,
		} = this.props.transport.services;
		const {isSSOEnabledForOrganization, isSSOEnabledForUser} = org;
		const {appState} = this.props;

		// To allow the user with the SSO path the following two condtions needs to be satisfied
		// 1. SSO is enabled for the organization
		// 2. SSO is enabled for the user #7502
		if (isSSOEnabledForOrganization && isSSOEnabledForUser) {
			const param = {
				organizationId: org.id,
				clientID: loginConst.clientId,
				clientSecret: loginConst.clientSecret,
				email: this.props.appState.user.email,
				ssoCallBackURL: `${BrowserWindow.getURLPathWithoutQuery()}#auth/ssocallback`,
				isUserLoggedInUsingXyiconCredentials: isUserLoggedInUsingXyiconCredentials.toString(),
			};
			const url = `${this.props.transport.apiUrl}/auth/ssosignin?${XHRLoader.encodeParams(param)}`;
			window.open(url, "_self");
		} else {
			if (isSSOEnabledForUser && !isSSOEnabledForOrganization) {
				// This should be there to identify the issue in case of SSO configuration from BE
				// and also as a help for FE dev and QA team
				localStorage.set("sso-config-issue", "Something went wrong with SSO configuration from BE");
			}
			if (isUserLoggedInUsingXyiconCredentials) {
				await this.props.transport.services.auth.switchOrganization(org.id);
				this.props.toggleOrganizationSwitch();
			} else {
				this.setState({
					isLoggingUsingCredentials: true,
					switchingOrgId: org.id,
				});
			}
		}
	};

	private handleSwitchFromSSOToNormalLoginSuccess = async () => {
		await this.props.transport.services.auth.switchOrganization(this.state.switchingOrgId);
		this.props.toggleOrganizationSwitch();
	};

	private handleClose = () => {
		this.setState({isLoggingUsingCredentials: false});
	};

	public override render() {
		const {appState, open, toggleOrganizationSwitch} = this.props;
		const {isLoggingUsingCredentials} = this.state;
		const organizations = appState.organizations;
		const query = this.state.query.toLowerCase() || "";

		return (
			<>
				<div className={ReactUtils.cls("OrganizationSwitch", {open: open})}>
					<IconButton
						icon="arrow"
						className="goBack"
						onClick={toggleOrganizationSwitch}
					/>
					{open && (
						<TextInput
							placeholder="Search Organization..."
							onInput={(value) => this.setState({query: value})}
							autoFocus={true}
						/>
					)}
					<div className="organizationsList">
						<ul>
							{organizations
								.filter((org) => StringUtils.containsIgnoreCase(org.name, query))
								.sort((orgA, orgB) => StringUtils.sortIgnoreCase(orgA.name, orgB.name))
								.map((org, key) => (
									<li
										key={key}
										className={ReactUtils.cls("OrganizationItem", {selected: org.id === appState.organizationId})}
										onClick={() => this.onClickOrganization(org)}
									>
										<SVGIcon icon="checkmark" />
										<h2>{org.name}</h2>
									</li>
								))}
						</ul>
					</div>
					{isLoggingUsingCredentials && (
						<DomPortal
							destination={appState.app.modalContainer}
							noPortal={false}
						>
							<PopupBackdropV5 onClick={Functions.stopPropagation}>
								<div className="sso-switch">
									<LoginWindow
										handleClose={this.handleClose}
										isOrganizationSwitchView={true}
										handleOrganizationSwitch={this.handleSwitchFromSSOToNormalLoginSuccess}
									/>
								</div>
							</PopupBackdropV5>
						</DomPortal>
					)}
				</div>
			</>
		);
	}
}
