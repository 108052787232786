import styled from "styled-components";
import type {Color} from "../../../../../generated/api/base";
import {MarkupType} from "../../../../../generated/api/base";
import {MarkupsWithInterchangeableProperties} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/MarkupStaticElements";
import {markupTypeToMarkupToolName} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/MarkupUtils";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {colorPalette} from "../../../styles/colorPalette";
import {ToolButtonV5} from "../toolbar/ToolButtonV5";
import type {ExtendedToolIconKey} from "../toolbar/ToolButtonV5";
import {baseDistance, radius} from "../../../styles/styles";

interface IMarkupTypeOptionsProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly activeColor: Color;
	readonly activeType: MarkupType;
	readonly onChange: (newType: MarkupType) => void;
}

export const MarkupTypeOptionsV5 = (props: IMarkupTypeOptionsProps) => {
	const typeOptions = [...MarkupsWithInterchangeableProperties];

	const buttons = [];

	for (const typeOption of typeOptions) {
		const tools = props.spaceViewRenderer.tools;
		const toolName = markupTypeToMarkupToolName(typeOption);
		const tool = tools[toolName];

		buttons.push(
			<ToolButtonV5
				key={toolName}
				isActive={typeOption === props.activeType}
				title={`${MarkupType[typeOption].replaceAll("_", " ")} Markup`}
				titleAlignment="top"
				onClick={() => props.onChange(typeOption)}
				icon={tool.icon as ExtendedToolIconKey}
				color={props.activeColor}
			/>,
		);
	}

	return <MarkupTypeOptionsStyled className="MarkupTypeOptions">{buttons}</MarkupTypeOptionsStyled>;
};

export const MarkupTypeOptionsStyled = styled.div`
	position: absolute;
	background: ${colorPalette.white};
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: ${baseDistance.xs};
	padding: ${baseDistance.xs};
	border-radius: ${radius.md};
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
	top: 100%;
`;
