import * as React from "react";
import {inject, observer} from "mobx-react";
import {ConfirmWindowV5} from "../../popup/ConfirmWindowV5";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import type {AppState} from "../../../../data/state/AppState";
import {Permission, XyiconFeature} from "../../../../generated/api/base";
import {ReactUtils} from "../../../utils/ReactUtils";
import {TextInputV5} from "../../details/datatypes/TextInputV5";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";
import DotsHorizontalIcon from "../../icons/dots-horizontal.svg?react";
import ListIcon from "../../icons/list.svg?react";
import FrameIcon from "../../icons/frame.svg?react";
import StarIcon from "../../icons/star.svg?react";
import ShareIcon from "../../icons/share-dark.svg?react";
import SwitchIcon from "../../icons/switch-horizontal.svg?react";
import RenameIcon from "../../icons/pen-field.svg?react";
import CloneIcon from "../../icons/clone.svg?react";
import TrashIcon from "../../icons/trash.svg?react";
import GlobeIcon from "../../icons/globe.svg?react";
import {IconButtonV5} from "../../interaction/IconButtonV5";
import {DropdownButtonV5} from "../../interaction/DropdownButtonV5";
import type {IDropdownOption} from "../../interaction/DropdownOptionsV5";
import type {View} from "../../../../data/models/View";
import type {IViewFolder} from "../../../../data/models/ViewUtils";
import type {ViewActionType} from "../../sharing/GlobalSharingPopup";
import {GlobalSharingPopup} from "../../sharing/GlobalSharingPopup";
import {navBarZIndex} from "../../styles/styles";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {ViewElementStyled, getKeyForLocalStorageWorkspaceViewSections} from "./WorkspaceViewCommon";
import type {WorkspaceViewType} from "./WorkspaceViewCommon";

interface IViewItemProps {
	readonly appState?: AppState;
	readonly view: View;
	readonly type: WorkspaceViewType;
	readonly level?: number;
	readonly onViewClick: (view: View) => void;
	readonly onShareClick: (viewItem: View | IViewFolder) => void;
	readonly forceUpdateParent: () => void;
	readonly searchString: string;
}

interface IViewItemState {
	isInEditMode: boolean;
	isViewNameToolTipOpen: boolean;
	viewNameToolTipTransform: TransformObj;
	isGlobalActionPanelOpen: boolean;
	globalActionType: ViewActionType;
	renameValue: string;
	isViewEllipsisOpen: boolean;
}

@inject("appState")
@observer
export class ViewItemV5 extends React.Component<IViewItemProps, IViewItemState> {
	private _viewName = React.createRef<HTMLDivElement>();
	private _viewNameParent = React.createRef<HTMLDivElement>();
	private _timeOutId: number = null;

	constructor(props: IViewItemProps) {
		super(props);

		this.state = {
			isInEditMode: false,
			isViewNameToolTipOpen: false,
			viewNameToolTipTransform: null,
			isGlobalActionPanelOpen: false,
			globalActionType: "modify",
			renameValue: "",
			isViewEllipsisOpen: false,
		};
	}

	private onClick = (e: React.MouseEvent) => {
		const {view} = this.props;

		// Without this, the navigation panel can open up when you click on a view/folder in a popup
		e.stopPropagation();

		this.props.onViewClick(view);
	};

	private onRenameClick = () => {
		// Workaround for focusloss/blur problem
		requestAnimationFrame(() => {
			if (!this.state.isInEditMode) {
				this.setState({
					isInEditMode: true,
				});
			}
		});
	};

	private getErrorMessage = (value: string) => {
		const view = this.props.view;
		const isNameValid = this.props.appState.actions.isNameValidForView(value, view.itemFeature, view.id);

		if (isNameValid) {
			return "";
		}
		return value.trim() === "" ? "Name cannot be empty!" : "Name needs to be unique!";
	};

	private onRenameGlobalView = (value: string) => {
		const view = this.props.view;
		const isNameValid = this.props.appState.actions.isNameValidForView(value, view.itemFeature, view.id);

		if (isNameValid) {
			if (value !== view.name) {
				this.setState({
					isGlobalActionPanelOpen: true,
					globalActionType: "rename",
					renameValue: value,
				});
			}
		}
	};

	private onRenameApply = async (value: string) => {
		const view = this.props.view;
		const isNameValid = this.props.appState.actions.isNameValidForView(value, view.itemFeature, view.id);

		if (isNameValid) {
			this.onRenameBlur();

			if (value !== view.name) {
				view.name = value;
				await this.props.appState.app.transport.services.view.update(view.getData());
			}
		}
	};

	private onRenameBlur = () => {
		if (this.state.isInEditMode) {
			this.setState({
				isInEditMode: false,
			});
		}
	};

	private onBlurGlobalViewRename = () => {
		if (this.state.isInEditMode) {
			this.setState({
				isGlobalActionPanelOpen: true,
				globalActionType: "rename",
			});
		}
	};

	private onDeleteClick = async () => {
		if (this.props.type === "Global") {
			this.setState({
				isGlobalActionPanelOpen: true,
				globalActionType: "delete",
			});
		} else {
			const confirmed = await ConfirmWindowV5.open("Are you sure you want to delete the selected 1 item?");

			if (confirmed) {
				const isFavorite = this.props.view?.isFavorite;

				await this.props.appState.app.transport.services.view.delete(this.props.view.id);

				if (isFavorite) {
					const {user} = this.props.appState;

					if (user) {
						await user.setFavoriteViews(user.favoriteViews);
					}
				}
			}
		}
	};

	private onDuplicateClick = async () => {
		const duplicate = await this.props.view.duplicate();

		const keyForLocalStorage = getKeyForLocalStorageWorkspaceViewSections(this.props.appState.user.id);

		let openViewSpaceTypes: WorkspaceViewType[] = JSON.parse(localStorage.getItem(keyForLocalStorage)) || null;

		if (!openViewSpaceTypes) {
			openViewSpaceTypes = ["Favorites", "Global", "My Views", "Shared With Me"];
			localStorage.setItem(keyForLocalStorage, JSON.stringify(openViewSpaceTypes));
		}

		const newOpenViewSpaceTypes = new Set([...openViewSpaceTypes]);

		if (this.props.type === "Global") {
			if (!newOpenViewSpaceTypes.has("My Views")) {
				newOpenViewSpaceTypes.add("My Views");
			}
			localStorage.setItem(keyForLocalStorage, JSON.stringify([...newOpenViewSpaceTypes]));
			this.props.forceUpdateParent();
		}

		return duplicate;
	};

	private onShareClick = () => {
		this.props.onShareClick(this.props.view);
	};

	private onMarkAsGlobalClick = () => {
		this.setState({
			isGlobalActionPanelOpen: true,
			globalActionType: "makeGlobal",
		});
	};

	private onMarkAsPrivateClick = () => {
		this.setState({
			isGlobalActionPanelOpen: true,
			globalActionType: "makePrivate",
		});
	};

	private onRenameCancel = () => {
		this.setState({
			isGlobalActionPanelOpen: false,
			isInEditMode: false,
			renameValue: "",
		});
	};

	private getOptions = (): IDropdownOption[] => {
		const user = this.props.appState.user;
		const view = this.props.view;
		const type = this.props.type;

		const dropdownOptions: IDropdownOption[] = [
			{
				label: view?.isFavorite ? "Remove Favorite" : "Mark as Favorite",
				onClick: view?.isFavorite ? () => view.setFavorite(false) : () => view.setFavorite(true),
				IconComponent: StarIcon,
			},
		];

		switch (type) {
			case "My Views":
				dropdownOptions.push(
					{
						label: "Share",
						onClick: this.onShareClick,
						IconComponent: ShareIcon,
					},
					{
						label: "Change Owner",
						onClick: () => console.log("onChangeOwnerClick"),
						IconComponent: SwitchIcon,
					},
					{
						label: "Rename",
						onClick: this.onRenameClick,
						IconComponent: RenameIcon,
					},
					{
						label: "Duplicate",
						onClick: this.onDuplicateClick,
						IconComponent: CloneIcon,
					},
				);

				if (user.isAdmin) {
					dropdownOptions.push({
						label: "Mark as Global View",
						onClick: this.onMarkAsGlobalClick,
						IconComponent: GlobeIcon,
					});
				}

				dropdownOptions.push({
					label: "Delete",
					onClick: this.onDeleteClick,
					IconComponent: TrashIcon,
				});
				break;
			case "Shared With Me":
				if (view.getPermission(user.id) >= Permission.Update) {
					// edit access
					dropdownOptions.push(
						{
							label: "Share",
							onClick: this.onShareClick,
							IconComponent: ShareIcon,
						},
						{
							label: "Rename",
							onClick: this.onRenameClick,
							IconComponent: RenameIcon,
						},
						{
							label: "Duplicate",
							onClick: this.onDuplicateClick,
							IconComponent: CloneIcon,
						},
					);

					if (user.isAdmin || (!user.isAdmin && user.updatePermissions)) {
						dropdownOptions.push({
							label: "Mark as Global View",
							onClick: this.onMarkAsGlobalClick,
							IconComponent: GlobeIcon,
						});
					}
				} else {
					// view access only
					dropdownOptions.push({
						label: "Duplicate",
						onClick: this.onDuplicateClick,
						IconComponent: CloneIcon,
					});
				}

				break;
			case "Global":
				if (user.isAdmin) {
					dropdownOptions.push(
						{
							label: "Share",
							onClick: this.onShareClick,
							IconComponent: ShareIcon,
						},
						{
							label: "Rename",
							onClick: this.onRenameClick,
							IconComponent: RenameIcon,
						},
						{
							label: "Duplicate",
							onClick: this.onDuplicateClick,
							IconComponent: CloneIcon,
						},
						{
							label: "Convert to Private View",
							onClick: this.onMarkAsPrivateClick,
							IconComponent: GlobeIcon,
						},
						{
							label: "Delete",
							onClick: this.onDeleteClick,
							IconComponent: TrashIcon,
						},
					);
				} else {
					if (view.getPermission(user.id) >= Permission.Update) {
						dropdownOptions.push(
							{
								label: "Share",
								onClick: this.onShareClick,
								IconComponent: ShareIcon,
							},
							{
								label: "Rename",
								onClick: this.onRenameClick,
								IconComponent: RenameIcon,
							},
							{
								label: "Duplicate",
								onClick: this.onDuplicateClick,
								IconComponent: CloneIcon,
							},
							{
								label: "Delete",
								onClick: this.onDeleteClick,
								IconComponent: TrashIcon,
							},
						);
					} else {
						dropdownOptions.push({
							label: "Duplicate",
							onClick: this.onDuplicateClick,
							IconComponent: CloneIcon,
						});
					}
				}

				break;
		}

		return dropdownOptions;
	};

	private onMouseOverViewName = (event: React.MouseEvent) => {
		if (event.currentTarget.scrollWidth > event.currentTarget.clientWidth) {
			if (this._timeOutId) {
				clearTimeout(this._timeOutId);
			}

			this._timeOutId = window.setTimeout(() => {
				this.setState({isViewNameToolTipOpen: true});
			}, 1000);
		}
	};

	private onMouseLeaveViewName = () => {
		clearTimeout(this._timeOutId);

		this.setState({isViewNameToolTipOpen: false});
	};

	public override componentDidUpdate(prevProps: IViewItemProps, prevState: IViewItemState) {
		if (!prevState.isViewNameToolTipOpen && this.state.isViewNameToolTipOpen && this._viewNameParent.current && this._viewName.current) {
			this.setState({
				viewNameToolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._viewNameParent.current,
					this._viewName.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
					0,
					0,
				),
			});
		}
	}

	public override render() {
		const {viewNameToolTipTransform, isInEditMode, isViewNameToolTipOpen, isGlobalActionPanelOpen, globalActionType} = this.state;
		const {appState, level, view, searchString} = this.props;
		const app = appState.app;

		const inlineStyleViewName: React.CSSProperties = this._viewName.current && {
			transform: viewNameToolTipTransform?.translate,
		};

		const isActive = view?.itemFeature === appState.selectedFeature && view.id === appState.actions.getSelectedView(view?.itemFeature)?.id;
		const Icon = view?.itemFeature === XyiconFeature.SpaceEditor ? FrameIcon : ListIcon;

		const isFilteredOut = searchString && !StringUtils.containsIgnoreCase(view.name, searchString);

		if (isFilteredOut) {
			return <></>;
		}

		return (
			<ViewElementStyled
				className={ReactUtils.cls("ViewItem hbox alignCenter", {
					isActive,
					isInEditMode: this.state.isInEditMode,
					level1: level === 1,
				})}
				onClick={this.onClick}
				$isActive={isActive}
				$isHovered={this.state.isViewEllipsisOpen}
			>
				{/* <DragIcon /> */}
				<Icon />
				{isInEditMode ? (
					<TextInputV5
						className="viewName flex_1"
						value={view.name ?? ""}
						onChange={view.isGlobal ? this.onRenameGlobalView : this.onRenameApply}
						onBlur={view.isGlobal ? this.onBlurGlobalViewRename : this.onRenameBlur}
						autoFocus={true}
						getErrorMessage={this.getErrorMessage}
					/>
				) : (
					<div
						ref={this._viewNameParent}
						className="viewName flex_1"
						onMouseOver={this.onMouseOverViewName}
						onMouseLeave={this.onMouseLeaveViewName}
					>
						{view?.name}
					</div>
				)}
				{isGlobalActionPanelOpen && (
					<GlobalSharingPopup
						viewItem={view}
						onClose={this.onRenameCancel}
						viewActionType={globalActionType}
						isReports={false}
						newName={this.state.renameValue}
					/>
				)}
				{isViewNameToolTipOpen && (
					<DomPortal destination={app.modalContainer}>
						<div
							className="ViewItem__viewNameToolTip"
							style={inlineStyleViewName}
							ref={this._viewName}
							title={view?.name}
						>
							{/* <InfoBubbleV5 content={view.name ?? ""} /> */}
						</div>
					</DomPortal>
				)}
				<DropdownButtonV5
					options={this.getOptions()}
					button={
						<IconButtonV5
							IconComponent={DotsHorizontalIcon}
							onClick={() => this.setState((prevState) => ({isViewEllipsisOpen: !prevState.isViewEllipsisOpen}))}
						/>
					}
					optionsZIndex={navBarZIndex + 1}
					onDropdownClose={() => this.setState({isViewEllipsisOpen: false})}
				/>
			</ViewElementStyled>
		);
	}
}
