import styled from "styled-components";
import {baseDistance, fontWeight} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";

export const MarkupColorFilterPanel = styled.div`
	&.MarkupColorFilterPanel {
		padding: ${baseDistance.sm} 0px;

		.label {
			margin-top: ${baseDistance.sm};
			font-weight: ${fontWeight.thin};
			white-space: nowrap;
			margin-right: 30px;
		}

		.colorContainer {
			position: relative;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}

		.more {
			cursor: pointer;
			color: ${colorPalette.primary.c500Primary};
		}

		.moreColors {
			position: absolute;
			right: 0;
			top: 100%;
			width: 275px;

			.colorContainer {
				max-height: 230px;
				overflow-y: auto;
			}
		}

		.colorButton {
			cursor: pointer;
			width: 28px;
			height: 28px;
			margin: ${baseDistance.xs};
			position: relative;

			&.bordered {
				border-radius: 100%;
				border: 2px solid ${colorPalette.primary.c500Primary};
			}

			.colorFill {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				margin: 0px;

				svg {
					width: ${baseDistance.md};
					height: ${baseDistance.md};

					&.white {
						fill: ${colorPalette.white};
					}

					&.black {
						fill: black;
					}
				}
			}
		}
	}
`;
