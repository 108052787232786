import {Constants} from "../../ui/modules/space/spaceeditor/logic3d/Constants";
import {MathUtils} from "../../utils/math/MathUtils";
import {VectorUtils} from "../../utils/VectorUtils";

interface IRay {
	origin: [number, number, number];
	direction: number[];
}

export class RayCaster {
	// Optimized: (we're using the origo and the camera pos as [0, 0, 0], and 1 as radius), + we're always inside the sphere
	public static intersectSphereByForwardAndCursor(
		forward: [number, number, number],
		cursor: [number, number],
		aspectRatio: number,
		userZoomFactor: number,
	): number[] | null {
		const ray = RayCaster.convertCameraPositionAndCursorToRay(forward, cursor, aspectRatio, userZoomFactor);

		return this.intersectSphereByRay(ray);
	}

	// Optimized: ray origin has to be inside the sphere
	// Sphere's radius is 1
	public static intersectSphereByRay(ray: IRay) {
		const rc = ray.origin;

		const c = VectorUtils.dotProduct(rc, rc) - 1.0;
		const b = VectorUtils.dotProduct(ray.direction, rc);
		const d = b * b - c;
		const t = -b + Math.sqrt(Math.abs(d));

		return VectorUtils.addVectors(ray.origin, VectorUtils.multiplyByScalar(ray.direction, t));
	}

	/**
	 *
	 * @param cameraPosition world position, NOT normalized
	 * @param cursor in the range of [-1, 1]
	 */
	private static convertCameraPositionAndCursorToRay(
		forward: [number, number, number],
		cursor: [number, number],
		aspectRatio: number,
		userZoomFactor: number,
	): IRay {
		const worldUp = Constants.WORLD_UP;
		const camRight = VectorUtils.normalize(VectorUtils.crossVectors(forward, worldUp));
		const camUp = VectorUtils.normalize(VectorUtils.crossVectors(camRight, forward));

		const forwardWorld = VectorUtils.multiplyByScalar(forward, MathUtils.getForwardLengthFromFOV(Constants.FOV) * userZoomFactor);
		const rightComponent = VectorUtils.multiplyByScalar(VectorUtils.multiplyByScalar(camRight, aspectRatio), cursor[0]);
		const upComponent = VectorUtils.multiplyByScalar(camUp, cursor[1]);
		const direction = VectorUtils.addVectors(VectorUtils.addVectors(forwardWorld, rightComponent), upComponent);

		return {
			origin: Constants.CAMERA_POSITION,
			direction: VectorUtils.normalize(direction),
		};
	}
}
