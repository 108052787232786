import type {AppState} from "../data/state/AppState";
import type {TransportLayer} from "../data/TransportLayer";
import type {Navigation} from "../Navigation";
import type {ILoginWindowState} from "../ui/windows/login/LoginWindow";
import {BrowserWindow} from "./device/BrowserWindow";
import {XHRLoader} from "./loader/XHRLoader";

interface LoginUtilProps {
	event: React.ChangeEvent<HTMLFormElement>;
	transport: TransportLayer;
	appState: AppState;
	navigation: Navigation;
	isOrganizationSwitchView: boolean;
	loginWithSSO: boolean;
	handleClose: () => void;
	handleOrganizationSwitch: () => Promise<void>;
	handleLoginStateChange: (state: ILoginWindowState) => void;
}

export const handleLogin = async (props: LoginUtilProps) => {
	const {
		event,
		transport,
		appState,
		navigation,
		loginWithSSO,
		isOrganizationSwitchView,
		handleClose,
		handleOrganizationSwitch,
		handleLoginStateChange,
	} = props;

	const {
		apiUrl,
		services: {auth},
	} = transport;
	const {loginConst} = auth;
	const formData = new FormData(event.target);
	const user = isOrganizationSwitchView ? appState.user?.email : (formData.get("userName") as string);
	const password = formData.get("pwInput") as string;

	handleLoginStateChange({isLoading: true, loginWithSSO: loginWithSSO, message: ""});

	if (loginWithSSO) {
		const loginData = {
			email: user,
			clientID: loginConst.clientId,
			clientSecret: loginConst.clientSecret,
			ssoCallBackURL: `${BrowserWindow.getURLPathWithoutQuery()}#auth/ssocallback`,
			isUserLoggedInUsingXyiconCredentials: (appState.user?.isUserLoggedInUsingXyiconCredentials ?? false).toString(),
		};

		const url = `${apiUrl}/auth/ssosignin?${XHRLoader.encodeParams(loginData)}`;
		window.open(url, "_self");
	} else {
		const {error} = await auth.loginPassword(user, password, isOrganizationSwitchView);

		if (error) {
			handleLoginStateChange({message: error, isLoading: false, loginWithSSO});
		} else {
			if (isOrganizationSwitchView) {
				handleClose();
				handleOrganizationSwitch();
			}

			handleLoginStateChange({message: "", isLoading: false, loginWithSSO});
			await navigation.redirectAfterLogin();
			auth.loadSecondaryList();
			handleLoginStateChange({isLoading: false, loginWithSSO, message: ""});
		}
	}
};
