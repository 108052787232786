import type {RefObject} from "react";
import {Component, createRef} from "react";
import {inject, observer} from "mobx-react";
import {XyiconFeature} from "../../../../generated/api/base/models/XyiconFeature";
import {AppUtils} from "../../../../utils/AppUtils";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import {ButtonV5} from "../../button/ButtonV5";
import {PopupV5} from "../../popup/PopupV5";
import {SelectSearchFieldV5} from "../../input/search/SelectSearchFieldV5";
import {AddItemStyled, DropdownSelectorStyled, SectionTitle, SharingPanelStyled} from "../../sharing/SharingPanel.styled";
import type {UserGroup} from "../../../../data/models/UserGroup";
import type {AppState} from "../../../../data/state/AppState";
import {User} from "../../../../data/models/User";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import type {PortfolioGroup} from "../../../../data/models/PortfolioGroup";
import type {TransportLayer} from "../../../../data/TransportLayer";
import {Portfolio} from "../../../../data/models/Portfolio";
import {GroupShareSelectorV5} from "../../sharing/GroupShareSelectorV5";
import type {SelectedItem} from "../../sharing/SharingUtils";
import {SearchAddAndListItemsStyled} from "./ItemGroup.styled";

interface IAddGroupItemsProps {
	readonly isPopupOpen?: boolean;
	readonly parentRef?: RefObject<HTMLDivElement>;
	readonly onClose?: () => void;
	readonly onAddUserClick?: () => void;
	readonly userGroup?: UserGroup;
	readonly portfolioGroup?: PortfolioGroup;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
	readonly type?: "User" | "Portfolio";
}

interface IAddGroupItemsState {
	open: boolean;
	selectedItems: SelectedItem[];
	search: string;
}

@inject("appState")
@inject("transport")
@observer
export class AddGroupItemsV5 extends Component<IAddGroupItemsProps, IAddGroupItemsState> {
	private _container = createRef<HTMLDivElement>();
	private _list = createRef<HTMLDivElement>();

	constructor(props: IAddGroupItemsProps) {
		super(props);
		this.state = {
			open: false,
			selectedItems: [],
			search: "",
		};
	}

	private onSearchInput = (value: string) => {
		this.setState({search: value});
	};

	private toggleSelector = async (value?: boolean) => {
		const open = this.state.open;

		if (!value && open) {
			this.setState({search: ""});
			AppUtils.disableScrolling(false);
		}

		this.setState({open: value ?? !open});

		if (!open && value) {
			FocusLoss.stopListen(this._list.current, this.onFocusLoss);
			AppUtils.disableScrolling(true);
			await TimeUtils.wait(100);
			FocusLoss.listen(this._list.current, this.onFocusLoss);
		}
	};

	private onFocusLoss = () => {
		this.toggleSelector(false);
	};

	private onClosePopup = () => {
		this.setState({
			selectedItems: [],
		});
		this.props.onClose();
	};

	public override componentWillUnmount(): void {
		FocusLoss.stopListen(this._list.current, this.onFocusLoss);
	}

	private updateUserGroupApi() {
		const userGroup = this.props.userGroup;

		if (userGroup.id) {
			// we're in edit mode
			this.props.transport.services.feature.update(userGroup.id, XyiconFeature.UserGroup, {
				name: userGroup.name,
				userIDList: userGroup.userIds,
			});
		}
	}

	private updatePortfolioGroupApi() {
		const portfolioGroup = this.props.portfolioGroup;

		if (portfolioGroup.id) {
			// we're in edit mode
			this.props.transport.services.feature.update(portfolioGroup.id, XyiconFeature.PortfolioGroup, {
				portfolios: portfolioGroup.portfolioIds,
				name: portfolioGroup.name,
			});
		}
	}

	private onAddClick = () => {
		const {userGroup, appState, portfolioGroup, type} = this.props;
		const {selectedItems} = this.state;

		if (type == "User") {
			selectedItems.forEach((item) => {
				userGroup.addUser(item.id, appState);
			});
			this.updateUserGroupApi();
		} else {
			selectedItems.forEach((item) => {
				portfolioGroup.addPortfolio(item.id);
			});
			this.updatePortfolioGroupApi();
		}

		this.setState({
			selectedItems: [],
		});
	};

	private onItemDelete = (item: User | Portfolio) => {
		const {type, userGroup, portfolioGroup, appState} = this.props;

		if (type == "User") {
			userGroup.removeUser(item.id, appState);
			this.updateUserGroupApi();
		} else {
			portfolioGroup.removePortfolio(item.id);
			this.updatePortfolioGroupApi();
		}
	};

	private onItemSelect = (item: User | Portfolio) => {
		const id = item.id;
		const feature = item.ownFeature;
		const itemName = feature == XyiconFeature.User ? (item as User).firstName : (item as Portfolio).name;
		const isItemSelected = this.state.selectedItems.some((item) => item.id === id);

		if (!isItemSelected) {
			const updatedItems = [...this.state.selectedItems, {id, itemName, feature}];
			this.setState({
				selectedItems: updatedItems,
			});
		}

		this.setState({search: ""});
	};

	private onItemRemove = (item: User | Portfolio) => {
		const updatedItems = this.state.selectedItems.filter((selectedItem) => selectedItem.id !== item.id);

		this.setState({
			selectedItems: updatedItems,
		});
	};

	public override render() {
		const {onAddUserClick, isPopupOpen, parentRef, userGroup, appState, type, portfolioGroup} = this.props;
		const {open, search} = this.state;

		let allUsers = appState.actions.getList<User>(XyiconFeature.User);
		let allPortfolios = appState.actions.getList<Portfolio>(XyiconFeature.Portfolio);

		const isUser = type === "User";
		const feature = isUser ? XyiconFeature.User : XyiconFeature.Portfolio;
		const addedUsers = userGroup?.userIds.map((userId) => appState.actions.findUser(userId)).filter((user) => !!user);
		const addedPortfolios = portfolioGroup?.portfolioIds
			.map((portfolioId) => appState.actions.getPortfolioById(portfolioId))
			.filter((portfolio) => !!portfolio);

		if (search) {
			allUsers = allUsers.filter((user) => User.search(user, search));
			allPortfolios = allPortfolios.filter((id) => Portfolio.search(id, search));
		}

		const addedItems = isUser ? addedUsers : addedPortfolios;
		const allItems = isUser ? allUsers : allPortfolios;

		return (
			<SearchAddAndListItemsStyled>
				{isPopupOpen && (
					<PopupV5
						onClose={this.onClosePopup}
						label={`Add ${StringUtils.capitalize(type)}s to group`}
						parentRef={parentRef.current}
						className="SettingsUserCreation"
						width="400px"
						height="480px"
						freezeRoot={true}
						noButtons={true}
						isSmallPopup={true}
						centerOnScreen={true}
					>
						<AddItemStyled>
							<div className="container">
								<SelectSearchFieldV5
									className="hbox flex_1"
									value={search}
									onInput={this.onSearchInput}
									onClick={() => this.toggleSelector(true)}
									selectedItems={this.state.selectedItems}
									placeholder={!open && this.state.selectedItems.length == 0 ? "Enter user/ portfolio group, comma separated" : " "}
									divRef={this._container}
									isDropdown={false}
									removeSelectedItem={(items) => {
										this.setState({
											selectedItems: items,
										});
									}}
								/>
								<ButtonV5
									onClick={this.onAddClick}
									label="Add"
									disabled={this.state.selectedItems.length === 0}
								/>
							</div>
							{open && (
								<DropdownSelectorStyled ref={this._list}>
									{allItems.length === 0 ? (
										<div className="empty">We couldn't find a match.</div>
									) : (
										<>
											<SectionTitle>{`${StringUtils.capitalize(type)}s`}</SectionTitle>
											<GroupShareSelectorV5
												items={allItems}
												addedItems={addedItems}
												selectedItemList={this.state.selectedItems}
												isSharing={false}
												onDeleteItem={this.onItemDelete}
												onItemSelect={this.onItemSelect}
												onItemRemove={this.onItemRemove}
												feature={feature}
											/>
										</>
									)}
								</DropdownSelectorStyled>
							)}

							<SharingPanelStyled $isGroupEditMode={true}>
								{addedItems.length === 0 ? (
									<p className="noItem">No item in the list.</p>
								) : (
									<>
										<SectionTitle>{`${StringUtils.capitalize(type)}s`}</SectionTitle>

										<GroupShareSelectorV5
											items={addedItems}
											addedItems={addedItems}
											selectedItemList={this.state.selectedItems}
											isSharing={false}
											onDeleteItem={this.onItemDelete}
											onItemSelect={this.onItemSelect}
											onItemRemove={this.onItemRemove}
											feature={feature}
										/>
									</>
								)}
							</SharingPanelStyled>
						</AddItemStyled>
					</PopupV5>
				)}

				<div className="vbox flex_1 overflowYAuto">
					{addedItems.length === 0 ? (
						<div className="buttonContainer">
							<ButtonV5
								onClick={onAddUserClick}
								label={`Add ${StringUtils.capitalize(type)}`}
								className="button"
							/>
						</div>
					) : (
						<GroupShareSelectorV5
							items={addedItems}
							addedItems={addedItems}
							selectedItemList={this.state.selectedItems}
							isSharing={false}
							onDeleteItem={this.onItemDelete}
							onItemSelect={this.onItemSelect}
							onItemRemove={this.onItemRemove}
							feature={feature}
						/>
					)}
				</div>
			</SearchAddAndListItemsStyled>
		);
	}
}
