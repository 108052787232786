// xmp_metadata:
// http://ns.google.com/photos/1.0/panorama/:
// GPano:CroppedAreaImageHeightPixels: 640
// GPano:CroppedAreaImageWidthPixels: 960
// GPano:CroppedAreaLeftPixels: 1187
// GPano:CroppedAreaTopPixels: 514
// GPano:FullPanoHeightPixels: 1667
// GPano:FullPanoWidthPixels: 3334
// GPano:InitialViewHeadingDegrees: 0
// GPano:ProjectionType: "equirectangular"
// GPano:UsePanoramaViewer: true

import type {IPhotoSphereMetaData} from "../../ui/modules/space/spaceeditor/logic3d/elements3d/markups/MarkupPhoto360Utils";
import {FileUtils} from "../../utils/file/FileUtils";
import {MathUtils} from "../../utils/math/MathUtils";
import type {PhotoSphere} from "../PhotoSphere";
import {PhotoSphereShader} from "../PhotoSphereShader";

const XMPMetaDataKeys: (keyof IXMPMetaData)[] = [
	"GPano:CroppedAreaImageHeightPixels",
	"GPano:CroppedAreaImageWidthPixels",
	"GPano:CroppedAreaLeftPixels",
	"GPano:CroppedAreaTopPixels",
	"GPano:FullPanoHeightPixels",
	"GPano:FullPanoWidthPixels",
	"GPano:InitialViewHeadingDegrees",
];

interface IXMPMetaData {
	"GPano:CroppedAreaImageHeightPixels"?: number;
	"GPano:CroppedAreaImageWidthPixels"?: number;
	"GPano:CroppedAreaLeftPixels"?: number;
	"GPano:CroppedAreaTopPixels"?: number;
	"GPano:FullPanoHeightPixels"?: number;
	"GPano:FullPanoWidthPixels"?: number;
	"GPano:InitialViewHeadingDegrees"?: number;
}

export class ImageUtils {
	private static _canvas: HTMLCanvasElement = document.createElement("canvas");
	private static _ctx: CanvasRenderingContext2D = ImageUtils._canvas.getContext("2d");
	private static _cache: {[id: string]: Promise<HTMLImageElement>} = {};

	private static worker = ImageUtils.createWorker(() => {
		self.addEventListener("message", (message: MessageEvent) => {
			const src = message.data.url;
			const maxTextureSize = message.data.maxTextureSize;
			const android = message.data.android;

			function processImageBitmap(bitmap: ImageBitmap) {
				const largestSize = Math.max(bitmap.width, bitmap.height);
				if (maxTextureSize < largestSize) {
					// is resize needed
					const textureSize = Math.min(largestSize, maxTextureSize);
					const ratio = bitmap.width / bitmap.height;
					const newSize = {width: 1, height: 1};

					if (ratio >= 1) {
						// width > height
						newSize.width = textureSize;
						newSize.height = newSize.width / ratio;
					} else {
						newSize.height = textureSize;
						newSize.width = newSize.width * ratio;
					}

					// Needed, because for some reason, typescript definitions don't have the optional
					// "options" parameter for "createImageBitmap".
					// See https://github.com/microsoft/TypeScript/issues/35545
					createImageBitmap(bitmap, 0, 0, bitmap.width, bitmap.height, {resizeWidth: newSize.width, resizeHeight: newSize.height}).then(
						(resizedBitmap) => {
							(self as any).postMessage({progress: 1, src: src, bitmap: resizedBitmap});
						},
					);
				} else {
					(self as any).postMessage({progress: 1, src: src, bitmap: bitmap});
				}
			}

			if (android) {
				console.log(src);
				const xhr = new XMLHttpRequest();
				xhr.open("GET", src);
				xhr.responseType = "blob";
				xhr.onload = () => {
					createImageBitmap(xhr.response).then(processImageBitmap);
				};
				xhr.onprogress = (event: ProgressEvent) => {
					if (event.lengthComputable) {
						(self as any).postMessage({
							progress: event.loaded / event.total,
							src: src,
						});
					}
				};
				xhr.send();
			} else {
				fetch(src, {mode: "cors"}).then((response) => {
					response.blob().then((blob) => {
						createImageBitmap(blob).then(processImageBitmap);
					});
				});
			}
		});
	});

	private static createWorker(f: () => void) {
		// Convert the function to string.
		const blob = new Blob([`(${f})()`], {type: "application/javascript"});

		return new window.Worker(URL.createObjectURL(blob));
	}

	// https://stackoverflow.com/questions/52959839/convert-imagebitmap-to-blob
	public static imageToBlob(img: ImageBitmap | HTMLImageElement) {
		return new Promise<Blob>((resolve, reject) => {
			// create a canvas
			const canvas = document.createElement("canvas");
			// resize it to the size of our ImageBitmap
			canvas.width = img.width;
			canvas.height = img.height;
			// try to get a bitmaprenderer context

			if (img instanceof ImageBitmap) {
				const ctx = canvas.getContext("bitmaprenderer");
				if (ctx) {
					ctx.transferFromImageBitmap(img);
				} else {
					canvas.getContext("2d").drawImage(img, 0, 0);
				}
			} else {
				canvas.getContext("2d").drawImage(img, 0, 0);
			}
			// get it back as a Blob
			canvas.toBlob(resolve);
		});
	}

	public static getImage(
		url: string,
		maxTextureSize: number,
		originalURL?: string,
		onProgress?: (url: string, ratio: number) => void,
	): Promise<Exclude<TexImageSource, VideoFrame>> {
		if (typeof createImageBitmap !== "undefined") {
			return new Promise<ImageBitmap>((resolve, reject) => {
				function handler(e: MessageEvent<any>) {
					if (e.data.src === url) {
						if (e.data.bitmap) {
							onProgress?.(originalURL, 1);
							ImageUtils.worker.removeEventListener("message", handler);
							if (e.data.error) {
								reject(e.data.error);
							}
							resolve(e.data.bitmap);
						} else {
							onProgress?.(originalURL, e.data.progress);
						}
					}
				}
				ImageUtils.worker.addEventListener("message", handler);
				ImageUtils.worker.postMessage({
					url: url,
					maxTextureSize: maxTextureSize,
				});
			});
		} else {
			return new Promise<HTMLImageElement>(async (resolve, reject) => {
				const originalImage = await ImageUtils.getImageElement(url);
				const largestSize = Math.max(originalImage.width, originalImage.height);

				if (maxTextureSize < largestSize) {
					// is resize needed
					const textureSize = Math.min(largestSize, maxTextureSize);
					const ratio = originalImage.width / originalImage.height;
					const newSize = {width: 1, height: 1};

					if (ratio >= 1) {
						// width > height
						newSize.width = textureSize;
						newSize.height = newSize.width / ratio;
					} else {
						newSize.height = textureSize;
						newSize.width = newSize.width * ratio;
					}

					ImageUtils._canvas.width = newSize.width;
					ImageUtils._canvas.height = newSize.height;
					ImageUtils._ctx.drawImage(originalImage, 0, 0, newSize.width, newSize.height);
					ImageUtils._canvas.toBlob(async (blob: Blob) => {
						const resizedImage = await ImageUtils.getImageElement(URL.createObjectURL(blob));
						resolve(resizedImage);
					});
				} else {
					resolve(originalImage);
				}
			});
		}
	}

	/**
	 * Same as getImageElement, only it's not using cache. Needed if we use the same image in multiple places (bottom row thumbnails + hotspot thumbnails for example)
	 */
	public static getImageElement(url: string) {
		return new Promise<HTMLImageElement>((resolve, reject) => {
			const img = document.createElement("img");
			img.setAttribute("crossorigin", "anonymous");
			img.onload = () => {
				resolve(img);
			};
			img.onerror = () => {
				reject(img);
			};
			img.src = url;
		});
	}

	public static getCachedImageElement(url: string) {
		if (!ImageUtils._cache[url]) {
			ImageUtils._cache[url] = this.getImageElement(url);
		}

		return ImageUtils._cache[url];
	}

	private static async getXMPMetaDataFromImageFile(file: File) {
		const fileAsText = await FileUtils.readAsText(file);

		const xmpMetaData: IXMPMetaData = {};
		for (const key of XMPMetaDataKeys) {
			xmpMetaData[key] = parseFloat(FileUtils.getValueFromTextByKey(fileAsText, key));
		}

		return xmpMetaData;
	}

	public static async getMetaDataFromFile(file: File): Promise<IPhotoSphereMetaData> {
		// UV space
		const viewBox = PhotoSphereShader.defaultViewBox;
		const imageUrl = URL.createObjectURL(file);
		const image = await this.getCachedImageElement(imageUrl);
		const metadata = await this.getXMPMetaDataFromImageFile(file);

		// There might be a case when the heading angle is set, but nothing else is present within this object, so we need to check another property
		if (!metadata["GPano:FullPanoWidthPixels"]) {
			const differenceY = image.width / 2 - image.height;
			const fullHeight = image.height + differenceY;
			if (differenceY > 1) {
				const offsetY = differenceY / 2;
				viewBox[1] = offsetY / fullHeight;
				viewBox[3] = (fullHeight - offsetY) / fullHeight;
			}
		} else {
			const fullPanoWidth = metadata["GPano:FullPanoWidthPixels"];
			const fullPanoHeight = metadata["GPano:FullPanoHeightPixels"];
			const cropLeft = metadata["GPano:CroppedAreaLeftPixels"];
			const cropTop = metadata["GPano:CroppedAreaTopPixels"];
			const originalImageWidth = metadata["GPano:CroppedAreaImageWidthPixels"];
			const originalImageHeight = metadata["GPano:CroppedAreaImageHeightPixels"];

			if (fullPanoWidth !== originalImageWidth || fullPanoHeight !== originalImageHeight) {
				viewBox[0] = cropLeft / fullPanoWidth;
				viewBox[1] = (fullPanoHeight - originalImageHeight - cropTop) / fullPanoHeight;
				viewBox[2] = (cropLeft + originalImageWidth) / fullPanoWidth;
				viewBox[3] = (fullPanoHeight - cropTop) / fullPanoHeight;
			}
		}

		const headingAngleInDegrees = (metadata["GPano:InitialViewHeadingDegrees"] + 180) % 360;

		const headingAngle = MathUtils.isValidNumber(headingAngleInDegrees) ? MathUtils.DEG2RAD * headingAngleInDegrees : 0;

		return {
			viewBox: viewBox,
			headingAngle: headingAngle,
			originalWidth: image.width,
			originalHeight: image.height,
		};
	}

	public static async getImageForTexture(
		url: string,
		photoSphere: PhotoSphere,
		maxTextureSize: number,
		originalURL?: string,
		onProgress?: (url: string, ratio: number) => void,
	) {
		const textureData = await ImageUtils.getImage(url, maxTextureSize, originalURL, onProgress);

		return {
			textureData: textureData,
			viewBox: photoSphere.viewBox,
			photoSphere: photoSphere,
		};
	}
}
