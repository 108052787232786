import styled from "styled-components";
import React, {useEffect} from "react";
import {SVGIcon} from "../../widgets/button/SVGIcon";
import {colorPalette} from "../../5.0/styles/colorPalette";
import {fontSize, fontWeight, radius} from "../../5.0/styles/styles";
import {useAppStore} from "../../../StateManager";

export const SSOLoginFail = () => {
	const {
		app: {navigation},
	} = useAppStore((state) => state.appState);
	const userMismatch = window.location.href.includes("#auth/sso-login-failed?isSuccess=false&message=email_mismatch");

	useEffect(() => {
		const timer = setTimeout(() => {
			navigation.goToLogin();
		}, 5000);
		return () => clearTimeout(timer);
	}, [navigation]);

	const handleGoToLogin = (event: React.MouseEvent) => {
		event.preventDefault();
		navigation.goToLogin();
	};

	return (
		<SSOLoginFailContainer>
			<SSOLoginFailContent>
				<SVGIcon
					classNames="xyicon-logo"
					icon="xyiconlogo"
				/>
				{userMismatch ? (
					<>
						<h4>Email Mismatch</h4>
						<span>Your SSO login email does not match your Xyicon account email.</span>
						<button
							className="goToLoginButton"
							onClick={handleGoToLogin}
						>
							Go to login page
						</button>
					</>
				) : (
					<>
						<h4>SSO is not available for this email</h4>
						<span>
							Redirecting to Xyicon Login...
							<button
								className="goToLoginButton"
								onClick={handleGoToLogin}
							>
								Click here
							</button>{" "}
							if not redirected.
						</span>
					</>
				)}
			</SSOLoginFailContent>
		</SSOLoginFailContainer>
	);
};

const SSOLoginFailContainer = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${colorPalette.primary.c450PrimaryLight};
`;
const SSOLoginFailContent = styled.div`
	height: 306px;
	width: 619px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: ${colorPalette.white};
	border-radius: ${radius.xl};
	padding: 80px;
	gap: 10px;

	h4 {
		color: #37474f;
		font-weight: ${fontWeight.semiBold};
	}

	a {
		color: ${colorPalette.primary.c500Primary};
		text-decoration: underline;
		cursor: pointer;
	}

	.xyicon-logo {
		width: 150px;
		height: 50px;
	}

	.goToLoginButton {
		font-size: ${fontSize.md};
		font-weight: ${fontWeight.normal};
		font-family: "Roboto";
		color: #3495f0;
		background-color: transparent;
		border: none;

		&:hover {
			cursor: pointer;
		}
	}

	span {
		color: #727272;
		font-size: ${fontSize.md};
	}
`;
