import * as React from "react";
import type {PortfolioGroup} from "../../../../../data/models/PortfolioGroup";
import {ToggleContainerV5} from "../../../widgets/ToggleContainerV5/ToggleContainerV5";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import SearchPlusIcon from "../../../icons/search-plus.svg?react";
import {AddGroupItemsV5} from "../AddGroupItemsV5";
import {ItemsGroupStyled} from "../ItemGroup.styled";

interface IPortfolioGroupPortfoliosProps {
	readonly portfolioGroup: PortfolioGroup;
}

export const PortfolioGroupPortfoliosV5 = (props: IPortfolioGroupPortfoliosProps) => {
	const {portfolioGroup} = props;
	const [isPopupOpen, setIsPopupOpen] = React.useState<boolean>(false);
	const parentRef = React.useRef<HTMLDivElement>();

	const toggleAddPopup = () => {
		setIsPopupOpen((prevState) => !prevState);
	};

	return (
		<ItemsGroupStyled>
			<hr className="line" />
			<ToggleContainerV5
				title="Portfolios in this group"
				open={true}
				noPadding={true}
				className="toggleContainer"
			>
				<IconButtonV5
					IconComponent={SearchPlusIcon}
					onClick={toggleAddPopup}
					divRef={parentRef}
					className="addButton"
				/>
				<AddGroupItemsV5
					isPopupOpen={isPopupOpen}
					parentRef={parentRef}
					onClose={toggleAddPopup}
					onAddUserClick={toggleAddPopup}
					type="Portfolio"
					portfolioGroup={portfolioGroup}
				/>
			</ToggleContainerV5>
		</ItemsGroupStyled>
	);
};
