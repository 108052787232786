import type {UIVersion} from "../../data/state/AppState";
import {ConfirmWindowV5} from "../../ui/5.0/popup/ConfirmWindowV5";
import {PopupWindowV5ClassName} from "../../ui/5.0/popup/PopupWindowV5";
import {ConfirmWindow} from "../../ui/modules/abstract/popups/ConfirmWindow";
import {PopupWindowClassName} from "../../ui/modules/abstract/popups/PopupWindow";
import {Constants} from "../../ui/modules/space/spaceeditor/logic3d/Constants";
import {FileUtils} from "../file/FileUtils";
import {TimeUtils} from "../TimeUtils";
import {ImageUtils} from "./ImageUtils";

export class WebCamUtils {
	public static readonly onWebCamClick = async (currentUIVersion: UIVersion): Promise<{base64: string; fileName: string; extension: string}> => {
		const ConfirmModal = currentUIVersion === "5.0" ? ConfirmWindowV5 : ConfirmWindow;
		const popupWindowClassName = currentUIVersion === "5.0" ? PopupWindowV5ClassName : PopupWindowClassName;
		const extension: "webp" | "jpg" = "webp";
		let _stream: MediaStream | null = null;

		const boxShadow = "0px 4px 8px 0px rgba(0, 0, 0, 0.5)";
		const classNameForDisabledButtons = `PopupDisabledButtons`;
		const popupContent = document.createElement("div");
		const videoSpanElement = document.createElement("span");
		const videoElement = document.createElement("video");
		videoElement.style.display = "none";
		videoElement.style.margin = "10px 0";
		videoElement.style.boxShadow = boxShadow;
		const selectElement = document.createElement("select");
		videoSpanElement.textContent = "Loading...";
		const timerBoxElement = document.createElement("div");
		timerBoxElement.classList.add("hbox");
		timerBoxElement.classList.add("alignCenter");
		const timerLabelElement = document.createElement("span");
		timerLabelElement.textContent = "Timer (seconds): ";
		const timerInputElement = document.createElement("input");
		timerInputElement.type = "number";
		timerInputElement.value = "0";
		timerInputElement.min = "0";
		timerBoxElement.appendChild(timerLabelElement);
		timerBoxElement.appendChild(timerInputElement);
		popupContent.appendChild(selectElement);
		popupContent.appendChild(videoSpanElement);
		popupContent.appendChild(videoElement);
		popupContent.appendChild(timerBoxElement);
		popupContent.style.margin = "0 10px";
		popupContent.style.display = "flex";
		popupContent.style.flexDirection = "column";
		popupContent.style.gap = "8px";

		const fillSelectElement = async () => {
			const devices = await window.navigator.mediaDevices.enumerateDevices();
			selectElement.options.length = 0;
			for (const device of devices) {
				if (device.kind === "videoinput") {
					const optionElement = document.createElement("option");
					optionElement.label = device.label;
					optionElement.value = device.deviceId;
					selectElement.appendChild(optionElement);
				}
			}
		};
		await fillSelectElement();

		const stopTracks = () => {
			const tracks = _stream?.getTracks() ?? [];
			for (const track of tracks) {
				track.stop();
			}
		};

		const onDeviceSelect = () => {
			const selectedOption = selectElement.options[selectElement.selectedIndex ?? 0];
			stopTracks();

			const onLoadedMetaData = async () => {
				if (selectElement.options.length === 0 || (selectElement.options.length === 1 && !selectElement.options[0].value)) {
					await fillSelectElement();
				}
				const popupElement = document.querySelector(`.${popupWindowClassName}`) as HTMLElement;
				popupElement.style.width = "initial";
				popupElement.classList.remove(classNameForDisabledButtons);
				videoElement.style.display = "";
				videoElement.play();
				const {videoWidth, videoHeight} = videoElement;
				const aspectRatio = videoWidth / videoHeight;
				const visualWidth = 480;
				const visualHeight = visualWidth / aspectRatio;
				videoElement.style.width = `${visualWidth}px`;
				videoElement.style.height = `${visualHeight}px`;
				videoSpanElement.textContent = `${videoWidth} x ${videoHeight}`;
			};

			videoElement.removeEventListener("loadedmetadata", onLoadedMetaData);

			const options: MediaTrackConstraints = {};

			if (!Constants.iOS) {
				options.width = {
					ideal: Number.MAX_SAFE_INTEGER,
				};

				options.height = {
					ideal: Number.MAX_SAFE_INTEGER,
				};
			}

			window.navigator.mediaDevices
				.getUserMedia({
					video: {deviceId: selectedOption.value, ...options},
					audio: false,
				})
				.then((stream: MediaStream) => {
					_stream = stream;
					videoElement.srcObject = stream;
					videoElement.addEventListener("loadedmetadata", onLoadedMetaData);
				});
		};

		selectElement.onchange = onDeviceSelect;
		onDeviceSelect();

		const isCaptureConfirmed = await ConfirmModal.open("", "Take Photo", {
			additionalElements: popupContent,
			cancel: "Cancel",
			ok: "Capture",
			className: classNameForDisabledButtons,
		});

		if (isCaptureConfirmed) {
			const timeOutNumber = parseInt(timerInputElement.value);
			if (!isNaN(timeOutNumber) && timeOutNumber > 0) {
				const popupBackdropElement = document.createElement("div");
				popupBackdropElement.classList.add("popupBackdrop");
				popupBackdropElement.appendChild(videoElement);
				const secsLeftElement = document.createElement("div");
				secsLeftElement.style.position = "absolute";
				secsLeftElement.style.fontSize = "128px";
				secsLeftElement.style.color = "#FFFFFFAA";
				secsLeftElement.style.textShadow = "4px 4px 2px #00000033";
				secsLeftElement.style.left = "50%";
				secsLeftElement.style.top = "50%";
				secsLeftElement.style.transform = "translate(-50%, -50%)";
				popupBackdropElement.appendChild(secsLeftElement);
				document.body.appendChild(popupBackdropElement);

				for (let i = 0; i < timeOutNumber; ++i) {
					const secsLeft = timeOutNumber - i;
					secsLeftElement.textContent = `${secsLeft}`;
					await TimeUtils.wait(1000);
				}
				popupBackdropElement.remove();
			}
			ImageUtils.canvas.width = videoElement.videoWidth;
			ImageUtils.canvas.height = videoElement.videoHeight;
			ImageUtils.removeBlackBorders(ImageUtils.ctx);
			ImageUtils.ctx.drawImage(videoElement, 0, 0);
			stopTracks();
			let blobUrl: string = "";
			const format: "webp" | "jpeg" = (extension as string) === "jpg" ? "jpeg" : "webp";
			const capturedImageSrc = ImageUtils.canvas.toDataURL(`image/${format}`, 0.78);
			const imageContainer = document.createElement("div");
			const imageSpanElement = document.createElement("span");
			const fileNameInputElement = document.createElement("input");
			fileNameInputElement.value = new Date().toLocaleString().replaceAll(", ", "_").replaceAll(" ", "_");
			imageContainer.style.margin = popupContent.style.margin;
			imageContainer.style.display = "flex";
			imageContainer.style.flexDirection = "column";
			imageContainer.style.gap = "8px";
			imageSpanElement.textContent = "Loading...";
			imageContainer.appendChild(imageSpanElement);
			const imageElement = document.createElement("img");
			imageElement.onload = () => {
				const popupElement = document.querySelector(`.${popupWindowClassName}`) as HTMLElement;
				popupElement.style.width = "initial";
				popupElement.classList.remove(classNameForDisabledButtons);
				imageElement.style.width = videoElement.style.width;
				imageElement.style.height = videoElement.style.height;
				imageElement.style.boxShadow = boxShadow;
				imageElement.style.margin = videoElement.style.margin;
				const fileSizeEstimate = Math.floor(capturedImageSrc.length * 0.75);
				imageSpanElement.textContent = `${imageElement.width} x ${imageElement.height}, ${FileUtils.getSizeLabel(fileSizeEstimate)}`;
				imageContainer.appendChild(imageElement);
				const fileNameLabel = document.createElement("label");
				fileNameLabel.textContent = "File name:";
				imageContainer.appendChild(fileNameLabel);
				imageContainer.appendChild(fileNameInputElement);
				imageElement.style.cursor = "pointer";
				imageElement.onclick = async () => {
					const blob = await FileUtils.canvasToBlob(ImageUtils.canvas);
					blobUrl = URL.createObjectURL(blob);
					window.open(blobUrl, "_blank");
				};
			};
			imageElement.src = capturedImageSrc;

			const isUploadConfirmed = await ConfirmModal.open("", "Upload image", {
				additionalElements: imageContainer,
				ok: "Upload",
				cancel: "Cancel",
				className: classNameForDisabledButtons,
			});
			URL.revokeObjectURL(blobUrl);
			if (isUploadConfirmed) {
				return {
					base64: capturedImageSrc,
					fileName: fileNameInputElement.value,
					extension,
				};
			} else {
				stopTracks();

				return this.onWebCamClick(currentUIVersion);
			}
		} else {
			stopTracks();
		}

		return {base64: "", fileName: "", extension};
	};
}
