import styled from "styled-components";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {MathUtils} from "../../../utils/math/MathUtils";
import type {IGeoLocation} from "../../widgets/input/clicktoedit/datatypes/geolocation/GeoLocationLabel";
import {baseDistance, radius} from "../styles/styles";
import type {IInputPropsV5} from "../../widgets/input/clicktoedit/InputUtils";
import {TextInputStyled, TextInputV5} from "./datatypes/TextInputV5";

export const GeoLocationInputV5 = (props: IInputPropsV5<any, IGeoLocation>) => {
	const getValues = () => {
		let value = props.value;

		if (props.inline) {
			const valArr = (value as unknown as string).split(", ");

			return {
				long: String(valArr[1]),
				lat: String(valArr[0]),
			};
		}

		if (MathUtils.isValidNumber(value?.long) && MathUtils.isValidNumber(value?.lat)) {
			return {
				long: String(value.long),
				lat: String(value.lat),
			};
		}
		return {
			lat: "",
			long: "",
		};
	};

	const onLatChange = (lat: string) => {
		lat = StringUtils.trim(lat);
		const {long} = getValues();

		const value = {
			long: Number(long),
			lat: Number(lat),
		};

		props.inline ? props.onInput(value) : props.onChange(value);
	};

	const onLongChange = (long: string) => {
		long = StringUtils.trim(long);
		const {lat} = getValues();

		const value = {
			long: Number(long),
			lat: Number(lat),
		};

		props.inline ? props.onInput(value) : props.onChange(value);
	};

	const {lat, long} = getValues();

	return (
		<GeoLocationInputStyled
			className="GeoLocationInput field-input-container"
			$inline={props.inline}
		>
			<TextInputV5
				value={lat}
				onInput={onLatChange}
				autoFocus={true}
				className="GeoLat"
			/>
			<CommaStyled>,</CommaStyled>
			<TextInputV5
				value={long}
				onInput={onLongChange}
				className="GeoLat"
			/>
		</GeoLocationInputStyled>
	);
};

const GeoLocationInputStyled = styled.div<{$inline?: boolean; $width?: number}>`
	display: flex;
	align-items: center;
	height: ${(props) => (props.$inline ? "29px" : "32px")};
	border-radius: ${radius.sm};

	> div {
		align-items: center;

		&:first-child {
			margin-bottom: 2px;
		}

		> span {
			width: 120px;
		}

		> input {
			width: 100%;
		}
	}

	${TextInputStyled} {
		&.GeoLat {
			max-width: 150px;
			height: ${(props) => (props.$inline ? "29px" : "24px")};
			margin: ${baseDistance.xs} ${baseDistance.sm};
		}
	}
`;

const CommaStyled = styled.span`
	height: 16px;
	margin: ${baseDistance.sm} 0;
`;
