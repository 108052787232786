import type {RefObject} from "react";
import type {Report} from "../../../../../data/models/Report";
import {PopupV5} from "../../../popup/PopupV5";
import {CreatePopupFieldStyled} from "../../../popup/CreatePopupField.styled";
import {ReportWizardV5} from "./wizard/ReportWizardV5";

interface IReportPanelProps {
	readonly onRunReport: (report: Report) => void;
	readonly report: Report;
	readonly onClose: () => void;
	readonly parentRef?: RefObject<HTMLDivElement>;
}

export const ReportPanelV5 = (props: IReportPanelProps) => {
	const getTitle = () => {
		const {report} = props;

		if (report.id) {
			return `Edit report - ${report.name}`;
		} else {
			if (report.isDuplicate) {
				return "Duplicate Report";
			} else {
				return "Create Report";
			}
		}
	};

	return (
		<PopupV5
			onClose={props.onClose}
			label={getTitle()}
			className="ReportCreation"
			freezeRoot={true}
			centerOnScreen={true}
			width="60%"
			height="80vh"
			parentRef={props.parentRef?.current}
			closeOnClickOutside={false}
		>
			<CreatePopupFieldStyled>
				<ReportWizardV5
					report={props.report}
					onClose={props.onClose}
					onRunReport={props.onRunReport}
				/>
			</CreatePopupFieldStyled>
		</PopupV5>
	);
};
