import * as React from "react";
import {inject, observer} from "mobx-react";
import {observable, makeObservable} from "mobx";
import styled from "styled-components";
import type {IModulePanelProps} from "../../ModuleViewV5";
import type {IDefaultGlyphData, IIconConfig} from "../../../../modules/catalog/create/CatalogTypes";
import {getDefaultInsertionInfo} from "../../../../modules/catalog/create/CatalogTypes";
import type {Catalog, ICatalogSettings} from "../../../../../data/models/Catalog";
import type {Type} from "../../../../../data/models/Type";
import {LibraryModel, LibraryModelType} from "../../../../../data/models/LibraryModel";
import type {
	Color,
	CreateXyiconCatalogRequest,
	Dimensions,
	PortTemplateDto,
	XyiconCatalogSettingsModel,
	XyiconCatalogUpdateSettingsDto,
} from "../../../../../generated/api/base";
import {CatalogIconType, XyiconFeature} from "../../../../../generated/api/base";
import {ColorUtils} from "../../../../../utils/ColorUtils";
import {Constants} from "../../../../modules/space/spaceeditor/logic3d/Constants";
import type {PortTemplateEditorV5} from "../../../details/PortTemplateEditorV5";
import {TimeUtils} from "../../../../../utils/TimeUtils";
import {ObjectUtils} from "../../../../../utils/data/ObjectUtils";
import type {Xyicon} from "../../../../../data/models/Xyicon";
import {XHRLoader} from "../../../../../utils/loader/XHRLoader";
import {ImageUploadPreprocessor} from "../../../../../utils/image/ImageUploadPreprocessor";
import {NotificationType} from "../../../../notification/Notification";
import {notify} from "../../../../../utils/Notify";
import {CatalogGlyphs} from "../../../../modules/catalog/create/CatalogGlyphs";
import {CreateButtonStyled, NameStyled, PopupBodyStyled, PopupHeaderStyled, PopupV5} from "../../../popup/PopupV5";
import {baseDistance, fontSize, fontWeight} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import type {ShapeType} from "../../../modules/catalog/CatalogCreatePopupV5";
import {MeshTweakerV5} from "./MeshTweakerV5";
import {CatalogItemEditorV5} from "./CatalogItemEditorV5";
import {GeometrySelectorV5} from "./GeometrySelectorV5";

interface ICatalogItemPanelProps extends IModulePanelProps {
	readonly iconConfig?: IIconConfig;
	readonly catalog?: Catalog;
	readonly mode: "create" | "edit";
	readonly model: string;
	readonly type: Type;
	readonly loading: boolean;
	readonly shape?: ShapeType;
	readonly step?: StepType;
	readonly style?: React.CSSProperties;
}

type StepType = "Item properties" | "Select model";

interface ICatalogItemPanelState {
	model: string;
	selectedType: Type;
	thumbnail: string;
	fieldData: {[refId: string]: any};
	iconConfig: IIconConfig;
	libraryModel: LibraryModel;
	isSaving: boolean;
	selectedGeometry: ShapeType;
	selectedStep: StepType;
}

@inject("appState")
@inject("transport")
@observer
export class CatalogItemPanelV5 extends React.PureComponent<ICatalogItemPanelProps, ICatalogItemPanelState> {
	public static readonly defaultProps: Partial<ICatalogItemPanelProps> = {
		mode: "create",
	};

	private _defaultBodyColor: Color = {
		hex: ColorUtils.getHexStringFromNumber(Constants.DEFAULT_3D_COLOR),
		transparency: 0,
	};

	private _standard: LibraryModel;
	private get standard() {
		if (!this._standard) {
			this._standard = new LibraryModel(
				{
					fileName: "",
					type: LibraryModelType.STANDARD,
					keywords: ["tile", "standard", "default"],
					libraryModelID: "",
					thumbnail: "src/assets/images/spaceviewer/standard_tile.png",
				},
				this.props.appState,
			);
		}

		return this._standard;
	}

	@observable
	private _catalogItemEditorRef = React.createRef<CatalogItemEditorV5>();
	private _meshTweakerRef = React.createRef<MeshTweakerV5>();
	private _portTemplateEditorRef = React.createRef<PortTemplateEditorV5>();
	private _portTemplate: PortTemplateDto[] = [];

	constructor(props: ICatalogItemPanelProps) {
		super(props);
		makeObservable(this);
		const catalog = this.props.catalog;
		const model = this.actions.getFeatureItemById<LibraryModel>(this.libraryModelId, XyiconFeature.LibraryModel) || this.firstLibraryItem;

		if (model) {
			this.onLibraryModelSelect(model);
		}

		this._portTemplate = catalog?.portTemplate || [];

		this.state = {
			model: props.model ?? this.getDefaultModelName(catalog),
			selectedType: props.type ?? catalog?.type,
			thumbnail: catalog?.thumbnail,
			fieldData: catalog?.fieldData || {},
			iconConfig: this.props.iconConfig,
			isSaving: false,
			libraryModel: model,
			selectedStep: props.step ?? "Select model",
			selectedGeometry: props.shape,
		};
	}

	private getDefaultModelName(catalog: Catalog | undefined) {
		let defaultModelName = catalog?.model || "";
		const isCloneMode = catalog && this.props.mode === "create";

		if (isCloneMode) {
			defaultModelName += " (Duplicate)";
		}

		return defaultModelName;
	}

	private get libraryModelId() {
		return this.props.iconConfig?.modelParameters?.libraryModelID || "";
	}

	private get actions() {
		return this.props.transport.appState.actions;
	}

	private get firstLibraryItem() {
		return this.actions.getList<LibraryModel>(XyiconFeature.LibraryModel)[0];
	}

	private updateFields(catalog: Catalog, fieldData: {[refId: string]: any}) {
		this.props.transport.appState.actions.updateFields([catalog], fieldData);

		catalog.setModel(this.state.model);
		catalog.setFieldData(fieldData);
	}

	private onSaveCatalogItemClick = async () => {
		const {catalog, mode, transport} = this.props;
		const isLocalSaveSuccessful = await this.saveDataFromSteps();

		if (isLocalSaveSuccessful) {
			// wait for this.state to be updated
			await TimeUtils.waitForNextFrame();
			await TimeUtils.waitForNextFrame();

			const {model, thumbnail, selectedType} = this.state;

			if (this.isModelNameValid(model)) {
				this.setState({
					isSaving: true,
				});
				const portTemplate = this._portTemplate;

				const fieldData = {
					...this.state.fieldData,
					model,
				};

				const newestGlyphChildrenColorsMaybe = this._catalogItemEditorRef.current?.state.glyphChildrenColors;

				if (catalog && mode === "edit") {
					if (this.props.iconConfig !== this.state.iconConfig || catalog.thumbnail !== thumbnail) {
						await transport.updateIconConfig(catalog, this.state.iconConfig, thumbnail);
					}
					if (catalog.portTemplate !== portTemplate) {
						await transport.updatePortTemplate(catalog, portTemplate);
					}
					if (
						catalog.model !== model ||
						!ObjectUtils.compare(catalog.fieldData, this.state.fieldData) ||
						!ObjectUtils.compare(fieldData, this.state.fieldData)
					) {
						this.updateFields(catalog, fieldData);
					}
					if (catalog.type !== selectedType) {
						catalog.typeId = selectedType.id;
						transport.updateCatalogType(catalog);
						this.props.appState.actions.getList<Xyicon>(XyiconFeature.Xyicon).forEach((xyicon) => {
							if (xyicon.model === catalog.model) {
								xyicon.typeId = selectedType.id;
							}
						});
					}
					if (newestGlyphChildrenColorsMaybe && !ObjectUtils.compare(catalog.glyphChildrenColors, newestGlyphChildrenColorsMaybe)) {
						catalog.setGlyphChildrenColors(newestGlyphChildrenColorsMaybe);
						const {result, error} = await this.props.transport.requestForOrganization<XyiconCatalogUpdateSettingsDto>({
							url: "xyiconcatalogs/updatesettings",
							method: XHRLoader.METHOD_POST,
							params: {
								updatedXyiconCatalogs: [
									{
										xyiconCatalogID: catalog.id,
										settings: catalog.settings,
									},
								] as XyiconCatalogSettingsModel[],
							},
						});

						if (error) {
							console.warn(error);
						}
					}
					this.setState({
						isSaving: false,
					});
					requestAnimationFrame(() => {
						this.props.onClose();
					});
				} // create
				else {
					const createData: CreateXyiconCatalogRequest = {
						xyiconTypeID: this.state.selectedType.id,
						model: this.state.model,
						generatedIcon: this.state.thumbnail.replace(/\n\s*/g, ""), // remove white-spaces
						iconType: this.state.iconConfig.iconCategory, // on the server it's called iconType, but it's confusing, since we have "xyiconType" as well...
						iconData: {
							defaultIcon: this.state.iconConfig.defaultIcon,
							uploadedIcon: this.state.iconConfig.uploadedIcon,
							modelParameters: this.state.iconConfig.modelParameters,
						},
						portTemplate: portTemplate,
						fieldData: fieldData,
						settings: {
							xyiconFieldSettings: {visibleFields: []},
							catalogFieldSettings: {visibleFields: []},
							glyphChildrenColors: this._catalogItemEditorRef.current?.state.glyphChildrenColors || [],
						} as ICatalogSettings,
					};
					const result = await this.props.transport.services.feature.create<Catalog>(createData, XyiconFeature.XyiconCatalog);

					this.setState({
						isSaving: false,
					});
					requestAnimationFrame(() => {
						this.props.onClose(result?.[0]?.id);
					});
				}
			}
		}
	};

	private getErrorMessage = (newModel: string = this.state.model): string => {
		if (this.isModelNameValid(newModel)) {
			return "";
		} else {
			let errorModel = "";

			if (!newModel) {
				errorModel = "Model cannot be empty!";
			} else {
				errorModel = "Model needs to be unique!";
			}

			return errorModel;
		}
	};

	private onCloseClick = () => {
		this.props.onClose();
	};

	private onSaveIconConfig = (iconConfig: IIconConfig, thumbnail: string) => {
		this.setState({
			thumbnail,
			iconConfig,
			libraryModel: this.firstLibraryItem,
		});
	};

	public override async UNSAFE_componentWillReceiveProps(nextProps: ICatalogItemPanelProps) {
		const catalog = nextProps.catalog;

		if (catalog !== this.props.catalog) {
			const newModelName = this.getDefaultModelName(catalog);

			this._portTemplate = catalog?.portTemplate || [];

			await this.props.transport.services.feature.refreshList(XyiconFeature.LibraryModel);

			this.setState({
				iconConfig: nextProps.iconConfig,
				libraryModel:
					this.actions.getFeatureItemById(nextProps.iconConfig?.modelParameters?.libraryModelID || "", XyiconFeature.LibraryModel) ||
					this.firstLibraryItem,
				model: newModelName,
				thumbnail: catalog?.thumbnail,
				selectedType: catalog?.type,
				fieldData: {...(catalog?.fieldData || this.state.fieldData)},
			});

			// Autofocus model input field
			// TODO V5
			// this._modelInputRef.current?.setState({
			// 	editing     : true,
			// 	editingValue: newModelName
			// });
		}
	}

	private isModelNameValid = (modelName: string) => {
		if (!modelName) {
			return false;
		}

		return this.props.appState.actions.isModelValidForCatalog(modelName, this.props.mode === "edit" ? this.props.catalog : null);
	};

	/**
	 *
	 * @returns if it was successful
	 */
	private async saveDataFromSteps(): Promise<boolean> {
		if (this.state.selectedGeometry === "Default Shape") {
			await this._catalogItemEditorRef.current?.onSaveClick();
		} else {
			const currentMesh = this._meshTweakerRef.current?.currentMesh;

			if (currentMesh) {
				const thumbnail = await ImageUploadPreprocessor.getThumbnailFromMesh(currentMesh, "side");

				this.setState({thumbnail});
			} else {
				notify(this.props.appState.app.notificationContainer, {
					title: "Error while generating the thumbnail",
					type: NotificationType.Error,
					lifeTime: Infinity,
				});
				return false; // don't let the user go to the next step
			}
		}

		this._portTemplateEditorRef.current?.onSaveClick();

		return true;
	}

	private onLibraryModelSelect = async (newActiveLibraryModel: LibraryModel) => {
		if (newActiveLibraryModel.geometryType === LibraryModelType.STANDARD) {
			await this.updateDefaultIconConfigAndThumbnail();
		} else {
			const size = await newActiveLibraryModel.getSizeInMeters();

			this.setState({
				iconConfig: {
					iconCategory: CatalogIconType.ModelParameter,
					defaultIcon: null,
					uploadedIcon: null,
					modelParameters: {
						libraryModelID: newActiveLibraryModel.id,
						dimensions: size,
						bodyColor: this.props.iconConfig?.modelParameters?.bodyColor ?? this._defaultBodyColor,
						customParameters: "",
						insertionInfo: getDefaultInsertionInfo(),
					},
					innerPart: null,
				},
				libraryModel: newActiveLibraryModel,
			});
		}
	};

	private onBodyColorChange = (newColor: Color) => {
		this.setState({
			iconConfig: {
				iconCategory: CatalogIconType.ModelParameter,
				defaultIcon: null,
				uploadedIcon: null,
				modelParameters: {
					...this.state.iconConfig.modelParameters,
					bodyColor: newColor,
					insertionInfo: getDefaultInsertionInfo(),
				},
				innerPart: null,
			},
		});
	};

	private onDimensionChange = (dimensions: Dimensions) => {
		this.setState({
			iconConfig: {
				iconCategory: CatalogIconType.ModelParameter,
				defaultIcon: null,
				uploadedIcon: null,
				modelParameters: {
					...this.state.iconConfig.modelParameters,
					dimensions,
					insertionInfo: getDefaultInsertionInfo(),
				},
				innerPart: null,
			},
		});
	};

	private forceUpdateArrow = () => {
		this.forceUpdate();
	};

	private getDefaultBackgroundColor(selectedType: Type = this.state.selectedType) {
		const selectedTypeColor = selectedType?.settings?.color;

		return selectedTypeColor || CatalogItemEditorV5.defaultState.glyphBackgroundColor;
	}

	private onPopupButtonClick = () => {
		if (this.state.selectedGeometry === "Custom Shape" && this.state.selectedStep === "Select model") {
			if (this.state.libraryModel.id === this._standard.id) {
				this.setState({selectedGeometry: "Default Shape"});
			} else {
				this.setState({selectedStep: "Item properties"});
			}
		} else {
			this.onSaveCatalogItemClick();
		}
	};

	private onDefaultClick = async () => {
		await this.onLibraryModelSelect(this._standard);
		this.setState({selectedGeometry: "Default Shape", libraryModel: this._standard});
	};

	private onEditShapeClick = () => {
		this.setState({selectedStep: "Select model"});
	};

	private onCustomClick = () => {
		const model = this.firstLibraryItem;

		this.setState({selectedGeometry: "Custom Shape", selectedStep: "Select model", libraryModel: model});
		this.onLibraryModelSelect(model);
	};

	private async updateDefaultIconConfigAndThumbnail(selectedType: Type = this.state.selectedType) {
		if (CatalogGlyphs.defaultGlyphs && CatalogGlyphs.glyphs.length > 1) {
			const backgroundColor = this.getDefaultBackgroundColor(selectedType);
			const backgroundColorRGB = ColorUtils.hex2rgb(backgroundColor.hex, 1 - backgroundColor.transparency) as string;

			const defaultSvgElement = document.createElementNS(Constants.SVG_NAMESPACE, "svg");

			defaultSvgElement.setAttributeNS("http://www.w3.org/2000/xmlns/", "xmlns", Constants.SVG_NAMESPACE);
			defaultSvgElement.setAttributeNS(null, "viewBox", `0 0 ${Constants.CATALOG_SVG_VIEWBOXSIZE} ${Constants.CATALOG_SVG_VIEWBOXSIZE}`);

			const rect = document.createElementNS(Constants.SVG_NAMESPACE, "rect");

			rect.setAttributeNS(null, "x", "0");
			rect.setAttributeNS(null, "y", "0");
			rect.setAttributeNS(null, "width", `${Constants.CATALOG_SVG_VIEWBOXSIZE}`);
			rect.setAttributeNS(null, "height", `${Constants.CATALOG_SVG_VIEWBOXSIZE}`);
			rect.setAttributeNS(null, "rx", `${CatalogItemEditorV5.defaultState.borderRadius}`);
			rect.setAttributeNS(null, "ry", `${CatalogItemEditorV5.defaultState.borderRadius}`);
			rect.setAttributeNS(null, "fill", `${backgroundColorRGB}`);
			rect.setAttributeNS(null, "stroke", "none");

			defaultSvgElement.appendChild(rect);

			const compressedImage = await ImageUploadPreprocessor.createCompressedImageFromSVG(this.props.appState.fonts, defaultSvgElement, true);

			const iconConfig: IIconConfig = {
				defaultIcon: {
					borderRadius: CatalogItemEditorV5.defaultState.borderRadius,
					backgroundColor: backgroundColor,
					iconInfo: {
						defaultImageID: CatalogGlyphs.glyphs[0].defaultImageID,
						color: CatalogItemEditorV5.defaultState.glyphIconColor,
						translate: CatalogItemEditorV5.defaultState.iconTranslate,
						orientation: CatalogItemEditorV5.defaultState.iconOrientation,
						scale: CatalogItemEditorV5.defaultState.scale,
						isFlippedX: CatalogItemEditorV5.defaultState.isFlippedX,
						isFlippedY: CatalogItemEditorV5.defaultState.isFlippedY,
					},
					overlayText: {
						content: CatalogItemEditorV5.defaultState.text,
						fontFamily: CatalogItemEditorV5.defaultState.fontFamily,
						fontColor: CatalogItemEditorV5.defaultState.fontColor,
						fontSize: CatalogItemEditorV5.defaultState.fontSize,
						translate: CatalogItemEditorV5.defaultState.textTranslate,
						orientation: CatalogItemEditorV5.defaultState.textOrientation,
						isBold: CatalogItemEditorV5.defaultState.isBold,
						isItalic: CatalogItemEditorV5.defaultState.isItalic,
						isUnderlined: CatalogItemEditorV5.defaultState.isUnderlined,
					},
				},
				uploadedIcon: null,
				modelParameters: null,
				innerPart: null,
				iconCategory: CatalogIconType.Default,
			};

			this.onSaveIconConfig(iconConfig, compressedImage);
		}
	}

	private getTitle() {
		if (!this.props.loading) {
			if (!this.props.catalog && this.props.mode === "create") {
				return "Create Catalog Item";
			} else if (this.props.catalog && this.props.mode === "edit") {
				return `Edit Catalog Item - ${this.props.catalog.model}`;
			} else if (this.props.catalog && this.props.mode === "create") {
				return `Duplicate Catalog Item - ${this.props.catalog.model}`;
			}
		}

		return "";
	}

	public override async componentDidMount() {
		try {
			await this.props.transport.services.feature.refreshList(XyiconFeature.LibraryModel);
			this.setState({
				libraryModel: this.actions.getFeatureItemById(this.libraryModelId, XyiconFeature.LibraryModel) || this.firstLibraryItem,
			});

			await this.props.transport.services.feature.refreshList(XyiconFeature.LibraryImage);

			if (!CatalogGlyphs.defaultGlyphs) {
				const transport = this.props.transport;

				const {result} = await transport.requestForOrganization({url: "defaultimages/all"});

				const defaultGlyphs: IDefaultGlyphData[] = result.toSorted(
					(a: IDefaultGlyphData, b: IDefaultGlyphData) => a.imageData.length - b.imageData.length,
				);

				CatalogGlyphs.glyphs = [];

				const outerSVGs: string[] = [];

				for (const defaultGlyph of defaultGlyphs) {
					outerSVGs.push(defaultGlyph.imageData);
				}

				for (let i = 0; i < outerSVGs.length; ++i) {
					const outerSVG = outerSVGs[i].trim();
					const glyphData = defaultGlyphs[i];
					const indexOfSVGTagEnd = outerSVG.indexOf(">"); // closing bracket of opening tag <svg...`>`
					const innerSVG = outerSVG.substring(indexOfSVGTagEnd + 1, outerSVG.lastIndexOf("</svg>"));

					CatalogGlyphs.glyphs.push({
						innerPart: innerSVG,
						keywords: glyphData.keywords,
						defaultImageID: glyphData.defaultImageID,
					});
				}

				CatalogGlyphs.defaultGlyphs = defaultGlyphs;

				this._catalogItemEditorRef.current?.initState();
				this.forceUpdate();
			}

			if (!this.state.iconConfig?.defaultIcon && !this.state.iconConfig?.uploadedIcon && !this.state.iconConfig?.modelParameters) {
				await this.updateDefaultIconConfigAndThumbnail();
			}
		} catch (error) {
			console.warn(error);
		}
	}

	public override render() {
		const saveOrSavingLabel = this.state.isSaving ? "Saving..." : "Save";
		const {style = {}} = this.props;
		const {selectedGeometry, selectedStep} = this.state;

		return (
			<CatalogItemPanelPopupStyled
				style={style}
				buttonProps={{
					onClick: this.onPopupButtonClick,
					label: selectedStep === "Select model" && selectedGeometry === "Custom Shape" ? "Properties" : saveOrSavingLabel,
					hideIcon: true,
					disabled: this.state.isSaving,
				}}
				label={this.getTitle()}
				onClose={this.onCloseClick}
				centerOnScreen={true}
				height="792px"
				width="1080px"
				freezeRoot={true}
				isSmallPopup={false}
				closeOnClickOutside={false}
			>
				<EditorBodyStyled>
					{selectedGeometry === "Default Shape" ? (
						<CatalogItemEditorV5
							ref={this._catalogItemEditorRef}
							onSaveClick={this.onSaveIconConfig}
							iconConfig={this.state.iconConfig}
							catalog={this.props.catalog}
							selectedType={this.state.selectedType}
							updateParent={this.forceUpdateArrow}
						/>
					) : selectedStep === "Select model" ? (
						<GeometrySelectorV5
							standard={this.standard}
							setActiveLibraryModel={this.onLibraryModelSelect}
							activeLibraryModel={this.state.libraryModel}
						/>
					) : (
						this.state.iconConfig?.modelParameters && (
							<MeshTweakerV5
								ref={this._meshTweakerRef}
								modelParameters={this.state.iconConfig.modelParameters}
								libraryModel={this.state.libraryModel}
								onColorChange={this.onBodyColorChange}
								onDimensionChange={this.onDimensionChange}
							/>
						)
					)}
					<ChangeButtonStyled>
						{selectedGeometry === "Default Shape" ? (
							<TextStyled onClick={this.onCustomClick}>Change to 3D shape</TextStyled>
						) : selectedStep === "Item properties" ? (
							<TextStyled onClick={this.onEditShapeClick}>Edit shape</TextStyled>
						) : (
							<TextStyled onClick={this.onDefaultClick}>Change to default shape</TextStyled>
						)}
					</ChangeButtonStyled>
				</EditorBodyStyled>
			</CatalogItemPanelPopupStyled>
		);
	}
}

const CatalogItemPanelPopupStyled = styled(PopupV5)`
	${PopupHeaderStyled} {
		padding-bottom: 40px;

		${NameStyled} {
			font-size: 24px;
			font-weight: ${fontWeight.bold};
			line-height: 32px;
			letter-spacing: -0.0025em;
			height: 32px;
		}

		svg {
			width: 24px;
			height: 24px;
		}
	}

	${PopupBodyStyled} {
		gap: 40px;
	}

	${CreateButtonStyled} {
		margin-top: 0;
	}
`;

const EditorBodyStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.md};
`;

const ChangeButtonStyled = styled.div`
	color: ${colorPalette.primary.c500Primary};
	font-weight: ${fontWeight.bold};
	font-size: ${fontSize.lg};
	line-height: 24px;
`;

const TextStyled = styled.span`
	border-bottom: 1px solid ${colorPalette.primary.c500Primary};
	cursor: pointer;
`;
