import * as React from "react";
import type {Scene} from "three";
import {WebGLRenderer, OrthographicCamera, CanvasTexture, Mesh} from "three";
import {observer, inject} from "mobx-react";
import {observe} from "mobx";
import styled from "styled-components";
import type {ISignal} from "../../../utils/signal/ISignal";
import type {AppState} from "../../../data/state/AppState";
import {PointerDetector} from "../../../utils/interaction/PointerDetector";
import type {PointDouble} from "../../../generated/api/base";
import {THREEUtils} from "../../../utils/THREEUtils";
import type {Pointer} from "../../../utils/interaction/Pointer";
import {ColorUtils} from "../../../utils/ColorUtils";
import {KeyboardListener} from "../../../utils/interaction/key/KeyboardListener";
import type {IRenderSignalParam} from "../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {EyeDropperMaterial} from "../../modules/space/spaceeditor/logic3d/materials/EyeDropperMaterial";
import {ReactUtils} from "../../utils/ReactUtils";
import EyeDropperIcon from "../icons/eye-dropper.svg?react";
import {colorPalette} from "../styles/colorPalette";
import {FLEXCENTER, radius} from "../styles/styles";

export interface IPropsForEyeDropper {
	readonly textureNeedsUpdateSignal: ISignal;
	readonly canvas: HTMLCanvasElement; // the canvas that we can pick colors from with this tool
	readonly elementForPointerEvents?: HTMLElement; // the html element that we add the pointer events to. If not given, props.canvas will be assigned
	readonly onActivateStateChanged: (activated: boolean) => void;
}

interface IEyeDropperProps extends IPropsForEyeDropper {
	readonly onColorClick: (newColorHex: string) => void;
	readonly appState?: AppState;
}

interface IEyeDropperState {
	active: boolean;
}

@inject("appState")
@observer
export class EyeDropperV5 extends React.Component<IEyeDropperProps, IEyeDropperState> {
	private static _canvas: HTMLCanvasElement; // Represents the magnifier of the EyeDropper
	private static _ctx: CanvasRenderingContext2D; // Needed to retrieve pixel from center
	private static _cursor: HTMLDivElement;
	private static _renderer: WebGLRenderer;
	private static _scene: Scene;
	private static _camera: OrthographicCamera;

	private _pointerDetector: PointerDetector;
	private _containerSize: PointDouble;
	private _texture: CanvasTexture;
	private _magnifierMesh: Mesh;
	private _elementForPointerEvents: HTMLElement;
	private _defaultCursorStyle: string;
	private _ctx: CanvasRenderingContext2D;

	constructor(props: IEyeDropperProps) {
		super(props);

		this.state = {
			active: false,
		};

		this._containerSize = {
			x: null,
			y: null,
		};

		if (!EyeDropperV5._canvas) {
			EyeDropperV5._canvas = document.createElement("canvas");
			EyeDropperV5._canvas.width = 109;
			EyeDropperV5._canvas.height = 109;
			EyeDropperV5._canvas.classList.add("Magnifier");

			// PreserveDrawingBuffer is not needed in this case:
			// https://stackoverflow.com/questions/30628064/how-to-toggle-preservedrawingbuffer-in-three-js/30647502#30647502
			EyeDropperV5._renderer = new WebGLRenderer({
				canvas: EyeDropperV5._canvas,
				antialias: false,
			});

			EyeDropperV5._scene = THREEUtils.createScene("eyeDropperScene");
			const halfCameraFrustumSize = 11 / 2;

			EyeDropperV5._camera = new OrthographicCamera(
				-halfCameraFrustumSize,
				halfCameraFrustumSize,
				halfCameraFrustumSize,
				-halfCameraFrustumSize,
				0,
				2,
			);
			EyeDropperV5._camera.position.setZ(1);

			EyeDropperV5._cursor = document.createElement("div");
			EyeDropperV5._cursor.classList.add("cursorCircle");

			this.hideMagnifier();

			const canvas2D = document.createElement("canvas");

			canvas2D.width = EyeDropperV5._canvas.width;
			canvas2D.height = EyeDropperV5._canvas.height;
			EyeDropperV5._ctx = canvas2D.getContext("2d");
		}

		this.updateTheme();
	}

	private onPointerMove = (pointer: Pointer) => {
		this.showMagnifier();

		EyeDropperV5._canvas.style.transform = `translateX(${pointer.pageX}px) translateX(-100%) translateY(${pointer.pageY}px) translateY(-100%)`;
		EyeDropperV5._cursor.style.transform = `translateX(${pointer.pageX}px) translateY(${pointer.pageY}px)`;

		EyeDropperV5._camera.position.set(
			Math.round(pointer.localX * window.devicePixelRatio) + 0.5,
			Math.round(this.props.canvas.height - pointer.localY * window.devicePixelRatio) - 0.5,
			0,
		);
		this.renderMagnifier();
	};

	private onPointerUp = async (pointer: Pointer) => {
		const rgb = await this.getColorAtCenter();
		const hex = ColorUtils.rgb2hex(rgb.r, rgb.g, rgb.b, "string") as string;

		this.props.onColorClick(hex);
		this.setActive(false);
	};

	private onPointerLeave = (pointer: Pointer) => {
		this.hideMagnifier();
	};

	private hideMagnifier() {
		EyeDropperV5._canvas.classList.add("hidden");
		EyeDropperV5._cursor.classList.add("hidden");
	}

	private showMagnifier() {
		EyeDropperV5._cursor.classList.remove("hidden");
		EyeDropperV5._canvas.classList.remove("hidden");
	}

	private setActive(active: boolean) {
		if (!this.state.active && active) {
			// has been just activated
			this._pointerDetector.enable();
			this._pointerDetector.signals.move.add(this.onPointerMove, this);
			this._pointerDetector.signals.hoverMove.add(this.onPointerMove, this);
			this._pointerDetector.signals.leave.add(this.onPointerLeave, this);
			this._pointerDetector.signals.up.add(this.onPointerUp, this);
			document.body.appendChild(EyeDropperV5._canvas);
			document.body.appendChild(EyeDropperV5._cursor);
			this._defaultCursorStyle = this._elementForPointerEvents.style.cursor;
			this._elementForPointerEvents.style.cursor = "none";
			this.hideMagnifier();

			this.setState({
				active: true,
			});
			if (this.props.onActivateStateChanged) {
				this.props.onActivateStateChanged(true);
			}
		} else if (this.state.active && !active) {
			// has been just disabled
			this._pointerDetector.disable();
			this._pointerDetector.signals.move.remove(this.onPointerMove, this);
			this._pointerDetector.signals.hoverMove.remove(this.onPointerMove, this);
			this._pointerDetector.signals.leave.remove(this.onPointerLeave, this);
			this._pointerDetector.signals.up.remove(this.onPointerUp, this);
			document.body.removeChild(EyeDropperV5._canvas);
			document.body.removeChild(EyeDropperV5._cursor);
			this._elementForPointerEvents.style.cursor = this._defaultCursorStyle;
			this.setState({
				active: false,
			});
			if (this.props.onActivateStateChanged) {
				this.props.onActivateStateChanged(false);
			}
		}
		// else: this._isActive was already set to this value -> we do nothing
	}

	private getColorAtCenter() {
		return new Promise<{r: number; g: number; b: number}>((resolve, reject) => {
			// Needed, otherwise it would give a blank image,
			// See this: https://stackoverflow.com/questions/30628064/how-to-toggle-preservedrawingbuffer-in-three-js/30647502#30647502
			this.renderMagnifier();
			const imageData = EyeDropperV5._canvas.toDataURL();
			const canvas = EyeDropperV5._ctx.canvas;

			const img = document.createElement("img");

			img.onload = () => {
				EyeDropperV5._ctx.clearRect(0, 0, canvas.width, canvas.height);
				EyeDropperV5._ctx.drawImage(img, 0, 0);
				const imgData = EyeDropperV5._ctx.getImageData(Math.ceil(canvas.width / 2), Math.ceil(canvas.height / 2), 1, 1);
				const rgb = imgData.data;

				resolve({
					r: rgb[0],
					g: rgb[1],
					b: rgb[2],
				});
			};
			img.onerror = () => {
				reject("Error!");
			};
			img.src = imageData;
		});
	}

	private onIconClick = () => {
		this.setActive(!this.state.active);
	};

	private onKeyUp = (event: KeyboardEvent) => {
		if (event.key === KeyboardListener.KEY_ESCAPE) {
			this.setActive(false);
		}
	};

	private updateTheme = () => {
		const theme = this.props.appState.theme;

		EyeDropperV5._renderer.setClearColor(theme === "dark" ? 0x000000 : 0xffffff);
	};

	private updateContainerSize() {
		const rect = this._elementForPointerEvents.getBoundingClientRect();

		this._containerSize.x = rect.width * window.devicePixelRatio;
		this._containerSize.y = rect.height * window.devicePixelRatio;

		this._ctx.canvas.width = this.props.canvas.width;
		this._ctx.canvas.height = this.props.canvas.height;
	}

	private updateTexture = (renderSignalParam: IRenderSignalParam) => {
		this._texture.needsUpdate = true;

		if (this.state.active) {
			this.updateContainerSize();
			this._magnifierMesh.position.set(this.props.canvas.width / 2, this.props.canvas.height / 2, 0);
			THREEUtils.setScale(this._magnifierMesh, this.props.canvas.width, this.props.canvas.height);

			this._ctx.drawImage(this.props.canvas, 0, 0);
			this.renderMagnifier();
		}
	};

	private renderMagnifier() {
		EyeDropperV5._renderer.render(EyeDropperV5._scene, EyeDropperV5._camera);
	}

	public override componentDidMount() {
		this._elementForPointerEvents = this.props.elementForPointerEvents || this.props.canvas;
		this._pointerDetector = new PointerDetector({
			element: this._elementForPointerEvents,
			autoEnable: false,
		});

		const canvas = document.createElement("canvas");

		canvas.width = this.props.canvas.width;
		canvas.height = this.props.canvas.width;
		this._ctx = canvas.getContext("2d");

		this.updateContainerSize();

		if (!this._texture) {
			this._texture = new CanvasTexture(canvas);
			THREEUtils.applyNearestFilterToTexture(this._texture);
		} else {
			this._texture.image = canvas;
			this._texture.needsUpdate = true;
		}

		const material = new EyeDropperMaterial(this._texture, [EyeDropperV5._canvas.width, EyeDropperV5._canvas.height]);

		this._magnifierMesh = new Mesh(this.props.appState.app.spaceViewRenderer.planeGeometry, material);
		const width = this.props.canvas.width;
		const height = this.props.canvas.height;

		this._magnifierMesh.scale.set(width, height, 1);
		this._magnifierMesh.position.set(width / 2, height / 2, 0);
		THREEUtils.add(EyeDropperV5._scene, this._magnifierMesh);
		this.props.textureNeedsUpdateSignal.add(this.updateTexture);

		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);

		observe(this.props.appState, "theme", this.updateTheme);
	}

	public override componentWillUnmount() {
		this.setActive(false);
		this._pointerDetector.dispose();
		this._pointerDetector = null;
		THREEUtils.disposeContainer(EyeDropperV5._scene);
		this.props.textureNeedsUpdateSignal.remove(this.updateTexture);
		this._elementForPointerEvents = null;
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
	}

	public override render() {
		return (
			<EyeDropperStyled
				onClick={this.onIconClick}
				className={ReactUtils.cls("EyeDropper", {active: this.state.active})}
				title="Eye Dropper"
			>
				<EyeDropperIcon
					width="18"
					height="18"
				/>
			</EyeDropperStyled>
		);
	}
}

const EyeDropperStyled = styled.div`
	cursor: pointer;
	${FLEXCENTER};
	width: 32px;
	height: 32px;
	border-radius: ${radius.sm};

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}

	&.active {
		background-color: ${colorPalette.primary.c500Primary};

		svg {
			color: ${colorPalette.white};
		}
	}
`;
