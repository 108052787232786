import {Vector3} from "three";
import type {Markup} from "../data/models/Markup";
import {MarkupType} from "../generated/api/base";
import {MathUtils} from "../utils/math/MathUtils";
import {VectorUtils} from "../utils/VectorUtils";
import type {PhotoSphereManager} from "./PhotoSphereManager";
import type {IPhotoSphere, ITransition} from "./PhotoSphereTypes";

export class PhotoSphere {
	private readonly _photoSphereManager: PhotoSphereManager;
	private readonly _data: IPhotoSphere;

	constructor(data: IPhotoSphere, photoSphereManager: PhotoSphereManager) {
		this._data = data;
		this._data.headingAngle = this._data.headingAngle ?? 0;

		this._photoSphereManager = photoSphereManager;
	}

	public activate() {
		this._photoSphereManager.selectPhotoSphere(this, 0, false);
	}

	public get id() {
		return this._data.item.id;
	}

	public get file() {
		return this._data.fileUrl;
	}

	public get itemModel() {
		return this._data.item as Markup;
	}

	public get name() {
		return this.itemModel.textContent;
	}

	public get thumbnail() {
		return this._data.thumbnailUrl;
	}

	public get transitions(): ITransition[] {
		const transitions: ITransition[] = [];

		if (this.spaceViewRenderer.isMounted) {
			const visibleMarkupsOnSameSpace = this.spaceViewRenderer.markupManager
				.getItemsByType(MarkupType.Photo360)
				.filter((item) => item.isVisible && item?.modelData?.id !== this.itemModel.id);

			transitions.push(
				...visibleMarkupsOnSameSpace.map((markup) => {
					const {spaceUnitsPerMeter} = this.spaceViewRenderer.space;
					const fromPosition = this.itemModel.position;
					fromPosition.z += this.itemModel.heightOffsetFromFloorplan * spaceUnitsPerMeter;
					const toPosition = markup.position;
					// toPosition.z += (markup.modelData as Markup).heightOffsetFromFloorplan * spaceUnitsPerMeter;

					const fromTo = VectorUtils.subVectors([toPosition.x, toPosition.y, toPosition.z], [fromPosition.x, fromPosition.y, fromPosition.z]);

					const hitPointAsVec3 = new Vector3(fromTo[0], fromTo[1], fromTo[2]);
					hitPointAsVec3.applyAxisAngle(new Vector3(1, 0, 0), -Math.PI / 2);
					hitPointAsVec3.applyAxisAngle(new Vector3(0, 1, 0), -Math.PI / 2 - this.itemModel.floorplanRotationOffset);

					const uv = MathUtils.getUVFromSphereSufracePoint([hitPointAsVec3.x, hitPointAsVec3.y, hitPointAsVec3.z]);
					const longLat = MathUtils.getLongitudeLatitudeFromUV(uv);

					return {
						longitude: longLat[0],
						latitude: longLat[1],
						toPhotoSphereId: markup.id,
					};
				}),
			);
		}

		return transitions;
	}

	public get headingAngle() {
		return this._data.headingAngle;
	}

	public get viewBox() {
		return this._data.viewBox;
	}

	public get photoSphereSceneManager() {
		return this._photoSphereManager.photoSphereSceneManager;
	}

	public get spaceViewRenderer() {
		return this.photoSphereSceneManager.spaceViewRenderer;
	}
}
