import {useEffect, useReducer} from "react";
import styled from "styled-components";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {SpaceTool} from "../../../../modules/space/spaceeditor/logic3d/features/tools/Tools";
import {createToolButtonV5} from "../../../../modules/space/spaceeditor/ui/toolbar/ToolBarUtils";
import type {ExtendedDistanceUnitName} from "../../../../modules/space/spaceeditor/logic3d/Constants";
import {PopupWindowV5} from "../../../popup/PopupWindowV5";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";
import {UnitSelectorV5} from "../../../details/UnitSelectorV5";
import {ColorSelectorStyled, ColorSelectorV5} from "../../../colors/ColorSelectorV5";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import type {Color} from "../../../../../generated/api/base";
import type {Markup} from "../../../../../data/models/Markup";
import type {Markup3D} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import {IconButtonStyled, IconButtonV5} from "../../../interaction/IconButtonV5";
import CloseIcon from "../../../icons/xmark.svg?react";
import RedrawIcon from "../../../icons/boundary-redraw.svg?react";
import {colorPalette} from "../../../styles/colorPalette";
import {baseDistance, radius} from "../../../styles/styles";
import {SelectInputStyled} from "../../../input/select/SelectInputV5";
import type {TTitleAlignment} from "../toolbar/ToolButtonV5";
import {ToolButtonStyled} from "../toolbar/ToolButtonV5";

interface IMeasureToolBarProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly activeToolId: SpaceTool;
	readonly setActiveTool: (id: SpaceTool) => void;
}

export const MeasureToolBarV5 = (props: IMeasureToolBarProps) => {
	const {setActiveTool, spaceViewRenderer, activeToolId} = props;
	const {removeAllTempMarkups} = spaceViewRenderer.markupManager;

	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const createToolBtn = (id: SpaceTool, tooltipAlignment: TTitleAlignment) => {
		return createToolButtonV5(spaceViewRenderer, id, activeToolId, setActiveTool, tooltipAlignment, spaceViewRenderer.measureToolColor);
	};

	const unit = spaceViewRenderer.measureToolUnit;
	const setUnit = (unitName: ExtendedDistanceUnitName) => {
		spaceViewRenderer.measureToolUnit = unitName;
		spaceViewRenderer.markupManager.updateAllTempMarkups();
		forceUpdate();
	};

	const onCloseClick = () => {
		removeAllTempMarkups();
		spaceViewRenderer.isMeasureToolBarOpen = false;
		setActiveTool("selection");
	};

	const onClearAllClick = () => {
		removeAllTempMarkups();
	};

	useEffect(() => {
		const onKeyDown = (e: KeyboardEvent) => {
			switch (e.key) {
				case KeyboardListener.KEY_ESCAPE:
					if (PopupWindowV5.numberOfOpenPopups < 1) {
						spaceViewRenderer.markupManager.removeNewestTempMarkup();
						if (spaceViewRenderer.markupManager.tempMarkups.length === 0) {
							spaceViewRenderer.isMeasureToolBarOpen = false;
						}
					}
					break;
			}
		};

		KeyboardListener.getInstance().signals.down.add(onKeyDown);

		return () => {
			KeyboardListener.getInstance().signals.down.remove(onKeyDown);
		};
	}, [spaceViewRenderer]);

	useEffect(() => {
		return () => {
			removeAllTempMarkups();
		};
	}, [removeAllTempMarkups]);

	return (
		<MeasureToolBarStyled className="MeasureToolBar hbox alignCenter">
			{createToolBtn("tempMeasureLinearDistance", "top")}
			{createToolBtn("tempMeasureRectArea", "top")}
			{createToolBtn("tempMeasureNonLinearDistance", "top")}
			{createToolBtn("tempMeasureIrregularArea", "top")}
			<UnitSelectorV5
				unit={unit}
				addFeetAndInches={true}
				onChange={setUnit}
				searchBarMode="always off"
				dropdownFixedWidth="180px"
			/>
			<ColorSelectorV5
				title="Color"
				color={spaceViewRenderer.measureToolColor}
				isTransparencyEnabled={false}
				horizontalAlignment={HorizontalAlignment.center}
				verticalAlignment={VerticalAlignment.topOuter}
				onColorChange={(newColor: Color) => {
					spaceViewRenderer.measureToolColor = newColor;
					for (const markup of spaceViewRenderer.markupManager.items.array as Markup3D[]) {
						const markupModel = markup.modelData as Markup;

						if (markupModel.isTemp) {
							markupModel.setColor(newColor.hex);
							markup.updateByModel(markupModel);
						}
					}
					forceUpdate();
				}}
				eyeDropperProps={spaceViewRenderer.eyeDropperProps}
			/>
			<IconButtonV5
				IconComponent={RedrawIcon}
				title="Clear All"
				titleVerticalAlignment={VerticalAlignment.topOuter}
				onClick={onClearAllClick}
			/>
			<IconButtonV5
				IconComponent={CloseIcon}
				title="Close Measure Toolbar"
				onClick={onCloseClick}
			/>
		</MeasureToolBarStyled>
	);
};

const MeasureToolBarStyled = styled.div`
	position: absolute;
	display: flex;
	gap: ${baseDistance.sm};
	z-index: 1;
	bottom: 16px;
	left: calc(50% - 105px);
	transform: translateX(-50%);
	height: 48px;
	padding: ${baseDistance.sm};
	border-radius: ${radius.md};
	background: ${colorPalette.white};
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
	color: ${colorPalette.gray.c950};

	${SelectInputStyled} {
		width: 180px;
	}

	> ${IconButtonStyled}, > ${ToolButtonStyled}, > ${ColorSelectorStyled} {
		&:hover {
			background-color: ${colorPalette.gray.c200Light};
		}
		&.isActive {
			background-color: ${colorPalette.primary.c500Primary};
		}
	}

	> ${ToolButtonStyled} {
		svg {
			color: ${colorPalette.gray.c950};
		}

		&.isActive {
			svg {
				color: ${colorPalette.white};
			}
		}
	}
`;
