import type {CSSProperties} from "styled-components";
import styled from "styled-components";
import {ShadowDivStyled} from "./ui/5.0/details/ShadowDiv";
import {LoaderIconV5} from "./ui/5.0/loader/LoaderIconV5";

interface IFullscreenLoaderProps {
	readonly text: string;
	readonly style?: CSSProperties;
}

export const FullscreenLoader = (props: IFullscreenLoaderProps) => {
	const {text, style = {}} = props;

	return (
		text && (
			<ShadowDivStyled style={{display: "flex", justifyContent: "center", alignItems: "center", ...style}}>
				<LoaderWrapper>
					<LoaderIconV5 />
					{text}
				</LoaderWrapper>
			</ShadowDivStyled>
		)
	);
};

const LoaderWrapper = styled.div`
	display: flex;
	gap: 8px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
`;
