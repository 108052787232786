import type {PDFImage, PDFFont} from "pdf-lib";
import {PDFDocument, PDFPage, rgb, LineCapStyle, radians, toRadians, pushGraphicsState, setLineJoin, LineJoinStyle, popGraphicsState} from "pdf-lib";
import type {HSL, OrthographicCamera} from "three";
import {Vector2} from "three";
import fontkit from "@pdf-lib/fontkit";
import {BoundarySpaceMap3D} from "../../logic3d/elements3d/BoundarySpaceMap3D";
import {Constants} from "../../logic3d/Constants";
import {IndicatorMaterial} from "../../logic3d/materials/IndicatorMaterial";
import {TextGroupManager} from "../../logic3d/managers/MSDF/TextGroupManager";
import {
	curveHeight,
	curvePeriod,
	dashSize,
	dottedLineDashSize,
	dottedLineGapSize,
	gapSize,
	highlightOpacity,
	highlightRadius,
} from "../../logic3d/elements3d/markups/MarkupStaticElements";
import type {ICaption, ICaptionParent} from "../../logic3d/renderers/SpaceViewRendererUtils";
import {
	calculateCorrectionMultiplier,
	getRadiusForCaptionCollision,
	calculateCaptionSizeFromTextObject,
	getActiveCaptionFields,
	getBoundaryIndicatorScale,
	getCaptionFontConfig,
	getCaptionLeaderLineVisibilityThreshold,
	getDefaultPositionOfBoundaryCaption,
	getDefaultPositionOfXyiconCaption,
	getEmbeddedCounterPosition,
	getEmbeddedCounterScale,
	getFormattingColor,
	getLinkIconPosition,
	getXyiconHighlightScale,
	getXyiconIndicatorPosition,
	getXyiconIndicatorScale,
	SpaceEditorMode,
	updateCaption,
} from "../../logic3d/renderers/SpaceViewRendererUtils";
import type {MarkupCallout} from "../../logic3d/elements3d/markups/MarkupCallout";
import {PDFRenderer} from "../../logic3d/managers/PDFRenderer";
import {LeaderLine} from "../../logic3d/elements3d/LeaderLine";
import type {CaptionedItem} from "../../logic3d/managers/MSDF/CaptionManager";
import {
	MarkupUtils,
	calculateArrowHead,
	getArrowPointsForMarkupCallout,
	getUnrotatedCornersFromAB,
	isMarkupFilteredOutByColor,
} from "../../logic3d/elements3d/markups/abstract/MarkupUtils";
import type {Markup3D} from "../../logic3d/elements3d/markups/abstract/Markup3D";
import type {Xyicon3D} from "../../logic3d/elements3d/Xyicon3D";
import type {IObjectWithText, IPreprocessableObjectWithText} from "../../logic3d/managers/MSDF/TextUtils";
import {
	doesEveryLineHasTheSameBackground,
	getSumOfLineHeights,
	getTextWorldPosition,
	preprocessObjectWithText,
	textLabelToPreprocessableObjectWithText,
} from "../../logic3d/managers/MSDF/TextUtils";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {PDFExporter} from "../../../../../../data/exporters/PDFExporter";
import {FileUtils} from "../../../../../../utils/file/FileUtils";
import type {Xyicon} from "../../../../../../data/models/Xyicon";
import {ImageUtils} from "../../../../../../utils/image/ImageUtils";
import {MathUtils} from "../../../../../../utils/math/MathUtils";
import type {BoundarySpaceMap} from "../../../../../../data/models/BoundarySpaceMap";
import type {IBox3, IRect as IRectangle} from "../../../../../../utils/THREEUtils";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import type {IRGBObject} from "../../../../../../utils/ColorUtils";
import {ColorUtils} from "../../../../../../utils/ColorUtils";
import type {Markup} from "../../../../../../data/models/Markup";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../../utils/dom/DomUtils";
import type {Space} from "../../../../../../data/models/Space";
import type {AppState} from "../../../../../../data/state/AppState";
import type {Dimensions, PointDouble} from "../../../../../../generated/api/base";
import {CatalogIconType, MarkupType, XyiconFeature} from "../../../../../../generated/api/base";
import type {IModel} from "../../../../../../data/models/Model";
import type {Boundary} from "../../../../../../data/models/Boundary";
import {filterModels} from "../../../../../../data/models/filter/Filter";
import {Type} from "../../../../../../data/models/Type";
import {getDefaultInsertionInfo} from "../../../../catalog/create/CatalogTypes";
import {ObjectUtils} from "../../../../../../utils/data/ObjectUtils";
import {ImageUploadPreprocessor} from "../../../../../../utils/image/ImageUploadPreprocessor";
import type {ICaptionStyle} from "../../../../../../data/models/ViewUtils";
import type {SupportedFontName} from "../../../../../../data/state/AppStateTypes";
import {XHRLoader} from "../../../../../../utils/loader/XHRLoader";

interface IExtendedCaption extends ICaption {
	sizeOfGeneratedText: PointDouble;
}

interface IRect {
	x: number;
	y: number;
	width: number;
	height: number;
}

export type SpaceToPDFExportType = "SingleFile" | "MultipleFiles";

type BoxType = "CropBox" | "TrimBox" | "MediaBox" | "BleedBox" | "ArtBox";

const boxTypes: BoxType[] = [
	"CropBox",
	"TrimBox",
	//"MediaBox", // DON'T CHANGE THE MEDIABOX WHEN CROPPING. I spent ~5 hours debugging crop-problems, and it turned out we shouldn't manipulate this box at all
	"BleedBox",
	"ArtBox",
];

interface ISpaceProps {
	id: string;
	size: PointDouble;
	offset: PointDouble;
	correctionMultiplier: number;
}

const isGrayScaled = false; // This is no longer supported

export class SpaceToPDFExporter {
	private _fonts: {
		[key in SupportedFontName]?: PDFFont;
	} = {};

	private _catalogCache: {
		[catalogIdWithOrientation: string]: Promise<{base64: string; xyiconSize: Dimensions; unrotatedBBox: IBox3}>;
	} = {};

	private _imageMap: Map<PDFPage, {[catalogIdWithOrientation: string]: Promise<PDFImage>}> = new Map();
	private _appState: AppState;

	private _photo360SVG: string = "";

	constructor(appState: AppState) {
		this._appState = appState;
	}

	private get _spaceViewRenderer(): SpaceViewRenderer {
		return this._appState.app.graphicalTools.spaceViewRenderer;
	}

	private embedImageIntoPage(page: PDFPage, base64: string) {
		if (!this._imageMap.has(page)) {
			this._imageMap.set(page, {});
		}
		const imageSet = this._imageMap.get(page);

		if (!imageSet[base64]) {
			imageSet[base64] = page.doc.embedPng(base64);
		}

		return imageSet[base64];
	}

	private pdfToSpaceRatio(cropBox: IRect, spaceSize: PointDouble) {
		return Math.max(Math.abs(cropBox.width), Math.abs(cropBox.height)) / Math.max(spaceSize.x, spaceSize.y);
	}

	private getPDFRotationInDegress(pdfRotationInRadian: number): 0 | 90 | 180 | 270 {
		let pdfRotationInDegrees = (MathUtils.RAD2DEG * pdfRotationInRadian) % 360;

		if (pdfRotationInDegrees < 0) {
			pdfRotationInDegrees += 360;
		}
		pdfRotationInDegrees = Math.round(pdfRotationInDegrees);

		return pdfRotationInDegrees as 0 | 90 | 180 | 270;
	}

	private spaceCoordToPDFCoord(x: number, y: number, cropBox: IRect, pdfRotation: number, spaceProps: ISpaceProps) {
		let normalizedX = (x - spaceProps.offset.x) / spaceProps.size.x;
		let normalizedY = (y - spaceProps.offset.y) / spaceProps.size.y;

		const pdfRotationInDegrees = this.getPDFRotationInDegress(pdfRotation);

		if (cropBox.width < 0) {
			if (pdfRotationInDegrees === 90 || pdfRotationInDegrees === 270) {
				normalizedY = 1 - normalizedY;
			} else {
				normalizedX = 1 - normalizedX;
			}
		}
		if (cropBox.height < 0) {
			if (pdfRotationInDegrees === 90 || pdfRotationInDegrees === 270) {
				normalizedX = 1 - normalizedX;
			} else {
				normalizedY = 1 - normalizedY;
			}
		}

		const rotatedNormalized = THREEUtils.getRotatedVertex(
			{
				x: normalizedX,
				y: normalizedY,
			},
			pdfRotation,
			{
				x: 0.5,
				y: 0.5,
			},
		);

		return {
			x: cropBox.x + rotatedNormalized.x * cropBox.width,
			y: cropBox.y + rotatedNormalized.y * cropBox.height,
		};
	}

	/**
	 * geometryData: in space units
	 */
	private getSvgPathByGeometryData(geometryData: PointDouble[], cropBox: IRect, pdfRotation: number, isClosed: boolean, spaceProps: ISpaceProps) {
		const pdfCoords = geometryData.map((vec: PointDouble) => this.spaceCoordToPDFCoord(vec.x, vec.y, cropBox, pdfRotation, spaceProps));

		return this.getSvgPathByPDFCoords(pdfCoords, isClosed);
	}

	private getSvgPathByPDFCoords(pdfCoords: PointDouble[], isClosed: boolean) {
		const firstCoord = pdfCoords[0];
		const prevCoord = {...firstCoord};
		let svgPath = "M0,0 ";

		for (let i = 1; i < pdfCoords.length; ++i) {
			const coord = pdfCoords[i];

			svgPath += `l${coord.x - prevCoord.x},${prevCoord.y - coord.y} `;
			prevCoord.x = coord.x;
			prevCoord.y = coord.y;
		}

		if (isClosed) {
			svgPath += "Z";
		}

		return {
			firstCoord: firstCoord,
			svgPath: svgPath,
		};
	}

	private getMarkupSvgPath(unrotatedCorners: PointDouble[], cropBox: IRect, spaceProps: ISpaceProps) {
		const correctionMultiplier = this.pdfToSpaceRatio(cropBox, spaceProps.size) * spaceProps.correctionMultiplier;
		const correctedCurveHeight = 2 * curveHeight * correctionMultiplier;
		const correctedCurvePeriod = curvePeriod * correctionMultiplier;
		const correctedCurvePeriodHalf = correctedCurvePeriod / 2;
		const cloudWidth = unrotatedCorners[1].x - unrotatedCorners[0].x;
		const cloudHeight = unrotatedCorners[2].y - unrotatedCorners[1].y;

		const curveCount = {
			x: cloudWidth / correctedCurvePeriod,
			y: cloudHeight / correctedCurvePeriod,
		};

		let svgPath = "M0,0 ";

		const leftToRight = `q${correctedCurvePeriodHalf} ${correctedCurveHeight}, ${correctedCurvePeriod} 0 `;
		const topToBottom = `q-${correctedCurveHeight} ${correctedCurvePeriodHalf}, 0 ${correctedCurvePeriod} `;
		const rightToLeft = `q-${correctedCurvePeriodHalf} -${correctedCurveHeight}, -${correctedCurvePeriod} 0 `;
		const bottomToUp = `q${correctedCurveHeight} -${correctedCurvePeriodHalf}, 0 -${correctedCurvePeriod} `;

		for (let i = 0; i < curveCount.x; ++i) {
			svgPath += leftToRight;
		}
		for (let i = 0; i < curveCount.y; ++i) {
			svgPath += bottomToUp;
		}
		for (let i = 0; i < curveCount.x; ++i) {
			svgPath += rightToLeft;
		}
		for (let i = 0; i < curveCount.y; ++i) {
			svgPath += topToBottom;
		}

		svgPath += "z";

		return {
			firstCoord: unrotatedCorners[0],
			svgPath: svgPath,
		};
	}

	private drawSvgPathByBoundarySpaceMap(
		boundarySpaceMap: BoundarySpaceMap,
		page: PDFPage,
		cropBox: IRect,
		pdfRotation: number,
		spaceProps: ISpaceProps,
	) {
		const geometryData = boundarySpaceMap.geometryData;
		const {firstCoord, svgPath} = this.getSvgPathByGeometryData(geometryData, cropBox, pdfRotation, true, spaceProps);

		let colorHex = (boundarySpaceMap.type?.settings.color || Type.defaultColor).hex;

		// Overwrite boundary's color with formatting rule (if enabled and applied)
		const boundaryFormattingRules = this._spaceEditorView.spaceEditorViewSettings.formattingRules.boundary;

		if (boundaryFormattingRules.enabled) {
			const fieldName = boundaryFormattingRules.highlight[boundarySpaceMap.typeName];

			if (fieldName) {
				colorHex = getFormattingColor(boundarySpaceMap, fieldName, this._actions);
			}
		}

		let colorRgb = ColorUtils.hex2Array(colorHex);

		const isExcluded = false; // This is not supported anymore

		if (isExcluded) {
			const hsl: HSL = ColorUtils.hex2hsl(colorHex);

			hsl.s = 0;
			colorRgb = ColorUtils.hsl2array(hsl.h, hsl.s, hsl.l);
		}

		page.drawSvgPath(svgPath, {
			x: firstCoord.x,
			y: firstCoord.y,
			borderColor: rgb(colorRgb[0], colorRgb[1], colorRgb[2]),
			color: rgb(colorRgb[0], colorRgb[1], colorRgb[2]),
			borderWidth: 1 * this.pdfToSpaceRatio(cropBox, spaceProps.size) * spaceProps.correctionMultiplier,
			opacity: BoundarySpaceMap3D.fillOpacity,
		});
	}

	private addMarkupsToPDF(page: PDFPage, spaceProps: ISpaceProps, visibleMarkups: Markup[]) {
		const cropBox = page.getCropBox();
		const pdfRotation = toRadians(page.getRotation());
		const correctionMultiplier = this.pdfToSpaceRatio(cropBox, spaceProps.size) * spaceProps.correctionMultiplier;

		for (const markup of visibleMarkups) {
			const spaceViewRenderer = this._spaceViewRenderer;
			const isMarkupOnMountedSpace = spaceViewRenderer.space?.id === markup.spaceId;
			const markup3D = isMarkupOnMountedSpace
				? (spaceViewRenderer.markupManager.getItemById(markup.id) as Markup3D)
				: spaceViewRenderer.markupManager.createSpaceItem3DFromModel(markup);

			const firstCoord = markup.geometryData?.[0] ?? {x: 0, y: 0};
			const geometryData = markup.geometryData?.length > 1 ? markup.geometryData : [firstCoord, firstCoord];
			const pdfOrientation = radians(pdfRotation + markup.orientation);
			const a = this.spaceCoordToPDFCoord(geometryData[0].x, geometryData[0].y, cropBox, pdfRotation, spaceProps);
			const b = this.spaceCoordToPDFCoord(geometryData[1].x, geometryData[1].y, cropBox, pdfRotation, spaceProps);
			const unrotatedGeometryData = THREEUtils.getRotatedVertices(geometryData, -pdfRotation - markup.orientation, markup3D.position);
			const unrotatedA = this.spaceCoordToPDFCoord(unrotatedGeometryData[0].x, unrotatedGeometryData[0].y, cropBox, pdfRotation, spaceProps);
			const unrotatedB = this.spaceCoordToPDFCoord(unrotatedGeometryData[1].x, unrotatedGeometryData[1].y, cropBox, pdfRotation, spaceProps);
			const markupPosition = markup3D.position;
			const pdfMarkupPosition = this.spaceCoordToPDFCoord(markupPosition.x, markupPosition.y, cropBox, pdfRotation, spaceProps);
			const color = ColorUtils.hex2Array(markup.color).slice(0, 3);
			const pdfColor = rgb(color[0], color[1], color[2]);
			const pdfLineWidth = (markup.lineThickness || MarkupUtils.defaultLineThickness) * correctionMultiplier * 2;

			const unrotatedCorners = getUnrotatedCornersFromAB(unrotatedA, unrotatedB);
			const corners = THREEUtils.getRotatedVertices(unrotatedCorners, pdfRotation + markup.orientation, pdfMarkupPosition);

			const dashArray: number[] = [];

			if (markup.isTemp) {
				dashArray.push(2 * dottedLineDashSize * correctionMultiplier, 4 * dottedLineGapSize * correctionMultiplier);
			} else if (markup.type === MarkupType.DashedLine) {
				dashArray.push(dashSize * correctionMultiplier, gapSize * correctionMultiplier);
			}

			switch (markup.type) {
				case MarkupType.Photo360:
					{
						// Note that the SVG needs to be simplified to contain only one single path for this to work correctly!
						const domParser = new DOMParser();
						const svgDocument = domParser.parseFromString(this._photo360SVG, "image/svg+xml");
						const svgElement = svgDocument.querySelector("svg");
						const widthAttrib = parseInt(svgElement.getAttribute("width"));
						const width = (!isNaN(widthAttrib) ? widthAttrib : 24) - 4;
						const pathElement = svgDocument.querySelector("path");
						const pathD = pathElement.getAttribute("d");
						const markupSize = Constants.SIZE.XYICON * correctionMultiplier;
						const offset = THREEUtils.getRotatedVertex({x: -1, y: -1}, pdfOrientation.angle - pdfRotation, {x: 0, y: 0});
						const pdfSizeToSVGSizeRatio = markupSize / width;

						page.drawSvgPath(pathD, {
							x: a.x + (offset.x * markupSize * pdfSizeToSVGSizeRatio) / 2,
							y: a.y + (offset.y * markupSize * pdfSizeToSVGSizeRatio) / 2,
							rotate: pdfOrientation,
							scale: pdfSizeToSVGSizeRatio,
							color: pdfColor,
						});
					}
					break;
				case MarkupType.Arrow:
				case MarkupType.BidirectionalArrow:
				case MarkupType.Line:
				case MarkupType.DashedLine:
				case MarkupType.LinearDistance:
					page.drawLine({
						start: a,
						end: b,
						color: pdfColor,
						thickness: pdfLineWidth,
						dashArray,
						lineCap: LineCapStyle.Round,
					});

					if (markup.type === MarkupType.Arrow || markup.type === MarkupType.BidirectionalArrow) {
						const {bc, bd} = calculateArrowHead(a, b, markup.arrowHeadSize, correctionMultiplier);

						page.drawLine({
							start: b,
							end: {
								x: b.x + bc.x,
								y: b.y + bc.y,
							},
							color: pdfColor,
							thickness: pdfLineWidth,
							dashArray,
							lineCap: LineCapStyle.Round,
						});
						page.drawLine({
							start: b,
							end: {
								x: b.x + bd.x,
								y: b.y + bd.y,
							},
							color: pdfColor,
							thickness: pdfLineWidth,
							dashArray,
							lineCap: LineCapStyle.Round,
						});
						if (markup.type === MarkupType.BidirectionalArrow) {
							page.drawLine({
								start: a,
								end: {
									x: a.x - bc.x,
									y: a.y - bc.y,
								},
								color: pdfColor,
								thickness: pdfLineWidth,
								dashArray,
								lineCap: LineCapStyle.Round,
							});
							page.drawLine({
								start: a,
								end: {
									x: a.x - bd.x,
									y: a.y - bd.y,
								},
								color: pdfColor,
								thickness: pdfLineWidth,
								dashArray,
								lineCap: LineCapStyle.Round,
							});
						}
					} else if (markup.type === MarkupType.LinearDistance) {
						const AB = {
							x: b.x - a.x,
							y: b.y - a.y,
						};
						const normalAB = THREEUtils.multiplyByScalar(THREEUtils.getNormal(AB), 20 * correctionMultiplier);

						page.drawLine({
							start: {
								x: a.x + normalAB.x / 2,
								y: a.y + normalAB.y / 2,
							},
							end: {
								x: a.x - normalAB.x / 2,
								y: a.y - normalAB.y / 2,
							},
							color: pdfColor,
							thickness: pdfLineWidth,
							dashArray,
							lineCap: LineCapStyle.Round,
						});

						page.drawLine({
							start: {
								x: b.x + normalAB.x / 2,
								y: b.y + normalAB.y / 2,
							},
							end: {
								x: b.x - normalAB.x / 2,
								y: b.y - normalAB.y / 2,
							},
							color: pdfColor,
							thickness: pdfLineWidth,
							dashArray,
							lineCap: LineCapStyle.Round,
						});
					}
					break;
				case MarkupType.PencilDrawing:
				case MarkupType.HighlightDrawing:
				case MarkupType.NonlinearDistance:
				case MarkupType.IrregularArea:
					const irregularAreaSvgData = this.getSvgPathByGeometryData(
						geometryData,
						cropBox,
						pdfRotation,
						markup.type === MarkupType.IrregularArea,
						spaceProps,
					);

					// Set linejoin style based on this:
					// https://github.com/Hopding/pdf-lib/issues/398
					// This is mostly useful/visible for self-crossing highlightdrawings

					page.pushOperators(pushGraphicsState(), setLineJoin(LineJoinStyle.Round));
					page.drawSvgPath(irregularAreaSvgData.svgPath, {
						x: irregularAreaSvgData.firstCoord.x,
						y: irregularAreaSvgData.firstCoord.y,
						borderDashArray: dashArray,
						borderColor: pdfColor,
						borderWidth: markup.type === MarkupType.HighlightDrawing ? 2 * highlightRadius * correctionMultiplier : pdfLineWidth,
						borderOpacity: markup.type === MarkupType.HighlightDrawing ? highlightOpacity : 1,
						borderLineCap: LineCapStyle.Round,
						opacity: markup.type === MarkupType.IrregularArea ? 1 - markup.fillTransparency : 0,
						color: markup.type === MarkupType.IrregularArea ? pdfColor : undefined,
					});
					page.pushOperators(popGraphicsState());
					break;
				case MarkupType.Rectangle:
				case MarkupType.RectangleArea:
				case MarkupType.Callout:
					page.drawRectangle({
						x: corners[0].x,
						y: corners[0].y,
						width: Math.abs(unrotatedB.x - unrotatedA.x),
						height: Math.abs(unrotatedB.y - unrotatedA.y),
						borderColor: pdfColor,
						borderWidth: pdfLineWidth,
						borderDashArray: dashArray,
						color: pdfColor,
						opacity: 1 - markup.fillTransparency,
						rotate: pdfOrientation,
						borderLineCap: LineCapStyle.Round,
					});

					if (markup.type === MarkupType.Callout) {
						// Draw the target arrow
						// Use the saved one, otherwise the thumbnail gets updated before the object is actually updated on the backend
						let {startPos, elbowPos, target} = getArrowPointsForMarkupCallout(markup3D as MarkupCallout, true);

						startPos = this.spaceCoordToPDFCoord(startPos.x, startPos.y, cropBox, pdfRotation, spaceProps);
						elbowPos = this.spaceCoordToPDFCoord(elbowPos.x, elbowPos.y, cropBox, pdfRotation, spaceProps);
						target = this.spaceCoordToPDFCoord(target.x, target.y, cropBox, pdfRotation, spaceProps);

						const {bc, bd} = calculateArrowHead(elbowPos, target, markup.arrowHeadSize, correctionMultiplier);

						page.drawLine({
							start: startPos as {x: number; y: number},
							end: elbowPos as {x: number; y: number},
							color: pdfColor,
							thickness: pdfLineWidth,
							lineCap: LineCapStyle.Round,
						});
						page.drawLine({
							start: elbowPos as {x: number; y: number},
							end: target as {x: number; y: number},
							color: pdfColor,
							thickness: pdfLineWidth,
							lineCap: LineCapStyle.Round,
						});
						page.drawLine({
							start: target as {x: number; y: number},
							end: {
								x: target.x + bc.x,
								y: target.y + bc.y,
							},
							color: pdfColor,
							thickness: pdfLineWidth,
							lineCap: LineCapStyle.Round,
						});

						page.drawLine({
							start: target as {x: number; y: number},
							end: {
								x: target.x + bd.x,
								y: target.y + bd.y,
							},
							color: pdfColor,
							thickness: pdfLineWidth,
							lineCap: LineCapStyle.Round,
						});
					}
					break;
				case MarkupType.Ellipse:
					page.drawEllipse({
						x: pdfMarkupPosition.x,
						y: pdfMarkupPosition.y,
						xScale: Math.abs(unrotatedB.x - unrotatedA.x) / 2,
						yScale: Math.abs(unrotatedB.y - unrotatedA.y) / 2,
						borderWidth: pdfLineWidth,
						borderColor: pdfColor,
						color: pdfColor,
						opacity: 1 - markup.fillTransparency,
						rotate: pdfOrientation,
					});
					break;
				case MarkupType.Cross:
					page.drawLine({
						start: corners[0],
						end: corners[2],
						thickness: pdfLineWidth,
						color: pdfColor,
						lineCap: LineCapStyle.Round,
					});
					page.drawLine({
						start: corners[1],
						end: corners[3],
						thickness: pdfLineWidth,
						color: pdfColor,
						lineCap: LineCapStyle.Round,
					});
					break;
				case MarkupType.Triangle:
					const top = {
						x: (corners[2].x + corners[3].x) / 2,
						y: (corners[2].y + corners[3].y) / 2,
					};
					const triangleSvgData = this.getSvgPathByPDFCoords([corners[0], corners[1], top], true);

					page.drawSvgPath(triangleSvgData.svgPath, {
						x: triangleSvgData.firstCoord.x,
						y: triangleSvgData.firstCoord.y,
						borderColor: pdfColor,
						borderWidth: pdfLineWidth,
						borderLineCap: LineCapStyle.Round,
						opacity: 1 - markup.fillTransparency,
						color: pdfColor,
					});
					break;
				case MarkupType.Cloud:
					const markupSvgPath = this.getMarkupSvgPath(unrotatedCorners, cropBox, spaceProps);

					page.drawSvgPath(markupSvgPath.svgPath, {
						x: corners[0].x,
						y: corners[0].y,
						borderColor: pdfColor,
						borderWidth: pdfLineWidth,
						borderLineCap: LineCapStyle.Round,
						opacity: 1 - markup.fillTransparency,
						color: pdfColor,
						rotate: pdfOrientation,
					});
					break;
			}

			if (markup3D.textContent) {
				const {textBackground} = markup3D;

				if (
					markup3D.isTemp &&
					textBackground &&
					textBackground.fillColor?.length === 3 &&
					MathUtils.isValidNumber(textBackground.opacity) &&
					textBackground.opacity > 0
				) {
					const horizontalScaleMultiplicator = 1.05;

					this.drawRectangleOnPDF(
						page,
						spaceProps,
						{
							x: textBackground.size.x * horizontalScaleMultiplicator,
							y: textBackground.size.y,
						},
						markup3D.orientation + textBackground.orientation,
						{
							x: markup3D.position.x + textBackground.position.x - textBackground.size.x / 2,
							y: markup3D.position.y + textBackground.position.y - textBackground.size.y / 2,
						},
						[...textBackground.fillColor, textBackground.opacity] as [number, number, number, number],
					);
				}
				this.drawTextObjectOnPDF(markup3D, page, spaceProps);
			}

			if (!isMarkupOnMountedSpace) {
				markup3D.destroy(false);
			}
		}
	}

	private addConditionalFormattingElement(
		page: PDFPage,
		cropBox: IRect,
		pdfRotation: number,
		pdfToSpaceRatio: number,
		pos: PointDouble,
		scale: number,
		color: number[], // range: [0, 1]
		spaceProps: ISpaceProps,
	) {
		if (pos && color && scale) {
			const pdfPosition = this.spaceCoordToPDFCoord(pos.x, pos.y, cropBox, pdfRotation, spaceProps);

			page.drawCircle({
				x: pdfPosition.x,
				y: pdfPosition.y,
				size: (scale / 2) * pdfToSpaceRatio,
				color: rgb(color[0], color[1], color[2]),
				opacity: color[3],
			});
		}
	}

	private async getUnrotatedBoundingBoxOfXyicon(xyicon: Xyicon, pdfOrientation: number, spaceProps: ISpaceProps): Promise<IBox3> {
		const standardXyiconSize = Constants.SIZE.XYICON * spaceProps.correctionMultiplier;
		const offset = xyicon.catalog?.insertionInfo ?? getDefaultInsertionInfo();
		const offsetSize: Dimensions = {
			x: Math.abs(offset.offsetX),
			y: Math.abs(offset.offsetY),
			z: Math.abs(offset.offsetZ),
		};

		const bbox = ObjectUtils.deepClone(
			xyicon.iconCategory === CatalogIconType.ModelParameter
				? (await this._catalogCache[this.getCacheItemId(xyicon, pdfOrientation, isGrayScaled)]).unrotatedBBox
				: {
						min: {
							x: -standardXyiconSize / 2,
							y: -standardXyiconSize / 2,
							z: -standardXyiconSize / 2,
						},
						max: {
							x: standardXyiconSize / 2,
							y: standardXyiconSize / 2,
							z: standardXyiconSize / 2,
						},
					},
		);

		bbox.min.x -= offsetSize.x;
		bbox.min.y -= offsetSize.y;
		bbox.min.z -= offsetSize.z;
		bbox.max.x += offsetSize.x;
		bbox.max.y += offsetSize.y;
		bbox.max.z += offsetSize.z;

		return bbox;
	}

	private async addConditionalFormattingElementsForBottomLayer(
		page: PDFPage,
		spaceProps: ISpaceProps,
		visibleXyicons: Xyicon[],
		visibleBoundaries: BoundarySpaceMap[],
	) {
		const cropBox = page.getCropBox();
		const pdfRotation = toRadians(page.getRotation());
		const pdfToSpaceRatio = this.pdfToSpaceRatio(cropBox, spaceProps.size);

		// Boundary indicators
		for (const boundary of visibleBoundaries) {
			const formattingRules = this._spaceEditorView.spaceEditorViewSettings.formattingRules.boundary;

			if (formattingRules.enabled) {
				const fieldName = formattingRules.indicator[boundary.typeName];

				if (fieldName) {
					const colorHex = getFormattingColor(boundary, fieldName, this._actions);

					if (colorHex) {
						const pos = boundary.position;
						const scale = getBoundaryIndicatorScale(spaceProps.correctionMultiplier);
						const color = ColorUtils.hex2Array(colorHex, 1 - IndicatorMaterial.TRANSPARENCY.indicator);

						this.addConditionalFormattingElement(page, cropBox, pdfRotation, pdfToSpaceRatio, pos, scale, color, spaceProps);
					}
				}
			}
		}

		// Xyicon highlights
		for (const xyicon of visibleXyicons) {
			const formattingRules = this._spaceEditorView.spaceEditorViewSettings.formattingRules.xyicon;

			if (formattingRules.enabled) {
				const fieldName = formattingRules.highlight[xyicon.typeName];

				if (fieldName) {
					const colorHex = getFormattingColor(xyicon, fieldName, this._actions);

					if (colorHex) {
						const pos = xyicon.position;
						const color = ColorUtils.hex2Array(colorHex, 1 - IndicatorMaterial.TRANSPARENCY.highlight);
						const unrotatedBBox = await this.getUnrotatedBoundingBoxOfXyicon(xyicon, -xyicon.orientation - pdfRotation, spaceProps);
						const xyiconScale = THREEUtils.getSizeOfBoundingBox3(unrotatedBBox);
						const scale = getXyiconHighlightScale(xyiconScale);

						this.addConditionalFormattingElement(page, cropBox, pdfRotation, pdfToSpaceRatio, pos, scale, color, spaceProps);
					}
				}
			}
		}
	}

	private addLinkLinesToPDF(page: PDFPage, spaceProps: ISpaceProps) {
		if (spaceProps.id === this._spaceViewRenderer.space?.id) {
			const cropBox = page.getCropBox();
			const pdfRotation = toRadians(page.getRotation());
			const {correctionMultiplier} = spaceProps;

			const lines = this._spaceViewRenderer.spaceItemController.linkLineManager.lines;

			for (const line of lines) {
				if (line.isVisible) {
					const lineStartPos = line.posA;
					const lineEndPos = line.posB;
					const pdfStartPosition = this.spaceCoordToPDFCoord(lineStartPos[0], lineStartPos[1], cropBox, pdfRotation, spaceProps);
					const pdfEndPosition = this.spaceCoordToPDFCoord(lineEndPos[0], lineEndPos[1], cropBox, pdfRotation, spaceProps);
					const lineColor = ColorUtils.hex2rgb(this._spaceViewRenderer.spaceItemController.linkLineManager.lineColor, 0, "RGBObject") as IRGBObject;
					const dashArray: number[] = [];

					page.drawLine({
						start: pdfStartPosition,
						end: pdfEndPosition,
						thickness: 1 * correctionMultiplier,
						color: rgb(lineColor.r / 255, lineColor.g / 255, lineColor.b / 255),
						dashArray,
					});
				}
			}
		}
	}

	private async addConditionalFormattingElementsForTopLayer(page: PDFPage, spaceProps: ISpaceProps, visibleXyicons: Xyicon[]) {
		const cropBox = page.getCropBox();
		const pdfRotation = toRadians(page.getRotation());
		const pdfToSpaceRatio = this.pdfToSpaceRatio(cropBox, spaceProps.size);

		const xyiconMeshScale: Dimensions = {x: 1, y: 1, z: 1};

		for (const xyicon of visibleXyicons) {
			const formattingRules = this._spaceEditorView.spaceEditorViewSettings.formattingRules.xyicon;

			if (formattingRules.enabled) {
				const fieldName = formattingRules.indicator[xyicon.typeName];

				if (fieldName) {
					const colorHex = getFormattingColor(xyicon, fieldName, this._actions);

					if (colorHex) {
						const unrotatedBBox = await this.getUnrotatedBoundingBoxOfXyicon(xyicon, -xyicon.orientation - pdfRotation, spaceProps);
						const xyiconScale = THREEUtils.getSizeOfBoundingBox3(unrotatedBBox);
						const offset = getXyiconIndicatorPosition(xyiconScale, spaceProps.correctionMultiplier);
						const xyiconPos = xyicon.position;
						let pos: PointDouble = {
							x: xyiconPos.x + offset.x,
							y: xyiconPos.y + offset.y,
						};

						pos = THREEUtils.getRotatedVertices([pos], xyicon.orientation, xyiconPos)[0];
						const color = ColorUtils.hex2Array(colorHex, 1 - IndicatorMaterial.TRANSPARENCY.indicator);
						const scale = getXyiconIndicatorScale(Math.max(xyiconMeshScale.x, xyiconMeshScale.y), spaceProps.correctionMultiplier);

						this.addConditionalFormattingElement(page, cropBox, pdfRotation, pdfToSpaceRatio, pos, scale, color, spaceProps);
					}
				}
			}
		}
	}

	private async drawIconOnCornerOfXyicon(
		xyicon: Xyicon,
		textContent: string,
		page: PDFPage,
		spaceProps: ISpaceProps,
		getIconPosition: (xyiconScale: PointDouble, correctionMultiplier: number, zOffset: number) => Dimensions,
		fontSize: number = 16,
		orientationOffset: number = 0,
	) {
		const cropBox = page.getCropBox();
		const pdfRotation = toRadians(page.getRotation());
		const pdfToSpaceRatio = this.pdfToSpaceRatio(cropBox, spaceProps.size);
		const xyiconMeshScale: Dimensions = {x: 1, y: 1, z: 1};

		const unrotatedBBox = await this.getUnrotatedBoundingBoxOfXyicon(xyicon, -xyicon.orientation - pdfRotation, spaceProps);
		const xyiconScale = THREEUtils.getSizeOfBoundingBox3(unrotatedBBox);
		const offset = getIconPosition(xyiconScale, spaceProps.correctionMultiplier, 0);
		const xyiconPos = xyicon.position;
		let pos: PointDouble = {
			x: xyiconPos.x + offset.x,
			y: xyiconPos.y + offset.y,
		};

		pos = THREEUtils.getRotatedVertices([pos], xyicon.orientation, xyiconPos)[0];
		const pdfPosition = this.spaceCoordToPDFCoord(pos.x, pos.y, cropBox, pdfRotation, spaceProps);
		const scale = getEmbeddedCounterScale(xyiconMeshScale.x, spaceProps.correctionMultiplier);

		const bgColor = ColorUtils.hex2Array(Constants.COUNTER_BG_COLOR);
		const borderColor = ColorUtils.hex2Array(Constants.COUNTER_BORDER_COLOR);

		page.drawCircle({
			x: pdfPosition.x,
			y: pdfPosition.y,
			size: (scale / 2) * pdfToSpaceRatio,
			color: rgb(bgColor[0], bgColor[1], bgColor[2]),
			opacity: bgColor[3],
			borderColor: rgb(borderColor[0], borderColor[1], borderColor[2]),
			borderWidth: 1 * spaceProps.correctionMultiplier * pdfToSpaceRatio,
		});

		const preprocessableTextObject: IPreprocessableObjectWithText = {
			text: [{content: textContent}],
			fontColor: {hex: Constants.COUNTER_TEXT_COLOR, transparency: 0},
			fontFamily: "Roboto",
			fontSize,
			isBold: true,
			isItalic: false,
			isUnderlined: false,
			backgroundColor: null,
		};

		const textSettings: ICaptionStyle = {
			backgroundColor: null,
			isBold: preprocessableTextObject.isBold,
			isItalic: preprocessableTextObject.isItalic,
			isUnderlined: preprocessableTextObject.isUnderlined,
			fontColor: preprocessableTextObject.fontColor,
			fontFamily: preprocessableTextObject.fontFamily,
			fontSize: preprocessableTextObject.fontSize,
		};

		const textData = preprocessObjectWithText(
			textLabelToPreprocessableObjectWithText(preprocessableTextObject.text, textSettings),
			TextGroupManager.fonts,
			this._appState.app.spaceViewRenderer,
		);

		const textObject: IObjectWithText = {
			...preprocessableTextObject,
			id: `${xyicon.refId}_counter`,
			isVisible: true,
			position: pos,
			orientation: xyicon.orientation + orientationOffset,
			textHAlign: HorizontalAlignment.center,
			textVAlign: VerticalAlignment.center,
			isCaption: true, // we don't want it to be wrapped, ever
			opacity: 1,
			scale: textData.size,
			sizeOfGeneratedText: textData.size, // it's not used for this
			textInstanceIds: [],
		};

		this.drawTextObjectOnPDF(textObject, page, spaceProps);
	}

	private addEmbeddedXyiconCounters(page: PDFPage, spaceProps: ISpaceProps, visibleXyicons: Xyicon[]) {
		const promises: Promise<void>[] = [];

		for (const xyicon of visibleXyicons) {
			const embeddedCount = xyicon.embeddedXyicons.length;

			if (embeddedCount > 0) {
				const textContent = `${embeddedCount}`;

				promises.push(this.drawIconOnCornerOfXyicon(xyicon, textContent, page, spaceProps, getEmbeddedCounterPosition));
			}
		}

		return Promise.all(promises);
	}

	private addLinkIconsToXyicons(page: PDFPage, spaceProps: ISpaceProps, visibleXyicons: Xyicon[]) {
		const promises: Promise<void>[] = [];

		for (const xyicon of visibleXyicons) {
			const doesHaveLinkIcon = this._actions.doesXyiconHaveLinkIcon(xyicon);

			if (doesHaveLinkIcon) {
				const textContent = "8"; // try to imitate a "link" icon with this, lol

				promises.push(this.drawIconOnCornerOfXyicon(xyicon, textContent, page, spaceProps, getLinkIconPosition, 20, (3 * Math.PI) / 4));
			}
		}

		return Promise.all(promises);
	}

	private drawCaptions(page: PDFPage, visibleCaptions: IObjectWithText[], spaceProps: ISpaceProps) {
		for (const caption of visibleCaptions) {
			this.drawTextObjectOnPDF(caption, page, spaceProps);
		}
	}

	private drawRectangleOnPDF(
		page: PDFPage,
		spaceProps: ISpaceProps,
		size: PointDouble,
		orientation: number,
		position: PointDouble,
		color: [number, number, number, number],
	) {
		if (color?.[3] > 0 && size.x > 0 && size.y > 0) {
			const cropBox = page.getCropBox();
			const pdfToSpaceRatio = this.pdfToSpaceRatio(cropBox, spaceProps.size);
			const pdfRotation = toRadians(page.getRotation());
			const pdfMeshOrientation = pdfRotation + orientation;

			const pdfMeshSize = {
				x: size.x * pdfToSpaceRatio,
				y: size.y * pdfToSpaceRatio,
			};
			const pdfMeshPosition = this.spaceCoordToPDFCoord(position.x, position.y, cropBox, pdfRotation, spaceProps);

			page.drawRectangle({
				x: pdfMeshPosition.x,
				y: pdfMeshPosition.y,
				width: pdfMeshSize.x,
				height: pdfMeshSize.y,
				color: rgb(color[0], color[1], color[2]),
				opacity: color[3],
				rotate: radians(pdfMeshOrientation),
			});
		}
	}

	private drawTextObjectOnPDF(textObject: IObjectWithText, page: PDFPage, spaceProps: ISpaceProps) {
		if (textObject.text?.length > 0) {
			const dimensionMultiplier = textObject.dimensionMultiplier ?? 1;
			const cropBox = page.getCropBox();
			const pdfToSpaceRatio = this.pdfToSpaceRatio(cropBox, spaceProps.size);
			const pdfRotation = toRadians(page.getRotation());
			const pdfMeshOrientation = pdfRotation + textObject.orientation + (textObject.textOrientation ?? 0);

			const captionMeshWorldPosition = getTextWorldPosition(textObject);
			const pdfMeshSize = {
				x: textObject.scale.x * dimensionMultiplier * pdfToSpaceRatio,
				y: textObject.scale.y * dimensionMultiplier * pdfToSpaceRatio,
			};
			const pdfMeshPosition = this.spaceCoordToPDFCoord(captionMeshWorldPosition.x, captionMeshWorldPosition.y, cropBox, pdfRotation, spaceProps);
			{
				const unrotatedOffset = {
					x: -pdfMeshSize.x / 2,
					y: -pdfMeshSize.y / 2,
				};
				const rotatedOffset = THREEUtils.getRotatedVertex(unrotatedOffset, pdfMeshOrientation, {x: 0, y: 0});

				pdfMeshPosition.x += rotatedOffset.x;
				pdfMeshPosition.y += rotatedOffset.y;
			}

			const maxWidth = TextGroupManager.calculateMaxWidth(textObject, spaceProps.correctionMultiplier);
			const preprocessedTextObject = preprocessObjectWithText(textObject, TextGroupManager.fonts, this._spaceViewRenderer, maxWidth);

			const doesEveryLineHasTheSameBG = doesEveryLineHasTheSameBackground(preprocessedTextObject.lines);

			if (doesEveryLineHasTheSameBG) {
				const bg = preprocessedTextObject.lines[0].backgroundColor;
				const backgroundColor = bg && ColorUtils.hex2Array(bg.hex, 1 - bg.transparency);

				if (backgroundColor?.[3] > 0) {
					page.drawRectangle({
						x: pdfMeshPosition.x,
						y: pdfMeshPosition.y,
						width: pdfMeshSize.x,
						height: pdfMeshSize.y,
						color: rgb(backgroundColor[0], backgroundColor[1], backgroundColor[2]),
						opacity: backgroundColor[3],
						rotate: radians(pdfMeshOrientation),
					});
				}
			}

			const paddingBetweenLines = 0;
			const sumOfLineHeights =
				Math.max(0, getSumOfLineHeights(preprocessedTextObject.lines)) * pdfToSpaceRatio * spaceProps.correctionMultiplier * dimensionMultiplier;
			const paddingTopBottom = (pdfMeshSize.y - sumOfLineHeights) / 2;

			let lineOffsetY = pdfMeshSize.y;

			for (let i = 0; i < preprocessedTextObject.lines.length; ++i) {
				const line = preprocessedTextObject.lines[i];
				const activeFontColor = line.fontColor ?? textObject.fontColor;
				const fontColor = ColorUtils.hex2Array(activeFontColor.hex, 1 - activeFontColor.transparency);

				if (fontColor[3] > 0) {
					const fontSize = (line.fontSize ?? textObject.fontSize) * dimensionMultiplier;
					const pdfFontSize = fontSize * pdfToSpaceRatio * spaceProps.correctionMultiplier;
					const lineHeight = pdfFontSize + paddingBetweenLines * dimensionMultiplier;
					const textContent = line.textContent;
					const textWidth = line.width * pdfToSpaceRatio * dimensionMultiplier;

					if (i === 0) {
						switch (textObject.textVAlign) {
							case VerticalAlignment.center:
								lineOffsetY -= paddingTopBottom - lineHeight / 8;
								break;
							case VerticalAlignment.bottom:
								lineOffsetY = sumOfLineHeights + lineHeight / 10;
								break;
						}
					}
					lineOffsetY -= lineHeight;

					// We need to adjust the background box and the text a bit differently,
					// because the anchor point seems to be at the bottom of characters like T, and e,
					// but other characters like "p", and "g" partially slide under the "line"
					const getRotatedOffset = (objectType: "backgroundBox" | "text"): PointDouble => {
						const unrotatedOffset = {
							x: objectType === "text" ? (pdfMeshSize.x - textWidth) / 2 : 0, // center
							y: objectType === "text" ? lineOffsetY + 0.2 * lineHeight : lineOffsetY, // center
						};

						if (objectType === "text") {
							switch (textObject.textHAlign) {
								case HorizontalAlignment.left:
									unrotatedOffset.x = 0;
									break;
								case HorizontalAlignment.right:
									unrotatedOffset.x = pdfMeshSize.x - textWidth;
									break;
							}
						}

						return THREEUtils.getRotatedVertex(unrotatedOffset, pdfMeshOrientation, {x: 0, y: 0});
					};

					if (!doesEveryLineHasTheSameBG && line.backgroundColor?.transparency < 1) {
						const bg = line.backgroundColor;
						const backgroundColor = ColorUtils.hex2Array(bg.hex, 1 - bg.transparency);
						const rotatedOffset = getRotatedOffset("backgroundBox");

						if (backgroundColor?.[3] > 0) {
							page.drawRectangle({
								x: pdfMeshPosition.x + rotatedOffset.x,
								y: pdfMeshPosition.y + rotatedOffset.y,
								width: pdfMeshSize.x,
								height: lineHeight,
								color: rgb(backgroundColor[0], backgroundColor[1], backgroundColor[2]),
								opacity: backgroundColor[3],
								rotate: radians(pdfMeshOrientation),
							});
						}
					}

					const rotatedOffset = getRotatedOffset("text");

					page.drawText(textContent, {
						x: pdfMeshPosition.x + rotatedOffset.x,
						y: pdfMeshPosition.y + rotatedOffset.y,
						font: this._fonts[line.fontFamily ?? textObject.fontFamily],
						color: rgb(fontColor[0], fontColor[1], fontColor[2]),
						opacity: fontColor[3],
						size: pdfFontSize,
						rotate: radians(pdfMeshOrientation),
					});
				}
			}
		}
	}

	private async addCaptionsToPDF(page: PDFPage, spaceProps: ISpaceProps, visibleXyicons: Xyicon[], visibleBoundaries: BoundarySpaceMap[]) {
		const cropBox = page.getCropBox();
		const pdfRotation = toRadians(page.getRotation());
		const correctionMultiplier = this.pdfToSpaceRatio(cropBox, spaceProps.size) * spaceProps.correctionMultiplier;
		const dashArray = [LeaderLine.dashSize * correctionMultiplier, LeaderLine.gapSize * correctionMultiplier];
		const visibleXyiconsAsRects: IRectangle[] = [];
		const visibleCaptions: IExtendedCaption[] = [];
		const captionSettings = this._spaceEditorView.spaceEditorViewSettings.captions;
		const xyiconCaptionSettings = captionSettings.xyicon;
		const boundaryCaptionSettings = captionSettings.boundary;
		const spaceViewRenderer = this._spaceViewRenderer;
		const isExportingActiveSpace = spaceViewRenderer.space?.id === spaceProps.id;

		if (xyiconCaptionSettings.checkList.length > 0) {
			const activeCaptionFields = getActiveCaptionFields(XyiconFeature.Xyicon, this._actions);

			for (const xyicon of visibleXyicons) {
				const pdfOrientation = -xyicon.orientation - pdfRotation; // the direction is the other way in PDF...
				const {xyiconSize} = await this._catalogCache[this.getCacheItemId(xyicon, pdfOrientation, isGrayScaled)];
				const captionTextParts = this._actions.getCaptionFieldValues(activeCaptionFields, xyicon);

				visibleXyiconsAsRects.push({
					position: xyicon.position,
					scale: xyiconSize,
				});

				if (captionTextParts) {
					const parent: ICaptionParent = {
						id: xyicon.id,
						spaceItemType: "xyicon",
						position: xyicon.position,
						scale: xyiconSize,
					};

					const existingCaptionMaybe = (spaceViewRenderer.xyiconManager.getItemById(xyicon.id) as Xyicon3D)?.caption;

					if (existingCaptionMaybe) {
						visibleCaptions.push({
							...existingCaptionMaybe,
							text: existingCaptionMaybe.text,
							position: existingCaptionMaybe.position,
							parent,
						});
					} else {
						const captionData = preprocessObjectWithText(
							textLabelToPreprocessableObjectWithText(captionTextParts, xyiconCaptionSettings),
							TextGroupManager.fonts,
							this._spaceViewRenderer,
						);
						const captionSize = calculateCaptionSizeFromTextObject(captionData, xyiconCaptionSettings.fontSize, spaceProps.correctionMultiplier);
						const captionDefaultPos = getDefaultPositionOfXyiconCaption(xyicon.position, xyiconSize.y, captionSize, spaceProps.correctionMultiplier);
						const caption: IExtendedCaption = {
							text: captionTextParts,
							position: captionDefaultPos,
							scale: captionSize,
							sizeOfGeneratedText: captionData.size,
							parent,
						};

						visibleCaptions.push(caption);
					}
				}
			}
		}
		if (boundaryCaptionSettings.checkList.length > 0) {
			const activeCaptionFields = getActiveCaptionFields(XyiconFeature.Boundary, this._actions);

			for (const boundarySpaceMap of visibleBoundaries) {
				const captionTextParts = this._actions.getCaptionFieldValues(activeCaptionFields, boundarySpaceMap.parent);

				if (captionTextParts) {
					const parent: ICaptionParent = {
						id: boundarySpaceMap.id,
						spaceItemType: "boundary",
						position: boundarySpaceMap.position,
						scale: boundarySpaceMap.scale,
						geometryData: boundarySpaceMap.geometryData,
					};

					const existingCaptionMaybe = (spaceViewRenderer.boundaryManager.getItemById(boundarySpaceMap.id) as BoundarySpaceMap3D)?.caption;

					if (existingCaptionMaybe) {
						visibleCaptions.push({
							...existingCaptionMaybe,
							text: existingCaptionMaybe.text,
							position: existingCaptionMaybe.position,
							parent,
						});
					} else {
						const captionData = preprocessObjectWithText(
							textLabelToPreprocessableObjectWithText(captionTextParts, boundaryCaptionSettings),
							TextGroupManager.fonts,
							this._spaceViewRenderer,
						);
						const captionSize = calculateCaptionSizeFromTextObject(captionData, boundaryCaptionSettings.fontSize, spaceProps.correctionMultiplier);
						const captionDefaultPos = getDefaultPositionOfBoundaryCaption(boundarySpaceMap.geometryData);

						const caption: IExtendedCaption = {
							text: captionTextParts,
							position: captionDefaultPos,
							scale: captionSize,
							sizeOfGeneratedText: captionData.size,
							parent,
						};

						visibleCaptions.push(caption);
					}
				}
			}
		}

		const maxFontSize = Math.max(xyiconCaptionSettings.fontSize, boundaryCaptionSettings.fontSize);
		const radius = getRadiusForCaptionCollision(spaceProps.correctionMultiplier, maxFontSize);
		const v2 = new Vector2();
		const filterByDistance = (itemToCompare: ICaption | CaptionedItem) => v2.distanceTo(itemToCompare.position as unknown as Vector2) < radius;

		if (!isExportingActiveSpace) {
			// If we're exporting the active space, we don't want to recalculate the caption positions,
			// because it's not only unnecessary, but it can lead to strange side-effects, like
			// having different caption positions on the PDF compared to the actual space
			for (let i = 0; i < visibleCaptions.length; ++i) {
				const caption = visibleCaptions[i];

				v2.set(caption.position.x, caption.position.y);

				const captionsThatWillBeUpdated = visibleCaptions.slice(i);

				updateCaption(
					caption,
					visibleXyiconsAsRects.filter(filterByDistance),
					visibleCaptions.filter(
						(c) =>
							c.parent.id !== caption.parent.id &&
							v2.distanceTo(c.position as unknown as Vector2) < radius &&
							!captionsThatWillBeUpdated.slice(i).some((cap) => cap.parent.id === c.parent.id),
					),
					spaceProps.correctionMultiplier,
				);
			}
		}

		// Add leaderlines
		for (const caption of visibleCaptions) {
			if (
				caption.parent.spaceItemType === "xyicon" &&
				THREEUtils.calculateDistance([caption.position, caption.parent.position]) >
					getCaptionLeaderLineVisibilityThreshold(spaceProps.correctionMultiplier)
			) {
				const xyiconWorldPosition = caption.parent.position;
				const captionMeshWorldPosition = caption.position;
				const pdfStartPosition = this.spaceCoordToPDFCoord(xyiconWorldPosition.x, xyiconWorldPosition.y, cropBox, pdfRotation, spaceProps);
				const pdfEndPosition = this.spaceCoordToPDFCoord(captionMeshWorldPosition.x, captionMeshWorldPosition.y, cropBox, pdfRotation, spaceProps);

				page.drawLine({
					start: pdfStartPosition,
					end: pdfEndPosition,
					thickness: 1 * correctionMultiplier,
					color: rgb(0, 0, 0),
					dashArray,
				});
			}
		}

		const captionFontConfig = getCaptionFontConfig();
		const xyiconCaptionsAsTextObjects: IObjectWithText[] = [];
		const boundaryCaptionsAsTextObjects: IObjectWithText[] = [];

		for (const caption of visibleCaptions) {
			let captionsAsTextObjects = xyiconCaptionsAsTextObjects;
			let actualCaptionSettings = xyiconCaptionSettings;

			if (caption.parent.spaceItemType === "boundary") {
				captionsAsTextObjects = boundaryCaptionsAsTextObjects;
				actualCaptionSettings = boundaryCaptionSettings;
			}

			captionsAsTextObjects.push({
				...captionFontConfig,
				id: `${caption.parent.id}_caption`,
				fontColor: actualCaptionSettings.fontColor,
				fontFamily: actualCaptionSettings.fontFamily,
				fontSize: actualCaptionSettings.fontSize,
				backgroundColor: actualCaptionSettings.backgroundColor,
				text: caption.text,
				position: caption.position,
				isVisible: true,
				scale: caption.scale,
				sizeOfGeneratedText: caption.sizeOfGeneratedText,
				orientation: 0,
				textInstanceIds: [],
				isCaption: true,
			});
		}

		// Add captions

		this.drawCaptions(page, boundaryCaptionsAsTextObjects, spaceProps);
		this.drawCaptions(page, xyiconCaptionsAsTextObjects, spaceProps);
	}

	private populateCatalogCache(page: PDFPage, spaceProps: ISpaceProps) {
		const pdfRotation = toRadians(page.getRotation());
		const visibleXyicons = this.getFilteredVisibleItemsForSpace(XyiconFeature.Xyicon, spaceProps.id) as Xyicon[];

		for (const xyicon of visibleXyicons) {
			const pdfOrientation = -xyicon.orientation - pdfRotation; // the direction is the other way in PDF...
			const cacheItemId = this.getCacheItemId(xyicon, pdfOrientation, isGrayScaled);

			if (!this._catalogCache[cacheItemId]) {
				this._catalogCache[cacheItemId] = new Promise<{base64: string; xyiconSize: Dimensions; unrotatedBBox: IBox3}>(async (resolve, reject) => {
					const {image, boundingBox: unrotatedBBox} = await ImageUploadPreprocessor.getTopToBottomSnapshotOfXyiconOrCatalog(
						xyicon,
						this._actions,
						this._actions.getFeatureItemById(spaceProps.id, XyiconFeature.Space),
						spaceProps.correctionMultiplier,
					);
					const rotatedBBox = THREEUtils.getRotatedBox3(unrotatedBBox, xyicon.orientation);
					const rotatedBBoxSize = THREEUtils.getSizeOfBoundingBox3(rotatedBBox);
					const img = await ImageUtils.loadImage(image);
					const filter = isGrayScaled ? "grayscale(100%)" : "";
					const base64 = ImageUtils.rotateImage(img, pdfOrientation * MathUtils.RAD2DEG, filter);

					resolve({base64, xyiconSize: rotatedBBoxSize, unrotatedBBox});
				});
			}
		}

		return {
			catalogCache: this._catalogCache,
			visibleXyicons,
		};
	}

	private getCacheItemId = (xyicon: Xyicon, pdfOrientation: number, isGrayScaled: boolean) => {
		pdfOrientation = pdfOrientation % (2 * Math.PI);
		if (pdfOrientation < 0) {
			pdfOrientation += 2 * Math.PI;
		}

		// If we rotate the icons within the "page.drawImage" function, the edges of the image become strange (some odd artifacts can appear)
		// So we create the rotation within the raster image instead
		return `${xyicon.catalogId}_${xyicon.isFlippedX}_${xyicon.isFlippedY}_${pdfOrientation}_${isGrayScaled}`;
	};

	private getCorrectedXyiconPos(xyicon: Xyicon): PointDouble {
		const offset = xyicon.catalog?.insertionInfo ?? getDefaultInsertionInfo();
		const rotatedOffset = THREEUtils.getRotatedVertex({x: offset.offsetX, y: offset.offsetY}, xyicon.orientation, {x: 0, y: 0});

		return {
			x: xyicon.iconX + rotatedOffset.x,
			y: xyicon.iconY + rotatedOffset.y,
		};
	}

	private async addXyiconsToPDF(page: PDFPage, spaceProps: ISpaceProps, visibleXyicons: Xyicon[]) {
		const cropBox = page.getCropBox();
		const pdfRotation = toRadians(page.getRotation());

		for (const xyicon of visibleXyicons) {
			const pdfOrientation = -xyicon.orientation - pdfRotation; // the direction is the other way in PDF...

			// If we rotate the icons within the "page.drawImage" function, the edges of the image become strange (some odd artifacts can appear)
			// So we create the rotation within the raster image instead
			const cacheItemId = this.getCacheItemId(xyicon, pdfOrientation, isGrayScaled);
			const pdfToSpaceRatio = this.pdfToSpaceRatio(cropBox, spaceProps.size);
			const {base64, unrotatedBBox} = await this._catalogCache[cacheItemId];
			const xyiconScale = THREEUtils.getSizeOfBoundingBox3(unrotatedBBox);
			const pdfImage = await this.embedImageIntoPage(page, base64);
			const correctedXyiconsPos = this.getCorrectedXyiconPos(xyicon);
			const pdfCoord = this.spaceCoordToPDFCoord(correctedXyiconsPos.x, correctedXyiconsPos.y, cropBox, pdfRotation, spaceProps);

			// If we rotate the image in the "drawImage" call, the edges become rugged (aliased) for some reason
			// So we rotate the image before, but this way, the overall image needs to be larger, when rotated (size of AABB is increased)
			const rotationMultiplicator = Math.abs(Math.cos(pdfOrientation)) + Math.abs(Math.sin(pdfOrientation));
			const xyiconSizeInPdf = Math.max(xyiconScale.x, xyiconScale.y) * pdfToSpaceRatio * rotationMultiplicator;

			page.drawImage(pdfImage, {
				x: pdfCoord.x - xyiconSizeInPdf / 2,
				y: pdfCoord.y - xyiconSizeInPdf / 2,
				width: xyiconSizeInPdf,
				height: xyiconSizeInPdf,
			});
		}
	}

	private addBoundariesToPDF(page: PDFPage, spaceProps: ISpaceProps, visibleBoundaries: BoundarySpaceMap[]) {
		const cropBox = page.getCropBox();
		const pdfRotation = toRadians(page.getRotation());

		for (const boundarySpaceMap of visibleBoundaries) {
			this.drawSvgPathByBoundarySpaceMap(boundarySpaceMap, page, cropBox, pdfRotation, spaceProps);
		}
	}

	private async loadNecessarySVGs(visibleMarkups: Markup[]) {
		if (visibleMarkups.some((m) => m.type === MarkupType.Photo360)) {
			const photo360IconUrl = "src/assets/images/spaceviewer/markup-photo-360.svg";
			this._photo360SVG = await XHRLoader.loadAsync({url: photo360IconUrl, json: false});
		}
	}

	private async initFontIfNeeded(pdf: PDFDocument, visibleXyicons: Xyicon[], visibleBoundaries: BoundarySpaceMap[], visibleMarkups: Markup[]) {
		const fontNamesAndFiles: {
			[key in SupportedFontName]: string;
		} = {
			Arial: "Arial-Regular",
			Georgia: "Georgia-Regular",
			OpenSans: "OpenSans-Regular",
			Roboto: "Roboto-Bold",
			Lobster: "Lobster-Regular",
			Caveat: "Caveat-Regular",
			SourceCodePro: "SourceCodePro-Regular",
		};

		const necessaryFontNames = this.getNecessaryFontNames(visibleXyicons, visibleBoundaries, visibleMarkups);

		for (const necessaryFontName of necessaryFontNames) {
			const fontBytes = await fetch(`src/assets/fonts/${necessaryFontName}/${fontNamesAndFiles[necessaryFontName]}.ttf`).then((res) =>
				res.arrayBuffer(),
			);

			pdf.registerFontkit(fontkit);
			this._fonts[necessaryFontName] = await pdf.embedFont(fontBytes);
		}
	}

	private copyPageWithoutContent(pdfDocToAddTheNewPageTo: PDFDocument, pageToCopy: PDFPage) {
		const previousPageSize = pageToCopy.getSize();
		const previousPageArtBox = pageToCopy.getArtBox();
		const previousPageBleedBox = pageToCopy.getBleedBox();
		const previousPageCropBox = pageToCopy.getCropBox();
		const previousPageMediaBox = pageToCopy.getMediaBox();
		const previousPageTrimBox = pageToCopy.getTrimBox();
		const previousPageRotation = pageToCopy.getRotation();

		const newPage = PDFPage.create(pdfDocToAddTheNewPageTo);

		newPage.setSize(previousPageSize.width, previousPageSize.height);
		newPage.setArtBox(previousPageArtBox.x, previousPageArtBox.y, previousPageArtBox.width, previousPageArtBox.height);
		newPage.setBleedBox(previousPageBleedBox.x, previousPageBleedBox.y, previousPageBleedBox.width, previousPageBleedBox.height);
		newPage.setCropBox(previousPageCropBox.x, previousPageCropBox.y, previousPageCropBox.width, previousPageCropBox.height);
		newPage.setMediaBox(previousPageMediaBox.x, previousPageMediaBox.y, previousPageMediaBox.width, previousPageMediaBox.height);
		newPage.setTrimBox(previousPageTrimBox.x, previousPageTrimBox.y, previousPageTrimBox.width, previousPageTrimBox.height);
		newPage.setRotation(previousPageRotation);

		pdfDocToAddTheNewPageTo.addPage(newPage);

		return newPage;
	}

	private setCropBox(page: PDFPage, area: "entire" | "visible", spaceProps: ISpaceProps) {
		if (area === "visible") {
			const previousBoxes = boxTypes.map((boxType: BoxType) => ({
				label: boxType,
				value: page[`get${boxType}`](),
			}));

			const pdfRot = page.getRotation();
			const pdfRotation = toRadians(pdfRot);

			for (const previousBox of previousBoxes) {
				const cameraControls = this._appState.app.graphicalTools.spaceViewRenderer.toolManager.cameraControls;
				const camera = cameraControls.activeCamera as OrthographicCamera;

				const cameraCenterInPDFCoords = this.spaceCoordToPDFCoord(camera.position.x, camera.position.y, previousBox.value, pdfRotation, spaceProps);

				const cameraBottomLeftInPDFCoords = this.spaceCoordToPDFCoord(
					camera.position.x + camera.left / camera.zoom,
					camera.position.y + camera.bottom / camera.zoom,
					previousBox.value,
					pdfRotation,
					spaceProps,
				);
				const cameraTopRightInPDFCoords = this.spaceCoordToPDFCoord(
					camera.position.x + camera.right / camera.zoom,
					camera.position.y + camera.top / camera.zoom,
					previousBox.value,
					pdfRotation,
					spaceProps,
				);

				const viewPortSizeInPDFCoords = {
					x: cameraTopRightInPDFCoords.x - cameraBottomLeftInPDFCoords.x,
					y: cameraTopRightInPDFCoords.y - cameraBottomLeftInPDFCoords.y,
				};

				const cameraCenterInPDFNormalized = {
					x: (cameraCenterInPDFCoords.x - previousBox.value.x) / previousBox.value.width,
					y: (cameraCenterInPDFCoords.y - previousBox.value.y) / previousBox.value.height,
				};

				page[`set${previousBox.label}`](
					previousBox.value.x + cameraCenterInPDFNormalized.x * previousBox.value.width - viewPortSizeInPDFCoords.x / 2,
					previousBox.value.y + cameraCenterInPDFNormalized.y * previousBox.value.height - viewPortSizeInPDFCoords.y / 2,
					viewPortSizeInPDFCoords.x,
					viewPortSizeInPDFCoords.y,
				);
			}
		}
	}

	private getNecessaryFontNames(visibleXyicons: Xyicon[], visibleBoundaries: BoundarySpaceMap[], visibleMarkups: Markup[]): SupportedFontName[] {
		const necessaryFontNames: Set<SupportedFontName> = new Set<SupportedFontName>();

		const captionedItems = [...visibleXyicons, ...visibleBoundaries];

		const activeCaptionFields = {
			[XyiconFeature.Xyicon]: getActiveCaptionFields(XyiconFeature.Xyicon, this._actions),
			[XyiconFeature.Boundary]: getActiveCaptionFields(XyiconFeature.Boundary, this._actions),
		};

		const standardFontFamily: SupportedFontName = "Roboto";

		for (const item of visibleXyicons) {
			if (item.embeddedXyicons.length > 0 || this._actions.doesXyiconHaveLinkIcon(item)) {
				// For embedded xyicon counters;
				// Or xyicons with links (we're using character '8' for the link icon for now...)
				if (!necessaryFontNames.has(standardFontFamily)) {
					necessaryFontNames.add(standardFontFamily);
					break;
				}
			}
		}

		const captionSettings = this._spaceEditorView.spaceEditorViewSettings.captions.xyicon;

		for (const item of captionedItems) {
			const captionFieldValues = this._actions.getCaptionFieldValues(activeCaptionFields[item.ownFeature], item);

			for (const captionFieldValue of captionFieldValues) {
				const fontFamily = captionFieldValue.style?.fontFamily ?? captionSettings.fontFamily;

				if (!necessaryFontNames.has(fontFamily)) {
					necessaryFontNames.add(fontFamily);
				}
			}
		}

		const spaceViewRenderer = this._spaceViewRenderer;

		for (const markup of visibleMarkups) {
			const isMarkupOnMountedSpace = spaceViewRenderer.space?.id === markup.spaceId;
			const markup3D = isMarkupOnMountedSpace
				? (spaceViewRenderer.markupManager.getItemById(markup.id) as Markup3D)
				: spaceViewRenderer.markupManager.createSpaceItem3DFromModel(markup);

			if (markup3D.textContent && markup3D.fontFamily && !necessaryFontNames.has(markup3D.fontFamily)) {
				necessaryFontNames.add(markup3D.fontFamily);
			}

			if (!isMarkupOnMountedSpace) {
				markup3D.destroy(false);
			}
		}

		return [...necessaryFontNames];
	}

	private getFilteredVisibleItemsForSpace(feature: XyiconFeature, spaceId: string): IModel[] {
		const filters = this._spaceEditorView.filters;

		if (feature === XyiconFeature.Boundary) {
			const isBoundarySpaceMapInSpace = (bsm: BoundarySpaceMap) => bsm.spaceId === spaceId;

			const unfilteredItemsForSpace = this._actions
				.getList<Boundary>(feature)
				.filter((boundary: Boundary) => [...boundary.boundarySpaceMaps].some(isBoundarySpaceMapInSpace));
			let filteredItemsForSpace: IModel[] = filterModels(unfilteredItemsForSpace, filters, this._appState, XyiconFeature.SpaceEditor);

			filteredItemsForSpace = (filteredItemsForSpace as Boundary[]).flatMap((b) => [...b.boundarySpaceMaps]).filter(isBoundarySpaceMapInSpace);

			return filteredItemsForSpace.filter((item) => !this.isItemHiddenByLayerSettings(item as BoundarySpaceMap));
		} else {
			const unfilteredItemsForSpace = this._actions.getList(feature).filter((item) => item.spaceId === spaceId);
			let visibleItemsForSpace = unfilteredItemsForSpace;

			if (feature === XyiconFeature.Xyicon) {
				visibleItemsForSpace = visibleItemsForSpace.filter((xyicon: Xyicon) => !xyicon.isEmbedded);
				visibleItemsForSpace = filterModels(visibleItemsForSpace, filters, this._appState, XyiconFeature.SpaceEditor);
			} else if (feature === XyiconFeature.Markup) {
				const hiddenMarkupColors = new Set(this._spaceEditorView.spaceEditorViewSettings.layers.hiddenMarkupColors);

				visibleItemsForSpace = visibleItemsForSpace.filter((markup: Markup) => !isMarkupFilteredOutByColor(hiddenMarkupColors, markup));

				if (spaceId === this._appState.space?.id) {
					// Measure tool...
					const tempMarkups = this._spaceViewRenderer.markupManager.items.array.filter((markup: Markup3D) => markup.isTemp) as Markup3D[];

					visibleItemsForSpace.push(...tempMarkups.map((markup: Markup3D) => markup.modelData));
				}
			}

			return visibleItemsForSpace.filter((item) => !this.isItemHiddenByLayerSettings(item as Xyicon | Markup));
		}
	}

	private isItemHiddenByLayerSettings(item: Xyicon | BoundarySpaceMap | Markup): boolean {
		return this._spaceEditorView.spaceEditorViewSettings.layers[this.getItemName(item)].included[
			item.ownFeature === XyiconFeature.Markup ? item.type : item.typeName
		].isHidden;
	}

	private getItemName(item: Xyicon | BoundarySpaceMap | Markup) {
		switch (item.ownFeature) {
			case XyiconFeature.Xyicon:
				return "xyicon";
			case XyiconFeature.Boundary:
				return "boundary";
			case XyiconFeature.Markup:
				return "markup";
		}
	}

	private get _actions() {
		return this._appState.actions;
	}

	private get _spaceEditorView() {
		return this._appState.actions.getSelectedView(XyiconFeature.SpaceEditor);
	}

	private async createNewPDFDocFromSpace(space: Space) {
		const arrayBuffer = await fetch(space.selectedSpaceFile.sourceFileURL).then((res) => res.arrayBuffer());
		let pdfDoc = await PDFDocument.load(arrayBuffer, {ignoreEncryption: true});

		PDFExporter.setPDFProperties(pdfDoc);

		const isBackgroundHidden = this._spaceEditorView.spaceEditorViewSettings.layers.background.isHidden;

		if (isBackgroundHidden) {
			// The PDF size remains large, even if we remove the existing page from it.
			// It seems, it somehow stores the page's data in the pdf document
			// and I couldn't find an option to "destroy" the page completely
			// As a workaround, we create a new pdfdocument, and add the empty page into it

			const newPDFDoc = await PDFDocument.create();

			PDFExporter.setPDFProperties(newPDFDoc);
			let page = pdfDoc.getPage(0);
			const newPage = this.copyPageWithoutContent(newPDFDoc, page);

			page = newPage;
			pdfDoc = newPDFDoc;
		}

		return pdfDoc;
	}

	public async export(area: "entire" | "visible", spaces: Space[], pdfName: string, progressFeedback?: (progress: number) => void) {
		const pdfRenderer = new PDFRenderer(document.createElement("canvas")); // to get the PDF's height from the aspect ratio

		let pdfDoc = await this.createNewPDFDocFromSpace(spaces[0]);

		for (let i = 0; i < spaces.length; ++i) {
			// Clear cache
			// Every page can be different in size, so it can cause troubles
			// To load items from the cache, because often a different size from
			// The same xyicon is needed
			this._catalogCache = {};
			this._imageMap.clear();

			const space = spaces[i];

			if (i > 0) {
				const tempPdfDoc = await this.createNewPDFDocFromSpace(space);
				const [copiedPage] = await pdfDoc.copyPages(tempPdfDoc, [0]);

				pdfDoc.addPage(copiedPage);
			}
			const page = pdfDoc.getPage(pdfDoc.getPageCount() - 1);

			const {selectedSpaceFile} = space;

			await pdfRenderer.init(selectedSpaceFile.sourceFileURL, selectedSpaceFile.insertionInfo.width);

			const spaceSize: PointDouble = {
				x: pdfRenderer.spaceSize.width,
				y: pdfRenderer.spaceSize.height,
			};

			const spaceOffset = {
				x: selectedSpaceFile.insertionInfo.offsetX,
				y: selectedSpaceFile.insertionInfo.offsetY,
			};

			const spaceViewRendererCorrectionMultiplier = calculateCorrectionMultiplier(
				selectedSpaceFile.parent.spaceUnitsPerMeter,
				selectedSpaceFile.insertionInfo,
				selectedSpaceFile.parent.type.settings.xyiconSize,
				SpaceEditorMode.NORMAL,
			);

			const spaceProps: ISpaceProps = {
				id: space.id,
				size: spaceSize,
				offset: spaceOffset,
				correctionMultiplier: spaceViewRendererCorrectionMultiplier,
			};

			const {visibleXyicons} = this.populateCatalogCache(page, spaceProps);
			const visibleBoundaries = this.getFilteredVisibleItemsForSpace(XyiconFeature.Boundary, spaceProps.id) as BoundarySpaceMap[];
			const visibleMarkups = this.getFilteredVisibleItemsForSpace(XyiconFeature.Markup, spaceProps.id) as Markup[];

			await this.initFontIfNeeded(pdfDoc, visibleXyicons, visibleBoundaries, visibleMarkups);
			await this.loadNecessarySVGs(visibleMarkups);
			this.addMarkupsToPDF(page, spaceProps, visibleMarkups);
			await this.addConditionalFormattingElementsForBottomLayer(page, spaceProps, visibleXyicons, visibleBoundaries);
			this.addLinkLinesToPDF(page, spaceProps);
			await this.addCaptionsToPDF(page, spaceProps, visibleXyicons, visibleBoundaries);
			await this.addXyiconsToPDF(page, spaceProps, visibleXyicons);
			await this.addConditionalFormattingElementsForTopLayer(page, spaceProps, visibleXyicons);
			await this.addEmbeddedXyiconCounters(page, spaceProps, visibleXyicons);
			await this.addLinkIconsToXyicons(page, spaceProps, visibleXyicons);
			this.addBoundariesToPDF(page, spaceProps, visibleBoundaries);

			this.setCropBox(page, area, spaceProps);

			progressFeedback?.((i + 1) / spaces.length);
		}

		FileUtils.downloadFileGivenByData(await pdfDoc.save(), `${pdfName}.pdf`, "application/pdf");
	}
}
