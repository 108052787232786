import {computed, observable, makeObservable} from "mobx";
import type {AppState} from "../state/AppState";
import {XyiconFeature} from "../../generated/api/base";
import type {PortfolioDto, PortfolioFieldUpdateDto} from "../../generated/api/base";
import type {IModel} from "./Model";

export class Portfolio implements IModel, PortfolioDto {
	public static search(portfolio: Portfolio, search = "") {
		if (!search) {
			return true;
		}

		search = search.toLowerCase();

		const refId = portfolio.refId.toLowerCase();

		if (refId.indexOf(search) > -1) {
			return true;
		}

		const name = portfolio.name.toLowerCase();

		if (name.indexOf(search) > -1) {
			return true;
		}

		return false;
	}

	public readonly ownFeature = XyiconFeature.Portfolio;

	@observable
	private _data: PortfolioDto;

	private _appState: AppState;

	constructor(data: PortfolioDto, appState: AppState) {
		makeObservable(this);
		this._data = data;
		this._appState = appState;
		(this._data.fieldData as Record<string, any>) = this._data.fieldData || {};
	}

	public applyUpdate(data: PortfolioFieldUpdateDto) {
		if (data.portfolioIDList.includes(this.id)) {
			this.applyFieldValues(data.fieldValues);
			this._data.lastModifiedAt = data.lastModifiedAt || this._data.lastModifiedAt;
			this._data.lastModifiedBy = data.lastModifiedBy || this._data.lastModifiedBy;
		}
	}

	public applyFieldValues(fieldValues: Record<string, any> | null) {
		if (fieldValues?.name !== undefined) {
			this.name = fieldValues.name;
		}
	}

	@computed
	public get id() {
		return this._data.portfolioID;
	}

	@computed
	public get refId() {
		return this._data.portfolioRefID;
	}

	public set name(value: string) {
		this._data.name = value;
	}

	@computed
	public get name() {
		return this._data.name ?? "";
	}

	// public get author()
	// {
	// 	return "John Doe";
	// }

	public set typeId(value: string) {
		this._data.portfolioTypeID = value;
	}

	@computed
	public get typeId() {
		return this._data.portfolioTypeID;
	}

	@computed
	public get type() {
		return this._appState.actions.getTypeById(this.typeId);
	}

	@computed
	public get typeName() {
		return this.type?.name || "";
	}

	@computed
	public get fieldData() {
		return this._data.fieldData || {};
	}

	public get status() {
		return "Active"; //return this._data.isDeleted ? "Deleted" : "Active";
	}

	@computed
	public get lastModifiedBy() {
		return this._data.lastModifiedBy;
	}

	@computed
	public get lastModifiedAt() {
		return this._data.lastModifiedAt;
	}

	@computed
	public get data() {
		return this._data;
	}
}
