import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IFieldColumn, Report} from "../../../../../../../../data/models/Report";
import {ReportSortDirection} from "../../../../../../../../data/models/Report";
import type {IFieldAdapter} from "../../../../../../../../data/models/field/Field";
import {AppFieldActions} from "../../../../../../../../data/state/AppFields";
import type {AppState} from "../../../../../../../../data/state/AppState";
import {FieldDataType} from "../../../../../../../../generated/api/base";
import {ReportType} from "../../../../../../../../generated/api/reports";
import type {OutputDataSortField} from "../../../../../../../../generated/api/reports";
import {StringUtils} from "../../../../../../../../utils/data/string/StringUtils";
import {SelectInputV5} from "../../../../../../input/select/SelectInputV5";
import {ButtonV5} from "../../../../../../button/ButtonV5";
import CirclePlusIcon from "../../../../../../icons/circle-plus.svg?react";
import TrashIcon from "../../../../../../icons/trash.svg?react";
import LinkIcon from "../../../../../../icons/link.svg?react";
import {IconButtonV5} from "../../../../../../interaction/IconButtonV5";
import {colorPalette} from "../../../../../../styles/colorPalette";
import {ReportSortOrderStyled} from "./ReportSortOrderV5.style";

interface IReportSortOrderProps {
	readonly report: Report;
	readonly appState?: AppState;
}

@inject("appState")
@observer
export class ReportSortOrderV5 extends React.Component<IReportSortOrderProps> {
	public static isFieldSupported(field: IFieldAdapter): boolean {
		// Temporarily disable date fields until backend api supports it (except lastModifiedAt which is supported)
		if (field?.dataType === FieldDataType.DateTime && !AppFieldActions.isLastModifiedAt(field.refId)) {
			return false;
		}
		return true;
	}

	private _sortDirections = [
		{
			id: ReportSortDirection.ASC,
			label: "Ascending",
		},
		{
			id: ReportSortDirection.DESC,
			label: "Descending",
		},
	];

	private onAddClicked = () => {
		const firstField = this.getFields()[0];

		if (firstField) {
			(this.props.report.sortFields as (OutputDataSortField & {linkedField?: boolean})[]).push({
				name: firstField.field.refId,
				direction: ReportSortDirection.ASC,
				linkedField: false,
			});
		}
	};

	private onRemove = (sortField: OutputDataSortField, linkedField?: boolean) => {
		this.props.report.removeSortField(sortField, linkedField);
	};

	public clearAll = () => {
		this.props.report.sortFields = [];
	};

	private getFields() {
		const {report, appState} = this.props;
		const fieldColumns: IFieldColumn[] = report.displayedFields
			.map((fieldColumn) => ({
				field: appState.actions.getFieldByRefId(fieldColumn.field),
				linkedField: false,
			}))
			.filter((f) => f.field)
			.sort((a, b) => StringUtils.sortIgnoreCase(appState.actions.getFieldTitle(a.field.refId), appState.actions.getFieldTitle(b.field.refId)))
			.filter((fieldColumn) => {
				if (!fieldColumn.field) {
					return false;
				}

				return ReportSortOrderV5.isFieldSupported(fieldColumn.field);
			});

		if (report.type !== ReportType.UserDefinedLinkedXyiconReport) {
			return fieldColumns;
		}

		return [
			...fieldColumns,
			...report.displayedLinkedFields
				.map((f) => ({
					field: appState.actions.getFieldByRefId(f.field),
					linkedField: true,
				}))
				.sort((a, b) => StringUtils.sortIgnoreCase(appState.actions.getFieldTitle(a.field.refId), appState.actions.getFieldTitle(b.field.refId)))
				.filter((fieldColumn) => {
					if (!fieldColumn.field) {
						return false;
					}

					return ReportSortOrderV5.isFieldSupported(fieldColumn.field);
				}),
		];
	}

	private renderFieldOption = (fieldColumn: IFieldColumn) => {
		return (
			<div className="hbox alignCenter fieldOption">
				<span>{this.props.appState.actions.getFieldTitle(fieldColumn.field.refId)}</span>
				{fieldColumn.linkedField && (
					<LinkIcon
						color={colorPalette.positive.c300}
						className="linkIcon"
					/>
				)}
			</div>
		);
	};

	private onSelectSortField = (sortField: OutputDataSortField & {linkedField?: boolean}, selectedField: IFieldColumn, linkedField?: boolean) => {
		const {report} = this.props;
		let tempSortField: OutputDataSortField & {linkedField?: boolean} = null;

		if (!linkedField && !selectedField.linkedField) {
			sortField.name = selectedField.field.refId;
			sortField.linkedField = false;
		} else if (!!linkedField && !!selectedField.linkedField) {
			sortField.name = selectedField.field.refId;
			sortField.linkedField = true;
		} else if (!linkedField && !!selectedField.linkedField) {
			tempSortField = {
				name: selectedField.field.refId,
				linkedField: true,
				direction: sortField.direction,
			};

			report.removeSortField(sortField);
			report.sortLinkedFields.push(tempSortField);
		} else if (!!linkedField && !selectedField.linkedField) {
			tempSortField = {
				name: selectedField.field.refId,
				linkedField: false,
				direction: sortField.direction,
			};

			report.removeSortField(sortField, true);
			report.sortFields.push(tempSortField);
		}
	};

	private renderOption = (
		appState: AppState,
		fieldColumns: IFieldColumn[],
		sortField: OutputDataSortField,
		option: {id: ReportSortDirection; label: string},
	) => {
		let label = option.label;
		const field = fieldColumns.find((fc) => fc.field.refId === sortField.name).field;

		if (field.dataType === FieldDataType.Boolean) {
			label = appState.actions.getBooleanFieldCustomLabelSettingsByReportSortDirection(field, option.id);
		}

		return label;
	};

	public override render() {
		const {report, appState} = this.props;
		const fieldColumns = this.getFields();

		return (
			<ReportSortOrderStyled className="ReportSortOrder">
				<div style={{fontSize: "16px", padding: "16px 0", color: colorPalette.gray.c950}}>Add fields to sort by</div>
				<div className="ReportSortContainer">
					{report.sortFields.map((sortField, index) => (
						<div
							key={index}
							className="sortField"
						>
							<SelectInputV5
								sort={false}
								options={fieldColumns}
								selected={fieldColumns.find((f) => f.field.refId === sortField.name && !!f.linkedField === false)}
								onChange={(fieldColumn) => this.onSelectSortField(sortField, fieldColumn)}
								render={this.renderFieldOption}
								stringifyOption={(fieldColumn) => fieldColumn.field.name}
								isSameWidth={true}
							/>
							<SelectInputV5
								className="sort"
								options={this._sortDirections}
								selected={this._sortDirections.find((option) => option.id === sortField.direction)}
								onChange={(option) => (sortField.direction = option.id)}
								render={(option) => this.renderOption(appState, fieldColumns, sortField, option)}
								isSameWidth={true}
							/>
							<IconButtonV5
								IconComponent={TrashIcon}
								title="Delete"
								onClick={() => {
									this.onRemove(sortField);
								}}
							/>
						</div>
					))}
					{report.sortLinkedFields.map((sortField, index) => (
						<div
							key={index}
							className="sortField"
						>
							<SelectInputV5
								sort={false}
								options={fieldColumns}
								selected={fieldColumns.find((f) => f.field.refId === sortField.name && !!f.linkedField === true)}
								onChange={(fieldColumn) => this.onSelectSortField(sortField, fieldColumn, true)}
								render={this.renderFieldOption}
								stringifyOption={(fieldColumn) => fieldColumn.field.name}
								isSameWidth={true}
							/>
							<SelectInputV5
								className="sort"
								options={this._sortDirections}
								selected={this._sortDirections.find((option) => option.id === sortField.direction)}
								onChange={(option) => (sortField.direction = option.id)}
								render={(option) => this.renderOption(appState, fieldColumns, sortField, option)}
								isSameWidth={true}
							/>
							<IconButtonV5
								IconComponent={TrashIcon}
								title="Delete"
								onClick={() => {
									this.onRemove(sortField, true);
								}}
							/>
						</div>
					))}
				</div>
				{
					<div className="btn-container">
						{this.props.report.sortFields.length > 0 && (
							<span
								className="clear-all"
								onClick={this.clearAll}
							>
								Clear All
							</span>
						)}
						<ButtonV5
							label="ADD"
							type="secondary"
							onClick={this.onAddClicked}
						>
							<CirclePlusIcon />{" "}
						</ButtonV5>
					</div>
				}
			</ReportSortOrderStyled>
		);
	}
}
