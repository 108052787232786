import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import type {IPopupWindowConfig} from "./PopupWindow";
import {PopupWindow} from "./PopupWindow";

/**
 * Can be used as an alternative for `prompt("alertMessage");`
 */
export class PromptWindow extends PopupWindow<string | null> {
	protected static override readonly _defaultConfig: IPopupWindowConfig = {
		ok: "Submit",
		cancel: "Cancel",
		backdrop: false,
		parentElement: document.body,
	};

	protected _okValue = "";
	protected _cancelValue: null = null;

	constructor(
		message: string,
		title: string,
		placeholder: string,
		defaultValue: string,
		config: IPopupWindowConfig = {additionalElements: document.createElement("input")},
	) {
		super({
			title: title,
			message: message,
			config: ObjectUtils.mergeConfig(PromptWindow._defaultConfig, config),
		});

		this._config.additionalElements = document.createElement("input");
		(this._config.additionalElements as HTMLInputElement).placeholder = placeholder;
		(this._config.additionalElements as HTMLInputElement).value = defaultValue;
		this._okValue = defaultValue;
		this._config.additionalElements.oninput = this.onInputFieldChange;

		requestAnimationFrame(() => {
			if (this._config.additionalElements?.parentElement) {
				this._config.additionalElements.focus();
			}
		});
	}

	private onInputFieldChange = (event: Event) => {
		this._okValue = (event.currentTarget as HTMLInputElement).value;
	};

	public static open(message: string, title: string = "Submit", placeholder: string = "", defaultValue: string = "", config?: IPopupWindowConfig) {
		return new PromptWindow(message, title, placeholder, defaultValue, config).open() as Promise<string>;
	}
}
