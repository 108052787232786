import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {TransportLayer} from "../../../../data/TransportLayer";
import type {AppState} from "../../../../data/state/AppState";
import {DomUtils} from "../../../../utils/dom/DomUtils";
import {ReactUtils} from "../../../utils/ReactUtils";
import {ToggleSwitchV5} from "../switch/ToggleSwitchV5";
import ArrowDownIcon from "../../icons/chevron-down.svg?react";
import PlusIcon from "../../icons/plus.svg?react";
import {SideBar} from "../../../sidebar/SideBar";
import {InfoButtonV5} from "../../button/InfoButtonV5";
import {colorPalette} from "../../styles/colorPalette";
import {baseDistance, FLEXCENTER, FlexCenterStyle, fontSize, fontWeight, radius, VerticalFlexStyle, zIndex} from "../../styles/styles";
import {DropdownButtonStyled, DropdownButtonV5} from "../../interaction/DropdownButtonV5";
import ThreeDotIcon from "../../icons/dots-horizontal.svg?react";
import type {IDropdownOption} from "../../interaction/DropdownOptionsV5";
import {IconButtonV5} from "../../interaction/IconButtonV5";
import {FieldStyled} from "../../details/Field.styled";
import {ButtonStyled, ButtonV5} from "../../button/ButtonV5";
import CameraIcon from "../../icons/camera.svg?react";

const toggleContainerMainTitlesWithoutDocuments: Set<string> = new Set<string>([
	"Fields",
	"AssignByCatalog",
	"AssignByXyicon",
	"Spaces",
	"Links",
	"Ports",
	"Port Template",
]);

interface IToggleContainerProps<T> {
	readonly title: React.ReactNode;
	readonly children: React.ReactNode;
	// Optional, if you want to manage state externally, or use as the default state:
	readonly open?: boolean;
	readonly onToggleOpen?: (value: boolean) => void;
	readonly onAddClicked?: () => void;
	readonly onWebCamClicked?: () => void;
	readonly className?: string;
	readonly isEnableTogglerVisible?: boolean;
	readonly isEnabled?: boolean;
	readonly onEnabledChange?: (value: boolean) => void;
	readonly scrollIntoViewOnOpen?: boolean;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
	readonly saveStateToLocalStorage?: boolean;
	readonly hideTitle?: boolean;
	readonly dropdownOptions?: IDropdownOption[];
	readonly noPadding?: boolean;
	readonly helperText?: string;
	readonly hasFiltersApplied?: boolean;
	readonly showToggleLabel?: boolean;
	readonly itemCount?: number;
	readonly showItemCount?: boolean;
	readonly customOnAddIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {title?: string}>;
	readonly onAddButtonTitle?: string;
}

interface IToggleContainerState {
	open: boolean;
	isEnabled: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class ToggleContainerV5<T = any> extends React.PureComponent<IToggleContainerProps<T>, IToggleContainerState> {
	private _container = React.createRef<HTMLDivElement>();

	constructor(props: IToggleContainerProps<T>) {
		super(props);
		const openByDefault = this.props.saveStateToLocalStorage ? this.getLocalStorageValueByKey(this.props.title) : !!props.open;

		this.state = {
			open: openByDefault,
			isEnabled: !!props.isEnabled,
		};

		if (this.props.saveStateToLocalStorage) {
			this.saveValueToLocalStorage(this.props.title, openByDefault);
		}
	}

	private getKeyForLocalStorage(organizationId: string, sectionTitle: string) {
		return `srv4-org-${organizationId}-feature-${SideBar.activeNav}-section-${sectionTitle}-state`;
	}

	private getLocalStorageValueByKey(sectionTitle: React.ReactNode): boolean {
		// sections are open by default
		const defaultValue = true;

		const organizationId = this.props.appState.organizationId;

		if (organizationId) {
			const value = this.props.transport.services.localStorage.get(this.getKeyForLocalStorage(organizationId, sectionTitle.toString()));

			return value === false ? false : defaultValue;
		}

		return defaultValue;
	}

	private saveValueToLocalStorage(sectionTitle: React.ReactNode, value: boolean) {
		const organizationId = this.props.appState.organizationId;

		if (organizationId) {
			const titleAsString = sectionTitle.toString();

			if (titleAsString) {
				this.props.transport.services.localStorage.set(this.getKeyForLocalStorage(organizationId, titleAsString), value);
			}
		}
	}

	public onToggleOpen = () => {
		const {onToggleOpen, open, scrollIntoViewOnOpen} = this.props;
		let newOpenValue = !open;

		if (onToggleOpen) {
			onToggleOpen(newOpenValue);
		} else {
			newOpenValue = !this.state.open;
			this.setState({
				open: newOpenValue,
			});
		}

		if (this.props.saveStateToLocalStorage) {
			this.saveValueToLocalStorage(this.props.title, newOpenValue);
		}

		if (scrollIntoViewOnOpen && newOpenValue) {
			// opening now -> scroll into view to make sure the opened content can be seen
			requestAnimationFrame(() => {
				DomUtils.scrollIntoViewIfNeeded(this._container.current);
			});
		}
	};

	private onAddClicked = (event: React.MouseEvent) => {
		event.stopPropagation();
		this.props.onAddClicked?.();
		if (!this.state.open) {
			this.setState({
				open: true,
			});
		}
	};

	private onWebCamClicked = (event: React.MouseEvent) => {
		event.stopPropagation();
		this.props.onWebCamClicked?.();
	};

	private updateOpenState() {
		if (this.props.appState.isDetailsTabBeingOpenByDocumentOfItem) {
			if (toggleContainerMainTitlesWithoutDocuments.has(this.props.title.toString())) {
				if (this.state.open) {
					this.setState({
						open: false,
					});
				}
			} else if (this.props.title.toString() === "Documents") {
				if (!this.state.open) {
					this.setState({
						open: true,
					});
				}
			}

			setTimeout(() => {
				if (this.props.appState.isDetailsTabBeingOpenByDocumentOfItem) {
					this.props.appState.isDetailsTabBeingOpenByDocumentOfItem = false;
				}
			}, 100);
		}
	}

	public override componentDidMount(): void {
		this.updateOpenState();
	}

	public override componentDidUpdate(prevProps: IToggleContainerProps<T>) {
		if (this.props.open !== prevProps.open) {
			this.setState({open: this.props.open});
		}

		this.updateOpenState();
	}

	public override render() {
		const {
			dropdownOptions,
			helperText,
			hasFiltersApplied,
			noPadding,
			className,
			hideTitle,
			children,
			isEnableTogglerVisible,
			showItemCount,
			itemCount,
			onAddClicked,
			onWebCamClicked,
			onEnabledChange,
			title,
			showToggleLabel = true,
			customOnAddIcon,
			onAddButtonTitle = "",
		} = this.props;
		const open = this.props.onToggleOpen ? this.props.open : this.state.open;
		const isEnabled = this.props.onEnabledChange ? this.props.isEnabled : this.state.isEnabled;

		return (
			<ToggleContainerStyled
				ref={this._container}
				className={ReactUtils.cls("ToggleContainer", {[className]: className, open, noPadding})}
				data-cy={`ToggleContainer_${this.props.title}`}
			>
				{!hideTitle && (
					<h4 className="filter-title">
						{!hasFiltersApplied && (
							<ArrowDownIcon
								style={{
									transform: `rotate(${open ? 180 : 0}deg)`,
									transition: "transform 0.2s ease",
									cursor: "pointer",
									padding: 4,
									boxSizing: "content-box",
								}}
								onClick={this.onToggleOpen}
							/>
						)}

						<span
							className="label"
							onClick={this.onToggleOpen}
						>
							{title}
							{helperText && (
								<InfoButtonV5
									bubbleText={helperText}
									infoBubbleStyle={{top: -15, left: "auto"}}
									icon="info"
								/>
							)}
						</span>
						{onWebCamClicked && (
							<ButtonV5
								label=""
								IconComponent={CameraIcon}
								onClick={this.onWebCamClicked}
							/>
						)}
						{onAddClicked && (
							<IconButtonV5
								IconComponent={customOnAddIcon || PlusIcon}
								onClick={this.onAddClicked}
								style={{color: colorPalette.gray.c950}}
								title={onAddButtonTitle}
							/>
						)}
						{!!isEnableTogglerVisible && (
							<ToggleSwitchV5
								value={isEnabled}
								onChange={onEnabledChange}
								noLabel={!showToggleLabel}
							/>
						)}
						{dropdownOptions?.length > 0 && (
							<DropdownButtonV5
								button={<ThreeDotIcon />}
								options={dropdownOptions}
								style={{zIndex: zIndex.contextOptions}}
							/>
						)}
						{showItemCount && <ItemCountContainerStyled>{itemCount}</ItemCountContainerStyled>}
					</h4>
				)}
				<div
					className={ReactUtils.cls("children", {open, fields: this.props.title.toString().includes("Fields")})}
					data-cy="toggle-container"
				>
					{children}
				</div>
			</ToggleContainerStyled>
		);
	}
}

export const ToggleContainerStyled = styled.div`
	.children {
		${VerticalFlexStyle};
		gap: 2px;
	}

	.title {
		padding: 0;
		text-transform: uppercase;
		font-weight: ${fontWeight.bold};
		background: red;
		background-color: transparent;
		flex-flow: row-reverse;
		color: ${colorPalette.primary.c500Primary};

		.btn svg.icon {
			fill: currentColor;
			stroke: none;
			width: 12px;
			height: 12px;
		}
	}

	&.FieldSection {
		.title {
			color: ${colorPalette.gray.c950};
			font-size: ${fontSize.md};
		}

		.children {
			grid-column-gap: ${baseDistance.lg};
			grid-template-rows: 32px;
		}
	}

	h4 {
		${FlexCenterStyle};
		font-family: "Inter", sans-serif;
		font-size: ${fontSize.lg};
		font-weight: ${fontWeight.bold};
		letter-spacing: 0.5px;
		text-transform: uppercase;
		color: ${colorPalette.primary.c500Primary};
		line-height: 16px;
		cursor: pointer;

		.label {
			flex: 1;
		}

		${DropdownButtonStyled} {
			width: 32px;
		}

		${ButtonStyled} {
			margin-left: 4px;
		}
	}

	.children {
		transition: height 0.2s ease;
		height: 0;

		h4 {
			font-size: ${fontSize.xs};
			color: ${colorPalette.gray.c500Primary};

			& > svg {
				width: 16px;
				height: 16px;
			}
		}

		&.open {
			padding: ${baseDistance.sm};
			margin-bottom: ${baseDistance.sm};
			height: initial;
			padding-top: ${baseDistance.sm};

			.children.open {
				${FieldStyled} {
					margin-left: 24px;
				}
			}

			&.fields {
				margin-bottom: 24px;
			}
		}

		&:not(.open) {
			overflow: hidden;
		}
	}

	.threeColumns {
		display: grid;
		grid-template-columns: 0.5fr 0.6fr 1fr;
	}
`;

const ItemCountContainerStyled = styled.div`
	${FLEXCENTER};
	width: 35px;
	height: 35px;
	border-radius: ${radius.sm};
	padding: ${baseDistance.xs} ${baseDistance.sm};
	background-color: ${colorPalette.gray.c100};
`;
