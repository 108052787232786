import type {TransportLayer} from "../TransportLayer";
import type {
	CreateViewRequest,
	MarkViewAsGlobalRequest,
	MarkViewAsPrivateRequest,
	ShowViewOnNavigationRequest,
	UpdateViewRequest,
	ViewDto,
	ViewMarkAsGlobalResultDto,
	ViewShownOnNavigationDto,
} from "../../generated/api/base";
import {ViewPreferenceCategory, XyiconFeature} from "../../generated/api/base";
import {XHRLoader} from "../../utils/loader/XHRLoader";
import {View} from "../models/View";
import {doesItemExistInViewFolderStructure} from "../models/ViewUtils";

export class ViewService {
	private _transport: TransportLayer;

	constructor(transportLayer: TransportLayer) {
		this._transport = transportLayer;
	}

	public async loadAllViews() {
		const appState = this._transport.appState;
		const features = [
			XyiconFeature.Portfolio,
			XyiconFeature.Space,
			XyiconFeature.XyiconCatalog,
			XyiconFeature.Boundary,
			XyiconFeature.Xyicon,
			XyiconFeature.SpaceEditor,
		];

		const {result: viewDataArray} = await this._transport.requestForOrganization<ViewDto[]>({
			url: "views/all",
			method: XHRLoader.METHOD_GET,
		});

		const groupList: {[key: number]: ViewDto[]} = {};

		if (viewDataArray) {
			viewDataArray.forEach((item: ViewDto) => {
				if (!groupList[item.feature]) {
					groupList[item.feature] = [item];
				} else {
					groupList[item.feature].push(item);
				}
			});

			for (const feature of features) {
				const currentViews = appState.views[feature];

				for (const view of currentViews) {
					if (appState.viewsMap[view.id]) {
						delete appState.viewsMap[view.id];
					}
				}

				appState.views[feature] = [];

				const views: View[] = [];

				const allViewData: ViewDto[] = groupList[feature] || [];

				for (const viewData of allViewData) {
					const view = this.createView(viewData);

					views.push(view);
				}

				appState.views[feature] = views;
			}
		}

		this._transport.appState.user?.updateViewFolderStructures();
		this._transport.appState.initAdditionalDefaultViews();
		this._transport.appState.selectViewForFeatureAfterLogin(features);
	}

	public async loadViews(feature: XyiconFeature) {
		const appState = this._transport.appState;
		const currentViews = appState.views[feature];

		if (currentViews.length > 0) {
			// No need to refresh views if already loaded, SignalR listeners takes care of listening to external incoming data
			return;
		}

		const {result: viewDataArray, error} = await this._transport.requestForOrganization<ViewDto[]>({
			url: "views/all",
			params: {
				feature,
			},
			method: XHRLoader.METHOD_GET,
		});

		if (viewDataArray) {
			for (const view of currentViews) {
				if (appState.viewsMap[view.id]) {
					delete appState.viewsMap[view.id];
				}
			}

			appState.views[feature] = [];

			const views: View[] = [];

			for (const viewData of viewDataArray) {
				const view = this.createView(viewData);

				views.push(view);
			}

			appState.views[feature] = views;
		}
	}

	public createView(data: ViewDto, addToList: boolean = true): View {
		const appState = this._transport.appState;
		const view = new View(data, appState);

		if (addToList) {
			if (appState.viewsMap[view.id]) {
				console.warn("View already exists!", view.id);

				return appState.viewsMap[view.id];
			} else {
				appState.views[data.feature].push(view);
				appState.viewsMap[view.id] = view;

				return view;
			}
		} else {
			return view;
		}
	}

	public async create(viewData: CreateViewRequest, feature: XyiconFeature) {
		// const response = {
		// 	viewData: null as ViewDto,
		// 	error: null as IError
		// };

		const params: CreateViewRequest = {
			...viewData,
			feature,
		};

		const response = await this._transport.requestForOrganization<ViewDto>({
			url: "views/create",
			params,
			method: XHRLoader.METHOD_POST,
		});

		if (response.result) {
			this.createView(response.result);
			this._transport.appState.user?.updateViewFolderStructures();
		}

		return response;
	}

	public async markViewAsGlobal(view: View) {
		const originalIsGlobalValue = view.isGlobal;

		view.isGlobal = true;

		const params: MarkViewAsGlobalRequest = {
			viewID: view.id,
		};

		const {result, error} = await this._transport.requestForOrganization<ViewMarkAsGlobalResultDto>({
			url: "views/markasglobal",
			method: XHRLoader.METHOD_POST,
			params,
		});

		if (result) {
			this.updateViews(originalIsGlobalValue, view, result);
		}
	}

	private updateViews(originalIsGlobalValue: boolean, view: View, result: ViewMarkAsGlobalResultDto) {
		if (originalIsGlobalValue !== result.isGlobal) {
			const newViewData: ViewDto = {
				...view.data,
				...result,
			};

			view.applyData(newViewData);
		} else {
			view.isGlobal = originalIsGlobalValue;
		}

		const organization = this._transport.appState.organization;

		if (organization) {
			if (view.isGlobal) {
				const globalViews = [...organization.globalViews];

				if (!doesItemExistInViewFolderStructure(globalViews, view.id)) {
					globalViews.push({id: result.viewID, category: ViewPreferenceCategory.View});
					organization.setGlobalViews(globalViews);
				}
			} else {
				organization.removeElementFromGlobalViews(view.id);
			}
		}
	}

	public async markViewAsPrivate(view: View) {
		const originalIsGlobalValue = view.isGlobal;

		const params: MarkViewAsPrivateRequest = {
			viewID: view.id,
		};

		const {result, error} = await this._transport.requestForOrganization<ViewMarkAsGlobalResultDto>({
			url: "views/markasprivate",
			method: XHRLoader.METHOD_POST,
			params,
		});

		if (result) {
			this.updateViews(originalIsGlobalValue, view, result);
		}
	}

	public async update(viewData: ViewDto) {
		const paramsForShowOnNavigation: ShowViewOnNavigationRequest = {
			viewID: viewData.viewID,
			showOnNavigation: viewData.showOnNavigation,
		};

		await this._transport.requestForOrganization<ViewShownOnNavigationDto>({
			url: "views/showonnavigation",
			method: XHRLoader.METHOD_POST,
			params: paramsForShowOnNavigation,
		});

		const paramsForUpdateView: UpdateViewRequest = {
			...viewData,
		};

		const {result, error} = await this._transport.requestForOrganization<ViewDto>({
			url: "views/update",
			method: XHRLoader.METHOD_PUT,
			params: paramsForUpdateView,
		});

		const view = this._transport.appState.actions.getViewById(viewData.viewID);

		if (view && result) {
			view.applyData(result);
		}
	}

	public async delete(viewId: string) {
		const params = {
			viewIDList: [viewId],
		};

		const {result, error} = await this._transport.requestForOrganization({
			url: "views/delete",
			method: XHRLoader.METHOD_DELETE,
			params: params,
		});

		if (result?.deletedList) {
			this.applyDelete(result.deletedList);
			this._transport.appState.user?.updateViewFolderStructures();
		}

		return result;
	}

	public applyDelete(ids: string[]) {
		const appState = this._transport.appState;
		const views = appState.views;

		for (const id of ids) {
			for (const feature in views) {
				const list = views[feature];
				const index = list.findIndex((view) => view.id === id);

				if (index > -1) {
					// Remove from list
					list.splice(index, 1);

					// Selected view got deleted -> select first view in list
					if (appState.selectedViewId[feature] === id) {
						const viewToSelect = list.length > 0 ? list[0] : appState.defaultViews[feature];

						appState.actions.selectView(viewToSelect);
					}
					break;
				}
			}
		}
	}
}
