import {inject, observer} from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {AppState} from "../../../../../data/state/AppState";
import {UserGroup} from "../../../../../data/models/UserGroup";
import {Permission, XyiconFeature} from "../../../../../generated/api/base";
import {ButtonStyled, ButtonV5} from "../../../button/ButtonV5";
import {ClickToEditInputV5} from "../../../input/clicktoedit/ClickToEditInputV5";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";
import {baseDistance} from "../../../styles/styles";

interface ICreateUserGroupPanelProps {
	readonly onCreated: (id: string) => void;
	readonly creating: boolean;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}
interface ICreateUserGroupPanelState {
	isCreating: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class CreateUserGroupPanelV5 extends React.PureComponent<ICreateUserGroupPanelProps, ICreateUserGroupPanelState> {
	constructor(props: ICreateUserGroupPanelProps) {
		super(props);
		this.state = {
			isCreating: false,
		};
	}

	private createNewUserGroup = () => {
		return new UserGroup({
			userGroupID: "",
			userIDList: [],
			name: "",
			lastModifiedAt: "",
			lastModifiedBy: "",
		});
	};

	private _userGroup = this.createNewUserGroup();

	private onCreateClicked = async () => {
		const data = {
			name: this._userGroup.name,
			userIDList: this._userGroup.userIds,
			shareViewPermission: Permission.Delete,
			canPinToNavigation: true,
		};

		try {
			this.setState({isCreating: false});
			const models = await this.props.transport.services.feature.create(data, XyiconFeature.UserGroup);
			this.props.onCreated(models?.[0]?.id);
			this._userGroup = this.createNewUserGroup();
		} catch (e) {
			console.error(e);
		} finally {
			this.setState({isCreating: false});
		}
	};

	private checkIsButtonDisabled = () => {
		if (this._userGroup.name.trim() === "" || !this.isNameValid(this._userGroup.name) || this.state.isCreating) {
			return true;
		} else {
			return false;
		}
	};

	private onInput = (value: string) => {
		this._userGroup.name = value;
	};

	private isNameValid = (name: string) => {
		const userGroup = this._userGroup;

		return this.props.appState.actions.isUserGroupNameValid(name, userGroup);
	};

	private onKeyUp = (event: KeyboardEvent) => {
		if (event.key === KeyboardListener.KEY_ENTER && !this.checkIsButtonDisabled()) {
			this.onCreateClicked();
		}
	};

	private getErrorMessage = (name: string) => {
		return !name.trim() ? "Name cannot be empty!" : this.isNameValid(name) ? "" : "Value needs to be unique!";
	};

	private removeListeners() {
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
	}

	private addListeners() {
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
	}

	public override componentDidMount() {
		this.addListeners();
	}

	public override componentWillUnmount() {
		this.removeListeners();
	}

	public override render() {
		const userGroup = this._userGroup;
		const isButtonDisabled = this.checkIsButtonDisabled();

		return (
			<CreateUserGroupPanelStyled>
				<ClickToEditInputV5
					value={userGroup.name}
					onLiveChange={this.onInput}
					getErrorMessage={this.getErrorMessage}
					placeholder="Name Value"
					focused={this.props.creating}
				/>
				<ButtonV5
					label="Confirm"
					title="Create"
					onClick={this.onCreateClicked}
					disabled={isButtonDisabled}
				/>
			</CreateUserGroupPanelStyled>
		);
	}
}

export const CreateUserGroupPanelStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding-left: ${baseDistance.sm};
	padding-right: ${baseDistance.sm};

	${ButtonStyled} {
		width: 70px;
		margin-left: 215px;
		margin-right: 0;
	}
`;
