import styled from "styled-components";
import {Observer} from "mobx-react";
import {useRef} from "react";
import type {Markup} from "../../../data/models/Markup";
import type {Color, TextDto} from "../../../generated/api/base";
import {FieldDataType} from "../../../generated/api/base";
import {HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import type {Markup3D} from "../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import {
	MarkupsWithArrowHeads,
	MarkupsWithChangeableLineThickness,
	MarkupsWithCustomizableColor,
	MarkupsWithCustomizableFillOpacity,
	MarkupsWithCustomizableTextAlignment,
	MarkupsWithCustomText,
} from "../../modules/space/spaceeditor/logic3d/elements3d/markups/MarkupStaticElements";
import {TextGroupManager} from "../../modules/space/spaceeditor/logic3d/managers/MSDF/TextGroupManager";
import type {SpaceViewRenderer} from "../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {ColorSelectorV5} from "../colors/ColorSelectorV5";
import {ClickToEditInputStyled, ClickToEditInputV5} from "../input/clicktoedit/ClickToEditInputV5";
import {SelectInputStyled, SelectInputV5} from "../input/select/SelectInputV5";
import {ArrowHeadSizeChangerV5, MarkupSelectInputStyled} from "../modules/spaceeditor/spaceactionbar/ArrowHeadSizeChangerV5";
import {FillButtonV5} from "../modules/spaceeditor/spaceactionbar/FillButtonV5";
import {LineThicknessChangerV5} from "../modules/spaceeditor/spaceactionbar/LineThicknessChangerV5";
import {FontStyleContainerV5} from "../text/FontStyleContainerV5";
import {baseDistance, fontWeight} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";
import CancelIcon from "../icons/xmark.svg?react";
import {ReactUtils} from "../../utils/ReactUtils";
import {TextBoxAlignButtonV5} from "../text/TextBoxAlignButtonV5";
import {FillOptionsStyled} from "../modules/spaceeditor/spaceactionbar/FillOptionsV5";
import {TextAlignOptionsStyled} from "../text/TextAlignOptions.styled";
import {SizeChangerStyled, SizeChangerV5} from "../text/SizeChangerV5";
import {Constants} from "../../modules/space/spaceeditor/logic3d/Constants";
import {MarkupManager} from "../../modules/space/spaceeditor/logic3d/managers/spaceitems/MarkupManager";
import {FieldV5} from "./FieldV5";

interface ICaptionSettingsProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly markups: Markup3D[];
}

export const MarkupPropertiesV5 = (props: ICaptionSettingsProps) => {
	const {spaceViewRenderer, markups} = props;
	const firstItem = markups[0];
	const modelData = firstItem.modelData as Markup;
	const eyeDropperProps = spaceViewRenderer.eyeDropperProps;
	const typesWithMarkupStyleSet = MarkupsWithCustomizableColor;
	const typesWithMarkupTextSet = MarkupsWithCustomText;
	const _timeOutId = useRef<number>(-1);

	const getColor = () => {
		return {
			hex: (markups.find((markup) => typesWithMarkupStyleSet.includes(markup.type))?.modelData as Markup)?.color ?? MarkupManager.defaultMarkupColor,
			transparency: 0,
		};
	};

	const onColorChange = (markupPropNames: (keyof TextDto | "color" | "fillTransparency")[], newValues: (boolean | string | number | Color)[]) => {
		updateMarkupProp(markupPropNames, newValues);
	};

	const updateMarkupProp = (markupPropNames: (keyof TextDto | "color" | "fillTransparency")[], newValues: (boolean | string | number | Color)[]) => {
		markups.forEach((markup) => {
			const data = markup.modelData as Markup;

			markupPropNames.forEach((markupPropName, index) => {
				if (markupPropName === "color") {
					data.setColor(newValues[index] as string);
				} else if (markupPropName === "fillTransparency") {
					data.setFillTransparency(newValues[index] as number);
				} else {
					(data.text[markupPropName] as boolean | string | number | Color) = newValues[index];
				}
			});

			markup.updateByModel(data);
		});

		if (_timeOutId) {
			clearTimeout(_timeOutId.current);
		}

		_timeOutId.current = window.setTimeout(() => {
			spaceViewRenderer.markupManager.updateItems(markups, true);
		}, 300);

		if (markupPropNames.includes("content")) {
			spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry();
		}
	};

	return (
		<Observer>
			{() => {
				return (
					<MarkupPropertiesStyled>
						{markups.every((markup) => MarkupsWithCustomizableColor.includes(markup.type)) && (
							<PropertyContainerStyled className="markupStyle">
								<PropertySetLabelStyled>Markup Style</PropertySetLabelStyled>
								<FieldV5 label="Fill Color">
									<ColorSelectorV5
										title="Fill Color"
										color={getColor()}
										onColorChange={(color) => onColorChange(["color"], [color.hex])}
										eyeDropperProps={eyeDropperProps}
										isTransparencyEnabled={false}
										horizontalAlignment={HorizontalAlignment.outerRight}
										verticalAlignment={VerticalAlignment.bottom}
									/>
								</FieldV5>
								{markups.every((markup) => MarkupsWithCustomizableFillOpacity.includes(markup.type)) && (
									<FieldV5 label="Fill transparency">
										<FillButtonV5 spaceViewRenderer={spaceViewRenderer} />
									</FieldV5>
								)}
								{markups.every((markup) => MarkupsWithChangeableLineThickness.includes(markup.type)) && (
									<FieldV5 label="Stroke Width">
										<LineThicknessChangerV5
											spaceViewRenderer={spaceViewRenderer}
											items={markups}
										/>
									</FieldV5>
								)}
								{markups.every((markup) => MarkupsWithArrowHeads.includes(markup.type)) && (
									<FieldV5 label="Arrow Size">
										<ArrowHeadSizeChangerV5 spaceViewRenderer={spaceViewRenderer} />
									</FieldV5>
								)}
							</PropertyContainerStyled>
						)}
						{markups.length === 1 && markups.every((markup) => typesWithMarkupTextSet.includes(markup.type)) && (
							<PropertyContainerStyled className="markupText">
								<PropertySetLabelStyled>Markup Text</PropertySetLabelStyled>
								{markups.every((markup) => MarkupsWithCustomText.includes(markup.type)) && (
									<>
										<FieldV5
											label="Text"
											className={ReactUtils.cls("markupTextInput", {hasText: !!modelData.textContent})}
										>
											<ClickToEditInputV5
												value={modelData.textContent}
												onChange={(text) => updateMarkupProp(["content"], [text])}
												dataType={FieldDataType.MultiLineText}
											/>
											<CancelIcon onClick={() => updateMarkupProp(["content"], [""])} />
										</FieldV5>
										<FieldV5 label="Font Style">
											<FontStyleContainerV5
												fontStyleSettings={{isBold: firstItem.isBold, isItalic: firstItem.isItalic, isUnderlined: firstItem.isUnderlined}}
												onIsBoldChange={(isBold) => updateMarkupProp(["isBold"], [isBold])}
												onIsItalicChange={(isItalic) => updateMarkupProp(["isItalic"], [isItalic])}
												onIsUnderlinedChange={(isUnderLined) => updateMarkupProp(["isUnderlined"], [isUnderLined])}
											/>
										</FieldV5>
										<FieldV5
											label="Font Family"
											className="fontFamily"
										>
											<SelectInputV5
												onChange={(fontFamily) => updateMarkupProp(["fontFamily"], [fontFamily])}
												options={TextGroupManager.supportedFontNames}
												selected={firstItem.fontFamily}
											/>
										</FieldV5>
										<FieldV5 label="Font Size">
											<SizeChangerV5
												disabled={false}
												value={firstItem.fontSize}
												onChange={(size) => updateMarkupProp(["fontSize"], [size])}
												range={Constants.SIZE.FONT}
											/>
										</FieldV5>
										<FieldV5 label="Font Color">
											<ColorSelectorV5
												title="Font Color"
												color={modelData.text.fontColor}
												onColorChange={(color) => onColorChange(["fontColor"], [color])}
												eyeDropperProps={eyeDropperProps}
												horizontalAlignment={HorizontalAlignment.center}
												verticalAlignment={VerticalAlignment.topOuter}
												isTransparencyEnabled={false}
											/>
										</FieldV5>
									</>
								)}
								{markups.every((markup) => MarkupsWithCustomizableTextAlignment.includes(markup.type)) && (
									<FieldV5 label="Align Text">
										<TextBoxAlignButtonV5
											horizontalAlignment={modelData.text.horizontalAlignment}
											verticalAlignment={modelData.text.verticalAlignment}
											onTextAlignmentChange={(hA, vA) => updateMarkupProp(["horizontalAlignment", "verticalAlignment"], [hA, vA])}
										/>
									</FieldV5>
								)}
							</PropertyContainerStyled>
						)}
					</MarkupPropertiesStyled>
				);
			}}
		</Observer>
	);
};

const MarkupPropertiesStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.md};

	svg {
		color: ${colorPalette.gray.c950};
	}

	${TextAlignOptionsStyled}, ${FillOptionsStyled} {
		z-index: 1;
	}
`;

const PropertyContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.xs};

	.markupTextInput {
		svg {
			position: absolute;
			right: 18px;
			visibility: hidden;
		}

		&.hasText:hover {
			svg {
				visibility: visible;
			}
		}
	}

	.fontFamily {
		${SelectInputStyled} {
			width: 120px;
		}
	}

	${SizeChangerStyled} {
		width: 80px;
		gap: 20px;
	}

	${MarkupSelectInputStyled} {
		min-width: 80px;
	}

	${ClickToEditInputStyled} {
		.unfocused {
			padding-top: 6px;
			padding-bottom: 6px;
		}

		.multiline textarea {
			padding: 6px;
		}
	}
`;

const PropertySetLabelStyled = styled.span`
	margin-bottom: 4px;
	font-weight: ${fontWeight.bold};
	color: ${colorPalette.gray.c700Dark};
`;
