import {inject, observer} from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import {Permission, XyiconFeature} from "../../../../../generated/api/base";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {ToggleSwitchFieldV5} from "../../../details/ToggleSwitchFieldV5";
import {featureTitles} from "../../../../../data/state/AppStateConstants";
import {SearchFieldV5} from "../../../input/search/SearchFieldV5";
import {SelectSliderV5} from "../../../details/SelectSliderV5";
import {baseDistance, fontSize} from "../../../styles/styles";
interface ITypePermissionsProps {
	readonly permissionSet: PermissionSet;
	readonly feature: XyiconFeature;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface ITypePermissionState {
	searchQuery: string;
}

@inject("appState")
@inject("transport")
@observer
export class TypePermissionsV5 extends React.Component<ITypePermissionsProps, ITypePermissionState> {
	constructor(props: ITypePermissionsProps) {
		super(props);
		this.state = {
			searchQuery: "",
		};
	}

	public override render() {
		const {permissionSet, feature, appState, transport} = this.props;
		const {searchQuery} = this.state;
		const rows = appState.types[feature].slice().sort((a, b) => StringUtils.sortIgnoreCase(a.name, b.name));

		return (
			<TypePermissionsStyled>
				<div className="vbox container">
					<div className="permissionHeading">
						<ToggleSwitchFieldV5
							label={`Enable ${featureTitles[feature]} Module`}
							value={permissionSet.isAccessEnabled(feature)}
							onChange={(value) => {
								permissionSet.enableAccess(feature, value);
								transport.services.feature.update(permissionSet.id, XyiconFeature.PermissionSet, permissionSet.getData());
							}}
						/>
					</div>
					<h4>Type Permissions</h4>
					<SearchFieldV5
						value={searchQuery}
						onInput={(value) => this.setState({searchQuery: value})}
					/>
					<SelectSliderV5
						options={[
							{
								id: Permission.None,
								label: "Hide",
							},
							{
								id: Permission.View,
								label: "Show",
							},
							{
								id: Permission.Update,
								label: "Update",
							},
							{
								id: Permission.Delete,
								label: "Delete",
							},
						]}
						rows={rows
							.filter((type) => StringUtils.containsIgnoreCase(type.name, searchQuery))
							.map((type) => {
								return {
									label: type.name,
									value: permissionSet.getObjectPermission(type.id, feature),
								};
							})}
						onChange={(rowLabels: string[], value) => {
							rowLabels.forEach((label) => {
								const type = rows.find((row) => row.name === label);

								if (type) {
									permissionSet.setObjectPermission(feature, type.id, value as Permission);
								}
							});
							transport.services.feature.update(permissionSet.id, XyiconFeature.PermissionSet, permissionSet.getData());
						}}
					/>
				</div>
			</TypePermissionsStyled>
		);
	}
}

const TypePermissionsStyled = styled.div`
	.container {
		padding: ${baseDistance.md};

		h4 {
			font-size: ${fontSize.xl};
			margin-bottom: 20px;
		}

		.permissionHeading {
			margin-bottom: 30px;
		}

		.SearchField {
			width: 360px;
			margin-bottom: 30px;
		}

		.toggleSwitchLabel {
			display: none;
		}
	}
`;
