import {computed, observable, makeObservable} from "mobx";
import type {Object3D, Object3DEventMap} from "three";
import type {Markup3D} from "../../elements3d/markups/abstract/Markup3D";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {MarkupCloud} from "../../elements3d/markups/MarkupCloud";
import {MarkupArrow} from "../../elements3d/markups/MarkupArrow";
import {MarkupLine} from "../../elements3d/markups/MarkupLine";
import {MarkupDrawing} from "../../elements3d/markups/MarkupDrawing";
import {MarkupRectangle} from "../../elements3d/markups/MarkupRectangle";
import {MarkupEllipse} from "../../elements3d/markups/MarkupEllipse";
import {MarkupTriangle} from "../../elements3d/markups/MarkupTriangle";
import {MarkupCross} from "../../elements3d/markups/MarkupCross";
import {PencilTool} from "../../features/tools/markuptools/PencilTool";
import type {SpaceItem} from "../../elements3d/SpaceItem";
import {MarkupTextBox} from "../../elements3d/markups/MarkupTextBox";
import {dashSize, gapSize} from "../../elements3d/markups/MarkupStaticElements";
import {MarkupCallout} from "../../elements3d/markups/MarkupCallout";
import {updateMarkups, type FillOpacity, type IMarkupConfig} from "../../elements3d/markups/abstract/MarkupUtils";
import {getCorrectionMultiplierForSpaceItem, MeasureType} from "../../renderers/SpaceViewRendererUtils";
import type {ABTool} from "../../features/tools/markuptools/ABTool";
import {Collection} from "../../../../../../../data/models/abstract/Collection";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import {XHRLoader} from "../../../../../../../utils/loader/XHRLoader";
import type {IMarkupMinimumData, IMarkupSettingsData} from "../../../../../../../data/models/Markup";
import {Markup} from "../../../../../../../data/models/Markup";
import {MarkupType, XyiconFeature} from "../../../../../../../generated/api/base";
import type {
	CreateMarkupRequest,
	MarkupCreateDetail,
	MarkupDto,
	PointDouble,
	SetupPhoto360MarkupRequest,
	SetupPhoto360MarkupResult,
} from "../../../../../../../generated/api/base";
import {MathUtils} from "../../../../../../../utils/math/MathUtils";
import {MarkupPhoto360} from "../../elements3d/markups/MarkupPhoto360";
import {FileUtils} from "../../../../../../../utils/file/FileUtils";
import {ImageUtils as PhotoSphereImageUtils} from "../../../../../../../photosphere/utils/ImageUtils";
import {ImageUtils} from "../../../../../../../utils/image/ImageUtils";
import {TimeUtils} from "../../../../../../../utils/TimeUtils";
import type {MarkupPhoto360Material} from "../../materials/MarkupPhoto360Material";
import {MarkupPhoto360InstancedMeshManager} from "./MarkupPhoto360InstancedMeshManager";
import {EditableItemManager} from "./EditableItemManager";

export class MarkupManager extends EditableItemManager {
	public static defaultMarkupColor: string = "F44336";
	@observable
	protected _items: Collection<Markup3D> = new Collection();
	@observable
	private _markupColor: string = MarkupManager.defaultMarkupColor;
	private _markupPhoto360InstancedMeshManager: MarkupPhoto360InstancedMeshManager;
	protected _excludedLayerSettings = {};

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		super(spaceViewRenderer, "markup");
		this._markupPhoto360InstancedMeshManager = new MarkupPhoto360InstancedMeshManager(spaceViewRenderer);
		makeObservable(this);
	}

	private updateCameraPosUniform = () => {
		const cameraPos = this._spaceViewRenderer.activeCamera.position;

		(this._photo360InstancedMesh?.material as MarkupPhoto360Material)?.setCameraPosition?.([cameraPos.x, cameraPos.y, cameraPos.z]);
	};

	private get _photo360InstancedMesh() {
		return this._markupPhoto360InstancedMeshManager.photo360InstancedMesh;
	}

	public override init() {
		THREEUtils.setPosition(this._container, this._container.position.x, this._container.position.y, this._spaceViewRenderer.spaceOffset.z);
		this._spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.add(this.updateCameraPosUniform);
	}

	public override clear() {
		super.clear();
		this._spaceViewRenderer.spaceItemController.markupTextManager?.clear();
		this._spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.remove(this.updateCameraPosUniform);
	}

	public onCanvasResize() {
		for (const markup of this._items.array) {
			markup.onCanvasResize();
		}
	}

	public createSpaceItem3DFromModel(markup: IMarkupMinimumData) {
		const spaceViewRenderer = this._spaceViewRenderer;
		const color = markup.color;
		const correctionMultiplier = getCorrectionMultiplierForSpaceItem(spaceViewRenderer, markup);

		const strokeConfig: IMarkupConfig = {
			strokeColor: color,
			fill: false,
		};

		const fillConfig: IMarkupConfig = {
			strokeColor: color,
			fill: true,
			fillOpacity: (1 - markup.fillTransparency) as FillOpacity,
		};

		let markup3D: Markup3D;

		switch (markup.type) {
			case MarkupType.Cloud:
				markup3D = new MarkupCloud(spaceViewRenderer, fillConfig);
				break;
			case MarkupType.Arrow:
				markup3D = new MarkupArrow(spaceViewRenderer, strokeConfig);
				break;
			case MarkupType.BidirectionalArrow:
				markup3D = new MarkupArrow(spaceViewRenderer, strokeConfig, true);
				break;
			case MarkupType.Line:
				markup3D = new MarkupLine(spaceViewRenderer, strokeConfig);
				break;
			case MarkupType.DashedLine:
				markup3D = new MarkupLine(spaceViewRenderer, {
					strokeColor: color,
					dashSize: dashSize * correctionMultiplier,
					gapSize: gapSize * correctionMultiplier,
				});
				break;
			case MarkupType.PencilDrawing:
				strokeConfig.strokeOpacity = PencilTool.strokeOpacity.pencil;
				markup3D = new MarkupDrawing(spaceViewRenderer, strokeConfig);
				break;
			case MarkupType.HighlightDrawing:
				strokeConfig.strokeOpacity = PencilTool.strokeOpacity.highlight;
				strokeConfig.isHighLight = true;
				markup3D = new MarkupDrawing(spaceViewRenderer, strokeConfig);
				break;
			case MarkupType.TextBox:
				fillConfig.fillOpacity = 0;
				fillConfig.strokeOpacity = 0;
				markup3D = new MarkupTextBox(spaceViewRenderer, fillConfig);
				break;
			case MarkupType.Rectangle:
				markup3D = new MarkupRectangle(spaceViewRenderer, fillConfig);
				break;
			case MarkupType.Ellipse:
				markup3D = new MarkupEllipse(spaceViewRenderer, fillConfig);
				break;
			case MarkupType.Triangle:
				markup3D = new MarkupTriangle(spaceViewRenderer, fillConfig);
				break;
			case MarkupType.Cross:
				markup3D = new MarkupCross(spaceViewRenderer, strokeConfig);
				break;
			case MarkupType.LinearDistance:
				strokeConfig.measureType = MeasureType.DISTANCE;
				markup3D = new MarkupLine(spaceViewRenderer, strokeConfig);
				break;
			case MarkupType.RectangleArea:
				fillConfig.measureType = MeasureType.AREA;
				markup3D = new MarkupRectangle(spaceViewRenderer, fillConfig);
				break;
			case MarkupType.NonlinearDistance:
				strokeConfig.measureType = MeasureType.DISTANCE;
				markup3D = new MarkupDrawing(spaceViewRenderer, strokeConfig);
				break;
			case MarkupType.IrregularArea:
				fillConfig.fillOpacity = 0.1;
				fillConfig.measureType = MeasureType.AREA;
				markup3D = new MarkupDrawing(spaceViewRenderer, fillConfig);
				break;
			case MarkupType.Stamp:
				break;
			case MarkupType.Photo:
				break;
			case MarkupType.Photo360:
				markup3D = new MarkupPhoto360(spaceViewRenderer, fillConfig, this._photo360InstancedMesh.count);
				break;
			case MarkupType.Callout:
				fillConfig.fillOpacity = 0;
				markup3D = new MarkupCallout(spaceViewRenderer, fillConfig);
				break;
		}

		if (!markup3D) {
			console.warn("Wrong markup type!");
			return null;
		}

		markup3D.updateByModel(markup, false);

		return markup3D;
	}

	public addItemsByModel(markupModels: Markup[]) {
		const markups: Markup3D[] = [];

		for (const markupModel of markupModels) {
			const existingModelMaybe = this._items.getById(markupModel.id);
			const markup = existingModelMaybe ?? this.createSpaceItem3DFromModel(markupModel);

			if (markup) {
				if (markupModel.type === MarkupType.HighlightDrawing) {
					markupModel.setGeometryData(PencilTool.getOptimizedPathCoords(markupModel.geometryData));
				}
				markup.updateByModel(markupModel);
				markups.push(markup);
			} else {
				console.warn("Error while loading markup");
				console.warn(markupModel);
			}
		}
		this.add(markups, false);

		return markups;
	}

	public async initMarkups(markupArray: Markup[]) {
		await this._markupPhoto360InstancedMeshManager.initPhoto360InstancedMesh(
			markupArray.filter((markup) => markup.type === MarkupType.Photo360).length,
		);
		this.addItemsByModel(markupArray);
	}

	protected async addItems(items: Markup3D[], addToServer: boolean = true) {
		const markupPhoto360Items = items.filter((item) => item.type === MarkupType.Photo360) as MarkupPhoto360[];
		const regularMarkupItems = items.filter((item) => item.type !== MarkupType.Photo360);

		for (const item of items) {
			item.finalize();
			if (!addToServer) {
				(item as MarkupTextBox).hideOutline?.();
			}
		}

		let textNeedsUpdate: boolean = false;

		if (addToServer) {
			const appState = this._spaceViewRenderer.transport.appState;
			const spaceID = appState.space.id;
			const portfolioID = appState.portfolioId;

			//
			// Markup 360 photos
			//
			for (const item of markupPhoto360Items) {
				const setupMarkupPhoto360Request: SetupPhoto360MarkupRequest = {
					portfolioID,
					spaceID,
					orientation: 0,
					svgData: {
						geometryData: item.data.geometryData,
						color: item.data.color,
						fillTransparency: item.data.fillTransparency,
						lineThickness: item.data.lineThickness,
						text: item.data.text,
					},
					fieldData: {},
					settings: "",
				};

				try {
					const {result} = await this._spaceViewRenderer.transport.requestForOrganization<SetupPhoto360MarkupResult>({
						method: "POST",
						url: "markups/photo360/setup",
						params: setupMarkupPhoto360Request,
					});

					const markupDto: MarkupDto = {
						...setupMarkupPhoto360Request,
						markupID: result.markupID,
						organizationID: this._spaceViewRenderer.transport.appState.organizationId,
						type: MarkupType.Photo360,
					};
					const markupModel = this._spaceViewRenderer.actions.addToList(markupDto, XyiconFeature.Markup) as Markup;
					markupModel.isIncompletePhoto360 = true;
					const itemPositionVec3 = item.position;
					const itemPosition: PointDouble = {
						x: itemPositionVec3.x,
						y: itemPositionVec3.y,
					};
					markupModel.setGeometryData([itemPosition]);
					item.addModelData(markupModel);
					item.updateGeometry([itemPosition]);

					const organizationID = appState.organizationId;

					const uploadPhoto360URL = "markups/photo360/upload";
					const requestMethod = "PUT";
					const maxDimensionsForThumbnail = 500;

					const isKeyValuePairPresentInLocalhost = localStorage.getItem("useInsta360") === "true";
					if (isKeyValuePairPresentInLocalhost) {
						const timeoutFromLocalStorage = Math.max(0, parseInt(localStorage.getItem("insta360Timeout")));
						const timeoutInSeconds = MathUtils.isValidNumber(timeoutFromLocalStorage) && timeoutFromLocalStorage >= 0 ? timeoutFromLocalStorage : 5;
						const {authData} = appState.app.transport.services.auth;
						const params: Record<string, string> = {
							uploadPhoto360URL: `${appState.app.transport.apiUrl}/${uploadPhoto360URL}`,
							accessTokenType: authData.tokenType,
							accessToken: authData.accessToken,
							contentType: "multipart/form-data",
							timeout: `${timeoutInSeconds}`,
							maxDimensionsForThumbnail: `${maxDimensionsForThumbnail}`,
							method: requestMethod,
							organizationID,
							portfolioID,
							markupID: item.id,
						};
						const a = document.createElement("a");
						a.href = `xyiconinsta360://?${decodeURIComponent(XHRLoader.encodeParams(params))}`;

						a.style.display = "none";
						document.body.appendChild(a);
						a.click();
						document.body.removeChild(a);
					} else {
						const onFileSelect = async (fileList: FileList) => {
							appState.fullscreenLoaderText = "Converting...";
							const imageFile = fileList[0];
							const imageFileLocalURL = URL.createObjectURL(imageFile);
							const photoSphereMetaData = await PhotoSphereImageUtils.getMetaDataFromFile(imageFile);
							const imageElement = await ImageUtils.loadImage(imageFileLocalURL);
							const {fonts} = this._spaceViewRenderer.transport.appState;
							const imageAsBlob = await ImageUtils.image2RasterBlob(fonts, imageElement, Infinity, "webp");
							const thumbnailImageAsBlob = await ImageUtils.image2RasterBlob(fonts, imageElement, maxDimensionsForThumbnail, "webp");

							const imageAsFile = new File([imageAsBlob], `${item.id}_photo360.webp`, {type: "image/webp"});
							const thumbnailImageAsFile = new File([thumbnailImageAsBlob], `${item.id}_thumbnail.webp`, {type: "image/webp"});

							const formData = new FormData();
							formData.append("organizationID", organizationID);
							formData.append("portfolioID", portfolioID);
							formData.append("markupID", item.id);
							formData.append("photo360WebpFile", imageAsFile);
							formData.append("thumbnailWebpFile", thumbnailImageAsFile);

							appState.fullscreenLoaderText = "Uploading...";
							const {result} = await this._spaceViewRenderer.transport.request<MarkupDto>({
								method: requestMethod,
								url: uploadPhoto360URL,
								params: formData,
							});

							if (result && !result.settings) {
								result.settings = JSON.stringify({
									photoSphereMetaData,
								} as IMarkupSettingsData);
							}

							item.modelData.applyData(result);
							appState.fullscreenLoaderText = "Updating...";
							// Need to save photoSphereMetaData, otherwise we'll lose it forever!
							// It's not very important for full sphere images, but for partial ones, it can be crucial
							await this.updateItems([item]);
							markupModel.isIncompletePhoto360 = false;
							URL.revokeObjectURL(imageFileLocalURL);

							appState.fullscreenLoaderText = "Done!";
							await TimeUtils.wait(500);
							appState.fullscreenLoaderText = "";
						};

						const onFileDialogueCancel = () => {
							this._spaceViewRenderer.spaceItemController.deselectAll();
							item.destroy(false, true);
						};

						FileUtils.openFileDialogue(false, ["image/*"], onFileSelect, onFileDialogueCancel);
					}
				} catch (error) {
					console.warn(`Markup not saved: ${error}`);
				}
			}

			//
			// Regular markups
			//
			const markupCreateDetail: MarkupCreateDetail[] = [];

			for (const item of regularMarkupItems) {
				const data = item.data;

				const markupCreateDetailObject: MarkupCreateDetail = {
					spaceID: spaceID,
					orientation: data.orientation,
					svgData: {
						geometryData: data.geometryData,
						color: data.color,
						fillTransparency: data.fillTransparency,
						lineThickness: data.lineThickness,
						text: data.text,
					},
					type: data.type,
					settings: data.settings,
				};

				// We're not saving temp markups into the db
				if (item.config.isTemp) {
					const markupDto: MarkupDto = {
						...markupCreateDetailObject,
						markupID: MathUtils.getNewRandomGUID(),
					};
					const markupModel = new Markup(markupDto, this._spaceViewRenderer.transport.appState, true);

					item.addModelData(markupModel);
				}
				// Eg.: Set-scale markup shouldn't be saved, so it has a "null" type
				else if (data.type != null) {
					if (!textNeedsUpdate && item.textContent.length > 0) {
						textNeedsUpdate = true;
					}

					markupCreateDetail.push(markupCreateDetailObject);
				}
			}

			if (markupCreateDetail.length > 0) {
				try {
					const createData: CreateMarkupRequest = {
						portfolioID,
						markupCreateDetail,
					};

					const markupModels = await this._spaceViewRenderer.transport.services.feature.create<Markup>(createData, XyiconFeature.Markup);

					if (markupModels?.length === regularMarkupItems.length) {
						for (let i = 0; i < regularMarkupItems.length; ++i) {
							const item = regularMarkupItems[i];
							const markupModel = markupModels[i];

							item.addModelData(markupModel);
							(item as MarkupTextBox).hideOutline?.();
						}
					}
				} catch (error) {
					console.warn(`Markup not saved: ${error}`);
				}
			}
		}

		this._items.addMultiple(items);

		if (textNeedsUpdate) {
			// Workaround for the bug, when text is "below" the new markup
			requestAnimationFrame(() => {
				this._spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry();
			});
		}
	}

	public getSpaceItemByInstancedMeshIDAndInstanceID(instancedMeshId: number, instanceId: number) {
		for (const markup of this._items.array) {
			if (markup.type === MarkupType.Photo360 && (markup as MarkupPhoto360).instanceId === instanceId) {
				return markup;
			}
		}
	}

	public override getIntersectables(): Object3D<Object3DEventMap>[] {
		this._intersectables = super.getIntersectables();
		this._intersectables.push(this._photo360InstancedMesh);

		return this._intersectables;
	}

	public override updateItems(items: SpaceItem[], force: boolean = false) {
		return updateMarkups(
			items.map((item) => item.modelData as Markup),
			this._spaceViewRenderer,
			force,
		);
	}

	public get tempMarkups() {
		// In practice, this only contains the one currently being created, if any
		const additionalTempMarkups: Markup3D[] = [(this._spaceViewRenderer.toolManager.activeTool as ABTool | PencilTool).markup];
		return [...this._items.array, ...additionalTempMarkups].filter((item) => item?.isTemp);
	}

	public updateAllTempMarkups() {
		const {tempMarkups} = this;

		for (const markup of tempMarkups) {
			markup.updateByModel(markup.modelData as Markup);
		}

		if (tempMarkups.length > 0) {
			this._spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry();
		}
	}

	public removeNewestTempMarkup() {
		const {tempMarkups} = this;

		if (tempMarkups.length > 0) {
			const tool = this._spaceViewRenderer.toolManager.activeTool as ABTool | PencilTool;

			if (tool.markup?.isTemp) {
				tool.abortMarkup();
			} else {
				const item = tempMarkups[tempMarkups.length - 1];

				item.destroy(false, true);
			}

			this._spaceViewRenderer.spaceItemController.deselectAll();
			this._spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry();
		}
	}

	public removeAllTempMarkups = () => {
		const tempMarkups = this.tempMarkups;
		const areThereTempMarkups = tempMarkups.length > 0;

		if (areThereTempMarkups) {
			this._spaceViewRenderer.spaceItemController.deselectAll();
		}

		for (const item of tempMarkups) {
			item.destroy(false, true);
		}

		if (areThereTempMarkups) {
			this._spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry();
		}
	};

	public setColor(hex: string) {
		this._markupColor = hex;
	}

	public override getItemsByType(type: MarkupType) {
		return this._items.array.filter((markup: Markup3D) => markup.type === type);
	}

	public get markupPhoto360InstancedMeshManager() {
		return this._markupPhoto360InstancedMeshManager;
	}

	public get photo360InstancedMesh() {
		return this._photo360InstancedMesh;
	}

	@computed
	public get markupColor() {
		return this._markupColor;
	}
}
