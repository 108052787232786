import * as React from "react";
import {inject, observer} from "mobx-react";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {Navigation} from "../../../Navigation";
import {LoaderIcon} from "../../widgets/button/LoaderIcon";
import type {AppState} from "../../../data/state/AppState";
import {SVGIcon} from "../../widgets/button/SVGIcon";
import {Button} from "../../widgets/button/Button";
import {IconButton} from "../../widgets/button/IconButton";
import {handleLogin} from "../../../utils/LoginUtils";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {ReactUtils} from "../../utils/ReactUtils";

interface ILoginWindowProps {
	readonly navigation?: Navigation;
	readonly transport?: TransportLayer;
	readonly appState?: AppState;
	readonly isOrganizationSwitchView?: boolean;
	readonly handleClose?: () => void;
	readonly handleOrganizationSwitch?: () => Promise<void>;
}

export interface ILoginWindowState {
	message: string;
	isLoading: boolean;
	loginWithSSO: boolean;
	isValidEmail?: boolean;
	inputString?: string;
}

@inject("navigation")
@inject("transport")
@inject("appState")
@observer
export class LoginWindow extends React.Component<ILoginWindowProps, ILoginWindowState> {
	constructor(props: ILoginWindowProps) {
		super(props);
		this.state = {
			message: "",
			isLoading: false,
			loginWithSSO: false,
			isValidEmail: false,
			inputString: "",
		};
	}

	public override componentDidMount() {
		window.addEventListener("hashchange", this.onHashChange);
	}

	public override componentWillUnmount() {
		window.removeEventListener("hashchange", this.onHashChange);
	}

	private onHashChange = () => {
		this.props.appState.isLoggingIn = false;
	};

	private onForgottenPasswordClick = () => {
		this.props.navigation.go("auth/forgotten_password");
	};

	private handleLoginWithSSO = () => {
		this.setState((prev) => ({
			loginWithSSO: !prev.loginWithSSO,
			message: "",
			inputString: "",
			isValidEmail: false,
		}));
	};

	private validateInputs = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {
			target: {value},
		} = event;
		const isValid = StringUtils.emailValidator(value);
		if (isValid) {
			this.setState({isValidEmail: true, inputString: value});
		} else {
			this.setState({isValidEmail: false, inputString: value});
		}
	};

	public override render() {
		const message = this.state.message || this.props.appState.lastError || "";
		const {isLoggingIn} = this.props.appState;
		const {isOrganizationSwitchView = false} = this.props;
		const {user} = this.props.appState;
		const {loginWithSSO, isLoading, isValidEmail, inputString} = this.state;
		const isDisabled = isLoggingIn || isLoading || (!isValidEmail && loginWithSSO);

		return (
			<div className="LoginWindow">
				<div className="loginContainer">
					{isOrganizationSwitchView && (
						<div className="closeButtonContainer">
							<IconButton
								className="close"
								icon="close"
								onClick={this.props.handleClose}
							/>
						</div>
					)}
					<div className="formContainer hbox alignCenter">
						<SVGIcon
							classNames="xyicon-logo"
							icon="xyiconlogo"
						/>
						<form
							className="vbox alignCenter"
							role="form"
							name="formLogin"
							noValidate
							autoCapitalize="off"
							onSubmit={this.onSubmit}
						>
							<div className="inputGroup">
								<input
									id="userName"
									name="userName"
									type="text"
									className="Input"
									placeholder={loginWithSSO ? "Email" : "Username"}
									onChange={(event) => this.validateInputs(event)}
									value={isOrganizationSwitchView ? user?.email : inputString}
									disabled={isOrganizationSwitchView}
								/>
							</div>
							{!loginWithSSO && (
								<div className="inputGroup">
									<input
										id="pwInput"
										name="pwInput"
										type="password"
										className="Input"
										placeholder="Password"
										autoComplete="off"
									/>
									{!isOrganizationSwitchView && (
										<p
											className="forgotPassword"
											onClick={this.onForgottenPasswordClick}
										>
											Forgot Password?
										</p>
									)}
								</div>
							)}
							<button
								name="submitButton"
								className={ReactUtils.cls("Button primary", {disabled: isDisabled, loginButton: true})}
								type="submit"
								disabled={isDisabled}
							>
								{isLoading ? <LoaderIcon /> : loginWithSSO ? "Login with SSO" : "Log in"}
							</button>
							{!isOrganizationSwitchView && (
								<button
									type="button"
									className="sso-login-text"
									disabled={isLoading}
									onClick={this.handleLoginWithSSO}
								>
									{loginWithSSO ? "Go back to login page" : "or login with SSO"}
								</button>
							)}
							<Button
								onClick={() => this.props.navigation.openInNewTab("https://support.xyicon.com/docs")}
								className="naked small helpbutton"
								icon="help"
								label="Help"
							/>
							{!loginWithSSO && <div className="errorMessage">{message}</div>}
						</form>
					</div>
				</div>
			</div>
		);
	}

	private handleLoginStateChange = (state: ILoginWindowState) => {
		this.setState(state);
	};

	private onSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
		event.preventDefault();
		const {transport, appState, isOrganizationSwitchView, navigation, handleClose, handleOrganizationSwitch} = this.props;
		const {loginWithSSO} = this.state;
		this.props.appState.lastError = "";
		this.props.appState.isLoggingIn = true;

		await handleLogin({
			event,
			transport,
			appState,
			navigation,
			isOrganizationSwitchView,
			loginWithSSO,
			handleClose,
			handleOrganizationSwitch,
			handleLoginStateChange: this.handleLoginStateChange,
		});

		this.props.appState.isLoggingIn = false;
	};
}
