import {css, styled} from "styled-components";
import type {CSSProperties, FunctionComponent, MouseEvent, ReactNode, SVGProps, TouchEvent} from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import {colorPalette} from "../../styles/colorPalette";
import {ELLIPSIS, FLEXCENTER, FlexCenterStyle, VerticalFlexStyle, baseDistance, fontSize, radius, zIndex} from "../../styles/styles";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {AppUtils} from "../../../../utils/AppUtils";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {useAppStore} from "../../../../StateManager";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {ReactUtils} from "../../../utils/ReactUtils";
import {SearchFieldV5} from "../search/SearchFieldV5";
import ChevronDownIcon from "../../icons/chevron-down.svg?react";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";
import {useClickOutside} from "../../utils";
//import {ButtonStyled, ButtonV5} from "../../button/ButtonV5";
//import CirclePlusIcon from "../../icons/circle-plus.svg?react";
import CloseIcon from "../../icons/xmark.svg?react";
//import PenLineIcon from "../../icons/pen-with-line.svg?react";

export const SelectInputDropdownClass = "SelectInputDropDownV5";
export const SelectInputStyledClass = "SelectInputStyled";

const svgWidth = "18px";

const renderTitle = (title: any) => (["number", "string"].includes(typeof title) ? title.toString() : "");

interface ISelectInputPropsV5<T> {
	readonly optionClassName?: React.CSSProperties;
	readonly className?: string;
	readonly childBeforeOptions?: ReactNode;
	readonly options: T[];
	readonly nullOption?: boolean;
	readonly selected?: T;
	readonly type?: string;
	readonly disabled?: boolean;
	readonly nullLabel?: string;
	readonly fullWidth?: boolean;
	readonly searchBarFullWidth?: boolean;
	readonly dropdownFixedWidth?: string;
	readonly dropdownMaxHeight?: string;
	readonly sort?: boolean;
	readonly searchBarMode?: "always on" | "always off" | "when many items";
	readonly noFixedPosition?: boolean;
	readonly optionsZIndex?: number;
	readonly placeholder?: string;
	readonly focused?: boolean;
	readonly IconComponent?: FunctionComponent<SVGProps<SVGSVGElement> & {title?: string}>;
	readonly dropdownIcon?: boolean;
	readonly isSameWidth?: boolean;
	readonly render?: (item: T) => ReactNode;
	readonly renderOptionValue?: (item: T) => string;
	readonly renderSelectedWhenClosed?: (item: T) => ReactNode;
	readonly stringifyOption?: (item: T) => string;
	readonly onChange?: (option: T) => void;
	readonly onFocus?: (value: boolean) => void;
	readonly onClick?: () => void;
	readonly onClose?: () => void;
	readonly horizontalAlignment?: HorizontalAlignment;
	readonly verticalAlignment?: VerticalAlignment;
	readonly offsetX?: number;
	readonly offsetY?: number;
	readonly isConfirmXyiconModelChangesForm?: boolean;
	readonly inline?: boolean;
	readonly isSingleSelect?: boolean;
	readonly closeIcon?: boolean;
	readonly isFixedWidth?: boolean;
	readonly optionFontSize?: string;
	readonly selectedIsRemoved?: boolean;
}

export const SelectInputV5 = <T,>({
	optionClassName,
	options = [],
	nullOption = false,
	disabled = false,
	render = (option: any) => <>{option}</>,
	renderSelectedWhenClosed,
	nullLabel = "",
	sort = true,
	searchBarMode = "when many items",
	IconComponent,
	dropdownIcon = true,
	onClick,
	onFocus,
	onClose,
	focused,
	onChange,
	selected,
	childBeforeOptions,
	noFixedPosition,
	optionsZIndex,
	stringifyOption,
	className,
	fullWidth,
	searchBarFullWidth,
	dropdownFixedWidth,
	dropdownMaxHeight,
	placeholder,
	isSameWidth,
	verticalAlignment = VerticalAlignment.bottomOuter,
	horizontalAlignment = HorizontalAlignment.left,
	offsetY = 8,
	offsetX = 0,
	isConfirmXyiconModelChangesForm = false,
	inline,
	isSingleSelect,
	type,
	renderOptionValue = (option: any) => option,
	closeIcon,
	isFixedWidth,
	optionFontSize,
	selectedIsRemoved,
}: ISelectInputPropsV5<T>) => {
	const _element = useRef<HTMLDivElement>();
	const _list = useRef<HTMLDivElement>();
	const _searchBar = useRef<HTMLDivElement>();
	const _prevFocused = useRef<boolean>();
	let _touchMove = false;

	const appState = useAppStore((state) => state.appState);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [search, setSearch] = useState<string>("");
	const [transform, setTransform] = useState<TransformObj>(null);

	const placeholderProp = placeholder;

	useEffect(() => {
		if (_element.current && _list.current && isOpen) {
			const elementWidth = _element.current.offsetWidth;
			const listWidth = _list.current.offsetWidth;

			const effectiveHorizontalAlignment =
				horizontalAlignment === HorizontalAlignment.left && isSingleSelect && elementWidth < listWidth
					? HorizontalAlignment.right
					: horizontalAlignment;

			setTransform(
				DomUtils.getFixedFloatingElementPosition(
					_element.current,
					_list.current,
					verticalAlignment,
					effectiveHorizontalAlignment,
					offsetY,
					offsetX,
					true,
				),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_element.current, _list.current, verticalAlignment, horizontalAlignment, offsetY, offsetX, setTransform, isOpen, disabled]);

	useEffect(() => {
		_prevFocused.current = focused;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (focused && !_prevFocused.current) {
			if (!disabled) {
				setIsOpen(true);
			}
		} else {
			setIsOpen(false);
		}

		_prevFocused.current = focused;
	}, [focused, disabled]);

	const onOpen = useCallback(() => {
		if (disabled) {
			return;
		}

		const isOpenCallback = async (event?: MouseEvent) => {
			if (!isOpen) {
				try {
					onClick?.();
					event?.stopPropagation();
					onFocus?.(true);
					setIsOpen(true);
					AppUtils.disableScrolling(true);

					await TimeUtils.wait(100);

					// Auto-scroll to selected option when isOpening
					// requestAnimationFrame is needed because the div is not visible immediately after calling setState(isOpen)
					requestAnimationFrame(() => {
						const selectedOption = _element.current?.querySelector(".option.selected") as HTMLDivElement;

						DomUtils.scrollIntoViewIfNeeded(selectedOption);
					});
				} catch (error) {
					console.warn(error);
				}
			}
		};

		isOpenCallback();
	}, [onFocus, onClick, isOpen, disabled]);

	const onCloseMethod = useCallback(() => {
		if (isOpen) {
			AppUtils.disableScrolling(false);
			setIsOpen(false);
			setSearch("");
			onFocus?.(false);
			onClose?.();
		}
	}, [isOpen, onFocus, onClose]);

	const onToggle = (event: MouseEvent) => {
		if (_searchBar.current && (event.target as Element).contains(_searchBar.current)) {
			return;
		}

		if (isOpen) {
			onCloseMethod();
		} else {
			onOpen();
		}
	};

	useClickOutside([_element, _list], onCloseMethod, "mousedown", []);

	const onOptionTouchMove = () => {
		_touchMove = true;
	};

	const onOptionTouchEnd = (e: TouchEvent, option: T = null) => {
		if (!_touchMove) {
			e.nativeEvent.stopImmediatePropagation();
			AppUtils.disableScrolling(false);
			setIsOpen(false);
			setSearch("");
			onChange?.(option);
		}

		_touchMove = false;
	};

	const onOptionClick = (e: MouseEvent, option: T = null) => {
		e.nativeEvent.stopImmediatePropagation();
		AppUtils.disableScrolling(false);
		setIsOpen(false);
		setSearch("");
		onChange?.(option);
	};

	const renderLabel = (option: T, isPlaceholder: boolean) => {
		if (isPlaceholder && selected == null) {
			return <div className="placeholder">{placeholderProp}</div>;
		}

		if (!option) {
			if (inline) {
				return <div className="nullLabel">-</div>;
			}
			return nullOption ? <div className="nullLabel">{nullLabel}</div> : placeholder;
		} else {
			return render(option);
		}
	};

	const clearSelected = () => {
		onChange?.(null);
	};

	const inlineStyle: CSSProperties = _list.current && {
		maxWidth: isSameWidth ? _element.current.offsetWidth : (dropdownFixedWidth ?? "500px"),
		minWidth: isSameWidth ? _element.current.offsetWidth : (dropdownFixedWidth ?? "150px"),
		transform: noFixedPosition ? "" : transform?.translate,
		position: noFixedPosition ? "absolute" : "fixed",
		zIndex: optionsZIndex ?? zIndex.contextOptions,
		visibility: "visible",
	};

	const optionsClone = options.slice(); // [mobx] `observableArray.sort()` will not update the array in place. Use `observableArray.slice().sort()` to suppress this warning and perform the operation on a copy, or `observableArray.replace(observableArray.slice().sort())` to sort & update in place

	if (sort) {
		optionsClone.sort((optionA, optionB) => {
			let a = renderLabel(optionA, false).toString();
			let b = renderLabel(optionB, false).toString();

			if (stringifyOption) {
				a = stringifyOption(optionA);
				b = stringifyOption(optionB);
			}

			return StringUtils.sortIgnoreCase(a, b);
		});
	}

	const selectedOption = renderSelectedWhenClosed ? renderSelectedWhenClosed(selected) : renderLabel(selected, true);

	const optionElements = optionsClone
		.filter((option) => {
			let o: any = option;

			if (stringifyOption) {
				o = stringifyOption(option);
			}

			if (search) {
				const value = typeof o === "string" ? o : renderLabel(o, false).toString();

				return StringUtils.containsIgnoreCase(value, search);
			} else {
				return true;
			}
		})
		.map((option, index) => {
			const isSelected = isSingleSelect ? renderOptionValue(option) : option;
			const label = renderLabel(option, false);

			return (
				<OptionStyled
					title={renderTitle(label)}
					key={`${index}_${renderTitle(label)}`}
					className={ReactUtils.cls("Options", {
						selected: selected === isSelected,
						removed: selected === isSelected && selectedIsRemoved,
						singleSelect: isSingleSelect,
					})}
					style={optionClassName}
					onMouseDown={(event) => {
						onOptionClick(event, option);
					}}
					onTouchMove={onOptionTouchMove}
					onTouchEnd={(event) => onOptionTouchEnd(event, option)}
					$optionFontSize={optionFontSize}
					$type={type ?? ""}
				>
					<span>{label}</span>
				</OptionStyled>
			);
		});

	return (
		<SelectInputStyled
			ref={_element}
			className={ReactUtils.cls(`${SelectInputStyledClass} ${className || ""}`, {
				disabled,
				fullWidth,
				isOpen,
			})}
			onClick={onToggle}
			$inline={inline}
			$isOpen={isOpen}
			$isSingleSelect={isSingleSelect}
			$fixedWidth={isFixedWidth}
			title={renderTitle(selectedOption)}
		>
			{IconComponent && <IconComponent />}
			<span>{selectedOption}</span>
			{dropdownIcon && <ChevronDownIcon />}
			{!inline && closeIcon && selected && (
				<CloseIcon
					className="closeBtn"
					onClick={clearSelected}
				/>
			)}
			{isOpen && (
				<DomPortal
					destination={appState.app.modalContainer}
					noPortal={noFixedPosition}
				>
					<SelectInputDropdownStyled
						className={ReactUtils.cls("SelectInputDropdownClass", {
							singleSelect: isSingleSelect,
						})}
						style={inlineStyle}
						ref={_list}
						$isConfirmXyiconModelChangesForm={isConfirmXyiconModelChangesForm}
						$numberOfOptions={options.length}
					>
						{(searchBarMode === "always on" || (searchBarMode === "when many items" && options.length > 5)) && (
							<SearchWrapper>
								<SearchFieldV5
									value={search}
									onInput={(value) => setSearch(value)}
									divRef={_searchBar}
									fullWidth={searchBarFullWidth}
								/>
							</SearchWrapper>
						)}
						<ListElementContainerStyled style={{maxHeight: dropdownMaxHeight ?? "140px"}}>
							{childBeforeOptions}
							{nullOption && optionElements.length > 0 && !search && (
								<OptionStyled
									className={ReactUtils.cls("Options", {
										selected: !selected,
										singleSelect: isSingleSelect,
									})}
									style={optionClassName}
									onMouseDown={(event) => {
										onOptionClick(event);
									}}
									onTouchMove={onOptionTouchMove}
									onTouchEnd={onOptionTouchEnd}
									$type={type ? type : ""}
									$optionFontSize={optionFontSize}
								>
									{renderLabel(null, false)}
								</OptionStyled>
							)}
							{optionElements.length === 0 ? (
								<div className="bottom-section noValues">
									No values found.
									<br />
									{!search && "Contact your admin."}
								</div>
							) : (
								optionElements
							)}
						</ListElementContainerStyled>
						{/*{isSingleSelect && (
							<OptionsLabelStyled>
								<ButtonV5
									onClick={() => true}
									label="Add Item"
								>
									<CirclePlusIcon />
								</ButtonV5>
								<ButtonV5
									onClick={() => true}
									label="Edit List"
								>
									<PenLineIcon />
								</ButtonV5>
							</OptionsLabelStyled>
						)}*/}
					</SelectInputDropdownStyled>
				</DomPortal>
			)}
		</SelectInputStyled>
	);
};

export const SelectInputStyled = styled.div<{$inline?: boolean; $isOpen?: boolean; $isSingleSelect?: boolean; $fixedWidth?: boolean}>`
	cursor: pointer;
	${FlexCenterStyle};
	justify-content: space-between;
	gap: ${baseDistance.sm};
	height: 32px;
	padding-left: ${baseDistance.sm};
	padding-right: ${baseDistance.sm};
	border: ${(props) =>
		props.$inline ? "none" : props.$isOpen ? `1px solid ${colorPalette.primary.c500Primary}` : `1px solid ${colorPalette.gray.c300}`};
	border-radius: ${radius.sm};
	font-size: ${fontSize.md};
	line-height: ${fontSize.lg};
	width: ${(props) => (props.$fixedWidth ? "125px" : "")};

	.closeBtn {
		/*position: fixed;*/
		visibility: ${(props) => (props.$isOpen ? "visible" : "hidden")};
		position: absolute;
		right: 55px;
		right: 6px;
	}

	& > span {
		display: flex;
		align-items: center;
		height: 100%;
		white-space: nowrap;
		overflow: ${(props) => (props.$isSingleSelect ? "visible" : "hidden")};
		text-overflow: ellipsis;
		width: 100%;
	}

	&:hover {
		background-color: ${colorPalette.gray.c200Light};

		.closeBtn {
			visibility: visible;
			position: absolute;
			right: 6px;
		}
	}

	svg {
		width: ${svgWidth};
		height: ${svgWidth};
	}

	.empty {
		width: calc(100% - ${svgWidth});
		text-align: center;
	}

	.input {
		position: relative;
		padding-right: 30px;
		font-size: ${fontSize.md};
		flex: 1;
		line-height: 14px;
		${ELLIPSIS}

		.fieldOption {
			.linkIcon {
				right: 20px;
			}
		}

		svg {
			width: 12px;
			min-width: 12px;
			height: 12px;
			min-height: 12px;
			position: absolute;
			right: 5px;
			top: 2px;

			path {
				width: 6px;
				height: 3px;
			}
		}
	}

	.placeholder {
		color: ${colorPalette.gray.c500Primary};
	}
`;

const SelectInputDropdownStyled = styled.div<{$isConfirmXyiconModelChangesForm: boolean; $numberOfOptions: number}>`
	${VerticalFlexStyle};
	visibility: hidden;
	background-color: ${colorPalette.white};
	border-radius: ${radius.sm};
	padding: ${baseDistance.sm};
	box-shadow: 0px 4px 8px 0px #00000033;

	svg.icon {
		width: 16px;
		height: 16px;
		margin-right: 5px;
	}

	.filterField {
		width: 100%;
		justify-content: space-between;
	}

	.SearchField {
		width: 99%;
	}

	${(props) =>
		props.$isConfirmXyiconModelChangesForm &&
		props.$numberOfOptions != 2 &&
		`.Options:nth-last-child(2) {
				margin-top: ${baseDistance.sm};
				border-top: 1px solid ${colorPalette.gray.c300};
				border-radius: 0;
			}`}
`;

const ListElementContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;

	.onlyFavoritesToggler {
		padding: 10px 0;

		.DetailsTabToggleSwitch {
			transform: scale(0.8);
		}
	}

	.favorite {
		justify-content: left;
		padding-left: ${baseDistance.sm};
	}
`;

const SearchWrapper = styled.div`
	${FlexCenterStyle};
	gap: ${baseDistance.sm};
	margin-bottom: ${baseDistance.sm};
`;

const OptionStyled = styled.div<{$optionFontSize?: string; $type?: string}>`
	${FlexCenterStyle};
	padding: ${(props) => (props.$type ? "" : "0 8px")};
	cursor: pointer;
	min-height: 32px;
	border-radius: ${radius.sm};
	${ELLIPSIS}
	font-size: ${(props) => props.$optionFontSize || fontSize.lg};

	span {
		${ELLIPSIS}
		line-height: 18px;
		width: 100%;
	}

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}

	&.selected {
		border: none;
		background-color: ${colorPalette.primary.c200Light};
		color: ${colorPalette.primary.c500Primary};
	}

	&.singleSelect {
		min-height: 40px;
		box-sizing: border-box;
	}

	.fieldOption {
		width: 100%;
		justify-content: space-between;
	}

	svg {
		flex-shrink: 0;
	}

	${(props) => {
		switch (props.$type) {
			case "textOnly":
			case "pillColored":
			case "pill":
			case "flag":
			case "colorBar":
			case "colorBarFill":
				return css`
					&.selected {
						background-color: ${colorPalette.primary.c200Light};
						border: 2px solid ${colorPalette.primary.c500Primary};
						color: black;
					}
				`;
			case "default":
				return css`
					&.selected {
						background-color: ${colorPalette.primary.c200Light};
						border: 2px solid ${colorPalette.primary.c500Primary};
					}

					.nullLabel {
						${FLEXCENTER};
						height: 32px;
						margin: ${baseDistance.xs} ${baseDistance.xs};
						width: 100%;
						border-radius: ${radius.sm};
						border: 1px solid ${colorPalette.gray.c300};
						background-color: ${colorPalette.white};
					}
				`;
		}
	}}
`;

// Temporarily removed, as per 6979
// const OptionsLabelStyled = styled.div`
// 	display: flex;
// 	font-size: ${fontSize.sm};
// 	height: 24px;
// 	letter-spacing: 0.0025em;
// 	color: ${colorPalette.gray.c500Primary};
// 	margin-top: ${baseDistance.sm};
// 	align-items: center;

// 	${ButtonStyled} {
// 		width: fit-content;
// 		height: fit-content;
// 		color: black;
// 		background-color: ${colorPalette.white};
// 		padding-left: 0px;
// 	}
// `;
