import styled from "styled-components";
import {colorPalette} from "../styles/colorPalette";
import {baseDistance, FlexCenterStyle, fontSize, fontWeight, radius} from "../styles/styles";

export const UserStaticPageWrapperStyled = styled.div`
	${FlexCenterStyle}
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	justify-content: center;

	.preLoadingPage {
		text-align: center;
	}

	.UserStaticPage {
		width: 500px;
		margin: auto;
		box-sizing: content-box;
		background: ${colorPalette.white};
		border-radius: 24px;
		box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.2);

		&.invalidLink {
			width: 709px;
		}

		.Notification {
			top: 25px;
			position: fixed;
		}

		.shadow {
			position: fixed;
			background: rgba(0, 0, 0, 0.5);
			width: 100vw;
			height: 100vh;
			top: 0;
			left: 0;
		}

		.desc {
			font-size: ${fontSize.md};
			color: ${colorPalette.gray.c950};
			margin: 0 0 ${baseDistance.md} 0;
		}

		.fieldContainer {
			position: relative;
			opacity: 1;
			transition: ease-in-out opacity 0.4s;

			svg {
				width: 16px;
				height: 16px;
			}

			.confirm {
				width: 100%;
				height: 32px;
				font-size: ${fontSize.lg};
				background-color: ${colorPalette.primary.c500Primary};
				color: ${colorPalette.white};
				border: none;
				border-radius: ${radius.sm};
				margin: ${baseDistance.lg} 0 ${baseDistance.sm} 0;

				&.disabled {
					background: ${colorPalette.gray.c300};
					color: ${colorPalette.white};
				}
			}

			.textLink {
				color: ${colorPalette.primary.c500Primary};
			}

			&.loading {
				opacity: 0;
				transition: ease-in-out opacity 0.4s;
			}

			.Button {
				margin-top: ${baseDistance.sm};
			}
		}
	}

	.ForgottenPasswordReset {
		&.expiredForgottenPasswordReset {
			width: 816px;
		}

		.fieldContainer {
			.btns {
				align-self: center;
				padding-top: 24px;
				padding-bottom: 48px;
				gap: 10px;

				.Button {
					margin-left: 10px;
					margin-top: 24px;

					&.disabled {
						background: ${colorPalette.gray.c300};
						color: ${colorPalette.white};
					}
				}
			}
		}
	}
`;

export const PasswordValidatorStyled = styled.div`
	margin: ${baseDistance.md} 0 0 0;
	width: auto;

	.pwddRequirementsMessage {
		font-size: ${fontSize.sm};
		color: ${colorPalette.gray.c700Dark};
		line-height: 16px;
	}
`;

export const LoginWindowStyled = styled.div`
	width: 100vw;
	height: 100vh;
	${FlexCenterStyle};
	justify-content: center;
	background: ${colorPalette.gray.c100};
`;

export const FormContainerStyled = styled.div<{$loginWithSSO?: boolean; $isOrganizationSwitchView?: boolean}>`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;
	background: ${colorPalette.white};
	border-radius: 24px;
	box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.2);
	padding: 48px 48px 0px 48px;
	position: relative;

	&.login {
		width: 396px;
		height: ${(props) => (props.$loginWithSSO ? "377px" : props.$isOrganizationSwitchView ? "408px" : "469px")};
	}

	.closeButtonContainer {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		position: absolute;
		top: ${baseDistance.md};
		right: ${baseDistance.md};

		.close {
			cursor: pointer;
			width: 16px;
			height: 16px;
		}
	}

	&.newuserreg {
		padding: ${baseDistance.lg};
	}

	.title {
		font-size: ${fontSize.xl};
		font-weight: ${fontWeight.bold};
		padding-bottom: ${baseDistance.md};

		&.loginTitel {
			font-size: 32px;
			font-weight: ${fontWeight.normal};
		}
	}

	.labelName {
		font-size: ${fontSize.md};
		padding-bottom: 4px;
		color: ${colorPalette.gray.c950};

		&.help {
			margin-top: ${baseDistance.lg};
			justify-content: flex-end;
			display: flex;

			a {
				color: ${colorPalette.primary.c500Primary};
				font-size: ${fontSize.md};
				font-weight: ${fontWeight.normal};
				text-decoration: none;
				line-height: 16px;
				height: 16px;
				display: flex;
				gap: ${baseDistance.xs};

				&:hover {
					p {
						color: ${colorPalette.primary.c700Dark};
					}

					svg path {
						stroke: ${colorPalette.primary.c700Dark};
					}
				}
			}

			svg {
				width: 16px;
				height: 16px;

				path {
					stroke: ${colorPalette.primary.c500Primary};
				}
			}
		}
	}

	.welcomeMsg {
		margin-bottom: ${baseDistance.sm};
		font-size: ${fontSize.xl};
	}

	.xyicon-logo {
		width: 150px !important;
		height: 50px !important;
		margin-bottom: ${baseDistance.md};
	}

	.description {
		font-size: ${fontSize.md};
		text-align: center;
		color: ${colorPalette.gray.c700Dark};
		line-height: 16px;

		.support {
			color: black;
		}
	}

	.description-forgotpswd {
		font-size: ${fontSize.md};
		color: ${colorPalette.gray.c700Dark};
		line-height: 16px;
	}

	.Field {
		${FlexCenterStyle}

		label {
			min-width: 160px;
		}

		.check-icon {
			color: ${colorPalette.positive.c500Primary};
			position: absolute;
			right: 95px;
			top: 25px;
		}
	}

	.inputGroup {
		position: relative;
		padding-top: 16px;

		input::placeholder {
			color: ${colorPalette.gray.c300};
			line-height: 16px;
			font-size: ${fontSize.md};
		}

		.usernameerror {
			position: absolute;
			right: 0;
		}

		input {
			border-radius: ${radius.sm};
			border: 1px solid ${colorPalette.gray.c300};
			background-color: ${colorPalette.white};
			height: 32px;
			width: 100%;
			font-size: ${fontSize.md};
			line-height: 16px;
			color: ${colorPalette.gray.c950};
			resize: none;
			outline: none;

			&.email {
				background-color: ${colorPalette.gray.c100};
			}

			&.redBorder {
				border-color: ${colorPalette.negative.c500Primary};
			}

			&.redBorder:focus {
				border-color: ${colorPalette.negative.c500Primary};
			}

			&:disabled {
				background-color: ${colorPalette.gray.c100Light};
			}
		}

		input:focus {
			border-color: ${colorPalette.primary.c500Primary};
		}

		.forgotPassword {
			cursor: pointer;
			padding-top: ${baseDistance.sm};
			color: ${colorPalette.primary.c500Primary};
			width: fit-content;
		}

		.forgotPassword:hover {
			color: ${colorPalette.primary.c700Dark};
		}

		&.login {
			svg {
				position: absolute;
				top: 45px;
				left: ${baseDistance.sm};
			}
		}

		&.password {
			.check-icon {
				color: ${colorPalette.positive.c500Primary};
				position: absolute;
				right: 35px;
				top: 42px;
			}
		}
	}

	.helpbutton {
		align-self: end;
		padding: 0;
		color: ${colorPalette.primary.c500Primary};

		.IconButton {
			height: 12px;

			&:hover {
				background-color: transparent;
			}
		}

		.label {
			margin: 0;
		}
	}

	.username {
		&.errorInfo {
			right: 35px;
		}

		.InfoButtonV5 {
			position: absolute;
			margin: 0;
			top: 15px;
			left: 135px;
			color: ${colorPalette.primary.c500Primary};
		}
	}

	.forgotPassword {
		cursor: pointer;
		padding-top: ${baseDistance.sm};
	}

	form {
		width: 100%;
		position: relative;

		button.Button {
			width: 100%;
			height: 32px;
			font-size: ${fontSize.lg};
			background-color: ${colorPalette.primary.c500Primary};
			color: ${colorPalette.white};
			border: none;
			border-radius: ${radius.sm};
			margin-top: 32px;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;

			&.disabled {
				background: ${colorPalette.gray.c300};
				color: ${colorPalette.white};
			}
		}

		input {
			padding: ${baseDistance.sm};
		}

		button.Button .loading div .loader .dots div {
			background: ${colorPalette.white};
		}
	}

	.errorMessage {
		text-align: center;
		width: 100%;
		margin-top: ${baseDistance.sm};
		font-size: ${fontSize.md};
	}

	.sso-button-container {
		display: flex;
		justify-content: center;
	}

	.sso-login-text {
		font-size: ${fontSize.md};
		font-weight: ${fontWeight.normal};
		font-family: "Roboto";
		margin-top: 8px;
		color: #3495f0;
		background-color: transparent;
		border: none;

		&:hover {
			cursor: pointer;
		}
	}
`;
