import * as React from "react";
import type {IBasicPropsFor3DTweaking} from "../../../SpaceView";
import type {TileManager} from "../../logic3d/managers/TileManager";
import {SpaceEditorMode} from "../../logic3d/renderers/SpaceViewRendererUtils";
import {LogarithmicSlider} from "../../../../abstract/common/slider/LogarithmicSlider";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import {Button} from "../../../../../widgets/button/Button";
import {ViewComponentToggler} from "./ViewComponentToggler";

interface ISpaceAlignViewBarState {
	isOldBackgroundVisible: boolean;
	isNewBackgroundVisible: boolean;
	centerScaleOnPointerDown: number;
	largeStepValue: number;
	backgroundScale: number;
}

export class SpaceAlignViewBar extends React.Component<IBasicPropsFor3DTweaking, ISpaceAlignViewBarState> {
	private _tileManager: TileManager;

	constructor(props: IBasicPropsFor3DTweaking) {
		super(props);

		this._tileManager = this.props.spaceViewRenderer.tileManager;

		const defaultScale = 1;

		this.state = {
			isOldBackgroundVisible: true,
			isNewBackgroundVisible: true,
			largeStepValue: this.getLargeStepValue(defaultScale),
			backgroundScale: defaultScale,
			centerScaleOnPointerDown: defaultScale,
		};
	}

	private onOldLayerClick = () => {
		if (this._tileManager.oldBackgroundContainer) {
			this._tileManager.oldBackgroundContainer.visible = !this.state.isOldBackgroundVisible;

			this.setState({
				isOldBackgroundVisible: this._tileManager.oldBackgroundContainer.visible,
			});
			this.props.spaceViewRenderer.needsRender = true;
		}
	};

	private onNewLayerClick = () => {
		if (this._tileManager.firstChildOfPivotContainer) {
			this._tileManager.firstChildOfPivotContainer.visible = !this.state.isNewBackgroundVisible;

			this.setState({
				isNewBackgroundVisible: this._tileManager.firstChildOfPivotContainer.visible,
			});
			this.props.spaceViewRenderer.needsRender = true;
		}
	};

	private onScaleChange = (newScaleValue: number) => {
		if (this._tileManager.pivotContainer) {
			this._tileManager.pivotContainer.scale.set(newScaleValue, newScaleValue, newScaleValue);
			THREEUtils.updateMatrices(this._tileManager.pivotContainer);
			this.setState({
				backgroundScale: newScaleValue,
			});
			this.props.spaceViewRenderer.needsRender = true;
		}
	};

	private onScaleEnd = () => {
		this.setState({
			centerScaleOnPointerDown: this.state.backgroundScale,
		});
	};

	private onConfirmClick = () => {
		this.props.spaceViewRenderer.confirmAlignment();
		this.setState({
			backgroundScale: 1,
			centerScaleOnPointerDown: 1,
		});
	};

	private getLargeStepValue(centerScaleValue: number = this.state.centerScaleOnPointerDown) {
		return centerScaleValue / (this.props.spaceViewRenderer.toolManager.cameraControls.cameraZoomValue * 2000);
	}

	private onCameraZoomChange = () => {
		this.setState({
			largeStepValue: this.getLargeStepValue(),
		});
	};

	public override componentDidMount() {
		this.props.spaceViewRenderer.toolManager.cameraControls.signals.cameraZoomChange.add(this.onCameraZoomChange);
	}

	public override componentWillUnmount() {
		this.props.spaceViewRenderer.toolManager.cameraControls.signals.cameraZoomChange.remove(this.onCameraZoomChange);
	}

	public override render() {
		const confirmText = "Confirm Alignment";

		return (
			<div className="SpaceAlignViewBar">
				<Button
					label={confirmText}
					title={confirmText}
					onClick={this.onConfirmClick}
				/>
				<LogarithmicSlider
					width={150}
					value={this.state.backgroundScale}
					largeStepValue={this.state.largeStepValue}
					min={this.state.centerScaleOnPointerDown / 2}
					max={this.state.centerScaleOnPointerDown * 2}
					title="Scale"
					onValueChange={this.onScaleChange}
					onPointerUp={this.onScaleEnd}
					arrows={true}
				/>
				{this.props.spaceViewRenderer.mode === SpaceEditorMode.ALIGN && (
					<>
						<ViewComponentToggler
							title="Old Background"
							type="currentLayer"
							label="Current"
							isActive={this.state.isOldBackgroundVisible}
							onClick={this.onOldLayerClick}
						/>
						<ViewComponentToggler
							title="New Background"
							type="newLayer"
							label="New"
							isActive={this.state.isNewBackgroundVisible}
							onClick={this.onNewLayerClick}
						/>
					</>
				)}
			</div>
		);
	}
}
