import styled from "styled-components";
import {baseDistance, ELLIPSIS, fontSize, fontWeight} from "../../../styles/styles";

export const LayerViewStyled = styled.div`
	.fieldContainer {
		.field {
			display: flex;
			align-items: center;
			gap: ${baseDistance.sm};
			height: 32px;
			-webkit-column-break-inside: avoid;
			page-break-inside: avoid;
			break-inside: avoid;

			.title-container {
				display: flex;
				position: relative;
				top: 2px;
				gap: ${baseDistance.xs};
			}

			.checkbox {
				gap: ${baseDistance.sm};
				font-size: ${fontSize.md};

				.title {
					display: flex;
					align-items: center;
				}
			}
		}

		.fields {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(268px, 1fr));
			padding-top: ${baseDistance.sm};

			.title-container {
				.layer-title {
					font-size: ${fontSize.md};
					font-weight: ${fontWeight.normal};
					align-items: center;
					max-width: 256px;
					${ELLIPSIS};
				}

				.itemCount {
					font-size: ${fontSize.md};
				}
			}
		}
	}
`;
