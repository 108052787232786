import type {Report} from "../../../data/models/Report";
import type {View} from "../../../data/models/View";
import type {IViewFolder} from "../../../data/models/ViewUtils";
import {XyiconFeature} from "../../../generated/api/base";

export const getLabelFromViewReportOrViewFolder = (
	item: View | Report | IViewFolder,
): "View" | "Report" | "View Folder" | "Global View" | "Global Report" => {
	if ((item as View).ownFeature) {
		if ((item as View).isGlobal) {
			return `${(item as View).ownFeature === XyiconFeature.View ? "Global View" : "Global Report"}`;
		} else {
			return `${(item as View).ownFeature === XyiconFeature.View ? "View" : "Report"}`;
		}
	} else {
		return "View Folder";
	}
};

export interface SelectedItem {
	id: string;
	itemName: string;
	feature: XyiconFeature;
}
