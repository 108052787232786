import {MathUtils as THREEMath} from "three";
import {Tween} from "@tweenjs/tween.js";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import type {Xyicon} from "../../../../../../data/models/Xyicon";
import type {BoundarySpaceMap} from "../../../../../../data/models/BoundarySpaceMap";
import type {SpaceItem} from "./SpaceItem";

interface IShakeProps {
	// Units are based on the values of unitTranslateAmplitude, and unitRotateAmplitude
	dx: number; // delta x
	dy: number; // delta y
	dr: number; // delta rotation
}

export class SpaceItemShakeManager {
	private _spaceViewRenderer: SpaceViewRenderer;
	private _spaceItem: SpaceItem;
	private _isShaking: boolean = false;

	private _defaultTransformations: IShakeProps = {
		dx: 0,
		dy: 0,
		dr: 0,
	};

	// Based on this: https://www.w3schools.com/howto/howto_css_shake_image.asp
	private _shakeProps: IShakeProps[] = [
		{
			...this._defaultTransformations,
		},
		{
			dx: 1,
			dy: 1,
			dr: 0,
		},
		{
			dx: -1,
			dy: -2,
			dr: -1,
		},
		{
			dx: -3,
			dy: 0,
			dr: 1,
		},
		{
			dx: 3,
			dy: 2,
			dr: 0,
		},
		{
			dx: 1,
			dy: -1,
			dr: 1,
		},
		{
			dx: -1,
			dy: 2,
			dr: -1,
		},
		{
			dx: -3,
			dy: 1,
			dr: 0,
		},
		{
			dx: 3,
			dy: 1,
			dr: -1,
		},
		{
			dx: -1,
			dy: -1,
			dr: 1,
		},
		{
			dx: 1,
			dy: 2,
			dr: 0,
		},
		{
			dx: 1,
			dy: -2,
			dr: -1,
		},
		{
			...this._defaultTransformations,
		},
	];

	constructor(spaceViewRenderer: SpaceViewRenderer, spaceItem: SpaceItem) {
		this._spaceViewRenderer = spaceViewRenderer;
		this._spaceItem = spaceItem;
	}

	private get unitTranslateAmplitude() {
		return 2 * this._spaceViewRenderer.correctionMultiplier.current;
	}

	private get unitRotateAmplitude() {
		return THREEMath.degToRad(2);
	}

	private get lastSavedOrientation() {
		return (this._spaceItem.modelData as Xyicon | BoundarySpaceMap).orientation;
	}

	private createTweenForShake(fromProps: IShakeProps, toProps: IShakeProps) {
		const fromPropsWithProperUnits = {
			dx: fromProps.dx * this.unitTranslateAmplitude,
			dy: fromProps.dy * this.unitTranslateAmplitude,
			dr: fromProps.dr * this.unitRotateAmplitude,
		};

		const toPropsWithProperUnits = {
			dx: toProps.dx * this.unitTranslateAmplitude,
			dy: toProps.dy * this.unitTranslateAmplitude,
			dr: toProps.dr * this.unitRotateAmplitude,
		};

		return new Tween<IShakeProps>(fromPropsWithProperUnits).to(toPropsWithProperUnits, 50);
	}

	private createTweensForShake() {
		const tweens: Tween<IShakeProps>[] = [];
		const lastIndex = this._shakeProps.length - 1;

		for (let i = 0; i < lastIndex; ++i) {
			const fromProps = this._shakeProps[i];
			const toProps = this._shakeProps[i + 1];

			const tween = this.createTweenForShake(fromProps, toProps);
			this._spaceViewRenderer.tweenGroup.add(tween);

			tween.onComplete(() => {
				this._spaceViewRenderer.tweenGroup.remove(tween);
			});

			tween.onUpdate(this.onShakeUpdate);
			if (0 < i) {
				tweens[i - 1].chain(tween);
			}

			if (i + 1 === lastIndex) {
				tween.onComplete(this.onShakeComplete);
			}
			tweens.push(tween);
		}

		return tweens[0];
	}

	private onShakeUpdate = (shakeProps: IShakeProps, e: number) => {
		// Handling the case when the spaceitem gets deleted while shaking
		if (!this._spaceItem.isDestroyed) {
			this._spaceItem.setRotation(this.lastSavedOrientation + shakeProps.dr);
			this._spaceItem.translate(shakeProps.dx, shakeProps.dy, 0, true);
			this._spaceItem.itemManager.updateSelectionBox();
			this._spaceViewRenderer.spaceItemController.updateBoundingBox();
		}
	};

	private onShakeComplete = () => {
		this._isShaking = false;
		this._spaceItem.stopTranslating();
	};

	public shake() {
		if (!this._isShaking && !this._spaceItem.isBeingMoved) {
			this._isShaking = true;

			this._spaceItem.startTranslating();

			const tween = this.createTweensForShake();

			tween.start();
		}
	}

	public get isShaking() {
		return this._isShaking;
	}
}
