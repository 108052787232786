import React from "react";

interface IGlobalSharingBodyComponent {
	readonly viewActionType?: ViewActionType;
	readonly isReports?: boolean;
}

type ViewActionType = "makePrivate" | "makeGlobal" | "modify" | "delete" | "rename" | "create";

export const GlobalSharingBodyComponent = (props: IGlobalSharingBodyComponent) => {
	const {viewActionType, isReports} = props;
	let message;
	const itemType = isReports ? "report" : "view";

	switch (viewActionType) {
		case "delete":
			message = `This action will delete the ${itemType} for all users who have access.`;
			break;
		case "modify":
		case "rename":
			message = `Changes you make are visible to all users who have access.`;
			break;
		case "makePrivate":
			message = `This action will remove access to the ${itemType} for all users. It will be available ${!isReports ? "under My Views" : "only to you"}.`;
			break;
		case "makeGlobal":
			message = `Global ${itemType}s are shared with all users in the organization. Administrators will have edit permissions. It will be available under Global.`;
			break;
		case "create":
			message = `This ${itemType} will be shared with all administrators in your organization as well as “All User” group. Do you wish to continue?`;
			break;
		default:
			message = "";
			break;
	}

	return <span>{message}</span>;
};
