import {FieldDataTypes} from "../../../../../../data/models/field/FieldDataTypes";
import {FieldDataType} from "../../../../../../generated/api/base";

interface ISingleLineLabelProps {
	readonly value: string;
}

export const UserLabel = (props: ISingleLineLabelProps) => {
	const formatted = FieldDataTypes.map[FieldDataType.User]?.formatter(props.value);

	return formatted ?? "";
};
