import styled from "styled-components";
import {baseDistance, fontSize, fontWeight} from "../styles/styles";

export const ToggleButton = styled.div`
	display: flex;
	gap: ${baseDistance.sm};

	.tabLabel {
		font-size: ${fontSize.xl};
		font-weight: ${fontWeight.bold};
	}
`;
