import * as React from "react";
import styled from "styled-components";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import {colorPalette} from "../../styles/colorPalette";
import {baseDistance, fontSize, InfoIconStyled, radius} from "../../styles/styles";
import {InfoBubbleV5} from "../../button/InfoBubbleV5";
import ErrorInfoIcon from "../../icons/errorInfo.svg?react";
import {Functions} from "../../../../utils/function/Functions";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {ReactUtils} from "../../../utils/ReactUtils";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";

interface ITextInputProps {
	readonly isTextArea?: boolean;
	readonly defaultValue?: string;
	readonly value?: string;
	readonly placeholder?: string;
	readonly className?: string;
	readonly style?: React.CSSProperties;
	readonly autoFocus?: boolean;
	readonly disabled?: boolean;
	readonly inputType?: IInputType;
	readonly trimText?: boolean; // trim the whitespace from the beginning and the end of the string if true
	readonly selectAll?: boolean;
	readonly borderColor?: string;
	readonly viewBoxSize?: number;
	readonly inline?: boolean;
	readonly caretPosition?: number;
	readonly showErrorOnlyIfEdited?: boolean;
	readonly divRef?: React.RefObject<HTMLDivElement>;
	readonly getErrorMessage?: (value: string) => string;
	readonly onChange?: (value: string) => void;
	readonly onBlur?: (isEnterPressed?: boolean) => void;
	readonly onInput?: (value: string, event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	readonly onClick?: (event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	readonly onKeyDown?: (event: React.KeyboardEvent, value: string) => void;
}

export type IInputType = "number" | "date" | "text" | "password" | "search";

interface ITextInputState {
	value: string;
	isFocusingInfoIcon: boolean;
	hasBeenEditedSinceSave: boolean;
	infoBubbleStyle: React.CSSProperties;
	isErrorMessageRightAlign: boolean;
}

export class TextInputV5 extends React.Component<ITextInputProps, ITextInputState> {
	private _ref = React.createRef<HTMLInputElement | HTMLTextAreaElement>();
	private _infoIconRef = React.createRef<HTMLDivElement>();
	private _infoBubbleRef = React.createRef<HTMLDivElement>();

	private _valueBeforeEditing: string = this.props.value;
	private _lastValidValue = this.props.value;
	private _isEscClicked: boolean = false;
	private _intervalId: number = -1;

	public static readonly defaultProps: ITextInputProps = {
		defaultValue: "",
		value: "",
		placeholder: "",
		trimText: true,
		showErrorOnlyIfEdited: true,
		inputType: "text",
		getErrorMessage: () => "",
	};

	constructor(props: ITextInputProps) {
		super(props);

		const value = this.props.defaultValue || this.props.value;

		this.state = {
			value: value,
			isFocusingInfoIcon: true,
			hasBeenEditedSinceSave: false,
			infoBubbleStyle: {},
			isErrorMessageRightAlign: false,
		};
	}

	private onInput = (event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => {
		const value = event.currentTarget.value;
		const errorMessage = this.props.getErrorMessage?.(value) || "";

		this.setState({
			value,
			isFocusingInfoIcon: true,
			hasBeenEditedSinceSave: true,
		});

		this.props.onInput?.(value, event);

		if (!errorMessage) {
			this._lastValidValue = value;
		}
	};

	private onKeyUp = (event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
			case KeyboardListener.KEY_ENTER:
				this.triggerChange(event);
				this.props.onBlur?.(true);
				break;
		}
	};

	private onBlur = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
		this.triggerChange(event);
		this.props.onBlur?.();

		this.setState({
			isFocusingInfoIcon: false,
		});

		return false;
	};

	private triggerChange = (
		event:
			| React.KeyboardEvent<HTMLInputElement>
			| React.KeyboardEvent<HTMLTextAreaElement>
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>,
	) => {
		const {onChange, inline} = this.props;

		if (onChange) {
			const eventTargetValue = this._ref.current?.value ?? event.currentTarget.value;
			const errorMessage = this.props.getErrorMessage(eventTargetValue);

			if (eventTargetValue != null) {
				const value = this.validateValue(eventTargetValue);

				if (value !== this.props.value) {
					this.props.onChange(value);
				}
			} else if (errorMessage && inline) {
				this.props.onChange(this._lastValidValue);
			}
		}
	};

	private onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === KeyboardListener.KEY_ESCAPE) {
			this._isEscClicked = true;
		}
		this.props.onKeyDown?.(event, this.state.value);
	};

	protected validateValue(value: string) {
		return this.props.trimText ? value.trim() : value;
	}

	private turnFocusOn = () => {
		this.setState({isFocusingInfoIcon: true});
	};

	private turnFocusOff = () => {
		this.setState({isFocusingInfoIcon: false});
	};

	private updateInfoBubbleStyle = () => {
		const parentElement = this._infoIconRef.current;
		const floatingElement = this._infoBubbleRef.current;

		if (floatingElement && parentElement) {
			const parent = parentElement.getBoundingClientRect();
			const floating = floatingElement.getBoundingClientRect();
			const x = parent.left + parent.width / 2 - floating.width / 2 + 5;

			let horizontalAlignment: HorizontalAlignment;

			if (x + floating.width > window.innerWidth - 5) {
				horizontalAlignment = HorizontalAlignment.right;
				if (!this.state.isErrorMessageRightAlign) {
					this.setState({isErrorMessageRightAlign: true});
				}
			} else {
				horizontalAlignment = HorizontalAlignment.center;
				if (this.state.isErrorMessageRightAlign) {
					this.setState({isErrorMessageRightAlign: false});
				}
			}

			const infoBubbleStyle: React.CSSProperties = {};
			const transformObj: TransformObj = DomUtils.getFixedFloatingElementPosition(
				parentElement,
				floatingElement,
				VerticalAlignment.topOuter,
				horizontalAlignment,
				5,
				0,
				true,
			);

			infoBubbleStyle.transform = transformObj.translate;

			if (!ObjectUtils.compare(this.state.infoBubbleStyle, infoBubbleStyle)) {
				this.setState({
					infoBubbleStyle,
				});
			}
		}
	};

	public override async componentDidMount() {
		const {caretPosition, inputType, autoFocus, selectAll} = this.props;
		const input = this._ref.current;

		if (autoFocus) {
			// wait till input values are assgined before focus
			await TimeUtils.wait(0);
			input.focus();
		}

		if (selectAll) {
			input.select();
		}

		// number input has no setSelectionRange
		if (caretPosition !== undefined && inputType !== "number" && input?.setSelectionRange) {
			input.focus();
			input.setSelectionRange(caretPosition, caretPosition);
		}

		FocusLoss.stopListen(input, this.onBlur as any);
		FocusLoss.listen(input, this.onBlur as any);

		// This hack is to "follow" the object's movement, if the user scrolls, for example
		// Don't worry, the "heavy" calculation only runs if the InfoBubble is being rendered,
		// and there can be only one InfoBubble in the application, because it's tied to the "focused" item
		this._intervalId = window.setInterval(this.updateInfoBubbleStyle, 0);
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: Readonly<ITextInputProps>, nextContext: any): void {
		if (this.props.value !== nextProps.value) {
			this.setState({
				value: nextProps.value,
			});

			if (!nextProps.onInput) {
				this.setState({
					hasBeenEditedSinceSave: false,
				});
			}
		}
	}

	public override componentDidUpdate = (prevProps: ITextInputProps, prevState: ITextInputState) => {
		const input = this._ref.current;

		if (!prevProps.autoFocus && this.props.autoFocus) {
			input.focus();
		}
		this.updateInfoBubbleStyle();
	};

	public override componentWillUnmount() {
		const {onChange} = this.props;
		const input = this._ref.current;

		if (input && onChange) {
			const currentValue = input.value;

			const value = this.validateValue(currentValue);
			const errorMessage = this.props.getErrorMessage(value);

			if (value !== this.props.value && !errorMessage && !this._isEscClicked) {
				onChange?.(value);
			} else if (this._isEscClicked) {
				onChange?.(this._valueBeforeEditing);
			}
		}

		this.props.onBlur?.();

		FocusLoss.stopListen(input, this.onBlur as any);
		clearInterval(this._intervalId);
	}

	public override render() {
		const {disabled, placeholder, className, style, autoFocus, onClick, borderColor, viewBoxSize, isTextArea, showErrorOnlyIfEdited, inputType} =
			this.props;
		const {value, isFocusingInfoIcon, infoBubbleStyle, hasBeenEditedSinceSave, isErrorMessageRightAlign} = this.state;
		const inputStyle: React.CSSProperties = {
			...style,
			width: viewBoxSize ?? style?.width,
			borderColor: borderColor ?? style?.borderColor,
		};

		const errorMessage = this.props.getErrorMessage(value);
		const showErrorMessage = (!showErrorOnlyIfEdited || (showErrorOnlyIfEdited && hasBeenEditedSinceSave)) && errorMessage && !disabled;

		if (isTextArea) {
			return (
				<textarea
					value={value}
					placeholder={placeholder}
					onClick={onClick}
					className={className || ""}
					style={inputStyle}
					autoFocus={autoFocus}
					onInput={this.onInput}
					onBlur={this.onBlur}
					disabled={!!disabled}
					ref={this._ref as React.RefObject<HTMLTextAreaElement>}
				/>
			);
		} else {
			return (
				<TextInputWrapperStyled>
					<TextInputStyled
						value={value}
						placeholder={placeholder}
						className={className || ""}
						style={inputStyle || null}
						autoFocus={autoFocus}
						onMouseDown={Functions.stopPropagation}
						onInput={this.onInput}
						onBlur={this.onBlur}
						onClick={onClick}
						disabled={!!disabled}
						ref={(this.props.divRef as React.RefObject<HTMLInputElement>) ?? (this._ref as React.RefObject<HTMLInputElement>)}
						onKeyUp={this.onKeyUp}
						onKeyDown={this.onKeyDown}
						type={inputType}
						onFocus={this.turnFocusOn}
					/>
					{showErrorMessage && (
						<InfoIconStyled
							ref={this._infoIconRef}
							className={ReactUtils.cls("infoIcon", {isFocusingInfoIcon})}
						>
							<ErrorInfoIcon
								onMouseEnter={this.turnFocusOn}
								onMouseLeave={this.turnFocusOff}
							/>
							<InfoBubbleV5
								divRef={this._infoBubbleRef}
								style={infoBubbleStyle}
								content={errorMessage}
								isErrorMessage={true}
								hide={!isFocusingInfoIcon}
								isErrorMessageRightAlign={isErrorMessageRightAlign}
							/>
						</InfoIconStyled>
					)}
				</TextInputWrapperStyled>
			);
		}
	}
}

const TextInputWrapperStyled = styled.div`
	position: relative;
	width: 100%;
`;

export const TextInputStyled = styled.input`
	background: none;
	width: 100%;
	height: 32px;
	border: 1px solid ${colorPalette.gray.c200Light};
	border-radius: ${radius.sm};
	padding: 0 ${baseDistance.sm};
	font-size: ${fontSize.md};
	line-height: 16px;

	&:focus {
		border-color: ${colorPalette.primary.c500Primary};
		outline: none;
	}
`;
