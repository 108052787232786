import {inject, observer} from "mobx-react";
import * as React from "react";
import type {IInputType} from "../text/TextInput";
import type {FieldDataType} from "../../../../generated/api/base";
import type {IFieldAdapter} from "../../../../data/models/field/Field";
import type {AppState} from "../../../../data/state/AppState";
import type {IModel} from "../../../../data/models/Model";
import {ClickToEditInput} from "./ClickToEditInput";
import {SingleLineInput} from "./datatypes/singleline/SingleLineInput";
import {FieldInputLabels, FieldInputs} from "./InputUtils";

interface IFieldInputProps {
	readonly dataType: FieldDataType;
	readonly dataTypeSettings: any;
	readonly value: any;
	readonly onChange?: (value: any) => void;
	readonly onClick?: () => void;
	readonly onHover?: (over?: boolean) => void;
	readonly hasValidation?: boolean;
	readonly disabled?: boolean;
	readonly updating?: boolean;
	readonly className?: string;
	readonly onFocusLossForceBlur?: boolean;
	readonly inputType?: IInputType;
	readonly noButtons?: boolean;
	readonly refId?: string;
	readonly appState?: AppState;
}

export interface IInputProps<T = any, V = any> {
	value: V;
	onInput?: (value: V) => void;
	onCancel?: () => void;
	onApply?: () => void;
	onChange?: (value: V) => void;
	onBlur?: () => void;
	onClick?: (e?: React.MouseEvent) => void;
	dataTypeSettings?: T;
	disabled?: boolean;
	updating?: boolean;
	height?: number;
	scrollHeight?: number;
	onFocusLossForceBlur?: boolean;
	noButtons?: boolean;
	inputType?: IInputType;
	field?: IFieldAdapter;
	focused?: boolean;
	inline?: boolean;
	expand?: boolean;
	focusLossStarterEvent?: "up" | "down";
	item?: IModel;
	selectedItems?: IModel[];
	secondaryState?: boolean;
	caretPosition?: number;
	noBooleanLabel?: boolean;
}

interface IFieldInputState {
	caretPosition: number;
}

@inject("appState")
@observer
export class FieldInput extends React.Component<IFieldInputProps, IFieldInputState> {
	public clickToEditRef = React.createRef<ClickToEditInput>();
	public _containerRef = React.createRef<HTMLDivElement>();

	private _isItInsideDetailsContainer = false;

	constructor(props: IFieldInputProps) {
		super(props);
		this.state = {
			caretPosition: 0,
		};
	}

	override componentDidMount(): void {
		const detailsContainerElement = document.querySelector(".DetailsContainer");

		if (detailsContainerElement) {
			this._isItInsideDetailsContainer = detailsContainerElement.contains(this._containerRef.current);
		}
	}

	private onClickEditInput = (event: React.MouseEvent) => {
		const sel = window?.getSelection();

		if (sel?.rangeCount) {
			const range = sel.getRangeAt(0);
			const preRange = range.cloneRange();
			const element = (this.clickToEditRef.current as any)._element.current.children[0];

			preRange.selectNodeContents(element);
			preRange.setEnd(range.endContainer, range.endOffset);

			this.setState({caretPosition: preRange.toString().length});
		}

		this.saveRefIdToAppState(event);
	};

	private saveRefIdToAppState(event: React.MouseEvent) {
		const {refId, appState, onClick} = this.props;

		if (this._isItInsideDetailsContainer === appState.isDetailsContainerOpened) {
			appState.selectedFieldInputRefId = refId || "";
		}

		onClick?.();
	}

	private getEditInput() {
		const {value, dataType, dataTypeSettings, onChange, disabled, updating, onFocusLossForceBlur, inputType, noButtons, appState, refId} = this.props;
		const {selectedFieldInputRefId} = appState;

		let InputComponent = FieldInputs[dataType];

		InputComponent = InputComponent || SingleLineInput;

		return (
			<InputComponent
				value={value}
				dataTypeSettings={dataTypeSettings}
				disabled={disabled}
				updating={updating}
				inputType={inputType}
				onFocusLossForceBlur={onFocusLossForceBlur}
				focused={selectedFieldInputRefId === refId && appState.isDetailsContainerOpened === this._isItInsideDetailsContainer}
				onChange={onChange}
				onClick={this.onClick}
				caretPosition={this.state.caretPosition}
				noButtons={noButtons}
				noBooleanLabel={false}
			/>
		);
	}

	private onClick = (e: React.MouseEvent) => {
		if (this.props.refId !== this.props.appState.selectedFieldInputRefId) {
			if (e) {
				e.stopPropagation();
			}

			this.saveRefIdToAppState(e);
		}
	};

	public override render() {
		const {
			appState,
			value,
			dataType,
			dataTypeSettings,
			className,
			onChange,
			onHover,
			disabled,
			updating,
			onFocusLossForceBlur,
			inputType,
			hasValidation,
			noButtons,
		} = this.props;

		const props = {
			ref: this.clickToEditRef,
			className,
			value,
			dataType,
			dataTypeSettings,
			onClick: this.onClickEditInput,
			onHover,
			disabled,
			updating,
			hasValidation,
			onFocusLossForceBlur,
			inputType,
			focused: appState.selectedFieldInputRefId === this.props.refId && appState.isDetailsContainerOpened === this._isItInsideDetailsContainer,
			onChange,
			caretPosition: this.state.caretPosition,
			noButtons,
		};

		return (
			<div
				style={{display: "contents"}}
				ref={this._containerRef}
			>
				{!FieldInputLabels[dataType] ? this.getEditInput() : <ClickToEditInput {...props} />}
			</div>
		);
	}
}
