import {computed, observable, makeObservable} from "mobx";
import type {CreateUserRequest, UpdateUserRequest, UpdateViewFavoritesFolderStructureRequest, UserDto} from "../../generated/api/base";
import {Permission, XyiconFeature, ViewPreferenceCategory} from "../../generated/api/base";
import type {AppState} from "../state/AppState";
import {ArrayUtils} from "../../utils/data/array/ArrayUtils";
import {StringUtils} from "../../utils/data/string/StringUtils";
import {XHRLoader} from "../../utils/loader/XHRLoader";
import type {IViewFolderStructures, ViewFolderStructure} from "./ViewUtils";
import {
	cleanViewFolderStructureFromAdditionalProps,
	doesItemExistInViewFolderStructure,
	featuresWithViews,
	filterViewFolderStructure,
	removeElementFromViewFolderStructureById,
} from "./ViewUtils";
import type {UserGroup} from "./UserGroup";
import type {IModel} from "./Model";
import type {View} from "./View";

export class User implements IModel, UserDto {
	public static id = 1;

	public static search(user: User, search = "") {
		if (!search) {
			return true;
		}

		search = search.toLowerCase();
		const name = user.fullName.toLowerCase();

		if (name.includes(search)) {
			return true;
		}
		const email = user.email.toLowerCase();

		if (email.includes(search)) {
			return true;
		}

		return false;
	}

	public readonly ownFeature = XyiconFeature.User;

	private _appState: AppState;

	@observable
	private _data: UserDto;

	constructor(data: UserDto, appState: AppState) {
		makeObservable(this);
		this._appState = appState;

		this.applyData(data);
	}

	public applyData(data: UserDto) {
		this._data = data;
		this._data.userGroupIDList = this._data.userGroupIDList || [];
		this._data.organizationFeaturePermissionList = this._data.organizationFeaturePermissionList || [];
		this._data.portfolioPermissionList = this._data.portfolioPermissionList || [];
		this._data.portfolioGroupPermissionList = this._data.portfolioGroupPermissionList || [];

		// When lodaing and switchin between organizations two currentuser API
		// calls are triggered and from the second API call the preferences are loaded again
		// but the preferences are accessed before the data is loaded and at that time the ui-version is not set
		// in the local storage. So, we need to set it here.
		// Please refer public get currentUIVersion() in  src/ts/data/state/AppState.ts
		if (this._data.preferences) {
			localStorage.setItem("ui-version", this._data.preferences.generalSetting?.currentUiVersion ?? "4.0");
		}

		this.updatePermissions();
	}

	private initViewFolderStructuresIfNeeded() {
		//
		// V4
		//
		if (!this._data.preferences) {
			this._data.preferences = {
				viewFolderStructure: {},
			};
		}
		// The old format was an array...
		if (!this._data.preferences.viewFolderStructure || Array.isArray(this._data.preferences.viewFolderStructure)) {
			this._data.preferences.viewFolderStructure = {};
		}

		const organizationId = this._appState.organizationId;

		if (organizationId) {
			if (!this._data.preferences.viewFolderStructure[organizationId]) {
				this._data.preferences.viewFolderStructure[organizationId] = {};
			}

			for (const feature of featuresWithViews) {
				if (!Array.isArray(this._data.preferences.viewFolderStructure[organizationId][feature])) {
					this._data.preferences.viewFolderStructure[organizationId][feature] = [];
				}
			}
		} else {
			console.warn("Organization Id should exist at this point. Check the order of loading the data.");
		}

		//
		// V5
		//
		if (!this.preferences?.viewFavorites?.[organizationId]) {
			if (!this.preferences) {
				this._data.preferences = {
					viewFavorites: {
						[organizationId]: [],
					},
				};
			} else if (!this.preferences.viewFavorites) {
				this._data.preferences.viewFavorites = {
					[organizationId]: [],
				};
			} else if (!this.preferences.viewFavorites[organizationId]) {
				this.preferences.viewFavorites[organizationId] = [];
			}
		}
	}

	// Basically a "migration" for outdated data
	// Removes views that are no longer available, and
	// Adds new views, that are not yet part of the viewfolderstructures
	public updateViewFolderStructures() {
		this.initViewFolderStructuresIfNeeded();

		const organizationId = this._appState.organizationId;

		if (organizationId) {
			const viewFolderStructures = this.viewFolderStructures[organizationId];

			for (const feature of featuresWithViews) {
				const availableViews = this._appState.actions.getViews(feature).toSorted((a, b) => StringUtils.sortIgnoreCase(a.name, b.name));
				const availableViewIds = availableViews.map((v) => v.id);

				// Remove views from structure that are not available anymore (deleted, or unshared)
				viewFolderStructures[feature] = filterViewFolderStructure(viewFolderStructures[feature], availableViewIds);

				// Add views to structure that are available, but not yet part of the structure
				for (const availableViewId of availableViewIds) {
					if (!doesItemExistInViewFolderStructure(viewFolderStructures[feature], availableViewId)) {
						viewFolderStructures[feature].push({id: availableViewId, category: ViewPreferenceCategory.View});
					}
				}
			}
		} else {
			console.warn("Organization Id should exist at this point. Check the order of loading the data.");
		}
	}

	public getUpdateData(): UpdateUserRequest {
		const data: UpdateUserRequest = {
			userID: this.id,
			email: this.email,
			isOrganizationAdmin: this.isAdmin,
			userGroupIDList: this.userGroupIds,
			organizationFeaturePermissionList: this.organizationFeaturePermissionList,
			portfolioGroupPermissionList: this.portfolioGroupPermissionList.filter((pgp) => pgp.portfolioGroupID && pgp.portfolioPermissionSetID),
			portfolioPermissionList: this.portfolioPermissionList.filter((pp) => pp.portfolioPermissionSetID && pp.portfolioID),
		};

		return data;
	}

	public getCreateData(): CreateUserRequest {
		const user = this;

		return {
			email: user.email,
			isOrganizationAdmin: user.isAdmin,
			userGroupIDList: user.userGroupIds,
			organizationFeaturePermissionList: user.organizationFeaturePermissionList,
			portfolioGroupPermissionList: user.portfolioGroupPermissionList,
			portfolioPermissionList: user.portfolioPermissionList,
			organizationID: this._appState.actions.getCurrentOrganizationId(),
		};
	}

	@computed
	public get userGroupIds(): string[] {
		return this._data.userGroupIDList;
	}

	// This should only be called from UserGroup.tsx where user is added to a UserGroup.
	// Otherwise code needs to be added here to add the user to the UserGroup too.
	public _addToUserGroup(userGroupId: string) {
		ArrayUtils.addMutable(this._data.userGroupIDList, userGroupId);
	}

	public _removeFromUserGroup(userGroupId: string) {
		ArrayUtils.removeMutable(this._data.userGroupIDList, userGroupId);
	}

	public set firstName(value: string) {
		this._data.firstName = value;
	}

	@computed
	public get isAdmin() {
		return !!this._data.isOrganizationAdmin;
	}

	public set isAdmin(value: boolean) {
		this._data.isOrganizationAdmin = value;
		this.updatePermissions();
	}

	public updatePermissions() {
		if (this.isAdmin) {
			this.setOrganizationPermission(XyiconFeature.XyiconCatalog, Permission.Delete);
			this.setOrganizationPermission(XyiconFeature.Report, Permission.Delete);
		} else {
			if (!this.getOrganizationPermissionObject(XyiconFeature.Report)) {
				// Non-admins have view permission for reports
				this.setOrganizationPermission(XyiconFeature.Report, Permission.View);
			}

			if (!this.getOrganizationPermissionObject(XyiconFeature.XyiconCatalog)) {
				// Add view for Catalog by default to make sure it's sent when creating a new user
				this.setOrganizationPermission(XyiconFeature.XyiconCatalog, Permission.View);
			}
		}
	}

	private getOrganizationPermissionObject(feature: XyiconFeature) {
		return this.organizationFeaturePermissionList.find((p) => p.feature === feature);
	}

	@computed
	public get organizationFeaturePermissionList() {
		return this._data.organizationFeaturePermissionList;
	}

	public getOrganizationPermission(feature: XyiconFeature) {
		const perm = this.getOrganizationPermissionObject(feature);

		if (perm) {
			return perm.permission;
		}
		return Permission.None;
	}

	public setOrganizationPermission(feature: XyiconFeature, permission: Permission) {
		const perm = this.getOrganizationPermissionObject(feature);

		if (perm) {
			perm.permission = permission;
		} else {
			this.organizationFeaturePermissionList.push({
				feature,
				permission,
			});
		}
	}

	public addOrRemoveUserFromUserGroups(userGroupIDList: string[], action: "add" | "remove", appState: AppState) {
		for (const id of userGroupIDList) {
			const userGroup = appState.actions.getFeatureItemById<UserGroup>(id, XyiconFeature.UserGroup);

			if (userGroup) {
				if (action === "add") {
					userGroup.addUser(this.id, appState);
				} else {
					userGroup.removeUser(this.id, appState);
				}
			} else {
				console.warn(`There is no User Group with this id: ${id}`);
			}
		}
	}

	@computed
	public get portfolioPermissionList() {
		return this._data.portfolioPermissionList;
	}

	@computed
	public get portfolioGroupPermissionList() {
		return this._data.portfolioGroupPermissionList;
	}

	@computed
	public get firstName() {
		return this._data.firstName;
	}

	public set lastName(value: string) {
		this._data.lastName = value;
	}

	@computed
	public get lastName() {
		return this._data.lastName;
	}

	@computed
	public get fullName() {
		const firstName = this.firstName || "";
		const lastName = this.lastName || "";

		return `${firstName}${firstName && " "}${lastName}`;
	}

	public set username(value: string) {
		this._data.username = value;
	}

	@computed
	public get username() {
		return this._data.username;
	}

	public set phone(value: string) {
		this._data.telephoneNumber = value;
	}

	@computed
	public get phone() {
		return this._data.telephoneNumber;
	}

	public set email(value: string) {
		this._data.email = value;
	}

	@computed
	public get email() {
		return this._data.email || "";
	}

	@computed
	public get id() {
		return this._data.userID;
	}

	@computed
	public get countryCode() {
		return this._data.countryCode;
	}

	@computed
	public get status() {
		let status = "";
		const data = this._data;

		if (!data.isEmailVerified) {
			status = "invited";
		} else {
			status = "active";
		}

		return status;
	}

	@computed
	public get lastModifiedAt() {
		return this._data.lastModifiedAt;
	}

	@computed
	public get settings() {
		return this._data.settings;
	}

	@computed
	public get profileFileName() {
		return this.settings?.profileFileName;
	}

	@computed
	public get preferences() {
		if (!this._data.preferences) {
			this._data.preferences = {};
		}
		if (!this._data.preferences.generalSetting) {
			this._data.preferences.generalSetting = {};
		}

		return this._data.preferences;
	}

	public get generalSetting() {
		if (!this.preferences.generalSetting) {
			this._data.preferences.generalSetting = {};
		}

		return this._data.preferences.generalSetting;
	}

	@computed
	private get organizationSettings() {
		if (!this.preferences.organizationSettings) {
			this._data.preferences.organizationSettings = [
				{
					organizationID: this._appState.organizationId,
					features: [],
				},
			];
		}

		let currentOrgSettings = this.preferences.organizationSettings?.find((o) => o.organizationID === this._appState.organizationId);

		if (!currentOrgSettings) {
			currentOrgSettings = {
				organizationID: this._appState.organizationId,
				features: [],
			};

			this.preferences.organizationSettings.push(currentOrgSettings);
		}

		return currentOrgSettings;
	}

	private getFavoriteArrayByFeature = (feature: XyiconFeature): string[] => {
		let featureSetting = this.organizationSettings.features.find((f) => f.feature === feature);

		if (!featureSetting) {
			featureSetting = {
				favorites: [],
				feature,
			};
			this.organizationSettings.features.push(featureSetting);
		}

		return featureSetting.favorites;
	};

	@computed
	public get favoriteCatalogs() {
		return this.getFavoriteArrayByFeature(XyiconFeature.XyiconCatalog);
	}

	@computed
	public get favoriteViews(): ViewFolderStructure {
		return (this.preferences.viewFavorites[this._appState.organizationId] as unknown as ViewFolderStructure) ?? [];
	}

	public setFavoriteViews(favoriteViews: ViewFolderStructure) {
		const allViews = this._appState.actions.getAllViews().toSorted((a: View, b: View) => StringUtils.sortIgnoreCase(a.name, b.name));
		const availableViews = allViews.toSorted((a, b) => StringUtils.sortIgnoreCase(a.name, b.name));
		const availableViewIds = availableViews.map((v) => v.id);
		const sanitizedFavoriteViews = cleanViewFolderStructureFromAdditionalProps(favoriteViews);

		this._data.preferences.viewFavorites[this._appState.organizationId] = filterViewFolderStructure(sanitizedFavoriteViews, availableViewIds);
		const params: UpdateViewFavoritesFolderStructureRequest = {
			viewFavorites: this._data.preferences.viewFavorites[this._appState.organizationId],
		};

		return this._appState.app.transport.requestForOrganization<ViewFolderStructure>({
			url: "currentuser/viewfavorites",
			method: XHRLoader.METHOD_POST,
			params,
		});
	}

	@computed
	public get viewFolderStructures(): IViewFolderStructures {
		return this.preferences?.viewFolderStructure || {};
	}

	public getViewFolderStructureForFeature(feature: XyiconFeature): ViewFolderStructure {
		return this.viewFolderStructures[this._appState?.organizationId]?.[feature] || [];
	}

	public removeElementFromViewFolderStructure(viewIdToDelete: string) {
		for (const feature of featuresWithViews) {
			const isDeletionSuccessful = removeElementFromViewFolderStructureById(
				this.viewFolderStructures[this._appState.organizationId][feature],
				viewIdToDelete,
			);

			if (isDeletionSuccessful) {
				return true;
			}
		}

		return false;
	}

	public removeElementFromFavoriteViews(viewIdToDelete: string) {
		const isDeletionSuccessful = removeElementFromViewFolderStructureById(this.favoriteViews, viewIdToDelete);

		if (isDeletionSuccessful) {
			return true;
		}

		return false;
	}

	@computed
	public get data() {
		return this._data;
	}

	@computed
	public get isUserLoggedInUsingXyiconCredentials() {
		return this._appState.user === this ? this._appState.app.transport.services.auth.authData.isUserLoggedInUsingXyiconCredentials : false;
	}
}

export class ExternalUser extends User {}
