import type {Texture, WebGLProgramParametersWithUniforms} from "three";
import {GLSL1, GLSL3, RawShaderMaterial} from "three";

export class MarkupPhoto360Material extends RawShaderMaterial {
	private _envMap: {
		type: "t";
		value: Texture;
	};

	private _cameraPos: {
		type: "v3";
		value: number[];
	};

	private _vertexShader: string = `precision highp float;

uniform mat4 viewMatrix;
uniform mat4 projectionMatrix;

attribute vec3 position;
attribute vec3 normal;
attribute mat4 instanceMatrix;

attribute vec3 color;
attribute float opacity;
attribute float isGrayScaled;

varying vec3 vWorldPosition;
varying vec3 vNormal;
varying vec3 vColor;
varying float vOpacity;
varying float vIsGrayScaled;

void main()
{
	vec4 worldPos4 = instanceMatrix * vec4(position, 1.0);
	vWorldPosition = worldPos4.xyz;
	vNormal = (instanceMatrix * vec4(normal.xyz, 0.0)).xyz;
	vColor = color;
	vOpacity = opacity;
	vIsGrayScaled = isGrayScaled;

	gl_Position = projectionMatrix * viewMatrix * worldPos4;
}
`;

	private _fragmentShader: string = `precision highp float;

//https://www.shadertoy.com/view/XdXSzX
#define SRGB_COEFFICIENTS vec3(0.2126, 0.7152, 0.0722)
#define PI 3.14159265359

varying vec3 vWorldPosition;
varying vec3 vNormal;
varying vec3 vColor;
varying float vOpacity;
varying float vIsGrayScaled;

uniform sampler2D envMap;
uniform vec3 cameraPos;

vec3 getNormalVector()
{
	return normalize(vNormal);
}

void main()
{
	vec3 normal = getNormalVector();

	vec3 eyeToSurfaceDir = normalize(vWorldPosition - cameraPos);
	vec3 refDir = reflect(eyeToSurfaceDir, normal);
	vec2 sphereUV = vec2(
		0.5 + atan(refDir.y, refDir.x) / (2.0 * PI),
		0.5 - asin(refDir.z) / PI
	);

	vec4 reflection = texture2D(envMap, sphereUV);

	vec3 toLight1 = normalize(vec3(0.8, 0.8, 1.0));
	vec3 toLight2 = normalize(vec3(-0.8, -1.3, -1.0));
	vec3 toLight3 = normalize(vec3(-1.3, 0.5, 0.5));

	// Apply gamma correction to texture color (linear to sRGB)
	vec3 correctedColor = pow(vColor.rgb, vec3(1.0 / 2.2));

	vec4 ambient = vec4(correctedColor.rgb * 0.3, vOpacity);
	vec4 diffuse = clamp(
		vec4(1.0) * (
			max(dot(toLight1, normal) * 0.92, 0.0) +
			max(dot(toLight2, normal) * 0.5, 0.0) +
			max(dot(toLight3, normal) * 0.63, 0.0)
		),
		0.0, 1.0
	);

	vec4 outputColor = mix(ambient + diffuse, reflection, 0.2) * vec4(correctedColor.rgb, vOpacity);
	outputColor = vIsGrayScaled > 0.5 ? vec4(vec3(dot(outputColor.rgb, SRGB_COEFFICIENTS)), outputColor.a) : outputColor;

	gl_FragColor = outputColor;
}
`;

	constructor(envMap: Texture, cameraPos: number[], isWebGL2Supported: boolean) {
		super({transparent: true, glslVersion: isWebGL2Supported ? GLSL3 : GLSL1});

		if (isWebGL2Supported) {
			this._vertexShader = this._vertexShader.replaceAll("attribute", "in").replaceAll("varying", "out");

			this._fragmentShader = this._fragmentShader
				.replaceAll("varying", "in")
				.replaceAll("texture2D", "texture")
				.replaceAll("void main()", "out vec4 fragColor;\n\nvoid main()")
				.replaceAll("gl_FragColor", "fragColor");
		}

		this._envMap = {
			type: "t",
			value: envMap,
		};

		this._cameraPos = {
			type: "v3",
			value: cameraPos,
		};

		this.onBeforeCompile = (program: WebGLProgramParametersWithUniforms) => {
			program.vertexShader = this._vertexShader;
			program.fragmentShader = this._fragmentShader;

			program.uniforms = {
				envMap: this._envMap,
				cameraPos: this._cameraPos,
			};
		};
	}

	public setCameraPosition(cameraPos: number[]) {
		this._cameraPos.value = cameraPos;
	}

	// So it can be disposed, when the scene is disposed
	public get envMap() {
		return this._envMap.value;
	}
}
