import type {Dispatch, SetStateAction} from "react";
import {useAppStore} from "../../../StateManager";
import {SearchFieldV5} from "../input/search/SearchFieldV5";
import SearchIcon from "../icons/search.svg?react";
import {NavigationMenuItemStyled} from "./NavigationMenuItem";

interface IViewFindProps {
	readonly viewSearchString: string;
	readonly setViewSearchString: Dispatch<SetStateAction<string>>;
}

export const ViewFind = (props: IViewFindProps) => {
	const {viewSearchString, setViewSearchString} = props;
	const navBarState = useAppStore((state) => state.navBarState);

	if (navBarState === "closed") {
		return (
			<NavigationMenuItemStyled $onlyIcon={true}>
				<SearchIcon />
				<h4>Find</h4>
			</NavigationMenuItemStyled>
		);
	}

	return (
		<SearchFieldV5
			autoFocus={true}
			value={viewSearchString}
			onInput={setViewSearchString}
		/>
	);
};
