import {MarkupType} from "../../../../../../../generated/api/base";

export const curveHeight = 10; // px
export const curvePeriod = 2 * curveHeight;
export const dashSize = 30; // px
export const gapSize = 15; // px
export const dottedLineDashSize = 4;
export const dottedLineGapSize = 4;
export const highlightRadius: number = 40; // px
export const highlightOpacity: number = 0.2;

export const MarkupsWithCustomizableColor: MarkupType[] = [
	MarkupType.Cloud,
	MarkupType.Arrow,
	MarkupType.BidirectionalArrow,
	MarkupType.Line,
	MarkupType.DashedLine,
	MarkupType.PencilDrawing,
	MarkupType.HighlightDrawing,
	MarkupType.Rectangle,
	MarkupType.Ellipse,
	MarkupType.Triangle,
	MarkupType.Cross,
	MarkupType.LinearDistance,
	MarkupType.RectangleArea,
	MarkupType.NonlinearDistance,
	MarkupType.IrregularArea,
	MarkupType.Callout,
	MarkupType.Photo360,
];

export const MarkupsWithInterchangeableProperties: MarkupType[] = [
	MarkupType.Cloud,
	MarkupType.Callout,
	MarkupType.TextBox,
	MarkupType.Arrow,
	MarkupType.BidirectionalArrow,
	MarkupType.Line,
	MarkupType.DashedLine,
	MarkupType.Rectangle,
	MarkupType.Ellipse,
	MarkupType.Triangle,
];

export const MarkupsWithCustomText: MarkupType[] = [...MarkupsWithInterchangeableProperties, MarkupType.Photo360];

export const MarkupsWithCustomizableTextAlignment: MarkupType[] = [
	MarkupType.Cloud,
	MarkupType.Rectangle,
	MarkupType.Ellipse,
	MarkupType.Triangle,
	MarkupType.Callout,
];

export const MarkupsWithIndependentTextSize: MarkupType[] = [
	MarkupType.Arrow,
	MarkupType.BidirectionalArrow,
	MarkupType.Line,
	MarkupType.DashedLine,
	MarkupType.Photo360,
];

export const MarkupsWithTextOffset: MarkupType[] = [...MarkupsWithIndependentTextSize];

export const MarkupsWithCustomizableFillOpacity: MarkupType[] = [
	MarkupType.Cloud,
	MarkupType.Rectangle,
	MarkupType.RectangleArea,
	MarkupType.Ellipse,
	MarkupType.IrregularArea,
	MarkupType.Triangle,
	MarkupType.Callout,
];

export const MarkupsWithChangeableLineThickness: MarkupType[] = [
	MarkupType.Line,
	MarkupType.DashedLine,
	MarkupType.Arrow,
	MarkupType.BidirectionalArrow,
	MarkupType.Callout,
];

export const MarkupsWithArrowHeads: MarkupType[] = [MarkupType.Arrow, MarkupType.BidirectionalArrow, MarkupType.Callout];
