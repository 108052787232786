import * as React from "react";
import {useState} from "react";
import type {ViewSharingSettingsData} from "../../../generated/api/base";
import {Permission, XyiconFeature} from "../../../generated/api/base";
import type {User} from "../../../data/models/User";
import type {UserGroup} from "../../../data/models/UserGroup";
import {AddUserOrUserGroupV5} from "../details/AddUserOrUserGroupV5";
import {InitialsV5} from "../widgets/InitialsV5";
import {ShareOption} from "../../modules/abstract/view/sharing/ShareOption";
import {ConfirmWindowV5} from "../popup/ConfirmWindowV5";
import {PopupV5} from "../popup/PopupV5";
import {ButtonV5} from "../button/ButtonV5";
import type {ReportSharingSettingsData} from "../../../generated/api/reports";
import {notify} from "../../../utils/Notify";
import {NotificationType} from "../../notification/Notification";
import {useAppStore} from "../../../StateManager";
import {NameContainerStyled, RowStyled, SectionTitle, SharingPanelStyled, SharingStyled} from "./SharingPanel.styled";
import {GroupShareSelectorV5} from "./GroupShareSelectorV5";
import type {SelectedItem} from "./SharingUtils";

export interface IGlobalViewFolderSharingSettingsData {
	userID?: string | null;
	userGroupID?: string | null;
}

interface IEditViewSharingProps {
	readonly onAddClick: (id: string, permission: Permission.View | Permission.Update, feature: XyiconFeature) => void;
	readonly onDeleteUserGroup: (userGroup: UserGroup) => Promise<any>;
	readonly onDeleteUser: (user: User) => Promise<any>;
	readonly onConfirmOwnershipChange: (sharing: ViewSharingSettingsData | ReportSharingSettingsData | IGlobalViewFolderSharingSettingsData) => void;
	readonly onSaveChanges: () => Promise<any>;
	readonly sharingSettings: ViewSharingSettingsData[] | ReportSharingSettingsData[] | IGlobalViewFolderSharingSettingsData[];
	readonly ownedByUserId: string;
	readonly itemLabel: "View" | "Report" | "View Folder" | "Global View" | "Global Report";
	readonly itemName: string;
	readonly showShareOption: boolean;
	readonly onClose: () => void;
	readonly isGlobal?: boolean;
}

export const SharingPopupV5 = (props: IEditViewSharingProps) => {
	const appState = useAppStore((state) => state.appState);
	const {
		onAddClick,
		onDeleteUserGroup,
		onDeleteUser,
		onConfirmOwnershipChange,
		onSaveChanges,
		sharingSettings,
		ownedByUserId,
		itemLabel,
		itemName,
		showShareOption,
		onClose,
		isGlobal,
	} = props;

	const [isDeletePopupWindowOpen, setIsDeletePopupWindowOpen] = useState<boolean>(false);
	const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
	const [permissionType, setPermissionType] = useState<Permission.View | Permission.Update>(null);

	const allUsers = appState.actions.getList<User>(XyiconFeature.User);
	const userGroups = appState.actions.getList<UserGroup>(XyiconFeature.UserGroup);
	const sharing = sharingSettings || [];
	const userGroupSharingList = sharing.filter((sh) => sh.userGroupID);
	const userSharingList = sharing.filter((sh) => sh.userID);
	const itemOwnerMaybe: User | null = allUsers.find((user) => user.id === ownedByUserId);
	const users = allUsers.filter((user) => user.id !== ownedByUserId);
	const exceptionIds: string[] = [...userGroupSharingList.map((sh) => sh.userGroupID), ...userSharingList.map((sh) => sh.userID)];
	const exceptionsWithCurrentUser = [...exceptionIds, !isGlobal && appState.user?.id];

	let sharedUsers: User[] = [];
	let sharedUserGroups: UserGroup[] = [];

	if (exceptionsWithCurrentUser?.length) {
		sharedUsers = users.filter((user) => exceptionsWithCurrentUser.includes(user.id));
	}

	if (exceptionIds?.length) {
		sharedUserGroups = userGroups.filter((userGroup) => exceptionIds.includes(userGroup.id));
	}

	const handleClick = (event: React.MouseEvent) => {
		event.stopPropagation();
	};

	const onDeleteItem = async (item: User | UserGroup) => {
		if (!isDeletePopupWindowOpen) {
			setIsDeletePopupWindowOpen(true);
			const confirmed = await ConfirmWindowV5.open("Are you sure you want to delete the selected 1 item?");
			setIsDeletePopupWindowOpen(false);

			if (confirmed) {
				if (item.ownFeature === XyiconFeature.User) {
					onDeleteUser(item as User);
				} else {
					onDeleteUserGroup(item as UserGroup);
				}
				onSaveChanges();
			}
		}
	};

	const onShareClick = () => {
		selectedItems.forEach((item) => {
			onAddClick(item.id, permissionType, item.feature);
			notify(appState.app.notificationContainer, {
				type: NotificationType.Success,
				title: `"${item.itemName}" ${itemLabel} has been shared successfully!`,
				lifeTime: 10000,
			});
		});
		onSaveChanges();
		setSelectedItems([]);
	};

	const setUserPermission = async (permission: number, sharing: ViewSharingSettingsData | ReportSharingSettingsData) => {
		if (permission === ShareOption.OWNER) {
			if (!isDeletePopupWindowOpen) {
				setIsDeletePopupWindowOpen(true);
				const confirmed = await ConfirmWindowV5.open(
					`You will be removed as the owner of the "${itemName}" ${itemLabel} but will retain edit privileges. Do you want to continue?`,
					"Confirm Ownership Change",
					{ok: "Change Owner", cancel: "Cancel"},
				);
				setIsDeletePopupWindowOpen(false);

				if (confirmed) {
					onConfirmOwnershipChange(sharing);
				}
			}
		} else {
			const canEdit = permission === Permission.Update;

			if (itemLabel.includes("View")) {
				(sharing as ViewSharingSettingsData).canEditSharedView = canEdit;
			} else if (itemLabel.includes("Report")) {
				(sharing as ReportSharingSettingsData).canEditSharedReport = canEdit;
			}
		}
		onSaveChanges();
	};

	const setUserGroupPermission = (permission: number, sharing: ViewSharingSettingsData | ReportSharingSettingsData) => {
		if (itemLabel.includes("View")) {
			(sharing as ViewSharingSettingsData).canEditSharedView = permission === Permission.Update;
		} else if (itemLabel.includes("Report")) {
			(sharing as ReportSharingSettingsData).canEditSharedReport = permission === Permission.Update;
		}
		onSaveChanges();
	};

	const onSetSelectedItems = (items: []) => {
		setSelectedItems(items);
	};

	const getPermission = (value: Permission.View | Permission.Update) => {
		setPermissionType(value);
	};

	return (
		<PopupV5
			onClose={onClose}
			label={`Share ${itemLabel}: ${itemName}`}
			centerOnScreen={true}
			className="SharingPopup"
			width="528px"
			height="528px"
			freezeRoot={true}
			closeOnClickOutside={false}
		>
			<SharingStyled onClick={handleClick}>
				<div className="container">
					<AddUserOrUserGroupV5
						exceptions={exceptionsWithCurrentUser}
						selectedItemList={selectedItems}
						ownedByUserId={ownedByUserId}
						setSelectedItems={onSetSelectedItems}
						onDeleteItem={onDeleteItem}
						getPermissionType={getPermission}
						isGlobal={isGlobal}
					/>
					<ButtonV5
						label="Share"
						title="Share"
						className="primary rounded dark"
						disabled={selectedItems.length === 0}
						onClick={onShareClick}
					/>
				</div>
				<SharingPanelStyled>
					<div className="borderBottom">
						<SectionTitle>User Groups</SectionTitle>
						<GroupShareSelectorV5
							items={sharedUserGroups}
							addedItems={userGroups}
							sharingList={userGroupSharingList}
							isSharing={true}
							showShareOption={showShareOption}
							onDeleteItem={onDeleteItem}
							setUserGroupPermission={setUserGroupPermission}
							isGlobal={isGlobal}
							itemLabel={itemLabel}
							feature={XyiconFeature.UserGroup}
						/>
					</div>
					<div>
						<SectionTitle>Users</SectionTitle>
						{itemOwnerMaybe && (
							<RowStyled className="item hbox">
								<div className="avatar">
									{itemOwnerMaybe?.profileFileName ? (
										<img
											src={itemOwnerMaybe.profileFileName}
											alt={`${itemOwnerMaybe?.fullName} profile image`}
										/>
									) : (
										<InitialsV5
											name={itemOwnerMaybe?.fullName || itemOwnerMaybe?.email}
											className="users"
										/>
									)}
								</div>
								<NameContainerStyled>
									<div
										className="name"
										title={`${itemOwnerMaybe?.fullName}`}
									>{`${itemOwnerMaybe?.fullName}`}</div>
									<div className="email">{itemOwnerMaybe?.email}</div>
								</NameContainerStyled>
								<div className="sharedStyles"> Owner</div>
							</RowStyled>
						)}
						<GroupShareSelectorV5
							items={sharedUsers}
							addedItems={users}
							sharingList={userSharingList}
							isSharing={true}
							showShareOption={showShareOption}
							onDeleteItem={onDeleteItem}
							setUserPermission={setUserPermission}
							feature={XyiconFeature.User}
							itemLabel={itemLabel}
						/>
					</div>
				</SharingPanelStyled>
			</SharingStyled>
		</PopupV5>
	);
};
