import type {PerspectiveCamera, OrthographicCamera, CanvasTexture, Texture} from "three";
import {Scene, WebGLRenderer, Color, Vector3, Vector2} from "three";
import {PlaneGeometry} from "three/src/geometries/PlaneGeometry.js";
import {Group as TweenGroup} from "@tweenjs/tween.js";
import {computed, observable, makeObservable} from "mobx";
import {TileManager} from "../managers/TileManager";
import {TextureManager} from "../managers/TextureManager";
import {ToolManager} from "../features/ToolManager";
import {SpaceItemController} from "../managers/spaceitems/SpaceItemController";
import type {ExtendedDistanceUnitName} from "../Constants";
import {Constants} from "../Constants";
import {SelectionBox} from "../elements3d/SelectionBox";
import type {ItemManager} from "../managers/spaceitems/ItemManager";
import type {SetPinTool} from "../features/tools/SetPinTool";
import type {ClipboardActionType} from "../managers/ClipboardManager";
import {ClipboardManager} from "../managers/ClipboardManager";
import {GhostModeManager} from "../managers/GhostModeManager";
import type {InvisibleLinkedXyiconType} from "../../ui/actionbar/LinkedXyiconsWindow";
import type {IToolConfig, SpaceTool} from "../features/tools/Tools";
import {initTools} from "../features/tools/Tools";
import type {ThemeType} from "../../../../../ThemeType";
import {ResizeDetector} from "../../../../../../utils/resize/ResizeDetector";
import {HTMLUtils} from "../../../../../../utils/HTML/HTMLUtils";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import {Signal} from "../../../../../../utils/signal/Signal";
import {Convergence} from "../../../../../../utils/animation/Convergence";
import {XyiconFeature, Permission, SpaceFileTileMode} from "../../../../../../generated/api/base";
import type {ISpaceItemModel} from "../../../../../../data/models/Model";
import type {PortTemplateDto, SpaceFileInsertionInfo, PointDouble, Color as ColorDto} from "../../../../../../generated/api/base";
import type {Space} from "../../../../../../data/models/Space";
import type {Xyicon} from "../../../../../../data/models/Xyicon";
import type {BoundarySpaceMap} from "../../../../../../data/models/BoundarySpaceMap";
import type {Markup} from "../../../../../../data/models/Markup";
import type {IPropsForEyeDropper} from "../../../../abstract/common/colorselector/colorwindow/EyeDropper";
import type {App} from "../../../../../../App";
import {notify} from "../../../../../../utils/Notify";
import {NotificationType} from "../../../../../notification/Notification";
import type {INotificationElementParams} from "../../../../../notification/AppNotifications";
import {calculateSpaceResolution, SpaceEditorMode} from "./SpaceViewRendererUtils";

export interface IRenderSignalParam {
	camera: PerspectiveCamera | OrthographicCamera;
	spaceSize: {
		width: number;
		height: number;
	};
}

export interface ILoadProgress {
	numberOfLoadedItems: number;
	itemsToLoad: string[];
}

export interface IinheritedMethods {
	openActionBar: (worldX: number, worldY: number) => void;
	closeActionBar: () => void;
	openPortSelector: (worldX: number, worldY: number, item: Xyicon, portEndPoints: PortTemplateDto[], type: "from" | "to") => void;
	closePortSelector: () => void;
	openContextMenu: (worldX: number, worldY: number) => void;
	isContextMenuOpen: () => boolean;
	closeContextMenu: () => void;
	selectItems: (items: ISpaceItemModel[], selectDetailsTab?: boolean, forceUpdate?: boolean) => void;
	focusItems: (items: ISpaceItemModel[]) => void;
	getFocusedItems: () => ISpaceItemModel[];
	setScale: (spaceUnitsPerMeter: number, setToAllSpaces: boolean) => void;
	confirmAlignment: (insertionInfo: SpaceFileInsertionInfo) => void;
	setActiveTool: (toolId: SpaceTool) => void;
	onPasteClick: () => void;
	openLinkBreakers: (fromObjectIds: string[]) => void;
	updateLinkBreakers: () => void;
	closeLinkBreakers: () => void;
	openLinkedXyiconsWindow: (fromObjectIds: string[], type: InvisibleLinkedXyiconType) => void;
	closeLinkedXyiconsWindow: () => void;
	onEditModeSwitched: (value: boolean) => void;
	onSwitchToTextEditMode: () => void;
	onExitTextEditMode: () => void;
}

export class SpaceViewRenderer {
	private _tileScene: Scene; // for the pdf
	private _markupScene: Scene; // for objects with no height, on top of the ground
	private _xyiconScene: Scene; // for objects with height
	private _topLayerScene: Scene; // contains elements that should be rendered on top of the activescene (like selectionbox for example)
	private _selectionBox: SelectionBox;
	private _canvas: HTMLCanvasElement = document.createElement("canvas");
	private _domElement: HTMLDivElement = document.createElement("div");
	private _tools: {[id in SpaceTool]: IToolConfig};

	@observable
	private _space: Space;
	@observable
	public isMeasureToolBarOpen: boolean = false;
	@observable
	public measureToolUnit: ExtendedDistanceUnitName = "foot&inch";

	private _app: App;
	private _inheritedMethods: IinheritedMethods;
	private readonly _planeGeometry = new PlaneGeometry(1, 1); /** Almost every mesh on the canvas is a rectangle, so they can all use this geometry */
	private _textureManager: TextureManager;
	private _tileManager: TileManager;
	private _spaceItemController: SpaceItemController;
	private _activeCamera: PerspectiveCamera | OrthographicCamera;
	private _resizeDetector: ResizeDetector;
	private _needsResize = false;
	private _renderer: WebGLRenderer;
	private _tileSetsFinished: number;
	private _toolManager: ToolManager;
	private _spaceSize: Vector2 = new Vector2();
	private _spaceOffset: Vector3 = new Vector3();
	private _spaceResolution: PointDouble = {x: 100, y: 100};
	private _correctionMultiplier: number = 1;
	private _loopId: number = null;
	public needsRender: boolean = false;
	public signals = {
		onBeforeRender: Signal.create(),
		onAfterRender: Signal.create<IRenderSignalParam>(), // dispatches a signal event after render
		themeChange: Signal.create<ThemeType>(),
		loadProgress: Signal.create<ILoadProgress>(),
		spaceLoadStarted: Signal.create<string>(), // spaceId
		spaceLoadReady: Signal.create(),
		onCanvasResized: Signal.create<number, number>(),
	};
	private _renderSignalParam: IRenderSignalParam;
	private _initialize: Promise<boolean>;
	private _timeStamp: number = 0;
	private _lastDispatchedLoadedRatio: number = 0;
	private _isMounted: boolean = false;
	private _mode: SpaceEditorMode = SpaceEditorMode.NORMAL;
	private _ghostModeManager: GhostModeManager;
	public readonly tweenGroup = new TweenGroup();
	public activeConvergences: Convergence[] = [];
	public measureToolColor: ColorDto = this.measureToolDefaultColor;
	public itemsHiddenNotificationMaybe: INotificationElementParams | null = null;

	constructor(app: App) {
		makeObservable(this);
		this._app = app;
		this._domElement.id = "canvas-div";
		this._domElement.style.position = "absolute";
		this._domElement.style.top = "0";
		this._domElement.style.left = "0";
		this._domElement.style.width = "100%";
		this._domElement.style.height = "100%";

		this._markupScene = THREEUtils.createScene("markupScene");
		this._xyiconScene = THREEUtils.createScene("xyiconScene");
		this._tileScene = THREEUtils.createScene("tileScene");
		this._topLayerScene = new Scene();
		this._topLayerScene.name = "topLayer";
		this._textureManager = new TextureManager(this, this.transport);
		this._tileManager = new TileManager(this);
		this._renderer = this.createWebGLRenderer();

		this._toolManager = new ToolManager(this);
		this._spaceItemController = new SpaceItemController(this);
		this._ghostModeManager = new GhostModeManager(this);
		this._selectionBox = new SelectionBox(this);
		this._activeCamera = this._toolManager.cameraControls.activeCamera;
		this._tools = initTools(this);

		this.signals.loadProgress.add((progressData: ILoadProgress) => {
			const ratio = progressData.numberOfLoadedItems / progressData.itemsToLoad.length;

			this._lastDispatchedLoadedRatio = ratio;
			if (ratio === 1) {
				this.signals.spaceLoadReady.dispatch();
			} else if (ratio === 0) {
				this.signals.spaceLoadStarted.dispatch(this._space.id);
			}
		});

		console.log("SpaceViewRenderer Created");
	}

	public initialize() {
		if (!this._initialize) {
			this._initialize = new Promise<boolean>(async (resolve, reject) => {
				if (this.themeType === "dark") {
					this.updateTheme();
				}
				await this._textureManager.initMiscTextures(); // this way we can guarantee that the required textures are already loaded before we init the xyicons
				console.log("SpaceViewRenderer Initialized");
				resolve(true);
			});
		}

		return this._initialize;
	}

	private revokeURLs(space: Space) {
		for (const spaceFile of space.spaceFiles) {
			URL.revokeObjectURL(spaceFile.sourceFileURL);
		}
	}

	public get areTilesAvailableOnline() {
		return this._space.selectedSpaceFile.mode === SpaceFileTileMode.PreGenerated && this._mode === SpaceEditorMode.NORMAL;
	}

	private calculateSpaceResolution() {
		const spaceFile = this.getProperSpaceFile();

		const xyiconSize = spaceFile.parent.type.settings.xyiconSize;
		const {spaceResolution, correctionMultiplier} = calculateSpaceResolution(
			spaceFile.parent.spaceUnitsPerMeter,
			spaceFile.insertionInfo,
			xyiconSize,
			this._mode,
		);

		this._spaceResolution.x = spaceResolution.x;
		this._spaceResolution.y = spaceResolution.y;
		this._correctionMultiplier = correctionMultiplier;

		console.log(`Xyicon size: ${xyiconSize.value} ${xyiconSize.unit}`);
		console.log(`Space width: ${spaceFile.insertionInfo.width}, height: ${spaceFile.insertionInfo.height}`);
		console.log(`Space units per meter: ${spaceFile.parent.spaceUnitsPerMeter}`);
		console.log(`Space resolution: ${this._spaceResolution.x}px * ${this._spaceResolution.y}px`);
	}

	private getArrayOfStuffToBeLoaded(): string[] {
		switch (this._mode) {
			case SpaceEditorMode.NORMAL:
				return ["Retrieving the floor plan", "Retrieving xyicons", "Retrieving boundaries", "Retrieving markups", "Preparing your space"];
			case SpaceEditorMode.SET_SCALE:
				return ["Retrieving the floor plan", "Preparing your space"];
			case SpaceEditorMode.ALIGN:
				return [
					"Retrieving the older version of your floor plan",
					"Rasterizing the older version of your floor plan",
					"Retrieving the newer version of your floor plan",
					"Rasterizing the newer version of your floor plan",
				];
		}
	}

	private getProperSpaceFile() {
		switch (this._mode) {
			case SpaceEditorMode.NORMAL:
				return this._space.selectedSpaceFile;
			case SpaceEditorMode.SET_SCALE:
				return this._space.newestSpaceFile;
			case SpaceEditorMode.ALIGN:
				return this._space.newestValidSpaceFile;
		}
	}

	public async populateData(space: Space) {
		if (this._space !== space) {
			this.stopAnimationLoop();
			this._ghostModeManager.stop(true);

			if (this._space) {
				this.revokeURLs(this._space);
			}

			let loadedStuff = 0;
			const arrayOfStuffToBeLoaded = this.getArrayOfStuffToBeLoaded();

			this._space = space;
			this.measureToolUnit = this._space.type?.settings?.unitOfMeasure ?? this._space.type?.settings?.xyiconSize?.unit ?? "foot&inch";
			this._tileSetsFinished = 0;
			this.signals.loadProgress.dispatch({itemsToLoad: arrayOfStuffToBeLoaded, numberOfLoadedItems: loadedStuff});
			this._toolManager.deactivateCameraControls();
			await this.initialize();
			this.clearData();

			if (!this._isMounted) {
				return;
			}

			const spaceFile = this.getProperSpaceFile();
			const sourceFileURL = spaceFile.sourceFileURL;

			const insertionInfo = spaceFile.insertionInfo;

			this._spaceOffset.set(insertionInfo.offsetX, insertionInfo.offsetY, insertionInfo.offsetZ);
			this._topLayerScene.position.setZ(this._spaceOffset.z);
			this._spaceSize.set(insertionInfo.width, insertionInfo.height ?? 0);

			if (!this.areTilesAvailableOnline) {
				await this.pdfRenderer.init(sourceFileURL, this._spaceSize.width);
				this._spaceSize.height = this.pdfRenderer.spaceSize.height;
				insertionInfo.height = this._spaceSize.height;
			}

			if (!insertionInfo.height) {
				console.warn("Data problem: insertionInfo.height is not valid!");
			}

			if (!this._isMounted) {
				return;
			}

			this.signals.loadProgress.dispatch({itemsToLoad: arrayOfStuffToBeLoaded, numberOfLoadedItems: ++loadedStuff});

			this._tileManager.clearAndInit();
			console.log(spaceFile.sourceFileURL);
			this.calculateSpaceResolution();
			const numberOfZoomLevels = this._tileManager.populateData(sourceFileURL);

			this.xyiconManager.initXyiconSize(Constants.SIZE.XYICON * this._correctionMultiplier);

			if (this._mode === SpaceEditorMode.NORMAL) {
				await this.transport.services.feature.refreshList(XyiconFeature.XyiconCatalog);
				await this.transport.services.feature.refreshList(XyiconFeature.Link);
				// Load items
				const allLibraryModels = await this.transport.services.feature.refreshList(XyiconFeature.LibraryModel); // dataset for 3d xyicons (links to the 3d models, ids, etc)
				const allXyicons = await this.transport.services.feature.refreshList(XyiconFeature.Xyicon);

				this.signals.loadProgress.dispatch({itemsToLoad: arrayOfStuffToBeLoaded, numberOfLoadedItems: ++loadedStuff});
				const allBoundaries = await this.transport.services.feature.refreshList(XyiconFeature.Boundary);

				this.signals.loadProgress.dispatch({itemsToLoad: arrayOfStuffToBeLoaded, numberOfLoadedItems: ++loadedStuff});
				const allMarkups = await this.transport.services.feature.refreshList(XyiconFeature.Markup);

				this.signals.loadProgress.dispatch({itemsToLoad: arrayOfStuffToBeLoaded, numberOfLoadedItems: ++loadedStuff});

				if (!this._isMounted) {
					return;
				}
				await this.addSpaceItems();
			} else {
				if (!this._isMounted) {
					return;
				}
				await this._spaceItemController.init([], [], []);
			}

			if (!this._isMounted) {
				return;
			}

			this._ghostModeManager.init();
			this._inheritedMethods.closeActionBar();
			this._inheritedMethods.closeContextMenu();
			this._inheritedMethods.closePortSelector();
			const canvasSize = HTMLUtils.getSize(this._canvas);

			this._toolManager.init(canvasSize.width, canvasSize.height, this._spaceOffset, numberOfZoomLevels);

			this._renderSignalParam = {
				camera: this._activeCamera,
				spaceSize: {
					width: this._spaceSize.width,
					height: this._spaceSize.height,
				},
			};

			this._renderer.compile(this._tileScene, this._activeCamera);

			if (this._mode === SpaceEditorMode.NORMAL) {
				await this._spaceItemController.updateFilterState(undefined, false);
			}

			// For align mode, we wait till all the tiles are created
			if (this._mode !== SpaceEditorMode.ALIGN) {
				this._toolManager.activateCameraControls();
				this._toolManager.activeTool.activate();
			}

			window.getSelection().empty();

			this.startAnimationLoop();
			requestAnimationFrame(() => {
				// Call cameracontrols.update manually once to make sure the camera is in the right position
				// even if it's not activated yet (ALIGN mode)
				this.toolManager.cameraControls.update(true);
			});
		}
	}

	private startAnimationLoop() {
		if (this._loopId == null) {
			this._loopId = requestAnimationFrame(() => {
				this.needsRender = true;

				this.onFrame();
			});
		}
	}

	private stopAnimationLoop() {
		cancelAnimationFrame(this._loopId);
		this._loopId = null;
	}

	private invertClearColor() {
		const clearColor = new Color();

		this._renderer.getClearColor(clearColor);
		this._renderer.setClearColor(new Color(1 - clearColor.r, 1 - clearColor.g, 1 - clearColor.b));
	}

	public async onTileSetFinish() {
		const arrayOfStuffToBeLoaded = this.getArrayOfStuffToBeLoaded();

		if (this._mode === SpaceEditorMode.ALIGN) {
			this._tileSetsFinished++;
			if (this._tileSetsFinished === 2) {
				this.signals.loadProgress.dispatch({itemsToLoad: arrayOfStuffToBeLoaded, numberOfLoadedItems: arrayOfStuffToBeLoaded.length});
				const centerX = this._spaceOffset.x + this._spaceSize.x / 2;
				const centerY = this._spaceOffset.y + this._spaceSize.y / 2;

				(this._tools.setPin.tool as SetPinTool).setPinPosition(centerX, centerY);
				this._toolManager.activateCameraControls();
				this._toolManager.activeTool.activate();
			} else {
				this.signals.loadProgress.dispatch({itemsToLoad: arrayOfStuffToBeLoaded, numberOfLoadedItems: arrayOfStuffToBeLoaded.length / 2});
				const oldSizeInPDFUnits = {
					x: this.pdfRenderer.pdfPageWidth,
					y: this.pdfRenderer.pdfPageHeight,
				};

				const oldCropValues = {
					x: this.pdfRenderer.cropLeft,
					y: this.pdfRenderer.cropBottom,
				};

				const oldSpaceToPDFRatio = this.pdfRenderer.spaceToPDFRatio;

				const spaceFile = this._space.newestSpaceFile;

				await this.pdfRenderer.savePDFValues(spaceFile.sourceFileURL);
				this.signals.loadProgress.dispatch({itemsToLoad: arrayOfStuffToBeLoaded, numberOfLoadedItems: arrayOfStuffToBeLoaded.length - 1});

				if (spaceFile.isInsertionInfoModified) {
					const insertionInfo = spaceFile.insertionInfo;
					const newSpaceWidth = insertionInfo.width;

					this.pdfRenderer.saveSpaceSize(newSpaceWidth);
					this._spaceSize.set(newSpaceWidth, this.pdfRenderer.spaceSize.height);

					this._spaceOffset.set(insertionInfo.offsetX, insertionInfo.offsetY, insertionInfo.offsetZ);

					// [0] is not valid, it's temporary
					const oldBackgroundWidth = this._space.newestValidSpaceFile.insertionInfo.width;

					this._spaceResolution.x = (this._spaceResolution.x * newSpaceWidth) / oldBackgroundWidth;
				} else {
					const newSizeInPDFUnits = {
						x: this.pdfRenderer.pdfPageWidth,
						y: this.pdfRenderer.pdfPageHeight,
					};

					const newCropValues = {
						x: this.pdfRenderer.cropLeft,
						y: this.pdfRenderer.cropBottom,
					};

					const newSpaceWidth = (this._spaceSize.x * newSizeInPDFUnits.x) / oldSizeInPDFUnits.x;

					this.pdfRenderer.saveSpaceSize(newSpaceWidth);

					// We limit the resolution to DEFAULT_SPACE_RESOLUTION, because the calculated resolution can be extremely large depending on the values
					this._spaceResolution.x = Math.min(Constants.DEFAULT_SPACE_RESOLUTION, (this._spaceResolution.x * newSpaceWidth) / this._spaceSize.width);

					this._spaceSize.set(newSpaceWidth, this.pdfRenderer.spaceSize.height);

					this._spaceOffset.add(
						new Vector3((newCropValues.x - oldCropValues.x) * oldSpaceToPDFRatio, (newCropValues.y - oldCropValues.y) * oldSpaceToPDFRatio, 0),
					);
				}

				this._spaceResolution.y = this._spaceResolution.x / (this._spaceSize.width / this._spaceSize.height);

				this._tileManager.populateData(spaceFile.sourceFileURL);
				this._toolManager.cameraControls.tilesNeedUpdate = true;
			}
		} else {
			if (this._lastDispatchedLoadedRatio !== 1) {
				if (this._mode === SpaceEditorMode.NORMAL) {
					const centerX = this._spaceOffset.x + this._spaceSize.x / 2;
					const centerY = this._spaceOffset.y + this._spaceSize.y / 2;

					(this._tools.setPin.tool as SetPinTool).setPinPosition(centerX, centerY);
				}

				this.signals.loadProgress.dispatch({itemsToLoad: arrayOfStuffToBeLoaded, numberOfLoadedItems: arrayOfStuffToBeLoaded.length});

				requestAnimationFrame(() => {
					this._toolManager.cameraControls.loadStateFromLocalStorage();
				});
			}
		}
	}

	public updateTheme() {
		this.invertClearColor();
		this._tileManager.updateTheme(this.themeType);
		this.needsRender = true;
		this.signals.themeChange.dispatch(this.themeType);
	}

	public onResize = () => {
		this._needsResize = true;
	};

	private resizeCanvas() {
		const canvas = this.canvas;

		const size = HTMLUtils.getSize(canvas);

		const w = size.width;
		const h = size.height;

		const isInPhotoSphereSplittedViewMode = this._app.graphicalTools.photoSphereSceneManager.viewMode === "split";

		canvas.width = w;
		canvas.height = h;

		this._renderer.setSize(w, h, true);

		canvas.style.width = isInPhotoSphereSplittedViewMode ? "50%" : "100%";
		this._domElement.style.width = canvas.style.width;
		canvas.style.height = "100%";

		this._toolManager.resize(w, h);
		this._spaceItemController.markupManager.onCanvasResize();

		this.signals.onCanvasResized.dispatch(w, h);
	}

	public update() {
		this._timeStamp = performance.now();
		this.needsRender = Convergence.updateActiveOnes(this._timeStamp, this) || this.needsRender;
		this.tweenGroup.update();

		this.signals.onBeforeRender.dispatch();

		// For safety. this.stopAnimationLoop doesn't cancel the existing requestAnimationFrame
		if (this._isMounted) {
			this._toolManager.update();
		}

		if (this.needsRender) {
			this._renderer.clear();
			this._renderer.render(this._tileScene, this._activeCamera);
			this._renderer.render(this._markupScene, this._activeCamera);
			this._renderer.render(this._xyiconScene, this._activeCamera);
			this._renderer.render(this._topLayerScene, this._activeCamera);
			this._renderer.info.reset();
			this.signals.onAfterRender.dispatch(this._renderSignalParam);
			this.needsRender = false;
		}
	}

	private onFrame = () => {
		if (this._loopId) {
			this._loopId = requestAnimationFrame(this.onFrame);
		}

		if (this._needsResize) {
			this.resizeCanvas();
			this.needsRender = true;
			this._needsResize = false;
		}

		this.update();
	};

	public async mount() {
		if (!this._isMounted && this._canvas.parentElement) {
			// Initially canvas doesn't have a parentelement, so we need to call the rest (below) individually
			this._canvas.parentElement.appendChild(this._domElement);
			this.resizeCanvas();
			this.needsRender = true;
			this.onFrame();
			console.log("SpaceViewRenderer Did Mount");
			this._resizeDetector = new ResizeDetector(this.canvas.parentElement);
			this._resizeDetector.resize.add(this.onResize, this);

			await this.initialize();
			this._isMounted = true;
		}
	}

	public addInheritedMethods(inheritedMethods: IinheritedMethods) {
		this._inheritedMethods = inheritedMethods;
	}

	private createWebGLRenderer(): WebGLRenderer {
		const renderer = new WebGLRenderer({
			antialias: true,
			canvas: this._canvas,
		});

		renderer.info.autoReset = false;
		// TODO: Review if we can gain any performance improvements with this + how to apply this only in production...?
		// this._renderer.debug.checkShaderErrors = false;
		renderer.sortObjects = false;
		renderer.autoClear = false;
		renderer.autoClearColor = false;
		renderer.autoClearDepth = false;
		renderer.setPixelRatio(devicePixelRatio);
		renderer.setClearColor(0xf0f0f0);

		return renderer;
	}

	private clearData() {
		this.pdfRenderer.clearQueue();
		this._spaceItemController.clearData();
		this._tileManager.clearData();
		this._textureManager.deleteCachedTextures();
		THREEUtils.disposeAndClearContainer(this._tileScene);
		THREEUtils.disposeAndClearContainer(this._markupScene);
		THREEUtils.disposeAndClearContainer(this._xyiconScene);
		this._renderer.resetState();
		this._renderer.dispose();
		this._renderer = this.createWebGLRenderer();
	}

	public unmount() {
		if (this._isMounted) {
			this.revokeURLs(this._space);
			this._space = null;
			this._toolManager.unmount();
			this.stopAnimationLoop();

			this.clearData();
			this._resizeDetector.resize.remove(this.onResize, this);
			//this._resizeDetector.dispose(); ?

			// Watch out: https://github.com/mrdoob/three.js/pull/17588
			// The PR has been reverted with r106, so we can use renderer.dispose again
			this._renderer.dispose();
			this._domElement.parentElement.removeChild(this._domElement);
			this._canvas.parentElement.removeChild(this._canvas);
			this._isMounted = false;
			console.log("SpaceViewRenderer Did Unmount");
		}
	}

	public onEyeDropperStateChanged = (activated: boolean) => {
		if (activated) {
			this.needsRender = true;
			this._toolManager.activeTool.deactivate();
		} else {
			this._toolManager.activeTool.activate();
		}
	};

	public changeCameraType(newCameraType: "perspective" | "orthographic") {
		const oldCamera = this._activeCamera;

		this._toolManager.cameraControls.changeCameraType(newCameraType);
		this._activeCamera = this._toolManager.cameraControls.activeCamera;

		const hasChanged = oldCamera !== this._activeCamera;

		if (hasChanged) {
			THREEUtils.updateMatrices(this._activeCamera);
			this._activeCamera.updateProjectionMatrix();
			this._toolManager.cameraControls.signals.cameraPropsChange.dispatch();
		}
	}

	public get inheritedMethods() {
		return this._inheritedMethods;
	}

	public initTexture(texture: Texture | CanvasTexture) {
		this._renderer.initTexture(texture);
	}

	public setMode(mode: SpaceEditorMode) {
		if (this._mode !== mode) {
			this._mode = mode;
		}
	}

	public setInsertionInfo(insertionInfo: SpaceFileInsertionInfo) {
		const originalPDFRatio = this._spaceSize.width / this._spaceSize.height;

		this._spaceOffset.set(insertionInfo.offsetX, insertionInfo.offsetY, insertionInfo.offsetZ);

		const height = insertionInfo.width / originalPDFRatio;

		this._spaceSize.set(insertionInfo.width, height);

		this.pdfRenderer.saveSpaceSize(this._spaceSize.width);

		this.reloadTiles(false);
	}

	public async reloadTiles(reloadPDF: boolean = true) {
		this._tileManager.deleteCachedTiles();
		const spaceFile = this.getProperSpaceFile();
		const sourceFileURL = spaceFile.sourceFileURL;

		if (reloadPDF) {
			const insertionInfo = spaceFile.insertionInfo;

			this._spaceOffset.set(insertionInfo.offsetX, insertionInfo.offsetY, insertionInfo.offsetZ);
			this._spaceSize.set(
				insertionInfo.width,
				0, // calculated later from the width and the pdf's aspect ratio
			);

			await this._textureManager.initPDF(sourceFileURL, this._spaceSize.width);
			this._spaceSize.height = this.pdfRenderer.spaceSize.height;
		}

		this._tileManager.clearAndInit();
		this.calculateSpaceResolution();

		const numberOfZoomLevels = this._tileManager.populateData(sourceFileURL);
		const canvasSize = HTMLUtils.getSize(this._canvas);

		this._toolManager.cameraControls.setSize(canvasSize.width, canvasSize.height, this._spaceOffset, numberOfZoomLevels);
	}

	public async refreshSpaceItems() {
		this.xyiconManager.initXyiconSize(Constants.SIZE.XYICON * this._correctionMultiplier);
		await this.addSpaceItems();
	}

	private async addSpaceItems() {
		const spaceId = this._space.id;
		const actions = this.actions;

		const xyiconsInSpace = actions.getXyiconsBySpace(spaceId);
		const boundariesInSpace = actions.getBoundariesBySpace(spaceId);
		const markupsInSpace = actions.getMarkupsBySpace(spaceId);

		await this._spaceItemController.init(xyiconsInSpace, boundariesInSpace, markupsInSpace);
	}

	public async refreshSpace(refreshItems: boolean = true) {
		if (refreshItems) {
			this.clearData();
		}
		await this.reloadTiles(false);
		if (refreshItems) {
			await this.refreshSpaceItems();
		}
		this._inheritedMethods.setActiveTool(this._mode === SpaceEditorMode.NORMAL ? "selection" : "pan");
	}

	public confirmAlignment() {
		const worldPosition = new Vector3();

		this._tileManager.firstChildOfPivotContainer.getWorldPosition(worldPosition);

		this._inheritedMethods.confirmAlignment({
			offsetX: worldPosition.x,
			offsetY: worldPosition.y,
			offsetZ: worldPosition.z,
			width: this._tileManager.pivotContainer.scale.x * this._spaceSize.width,
			height: this._tileManager.pivotContainer.scale.y * this._spaceSize.height,
		});
	}

	public copySelectedItemsToClipboard = () => {
		this.copyOrCutSelectedItemsToClipboard("COPY");
	};

	public copySelectedItemsToStamp = () => {
		this.copyOrCutSelectedItemsToClipboard("STAMP");
	};

	public cutSelectedItemsToClipboard = () => {
		this.copyOrCutSelectedItemsToClipboard("CUT");
	};

	private copyOrCutSelectedItemsToClipboard(action: ClipboardActionType) {
		const spaceItemsWithoutPermissionCount = this.actions.getNumberOfSpaceItemsWithoutPermission(
			this._spaceItemController.selectedItems,
			Permission.Update,
		);

		if (spaceItemsWithoutPermissionCount === 0) {
			const {xyiconManager, boundaryManager, markupManager} = this;
			const xyicons = xyiconManager.selectedItems.map((spaceItem) => spaceItem.modelData as Xyicon);
			const boundaries = boundaryManager.selectedItems.map((spaceItem) => spaceItem.modelData as BoundarySpaceMap);
			const markups = markupManager.selectedItems.map((spaceItem) => spaceItem.modelData as Markup);

			ClipboardManager.copyItems(xyicons, boundaries, markups, this.actions, action);

			this._ghostModeManager.start(xyicons, boundaries, markups);
		} else {
			notify(this._app.notificationContainer, {
				type: NotificationType.Warning,
				title: "Action not allowed!",
				description: `${spaceItemsWithoutPermissionCount} of ${this._spaceItemController.selectedItems.length} xyicons or boundaries you have selected do not have the required permissions to perform this action. To obtain permission, contact your organization's administrator.`,
				lifeTime: 10000,
			});
		}
	}

	public get timeStamp() {
		return this._timeStamp;
	}

	public get mode() {
		return this._mode;
	}

	public get themeType() {
		return this.transport.appState.theme;
	}

	public get tileSetsFinished() {
		return this._tileSetsFinished;
	}

	public get planeGeometry() {
		return this._planeGeometry;
	}

	public get tileScene() {
		return this._tileScene;
	}

	public get markupScene() {
		return this._markupScene;
	}

	public get xyiconScene() {
		return this._xyiconScene;
	}

	public get topLayerScene() {
		return this._topLayerScene;
	}

	public get tileManager() {
		return this._tileManager;
	}

	public get spaceItemController() {
		return this._spaceItemController;
	}

	public get xyiconManager() {
		return this._spaceItemController.xyiconManager;
	}

	public get boundaryManager() {
		return this._spaceItemController.boundaryManager;
	}

	public get markupManager() {
		return this._spaceItemController.markupManager;
	}

	public get eyeDropperProps(): IPropsForEyeDropper | undefined {
		if (this.isMounted) {
			return {
				canvas: this.canvas,
				elementForPointerEvents: this.canvas.parentElement,
				textureNeedsUpdateSignal: this.signals.onAfterRender,
				onActivateStateChanged: this.onEyeDropperStateChanged,
			};
		}

		return undefined;
	}

	public getItemManager(feature: XyiconFeature): ItemManager {
		switch (feature) {
			case XyiconFeature.Markup:
				return this.markupManager;
			case XyiconFeature.Boundary:
				return this.boundaryManager;
			case XyiconFeature.Xyicon:
				return this.xyiconManager;
		}

		return null;
	}

	public get textureManager() {
		return this._textureManager;
	}

	public get toolManager() {
		return this._toolManager;
	}

	@computed
	public get space() {
		return this._space;
	}

	public get spaceSize() {
		return this._spaceSize;
	}

	public get spaceOffset() {
		return this._spaceOffset;
	}

	public get spaceResolution() {
		return this._spaceResolution;
	}

	public get tileResolution() {
		// iOS is more likely to crash when tile resolution is high,
		// but if the tiles are already pregenerated, then we must use the default resolution
		return Constants.iOS && this._space.selectedSpaceFile.mode === SpaceFileTileMode.OnDemand ? 512 : Constants.RESOLUTION.TILE;
	}

	private get minCorrectionMultiplier() {
		return 0.00000001;
	}

	// If you have a size value in PX (fully zoomed in), multiply it by this to get the valid size in model space
	public get correctionMultiplier() {
		// 2 versions for align mode, when we load 2 pdfs
		return {
			original: Math.max(this._correctionMultiplier, this.minCorrectionMultiplier),
			current: Math.max(this._spaceSize.width / this._spaceResolution.x, this.minCorrectionMultiplier),
		};
	}

	public get ghostModeManager() {
		return this._ghostModeManager;
	}

	public get selectionBox() {
		return this._selectionBox;
	}

	public get canvas() {
		return this._canvas;
	}

	public get domElement() {
		return this._domElement;
	}

	public get tools(): {[key in SpaceTool]: IToolConfig} {
		return this._tools;
	}

	public get currentZoomValue() {
		return this._toolManager.cameraControls.cameraZoomValue;
	}

	public get activeCamera() {
		return this._toolManager.cameraControls.activeCamera;
	}

	@computed
	public get activeCameraType() {
		return this.activeCamera.type;
	}

	public get resizeDetector() {
		return this._resizeDetector;
	}

	public get renderer() {
		return this._renderer;
	}

	public get transport() {
		return this._app.transport;
	}

	public get pdfRenderer() {
		return this._app.graphicalTools.pdfRenderer;
	}

	public get actions() {
		return this._app.transport.appState.actions;
	}

	public get isMounted() {
		return this._isMounted && this._isReadyForSignalREvents;
	}

	private get _isReadyForSignalREvents() {
		return !!this._loopId;
	}

	private get measureToolDefaultColor(): ColorDto {
		return {
			hex: "893DD0",
			transparency: 0,
		};
	}
}
