import styled from "styled-components";
import {useEffect, useReducer} from "react";
import {ObjectUtils} from "../../../../../utils/data/ObjectUtils";
import {ReactUtils} from "../../../../utils/ReactUtils";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {colorPalette} from "../../../styles/colorPalette";
import {baseDistance, FLEXCENTER, fontSize, fontWeight, radius} from "../../../styles/styles";
import SlidersIcon from "../../../icons/sliders-2.svg?react";
import MapIcon from "../../../icons/map.svg?react";
import MinusIcon from "../../../icons/minus.svg?react";
import PlusIcon from "../../../icons/plus.svg?react";
import FullScreenIcon from "../../../icons/fullscreen.svg?react";
import ExitFullScreenIcon from "../../../icons/exit-fullscreen.svg?react";
import SetScaleIcon from "../../../icons/ruler-triangle.svg?react";
import RealignBackgroundIcon from "../../../icons/crop.svg?react";
import ScrollBarIcon from "../../../icons/scrollbar.svg?react";
import MagnetIcon from "../../../icons/magnet.svg?react";
import AngleIcon from "../../../icons/angle.svg?react";
import RulerIcon from "../../../icons/ruler.svg?react";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import {VerticalLine} from "../../../widgets/VerticalLine";
import {DropdownButtonV5} from "../../../interaction/DropdownButtonV5";
import {FullscreenManager} from "../../../../../utils/resize/FullscreenManager";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import {Functions} from "../../../../../utils/function/Functions";
import type {SpaceTool} from "../../../../modules/space/spaceeditor/logic3d/features/tools/Tools";
import {NavigationBoxV5} from "./NavigationBoxV5";

const keyForLocalStorage = "srv4-navigationbox-open";

interface ISpaceControlBarProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly navigationBoxImgSrc: string;
	readonly onRealignSpaceClick: () => void;
	readonly onCloseCreatePanel: () => void;
	readonly setActiveTool: (id: SpaceTool) => void;
}

export const SpaceControlBarV5 = (props: ISpaceControlBarProps) => {
	const {spaceViewRenderer, navigationBoxImgSrc, onRealignSpaceClick, onCloseCreatePanel, setActiveTool} = props;
	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const isNavigationBoxOpen = getNavigationStateFromLocalStorage(spaceViewRenderer);

	const onMeasureToolIconClick = () => {
		spaceViewRenderer.isMeasureToolBarOpen = !spaceViewRenderer.isMeasureToolBarOpen;
		onCloseCreatePanel();
		setActiveTool("tempMeasureLinearDistance");
	};

	const saveNavigationStateToLocalStorage = (value: boolean) => {
		spaceViewRenderer.transport.services.localStorage.set(keyForLocalStorage, value);
	};

	const onNavigationIconClick = () => {
		const newValue = !isNavigationBoxOpen;

		saveNavigationStateToLocalStorage(newValue);
		forceUpdate();
	};

	const cameraControls = spaceViewRenderer.toolManager.cameraControls;

	const onChangeToOrthographic = () => {
		cameraControls.changeToOrthographic();
	};

	const onChangeToPerspective = () => {
		cameraControls.changeToPerspective();
	};

	const onSetScaleClick = () => {
		spaceViewRenderer.inheritedMethods.setActiveTool("setScale");
	};

	const onSnapToGridClick = () => {
		const {snapToGridManager} = spaceViewRenderer.spaceItemController;

		if (snapToGridManager.isActive) {
			snapToGridManager.deactivate();
		} else {
			snapToGridManager.activate();
		}

		forceUpdate();
	};

	const onSnapToAngleClick = () => {
		const {boundaryManager} = spaceViewRenderer;

		boundaryManager.setSnapToAngle(!boundaryManager.isSnapToAngleActive);
		forceUpdate();
	};

	const onScrollbarClick = () => {
		const {scrollbars} = spaceViewRenderer.toolManager.cameraControls;

		if (scrollbars.enabled) {
			scrollbars.disable();
		} else {
			scrollbars.enable();
		}

		forceUpdate();
	};

	useEffect(() => {
		spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.add(forceUpdate);
		spaceViewRenderer.resizeDetector.resize.add(forceUpdate);

		return () => {
			spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.remove(forceUpdate);
			spaceViewRenderer.resizeDetector.resize.remove(forceUpdate);
		};
	}, [spaceViewRenderer, forceUpdate]);

	const {isMeasureToolBarOpen} = spaceViewRenderer;
	const isInFullScreen = FullscreenManager.isFullscreen();

	return (
		<SpaceControlBarStyled className="SpaceControlBar hbox">
			<IconButtonV5
				IconComponent={MapIcon}
				title="Toggle Map"
				isActive={isNavigationBoxOpen}
				onClick={onNavigationIconClick}
			/>
			<VerticalLine />
			<CameraZoomControlsWrapper>
				<IconButtonV5
					title="Zoom-out"
					onClick={() => cameraControls.zoomToDirection(-1)}
					IconComponent={MinusIcon}
				/>
				<DropdownButtonV5
					verticalAlignment={VerticalAlignment.topOuter}
					offsetY={16}
					button={
						<ZoomPercentage
							className="zoomPercentage"
							title="Zoom Options"
						>
							{`${(cameraControls.cameraZoomValue * 100).toFixed(0)}%`}
						</ZoomPercentage>
					}
					options={[
						{
							IconComponent: isInFullScreen ? ExitFullScreenIcon : FullScreenIcon,
							label: isInFullScreen ? "Exit Full Screen" : "Full Screen",
							onClick: () => {
								FullscreenManager.toggle();
								forceUpdate();
							},
						},
						{
							label: "Zoom To Fit",
							onClick: () => cameraControls.fitToScreen(),
						},
					]}
				/>
				<IconButtonV5
					title="Zoom-in"
					onClick={() => cameraControls.zoomToDirection(1)}
					IconComponent={PlusIcon}
				/>
			</CameraZoomControlsWrapper>
			<VerticalLine />
			<CameraModeChangerWrapper>
				<div
					className={ReactUtils.cls("cameraChanger perspective flexCenter", {
						selected: spaceViewRenderer.activeCameraType === "OrthographicCamera",
					})}
					onClick={onChangeToOrthographic}
				>
					2D
				</div>
				<div
					className={ReactUtils.cls("cameraChanger orthographic flexCenter", {
						selected: spaceViewRenderer.activeCameraType === "PerspectiveCamera",
					})}
					onClick={onChangeToPerspective}
				>
					3D
				</div>
			</CameraModeChangerWrapper>
			<IconButtonV5
				titleVerticalAlignment={VerticalAlignment.top}
				title="Measurement Tool"
				IconComponent={RulerIcon}
				onClick={onMeasureToolIconClick}
				isActive={isMeasureToolBarOpen}
			/>
			<DropdownButtonV5
				verticalAlignment={VerticalAlignment.topOuter}
				horizontalAlignment={HorizontalAlignment.right}
				offsetX={-8}
				offsetY={16}
				button={
					<IconButtonV5
						IconComponent={SlidersIcon}
						title="More Tools"
						onClick={Functions.emptyFunction}
					/>
				}
				options={[
					{
						IconComponent: SetScaleIcon,
						label: "Set Scale",
						onClick: onSetScaleClick,
					},
					{
						IconComponent: RealignBackgroundIcon,
						label: "Realign Background",
						onClick: onRealignSpaceClick,
					},
					{
						IconComponent: ScrollBarIcon,
						label: "Enable Scrollbar",
						isActive: spaceViewRenderer.toolManager.cameraControls.scrollbars.enabled,
						onClick: onScrollbarClick,
					},
					{
						IconComponent: MagnetIcon,
						label: "Snap to Grid",
						isActive: spaceViewRenderer.spaceItemController.snapToGridManager.isActive,
						onClick: onSnapToGridClick,
					},
					{
						IconComponent: AngleIcon,
						label: "Snap to Angle",
						isActive: spaceViewRenderer.boundaryManager.isSnapToAngleActive,
						onClick: onSnapToAngleClick,
					},
				]}
			/>
			{isNavigationBoxOpen && (
				<NavigationBoxV5
					spaceViewRenderer={spaceViewRenderer}
					navigationBoxImgSrc={navigationBoxImgSrc}
				/>
			)}
		</SpaceControlBarStyled>
	);
};

const getNavigationStateFromLocalStorage = (spaceViewRenderer: SpaceViewRenderer) => {
	const originalValue = spaceViewRenderer.transport.services.localStorage.get(keyForLocalStorage);

	if (ObjectUtils.isNullish(originalValue)) {
		return true;
	} else {
		return originalValue;
	}
};

const ZoomPercentage = styled.div`
	font-weight: ${fontWeight.bold};
	width: 56px;
	height: 32px;
	${FLEXCENTER};
	cursor: pointer;
	border-radius: ${radius.sm};

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
`;

const CameraZoomControlsWrapper = styled.div`
	${FLEXCENTER};
	gap: ${baseDistance.sm};
`;

const CameraModeChangerWrapper = styled.div`
	${FLEXCENTER};
	border-radius: ${radius.md};
	border: 1px solid ${colorPalette.gray.c950};
	width: 64px;
	height: 32px;
	overflow: hidden;
	font-weight: ${fontWeight.bold};
`;

const SpaceControlBarStyled = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	border-radius: ${radius.md};
	height: 48px;
	width: 396px;
	bottom: 16px;
	right: 16px;
	gap: ${baseDistance.sm};
	padding: 0 ${baseDistance.sm};
	background: ${colorPalette.white};
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
	z-index: 1;

	.cameraChanger {
		${FLEXCENTER};
		font-size: ${fontSize.md};
		line-height: 16.41px;
		cursor: pointer;
		width: 100%;
		height: 100%;

		&:hover {
			background-color: ${colorPalette.gray.c200Light};
		}

		&.selected {
			background-color: ${colorPalette.primary.c200Light};
			color: ${colorPalette.primary.c500Primary};
		}
	}

	.IconButton {
		width: 32px;
		height: 32px;
		border-radius: ${radius.sm};

		&:hover {
			background-color: ${colorPalette.gray.c200Light};
		}

		&.isActive {
			background-color: ${colorPalette.primary.c200Light};
			color: ${colorPalette.primary.c500Primary};
		}
	}

	.NavigationBox {
		position: absolute;
		bottom: calc(100% + 8px);
		left: 0;
		width: 280px;
		height: 225px;
		background-color: ${colorPalette.white};
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
		padding-left: 10px;
		padding-right: 10px;
		border-radius: ${radius.md};
	}

	.navigationBackground {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.NavigationControlButtons {
		margin-bottom: ${baseDistance.sm};
		margin-top: 5px;
		margin-left: 10px;
		height: 20px;

		.zoomPercentage {
			cursor: pointer;

			&:hover {
				color: ${colorPalette.primary.c500Primary};
			}
		}
	}
`;
