import styled from "styled-components";
import {baseDistance, fontSize, radius} from "../../../../styles/styles";
import {colorPalette} from "../../../../styles/colorPalette";

export const ReportWizardStyled = styled.div`
	.reportPage {
		margin-top: 20px;
		align-items: center;
		display: flex;
		flex-direction: column;
		min-height: 350px;
		max-height: 450px;

		.ReportDetails {
			width: 500px;
			margin-left: auto;
			margin-right: auto;

			.Field {
				padding: 5px;

				&.currenPortfolio .element {
					font-size: ${fontSize.md};
					margin-left: ${baseDistance.sm};
					color: ${colorPalette.gray.c700Dark};
				}

				.element {
					width: 340px;
				}

				textarea,
				input {
					border: solid 1px ${colorPalette.gray.c300};
					border-radius: ${radius.sm};
					width: 100%;
					background-color: ${colorPalette.white};
					color: ${colorPalette.gray.c700Dark};
				}

				textarea {
					min-height: 32px;
					padding: 6px ${baseDistance.sm};
				}
			}
		}

		.btn-container {
			padding: ${baseDistance.sm};
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.clear-all {
				font-size: ${fontSize.md};
				color: ${colorPalette.gray.c400};
				cursor: pointer;
				padding-top: ${baseDistance.sm};
			}
		}
	}
`;

export const ButtonContainerStyled = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 10px;
	padding-bottom: ${baseDistance.md};
	padding-right: ${baseDistance.md};
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;

	.saveDropdown {
		width: auto;
		background-color: ${colorPalette.primary.c500Primary};
		color: ${colorPalette.white};
		font-size: ${fontSize.md};

		&:hover {
			background-color: ${colorPalette.primary.c700Dark};
		}
	}
`;
