import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {AppState} from "../../../../../data/state/AppState";
import type {PortfolioGroupPermission} from "../../../../../generated/api/base";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {PortfolioGroup} from "../../../../../data/models/PortfolioGroup";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import {SelectInputV5} from "../../../input/select/SelectInputV5";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import DeleteIcon from "../../../icons/delete.svg?react";
import {colorPalette} from "../../../styles/colorPalette";

interface IPortfolioGroupPermissionViewProps {
	readonly permission: PortfolioGroupPermission;
	readonly exceptions: string[];
	readonly onRemove: (permission: PortfolioGroupPermission) => void;
	readonly onChange?: () => void;
	readonly appState?: AppState;
}

@inject("appState")
@observer
export class PortfolioGroupPermissionViewV5 extends React.PureComponent<IPortfolioGroupPermissionViewProps> {
	private onRemove = () => {
		this.props.onRemove(this.props.permission);
	};

	private onChangePortfolioGroup = (portfolioGroup: PortfolioGroup) => {
		this.props.permission.portfolioGroupID = portfolioGroup.id;
		this.props.onChange?.();
	};

	private onChangePermissionSet = (permissionSet: PermissionSet) => {
		this.props.permission.portfolioPermissionSetID = permissionSet.id;
		this.props.onChange?.();
	};

	public override render() {
		const {permission, exceptions, appState} = this.props;

		const permissionSets = appState.actions.getList<PermissionSet>(XyiconFeature.PermissionSet);
		const portfolioGroups = appState.actions.getList<PortfolioGroup>(XyiconFeature.PortfolioGroup);

		// Only list portfolios that haven't been added yet
		const options = portfolioGroups.filter((pg) => pg.id === permission.portfolioPermissionSetID || !exceptions.includes(pg.id));

		return (
			<PortfolioGroupPermissionViewStyled>
				<div className="PortfolioGroupPermissionView AdvancedFilterField hbox">
					<SelectInputV5
						options={options}
						className="selectinput"
						selected={portfolioGroups.find((pg) => pg.id === permission.portfolioGroupID)}
						onChange={this.onChangePortfolioGroup}
						render={(p) => p.name}
						placeholder="Portfolio Group"
						isSameWidth={true}
					/>
					<SelectInputV5
						options={permissionSets}
						className="selectinput"
						selected={permissionSets.find((ps) => ps.id === permission.portfolioPermissionSetID)}
						onChange={this.onChangePermissionSet}
						render={(ps) => ps.name}
						placeholder="Permission Set"
						isSameWidth={true}
					/>

					<IconButtonV5
						title="Delete"
						IconComponent={DeleteIcon}
						className="delete"
						onClick={this.onRemove}
					/>
				</div>
			</PortfolioGroupPermissionViewStyled>
		);
	}
}

const PortfolioGroupPermissionViewStyled = styled.div`
	.PortfolioGroupPermissionView {
		display: flex;
		justify-content: space-between;
		margin-bottom: 3px;

		.selectinput {
			width: 100%;
			margin-right: 5px;
		}

		.delete svg path {
			stroke: ${colorPalette.white};
		}
	}

	.PortfolioGroupPermissionView:hover {
		.delete svg path {
			stroke: black;
		}
	}
`;
