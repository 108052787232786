import {ReactSortable} from "react-sortablejs";
import type {Sortable} from "react-sortablejs";
import type {CSSProperties} from "react";
import {useRef, useState} from "react";
import styled from "styled-components";
import {TimeUtils} from "../../../utils/TimeUtils";
import {ObjectUtils} from "../../../utils/data/ObjectUtils";
import {ArrayUtils} from "../../../utils/data/array/ArrayUtils";
import {InfoBubbleV5} from "../button/InfoBubbleV5";
import TrashIcon from "../icons/trash.svg?react";
import {ReactUtils} from "../../utils/ReactUtils";
import {IconButtonV5} from "../interaction/IconButtonV5";
import {colorPalette} from "../styles/colorPalette";
import type {ISingleSelectFieldSettingsDefinition, ListObject} from "../../../data/models/field/datatypes/SingleSelect";
import {ColorSelectorStyled, ColorSelectorV5} from "../colors/ColorSelectorV5";
import {baseDistance, FLEXCENTER, fontSize, fontWeight, radius} from "../styles/styles";
import {HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import CirclePlus from "../icons/circle-plus.svg?react";
import {ButtonV5} from "../button/ButtonV5";
import ErrorInfoIcon from "../icons/errorInfo.svg?react";
import {SelectInputV5} from "../input/select/SelectInputV5";
import {FieldDataType} from "../../../generated/api/base";
import {disableHighlightingOnBody, enableHighlightingOnBody} from "../../../utils/interaction/HighlightDisablingUtils";
import DragIcon from "../icons/drag.svg?react";
import {TextInputV5} from "./datatypes/TextInputV5";
import {PillStyled, renderIconComponent} from "./datatypes/SingleSelectInputV5";

interface IListBuilderInputProps {
	readonly list: ListObject[];
	readonly onChange: (choiceList: ISingleSelectFieldSettingsDefinition) => void;
	readonly displayFormatType?: string;
	readonly dataType?: FieldDataType;
}
interface IListBuilderInputSortableList {
	id: string;
	value: string;
	color: string;
}

const dragTolerance = 4;
const defaultItemString = "New Item";

const createNewItemName = (count: number) => `${defaultItemString} (${count})`;

function filterDragElement(this: Sortable, event: Event | TouchEvent, target: HTMLElement, sortable: Sortable): boolean {
	const element = event.target as HTMLElement;
	const elementName = element.nodeName.toLowerCase();

	return elementName === "input" || elementName === "textarea";
}

export const ListBuilderInputV5 = (props: IListBuilderInputProps) => {
	const {list, dataType, displayFormatType, onChange} = props;
	const [isUsingTextArea, setIsUsingTextArea] = useState<boolean>(false);
	const [textArea, setTextArea] = useState<string>(list && list.join("\n"));
	const [editedItem, setEditedItem] = useState<{index: number; value: string}>({index: -1, value: ""});
	const [isActive, setIsActive] = useState<boolean>(false);

	const _listRef = useRef<HTMLDivElement>();
	const infoBubbleRef = useRef<HTMLDivElement>(null);

	const onAddItemClick = async () => {
		let count: number = 1;
		let newItemName = createNewItemName(count);

		while (list.map((element) => element.value).includes(newItemName)) {
			newItemName = createNewItemName(++count);
		}
		onChange({
			choiceList: [...list, {value: newItemName, color: ""}],
			type: displayFormatType,
		});

		await TimeUtils.waitForNextFrame();
		await TimeUtils.waitForNextFrame();

		// Number.MAX_SAFE_INTEGER is not working reliably in Safari and Firefox
		_listRef.current.scrollTo({top: _listRef.current.scrollHeight, behavior: "smooth"});
		setIsActive(true);
	};

	const isCurrentListValid = (currentList: ListObject[] = list) => {
		let currList: string[] = currentList.map((item) => item.value);

		if (isUsingTextArea) {
			currList = textArea
				.split("\n") // Split the text into lines
				.filter((line) => !!line) // Filter out empty lines
				.map((line) => {
					const match = line.match(/^(.+?)\s*#(?:[0-9A-Fa-f]{6})?/); // Match the part before the `#` and the optional hex color code
					return match ? match[1].trim() : line.trim(); // Return the left part if a match is found, otherwise return the original line
				});
		} else {
			currList = ObjectUtils.deepClone(currList);

			if (editedItem.index !== -1) {
				const {value} = editedItem;

				currList[editedItem.index] = value.trim();
				if (!value) {
					return false;
				}
			}
		}

		const caseInsensitiveList = currList.map((el) => el?.toLowerCase());

		const s = new Set<string>(caseInsensitiveList);
		return s.size === caseInsensitiveList.length;
	};

	const onEditItem = (value: string, index: number) => {
		setEditedItem({value, index});
	};

	const onBlurTextArea = () => {
		// text area values are list's values
		// if they are not the same as the current list, update the list
		const colorsAndValues = textArea
			.split("\n")
			.filter((line) => !!line)
			.map((line) => {
				const match = line.split("#");
				return match.length > 1 ? {value: match[0]?.trim(), color: match[1] ? match[1].trim() : ""} : {value: match[0]?.trim(), color: ""};
			});

		const newListValues = colorsAndValues.map((item) => item.value);

		if (isCurrentListValid() && !ObjectUtils.compare(newListValues, list)) {
			onChange({
				choiceList: colorsAndValues,
				type: displayFormatType,
			});
		} else {
			setTextArea(list.map((item) => `${item.value} ${item.color ? `\t#${item.color}` : ""}`).join("\n"));
		}
	};

	const onItemSave = (itemValue: string, color: string, newValue: string) => {
		if (isCurrentListValid()) {
			const newList = list.map((item) => (item.value === itemValue ? {color: color, value: newValue.trim()} : item));
			if (!ObjectUtils.compare(newList, list) && isCurrentListValid(newList)) {
				onChange({
					choiceList: newList,
					type: displayFormatType,
				});
			}
		}
	};

	const onDeleteClick = (index: number) => {
		if (index < list.length) {
			onChange({
				choiceList: ArrayUtils.remove(list, list[index]),
				type: displayFormatType,
			});
		}
	};

	const onSwitchChange = () => {
		if (!isUsingTextArea) {
			setTextArea(list.map((item) => `${item.value} ${item.color ? `\t#${item.color}` : ""}`).join("\n"));
		}
		setIsUsingTextArea(!isUsingTextArea);
		setEditedItem({index: -1, value: ""});
	};

	const onEditTextArea = (value: string) => {
		setTextArea(value);
	};

	const onSetList = (newList: IListBuilderInputSortableList[]) => {
		// React sortable will add extra fields to the array that we don't need.
		// We need to remove them before comparing the new list to the old list.

		const updatedList = newList.map((item) => {
			const {value, color} = item;
			return {value, color};
		});

		if (!ObjectUtils.compare(updatedList, list)) {
			onChange({
				choiceList: updatedList,
				type: displayFormatType,
			});
		}
	};

	const isValid = isCurrentListValid();

	const generateSortableListFromSourceList = (source: ListObject[]): IListBuilderInputSortableList[] => {
		return source.map((item, index) => ({id: `${item.value}-${index}`, value: item.value, color: item.color}));
	};
	const listToRender = generateSortableListFromSourceList(list);

	const displayFormats = ["default", "textOnly", "pillColored", "pill", "flag", "colorBar", "colorBarFill"];

	const getDisplayFormats = (items: string[], showType: boolean = false) => {
		return items.map((type, index) => (
			<DisplayFormatContainer
				key={type}
				$showType={showType}
				className={ReactUtils.cls({optionIsSelected: type === displayFormatType})}
			>
				{showType && (
					<span style={{width: "100%", height: "100%"}}>
						{type.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/\b\w/g, (char) => char.toUpperCase())}
					</span>
				)}
				<div className="pillContainer">
					<PillStyled
						$color={colorPalette.primary.c500Primary}
						$type={type}
					>
						{renderIconComponent(type)}
						Content
					</PillStyled>
				</div>
			</DisplayFormatContainer>
		));
	};

	const handleOnchage = (option: JSX.Element) => {
		onChange({
			choiceList: list,
			type: option.key,
		});
	};

	const sectionDraggingStart = () => {
		disableHighlightingOnBody();
	};

	const draggingEnd = () => {
		enableHighlightingOnBody();
	};

	const getErrorMessage = (value: string, index: number) => {
		if (editedItem.index !== index) {
			return "";
		}

		if (!value) {
			return "Item name cannot be empty.";
		}
		if (!isValid) {
			return "Items must be unique.";
		}
		return "";
	};

	const optionStyles: CSSProperties = {
		minHeight: "56px",
	};

	return (
		<ListBuilderInputWrapper className="ListBuilderInputWrapper">
			{!isUsingTextArea && (
				<>
					<ChoicesContainer>
						<span className="ChoicesText">Choices</span>
						<ChoicesCount>{list.length}</ChoicesCount>
					</ChoicesContainer>
					<ListBuilderAddItemsSections>
						<div onClick={onAddItemClick}>
							<CirclePlus className="circle-plus" />
						</div>
						<div onClick={onSwitchChange}>
							<span className="MultipleItemText">Add multiple items</span>
						</div>
					</ListBuilderAddItemsSections>
				</>
			)}
			<ListBuilderInputStyled
				className={ReactUtils.cls("ListBuilderInput", {error: !isValid})}
				ref={_listRef}
			>
				{isUsingTextArea ? (
					<TextAreaContainerStyled $isValid={isValid}>
						<TextInputV5
							isTextArea={true}
							value={textArea}
							onInput={onEditTextArea}
							onBlur={onBlurTextArea}
							className="textAreaInput"
							disabled={false}
						/>
						{!isValid && (
							<div
								className="infoIcon"
								ref={infoBubbleRef}
							>
								<ErrorInfoIcon />
								<InfoBubbleV5
									useDomPortal={false}
									style={{top: "-50px", right: "0"}}
									content="Items must be unique."
									isErrorMessage={true}
									className="left"
									divRef={infoBubbleRef}
								/>
							</div>
						)}
						<div style={{marginLeft: "auto"}}>
							<ButtonV5
								label="Done"
								onClick={onSwitchChange}
								className="doneButton"
								disabled={!isValid}
							/>
						</div>
					</TextAreaContainerStyled>
				) : (
					<ReactSortable
						onStart={sectionDraggingStart}
						onEnd={draggingEnd}
						list={listToRender}
						setList={onSetList}
						animation={150}
						filter={filterDragElement}
						fallbackOnBody={true}
						forceFallback={true}
						swapThreshold={0.45}
						fallbackTolerance={dragTolerance}
						touchStartThreshold={dragTolerance}
						preventOnFilter={false}
						delay={500}
						delayOnTouchOnly={true}
					>
						{list.map((item, index) => {
							const {color, value} = item;
							const onItemChange = (newValue: string) => onItemSave(value, color, newValue);
							const textValue = editedItem.index === index ? editedItem.value : value;

							return (
								<ChoiceContainerStyled key={value}>
									<DragIcon className="drag-icon" />
									<div className="item">
										<ColorSelectorV5
											title="Choose Color"
											color={{hex: color ?? "", transparency: 0}}
											onColorChange={(color) => {
												onItemSave(value, color.hex ?? "", value);
											}}
											isTransparencyEnabled={false}
											isSingleSelectColor={true}
											verticalAlignment={VerticalAlignment.bottomOuter}
											horizontalAlignment={HorizontalAlignment.left}
										/>
										<TextInputV5
											value={textValue}
											onInput={(value) => onEditItem(value, index)}
											onChange={onItemChange}
											getErrorMessage={() => getErrorMessage(textValue, index)}
											onBlur={() => {
												if (editedItem.index === index) {
													if (isValid) {
														onItemChange(editedItem.value);
													}
													setEditedItem({index: -1, value: ""});
												}
											}}
											autoFocus={isActive}
										/>
										<IconButtonV5
											IconComponent={TrashIcon}
											title="Delete"
											onClick={() => onDeleteClick(index)}
											className="DeleteButton"
										/>
									</div>
								</ChoiceContainerStyled>
							);
						})}
					</ReactSortable>
				)}
			</ListBuilderInputStyled>
			{dataType === FieldDataType.SingleChoiceList && (
				<>
					<ChoicesContainer>
						<span className="ChoicesText">Display Format</span>
					</ChoicesContainer>
					<SelectInputV5
						options={getDisplayFormats(displayFormats, true)}
						selected={getDisplayFormats([displayFormatType])[0]}
						onChange={handleOnchage}
						className="display-format"
						fullWidth={true}
						isSameWidth={true}
						optionClassName={optionStyles}
						dropdownMaxHeight="224px"
						isSingleSelect={true}
						searchBarMode="always off"
					/>
				</>
			)}
		</ListBuilderInputWrapper>
	);
};

const DisplayFormatContainer = styled.div<{$showType: boolean}>`
	width: 100%;
	display: flex;
	align-items: center;
	padding: ${baseDistance.sm};
	justify-content: ${(prop) => (prop.$showType ? "space-between" : "center")};

	.pillContainer {
		border: ${(props) => (props.$showType ? `1px solid ${colorPalette.gray.c300}` : "none")};
		height: 40px;
		display: flex;
		align-items: center;
		width: 120px;
	}

	${PillStyled} {
		width: 120px;
	}
`;

const TextAreaContainerStyled = styled.div<{$isValid: boolean}>`
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.sm};

	.doneButton {
		padding: ${baseDistance.xs};
		width: 39px;
		height: 24px;
		font-size: ${fontSize.sm};
	}

	.textAreaInput {
		position: relative;
		border-color: ${(props) => (props.$isValid ? colorPalette.gray.c300 : colorPalette.negative.c500Primary)};
		border-radius: ${radius.sm};
		z-index: 1;

		&:focus {
			background-color: ${colorPalette.white};
		}
	}
`;

const ChoicesContainer = styled.div`
	display: flex;
	align-items: center;
	gap: ${baseDistance.sm};
	position: relative;

	.ChoicesText {
		font-weight: ${fontWeight.bold};
	}
`;

const ChoicesCount = styled.div`
	${FLEXCENTER};
	width: 16px;
	height: 16px;
	border-radius: ${radius.xs};
	padding: ${baseDistance.xs} ${baseDistance.sm};
	background-color: ${colorPalette.primary.c500Primary};
	color: ${colorPalette.white};
	font-weight: ${fontWeight.normal};
`;

const ListBuilderAddItemsSections = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	.MultipleItemText {
		text-decoration: underline;
		color: ${colorPalette.gray.c700Dark};

		&:hover {
			color: ${colorPalette.primary.c500Primary};
		}
	}

	svg {
		&:hover {
			cursor: pointer;
		}

		path {
			stroke-width: 1;
			color: ${colorPalette.gray.c950};
			border: 1px solid ${colorPalette.gray.c950};
		}
	}
`;

const ListBuilderInputWrapper = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.sm};

	.infoIcon {
		position: absolute;
		top: 3px;
		right: 10px;
		z-index: 2;
		color: ${colorPalette.negative.c500Primary};
	}

	.display-format {
		height: 48px;
		width: 100%;
	}
`;

const ListBuilderInputStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.sm};
	width: 100%;
	min-width: 240px;
	overflow-y: visible;
	border-bottom: none;
	padding-bottom: 0;
	padding-right: 0;

	&.error {
		border-color: ${colorPalette.warning.c700Dark};

		& + .add-item {
			border-color: ${colorPalette.warning.c700Dark};
		}
	}

	textarea {
		min-height: 150px;
		line-height: 16px;
	}
`;

const ChoiceContainerStyled = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	position: relative;
	padding: 0;

	&:hover {
		cursor: grab;

		.item {
			background-color: ${colorPalette.gray.c200Light};
		}

		svg.drag-icon {
			display: flex;
		}
	}

	.item {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 32px;
		background-color: ${colorPalette.white};
		border-radius: ${radius.sm};
		margin-left: ${baseDistance.md};

		${ColorSelectorStyled} {
			margin: ${baseDistance.xs} ${baseDistance.sm};
			width: 24px;
			height: 24px;
			border: 1px solid transparent;

			&:hover {
				border: 1px solid ${colorPalette.gray.c950};
			}
		}

		${ColorSelectorStyled} {
			margin: 4px 8px;
			width: 24px;
			height: 24px;
			border: 1px solid transparent;

			&:hover {
				border: 1px solid ${colorPalette.gray.c950};
			}
		}

		input {
			background: none;
			transition: none;
			padding: 0 0 0 2px;
			border: 0;
			flex-grow: 1;
			text-overflow: ellipsis;
			height: 24px;
			width: 270px;
		}

		.DeleteButton {
			display: none;
			margin-right: 8px;
			height: 24px;
			min-width: 24px;
			border: 1px solid none;
			padding: 3px;

			&:hover {
				border: 1px solid ${colorPalette.gray.c950};
			}

			svg {
				color: ${colorPalette.gray.c950};
				height: 16px;
				width: 16px;
				stroke-width: 1;
			}
		}

		&:hover {
			background-color: ${colorPalette.gray.c200Light};
			border-radius: ${radius.sm};

			.delete {
				display: block;
			}

			.DeleteButton {
				display: flex;
			}
		}

		input:focus {
			background-color: ${colorPalette.white};
		}

		input:hover {
			border: 1px solid #c8c8c8;
		}

		&:focus-within {
			background-color: ${colorPalette.primary.c200Light};

			.DeleteButton {
				display: block;
				background-color: transparent;

				&:hover {
					border: 1px solid ${colorPalette.primary.c500Primary};
				}
			}

			${ColorSelectorStyled} {
				background-color: transparent;

				&:hover {
					border: 1px solid ${colorPalette.primary.c500Primary};
				}
			}
		}
	}

	svg.drag-icon {
		position: absolute;
		width: 16px;
		height: 16px;
		fill: ${colorPalette.gray.c950};
		display: none;
		pointer-events: auto;
	}
`;
