import styled from "styled-components";
import {zIndex} from "../styles/styles";

export const ShadowDivStyled = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.4);
	transition: background-color 400ms ease-out;
	z-index: ${zIndex.shadowDiv};
`;
