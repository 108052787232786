import styled from "styled-components";
import {colorPalette} from "../../styles/colorPalette";
import {ToggleContainerStyled} from "../../widgets/ToggleContainerV5/ToggleContainerV5";
import {ButtonStyled} from "../../button/ButtonV5";

export const ItemsGroupStyled = styled.div`
	.line {
		border-top: 2px solid ${colorPalette.gray.c300};
		margin-top: 0px;
		margin-bottom: 0px;
		margin-left: 16px;
		margin-right: 16px;
		transform: translate(0px, -8px);
	}

	.toggleContainer {
		padding-left: 16px;
		padding-right: 16px;
	}

	${ToggleContainerStyled} {
		padding-bottom: 16px;

		.children {
			padding: 0;
		}
	}

	.addButton {
		width: 32px;
		height: 32px;
		position: absolute;
		right: 18px;
		top: 250px;
	}
`;

export const SearchAddAndListItemsStyled = styled.div`
	.buttonContainer {
		justify-content: center;
		display: flex;

		${ButtonStyled} {
			width: fit-content;
		}
	}
`;
