import * as React from "react";
import {inject} from "mobx-react";
import {InfoBubbleV5} from "../button/InfoBubbleV5";
import type {App} from "../../../App";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import {ToggleSwitchV5} from "../widgets/switch/ToggleSwitchV5";
import {FieldDataType} from "../../../generated/api/base";
import {ReactUtils} from "../../utils/ReactUtils";
import {CheckboxInputV5} from "./datatypes/CheckboxInputV5";
import {FieldElementsContainerStyled, FieldIconStyled, FieldStyled} from "./Field.styled";

interface IFieldProps {
	readonly label: string | React.ReactNode;
	readonly reverse?: boolean;
	readonly className?: string;
	readonly disabled?: boolean;
	readonly noWrap?: boolean;
	readonly icons?: IFieldIcon;
	readonly tooltips?: IFieldtooltip;
	readonly app?: App;
	readonly children: React.ReactNode;
}

export interface IFieldIcon {
	preLabelIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {title?: string}>;
	postLabelIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {title?: string}>;
}

interface IFieldtooltip {
	preLabelIconTooltip?: string;
	postLabelIconTooltip?: string;
	labelTooltip?: string;
}

enum tooltipPosition {
	PreIcon = 1,
	PostIcon = 2,
	Label = 3,
}

interface IFieldState {
	tooltipStatus: boolean | tooltipPosition;
	tooltipTransform: TransformObj | null;
	tooltipContent: string;
	isLabelOverflowed: boolean;
}

@inject("app")
export class FieldV5 extends React.Component<IFieldProps, IFieldState> {
	private _preIconRef = React.createRef<HTMLDivElement>();
	private _postIconRef = React.createRef<HTMLDivElement>();
	private _labelRef = React.createRef<HTMLDivElement>();
	private _floating = React.createRef<HTMLDivElement>();
	private _timeoutId: number = null;

	constructor(props: IFieldProps) {
		super(props);
		this.state = {
			tooltipStatus: false,
			tooltipTransform: null,
			tooltipContent: "",
			isLabelOverflowed: false,
		};
	}

	public static readonly defaultProps: IFieldProps = {
		label: "",
		children: <></>,
	};

	public override componentDidUpdate(prevProps: IFieldProps, prevState: IFieldState) {
		const {tooltipStatus, isLabelOverflowed} = this.state;

		if (!prevState.tooltipStatus && tooltipStatus && this._floating.current) {
			let ref: HTMLElement = this._preIconRef.current;

			if (tooltipStatus === tooltipPosition.PostIcon) {
				ref = this._postIconRef.current;
			} else if (tooltipStatus === tooltipPosition.Label) {
				ref = this._labelRef.current;
				if (isLabelOverflowed) {
					ref = this._labelRef.current.parentElement;
				}
			}

			if (ref) {
				this.setState({
					tooltipTransform: DomUtils.getFixedFloatingElementPosition(
						ref,
						this._floating.current,
						VerticalAlignment.topOuter,
						HorizontalAlignment.center,
						10,
						0,
					),
				});
			}
		}
	}

	private openTooltip = (position: tooltipPosition) => {
		const {tooltips} = this.props;
		const {tooltipStatus} = this.state;
		const element = this._labelRef.current.parentElement;

		clearTimeout(this._timeoutId);
		this._timeoutId = window.setTimeout(() => {
			let text = "";

			switch (position) {
				case tooltipPosition.PreIcon:
					text = tooltips?.preLabelIconTooltip;
					break;
				case tooltipPosition.PostIcon:
					text = tooltips?.postLabelIconTooltip;
					break;
				case tooltipPosition.Label:
					text = tooltips?.labelTooltip;
					break;
				default:
					break;
			}

			if (!tooltipStatus && !!text?.length) {
				this.setState({
					tooltipStatus: position,
					tooltipContent: text,
				});
			}
			if (!tooltipStatus && element.scrollWidth > element.clientWidth) {
				this.setState({
					tooltipStatus: position,
					isLabelOverflowed: true,
				});
			}
		}, 300);
	};

	private closeTooltip = () => {
		clearTimeout(this._timeoutId);

		if (this.state.tooltipStatus) {
			this.setState({
				tooltipStatus: false,
			});
		}
	};

	private reverseChildren() {
		if (this.props.reverse !== undefined) {
			return this.props.reverse;
		} else {
			// If child is a CheckboxInput -> reverse by default (checkbox will be on the left)
			const child = this.props.children as React.ReactElement<any>;

			return child && (child.type === CheckboxInputV5 || child.type === ToggleSwitchV5);
		}
	}

	public override render() {
		const {children, label, className, disabled, noWrap, app, icons} = this.props;
		const {tooltipTransform, tooltipStatus, tooltipContent} = this.state;

		let child1: React.ReactNode = (
			<label className="label">
				<span
					ref={this._labelRef}
					onMouseEnter={() => this.openTooltip(tooltipPosition.Label)}
					onMouseLeave={this.closeTooltip}
				>
					{label}
				</span>
				{icons?.postLabelIcon && (
					<div
						className="fieldIconWrapper hbox alignCenter justifyCenter"
						ref={this._postIconRef}
						onMouseEnter={() => this.openTooltip(tooltipPosition.PostIcon)}
						onMouseLeave={this.closeTooltip}
					>
						{icons.postLabelIcon && <icons.postLabelIcon />}
					</div>
				)}
			</label>
		);
		let child2: React.ReactNode = children;
		let inputType = FieldDataType[((child2 as React.ReactNode[])?.[0] as React.ReactElement)?.props?.dataType];

		if (this.reverseChildren()) {
			[child1, child2] = [child2, child1];
		}

		// if (child1 instanceof ToggleSwitchV5 || child2 instanceof ToggleSwitchV5)
		// {
		// 	throw new Error("ToggleSwitch in Field not supported. Use ToggleSwitchField instead!");
		// }

		const floatingElement = this._floating.current;
		const inlineStyle: React.CSSProperties = floatingElement && {
			transform: tooltipTransform?.translate,
			left: "auto",
			textAlign: "left",
			maxWidth: 300,
			lineHeight: "140%",
			color: "#F0F0F0",
			fontSize: "12px",
			zIndex: 9999,
		};

		return (
			<FieldStyled
				className={ReactUtils.cls(`Field ${className || ""}`, {
					disabled: disabled,
					noWrap: noWrap,
				})}
				data-inputtype={inputType}
				data-cy="xyicon-fields"
			>
				{icons?.preLabelIcon && (
					<FieldIconStyled
						className="fieldIconWrapper flexCenter"
						ref={this._preIconRef}
						onMouseEnter={() => this.openTooltip(tooltipPosition.PreIcon)}
						onMouseLeave={this.closeTooltip}
					>
						{icons.preLabelIcon && <icons.preLabelIcon />}
					</FieldIconStyled>
				)}
				{!!tooltipStatus && (
					<InfoBubbleV5
						title={tooltipStatus === tooltipPosition.Label && typeof label === "string" && label}
						content={tooltipContent}
						isErrorMessage={false}
						divRef={this._floating}
						style={inlineStyle}
						hide={false}
						className={ReactUtils.cls({
							left: tooltipTransform?.horizontal === HorizontalAlignment.right,
							right: tooltipTransform?.horizontal === HorizontalAlignment.left,
						})}
					/>
				)}
				<FieldElementsContainerStyled>
					{child1}
					<span className={ReactUtils.cls("element", {disabled})}>{child2}</span>
				</FieldElementsContainerStyled>
			</FieldStyled>
		);
	}
}
