import {type FunctionComponent, type SVGProps, type CSSProperties, type RefObject, useEffect, useState, useRef} from "react";
import styled from "styled-components";
import {ReactUtils} from "../../utils/ReactUtils";
import {colorPalette} from "../styles/colorPalette";
import {baseDistance, FLEXCENTER, fontSize, radius, zIndex} from "../styles/styles";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {useAppStore} from "../../../StateManager";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils, VerticalAlignment, HorizontalAlignment} from "../../../utils/dom/DomUtils";

const getTitleOffsetX = (horizontalAlignment: HorizontalAlignment) => {
	switch (horizontalAlignment) {
		case HorizontalAlignment.left:
		case HorizontalAlignment.outerLeft:
			return -8;
		case HorizontalAlignment.right:
		case HorizontalAlignment.outerRight:
			return 8;
		default:
			return 0;
	}
};

const getTitleOffsetY = (verticalAlignment: VerticalAlignment) => {
	switch (verticalAlignment) {
		case VerticalAlignment.bottom:
		case VerticalAlignment.bottomOuter:
			return 8;
		case VerticalAlignment.top:
			return 36;
		case VerticalAlignment.topOuter:
			return 4;
		default:
			return 0;
	}
};

interface IIconButtonProps {
	readonly IconComponent: FunctionComponent<SVGProps<SVGSVGElement> & {title?: string}>;
	readonly className?: string;
	readonly title?: string;
	readonly titleVerticalAlignment?: VerticalAlignment;
	readonly titleHorizontalAlignment?: HorizontalAlignment;
	readonly label?: string;
	readonly onClick: (e: React.MouseEvent) => void;
	readonly onMouseDown?: (e: React.MouseEvent) => void;
	readonly isActive?: boolean;
	readonly style?: CSSProperties;
	readonly disabled?: boolean;
	readonly divRef?: RefObject<HTMLDivElement>;
}

export const IconButtonV5 = ({
	title = "",
	titleVerticalAlignment = VerticalAlignment.topOuter,
	titleHorizontalAlignment = HorizontalAlignment.center,
	IconComponent,
	onClick,
	onMouseDown,
	isActive,
	style,
	disabled = false,
	label = "",
	className = "",
	divRef,
}: IIconButtonProps) => {
	const appState = useAppStore((state) => state.appState);
	const [transform, setTransform] = useState<TransformObj>(null);
	const _floatingElement = useRef<HTMLDivElement>(null);
	const _parentElementMaybe = useRef<HTMLDivElement>(null);
	const parentElementRef = divRef ?? _parentElementMaybe;
	const prevIsHovered = useRef(null);
	const [isHovered, setIsHovered] = useState<boolean>(false);

	const element = parentElementRef.current;
	const inlineStyle: React.CSSProperties = element && {
		zIndex: zIndex.popup,
		transform: transform?.translate,
		position: "fixed",
		left: "auto",
	};

	useEffect(() => {
		if (!prevIsHovered.current && setIsHovered && element && _floatingElement.current) {
			const transformValue = DomUtils.getFixedFloatingElementPosition(
				element,
				_floatingElement.current,
				titleVerticalAlignment,
				titleHorizontalAlignment,
				getTitleOffsetY(titleVerticalAlignment),
				getTitleOffsetX(titleHorizontalAlignment),
			);

			setTransform(transformValue);
		}

		prevIsHovered.current = isHovered;
	}, [isHovered, element, titleVerticalAlignment, titleHorizontalAlignment]);

	return (
		<IconButtonStyled
			data-title={title}
			onClick={onClick}
			onMouseDown={onMouseDown}
			className={ReactUtils.cls(`IconButton ${className}`, {isActive, disabled})}
			$label={label}
			style={style}
			ref={parentElementRef}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<IconComponent />
			{label && <span>{label}</span>}
			{title && isHovered && (
				<DomPortal destination={appState.app.modalContainer}>
					<IconTooltipStyled
						ref={_floatingElement}
						style={inlineStyle}
					>
						{title}
					</IconTooltipStyled>
				</DomPortal>
			)}
		</IconButtonStyled>
	);
};

export const IconButtonStyled = styled.div<{$label: string}>`
	cursor: pointer;
	${FLEXCENTER};
	min-width: ${(props) => (props.$label ? "initial" : "32px")};
	height: 32px;
	gap: ${baseDistance.sm};
	border-radius: ${radius.sm};
	position: relative;

	&:hover,
	&.isOpen {
		background-color: ${colorPalette.gray.c200Light};
	}

	&.isActive {
		background-color: ${colorPalette.primary.c500Primary};
		color: ${colorPalette.white};
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
`;

const IconTooltipStyled = styled.div`
	background-color: ${colorPalette.gray.c950};
	border-radius: ${radius.sm};
	position: absolute;
	color: ${colorPalette.white};
	display: block;
	font-size: ${fontSize.sm};
	padding: ${baseDistance.xs};
	width: max-content;
	z-index: 999;

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}
`;
