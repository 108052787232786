/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum MarkupType {
	Cloud = 1,
	Arrow = 2,
	BidirectionalArrow = 3,
	Line = 4,
	DashedLine = 5,
	PencilDrawing = 6,
	HighlightDrawing = 7,
	TextBox = 8,
	Rectangle = 9,
	Ellipse = 10,
	Triangle = 11,
	Cross = 12,
	LinearDistance = 13,
	RectangleArea = 14,
	NonlinearDistance = 15,
	IrregularArea = 16,
	Stamp = 17,
	Photo = 18,
	Callout = 19,
	Photo360 = 20,
}
