import * as React from "react";
import styled from "styled-components";
import {DateUtils} from "../../../../utils/DateUtils";
import {ButtonStyled, ButtonV5} from "../../button/ButtonV5";
import {SelectInputV5} from "../select/SelectInputV5";
import {baseDistance, FLEXCENTER, fontWeight, radius} from "../../styles/styles";
import {colorPalette} from "../../styles/colorPalette";

interface ITimePickerProps {
	readonly value: Date;
	readonly onChange: (value: string) => void;
	readonly format: "time" | "datetime";
}

export const TimePickerV5: React.FC<ITimePickerProps> = (props) => {
	const onHourChange = (value: number) => {
		const date = props.value;
		const hourDifference = (value % 12) - (date.getHours() % 12);

		date.setHours(date.getHours() + hourDifference);
		props.onChange(DateUtils.stringify(date));
	};

	const onMinuteChange = (value: number) => {
		const date = props.value;

		date.setMinutes(value);
		props.onChange(DateUtils.stringify(date));
	};

	const on24hChange = (value: string) => {
		const date = new Date(props.value);

		if (value === "AM" && value !== getPeriod()) {
			date.setHours(date.getHours() - 12);
		}
		if (value === "PM" && value !== getPeriod()) {
			date.setHours(date.getHours() + 12);
		}

		props.onChange(DateUtils.stringify(date));
	};

	const getHour = () => {
		const hour = props.value.getHours();

		return hour % 12 || 12;
	};

	const getMinute = () => {
		return props.value.getMinutes();
	};

	const getPeriod = () => {
		const hours = props.value.getHours();

		return hours % 12 === hours ? "AM" : "PM";
	};

	const renderLeadingZero = (digit: number) => {
		if (digit < 10) {
			return `0${digit}`;
		}
		return digit;
	};

	return (
		<TimePickerStyled $format={props.format}>
			{props.format === "datetime" && <div>Time</div>}
			<SelectorStyled>
				<SelectInputV5
					options={Array.from({length: 12}, (_, i) => i + 1)}
					selected={getHour()}
					onChange={onHourChange}
					sort={false}
					searchBarMode="always off"
					render={renderLeadingZero}
				/>
				<DotsStyled>:</DotsStyled>
				<SelectInputV5
					options={Array.from({length: 59}, (_, i) => i + 1)}
					selected={getMinute()}
					onChange={onMinuteChange}
					sort={false}
					searchBarMode="always off"
					nullOption={true}
					nullLabel="00"
					render={renderLeadingZero}
				/>
				<ButtonsStyled>
					<ButtonV5
						className="am"
						onClick={() => on24hChange("AM")}
						label="AM"
						type={getPeriod() === "AM" ? null : "secondary"}
					/>
					<ButtonV5
						className="pm"
						onClick={() => on24hChange("PM")}
						label="PM"
						type={getPeriod() === "PM" ? null : "secondary"}
					/>
				</ButtonsStyled>
			</SelectorStyled>
		</TimePickerStyled>
	);
};

const TimePickerStyled = styled.div<{$format: "time" | "datetime"}>`
	display: flex;
	align-items: center;
	justify-content: ${(props) => (props.$format === "time" ? "center" : "space-between")};
`;

const SelectorStyled = styled.div`
	display: flex;
	gap: ${baseDistance.sm};
`;

const DotsStyled = styled.div`
	${FLEXCENTER};
	font-weight: ${fontWeight.bold};
`;

const ButtonsStyled = styled.div`
	display: flex;

	.am {
		border-radius: ${radius.sm} 0 0 ${radius.sm};
	}

	.pm {
		border-radius: 0 ${radius.sm} ${radius.sm} 0;
	}

	${ButtonStyled}.secondary:hover {
		background-color: ${colorPalette.gray.c200Light};
	}
`;
