import {useEffect, useRef} from "react";
import styled from "styled-components";
import {MathUtils} from "../../../utils/math/MathUtils";
import {baseDistance, fontSize, radius} from "../styles/styles";
import EyeIcon from "../icons/eye-alt.svg?react";
import {colorPalette} from "../styles/colorPalette";

interface ColorInputProps {
	readonly hex: string;
	readonly onHexInputChange: (hexValue: string) => void;
	readonly onOpacityInputChange: (newValue: number) => void;
	readonly isTransparencyEnabled: boolean;
	readonly opacity?: number;
}

export const ColorInputV5 = (props: ColorInputProps) => {
	const _hexInput = useRef<HTMLInputElement>();
	const _opacityInput = useRef<HTMLInputElement>();

	const onHexInputChange = (event: Event) => {
		const inputElement = event.currentTarget as HTMLInputElement;

		const hexValue = inputElement.value.toUpperCase();

		// https://stackoverflow.com/questions/8027423/how-to-check-if-a-string-is-a-valid-hex-color-representation
		const isValidHex = /^#([A-F0-9]{6}|[A-F0-9]{3})$/.test(hexValue);

		if (isValidHex) {
			props.onHexInputChange(hexValue);
		}
	};

	const onOpacityInputChange = (event: Event) => {
		const inputElement = event.currentTarget as HTMLInputElement;
		let newValue = parseFloat(inputElement.value);

		if (!isNaN(newValue)) {
			newValue = MathUtils.clamp(newValue, 0, 100) / 100;

			props.onOpacityInputChange(newValue);
		}
	};

	useEffect(() => {
		_hexInput.current.value = `#${props.hex}`;

		if (_opacityInput.current) {
			_opacityInput.current.value = Math.round(props.opacity * 100).toString();
		}

		const hexInput = _hexInput.current;
		const opacityInput = _opacityInput.current;

		if (hexInput) {
			hexInput.addEventListener("change", onHexInputChange);
		}

		if (opacityInput) {
			opacityInput.addEventListener("change", onOpacityInputChange);
		}

		return () => {
			if (hexInput) {
				hexInput.removeEventListener("change", onHexInputChange);
			}

			if (opacityInput) {
				opacityInput.removeEventListener("change", onOpacityInputChange);
			}
		};
	});

	return (
		<ColorInputStyled className="ColorInput hbox alignCenter">
			<span>
				<span>HEX</span>
				<input
					ref={_hexInput}
					maxLength={7}
					className="hexInput"
					defaultValue={`#${props.hex}`}
				/>
			</span>
			<span className="opacityContainer">
				<EyeIcon />
				<input
					ref={_opacityInput}
					maxLength={3}
					className="opacityInput"
					defaultValue={Math.round(props.opacity * 100).toString()}
					disabled={!props.isTransparencyEnabled}
				/>
				<span>%</span>
			</span>
		</ColorInputStyled>
	);
};

const ColorInputStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex: 1;
	gap: ${baseDistance.sm};

	> span {
		display: flex;
		align-items: center;
		gap: ${baseDistance.sm};
		font-size: ${fontSize.md};
		line-height: 16px;
	}

	input {
		padding: 2px;
		border-radius: ${radius.sm};
		width: 32px;
		color: ${colorPalette.gray.c950};
		border: 1px solid ${colorPalette.gray.c500Primary};
		height: 32px;
		outline: none;

		&:focus {
			border-color: ${colorPalette.primary.c500Primary};
		}
	}

	.hexInput {
		width: 68px;
	}

	.opacityInput {
		width: 45px;
	}

	.opacityContainer {
		position: relative;

		> span {
			position: absolute;
			cursor: default;
			right: 8px;
			top: 9px;
		}
	}
`;
