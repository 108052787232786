export const colorPalette = {
	white: "white",
	primary: {
		c50: "#f1f7fe",
		c100: "#e2eefc",
		c200Light: "#bedcf9",
		c300: "#84bff5",
		c400: "#429eee",
		c450PrimaryLight: "#3495f0",
		c500Primary: "#1e88e5",
		c600: "#0c65bd",
		c700Dark: "#0b5199",
		c800: "#0e457e",
		c900: "#113b69",
		c950: "#0b2546",
	},
	gray: {
		c50: "#fff1f1",
		c100: "#ededed",
		c100Light: "#eaeaea",
		c200Light: "#dfdfdf",
		c300: "#c8c8c8",
		c400: "#adadad",
		c500Primary: "#9E9E9E",
		c600: "#888888",
		c700Dark: "#7B7B7B",
		c800: "#113b69",
		c900: "#545454",
		c950: "#363636",
	},
	blueGray: {
		c50: "#f4f6f7",
		c100: "#e2e8eb",
		c200Light: "#C8D4D9",
		c300: "#a2b6be",
		c400: "#74909c",
		c500Primary: "#607d8b",
		c600: "#4d616d",
		c700Dark: "#42515c",
		c800: "#3c474e",
		c900: "#353d44",
		c950: "#20272c",
	},
	positive: {
		c50: "#f3faf3",
		c100: "#e3f5e3",
		c200Light: "#c8eac9",
		c300: "#9cd99e",
		c400: "#69bf6c",
		c500Primary: "#43a047",
		c600: "#348538",
		c700Dark: "#2b6a2f",
		c800: "#275429",
		c900: "#214624",
		c950: "#0e2510",
	},
	negative: {
		c50: "#fef2f2",
		c100: "#FFE2E1",
		c200Light: "#FFC9C8",
		c300: "#FFA4A2",
		c400: "#FD6F6C",
		c500Primary: "#F5423E",
		c600: "#E53935",
		c700Dark: "#BE1B17",
		c800: "#9D1A17",
		c900: "#821C1A",
		c950: "#470908",
	},
	warning: {
		c50: "#FFFEEA",
		c100: "#FFF8C5",
		c200Light: "#FFF285",
		c300: "#FFE546",
		c400: "#FFD41B",
		c500Primary: "#FFB300",
		c600: "#E28900",
		c700Dark: "#BB6002",
		c800: "#984A08",
		c900: "#7C3C0B",
		c950: "#7c3c0b",
	},
	libraryColors: {
		darkIndigo: "#401694",
		navy: "#225091",
		aquamarine: "#4ECCC6",
		green: "#00C875",
		grassGreen: "#037F4C",
		brightGreen: "#9CD326",
		saladish: "#CAB641",
		eggYolk: "#FFCC00",
		orange: "#FDAB3D",
		peach: "#FFADAD",
		sunset: "#FF7575",
		darkOrange: "#FF642E",
		red: "#E2445C",
		darkRed: "#BA3254",
		brown: "#7f5347",
		blackish: "#333333",
		americanGray: "#808080",
		explosive: "#C4C4C4",
		winter: "#9AADBD",
		river: "#68A1BD",
		darkBlue: "#0086C0",
		lightBlue: "#579BFC",
		chillBlue: "#66CCFF",
		indigo: "#5559DF",
		darkPurple: "#784BD1",
		berry: "#7E3B8A",
		purple: "#A25DDC",
		sofiaPink: "#FF158A",
		lipstick: "#FF5AC4",
		bubble: "#FAA1F1",
	},
};
