import {styled} from "styled-components";
import {Observer} from "mobx-react";
import {UserProfileV5} from "../user/UserProfileV5";
import {baseDistance, FlexCenterStyle, TOPBAR_HEIGHT} from "../styles/styles";
import {useAppStore} from "../../../StateManager";
import {SearchFieldStyled} from "../input/search/SearchField.styled";
import {colorPalette} from "../styles/colorPalette";
import {ProjectNameV5} from "./ProjectNameV5";
import {ViewTabsV5} from "./ViewTabsV5";
import {MainSearchV5} from "./MainSearchV5";

export const TopBarV5 = () => {
	const navBarState = useAppStore((state) => state.navBarState);
	const appState = useAppStore((state) => state.appState);

	return (
		<Observer>
			{() => {
				const isInSettingsView = ["settings"].includes(appState.selectedMenu);

				return (
					<TopBarStyled>
						{navBarState !== "docked" && <ProjectNameV5 />}
						<ViewTabsV5 />
						<TopBarActionsStyled>
							{!isInSettingsView && <MainSearchV5 />}
							<UserProfileV5 />
						</TopBarActionsStyled>
					</TopBarStyled>
				);
			}}
		</Observer>
	);
};

const TopBarStyled = styled.div`
	${FlexCenterStyle};
	justify-content: space-between;
	position: relative;
	min-height: ${TOPBAR_HEIGHT};
	background-color: ${colorPalette.white};
	border-bottom: 1px solid ${colorPalette.gray.c300};
	padding: ${baseDistance.md};
	z-index: 11;
`;

const TopBarActionsStyled = styled.div`
	${FlexCenterStyle};
	gap: ${baseDistance.md};

	&:only-of-type {
		margin-left: auto;
	}

	${SearchFieldStyled} {
		width: 200px;
	}
`;
