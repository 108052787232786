import styled, {css} from "styled-components";
import {baseDistance, radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";

export const MultiSelectInputStyled = styled.div<{$emptyInline?: boolean}>`
	border-radius: ${radius.sm};
	border: 1px solid ${colorPalette.gray.c300};
	padding: ${baseDistance.sm};

	${(props) => {
		if (props.$emptyInline) {
			return css`
				max-height: 29px;
			`;
		}
	}}

	&.empty {
		height: 32px;
		cursor: pointer;

		&:hover {
			background-color: ${colorPalette.gray.c200Light};
		}
	}
`;
