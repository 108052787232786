import * as React from "react";
import styled from "styled-components";
import type {Color, Dimensions, ModelParameters} from "../../../../../generated/api/base";
import type {LibraryModel} from "../../../../../data/models/LibraryModel";
import type {IRealSize} from "../../../../modules/settings/modules/type/form/RealSizeInput";
import {Constants} from "../../../../modules/space/spaceeditor/logic3d/Constants";
import {ToggleSwitchFieldStyled, ToggleSwitchFieldV5} from "../../../details/ToggleSwitchFieldV5";
import {ColorSelectorV5} from "../../../colors/ColorSelectorV5";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import {ObjectUtils} from "../../../../../utils/data/ObjectUtils";
import {colorPalette} from "../../../styles/colorPalette";
import {baseDistance, FLEXCENTER, radius} from "../../../styles/styles";
import {ModelPreviewerV5} from "./ModelPreviewerV5";
import {RealSizeInputV5} from "./RealSizeInputV5";
import {SideContainerStyled} from "./CatalogItemEditorV5";

type Dimension = "x" | "y" | "z";

interface IMeshTweakerProps {
	readonly libraryModel: LibraryModel;
	readonly modelParameters: ModelParameters;
	readonly onColorChange: (newColor: Color) => void;
	readonly onDimensionChange: (dimensions: Dimensions) => void;
}

interface IMeshTweakerState {
	dimensions: IRealSize[];
	doesMaintainProportions: boolean;
}

export class MeshTweakerV5 extends React.Component<IMeshTweakerProps, IMeshTweakerState> {
	private _modelPreviewerRef = React.createRef<ModelPreviewerV5>();

	// Y and Z are swapped on purpose to be consistent with the space editor axes
	private _dimensionLabels: Dimension[] = ["x", "z", "y"];

	constructor(props: IMeshTweakerProps) {
		super(props);
		const defaultUnit = "inch";

		this.state = {
			dimensions: [
				{
					value: this.props.modelParameters.dimensions.x / Constants.DISTANCE_UNITS[defaultUnit].multiplicator,
					unit: defaultUnit,
				},
				{
					value: this.props.modelParameters.dimensions.z / Constants.DISTANCE_UNITS[defaultUnit].multiplicator,
					unit: defaultUnit,
				},
				{
					value: this.props.modelParameters.dimensions.y / Constants.DISTANCE_UNITS[defaultUnit].multiplicator,
					unit: defaultUnit,
				},
			],
			doesMaintainProportions: true,
		};
	}

	private getValueInMeters = (size: IRealSize) => {
		return size.value * Constants.DISTANCE_UNITS[size.unit].multiplicator;
	};

	private getDimensionTweakers() {
		return this._dimensionLabels.map((dimensionName: Dimension, index: number) => {
			const label = (dimensionName === "y" ? "z" : dimensionName === "z" ? "y" : "x").toUpperCase();

			return (
				<RealSizeInputV5
					key={dimensionName}
					label={label}
					size={{
						value: this.state.dimensions[index].value,
						unit: this.state.dimensions[index].unit,
					}}
					onChange={(size: IRealSize) => {
						const newDimensions = ObjectUtils.deepClone(this.state.dimensions);
						const newValueInMeters = this.getValueInMeters(size);
						const dimensionValues: Dimensions = {
							[this._dimensionLabels[0]]: this.getValueInMeters(this.state.dimensions[0]),
							[this._dimensionLabels[1]]: this.getValueInMeters(this.state.dimensions[1]),
							[this._dimensionLabels[2]]: this.getValueInMeters(this.state.dimensions[2]),
						};

						newDimensions[index].unit = size.unit;
						newDimensions[index].value = size.value;

						if (this.state.doesMaintainProportions) {
							const oldValueInMeters = this.getValueInMeters(this.state.dimensions[index]);
							const ratio = newValueInMeters / oldValueInMeters;

							for (let i = 0; i < this._dimensionLabels.length; ++i) {
								if (i !== index) {
									newDimensions[i].value *= ratio;
									dimensionValues[this._dimensionLabels[i]] = this.getValueInMeters(newDimensions[i]);
								}
							}
						}

						this.setState({dimensions: newDimensions});

						dimensionValues[dimensionName] = newValueInMeters;

						this.props.onDimensionChange(dimensionValues);
					}}
				/>
			);
		});
	}

	private onMaintainProportionsChanged = (value: boolean) => {
		this.setState({
			doesMaintainProportions: value,
		});
	};

	public get currentMesh() {
		return this._modelPreviewerRef.current?.mesh;
	}

	public override render() {
		const color = this.props.modelParameters.bodyColor;

		return (
			<MeshTweakerV5Styled>
				<MeshTweakerLeftSideStyled>
					{this.getDimensionTweakers()}
					<ToggleSwitchFieldV5
						noBooleanLabel={true}
						label="Maintain X, Y and Z proportions"
						value={this.state.doesMaintainProportions}
						onChange={this.onMaintainProportionsChanged}
					/>
				</MeshTweakerLeftSideStyled>
				<MeshTweakerRightSideStyled>
					<ModelPreviewerV5
						ref={this._modelPreviewerRef}
						color={color}
						libraryModel={this.props.libraryModel}
						dimensions={this.props.modelParameters.dimensions}
					>
						<div className="background-colorselector">
							<ColorSelectorV5
								label="Color"
								color={color}
								onColorChange={this.props.onColorChange}
								horizontalAlignment={HorizontalAlignment.outerRight}
								verticalAlignment={VerticalAlignment.bottom}
								title="Color"
								isTransparencyEnabled={true}
							/>
						</div>
					</ModelPreviewerV5>
				</MeshTweakerRightSideStyled>
			</MeshTweakerV5Styled>
		);
	}
}

const MeshTweakerV5Styled = styled.div`
	display: flex;
	gap: ${baseDistance.lg};
`;

const MeshTweakerLeftSideStyled = styled(SideContainerStyled)`
	width: 336px;
	display: flex;
	flex-direction: column;
	padding: ${baseDistance.md};
	gap: ${baseDistance.sm};

	${ToggleSwitchFieldStyled} {
		padding-top: ${baseDistance.sm};
		gap: ${baseDistance.md};
	}
`;

const MeshTweakerRightSideStyled = styled(SideContainerStyled)`
	width: 648px;

	.ModelPreviewer {
		height: 100%;
		box-shadow: none;
		border: none;
		display: flex;
		justify-content: center;
		padding: ${baseDistance.md};

		canvas {
			bottom: 24px;
		}

		.background-colorselector {
			z-index: 2;
			width: 64px;
			height: 48px;
			border: 1px solid ${colorPalette.gray.c300};
			border-radius: ${radius.md};
			${FLEXCENTER};

			.ColorSelector {
				&.active {
					background-color: transparent;
				}

				&:hover::after {
					left: auto;
					transform: translateX(85%);
					top: auto;
				}
			}
		}
	}
`;
