import {LineSegments2 as LineSegments} from "three/examples/jsm/lines/LineSegments2.js";
import type {LineMaterial} from "three/examples/jsm/lines/LineMaterial.js";
import {LineSegmentsGeometry} from "three/examples/jsm/lines/LineSegmentsGeometry.js";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {getCorrectionMultiplierForSpaceItem, MeasureType} from "../../renderers/SpaceViewRendererUtils";
import {MarkupType} from "../../../../../../../generated/api/base";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import type {PointDouble} from "../../../../../../../generated/api/base";
import type {IMarkupMinimumData, IMarkupSettingsData, Markup} from "../../../../../../../data/models/Markup";
import {ObjectUtils} from "../../../../../../../utils/data/ObjectUtils";
import type {IMarkupConfig} from "./abstract/MarkupUtils";
import {calculateArrowHead, getArrowPointsForMarkupCallout, getDefaultTargetForMarkupCallout, MarkupUtils} from "./abstract/MarkupUtils";
import type {ICornerLetter} from "./abstract/MarkupAB";
import {MarkupRectangle} from "./MarkupRectangle";

export class MarkupCallout extends MarkupRectangle {
	private _targetLineGeometry = new LineSegmentsGeometry();
	private _targetLine = new LineSegments(this._targetLineGeometry, this._lineMaterial as LineMaterial);

	private _targetStart = new Float32Array(3);
	private _targetElbow = new Float32Array(3);
	private _targetEnd = new Float32Array(3);
	private _arrowHeadC = new Float32Array(3);
	private _arrowHeadD = new Float32Array(3);

	private _targetOnPointerDown: PointDouble;

	constructor(spaceViewRenderer: SpaceViewRenderer, config: IMarkupConfig) {
		super(spaceViewRenderer, {measureType: MeasureType.NONE, ...config});

		THREEUtils.add(this._group, this._targetLine);
	}

	public setTargetOnPointerDown(worldX: number, worldY: number) {
		this._targetOnPointerDown = {
			x: worldX,
			y: worldY,
		};
	}

	public get targetOnPointerDown() {
		return this._targetOnPointerDown;
	}

	public override get target() {
		return (
			((this._modelData?.settings || null) as IMarkupSettingsData)?.target ||
			this._targetOnPointerDown ||
			getDefaultTargetForMarkupCallout(this._worldGeometryData, this.fontFamily, this._spaceViewRenderer)
		);
	}

	protected override destroyCallback(notifyServer?: boolean) {
		super.destroyCallback(notifyServer);

		this._targetLineGeometry.dispose();
	}

	private updateTargetArrow(isLocal: boolean = false) {
		const {position} = this;

		const {startPos, elbowPos, target} = getArrowPointsForMarkupCallout(this);

		this._targetStart[0] = startPos.x;
		this._targetStart[1] = startPos.y;

		this._targetElbow[0] = elbowPos.x;
		this._targetElbow[1] = elbowPos.y;

		this._targetEnd[0] = target.x;
		this._targetEnd[1] = target.y;

		// Don't use this._modelData.arrowHeadSize directly, because
		// modelData might be a simple object (IMarkupMinimumData) for copy-pasting for example
		const arrowHeadSize = ((this._modelData?.settings || null) as IMarkupSettingsData)?.arrowHeadSize || MarkupUtils.defaultArrowHeadSize;
		const arrowHead = calculateArrowHead(
			elbowPos,
			target,
			arrowHeadSize,
			getCorrectionMultiplierForSpaceItem(this._spaceViewRenderer, this._modelData),
		);

		this._arrowHeadC[0] = target.x + arrowHead.bc.x;
		this._arrowHeadC[1] = target.y + arrowHead.bc.y;
		this._arrowHeadD[0] = target.x + arrowHead.bd.x;
		this._arrowHeadD[1] = target.y + arrowHead.bd.y;

		if (isLocal) {
			this._targetStart[0] -= position.x;
			this._targetStart[1] -= position.y;
			this._targetElbow[0] -= position.x;
			this._targetElbow[1] -= position.y;
			this._targetEnd[0] -= position.x;
			this._targetEnd[1] -= position.y;

			this._arrowHeadC[0] -= position.x;
			this._arrowHeadC[1] -= position.y;
			this._arrowHeadD[0] -= position.x;
			this._arrowHeadD[1] -= position.y;
		}

		this._targetLineGeometry.dispose();
		this._targetLineGeometry = new LineSegmentsGeometry();
		this._targetLineGeometry.setPositions([
			...this._targetStart,
			...this._targetElbow,
			...this._targetElbow,
			...this._targetEnd,
			...this._targetEnd,
			...this._arrowHeadC,
			...this._targetEnd,
			...this._arrowHeadD,
		]);
		this._targetLine.geometry = this._targetLineGeometry;
		this._targetLine.rotation.z = -this._group.rotation.z;
		THREEUtils.updateMatrices(this._targetLine);

		this._spaceViewRenderer.needsRender = true;
	}

	private getAllVertices(): PointDouble[] {
		return [...this.get4CornersAsWorldVertices(), this.target];
	}

	protected override updateAB(A: PointDouble, B: PointDouble, isLocal: boolean = false, keepAspectRatio: boolean, fixedPoint?: ICornerLetter) {
		super.updateAB(A, B, isLocal, keepAspectRatio, fixedPoint);
		this.updateTargetArrow(isLocal);
	}

	protected override updateGrabbableCorners(vertices: PointDouble[] = this.getAllVertices(), recreateThem: boolean = true) {
		return super.updateGrabbableCorners(vertices, recreateThem);
	}

	protected override updateBoundingBox(dataToCalculateFrom: PointDouble[] = this.getAllVertices()) {
		return super.updateBoundingBox(dataToCalculateFrom);
	}

	public override makeSizeFixed(): void {
		const modelData = this._modelData as Markup;

		if (modelData?.isSizeFixed !== true) {
			modelData.setIsSizeFixed(true);
		}
	}

	public override onGrabbableCornerPointerMove(deltaX: number, deltaY: number) {
		const index = this._indicesOfSelectedVertices[0];
		const isTargetBeingGrabbed = index === 4;

		if (isTargetBeingGrabbed) {
			// target is being grabbed
			this._modelData.setTarget({
				x: this._savedVertex.x + deltaX,
				y: this._savedVertex.y + deltaY,
			});

			this.updateTargetArrow();

			this.updateGrabbableCorners(undefined, false);
			this.updateBoundingBox();
		} else {
			super.onGrabbableCornerPointerMove(deltaX, deltaY);
		}
	}

	public override startRotating(pivot?: PointDouble) {
		super.startRotating(pivot);
		this._targetOnPointerDown = {...this.target};
	}

	public override rotateWithHandlerByDelta(deltaAngle: number, pivot?: PointDouble) {
		if (this.hasPermissionToMoveOrRotate) {
			super.rotateWithHandlerByDelta(deltaAngle, pivot);

			const realDelta = this._group.rotation.z - this._lastSavedOrientation; // we have to deal with the snap-to-angle feature
			const newTargetPosition = THREEUtils.getRotatedVertex(this._targetOnPointerDown, realDelta, (!this.isPositionLocked && pivot) || this.position);

			this._modelData.setTarget(newTargetPosition);
		}
	}

	public override startTranslating() {
		super.startTranslating();
		this._targetOnPointerDown = {...this.target};
	}

	public override translate(x: number, y: number, z: number, force?: boolean) {
		// Don't use this._modelData.setTarget here, because
		// modelData might be a simple object (IMarkupMinimumData) for copy-pasting for example
		const settingsData = this._modelData.settings as IMarkupSettingsData;

		settingsData.target = {
			x: this._targetOnPointerDown.x + x,
			y: this._targetOnPointerDown.y + y,
		};
		if (this._modelData.setSettings) {
			this._modelData.setSettings(ObjectUtils.deepClone(settingsData));
		} else {
			(this._modelData as IMarkupMinimumData).settings = ObjectUtils.deepClone(settingsData);
		}

		return super.translate(x, y, z, force);
	}

	public override get position() {
		if (this._isInEditMode || !this._modelData) {
			const bboxForPosition = this.calculateBoundingBox(this._worldGeometryData);

			return {
				...THREEUtils.getCenterOfBoundingBox(bboxForPosition),
				z: this._group.position.z,
			};
		} else {
			return {
				x: this._group.position.x,
				y: this._group.position.y,
				z: this._group.position.z,
			};
		}
	}

	public override get type() {
		return MarkupType.Callout;
	}
}
