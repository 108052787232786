import type {BufferAttribute, InstancedBufferGeometry} from "three";
import {DynamicDrawUsage, InstancedMesh} from "three";
import {MarkupPhoto360Material} from "../../materials/MarkupPhoto360Material";
import type {MarkupPhoto360} from "../../elements3d/markups/MarkupPhoto360";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import {Constants} from "../../Constants";
import {MathUtils} from "../../../../../../../utils/math/MathUtils";

const initialMaxNumberOfPhoto360sOnAFloorplan = 16;

type AttributeKey = "color" | "opacity" | "isGrayScaled";
interface IAttributeProps {
	size: number;
	defaultValue: number;
}

type AttributeDataType = {
	[key in AttributeKey]: IAttributeProps;
};

export class MarkupPhoto360InstancedMeshManager {
	private _spaceViewRenderer: SpaceViewRenderer;
	private _instancedBufferGeometry: InstancedBufferGeometry;
	private _photo360InstancedMesh: InstancedMesh = new InstancedMesh(undefined, undefined, 0);
	private _photo360Material: MarkupPhoto360Material;
	protected readonly _attributeData: AttributeDataType = {
		color: {
			size: 3,
			defaultValue: 1,
		},
		opacity: {
			size: 1,
			defaultValue: 1,
		},
		isGrayScaled: {
			size: 1,
			defaultValue: 0,
		},
	};

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		this._spaceViewRenderer = spaceViewRenderer;
		this.initBufferAttributes(initialMaxNumberOfPhoto360sOnAFloorplan);
	}

	private get markupManager() {
		return this._spaceViewRenderer.markupManager;
	}

	public async initPhoto360InstancedMesh(markupPhoto360ArrayLength: number) {
		if (!this._instancedBufferGeometry) {
			this._instancedBufferGeometry = await THREEUtils.loadGLBAsInstancedBufferGeometry(Constants.URLForPhoto360GLB);
			this._instancedBufferGeometry.computeVertexNormals();
		}

		const instancedBufferGeometry = this._instancedBufferGeometry.clone();

		const {space} = this._spaceViewRenderer;
		const {spaceUnitsPerMeter} = space;
		const {xyiconSize} = space.type.settings;
		const {value, unit} = xyiconSize;
		const standardXyiconSize = MathUtils.convertDistanceToSpaceUnit(value, unit, spaceUnitsPerMeter);
		instancedBufferGeometry.scale(standardXyiconSize, standardXyiconSize, standardXyiconSize);

		const cameraWorldPos = this._spaceViewRenderer.activeCamera.position;
		this._photo360Material = new MarkupPhoto360Material(
			this._spaceViewRenderer.textureManager.miscTextures.envMap,
			[cameraWorldPos.x, cameraWorldPos.y, cameraWorldPos.z],
			this._spaceViewRenderer.renderer.capabilities.isWebGL2,
		);

		const material = this._photo360Material; // new MeshNormalMaterial();

		const maxCount = Math.max(initialMaxNumberOfPhoto360sOnAFloorplan, markupPhoto360ArrayLength * 2);
		this._photo360InstancedMesh = new InstancedMesh(instancedBufferGeometry, material, maxCount);
		this._photo360InstancedMesh.frustumCulled = false;
		(this._photo360InstancedMesh.geometry as InstancedBufferGeometry).instanceCount = maxCount;
		this.initBufferAttributes(maxCount);
		this._photo360InstancedMesh.count = markupPhoto360ArrayLength;
		this._photo360InstancedMesh.name = "MarkupPhoto360";
		this._photo360InstancedMesh.instanceMatrix.setUsage(DynamicDrawUsage);
		this._photo360InstancedMesh.instanceMatrix.needsUpdate = true;
		THREEUtils.add(this.markupManager.container, this._photo360InstancedMesh);
	}

	private initBufferAttributes(count: number) {
		const geometry = this._photo360InstancedMesh.geometry as InstancedBufferGeometry;
		const attributeData = this._attributeData;

		for (const key in attributeData) {
			const typedKey = key as keyof typeof attributeData;
			const attribute = attributeData[typedKey];
			const attributeItemSize = attribute.size;
			const typedArray = new Float32Array(count * attributeItemSize);

			typedArray.fill(attribute.defaultValue);
			geometry.setAttribute(typedKey, THREEUtils.createInstancedBufferAttribute(typedArray, attributeItemSize));
		}
	}

	private initAttributesOfNewInstance(instancedMesh: InstancedMesh, instanceId: number) {
		const attributes = (instancedMesh.geometry as InstancedBufferGeometry).attributes as {[key in keyof typeof this._attributeData]: BufferAttribute};

		THREEUtils.setAttributeXYZ(attributes.color, instanceId, 1, 1, 1);
		THREEUtils.setAttributeX(attributes.opacity, instanceId, 1);
		THREEUtils.setAttributeX(attributes.isGrayScaled, instanceId, 0);

		return attributes;
	}

	public addMarkup360Items(markupPhoto360Items: MarkupPhoto360[]) {
		const previousCount = this._photo360InstancedMesh.count;
		const newCount = previousCount + markupPhoto360Items.length;
		const previousMaxCount = (this._photo360InstancedMesh.geometry as InstancedBufferGeometry).instanceCount;

		if (newCount > previousMaxCount) {
			const newMaxCount = newCount * 2;
			this._photo360InstancedMesh = THREEUtils.cloneInstancedMeshWithLargerMaxCount(this._photo360InstancedMesh, newMaxCount);
			this._photo360InstancedMesh.frustumCulled = false;
		}

		const instanceId = previousCount;
		this.initAttributesOfNewInstance(this._photo360InstancedMesh, instanceId);

		this._photo360InstancedMesh.count = newCount;
	}

	public get photo360InstancedMesh() {
		return this._photo360InstancedMesh;
	}
}
