import {css, styled} from "styled-components";
import type {CSSProperties, ReactNode} from "react";
import {useCallback, useLayoutEffect, useEffect, useRef, useState} from "react";
import {colorPalette} from "../styles/colorPalette";
import {FlexCenterStyle, VerticalFlexStyle, baseDistance, fontSize, fontWeight, radius, zIndex} from "../styles/styles";
import {useAppStore} from "../../../StateManager";
import XmarkLargeIcon from "../icons/xmark-large.svg?react";
import XmarkIcon from "../icons/xmark.svg?react";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {HorizontalAlignment, VerticalAlignment, DomUtils} from "../../../utils/dom/DomUtils";
import type {IButtonV5Props} from "../button/ButtonV5";
import {ButtonV5} from "../button/ButtonV5";
import CirclePlusIcon from "../icons/circle-plus.svg?react";
import {Functions} from "../../../utils/function/Functions";
import {IconButtonStyled, IconButtonV5} from "../interaction/IconButtonV5";
import {useClickOutside} from "../utils";
import {KeyboardListener} from "../../../utils/interaction/key/KeyboardListener";

interface IButtonProps extends IButtonV5Props {
	hideIcon?: boolean;
}

interface IPopupV5Props {
	readonly label?: ReactNode;
	readonly buttonProps?: IButtonProps;
	readonly parentRef?: HTMLElement;
	readonly children: React.ReactNode;
	readonly verticalAlignment?: VerticalAlignment;
	readonly horizontalAlignment?: HorizontalAlignment;
	readonly centerOnScreen?: boolean;
	readonly noButtons?: boolean;
	readonly width?: string;
	readonly height?: string;
	readonly freezeRoot?: boolean;
	readonly className?: string;
	readonly offsetX?: number;
	readonly offsetY?: number;
	readonly isSmallPopup?: boolean;
	readonly versionNumber?: number;
	readonly closeOnClickOutside?: boolean;
	readonly onClose?: (e?: React.MouseEvent) => void;
	readonly style?: CSSProperties;
}

interface IPopupStyledProps {
	$center: boolean;
	$width: string;
	$height: string;
	$isSmallPopup: boolean;
}

export const PopupV5 = (props: IPopupV5Props) => {
	const _floating = useRef<HTMLDivElement>();
	const appState = useAppStore((state) => state.appState);
	const {
		parentRef,
		verticalAlignment = VerticalAlignment.bottom,
		horizontalAlignment = HorizontalAlignment.outerRight,
		offsetY = -8,
		offsetX = 16,
		isSmallPopup = false,
		freezeRoot = false,
		buttonProps = {
			label: "Create",
			create: Functions.emptyFunction,
			onClick: Functions.emptyFunction,
			disabled: false,
			hideIcon: false,
		} as IButtonProps,
		className = "",
		noButtons = false,
		versionNumber = 0,
		closeOnClickOutside = true,
		centerOnScreen,
		width,
		height,
		label,
		onClose,
		children,
		style = {},
	} = props;
	const setIsRootFrozen = useAppStore((state) => state.setIsRootFrozen);
	const [transform, setTransform] = useState<TransformObj>(null);
	const [styleState, setStyleState] = useState<React.CSSProperties>({});

	const handleClose = useCallback(() => {
		if (closeOnClickOutside) {
			onClose?.();
		}
	}, [closeOnClickOutside, onClose]);

	useClickOutside([_floating], handleClose);

	const onKeyUp = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === KeyboardListener.KEY_ESCAPE) {
				onClose?.();
			}
		},
		[onClose],
	);

	useEffect(() => {
		window.addEventListener("keyup", onKeyUp);

		return () => {
			window.removeEventListener("keyup", onKeyUp);
		};
	}, [onKeyUp]);

	const setMarginsCallback = useCallback(() => {
		if (centerOnScreen && _floating.current) {
			setStyleState({
				marginLeft: `${(document.body.offsetWidth - _floating.current.offsetWidth) / 2}px`,
				marginTop: `${(document.body.offsetHeight - _floating.current.offsetHeight) / 2}px`,
				...style,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [centerOnScreen]);

	const setTransformCallback = useCallback(() => {
		if (parentRef && _floating.current) {
			setTransform(DomUtils.getFixedFloatingElementPosition(parentRef, _floating.current, verticalAlignment, horizontalAlignment, offsetY, offsetX));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parentRef]);

	useLayoutEffect(() => {
		setMarginsCallback();
		setTransformCallback();

		if (freezeRoot) {
			setIsRootFrozen(true);
		}

		return () => setIsRootFrozen(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setMarginsCallback, setTransformCallback]);

	const popupStyle: CSSProperties = {
		visibility: centerOnScreen || (_floating.current && transform?.translate) ? "visible" : "hidden",
		...styleState,
	};

	if (!centerOnScreen && _floating.current && transform) {
		popupStyle.left = `${transform.x}px`;
		popupStyle.top = `${transform.y}px`;
	}

	return (
		<DomPortal destination={appState.app.modalContainer}>
			<PopupStyled
				ref={_floating}
				style={popupStyle}
				$center={centerOnScreen}
				$width={width}
				$height={height}
				className={className}
				$isSmallPopup={isSmallPopup}
			>
				{onClose && (
					<PopupHeaderStyled
						className="header"
						$isSmallPopup={isSmallPopup}
					>
						<NameStyled className="name">{label}</NameStyled>
						<IconButtonV5
							IconComponent={isSmallPopup ? XmarkIcon : XmarkLargeIcon}
							onClick={onClose}
						/>
					</PopupHeaderStyled>
				)}
				<PopupBodyStyled className="body">
					{children}
					{!noButtons &&
						![
							"SpaceCreation",
							"OrganizationCreation",
							"PermissionSetCreation",
							"ReportCreation",
							"ReportRun",
							"SettingsUserCreation",
							"SharingPopup",
						].includes(className) && (
							<CreateButtonStyled className="button popupButton">
								<ButtonV5
									onClick={buttonProps.onClick}
									disabled={buttonProps.disabled}
									label={buttonProps.label}
								>
									{!buttonProps.hideIcon && <CirclePlusIcon />}
								</ButtonV5>
							</CreateButtonStyled>
						)}
				</PopupBodyStyled>
			</PopupStyled>
		</DomPortal>
	);
};

export const PopupHeaderStyled = styled.div<{$isSmallPopup?: boolean}>`
	${FlexCenterStyle}
	justify-content: space-between;
	padding: ${(props) => (props.$isSmallPopup ? baseDistance.sm : baseDistance.md)};
`;

const PopupStyled = styled.div<IPopupStyledProps>`
	${VerticalFlexStyle};
	background-color: ${colorPalette.white};
	border-radius: ${(props) => (props.$isSmallPopup ? radius.md : radius.xl)};
	min-width: 320px;
	box-shadow: 0px 4px 20px 0px #00000033;
	z-index: ${zIndex.createPanel + 1};
	position: absolute;
	visibility: hidden;

	${PopupHeaderStyled} {
		${IconButtonStyled} {
			height: 32px;
			min-width: 32px;

			svg {
				width: 24px;
				height: 24px;
			}
		}
	}

	${(props) => {
		if (props.$center) {
			return css`
				position: fixed;
				visibility: visible;
			`;
		}
	}};

	&.SpaceCreation,
	&.ReportCreation {
		min-width: 810px;
		min-height: 600px;
		max-width: 1080px;
		max-height: 800px;
	}

	&.SettingsUserCreation {
		min-width: 528px;
		min-height: 528px;
		max-width: 528px;
		max-height: 528px;
		margin-left: 0px;
		visibility: visible;
	}

	&.PermissionSetCreation {
		min-width: 320px;
		min-height: 162px;
		max-width: 320px;
		max-height: 162px;
		position: absolute;
	}

	&.GlobalViewSharing {
		z-index: ${zIndex.popup + zIndex.modal};
	}

	${(props) => css`
		width: ${props.$width || "auto"};
		height: ${props.$height || "auto"};
	`};

	&.SharingPopup {
		.header {
			padding: ${baseDistance.sm};
		}

		.body {
			padding: 0 ${baseDistance.sm} ${baseDistance.sm} ${baseDistance.sm};
		}
	}
`;

export const NameStyled = styled.span`
	${FlexCenterStyle};
	font-size: ${fontSize.xl};
	font-weight: ${fontWeight.bold};
	color: ${colorPalette.gray.c950};
	height: 24px;
	overflow: hidden;
	word-break: break-all;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	white-space: unset;
`;

export const PopupBodyStyled = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 ${baseDistance.md} ${baseDistance.md} ${baseDistance.md};
	flex: 1;

	.threeColumns {
		display: grid;
		grid-template-columns: 0.5fr 0.65fr 1fr;
	}
`;

export const CreateButtonStyled = styled.div`
	margin-top: 24px;
	display: flex;
	justify-content: flex-end;

	&.button.popupButton {
		cursor: auto;

		.Button &:not(.disabled) {
			cursor: pointer;
		}
	}
`;
