import * as React from "react";
import {inject, observer} from "mobx-react";
import type {Field as FieldModel} from "../../../../../data/models/field/Field";
import {Field} from "../../../../widgets/form/field/Field";
import {SelectInput} from "../../../../widgets/input/select/SelectInput";
import {FieldDataTypes} from "../../../../../data/models/field/FieldDataTypes";
import {ToggleSwitchField} from "../../../../widgets/button/switch/ToggleSwitchField";
import type {ISwitchListChange} from "../../../../widgets/button/switch/SwitchList";
import {ToggleContainer} from "../../../../widgets/container/ToggleContainer";
import type {AppState} from "../../../../../data/state/AppState";
import {XyiconFeature, FieldDataType} from "../../../../../generated/api/base";
import {ArrayUtils} from "../../../../../utils/data/array/ArrayUtils";
import {ClickToEditInput} from "../../../../widgets/input/clicktoedit/ClickToEditInput";
import {SingleLineLabel} from "../../../../widgets/input/clicktoedit/datatypes/singleline/SingleLineLabel";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {PopupUtils} from "../../../abstract/popups/PopupUtils";
import type {ISelectSliderOption} from "../../../../widgets/input/selectslider/SelectSlider";
import {SelectSlider} from "../../../../widgets/input/selectslider/SelectSlider";
import {featureTitles} from "../../../../../data/state/AppStateConstants";
import {FormattingSettings} from "./datatypes/FormattingSettings";
import {FieldDetailsTabSettings} from "./FieldDetailsTabConstants";

interface IFieldFormProps {
	readonly field: FieldModel;
	readonly typesFeature: XyiconFeature;
	readonly createPanelNameInput?: (name: string) => void;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

export enum AssignType {
	Unassigned = 0,
	Assigned = 1,
	Lookup = 2,
}

@inject("appState")
@inject("transport")
@observer
export class FieldForm extends React.Component<IFieldFormProps> {
	private _updateFieldTimeoutId: number = null;
	private _updateFieldFormulaTimeoutId: number = null;
	public _assignedTypes: ISelectSliderOption[] = [
		{
			id: AssignType.Unassigned,
			label: AssignType[AssignType.Unassigned],
		},
		{
			id: AssignType.Assigned,
			label: AssignType[AssignType.Assigned],
		},
		this.props.field.feature === XyiconFeature.Xyicon && {
			id: AssignType.Lookup,
			label: "Lookup Link",
			tooltip: "Lookup Link is only applicable for Text (Single Line) fields where Display On Links is enabled.",
		},
	].filter((type) => type);

	private _helperText: string = "";

	private onMappingChange = (changes: ISwitchListChange[]) => {
		const {field, appState} = this.props;
		const fieldId = field.id;
		const mapping = appState.typeFieldMapping[field.feature];

		for (const change of changes) {
			const typeId = change.id;

			if (!mapping[typeId]) {
				mapping[typeId] = new Set();
			}

			const mappingForType = mapping[typeId];

			if (change.value) {
				mappingForType.add(fieldId);
			} else {
				mappingForType.delete(fieldId);
			}

			this.updateMapping(typeId);
		}
	};

	private onSliderMappingChange = (labels: string[], value: AssignType) => {
		const {field, typesFeature, appState} = this.props;
		const list = appState.actions.getTypesForField(field, typesFeature);
		const updatedTypes: any = [];

		labels.forEach((label) => {
			const typeItem = list.find((type) => type.label === label);
			const typeObj = appState.actions.getTypeById(typeItem.id);

			if (!typeItem || !typeObj) {
				return;
			}

			const savedTypeObjStr = JSON.stringify({data: typeObj.data, settings: typeObj.settings});

			typeObj.settings.xyiconLookupLinkFieldList = typeObj.settings.xyiconLookupLinkFieldList || [];

			if (value === AssignType.Lookup) {
				typeItem.value = false;
				ArrayUtils.addMutable(typeObj.settings.xyiconLookupLinkFieldList, field.id);
			} else {
				typeItem.value = value === AssignType.Assigned;
				ArrayUtils.removeMutable(typeObj.settings.xyiconLookupLinkFieldList, field.id);
			}

			if (savedTypeObjStr !== JSON.stringify({data: typeObj.data, settings: typeObj.settings})) {
				updatedTypes.push(typeObj);
			}
		});

		this.onMappingChange(list);

		if (updatedTypes.length > 0) {
			this.props.transport.services.typefield.updateType(updatedTypes);
		}
	};

	private updateMapping(typeId: string) {
		const selected = this.props.field;

		if (selected) {
			const services = this.props.transport.services;

			services.typefield.applyMapping(selected.feature, typeId);
			//services.typefield.applyFieldMapping(selected.feature);
		}
	}

	private getFormattingSettings(field: FieldModel) {
		return (
			<FormattingSettings
				field={field}
				onChange={this.onChangeFormatting}
			/>
		);
		// const FormattingSettingsComponent = FieldDetailsTab.formattingSettings[field.dataType];
		// if (FormattingSettingsComponent)
		// {
		// 	return <FormattingSettingsComponent field={field}/>
		// }
		// return false;
	}

	private onChangeFormatting = () => {
		this.updateField();
	};

	private onNameChange = (value: string) => {
		if (this.isNameValid(value)) {
			this.props.field.name = value;
			this.updateField();
		}
	};

	// private onBlur = () =>
	// {
	// 	this.saveFieldChanges();
	// };

	private isNameValid = (name: string) => {
		const {field, appState} = this.props;

		return appState.actions.isFieldNameValid(name, field);
	};

	private getErrorMessage = (name: string) => {
		return !name.trim() ? "Name cannot be empty!" : this.isNameValid(name) ? "" : "Name needs to be unique!";
	};

	private onChangeDisplayOnLinks = (value: boolean) => {
		const {field, typesFeature, appState} = this.props;
		const list = appState.actions.getTypesForField(field, typesFeature);

		field.displayOnLinks = value;

		if (!value && field.feature === XyiconFeature.Xyicon) {
			const savedList = JSON.stringify(list);

			list.forEach((item) => {
				const type = appState.actions.getTypeById(item.id);
				const savedTypeObjStr = JSON.stringify({data: type.data, settings: type.settings});

				type.settings.xyiconLookupLinkFieldList = type.settings.xyiconLookupLinkFieldList || [];

				if (type.settings.xyiconLookupLinkFieldList.includes(field.id)) {
					item.value = false;
				}

				ArrayUtils.removeMutable(type.settings.xyiconLookupLinkFieldList, field.id);

				if (savedTypeObjStr !== JSON.stringify({data: type.data, settings: type.settings})) {
					this.props.transport.services.typefield.updateType(type);
				}
			});

			if (savedList !== JSON.stringify(list)) {
				this.onMappingChange(list);
			}
		}

		this.updateField();
	};

	private updateField() {
		clearTimeout(this._updateFieldTimeoutId);
		this._updateFieldTimeoutId = window.setTimeout(this.saveFieldChanges, 500);
	}

	private updateFieldFormula() {
		clearTimeout(this._updateFieldFormulaTimeoutId);
		this._updateFieldFormulaTimeoutId = window.setTimeout(this.saveFieldFormulaChanges, 500);
	}

	private saveFieldChanges = async () => {
		const {field, transport} = this.props;

		if (field.refId) {
			const {error} = await transport.services.typefield.updateField(field);

			if (error) {
				console.warn(error);
			}
		}
	};

	private saveFieldFormulaChanges = async () => {
		const {field, transport} = this.props;

		if (field.refId) {
			const {error} = await transport.services.typefield.updateFieldFormula(field);

			if (error) {
				console.warn(error);
			}
		}
	};

	private onChangeSettings = () => {
		this.updateField();
	};

	private getDataTypeSettingsComponent() {
		const {field} = this.props;
		const SettingsComponent = FieldDetailsTabSettings[field.dataType];

		if (SettingsComponent) {
			return (
				<SettingsComponent
					field={field}
					onChange={this.onChangeSettings}
				/>
			);
		}
		return false;
	}

	private toggleHasFormula = async (value: boolean) => {
		const {field} = this.props;

		field.hasFormula = value;

		if (!value) {
			const confirmed = await PopupUtils.getFormulaDeleteConfirmationPopup(field.feature);

			if (confirmed) {
				field.formula = "";
				this.updateFieldFormula();
			}
		}
	};

	private onFormulaChange = async (value: string) => {
		const {field} = this.props;
		const confirmed = await PopupUtils.getFormulaChangeConfirmationPopup(field.feature);

		if (confirmed) {
			field.formula = value;
			this.updateFieldFormula();
		}
	};

	private onFieldDescriptionChange = (value: string) => {
		const {field} = this.props;

		field.fieldDescription = value;
		this.updateField();
	};

	private toggleAssignByModel = (value: boolean) => {
		const {field} = this.props;

		field.isAssignedByModel = value;

		this.saveFieldChanges();
	};

	private onHelperTextChange = (value: string) => {
		const {field} = this.props;

		field.helperText = value;

		this.saveFieldChanges();
	};

	public override componentDidMount() {
		const {field, typesFeature, appState} = this.props;
		const list = appState.actions.getTypesForField(field, typesFeature);

		list.forEach((item) => {
			const type = appState.actions.getTypeById(item.id);

			if (!type.settings.xyiconLookupLinkFieldList) {
				type.settings.xyiconLookupLinkFieldList = [];
			}
		});
	}

	public override componentWillUnmount() {
		clearTimeout(this._updateFieldTimeoutId);
		clearTimeout(this._updateFieldFormulaTimeoutId);
	}

	public override render() {
		const {field, typesFeature, appState, createPanelNameInput} = this.props;
		const editMode = !!field.refId;
		const fieldDataType = FieldDataTypes.map[field.dataType];
		const list = appState.actions.getTypesForField(field, typesFeature);

		return (
			<>
				<div className="header fieldForm">
					{editMode && (
						<Field label="ID">
							<SingleLineLabel value={field.refId} />
						</Field>
					)}
					<Field
						label="Name"
						className={!editMode && "focused"}
					>
						<ClickToEditInput
							value={field.name}
							onChange={this.onNameChange}
							onLiveChange={createPanelNameInput}
							// onBlur={this.onBlur}
							getErrorMessage={this.getErrorMessage}
							dataType={FieldDataType.SingleLineText}
							noButtons={true}
							focused={!!createPanelNameInput}
						/>
					</Field>
					<Field
						label="Field Type"
						className={!editMode && "focused"}
					>
						{editMode ? (
							<SingleLineLabel value={fieldDataType.title} />
						) : (
							<SelectInput
								options={FieldDataTypes.array.filter((config) => !config.system)}
								render={(type) => type.title}
								selected={fieldDataType}
								onChange={(type) => (field.dataType = type.id)}
								searchBar={false}
							/>
						)}
					</Field>
					{this.getDataTypeSettingsComponent()}
					<ToggleSwitchField
						label="Display On Links"
						value={field.displayOnLinks}
						onChange={this.onChangeDisplayOnLinks}
					/>
					{[XyiconFeature.Xyicon, XyiconFeature.XyiconCatalog].includes(field.feature) && (
						<ToggleSwitchField
							value={field.isAssignedByModel}
							onChange={this.toggleAssignByModel}
							label="Assign by Model"
							bubbleText={`Turning this toggle on allows this ${featureTitles[field.feature].toLowerCase()} field to be assigned to the Catalog Model.`}
						/>
					)}
					{[XyiconFeature.Boundary, XyiconFeature.Xyicon].includes(field.feature) && (
						<>
							<ToggleSwitchField
								value={field.hasFormula}
								onChange={this.toggleHasFormula}
								label="Has Formula"
							/>
							{field.hasFormula && (
								<Field label="Formula">
									<ClickToEditInput
										dataType={FieldDataType.MultiLineText}
										onChange={this.onFormulaChange}
										value={field.formula || undefined}
									/>
								</Field>
							)}
						</>
					)}
					{field.hasValidation && (
						<Field label="Validation Rule">
							<ClickToEditInput
								dataType={FieldDataType.SingleLineText}
								value={field.validation}
								disabled={true}
							/>
						</Field>
					)}
					<Field
						label="Field Description"
						icons={{postLabelIcon: "help"}}
						tooltips={{
							postLabelIconTooltip: "A detailed description explaining the intended use of the field. The description is only visible in Settings.",
						}}
						className="higher"
					>
						<ClickToEditInput
							value={field.fieldDescription}
							onChange={this.onFieldDescriptionChange}
							dataType={FieldDataType.MultiLineText}
						/>
					</Field>
					<Field
						label="Helper Text"
						icons={{postLabelIcon: "help"}}
						tooltips={{
							postLabelIconTooltip:
								"A short explanation designed to help the user understand the field's purpose. The text is displayed when the user hovers over the field's label on the Details tab.",
						}}
						className="higher"
					>
						<ClickToEditInput
							value={field.helperText}
							onChange={this.onHelperTextChange}
							dataType={FieldDataType.MultiLineText}
						/>
					</Field>
				</div>
				{field.id && (
					<ToggleContainer
						title="Assigned Types"
						open={true}
					>
						<SelectSlider
							options={this._assignedTypes}
							rows={list.map((t) => {
								let value = t.value ? AssignType.Assigned : AssignType.Unassigned;
								const typedObj = appState.actions.getTypeById(t.id);
								let disabledOptionsList: ISelectSliderOption[] = [];

								if (field.feature === XyiconFeature.Xyicon) {
									if (!field?.displayOnLinks || field.dataType !== FieldDataType.SingleLineText) {
										disabledOptionsList.push({id: AssignType.Lookup, label: this._assignedTypes.find((at) => at.id === AssignType.Lookup).label});
									}

									if (typedObj.settings.xyiconLookupLinkFieldList?.includes(field.id)) {
										value = AssignType.Lookup;
									}
								}

								return {
									label: t.label,
									value,
									disabledOptionsList,
								};
							})}
							onChange={this.onSliderMappingChange}
						/>
					</ToggleContainer>
				)}
				<ToggleContainer
					title="Formatting Rules"
					className="formattingRules"
					open={true}
				>
					<h4>Color Rules</h4>
					{this.getFormattingSettings(field)}
				</ToggleContainer>
			</>
		);
	}
}
