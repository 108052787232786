import * as React from "react";
import {Observer} from "mobx-react";
import type {IFieldDataTypeSettingsProps} from "../../../modules/settings/modules/field/datatypes/IFieldDataTypeSettingsProps";
import {SelectInputV5} from "../../input/select/SelectInputV5";
import {FieldV5} from "../FieldV5";

export enum IDateFieldFormat {
	DATE = "date",
	TIME = "time",
	DATETIME = "datetime",
	DATERANGE = "daterange",
	TIMERANGE = "timerange",
}

interface IDateFieldSettingsDefinition {
	format: IDateFieldFormat;
}

const _options: {id: IDateFieldFormat; label: string}[] = [
	{
		id: IDateFieldFormat.DATE,
		label: "Date Only",
	},
	{
		id: IDateFieldFormat.TIME,
		label: "Time Only",
	},
	{
		id: IDateFieldFormat.DATETIME,
		label: "Date + Time",
	},
];

export const DateFieldSettingsV5 = (props: IFieldDataTypeSettingsProps) => {
	const {field, onChange} = props;
	return (
		<Observer>
			{() => {
				const settings = field.dataTypeSettings as IDateFieldSettingsDefinition;
				return (
					<FieldV5 label="Format">
						<SelectInputV5
							options={_options}
							selected={_options.find((option) => option.id === settings.format)}
							onChange={(option) => {
								settings.format = option.id;
								onChange();
							}}
							isSameWidth={true}
							render={(option) => option.label}
						/>
					</FieldV5>
				);
			}}
		</Observer>
	);
};
