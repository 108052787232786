import type {Object3D} from "three";
import {Line, BufferGeometry, LineBasicMaterial, BufferAttribute, LineSegments, Mesh} from "three";
import {BasicMaterial} from "../../../materials/BasicMaterial";
import type {SpaceViewRenderer} from "../../../renderers/SpaceViewRenderer";
import {THREEUtils} from "../../../../../../../../utils/THREEUtils";
import {MarkupType} from "../../../../../../../../generated/api/base";
import type {Markup3D} from "./Markup3D";

/** Used for raycasting, so we can grab a markup, even when the mouse is not directly above a line, only it's pretty close to it */

export class InvisiblePart {
	private _spaceViewRenderer: SpaceViewRenderer;
	private _parent: Markup3D;
	private _container: Object3D;
	private _vertices: BufferAttribute;
	private _geometry: BufferGeometry;

	private readonly _isEdgeOnly: boolean = false;
	// for fill
	// we use a rectangle
	private _mesh: Mesh;
	private _meshMaterial: BasicMaterial;

	// for edge only
	private _lineMaterial: LineBasicMaterial;
	private _line: Line | LineSegments;

	constructor(spaceViewRenderer: SpaceViewRenderer, container: Object3D, parent: Markup3D, isEdgeOnly: boolean) {
		this._spaceViewRenderer = spaceViewRenderer;
		this._container = container;
		this._parent = parent;
		this._isEdgeOnly = isEdgeOnly;

		if (this._isEdgeOnly) {
			this._geometry = new BufferGeometry();
			this._lineMaterial = new LineBasicMaterial({color: 0x00ff00, visible: false});
			const type = this._type;
			const isSegment = type === MarkupType.Arrow || type === MarkupType.BidirectionalArrow || type === MarkupType.Cross;

			this._line = isSegment ? new LineSegments(this._geometry, this._lineMaterial) : new Line(this._geometry, this._lineMaterial);

			THREEUtils.add(this._container, this._line);
		} else {
			this._meshMaterial = new BasicMaterial(0x00ff00, 0, true, false);
			this._mesh = new Mesh(this._spaceViewRenderer.planeGeometry, this._meshMaterial);
			this._mesh.name = "textArea";
			THREEUtils.add(this._container, this._mesh);
		}
	}

	private refreshMesh() {
		const scale = this._parent.scale;

		this._mesh.scale.set(scale.x, scale.y, 1);

		THREEUtils.updateMatrices(this._mesh);
	}

	private get _type() {
		return this._parent.type;
	}

	public update(vertices: number[]) {
		if (this._isEdgeOnly) {
			const cornerVertices = new Float32Array(vertices);

			this._vertices = new BufferAttribute(cornerVertices, 3);

			this._geometry.dispose();
			this._geometry = new BufferGeometry();
			this._geometry.setAttribute("position", this._vertices);
			this._vertices.needsUpdate = true;
			this._line.geometry = this._geometry;
		} else {
			this.refreshMesh();
		}
	}

	public get mesh() {
		return this._mesh;
	}

	public dispose() {
		this._line?.removeFromParent();
		this._geometry?.dispose();
		this._lineMaterial?.dispose();
	}
}
