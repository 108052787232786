import React, {useCallback, useEffect, useRef, useState} from "react";
import styled, {css} from "styled-components";
import {DateFormatter} from "../../../../utils/format/DateFormatter";
import {DateUtils} from "../../../../utils/DateUtils";
import {FieldDataType} from "../../../../generated/api/base";
import {IDateFieldFormat} from "../../../modules/settings/modules/field/datatypes/DateFieldSettings";
import {HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {AppUtils} from "../../../../utils/AppUtils";
import {ClickToEditInputStyled, ClickToEditInputV5} from "../clicktoedit/ClickToEditInputV5";
import {IconButtonStyled, IconButtonV5} from "../../interaction/IconButtonV5";
import CalendarIcon from "../../icons/calendar.svg?react";
import ClockThreeIcon from "../../icons/clock-three.svg?react";
import XmarkIcon from "../../icons/xmark.svg?react";
import {baseDistance, ELLIPSIS, fontSize, fontWeight, radius} from "../../styles/styles";
import {colorPalette} from "../../styles/colorPalette";
import {PopupV5} from "../../popup/PopupV5";
import {TimePickerV5} from "./TimePickerV5";
import {DatePickerV5} from "./DatePickerV5";

interface DateTimeInputStyledProps {
	$inline: boolean;
}

interface PickersContainerStyledProps {
	format: IDateTimeInputFormat;
}

interface IDateInputV5Props {
	readonly value: string;
	readonly format: IDateTimeInputFormat;
	readonly disabled?: boolean;
	readonly valueToDate?: string;
	readonly focused?: boolean;
	readonly onChange: (value: string) => void;
	readonly onClick?: () => void;
	readonly inline?: boolean;
}

type IDateTimeInputFormat = "" | "date" | "time" | "datetime" | "daterange" | "timerange";

export const DateTimeInputV5 = (props: IDateInputV5Props) => {
	const {onClick, focused, format, disabled, inline, onChange} = props;

	const _container = useRef<HTMLDivElement>();
	const [isOpen, setIsOpen] = useState<boolean>(!!focused);
	const [value, setValue] = useState<string>(props.value);
	const [valueToDate, setValueToDate] = useState<string>("");

	const closePicker = useCallback(() => {
		AppUtils.disableScrolling(false);

		setIsOpen(false);
	}, []);

	const onTogglePicker = useCallback(
		async (e?: React.MouseEvent) => {
			e?.stopPropagation();
			onClick?.();

			AppUtils.disableScrolling(!isOpen);

			setIsOpen(!isOpen);

			if (!isOpen) {
				await TimeUtils.wait(100);
			}
		},
		[isOpen, onClick],
	);

	const onInput = (value: string) => {
		setValue(value);
	};

	const onClearClicked = () => {
		setValue("");
		setValueToDate("");

		onChange(null);
	};

	// This only handles the Popup 'Confirm' button which can't get invalid value (if you put invalid manually, it sets to Date.now()), so no need to check validation
	const onConfirmClicked = () => {
		const {format} = props;
		const from = DateUtils.parse(value);
		let date = "";

		if (format === "daterange" || format === "timerange") {
			const toDate = DateUtils.parse(valueToDate);
			const dateRange = {
				from: from,
				to: toDate,
			};

			date = DateUtils.stringifyDateRange(dateRange);
		} else {
			date = DateUtils.stringify(from);
		}

		onChange(date);
		closePicker();
	};

	const getErrorMessage = (value: string) => {
		if ((format === "date" && !DateUtils.dateValidation(value)) || (format === "time" && !DateUtils.isTimeValid(value))) {
			return "Invalid format";
		}
		return "";
	};

	const saveDateInput = (value: string, format: IDateTimeInputFormat) => {
		// It's needed because of time only input validation. Eg.: "10:20" would be invalid without date data.
		const placeholderDate = "2021.01.01";
		let inputValue = value;

		if (inputValue === "") {
			onChange(null);
			closePicker();

			return;
		}

		switch (format) {
			case "time":
			case "date":
			case "datetime":
				const date = DateUtils.parse(inputValue);

				if (date) {
					setValue(inputValue);
					onChange(date.toISOString());
					closePicker();
				}

				return;
			case "daterange":
			case "timerange":
				const value = DateUtils.parseDateRange(inputValue);
				let from = value.from;
				let to = value.to;

				if (format === "timerange") {
					from = `${placeholderDate} ${from}`;
					to = `${placeholderDate} ${to}`;
				}

				if (DateUtils.dateValidation(from) && DateUtils.dateValidation(to)) {
					setValue(from);
					setValueToDate(to);
					onChange(inputValue);
				}

				onTogglePicker();
				return;
		}
	};

	const getDisplayedDate = () => {
		const {format, value, valueToDate} = props;

		if (format === "daterange" || format === "timerange") {
			let rangeFormat = IDateFieldFormat.DATE;

			if (format === "daterange") {
				rangeFormat = IDateFieldFormat.DATETIME;
			} else if (format === "timerange") {
				rangeFormat = IDateFieldFormat.TIME;
			}

			if (value === "" || valueToDate === "") {
				return "";
			}

			return `${DateFormatter.format(value, rangeFormat)} - ${DateFormatter.format(valueToDate, rangeFormat)}`;
		} else {
			return DateFormatter.format(value, format);
		}
	};

	useEffect(() => {
		if (focused) {
			setIsOpen(true);
		} else {
			setIsOpen(false);
		}
	}, [focused]);

	return (
		<DateTimeInputStyled
			ref={_container}
			className="DateTimeInput hbox"
			$inline={inline}
		>
			<ClickToEditInputV5
				value={getDisplayedDate()}
				onChange={(value) => saveDateInput(value, format)}
				dataTypeSettings={FieldDataType.DateTime}
				disabled={disabled}
				getErrorMessage={getErrorMessage}
				onClick={onTogglePicker}
				focused={isOpen}
				inline={inline}
			/>
			{!disabled && (
				<InputIconsStyled
					$inline={inline}
					$hasValue={!!value}
				>
					{(!inline || !value) && (
						<IconButtonV5
							IconComponent={format === "time" ? ClockThreeIcon : CalendarIcon}
							onClick={onTogglePicker}
						/>
					)}
					{(!inline || value) && (
						<IconButtonV5
							IconComponent={XmarkIcon}
							onClick={onClearClicked}
							className="cancel"
						/>
					)}
				</InputIconsStyled>
			)}
			{isOpen && (
				<DateTimeInputPopupStyled
					parentRef={_container.current}
					label={format === "time" || format === "timerange" ? "Time" : "Calendar"}
					onClose={closePicker}
					closeOnClickOutside={true}
					buttonProps={{
						hideIcon: true,
						label: "Confirm",
						onClick: onConfirmClicked,
					}}
					isSmallPopup={true}
					verticalAlignment={VerticalAlignment.bottomOuter}
					horizontalAlignment={HorizontalAlignment.right}
					offsetY={10}
				>
					<PickersContainerStyled
						className="pickersContainer"
						format={format}
					>
						{(format === "date" || format === "datetime") && (
							<DatePickerV5
								value={value}
								onChange={onInput}
							/>
						)}
						{format === "datetime" && <LineStyled />}
						{(format === "time" || format === "datetime") && (
							<TimePickerV5
								value={DateUtils.parse(value)}
								onChange={onInput}
								format={format}
							/>
						)}
					</PickersContainerStyled>
				</DateTimeInputPopupStyled>
			)}
		</DateTimeInputStyled>
	);
};

const InputIconsStyled = styled.div<{$inline: boolean; $hasValue: boolean}>`
	${(props) => {
		if (!props.$inline || props.$hasValue) {
			return css`
				position: absolute;
				right: 0;
				display: flex;
				gap: ${baseDistance.sm};

				${IconButtonStyled} {
					&:hover {
						background-color: ${props.$hasValue && props.$inline ? "transparent" : colorPalette.gray.c200Light};
					}
				}
			`;
		} else {
			return css`
				position: absolute;
				right: 0;
				left: 0;

				${IconButtonStyled} {
					border: none;

					&:hover {
						background-color: transparent;
					}
				}
			`;
		}
	}}

	${IconButtonStyled} {
		border-radius: ${radius.sm};

		svg {
			width: 16px;
			height: 16px;
			color: ${colorPalette.gray.c950};
		}

		&:hover {
			border: 2px solid ${colorPalette.gray.c950};
		}
	}
`;

export const DateTimeInputStyled = styled.div<DateTimeInputStyledProps>`
	position: relative;
	justify-content: space-between;
	width: 100%;
	height: ${({$inline}) => ($inline ? "28px" : "32px")};
	align-items: center;

	${InputIconsStyled} {
		visibility: hidden;
	}

	&:hover {
		background-color: ${colorPalette.gray.c200Light};

		${InputIconsStyled} {
			visibility: visible;

			${(props) => {
				if (!props.$inline) {
					return css`
						${IconButtonStyled} {
							background-color: ${colorPalette.gray.c200Light};
							height: 30px;

							&.cancel {
								width: 31px;
								right: 1px;
							}
						}
					`;
				}
			}}
		}
	}

	.unfocused {
		font-size: ${fontSize.md};
		font-weight: ${({$inline}) => ($inline ? "400" : "300")};
		color: ${colorPalette.gray.c950};

		&.disabled {
			color: ${colorPalette.libraryColors.explosive};
		}
	}

	.dateLabel {
		min-height: 17px;
	}

	input {
		border: none;
		height: 32px;

		&:focus {
			background: transparent;
		}
	}

	${(props) => {
		if (props.$inline) {
			return css`
				${ClickToEditInputStyled} {
					.unfocused {
						height: 18px;
						min-height: 18px;
						border: none;
						padding: 0;
						text-align: center;
						position: relative;
						left: 4px;
						display: flex;

						&:hover {
							background-color: transparent;
						}

						.SingleLineLabel {
							${ELLIPSIS};
						}
					}
				}
			`;
		}
	}}
`;

const PickersContainerStyled = styled.div<PickersContainerStyledProps>`
	padding: ${baseDistance.md} 0;

	.unfocused {
		box-sizing: border-box;
		padding: 10px;
	}

	.input {
		box-sizing: border-box;
		position: relative;
		padding-right: 30px;
		max-width: 100%;
		${ELLIPSIS};
		font-weight: ${fontWeight.thin};
		font-size: ${fontSize.md};
	}

	&.datetime,
	&.daterange {
		min-width: 375px;
	}

	&.date,
	&.time,
	&.timerange {
		min-width: 305px;
	}

	&.time {
		min-width: 250px;

		.pickerinputs {
			.title {
				width: 40px;
			}

			.row {
				.ClickToEditInputV5 {
					width: 100%;
				}
			}
		}
	}

	.title {
		font-weight: ${fontWeight.thin};
	}
`;

const DateTimeInputPopupStyled = styled(PopupV5)`
	padding: ${baseDistance.md};
	margin: 0;

	.header {
		height: 32px;
		padding: 0;

		.name {
			margin: 0;
		}

		.cancel {
			margin: 0;
		}
	}

	.body {
		padding: 0;
		gap: 0;
	}
`;

const LineStyled = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colorPalette.gray.c300};
	margin: ${baseDistance.md} 0;
`;
