import {Vector2} from "three";
import type {LineGeometry} from "three/examples/jsm/lines/LineGeometry.js";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {Constants} from "../../Constants";
import {MeasureType} from "../../renderers/SpaceViewRendererUtils";
import type {PointDouble} from "../../../../../../../generated/api/base";
import {MarkupType} from "../../../../../../../generated/api/base";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import {MathUtils} from "../../../../../../../utils/math/MathUtils";
import {Markup} from "../../../../../../../data/models/Markup";
import {MarkupAB2D} from "./abstract/MarkupAB2D";
import type {IMarkupConfig} from "./abstract/MarkupUtils";
import type {ICornerLetter} from "./abstract/MarkupAB";

export class MarkupRectangle extends MarkupAB2D {
	private _textContent: string = "";

	constructor(spaceViewRenderer: SpaceViewRenderer, config: IMarkupConfig) {
		super(spaceViewRenderer, {measureType: MeasureType.NONE, ...config});
	}

	private get _showArea() {
		return this._config.measureType === MeasureType.AREA;
	}

	protected override updateAB(A: PointDouble, B: PointDouble, isLocal: boolean = false, keepAspectRatio: boolean, fixedPoint: ICornerLetter = "a") {
		const worldVertices = this.processAB(A, B, isLocal, keepAspectRatio, fixedPoint);

		this._vertices.length = 0;
		this._2dVectors.length = 0;
		for (const worldVertex of worldVertices) {
			this._vertices.push(worldVertex.x);
			this._vertices.push(worldVertex.y);
			this._vertices.push(0);

			this._2dVectors.push(new Vector2(worldVertex.x, worldVertex.y));
		}
		this._vertices.push(worldVertices[0].x);
		this._vertices.push(worldVertices[0].y);
		this._vertices.push(0);

		(this._lineGeometry as LineGeometry).setPositions(this._vertices);

		this.updateMesh(this._2dVectors);

		if (this._showArea) {
			const width = THREEUtils.calculateDistance([worldVertices[0], worldVertices[1]]);
			const height = THREEUtils.calculateDistance([worldVertices[1], worldVertices[2]]);
			const areaInSpaceUnit = width * height;
			const spaceMaybe = this._modelData?.space ? this._modelData.space : this._spaceViewRenderer.space;
			const spaceUnitsPerMeter = spaceMaybe?.spaceUnitsPerMeter ?? 1;
			const unitName = this.unitName;
			const unit = Constants.DISTANCE_UNITS[unitName];
			const area = MathUtils.convertAreaFromSpaceUnit(areaInSpaceUnit, unit.name, spaceUnitsPerMeter);

			if (unitName === "foot&inch") {
				// area is in ft²
				const ftSqInSq = MathUtils.convertFeetSqInDecimalToFeetSqAndInchesSq(area);

				this._textContent = MathUtils.formatFeetSqAndInchesSqArrayToText(ftSqInSq);
			} else {
				this._textContent = `${area.toFixed(Constants.TO_FIXED)} ${unit.abbreviation}²`;
			}
			this.recreateTextGroups();
		}

		if (this._isDashed) {
			this._line.computeLineDistances();
		}

		if (isLocal) {
			this._selectionPart.update(this._vertices);
			this._textArea?.update(this._vertices);
		}
		this.updateBoundingBox();
	}

	public override get textContent() {
		return this._showArea ? this._textContent : this._modelData?.text.content || "";
	}

	public override get fontColor() {
		return this.isTemp ? super.fontColor : this._showArea ? this.shiftedColor : (this._modelData?.text.fontColor ?? Markup.defaultText.fontColor);
	}

	public get type(): MarkupType {
		return this._showArea ? MarkupType.RectangleArea : MarkupType.Rectangle;
	}
}
