import * as React from "react";
import {inject, observer} from "mobx-react";
import type {FormEvent} from "react";
import {Button} from "../button/Button";
import {Initials} from "../Initials";
import type {AppState} from "../../../data/state/AppState";
import {User} from "../../../data/models/User";
import {FocusLoss} from "../../../utils/ui/focus/FocusLoss";
import {ReactUtils} from "../../utils/ReactUtils";
import {XyiconFeature} from "../../../generated/api/base";
import {UserGroup} from "../../../data/models/UserGroup";
import {SearchField} from "../input/search/SearchField";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils} from "../../../utils/dom/DomUtils";
import {TimeUtils} from "../../../utils/TimeUtils";
import {AppUtils} from "../../../utils/AppUtils";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {notify} from "../../../utils/Notify";
import type {View} from "../../../data/models/View";
import {NotificationType} from "../../notification/Notification";
import type {Report} from "../../../data/models/Report";

interface IAddUserOrUserGroupProps {
	readonly search: string;
	readonly appState?: AppState;
	readonly exceptions?: string[];
	readonly noFixedPosition?: boolean;
	readonly view?: View;
	readonly report?: Report;
	readonly usersOnly?: boolean;
	readonly addButtonText?: string;
	readonly searchTextPlaceholder?: string;
	readonly listMaxHeight?: number;
	readonly onSearch: (value: string) => void;
	readonly onAdd: (userId: string, feature: XyiconFeature) => void;
}

interface IAddUserOrUserGroupState {
	open: boolean;
	transform: TransformObj;
}

@inject("appState")
@observer
export class AddUserOrUserGroup extends React.Component<IAddUserOrUserGroupProps, IAddUserOrUserGroupState> {
	private _container = React.createRef<HTMLDivElement>();
	private _list = React.createRef<HTMLDivElement>();

	constructor(props: IAddUserOrUserGroupProps) {
		super(props);
		this.state = {
			open: false,
			transform: null,
		};
	}

	private toggleSelector = async (value?: boolean) => {
		const open = this.state.open;
		const {noFixedPosition} = this.props;

		if (!value && open) {
			this.props.onSearch("");
			AppUtils.disableScrolling(false);
		}

		this.setState({open: value ?? !open});

		if (!open && value) {
			if (!noFixedPosition) {
				AppUtils.disableScrolling(true);
			}

			await TimeUtils.wait(100);
			if (this._list.current) {
				FocusLoss.listen(this._list.current, this.onFocusLoss);
			}
		}
	};

	private onFocusLoss = () => {
		this.toggleSelector(false);
	};

	private onAddUser = (userId: string) => {
		const {onAdd, view, appState, report} = this.props;

		onAdd(userId, XyiconFeature.User);
		this.toggleSelector(false);

		if (view) {
			notify(this.props.appState.app.notificationContainer, {
				type: NotificationType.Success,
				title: `"${view.name}" view has been shared successfully!`,
			});
		} else if (report) {
			notify(this.props.appState.app.notificationContainer, {
				type: NotificationType.Success,
				title: `"${report.name}" report has been shared successfully!`,
			});
		}
	};

	private onAddUserGroup = (userGroupId: string) => {
		const {onAdd, view, appState, report} = this.props;

		onAdd(userGroupId, XyiconFeature.UserGroup);
		this.toggleSelector(false);

		if (view) {
			notify(this.props.appState.app.notificationContainer, {
				type: NotificationType.Success,
				title: `"${view.name}" view has been shared successfully with ${appState.actions.findUserGroup(userGroupId).name}!`,
			});
		} else if (report) {
			notify(this.props.appState.app.notificationContainer, {
				type: NotificationType.Success,
				title: `"${report.name}" report has been shared successfully with ${appState.actions.findUserGroup(userGroupId).name}!`,
			});
		}
	};

	private onSearchInput = (value: string, event: FormEvent) => {
		this.props.onSearch(value);
	};

	public override componentDidUpdate(prevProps: IAddUserOrUserGroupProps, prevState: IAddUserOrUserGroupState) {
		if (
			!this.props.noFixedPosition &&
			((!prevState.open && this.state.open) || (this.state.open && prevProps.search !== this.props.search)) &&
			this._container.current &&
			this._list.current
		) {
			this.setState({transform: DomUtils.getFixedFloatingElementPosition(this._container.current, this._list.current)});
		}
	}

	public override render() {
		const {search, appState, exceptions = [], noFixedPosition, addButtonText, usersOnly, searchTextPlaceholder, listMaxHeight} = this.props;
		const {open, transform} = this.state;
		const exceptionsWithCurrentUser = [...exceptions, appState.user?.id];

		const inlineStyle: React.CSSProperties = {
			position: noFixedPosition ? "fixed" : "inherit",
			transform: transform?.translate,
			zIndex: 8000,
			width: this._container.current?.getBoundingClientRect().width,
			height: listMaxHeight || "auto",
		};

		if (noFixedPosition && inlineStyle) {
			inlineStyle.transform = "";
		}

		let users = appState.actions.getList<User>(XyiconFeature.User);

		if (search) {
			users = users.filter((user) => User.search(user, search));
		}
		if (exceptionsWithCurrentUser?.length) {
			users = users.filter((user) => !exceptionsWithCurrentUser.includes(user.id));
		}

		let userGroups = appState.actions.getList<UserGroup>(XyiconFeature.UserGroup);

		if (search) {
			userGroups = userGroups.filter((userGroup) => UserGroup.search(userGroup, search));
		}
		if (exceptions?.length) {
			userGroups = userGroups.filter((userGroup) => !exceptions.includes(userGroup.id));
		}

		return (
			<div
				ref={this._container}
				className={ReactUtils.cls("AddUserOrUserGroup", {open})}
			>
				<SearchField
					className="hbox flex_1"
					value={search}
					onInput={this.onSearchInput}
					onClick={() => this.toggleSelector(true)}
					placeholder={!open ? searchTextPlaceholder || "Search users or groups to share" : " "}
				/>
				{open && (
					<DomPortal
						destination={DomPortal._body}
						noPortal={noFixedPosition}
					>
						<div
							className="AddUserOrUserGroup__selector vbox"
							style={inlineStyle}
							ref={this._list}
						>
							<div className="list addList overflowYAuto">
								{!usersOnly && userGroups.length !== 0 && (
									<div className="section">
										<h3>User Groups</h3>
										{userGroups
											.slice()
											.sort((a: UserGroup, b: UserGroup) => StringUtils.sortIgnoreCase(a.name, b.name))
											.map((userGroup) => (
												<div
													key={userGroup.id}
													className="item hbox alignCenter"
													onClick={() => this.onAddUserGroup(userGroup.id)}
												>
													<div className="avatar">
														<Initials name={userGroup.name} />
													</div>
													<div className="vbox flex_1">
														<div className="name">{userGroup.name}</div>
														<div className="counter">{userGroup.renderMemberCount()}</div>
													</div>
													<Button
														label={addButtonText || "Share"}
														title={addButtonText || "Share"}
														className="primary rounded dark"
													/>
												</div>
											))}
									</div>
								)}
								{users.length !== 0 && (
									<div className="section">
										<h3>Users</h3>
										{users
											.slice()
											.sort((a: User, b: User) => StringUtils.sortIgnoreCase(a.fullName, b.fullName))
											.map((user: User) => (
												<div
													key={user.id}
													className={ReactUtils.cls("item hbox alignCenter", {unregistered: user.status === "invited"})}
													onClick={() => this.onAddUser(user.id)}
												>
													<div className="avatar">
														{user.profileFileName ? (
															<img
																src={user.profileFileName}
																alt={`${user.fullName} profile image`}
															/>
														) : (
															<Initials name={user.fullName || user.email} />
														)}
													</div>
													<div className="vbox flex_1">
														<div className="name">{user.status === "invited" ? "Unregistered User" : user.fullName}</div>
														<div className="email">{user.email}</div>
													</div>
													<Button
														label={addButtonText || "Share"}
														title={addButtonText || "Share"}
														className="primary rounded dark"
													/>
												</div>
											))}
									</div>
								)}
								{userGroups.length === 0 && users.length === 0 && (
									<div className="empty">We couldn't find a match. To invite this user to Xyicon, please contact your Administrator.</div>
								)}
							</div>
						</div>
					</DomPortal>
				)}
			</div>
		);
	}
}
