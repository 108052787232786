import {CSS3DObject} from "./CSS3DObject";

export class CSS3DSprite extends CSS3DObject {
	private _originalPosition: number[] = [];

	constructor(element: HTMLElement) {
		super(element);
	}

	public setOriginalPosition(originalPosition: number[]) {
		this._originalPosition = originalPosition;
	}

	public get originalPosition() {
		return this._originalPosition;
	}
}
