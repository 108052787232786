import type {IModel} from "../../../data/models/Model";
import type {AppState} from "../../../data/state/AppState";
import LocationPinIcon from "../icons/location-pin.svg?react";
import PenFieldIcon from "../icons/pen-field.svg?react";
import DeleteIcon from "../icons/delete.svg?react";
import UnplotIcon from "../icons/unplot.svg?react";
import CloneIcon from "../icons/clone.svg?react";
import StarIcon from "../icons/star.svg?react";
import PenWithLineIcon from "../icons/pen-with-line.svg?react";
import InfoIcon from "../icons/info.svg?react";
import type {IActionBarItem} from "../abstract/ModuleViewV5";
import {CatalogIconType, Permission, XyiconFeature} from "../../../generated/api/base";
import type {Xyicon} from "../../../data/models/Xyicon";
import type {Boundary} from "../../../data/models/Boundary";
import type {Portfolio} from "../../../data/models/Portfolio";
import {NotificationType} from "../../notification/Notification";
import {notify} from "../../../utils/Notify";
import type {INotificationParams} from "../popup/NotificationV5";
import type {IPortfolioDuplicationStatus} from "../../../data/signalr/SignalRListener";
import type {INotificationElementParams} from "../../notification/AppNotifications";
import type {Catalog} from "../../../data/models/Catalog";
import {SetFavoriteButton} from "../abstract/table/SetFavoriteButton";
import type {Space} from "../../../data/models/Space";
import type {SpaceViewRenderer} from "../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {Xyicon3D} from "../../modules/space/spaceeditor/logic3d/elements3d/Xyicon3D";
import {HTMLUtils} from "../../../utils/HTML/HTMLUtils";
import {THREEUtils} from "../../../utils/THREEUtils";
import {XyiconUtils} from "../../../data/models/XyiconUtils";
import {ImageUploadPreprocessor} from "../../../utils/image/ImageUploadPreprocessor";
import {DomUtils} from "../../../utils/dom/DomUtils";
import {XHRLoader} from "../../../utils/loader/XHRLoader";
import type {AppActions} from "../../../data/state/AppActions";
import type {TypeFieldKind} from "../../../data/services/TypeAndFieldServiceUtils";
import {PopupUtilsV5} from "../popup/PopupUtilsV5";
import {WarningWindowV5} from "../popup/WarningWindowV5";
import {typesFeatures} from "../../../data/state/AppStateConstants";

export interface IDuplicationRequest {
	requestId: string;
	portfolio: Portfolio;
	notification: INotificationElementParams | null;
}

const getBoundaryViewActionBarButtons = (appState: AppState): IActionBarItem<Boundary>[] => {
	const canDelete = (selectedItems: IModel[]) => {
		return selectedItems.every((item) => appState.actions.getModuleTypePermission(item.typeId, item.ownFeature) >= Permission.Delete);
	};

	return [
		{
			id: "locate",
			title: "Locate Boundary",
			label: "Locate",
			IconComponent: LocationPinIcon,
			isVisible: (selectedItems) => selectedItems.length === 1 && !!selectedItems[0].space,
			onClick: (selectedItems) => {
				appState.actions.navigateToSpaceItem(selectedItems[0], false);
			},
		},
		{
			id: "fieldUpdate",
			title: "Update Field",
			label: "Update Field",
			IconComponent: PenFieldIcon,
			isVisible: () => true,
		},
		{
			id: "delete",
			title: "Delete Boundary",
			label: "Delete",
			IconComponent: DeleteIcon,
			isVisible: (selectedItems) => canDelete(selectedItems),
		},
	];
};

const getXyiconViewActionBarButtons = (appState: AppState): IActionBarItem<Xyicon>[] => {
	const {user, actions} = appState;
	const {spaceViewRenderer} = appState.app.graphicalTools;

	const canDelete = (selectedItems: Xyicon[]) => {
		if (!user) {
			return false;
		}
		if (user.isAdmin) {
			return true;
		}

		return selectedItems.every((item) => {
			const permission = actions.getModuleTypePermission(item.typeId, XyiconFeature.Xyicon);

			return permission >= Permission.Delete;
		});
	};

	const unplotClickHandler = (items: Xyicon[]) => {
		return onUnplotClick(spaceViewRenderer, items);
	};

	return [
		{
			id: "locate",
			title: "Locate Xyicon",
			label: "Locate",
			IconComponent: LocationPinIcon,
			isVisible: (selectedItems) => selectedItems.length === 1 && !!selectedItems[0].space,
			isEnabled: (selectedItems) => selectedItems.every((item) => !item.isUnplotted),
			onClick: (selectedItems) => {
				appState.actions.navigateToSpaceItem(selectedItems[0], false);
			},
		},
		{
			id: "unplot",
			title: "Unplot Xyicon",
			label: "Unplot",
			IconComponent: UnplotIcon,
			isVisible: (selectedItems) => selectedItems.length > 0,
			onClick: unplotClickHandler,
			isEnabled: (selectedItems) => selectedItems.every((x) => !x.isUnplotted),
		},
		{
			id: "fieldUpdate",
			title: "Update Field",
			label: "Update Field",
			IconComponent: PenFieldIcon,
			isVisible: () => true,
		},
		{
			id: "delete",
			title: "Delete Xyicon",
			label: "Delete",
			IconComponent: DeleteIcon,
			isVisible: (selectedItems: Xyicon[]) => canDelete(selectedItems),
		},
	];
};

let _duplicationRequests: IDuplicationRequest[] = [];

const getPortfolioViewActionBarButtons = (appState: AppState): IActionBarItem<Portfolio>[] => {
	const {
		user,
		app: {transport},
		actions,
	} = appState;

	const onStatusUpdated = (data: IPortfolioDuplicationStatus) => {
		const duplicationRequest = _duplicationRequests.find((request) => request.requestId === data.requestID);

		if (duplicationRequest) {
			duplicationRequest.notification?.onClose();

			if (data.isCompleted) {
				getPortfolioDuplicationStatusReceivedSignal().remove(onStatusUpdated);
				_duplicationRequests = _duplicationRequests.filter((request) => request !== duplicationRequest);

				const notificationParams: INotificationParams = {
					type: NotificationType.Success,
					title: `Duplicating ${duplicationRequest.portfolio.name} Portfolio is Completed!`,
					lifeTime: 10000,
				};

				if (data.isError) {
					notificationParams.title = `Duplicating ${duplicationRequest.portfolio.name} Portfolio Failed.`;
					notificationParams.type = NotificationType.Error;
					notificationParams.description = data.message;
					notificationParams.lifeTime = Infinity;
				}

				duplicationRequest.notification = notify(appState.app.notificationContainer, notificationParams);
			} else {
				duplicationRequest.notification = notify(appState.app.notificationContainer, {
					type: NotificationType.Message,
					title: `Duplicating ${duplicationRequest.portfolio.name} Portfolio`,
					description: data.message,
					lifeTime: 10000,
				});
			}
		}
	};

	const getPortfolioDuplicationStatusReceivedSignal = () => {
		return transport.signalR.listener.signals.portfolioDuplicationStatusReceived;
	};

	const onDuplicatePortfolioClick = async (model: Portfolio) => {
		try {
			const result = await transport.duplicatePortfolio(model);

			_duplicationRequests.push({requestId: result.requestID, portfolio: model, notification: null});
			getPortfolioDuplicationStatusReceivedSignal().add(onStatusUpdated);
		} catch (error) {
			console.error(error);
		}
	};

	const canDelete = (selectedItems: IModel[]) => {
		if (!user) {
			return false;
		}
		if (user.isAdmin) {
			return true;
		}

		return selectedItems.every((item: IModel) => {
			const permission = actions.getPortfolioPermission(item.id);

			return permission >= Permission.Delete;
		});
	};

	const isAdmin = () => {
		return appState.user?.isAdmin;
	};

	return [
		{
			id: "duplicate",
			title: "Duplicate Portfolio Item",
			label: "Duplicate",
			IconComponent: CloneIcon,
			isVisible: (selectedItems) => selectedItems.length === 1 && isAdmin(),
			onClick: (selectedItems) => onDuplicatePortfolioClick(selectedItems[0]),
		},
		{
			id: "fieldUpdate",
			title: "Update Field",
			label: "Update Field",
			IconComponent: PenFieldIcon,
			isVisible: () => true,
		},
		{
			id: "delete",
			title: "Delete Portfolio Item",
			label: "Delete",
			IconComponent: DeleteIcon,
			isVisible: (selectedItems) => selectedItems.length !== 0 && canDelete(selectedItems),
		},
	];
};

const getCatalogViewActionBarButtons = (appState: AppState): IActionBarItem<Catalog>[] => {
	const catalogPermission = appState.user?.getOrganizationPermission(XyiconFeature.XyiconCatalog) ?? Permission.None;

	const setFavorite = (items: Catalog[]) => {
		const areAllFavorites = items.every((catalog) => catalog.isFavorite);

		for (const item of items) {
			item.setFavorite(!areAllFavorites);
		}
	};

	return [
		{
			id: "favorite",
			title: "Add to Favorites", // gets overwritten within SetFavoriteButton
			IconComponent: StarIcon,
			isVisible: (selectedItems: Catalog[]) => selectedItems.length >= 1,
			onClick: setFavorite,
			componentFactory: (selectedItems: Catalog[]) => (
				<SetFavoriteButton
					items={selectedItems}
					setFavorite={setFavorite}
				/>
			),
		},
		{
			id: "duplicate",
			title: "Duplicate Catalog Item",
			label: "Duplicate",
			IconComponent: CloneIcon,
			isVisible: (selectedItems) => selectedItems.length === 1 && catalogPermission >= Permission.Update,
		},
		{
			id: "edit",
			title: "Edit Catalog Item",
			label: "Edit",
			IconComponent: PenWithLineIcon,
			isVisible: (selectedItems) => selectedItems.length === 1 && catalogPermission >= Permission.Update,
		},
		{
			id: "fieldUpdate",
			title: "Update Field",
			label: "Update Field",
			IconComponent: PenFieldIcon,
			isVisible: () => true,
		},
		{
			id: "delete",
			title: "Delete Catalog Item",
			label: "Delete",
			IconComponent: DeleteIcon,
			isVisible: (selectedItems) => selectedItems.length > 0 && catalogPermission >= Permission.Delete,
		},
	];
};

const getSpaceViewActionBarButtons = (appState: AppState): IActionBarItem<Space>[] => {
	const {user, actions} = appState;

	const hasEveryTypePermission = (items: Space[], givenPermission: Permission) => {
		if (!user) {
			return false;
		}
		if (user.isAdmin) {
			return true;
		}

		return items.every((item) => {
			const permission = actions.getModuleTypePermission(item.typeId, XyiconFeature.Space);

			return permission >= givenPermission;
		});
	};

	const isDeleteEnabled = (selectedItems: Space[]) => {
		if (selectedItems.length === 0) {
			return false;
		}

		return hasEveryTypePermission(selectedItems, Permission.Delete);
	};

	return [
		{
			id: "delete",
			title: "Delete Space",
			label: "Delete",
			IconComponent: DeleteIcon,
			isVisible: () => true,
			isEnabled: isDeleteEnabled,
		},
	];
};

export const getActionBarButtonsForFeature = (feature: XyiconFeature, appState: AppState): IActionBarItem<IModel>[] => {
	switch (feature) {
		case XyiconFeature.Boundary:
			return getBoundaryViewActionBarButtons(appState);
		case XyiconFeature.Xyicon:
			return getXyiconViewActionBarButtons(appState);
		case XyiconFeature.Portfolio:
			return getPortfolioViewActionBarButtons(appState);
		case XyiconFeature.XyiconCatalog:
			return getCatalogViewActionBarButtons(appState);
		case XyiconFeature.Space:
			return getSpaceViewActionBarButtons(appState);
	}
};

const getListFeatures = (typesFeature: XyiconFeature) => {
	const features = [typesFeature];

	if (typesFeature === XyiconFeature.Xyicon) {
		features.push(XyiconFeature.XyiconCatalog); // Catalog items also use type feature
	}

	return features;
};

const isDeleteAllowed = (ids: string[], kind: TypeFieldKind, typesFeature: XyiconFeature, appState: AppState) => {
	if (kind === "types") {
		const features = getListFeatures(typesFeature);

		for (const feature of features) {
			const list = appState.actions.getList(feature);

			for (const item of list) {
				if (item.typeId && ids.includes(item.typeId)) {
					return false;
				}
			}
		}
	}

	return true;
};

export const onDeleteClickForTypeAndField = async (
	selectedIds: string[],
	kind: TypeFieldKind,
	feature: XyiconFeature,
	appState: AppState,
	callback: () => void,
) => {
	const transport = appState.app.transport;
	const count = selectedIds.length;

	if (isDeleteAllowed(selectedIds, kind, feature, appState)) {
		const confirmed = await PopupUtilsV5.getDeleteConfirmationPopupV5(null, count);

		if (confirmed) {
			await transport.services.typefield.remove(kind, selectedIds, feature);
			callback();
		}
	} else {
		await WarningWindowV5.open("You can't delete this type because it's currently being used.");
	}
};

export const getActionBarButtonsForTypeAndField = (
	kind: TypeFieldKind,
	feature: XyiconFeature,
	appState: AppState,
	callback: () => void,
): IActionBarItem<IModel>[] => {
	const canDelete = (selectedItems: IModel[]) => true;
	const deleteFeature = kind === "types" ? (typesFeatures[feature as XyiconFeature.Xyicon] ?? feature) : feature;

	return [
		{
			id: "delete",
			title: "Delete Selected Items",
			label: "Delete",
			IconComponent: DeleteIcon,
			isVisible: (selectedItems) => canDelete(selectedItems),
			onClick: (selectedItems: IModel[]) =>
				onDeleteClickForTypeAndField(
					selectedItems.map((s) => s.id),
					kind,
					deleteFeature,
					appState,
					callback,
				),
		},
	];
};

export const extendActionBarButtonsWithDetailsButton = (
	actionBarButtons: IActionBarItem<IModel>[],
	isActive: boolean,
	onClick: (selectedItems: IModel[]) => void,
) => {
	return [
		{
			id: "details",
			title: "Details",
			label: "Details",
			IconComponent: InfoIcon,
			isActive,
			isVisible: () => true,
			onClick,
		} as IActionBarItem<IModel>,
		...actionBarButtons,
	];
};

const getNumberOfXyiconsWithoutPermission = (actions: AppActions, selectedItems: Xyicon3D[]): number =>
	actions.getNumberOfSpaceItemsWithoutPermission(selectedItems, Permission.Update);

export const notifyAboutInsufficientPermissions = (spaceViewRenderer: SpaceViewRenderer) => {
	const {spaceItemController, actions} = spaceViewRenderer;
	const {selectedItems} = spaceItemController;
	const selectedXyicons = selectedItems as Xyicon3D[];
	const _itemsWithoutPermissionCount = getNumberOfXyiconsWithoutPermission(actions, selectedXyicons);

	notify(spaceViewRenderer.transport.appState.app.notificationContainer, {
		type: NotificationType.Warning,
		title: "Action not allowed!",
		description: `${_itemsWithoutPermissionCount} of ${selectedItems.length} objects you have selected do not have the required permissions to perform this action. To obtain permission, contact your organization's administrator.`,
		lifeTime: Infinity,
	});
};

/**
 *
 * @param spaceViewRenderer
 * @param itemsToUnplot we're unplotting the selected xyicons from the spaceeditor. If the spaceeditor is not mounted, then this parameter is required
 * to determine which items we need to unplot
 */
export const onUnplotClick = async (spaceViewRenderer: SpaceViewRenderer, itemsToUnplot?: Xyicon[]) => {
	const {xyiconManager, space, actions} = spaceViewRenderer;
	const {selectedItems} = xyiconManager;
	const selectedXyicons = selectedItems as Xyicon3D[];

	const itemsToCheckPermissionsOf = spaceViewRenderer.isMounted
		? selectedXyicons
		: ((itemsToUnplot ?? []).map((item) => xyiconManager.getItemById(item.id)).filter((item) => !!item) as Xyicon3D[]);
	const _itemsWithoutPermissionCount = getNumberOfXyiconsWithoutPermission(actions, itemsToCheckPermissionsOf);

	if (_itemsWithoutPermissionCount === 0) {
		if (spaceViewRenderer.isMounted) {
			const _iconSize = XyiconUtils.iconSize;
			const xyiconIDList: string[] = selectedXyicons.map((xyicon) => xyicon.modelData.id);
			const correctionMultiplier = spaceViewRenderer.isMounted ? spaceViewRenderer.correctionMultiplier.current : 1;
			const unplotIconInToolarPos = (document.querySelector("[data-title='Unplotted Xyicons']") as HTMLElement).getBoundingClientRect();

			unplotIconInToolarPos.x += unplotIconInToolarPos.width / 2;
			unplotIconInToolarPos.y += unplotIconInToolarPos.height / 2;
			const {domElement} = spaceViewRenderer;
			const size = HTMLUtils.getSize(domElement);
			const tempDivs: HTMLDivElement[] = [];
			const duration = 200;

			for (const x of selectedXyicons) {
				const xyicon = x.modelData as Xyicon;
				const tempDiv = document.createElement("div");

				const coords = THREEUtils.worldCoordinatesToDomCoordinates(
					x.position.x,
					x.position.y,
					x.position.z,
					spaceViewRenderer.domElement,
					spaceViewRenderer.activeCamera,
				);

				coords.x += size.x;
				coords.y += size.y;

				tempDiv.className = `glyph-tobeunplotted ${x.modelData.id}`;
				tempDiv.style.backgroundImage = `url('${xyicon.thumbnail}')`;
				tempDiv.style.width = `${_iconSize}px`;
				tempDiv.style.height = `${_iconSize}px`;
				tempDiv.style.position = "absolute";
				tempDiv.style.backgroundSize = "contain";
				tempDiv.style.zIndex = "9999";
				tempDiv.style.left = `${coords.x}px`;
				tempDiv.style.top = `${coords.y}px`;
				tempDiv.style.pointerEvents = "none";

				tempDiv.style.transform = `translate(-50%, -50%) ${XyiconUtils.getScaleForCSSXyicon(xyicon, spaceViewRenderer)}`;

				if (xyicon.iconCategory === CatalogIconType.ModelParameter) {
					const snapShot = await ImageUploadPreprocessor.getTopToBottomSnapshotOfCatalog(
						xyicon.catalog,
						spaceViewRenderer.actions,
						space,
						correctionMultiplier,
					);

					if (snapShot.image !== xyicon.thumbnail) {
						const standardXyiconSize = spaceViewRenderer.xyiconManager.xyiconSize;
						const imageSize = (_iconSize * Math.max(snapShot.xyiconSize.x, snapShot.xyiconSize.y)) / standardXyiconSize;

						tempDiv.style.backgroundImage = `url('${snapShot.image}')`;
						tempDiv.style.width = `${imageSize}px`;
						tempDiv.style.height = `${imageSize}px`;
					}
				}

				tempDivs.push(tempDiv);
				document.body.appendChild(tempDiv);
			}

			xyiconManager.deleteSelectedItems(false);

			const promises: Promise<Animation[]>[] = [];

			for (const tempDiv of tempDivs) {
				promises.push(DomUtils.flyTo(unplotIconInToolarPos.x, unplotIconInToolarPos.y, tempDiv, duration, false));
			}

			await Promise.all(promises);

			for (const tempDiv of tempDivs) {
				document.body.removeChild(tempDiv);
			}

			for (const selectedXyicon of selectedXyicons) {
				(selectedXyicon.modelData as Xyicon).unplot();
			}

			await spaceViewRenderer.transport.requestForOrganization({
				url: "xyicons/unplot",
				method: XHRLoader.METHOD_POST,
				params: {
					xyiconIDList,
					portfolioID: spaceViewRenderer.transport.appState.portfolioId,
				},
			});
		} else if (itemsToUnplot?.length > 0) {
			for (const itemToUnplot of itemsToUnplot) {
				itemToUnplot.unplot();
			}

			await spaceViewRenderer.transport.requestForOrganization({
				url: "xyicons/unplot",
				method: XHRLoader.METHOD_POST,
				params: {
					xyiconIDList: itemsToUnplot.map((xyicon) => xyicon.id),
					portfolioID: spaceViewRenderer.transport.appState.portfolioId,
				},
			});
		}
	} else {
		notifyAboutInsufficientPermissions(spaceViewRenderer);
	}
};
